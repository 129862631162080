<template>
	<div class="ligand-based-tool-message">
		<div class="tool-message-section--header">
			<bButton
				label="Preview results"
				type="primary"
				@onClick="openJob"
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { router } from '@/app/providers';
import { bButton } from '@/shared/uikit';
import { defineProps } from 'vue';

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
});

const openJob = (id: string) => {
	router.push(`/job/${props.id}`);
};
</script>

<style lang="stylus" scoped>
.tool-message-section--header
	width 100%
	display flex
	justify-content space-between
	align-items center
	padding-top 1rem
</style>
