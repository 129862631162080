import { ocularUserModel } from '@/entities/app';
import { authRoutes } from '@/pages/auth';
import { cartRoutes } from '@/pages/cart';
import { chatsRoutes } from '@/pages/chats';
import { collectionsRoutes } from '@/pages/collections';
import { competitiveRoutes } from '@/pages/competitive/';
import { dashboardRoutes } from '@/pages/dashboard';
import { errorRoutes } from '@/pages/errors';
import { homeRoutes } from '@/pages/home';
import { jobRoutes } from '@/pages/job';
import { OnboardingPage } from '@/pages/onboarding';
import { dagPipelineRoutes } from '@/pages/pipeline';
import { isMobilePlatform } from '@/shared/lib';
import {
	createRouter,
	createWebHistory,
	type RouteRecordRaw,
} from 'vue-router';
import { auth } from './firebase';

const routes: Array<RouteRecordRaw> = [
	...errorRoutes,
	...authRoutes,
	...homeRoutes,
	...dashboardRoutes,
	...jobRoutes,
	...collectionsRoutes,
	...cartRoutes,
	...chatsRoutes,
	...dagPipelineRoutes,
	...competitiveRoutes,
	// ...toolsRoutes,
	{
		path: '/onboarding',
		name: 'onboarding',
		component: OnboardingPage,
		meta: { requiresAuth: true, requiresVerifiedEmail: true },
	},
];

const router = createRouter({
	// @ts-ignore
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (isMobilePlatform()) {
		if (to.name == 'mobile') {
			return next();
		} else {
			return next({ name: 'mobile' });
		}
	}

	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	const requiresVerifiedEmail = to.matched.some(
		(record) => record.meta.requiresVerifiedEmail
	);

	const currentUser = auth.currentUser;
	let user: any = null;

	if (!currentUser) {
		user = await new Promise((resolve) => {
			const unsubscribe = auth.onAuthStateChanged((user) => {
				unsubscribe();
				resolve(user);
			});
		});
	} else {
		user = currentUser;
	}

	if (!user && requiresAuth) {
		if (to.name !== 'login') {
			return next({ name: 'login' });
		}
	}

	if (user && !user.emailVerified && requiresVerifiedEmail) {
		if (to.name !== 'verify-email') {
			return next({ name: 'verify-email' });
		}
	}

	if (requiresAuth && user && user.emailVerified) {
		const { fetchUser, user: OcularUser } = ocularUserModel;
		if (OcularUser.value === null && to.name !== 'onboarding') {
			await fetchUser();
		}
	}

	if (
		(to.name === 'login' || to.name === 'signup') &&
		user &&
		user.emailVerified
	) {
		return next({ name: 'home' });
	}

	return next();
});

export default router;
