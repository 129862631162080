import type { HitEntity } from '@/shared/api';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	is3D: boolean;
	previewMolecule: HitEntity | null;
}

const data: IViewModel = {
	is3D: false,
	previewMolecule: null,
};

const state = reactive(data);

export const previewSmiles3DModel = {
	...toRefs(state),
};
