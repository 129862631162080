import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api/';
import { firebaseErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

const state = reactive({
	isLoading: false,
});

async function login(email: string, password: string) {
	Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_EMAIL);
	if (!email || !password) {
		return;
	}

	try {
		state.isLoading = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.login({
			email,
			password,
		});

		Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_EMAIL_SUCCESS);
		router.push('/');
	} catch (error: any) {
		firebaseErrorHandler(error);
		Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_EMAIL_FAILURE);
	} finally {
		state.isLoading = false;
	}
}

export const buttonLoginWithEmailModel = {
	...toRefs(state),
	login,
};
