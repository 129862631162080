<template>
	<div class="cart-button-item" @click="onClick">{{ title }}</div>
</template>

<script setup lang="ts">
import { cartModel } from '@/features/cart';
import { computed } from 'vue';
import { createCollectionModel } from './model';

const emit = defineEmits(['onClick']);
const { hits } = cartModel;
const { isVisible, selectedHits } = createCollectionModel;

const title = computed(() => {
	return `New collection with ${hits.value.length} ${hits.value.length === 1 ? 'selected molecule' : ' selected molecules'}`;
});

const onClick = () => {
	isVisible.value = true;
	selectedHits.value = hits.value;
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.cart-button-item
	width 100%
	display flex
	flex-direction row
	align-items center
	justify-content flex-start
	padding: 0.85rem 0.75rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	color var(--neutral-700)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
