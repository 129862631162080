<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.7071 3.70711C15.0976 3.31658 15.0976 2.68342 14.7071 2.29289C14.3166 1.90237 13.6834 1.90237 13.2929 2.29289L11.2929 4.29289C10.9024 4.68342 10.9024 5.31658 11.2929 5.70711L13.2929 7.70711C13.6834 8.09763 14.3166 8.09763 14.7071 7.70711C15.0976 7.31658 15.0976 6.68342 14.7071 6.29289L14.4142 6H16C17.6569 6 19 7.34315 19 9V21C19 21.5523 19.4477 22 20 22C20.5523 22 21 21.5523 21 21V9C21 6.23858 18.7614 4 16 4H14.4142L14.7071 3.70711ZM9 5C9 6.30622 8.16519 7.41746 7 7.82929V16.1707C8.16519 16.5825 9 17.6938 9 19C9 20.6569 7.65685 22 6 22C4.34315 22 3 20.6569 3 19C3 17.6938 3.83481 16.5825 5 16.1707V7.82929C3.83481 7.41746 3 6.30622 3 5C3 3.34315 4.34315 2 6 2C7.65685 2 9 3.34315 9 5ZM6 18C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20C6.55228 20 7 19.5523 7 19C7 18.4477 6.55228 18 6 18ZM7 5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
