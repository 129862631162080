import { navigationTabsModel } from '@/features/navigation';
import { type ChatEntity } from '@/shared/api';
import { ChatService } from '@/shared/api/chemcrow/ChatService';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	chats: ChatEntity[];
}

const data: IViewModel = {
	isLoading: false,
	chats: [],
};

const state = reactive(data);

async function fetchChats() {
	state.isLoading = true;
	try {
		const result = await ChatService.getInstance().fetchAll(10, 0);
		state.chats = result.chats;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

function onRemoveChat(id: string) {
	state.chats = state.chats.filter((chat) => chat.id !== id);
	const { removeTabById } = navigationTabsModel;
	removeTabById(`chat-${id}`);
}

export const asideChatsModel = {
	...toRefs(state),
	fetchChats,
	onRemoveChat,
};
