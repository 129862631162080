import { ocularUserModel } from '@/entities/app';
import { FeedbackService } from '@/shared/api/shared';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	message: string;
	isSended: boolean;
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	message: '',
	isSended: false,
};

const state = reactive(data);

async function submit() {
	state.isLoading = true;

	try {
		const { user } = ocularUserModel;

		if (!user.value) {
			throw new Error('User is not logged in');
		}

		await FeedbackService.getInstance().submit(
			user.value.email,
			state.message
		);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.isSended = true;
		state.message = '';
		setTimeout(() => {
			state.isSended = false;
			state.isVisible = false;
		}, 3000);
	}
}

export const suggestedFeatureModel = {
	...toRefs(state),
	submit,
};
