<template>
	<div
		class="chat-card"
		:class="{
			'chat-card--active': isCurrentChat,
		}"
		@click="onClick"
	>
		<div class="bodySmallRegular">
			{{ chat.name }}
		</div>

		<RemoveChatButton
			:id="chat.id"
			:isCurrentChat="isCurrentChat"
			@onClick="onRemove"
		/>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { RemoveChatButton } from '@/features/chats';
import { type ChatEntity } from '@/shared/api';
import type { PropType } from 'vue';

const emit = defineEmits(['onClick', 'onRemove']);

const props = defineProps({
	chat: {
		type: Object as PropType<ChatEntity>,
		required: true,
	},
	isCurrentChat: {
		type: Boolean,
		default: false,
	},
});

const onClick = () => {
	emit('onClick');
	router.push('/chat/' + props.chat.id);
};

const onRemove = () => {
	emit('onRemove');
};
</script>

<style lang="stylus" scoped>
.chat-card
	user-select none
	width 100%
	display grid
	grid-template-columns 1fr auto
	align-items center
	gap 1rem
	padding 0.5rem 0.75rem
	border 1px solid var(--neutral-200)
	border-radius 0.75rem
	cursor pointer
	transition all 0.25s
	&:hover
		background-color var(--neutral-50)
	&--active
		background-color var(--primary-600)
		cursor default
		.bodySmallRegular
			color white
		&:hover
			background-color var(--primary-600)
			.bodySmallRegular
				color white

.bodySmallRegular
	display -webkit-box
	-webkit-box-orient vertical
	-webkit-line-clamp 2
	overflow hidden
	text-overflow ellipsis
</style>
