<template>
	<div class="dag-pipeline-page">
		<SvgPipelineWidget />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	navigationHeaderModel,
	navigationTabsModel,
} from '@/features/navigation';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { SvgPipelineWidget } from '@/widgets/pipeline/';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { title, description, clear: clearNavigation } = navigationHeaderModel;

onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.name,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	if (router.currentRoute.value.name === 'pipeline') {
		title.value = 'Drug Discovery Pipeline';
		description.value = '';
		const { addTabIfNotExists, tabs } = navigationTabsModel;

		addTabIfNotExists(
			NavigationTabEntity.fromObject({
				id: `pipeline`,
				title: 'Drug Discovery Pipeline',
				description: '',
				path: `/pipeline`,
				type: NavigationTabType.Pipeline,
				tabs: tabs.value.length + 1,
				isPinned: false,
			})
		);
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style scoped>
.dag-pipeline-page {
	width: 100%;
	height: 100%;
	position: relative;
}
</style>
