import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { AuthService, MicrosoftAuthProvider } from '@/shared/api';
import { firebaseErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

const state = reactive({
	isLoading: false,
});

async function login() {
	if (router.currentRoute.value.fullPath === '/login') {
		Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_MICROSOFT);
	} else {
		Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_MICROSOFT);
	}

	try {
		state.isLoading = true;
		const provider = new MicrosoftAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.login(provider);
		router.push('/');

		if (router.currentRoute.value.fullPath === '/login') {
			Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_MICROSOFT_SUCCESS);
		} else {
			Analytics.send(
				AnalyticsEvents.SignUp.SIGN_UP_WITH_MICROSOFT_SUCCESS
			);
		}
	} catch (error) {
		console.error(error);
		firebaseErrorHandler(error);
		if (router.currentRoute.value.fullPath === '/login') {
			Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_MICROSOFT_FAILURE);
		} else {
			Analytics.send(
				AnalyticsEvents.SignUp.SIGN_UP_WITH_MICROSOFT_FAILURE
			);
		}
	} finally {
		state.isLoading = false;
	}
}

export const loginWithMicrosoftModel = {
	...toRefs(state),
	login,
};
