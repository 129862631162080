import { HitEntity } from '..';
import { RestClient } from '../client';
import { Endpoints } from '../shared';

export class OrderService {
	private static instance: OrderService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): OrderService {
		if (!OrderService.instance) {
			OrderService.instance = new OrderService();
		}
		return OrderService.instance;
	}

	async submitEmolecules(
		compounds: HitEntity[],
		ship_to: string,
		comments: string | null,
		format: string
	): Promise<void> {
		await this.client.post<any>(Endpoints.Order.CREATE, {
			ship_to: ship_to,
			compounds: compounds.map((compound) => {
				return {
					external_id: compound.external_id,
					smiles: compound.smiles,
					quantity: compound.quantity,
					purity: compound.purity,
				};
			}),
			format: format,
			comments: comments,
		});
	}
}
