<template>
	<bButton
		:label="$t('auth.button.signout')"
		type="tertiary"
		@onClick="onClick"
	/>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { modalsModel } from '@/entities/modals/';
import { bButton } from '@/shared/uikit/';

const { isLogout } = modalsModel;

const onClick = () => {
	isLogout.value = true;
	Analytics.send(AnalyticsEvents.Logout.OPEN_LOGOUT_MODAL);
};
</script>
