<template>
	<BottomDrawerTemplate
		:title="
			isStructureSearch
				? 'New Fingerprint-based search'
				: 'New Ligand Based search'
		"
		:info="
			isStructureSearch
				? 'Well known Tanimoto search'
				: 'Search by ligand SMILES and probes'
		"
		:isVisible="isVisible"
		@onClose="onClose"
	>
		<template v-slot:body>
			<div class="lbss-body-container">
				<template v-if="step === 0">
					<LigandBasedSearchInput
						:initSmiles="smiles"
						@onNext="onNext"
						@onClear="clear"
					/>
				</template>
				<template v-if="step === 1">
					<LBSSCatalogList
						:topK="top_k"
						:queriesCount="smiles.length"
						:selectedCatalogId="selectedCatalog?.id"
						:isStructureSearch="isStructureSearch"
						@onSelect="selectCatalog"
					/>
					<LBSSTopkSlider
						v-if="selectedCatalog && !isLoading"
						v-model="top_k"
						:max="selectedCatalog.max_top_k"
					/>
				</template>
				<template v-if="step === 2">
					<LBSSPrefiltersList
						v-if="selectedCatalog"
						:catalogId="selectedCatalog.id"
					/>
				</template>
			</div>
		</template>
		<template v-slot:footer-controls>
			<div class="lbss-footer">
				<template v-if="step === 0">
					<bButton
						label="Next"
						type="primary"
						:isDisabled="smiles.length === 0"
						@onClick="step = 1"
					/>
				</template>
				<template v-if="step === 1">
					<bButton label="Back" type="tertiary" @onClick="step = 0" />
					<bButton
						label="Next"
						type="primary"
						:isDisabled="!selectedCatalog"
						@onClick="step = 2"
					/>
				</template>
				<template v-if="step === 2">
					<bButton label="Back" type="tertiary" @onClick="step = 1" />
					<bButton
						label="Start search"
						type="primary"
						@onClick="finishCreating"
					/>
				</template>
			</div>
		</template>
	</BottomDrawerTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { BottomDrawerTemplate } from '@/features/home';
import {
	LBSSCatalogList,
	LBSSPrefiltersList,
	LBSSTopkSlider,
	LigandBasedSearchInput,
	lbssCatalogListModel,
} from '@/features/ligand-based-search/';
import { SearchType } from '@/shared/api';
import { bButton } from '@/shared/uikit';
import { onMounted, watch } from 'vue';
import { createLBSSModel } from './model';

const {
	isVisible,
	top_k,
	smiles,
	clear,
	step,
	selectCatalog,
	selectedCatalog,
	finishCreating,
	isStructureSearch,
} = createLBSSModel;

const { isLoading, fetchCatalogs } = lbssCatalogListModel;

watch(
	() => top_k.value,
	(newValue) => {
		if (smiles.value.length === 0) return;

		fetchCatalogs(newValue, smiles.value.length);
	},
	{ deep: true }
);

const onClose = () => {
	isVisible.value = false;
	clear();

	Analytics.send(AnalyticsEvents.Job.CLOSE_CREATE_JOB_MODAL, {
		type: SearchType.LIGAND,
		step: step.value,
	});
};

const onNext = (value: string[]) => {
	smiles.value = value;
};

onMounted(() => {
	Analytics.send(AnalyticsEvents.Job.OPEN_CREATE_JOB_MODAL, {
		type: SearchType.LIGAND,
	});
});
</script>
<style lang="stylus" scoped>
.lbss-body-container
	width 100%
	height 100%;
	padding: 0 10%
	display: flex
	flex-direction: column
	justify-content: flex-start
	padding-top 2.5rem
	padding-bottom 2.5rem
	gap 2.5rem
	&::-webkit-scrollbar
		display none
.lbss-footer
	width 100%
	display flex
	flex-direction row
	justify-content: center
	gap 1rem
</style>
