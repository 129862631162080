import 'firebase/compat/auth';
import { RestClient } from '../client';
import { Endpoints } from '../shared';
import { CollectionEntity, CollectionItemEntity } from './entities';

export class CollectionService {
	private static instance: CollectionService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): CollectionService {
		if (!CollectionService.instance) {
			CollectionService.instance = new CollectionService();
		}
		return CollectionService.instance;
	}

	async fetchCollections(
		limit: number,
		offset: number,
		keywords: string = ''
	): Promise<{
		count: number;
		collections: CollectionEntity[];
	}> {
		const response = await this.client.get<any>(
			Endpoints.Collections.FETCH_ALL,
			{
				limit: limit,
				offset: offset,
			}
		);

		return {
			count: response.data.count,
			collections: response.data.collections.map((collection: any) => {
				return CollectionEntity.fromObject(collection);
			}),
		};
	}

	async fetchCollection(id: string): Promise<CollectionEntity> {
		const response = await this.client.get<any>(
			Endpoints.Collections.GET(id)
		);

		return CollectionEntity.fromObject(response.data);
	}

	async fetchCollectionItems(
		collectionId: string,
		limit: number,
		offset: number,
		filters: any[]
	): Promise<{
		count: number;
		collectibles: CollectionItemEntity[];
	}> {
		const response = await this.client.post<any>(
			Endpoints.Collections.FETCH_ITEMS(collectionId),
			{
				order_by: 'added_at',
				limit: limit,
				offset: offset,
				filters: filters,
			}
		);

		return {
			count: response.data.count,
			collectibles: response.data.collectibles.map((collectible: any) =>
				CollectionItemEntity.fromObject(collectible)
			),
		};
	}

	async delete(id: string): Promise<void> {
		await this.client.delete(Endpoints.Collections.DELETE(id));
	}

	async create(name: string): Promise<CollectionEntity> {
		const response = await this.client.post(Endpoints.Collections.CREATE, {
			name: name,
		});

		return CollectionEntity.fromObject(response.data);
	}

	async addHitToCollection(
		collection_id: string,
		items: {
			catalog_id: string;
			index: number;
		}[]
	): Promise<void> {
		await this.client.patch(Endpoints.Collections.PATCH(collection_id), {
			add: items,
		});
	}

	async removeHitFromCollection(
		collection_id: string,
		items: {
			catalog_id: string;
			index: number;
		}[]
	): Promise<void> {
		await this.client.patch(Endpoints.Collections.PATCH(collection_id), {
			remove: items,
		});
	}
}
