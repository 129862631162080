<template>
	<div class="container">
		<IconLogo size="2.75rem" :color="ThemeColors.primary[600]" />

		<div class="login-container">
			<div class="header-block">
				<div class="calloutRegular">
					{{ $t('onboarding.about.title') }}
				</div>
				<div class="footnoteRegular">
					{{ $t('onboarding.about.subtitle') }}
				</div>
			</div>
			<form>
				<bSimpleInput v-model="name" size="large" placeholder="Name" />
				<bSelect
					v-model="role"
					size="large"
					placeholder="Role"
					:options="roleOptions"
				/>
				<bSimpleInput
					v-if="role.title === OnboardingUserRoles.Other"
					v-model="customRole"
					size="large"
					placeholder="Specify your role"
				/>
				<bSimpleInput
					v-model="haveYouHeardAboutUs"
					size="large"
					placeholder="How did you hear about us?"
				/>

				<bButton
					:label="$t('onboarding.button.continue')"
					type="primary"
					@click="onClick"
					:isLoading="isLoading"
					:isDisabled="!isValid"
				/>
			</form>
		</div>
		<div class="login-footer">
			<OnboardingLogoutButton />
		</div>
	</div>
</template>

<script setup lang="ts">
import { ocularUserModel } from '@/entities/app';
import { OnboardingLogoutButton } from '@/features/auth';
import {
	IconLogo,
	ThemeColors,
	bButton,
	bSelect,
	bSimpleInput,
} from '@/shared/uikit';
import { OnboardingUserRoles, tellAboutYourselfStepModel } from './model';

const {
	name,
	isValid,
	isLoading,
	role,
	roleOptions,
	customRole,
	haveYouHeardAboutUs,
} = tellAboutYourselfStepModel;
const { createUser } = ocularUserModel;

const props = defineProps({
	mode: {
		type: String,
		default: 'default',
	},
});

const onClick = async () => {
	isLoading.value = true;
	const selectedRole =
		role.value.title === OnboardingUserRoles.Other
			? customRole.value
			: role.value.title;

	const referralUserId = localStorage.getItem('referralUserId') ?? null;

	await createUser(
		name.value,
		selectedRole,
		haveYouHeardAboutUs.value,
		referralUserId
	);
	isLoading.value = false;
};
</script>
<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';
</style>
