<template>
	<div class="job-cluster-filters-widget">
		<CollectionHitsFiltersHeader
			:isShadow="isShadow"
			:total="collectionDetails?.size ?? 0"
			:currentCount="total"
			@onResetFilters="setDefaultFilters"
		/>
		<div
			id="job-cluster-filters-widget--body"
			class="job-cluster-filters-widget--body"
			@scroll="onScroll"
		>
			<bHistogramSlider
				v-for="(filter, index) in attributes"
				:key="'filter-histogramm-key' + filter.type"
				:model-value="filter"
				:title="getSearchAttributeTypeLabel(filter.type)"
				:min="filter.min"
				:max="filter.max"
				:absoluteMin="filter.absolute_min"
				:absoluteMax="filter.absolute_max"
				:items="filter.histogram"
				:index="index"
				:byStep="
					filter.type === SearchAttributeType.WEIGHT ||
					filter.type === SearchAttributeType.HBA ||
					filter.type === SearchAttributeType.HBD
						? 1
						: -1
				"
				:isForceOpen="true"
				@update:modelValue="
					(newValue: any) => {
						filter.max = newValue.max;
						filter.min = newValue.min;
						onReload();
					}
				"
				:isDisabled="filter.isDisabled"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getSearchAttributeTypeLabel, SearchAttributeType } from '@/shared/api';
import { bHistogramSlider } from '@/shared/uikit';
import { ref } from 'vue';
import { collectionItemsListModel } from '../collection-list-widget/model';
import { collectionDetailsModel } from '../model';
import CollectionHitsFiltersHeader from './CollectionHitsFiltersHeader.vue';
import { collectionFiltersModel } from './model';

const { attributes, setDefaultFilters } = collectionFiltersModel;
const { total, fetchCollectionItems } = collectionItemsListModel;
const { collectionDetails } = collectionDetailsModel;
const isShadow = ref<boolean>(false);

const onScroll = (e: any) => {
	const el = e.target;
	isShadow.value = el.scrollTop > 0;
};

const onReload = () => {
	fetchCollectionItems(1);
};
</script>

<style lang="stylus" scoped>
.job-cluster-filters-widget
    width 100%
    height 100%
    position relative
    &--body
        overflow-y auto
        width 100%
        height calc(100vh - 6.25rem)
        display flex
        flex-direction column
        align-items flex-start
        justify-content flex-start
        gap 0.75rem
        padding 6rem 0.5rem
        padding-bottom 1rem
        &::-webkit-scrollbar
            display none
</style>
