<template>
	<JobStateViewsWidget :isLoading="isLoading" :job="job">
		<template v-slot:ready-state>
			<div class="job-details-page">
				<JobResultsFiltersWidget />
				<JobResultsListWidget />
			</div>
		</template>
	</JobStateViewsWidget>
</template>

<script setup lang="ts">
import {
	JobResultsFiltersWidget,
	JobResultsListWidget,
	JobStateViewsWidget,
	jobDetailsModel,
} from '@/widgets/job';

const { job, isLoading } = jobDetailsModel;
</script>

<style lang="stylus" scoped>

.job-details-page
	width 100%
	height 100%
	padding 0 calc(5vw)
	display grid
	grid-template-columns 23rem minmax(40rem, 1fr)
	gap 1.5rem
	&--empty
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center

	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(2vw)
	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(1vw)
	.empty-view
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
