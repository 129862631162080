import type { ElementManager } from './ElementManager';
import type { IDraggable } from './entities';

export class ConnectionManager implements IDraggable {
	tempLine: SVGPathElement | null = null;
	startConnectionPoint: { x: number; y: number } | null = null;
	private removeButton: SVGForeignObjectElement | null = null;
	private removeButtonConnection: {
		fromElementId: string;
		toElementId: string;
	} | null = null;

	private svg: SVGSVGElement;
	private elementManager: ElementManager;
	private connectionsGroup: SVGGElement;

	constructor(svg: SVGSVGElement, elementManager: ElementManager) {
		this.svg = svg;
		this.elementManager = elementManager;
		this.connectionsGroup = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'g'
		);
		this.svg.insertBefore(this.connectionsGroup, this.svg.firstChild);
		this.createForeignObjectButton();
	}

	clear() {
		while (this.connectionsGroup.firstChild) {
			this.connectionsGroup.removeChild(this.connectionsGroup.firstChild);
		}
	}

	getLinePath(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		color: string = '#2DD4BF',
		id?: string
	): SVGPathElement {
		const path = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'path'
		);
		path.classList.add('connection-path');
		const midX = (x1 + x2) / 2;
		const controlPoint1 = { x: midX + (midX - x1), y: y1 };
		const controlPoint2 = { x: midX + (midX - x2), y: y2 };
		const d = `M ${x1},${y1} C ${controlPoint1.x},${controlPoint1.y} ${controlPoint2.x},${controlPoint2.y} ${x2},${y2}`;
		path.setAttribute('d', d);
		path.setAttribute('stroke', color);
		path.setAttribute('stroke-width', '4');
		path.setAttribute('fill', 'none');

		if (id) {
			path.setAttribute('data-id', id);
		}

		return path;
	}

	draw() {
		while (this.connectionsGroup.firstChild) {
			this.connectionsGroup.removeChild(this.connectionsGroup.firstChild);
		}

		const connections = this.elementManager.elements
			.map((el) => el.connections)
			.flat()
			.reduce((acc: any, current) => {
				const existingElement: any = acc.find(
					(el: any) =>
						el.input === current.input &&
						el.output === current.output
				);
				if (existingElement) {
					existingElement.isSelected =
						existingElement.isSelected || current.isSelected;
				} else {
					acc.push(current);
				}
				return acc;
			}, []);

		connections.forEach((connection: any) => {
			const startElement = this.elementManager.getElementById(
				connection.output
			);
			const endElement = this.elementManager.getElementById(
				connection.input
			);

			if (!startElement || !endElement) {
				return;
			}

			const start = startElement.outputPoint;
			const end = endElement.inputPoint;

			const pathColor = connection.isExecuted
				? '#0D9488'
				: connection.isSelected
					? '#0D9488'
					: '#2DD4BF';

			const path = this.getLinePath(
				start.x,
				start.y,
				end.x,
				end.y,
				pathColor,
				connection.id
			);

			this.connectionsGroup.appendChild(path);

			if (connection.isSelected) {
				this.updateRemoveButtonPosition(start, end);
			}
		});
	}

	// Handlers

	public handleMouseDown(event: MouseEvent, props?: any) {
		this.handleClick(event);
		this.draw();

		const target = event.target as HTMLElement;

		// Check if the clicked target is a connection point
		if (target.closest('.connection-point')) {
			// Get the coordinates of the connection point
			const rect = target.getBoundingClientRect();
			const x = rect.left + rect.width / 2;
			const y = rect.top + rect.height / 2;

			// Transform the screen coordinates to SVG coordinates
			const svgPoint = this.svg.createSVGPoint();
			svgPoint.x = x;
			svgPoint.y = y;
			const transformedPoint = svgPoint.matrixTransform(
				this.svg.getScreenCTM()?.inverse()
			);

			// Set the start connection point
			this.startConnectionPoint = {
				x: transformedPoint.x,
				y: transformedPoint.y,
			};

			// Create a temporary line element
			this.tempLine = this.getLinePath(
				this.startConnectionPoint.x,
				this.startConnectionPoint.y,
				this.startConnectionPoint.x,
				this.startConnectionPoint.y
			);

			// Append the temporary line to the SVG
			this.svg.appendChild(this.tempLine);
		}
	}

	public handleMouseMove(event: MouseEvent) {
		this.draw();

		if (!this.startConnectionPoint) {
			return;
		}

		const svgPoint = this.svg.createSVGPoint();
		svgPoint.x = event.clientX;
		svgPoint.y = event.clientY;
		const transformedPoint = svgPoint.matrixTransform(
			this.svg.getScreenCTM()?.inverse()
		);

		this.updateTempLine(
			this.startConnectionPoint.x,
			this.startConnectionPoint.y,
			transformedPoint.x,
			transformedPoint.y
		);
	}

	public handleMouseUp(event: MouseEvent) {
		this.draw();

		if (!this.startConnectionPoint) {
			return;
		}

		if (this.tempLine) {
			this.svg.removeChild(this.tempLine);
			this.tempLine = null;
		}
		this.startConnectionPoint = null;
	}

	public handleZoom(event: WheelEvent): void {
		this.draw();
	}

	public handleClick(event: MouseEvent): void {
		const target = event.target as SVGElement;
		if (target.tagName !== 'path') {
			return;
		}

		const pathElement = target as SVGPathElement;
		const id = pathElement.getAttribute('data-id');
		if (!id) {
			this.clearSelectedConnections();
			return;
		}
		this.elementManager.elements.forEach((element) => {
			element.connections.forEach((connection) => {
				if (connection.id === id) {
					this.removeButtonConnection = {
						fromElementId: connection.output,
						toElementId: connection.input,
					};
					connection.isSelected = true;
				} else {
					connection.isSelected = false;
				}
			});
		});
	}

	public clearSelectedConnections() {
		this.removeButtonConnection = null;
		this.removeButton!.style.display = 'none'; // Hide the button
		this.elementManager.elements.forEach((element) => {
			element.connections.forEach((connection) => {
				connection.isSelected = false;
			});
		});
	}

	// Private

	private updateTempLine(x1: number, y1: number, x2: number, y2: number) {
		if (this.tempLine) {
			const midX = (x1 + x2) / 2;
			const controlPoint1 = { x: midX + (midX - x1), y: y1 };
			const controlPoint2 = { x: midX + (midX - x2), y: y2 };

			const d = `M ${x1},${y1} C ${controlPoint1.x},${controlPoint1.y} ${controlPoint2.x},${controlPoint2.y} ${x2},${y2}`;
			this.tempLine.setAttribute('d', d);
		}
	}

	private updateRemoveButtonPosition(
		start: { x: number; y: number },
		end: { x: number; y: number }
	) {
		const midX = (start.x + end.x) / 2;
		const midY = (start.y + end.y) / 2;

		if (this.removeButton) {
			this.removeButton.setAttribute('x', `${midX - 20}`);
			this.removeButton.setAttribute('y', `${midY - 20}`);
			this.removeButton.style.display = 'block';
		}
	}

	private createForeignObjectButton() {
		this.removeButton = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'foreignObject'
		);
		this.removeButton.setAttribute('width', '40');
		this.removeButton.setAttribute('height', '40');
		this.removeButton.style.display = 'none';

		const button = document.createElement('button');
		button.innerHTML = `
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
	<g clip-path="url(#clip0_3262_4044)">
	<path d="M15.2501 4.75843C14.9251 4.43343 14.4001 4.43343 14.0751 4.75843L10.0001 8.8251L5.9251 4.7501C5.6001 4.4251 5.0751 4.4251 4.7501 4.7501C4.4251 5.0751 4.4251 5.6001 4.7501 5.9251L8.8251 10.0001L4.7501 14.0751C4.4251 14.4001 4.4251 14.9251 4.7501 15.2501C5.0751 15.5751 5.6001 15.5751 5.9251 15.2501L10.0001 11.1751L14.0751 15.2501C14.4001 15.5751 14.9251 15.5751 15.2501 15.2501C15.5751 14.9251 15.5751 14.4001 15.2501 14.0751L11.1751 10.0001L15.2501 5.9251C15.5668 5.60843 15.5668 5.0751 15.2501 4.75843Z" fill="white"/>
	</g>
	<defs>
	<clipPath id="clip0_3262_4044">
	<rect width="20" height="20" fill="white"/>
	</clipPath>
	</defs>
	</svg>
		`;
		button.style.width = '40px';
		button.style.height = '40px';
		button.style.border = 'none';
		button.style.borderRadius = '50%';
		button.style.backgroundColor = '#0D9488';
		button.style.color = 'white';
		button.style.display = 'flex';
		button.style.alignItems = 'center';
		button.style.justifyContent = 'center';
		button.style.padding = '0';
		button.style.margin = '0';
		button.style.cursor = 'pointer';

		button.onclick = (event) => {
			event.stopPropagation();
			if (this.removeButtonConnection) {
				const { fromElementId, toElementId } =
					this.removeButtonConnection;

				this.elementManager.removeConnection(
					fromElementId,
					toElementId
				);
				this.clearSelectedConnections();
			}
		};

		this.removeButton.appendChild(button);
		this.svg.appendChild(this.removeButton);
	}
}
