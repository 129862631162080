<template>
	<div class="b-select-container">
		<div v-if="label" class="footnoteRegular">{{ label }}</div>
		<div :class="className" ref="elementRef">
			<div :class="targetClassName" @click="toggleDropdown">
				{{
					modelValue && modelValue.title.length > 0
						? modelValue.title
						: placeholder
				}}

				<IconArrowUp v-if="isOpened" :size="iconSize" />
				<IconArrowDown v-else :size="iconSize" />
			</div>
			<div v-if="isOpened" class="b-select-dropdown">
				<div
					v-for="(option, index) in options"
					:key="'b-select-key' + index"
					:class="itemClassName(option)"
					@click="selectOption(option)"
				>
					{{ option.title }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { IconArrowDown, IconArrowUp } from '@/shared/uikit/';
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';

type Option = {
	title: string;
};

const props = defineProps({
	modelValue: {
		type: Object as () => Option | null,
		required: true,
	},
	options: {
		type: Array as () => Option[],
		required: false,
		default: () => ['Option 1', 'Option 2'],
	},
	size: {
		type: String,
		required: false,
		default: 'medium',
	},
	placeholder: {
		type: String,
		required: false,
		default: 'Select option',
	},
	label: { type: String, required: false },
	position: {
		type: String,
		required: false,
		default: 'bottom',
	},
});

const emit = defineEmits(['update:modelValue']);
const { modelValue } = toRefs(props);
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};

const className = computed((): any => {
	return {
		'b-select': true,
		'b-select--size-small': props.size === 'small',
		'b-select--size-medium': props.size === 'medium',
		'b-select--size-large': props.size === 'large',
		'b-select--position-top': props.position === 'top',
	};
});

const targetClassName = computed((): any => {
	return {
		'b-select-target': true,
		'b-select-target__active': isOpened,
		'b-select-target--placeholder':
			(modelValue.value as Option)?.title.length === 0,
		footnoteRegular: props.size === 'small',
		calloutRegular: props.size === 'medium',
		bodyRegular: props.size === 'large',
	};
});

const itemClassName = (current: Option) => {
	return {
		'b-select-item': true,
		'b-select-item--active': current.title === modelValue.value?.title,
		footnoteRegular: props.size === 'small',
		calloutRegular: props.size === 'medium',
		bodyRegular: props.size === 'large',
	};
};

const iconSize = computed((): any => {
	switch (props.size) {
		case 'medium':
			return '1.25rem';
		case 'small':
			return '1rem';
		case 'large':
			return '1.5rem';
		default:
			return '1rem';
	}
});

const selectOption = (option: Option) => {
	isOpened.value = false;
	emit('update:modelValue', option);
};
</script>

<style lang="stylus" scoped>
.b-select-container
	width 100%
	.footnoteRegular
		padding-left 0.5rem
		margin-bottom 0.38rem
		color var(--neutral-600)
.b-select
	position: relative
	width 100%
	&--size-large
		height 3.5rem
		font-size: 0.875rem;
		.b-select-target
			padding: 1rem
			font-size: 0.875rem;
		.b-select-item
			padding: 1rem 1.5rem;
			font-size: 0.875rem;
	&--size-medium
		height 2.375rem
		font-size: 1rem;
		.b-select-target
			padding: 0.75rem 1rem;
		.b-select-item
			padding: 0.56295rem 1rem;
	&--size-small
		height 2rem
		font-size: 0.75rem;
		.b-select-target
			padding: 0.5rem 0.75rem;
		.b-select-item
			padding: 0.5rem 0.75rem;
	&--position-top
		position relative
		.b-select-dropdown
			position: absolute
			margin-top: 0
			top: -11rem !important

	.b-select-target
		font-family: var(--font-family);
		height 100%
		width 100%
		display: flex;
		flex-direction row
		align-items: center;
		justify-content: space-between;
		border-radius: 0.5rem;
		background: #FFF;
		border: 1px solid rgba(0, 0, 0, 0.10);
		&:hover
			cursor: pointer
			background: var(--neutral-50);
	.b-select-item
		width 100%
		display: flex;
		flex-direction row
		align-items: center;
		justify-content: space-between;
		gap: 0.625rem;
		border-radius: 0.5rem;
		&:hover
			background: var(--neutral-100)
			cursor pointer
		&--active
			font-weight: 600

	.b-select-dropdown
		position: absolute
		margin-top: 0.5rem
		display: flex;
		width 100%
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.5rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 3
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
