<template>
	<div class="ligand-viewer-3d" :id="id">
		<div class="ligand-viewer-3d--left-controls">
			<div
				v-for="option in styleOptions"
				:key="`options-left-key-${option.id}`"
				class="ligand-viewer-3d--controls-item"
				@click="handleStyleOptionClick(option)"
			>
				<bRadio :modelValue="option.isSelected" size="small" />
				<div class="footnoteBold">{{ option.label }}</div>
			</div>
		</div>

		<div class="ligand-viewer-3d--right-controls">
			<div
				v-for="option in viewOptions"
				:key="`options-right-key-${option.id}`"
				class="ligand-viewer-3d--controls-item"
				@click="handleViewOptionClick(option)"
			>
				<bToggle :modelValue="option.isSelected" size="small" />

				<div class="footnoteBold">{{ option.label }}</div>
			</div>
		</div>

		<div class="ligand-viewer-3d--scene-controls">
			<Ligand3DControls
				@on-click-reset="onClickReset()"
				@on-click-zoom-in="viewer?.zoomIn()"
				@on-click-zoom-out="viewer?.zoomOut()"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { TDMoleculeViewer } from '@/shared/lib';
import { bRadio, bToggle } from '@/shared/uikit';
import { defineProps, onMounted, onUnmounted, ref } from 'vue';
import Ligand3DControls from './Ligand3DControls.vue';

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	sdfData: {
		type: String,
		required: true,
	},
});
let viewer = ref<TDMoleculeViewer | null>(null);

interface OptionType {
	id: number;
	label: string;
	isSelected: boolean;
}

const styleOptions = ref<OptionType[]>([
	{
		id: 0,
		label: 'Ball and Stick',
		isSelected: false,
	},
	{
		id: 1,
		label: 'Sticks',
		isSelected: true,
	},
	{
		id: 2,
		label: 'Cross',
		isSelected: false,
	},
	{
		id: 3,
		label: 'Wire-Frame',
		isSelected: false,
	},
	{
		id: 4,
		label: 'Space-Filling',
		isSelected: false,
	},
]);

const viewOptions = ref<OptionType[]>([
	{
		id: 0,
		label: 'Shape Similarity',
		isSelected: false,
	},
	{
		id: 1,
		label: 'Hydrogens',
		isSelected: false,
	},
]);

onMounted(() => {
	viewer.value = new TDMoleculeViewer(props.id, props.sdfData);
});

onUnmounted(() => {
	if (viewer) {
		viewer.value?.reset();
		viewer.value = null;
	}
});

const handleStyleOptionClick = (option: OptionType) => {
	styleOptions.value.forEach((item) => {
		if (item.id === option.id) {
			item.isSelected = true;
		} else {
			item.isSelected = false;
		}
	});

	switch (option.id) {
		case 0:
			viewer.value?.setBallAndStick();
			break;
		case 1:
			viewer.value?.setSticks();
			break;
		case 2:
			viewer.value?.setCross();
			break;
		case 3:
			viewer.value?.setWireFrame();
			break;
		case 4:
			viewer.value?.setSpaceFilling();
			break;
		default:
			break;
	}
};

const handleViewOptionClick = (option: OptionType) => {
	option.isSelected = !option.isSelected;

	switch (option.id) {
		case 0:
			viewer.value?.toggleShapeSimilarity();
			break;
		case 1:
			viewer.value?.toggleHydrogens();
			break;
		default:
			break;
	}
};

const onClickReset = () => {
	viewer.value?.reset();
	styleOptions.value.forEach((option) => {
		option.isSelected = false;
		if (option.id === 1) {
			option.isSelected = true;
		}
	});

	viewOptions.value.forEach((option) => {
		option.isSelected = false;
	});
};
</script>

<style lang="stylus" scoped>
.ligand-viewer-3d
	position relative
	width 100%
	height 400px
	.ligand-viewer-3d--controls-item
		width 100%
		display flex
		flex-direction row
		justify-content flex-start
		gap 0.5rem
		cursor pointer
		padding 0.5rem
		border-radius 0.25rem
		user-select none
		&:hover
			background var(--neutral-50)
	.ligand-viewer-3d--left-controls
		position absolute
		padding 0.5rem
		top 1rem
		left 1rem
		z-index 1
		display flex
		flex-direction column
		justify-content center
		align-items flex-start
		gap 0.5rem
		border: 1px solid var(--neutral-100);
		background: #fff;
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		border-radius: 0.5rem;
	.ligand-viewer-3d--right-controls
		position absolute
		padding 0.5rem
		top 1rem
		right 1rem
		z-index 1
		display flex
		flex-direction column
		justify-content center
		align-items flex-start
		gap 0.5rem
		border: 1px solid var(--neutral-100);
		background: #fff;
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		border-radius: 0.5rem;

	.ligand-viewer-3d--scene-controls
		position absolute
		bottom 1rem
		right 1rem
		z-index 1
</style>
