import { GLViewer, SurfaceType, type ViewerSpec } from '3dmol';
import OCL from 'openchemlib';

// 3D Viewer for molecules
export class TDMoleculeViewer {
	private viewer: GLViewer;
	private sdfData: string;
	private sdfDataWithHs: string = '';
	private parserOptions: ViewerSpec = {
		backgroundColor: 'white',
	};
	private hydrogenToggled: boolean = false;
	private shapeSimilarityToggled: boolean = false;

	constructor(containerId: string, sdfData: string) {
		this.sdfData = sdfData;
		this.parserOptions.id = `${containerId}-viewer`;

		const container = document.getElementById(containerId);
		if (!container) {
			throw new Error(`Container with id ${containerId} not found`);
		}

		this.viewer = new GLViewer(container, this.parserOptions);

		this.viewer.addModel(this.sdfData, 'sdf', this.parserOptions);
		this.viewer.setStyle({}, { stick: {} });
		this.viewer.zoomTo();
		this.render();
	}

	setBallAndStick() {
		this.viewer.setStyle(
			{},
			{ sphere: { scale: 0.3 }, stick: { radius: 0.2 } }
		);
		this.render();
	}

	setSticks() {
		this.viewer.setStyle({}, { stick: { radius: 0.2 } });
		this.render();
	}

	setWireFrame() {
		this.viewer.setStyle({}, { line: { linewidth: 0.1 } });
		this.render();
	}

	setSpaceFilling() {
		this.viewer.setStyle({}, { sphere: { scale: 1.0 } });
		this.render();
	}

	toggleShapeSimilarity() {
		if (!this.shapeSimilarityToggled) {
			this.addShapeSimilarity();
		} else {
			this.removeShapeSimilarity();
		}
		this.shapeSimilarityToggled = !this.shapeSimilarityToggled;
		this.render();
	}

	async toggleHydrogens() {
		// console.log('toggleHydrogens');

		// Parse SDF data using openchemlib
		const mol = OCL.Molecule.fromMolfile(this.sdfData);
		if (!mol) {
			console.error('Failed to parse SDF data.');
			return;
		}

		if (!this.hydrogenToggled) {
			// Add hydrogens
			mol.addImplicitHydrogens();
			this.sdfDataWithHs = mol.toMolfile();
			this.viewer.clear();
			this.viewer.addModel(this.sdfDataWithHs, 'sdf', this.parserOptions);
			this.viewer.setStyle({}, { stick: {} });
			this.viewer.zoomTo();
			this.hydrogenToggled = true;
		} else {
			this.reset();
			this.hydrogenToggled = false;
		}

		if (this.shapeSimilarityToggled) {
			this.addShapeSimilarity();
		}

		this.render();
	}
	setCross() {
		this.viewer.setStyle({}, { cross: { radius: 0.5 } });
		this.render();
	}

	zoomOut() {
		this.viewer.zoom(0.9);
		this.render();
	}

	zoomIn() {
		this.viewer.zoom(1.1);
		this.render();
	}

	render() {
		this.viewer.render();
	}

	reset() {
		this.viewer.clear();
		this.viewer.addModel(this.sdfData, 'sdf', this.parserOptions);
		this.viewer.setStyle({}, { stick: {} });
		this.viewer.zoomTo();
		this.render();
	}

	setPharmacophore() {
		// viewer.addModel(props.sdfData, 'sdf', parserOptions); // Добавляем исходную модель
		// const s1 = viewer.addSphere({
		// 	radius: 1.25,
		// 	center: { x: -6.89, y: 0.75, z: 0.35 },
		// });
		// s1.wireframe = true;
		// s1.color = window.$3Dmol.CC.color('purple');
		// viewer.addLabel('Aromatic', {
		// 	position: { x: -6.89, y: 0.75, z: 0.35 },
		// 	backgroundColor: 0x800080,
		// 	backgroundOpacity: 0.8,
		// });
		// const s3 = viewer.addSphere({
		// 	radius: 0.5,
		// 	center: { x: -0.42, y: -0.48, z: 0.64 },
		// });
		// s3.wireframe = true;
		// s3.color = window.$3Dmol.CC.color(0xbbbbbb);
		// const a = viewer.addArrow({
		// 	start: { x: -0.4217, y: -0.4795, z: 0.6395 },
		// 	end: { x: -0.613, y: -0.891, z: -0.198 },
		// 	radius: 0.1,
		// });
		// viewer.addLabel('Hydrogen Donor', {
		// 	position: { x: -0.4217, y: -0.4795, z: 0.6395 },
		// 	backgroundColor: 0x808080,
		// 	backgroundOpacity: 0.8,
		// });
		// a.color = window.$3Dmol.CC.color(0xeeeeee);
		// viewer.render();
	}

	private addShapeSimilarity() {
		this.viewer.addSurface(SurfaceType.VDW, {
			color: 'blue',
			opacity: 0.7,
		});
	}

	private removeShapeSimilarity() {
		this.viewer.removeAllSurfaces();
	}
}
