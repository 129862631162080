<template>
	<div
		:style="{ opacity: isInited ? 1 : 0, zIndex: isInited ? 1 : 0 }"
		class="svg-pipeline-widget"
		@drop="onDrop"
		@dragover="onDragOver"
	>
		<svg id="svg-pipeline"></svg>
		<MiniMapWidget
			v-if="dashboard && isInited"
			:dashboard="dashboard"
			:width="dashboardWidth / 45"
			:height="dashboardHeight / 45"
		/>
		<PipelineChat :isOpenedTools="isOpenedTools" @onGenerate="onGenerate" />
		<PipelineToolsDrawer v-model="isOpenedTools" @onAddTool="onAddTool" />

		<template v-if="isVisibleControls">
			<PipelineExecuteButton
				v-if="isLinear"
				:isLoading="isLoading"
				@executePipeline="executePipeline"
			/>

			<PipelineClearButton @onClick="onClear" />
		</template>
	</div>
	<PipelineTemplateView
		:style="{ opacity: isInited ? 0 : 1, zIndex: isInited ? 0 : 1 }"
		@on-click-application="selectTemplate"
		@on-click-manual-create-pipeline="selectTemplate([])"
	/>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	PipelineChat,
	PipelineClearButton,
	PipelineExecuteButton,
	PipelineToolsDrawer,
} from '@/features/pipeline';
import { ForeignObjectEntity, StepType } from '@/shared/api';
import { ZoomableSVG, generateUuid } from '@/shared/lib';
import { DashboardElement } from '@/shared/lib/svg/entities';
import {
	AttributeFilterCard,
	CatalogLoaderCard,
	DockingCard,
	LigandBasedSearchCard,
	MiniMapWidget,
	NoveltyFilterCard,
	PipelineTemplateView,
	TargetBasedSearchCard,
} from '@/widgets/pipeline';
import { computed, onBeforeUnmount, onMounted, ref, toRaw } from 'vue';
import { pipelineModel } from './model';

const {
	createJobBySteps,
	jobSteps,
	steps,
	fetchJobPipeline,
	isLoading,
	dashboard,
	isInited,
	updateCallback,
} = pipelineModel;

const dashboardWidth = 10000;
const dashboardHeight = 10000;

const isOpenedTools = ref<boolean>(false);

const isVisibleControls = computed(() => {
	return dashboard.value?.elementManager?.elements.length ?? 0 > 0;
});

const isLinear = computed(() => {
	return dashboard.value?.elementManager.isLinear ?? false;
});

onBeforeUnmount(() => {
	dashboard.value?.destroyEventListeners();
	steps.value = toRaw(dashboard.value?.elementManager?.elements ?? []);
});

onMounted(async () => {
	if (router.currentRoute.value.name === 'job-pipeline') {
		isInited.value = true;
	}

	if (!isInited.value) {
		return;
	}

	dashboard.value = new ZoomableSVG(
		'svg-pipeline',
		dashboardWidth,
		dashboardHeight,
		updateCallback
	);

	if (router.currentRoute.value.name === 'job-pipeline') {
		const { id } = router.currentRoute.value.params;
		await fetchJobPipeline(id as string);
		onGenerate(jobSteps.value);
	} else {
		initPreviewsView();
	}
});

const onGenerate = (results: ForeignObjectEntity[]) => {
	if (!dashboard.value) {
		return;
	}

	dashboard.value.clear();

	const elements = toRaw(results).map((step) => {
		return DashboardElement.fromObject({
			id: step.id,
			data: step,
			vueComponent: getCardByStepType(step.type),
			x: 0,
			y: 0,
			width: step.width,
			height: step.height,
			isSelected: false,
		});
	});

	dashboard.value.setElements(toRaw(elements));
};

const onDrop = (event: DragEvent) => {
	event.preventDefault();
	const dataTransfer = event.dataTransfer;
	if (dataTransfer) {
		const tool = JSON.parse(dataTransfer.getData('tool') || '{}');
		const x = event.offsetX;
		const y = event.offsetY;

		const element = DashboardElement.fromObject({
			id: generateUuid(),
			data: tool,
			vueComponent: getCardByStepType(tool.type),
			x,
			y,
			width: tool.width,
			height: tool.height,
			isSelected: false,
		});
		dashboard.value?.addElement(element);
	}
};

const onAddTool = (tool: ForeignObjectEntity) => {
	const element = DashboardElement.fromObject({
		id: generateUuid(),
		data: tool,
		vueComponent: getCardByStepType(tool.type),
		x: 0,
		y: 0,
		width: tool.width,
		height: tool.height,
		isSelected: false,
	});

	dashboard.value?.addElement(element, true);
};

const onDragOver = (event: DragEvent) => {
	event.preventDefault();
};

const onClear = () => {
	if (!dashboard.value) {
		return;
	}

	dashboard?.value.clear();
};

const getCardByStepType = (stepType: StepType) => {
	switch (stepType) {
		case StepType.CATALOG_LOADER:
			return CatalogLoaderCard;
		case StepType.DOCKING:
			return DockingCard;
		case StepType.LIGAND_BASED_SEARCH:
			return LigandBasedSearchCard;
		case StepType.TARGET_BASED_SEARCH:
			return TargetBasedSearchCard;
		case StepType.NOVELTY_FILTER:
			return NoveltyFilterCard;
		case StepType.ATTRIBUTE_FILTER:
			return AttributeFilterCard;
		default:
			return CatalogLoaderCard;
	}
};

const executePipeline = () => {
	const items = toRaw(dashboard.value?.elementManager?.elements ?? []).map(
		(element) => toRaw(element.data)
	);

	createJobBySteps(items);
};

const selectTemplate = (steps: ForeignObjectEntity[]) => {
	isInited.value = true;
	dashboard.value = new ZoomableSVG(
		'svg-pipeline',
		12000,
		12000,
		updateCallback
	);
	onGenerate(steps);
};

const initPreviewsView = () => {
	if (!dashboard.value) {
		return;
	}

	const elements = steps.value.map((step) => {
		return DashboardElement.fromObject({
			id: step.id,
			data: step.data,
			vueComponent: getCardByStepType(step.data.type),
			x: step.x,
			y: step.y,
			width: step.width,
			height: step.height,
			isSelected: false,
		});
	});

	dashboard.value?.elementManager.updateElements(elements);
};
</script>

<style scoped>
.svg-pipeline-widget {
	width: 100%;
	height: calc(100vh - 6.25rem);
	position: relative;
}

svg {
	display: block;
	width: 100%;
	height: 100%;
	cursor: grab;
	&:active {
		cursor: grabbing;
	}
}

.draggable {
	cursor: move;
}

.connection-path {
	cursor: pointer !important;
}
</style>
