<template>
	<div
		class="job-cluster-filters-widget--header"
		:class="{ 'job-cluster-filters-widget--header--shadow': isShadow }"
	>
		<section>
			<div class="calloutRegular">Filters</div>
			<div class="footnoteRegular">
				{{ currentCount }} out of {{ total }}
			</div>
		</section>

		<bTooltip
			id="reset-filters-tooltip"
			hoverTitle="Reset filters"
			type="primary"
		>
			<template v-slot:body>
				<bUnstyledButton @onClick="onResetFilters">
					<template v-slot:content>
						<IconReplay size="1.25rem" />
					</template>
				</bUnstyledButton>
			</template>
		</bTooltip>
	</div>
</template>

<script setup lang="ts">
import { IconReplay, bTooltip, bUnstyledButton } from '@/shared/uikit';

const emit = defineEmits(['onResetFilters']);

const props = defineProps({
	isShadow: {
		type: Boolean,
		required: true,
	},
	total: {
		type: Number,
		required: true,
	},
	currentCount: {
		type: Number,
		required: true,
	},
});

const onResetFilters = () => {
	emit('onResetFilters');
};
</script>

<style lang="stylus" scoped>
.job-cluster-filters-widget--header
	position absolute
	width 100%
	padding 1rem 0.5rem 1rem 1.5rem
	display grid
	grid-template-columns 1fr auto
	align-items center
	z-index 10
	background white
	&--shadow
		border-bottom-left-radius 1rem
		border-bottom-right-radius 1rem
		box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05);
	section
		display flex
		flex-direction column
		align-items flex-start
		justify-content flex-start
	.footnoteRegular
		color var(--neutral-600)
	.b-unstyled-button
		padding 0.5rem
		border-radius 0.5rem
</style>
