import { Analytics, AnalyticsEvents } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { computed, reactive } from 'vue';

interface IViewModel {
	user: any;
}

const data: IViewModel = {
	user: null,
};

const state = reactive(data);

async function logout() {
	const provider = new EmailAuthProvider();
	const authService = AuthService.getInstance(provider);
	await authService.logout();
	window.location.reload();
	localStorage.removeItem('auth_token');
	localStorage.removeItem('currentUser');
	Analytics.send(AnalyticsEvents.Logout.LOGOUT);
}

async function fetchUser() {
	try {
		const provider = new EmailAuthProvider();
		const user = await AuthService.getInstance(provider).getUser();
		state.user = user;
	} catch (error) {
		ocularRestErrorHandler(error);
	}
}

const userEmail = computed(() => {
	return state.user?.email ?? '';
});

export const logoutButtonModel = {
	logout,
	fetchUser,
	userEmail,
};
