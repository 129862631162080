export namespace Endpoints {
	export enum Users {
		ME = '/users/me',
		CREATE = '/users',
		PAYMENT = '/users/me/payment',
		REDEEM = '/users/me/redeem',
		ACTIVATE = '/users/me/activate',
	}

	export namespace Jobs {
		export const GET = (id: string) => `/jobs/${id}`;
		export const GET_ATTRIBUTES = (id: string) => `/jobs/${id}/attributes`;
		export const GET_HITS = (id: string) => `/jobs/${id}/hits`;
		export const GET_CLUSTERS = (id: string) => `/jobs/${id}/clusters`;
		export const GET_CLUSTER = (jobId: string, clusterId: string) =>
			`/jobs/${jobId}/clusters/${clusterId}`;

		export const DOWNLOAD = (id: string) => `/jobs/${id}/download-url`;
		export const FETCH_ALL = '/jobs';
		export const CREATE = '/jobs';

		export const DELETE = (id: string) => `/jobs/${id}`;
		export const CREATE_BY_STEPS = '/ai-scientist/jobs';
	}

	export namespace Collections {
		export const FETCH_ALL = '/collections';
		export const GET = (id: string) => `/collections/${id}`;
		export const FETCH_ITEMS = (id: string) =>
			`/collections/${id}/collectibles`;

		export const PATCH = (id: string) => `/collections/${id}`;
		export const CREATE = '/collections';
		export const DELETE = (id: string) => `/collections/${id}`;
		export const DOWNLOAD = (id: string) => `/collections/${id}/download`;
	}

	export namespace Catalogs {
		export const GET = '/catalogs';

		export const GET_BY_ID = (catalog_id: string) =>
			`/catalogs/${catalog_id}`;

		export const GET_ATTRIBUTES = (catalog_id: string) =>
			`/catalogs/${catalog_id}/attributes`;

		export const FETCH_MOLECULE = (index_id: string, molecule_id: string) =>
			`/indices/${index_id}/items/${molecule_id}`;
	}

	export enum History {
		GET = '/history',
		DELETE = '/history',
	}

	export enum Order {
		CREATE = '/orders',
	}

	export namespace Targets {
		export const GET = '/targets';

		export const GET_PROBES = (id: string) => `/targets/${id}/probes`;
		export const GET_ATTRIBUTES = (id: string) =>
			`/targets/${id}/attributes`;
	}

	export namespace Chats {
		export const FETCH_ALL = '/chats';

		export const CREATE = '/chats';
		export const FETCH = (id: string) => `/chats/${id}`;
		export const DELETE = (id: string) => `/chats/${id}`;
		export const DELETE_ALL = '/chats';
		export const UPLOAD_FILE = '/uploads';
	}
	export namespace Pipeline {
		export const FETCH_PIPELINE = (id: string) =>
			`/ai-scientist/jobs/${id}`;
	}
}
