<template>
	<div class="attribute-filter-container">
		<div class="attribute-filter">
			<div class="bodySmallSemiBold">
				{{ getSearchAttributeTypeLabel(type) }}
				<bChip
					:label="`Min: ${value[0]} - Max: ${value[1]}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>

			<div class="attribute-filter--input">
				<Slider
					v-model="value"
					:min="minimum"
					:max="maximum"
					:step="byStep"
					:format="{
						decimals: byStep === -1 ? 2 : 0,
					}"
					@change="sliderUpdate"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { SearchAttributeType, getSearchAttributeTypeLabel } from '@/shared/api';
import { bChip } from '@/shared/uikit';
import Slider from '@vueform/slider';
import { computed, onMounted, ref, type PropType } from 'vue';

const emit = defineEmits(['update:modelValue']);
const value = ref<number[]>([0, 0]);

const minimum = ref<number>(0);
const maximum = ref<number>(0);

const props = defineProps({
	type: {
		type: String as PropType<SearchAttributeType>,
		required: true,
	},
	max: {
		type: Number,
		required: true,
	},
	min: {
		type: Number,
		required: true,
	},
});

onMounted(() => {
	value.value = [props.min, props.max];
	minimum.value = props.min;
	maximum.value = props.max;
});

const byStep = computed(() => {
	return props.type === SearchAttributeType.WEIGHT ||
		props.type === SearchAttributeType.HBA ||
		props.type === SearchAttributeType.HBD
		? 1
		: -1;
});

const handleUpdate = (value: number[]) => {
	emit('update:modelValue', value);
};

const sliderUpdate = (value: number[]) => {
	value = value;
	handleUpdate(value);
};
</script>

<style lang="stylus" scoped>
.attribute-filter-container
	width 100%
	background white
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
	.attribute-filter
		position relative
		display flex
		flex-direction column
		gap 0.25rem
		padding 0.75rem 1rem
		border 1px solid var(--neutral-200)
		border-radius 0.75rem
		.bodySmallSemiBold
			width 100%
			display flex
			flex-direction row
			align-items center
			justify-content space-between
			.b-chip
				text-transform none

		.attribute-filter--input
			position absolute
			bottom -0.2rem
			left 0.5rem
			right 0.5rem
</style>

<style lang="stylus">

.slider-touch-area
	width 15px !important
	height 15px !important
:root {
    --slider-handle-width: 15px;
    --slider-handle-height: 15px;
}
</style>
