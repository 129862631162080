<template>
	<div class="target-job-card" @click="onShowPreview">
		<div v-if="!job.is_viewed" class="target-job-card-notification"></div>

		<div class="target-job-card-progress" :style="progressStyle"></div>
		<div class="target-job-card--header">
			<section>
				<div class="bodySmallRegular">Results for</div>
				<div class="subheadlineSemiBold">
					{{
						(props.job.parameters as TargetJobParameters).target
							.name
					}}
				</div>
			</section>
			<div>
				<bChip
					:label="job.statusTitle"
					:isGhost="false"
					:type="job.statusColor"
					size="small"
				/>
			</div>
		</div>
		<div class="target-job-card--details">
			<div class="target-job-card--details--item">
				<IconDNS size="1.5rem" :color="job.catalog.color" />
				<section>
					<div
						class="bodySmallRegular"
						:style="{ color: job.catalog.color }"
					>
						Search base
					</div>
					<div
						class="subheadlineSemiBold"
						:style="{ color: job.catalog.color }"
					>
						{{ job.catalog.name }}
					</div>
				</section>
			</div>
			<div class="target-job-card--details--item">
				<IconInbox size="1.5rem" :color="ThemeColors.neutral[800]" />
				<section>
					<div class="bodySmallRegular">Number of molecules</div>
					<div class="subheadlineSemiBold">
						{{ toStringBy(job.catalog.size) }}
					</div>
				</section>
			</div>
			<div class="target-job-card--details--item">
				<IconCalendar size="1.5rem" :color="ThemeColors.neutral[800]" />
				<section>
					<div class="bodySmallRegular">Search date</div>
					<div class="subheadlineSemiBold">
						{{ job.formattedCreatedAt }}
					</div>
				</section>
			</div>
		</div>

		<div
			v-if="job.state === JobState.SUCCESS"
			class="target-job-card--footer"
		>
			<div class="target-job-card--footer-left-controls">
				<DownloadSearchResultsButton :searchId="job.id" />

				<bButton
					label="Preview results"
					type="primary"
					:isGhost="true"
					@onClick="onShowPreview"
				/>
				<bButton
					label="Show pipeline"
					type="primary"
					:isGhost="true"
					@onClick="showPipieline"
				/>

				<TargetLinkButton
					:data="
						(job.parameters as TargetJobParameters).target
							.external_url
					"
					:id="'probe-' + job.id"
				/>
				<!-- <ProteinLinkButton :id="props.job.query" :elementId="job.id" /> -->
			</div>

			<DeleteJobButton :jobId="job.id" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { DeleteJobButton, DownloadSearchResultsButton } from '@/features/job/';
import { TargetLinkButton } from '@/features/target-driven-search/';
import {
	ActiveJobEntity,
	JobEntity,
	JobState,
	TargetJobParameters,
} from '@/shared/api/';
import { toStringBy } from '@/shared/lib/';
import {
	IconCalendar,
	IconDNS,
	IconInbox,
	ThemeColors,
	bButton,
	bChip,
} from '@/shared/uikit';
import { computed, type PropType } from 'vue';

const emit = defineEmits(['onDownload', 'onShowPreview']);

const props = defineProps({
	job: {
		type: Object as PropType<JobEntity>,
		required: true,
	},
	isDownloading: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const progressStyle = computed(() => {
	if (props.job instanceof ActiveJobEntity) {
		const width = Math.floor(props.job.progress * 100);
		return {
			width: `${width > 8 ? width : 0}%`,
		};
	}

	return {
		width: '100%',
	};
});

const onShowPreview = () => {
	emit('onShowPreview', props.job);
};

const showPipieline = () => {
	router.push(`/pipeline/${props.job.id}`);
	Analytics.send(AnalyticsEvents.PIPELINE.CLICK_OPEN_PIPELINE);
};
</script>

<style lang="stylus" scoped>
.target-job-card
	position relative
	width 100%
	padding 0.75rem
	border-radius 1.75rem
	background var(--neutral-50)
	display flex
	flex-direction column
	align-items flex-start
	justify-content flex-start
	gap 0.75rem
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	cursor pointer
	.target-job-card-notification
		position absolute
		top -0.25rem
		right -0.25rem
		width 1rem
		height 1rem
		border-radius 50%
		background var(--danger-600)
		transition all 0.25s ease
		z-index 1
		animation: pulse 1s infinite

	&--header
		padding 0.75rem
		width 100%
		display grid
		grid-template-columns minmax(0, 1fr) auto
		gap 0.31rem
		z-index 1
		section
			width calc(100% - 1rem)
		.bodySmallRegular
			color var(--neutral-600)
			letter-spacing: 0.025rem;
		.subheadlineSemiBold
			color var(--neutral-800)
			letter-spacing: 0.00625rem
			white-space nowrap
			overflow hidden
			text-overflow ellipsis
			width 100%
	&--body
		width 100%
		background white
		padding 0.75rem
		height 20.25rem
		border-radius 1rem
		display flex
		flex-direction column
		align-items center
		justify-content center
		border 1px solid var(--neutral-200)
		z-index 1
	&--details
		width 100%
		display grid
		grid-template-columns repeat(3, minmax(0, 1fr))
		align-items center
		justify-content flex-start
		gap 0.75rem
		padding 0.75rem
		z-index 1
		&--item
			width 100%
			display flex
			flex-direction row
			align-items center
			justify-content flex-start
			gap 1.25rem
			.bodySmallRegular
				color var(--neutral-600)
				letter-spacing: 0.025rem;
			.subheadlineSemiBold
				color var(--neutral-800)
				letter-spacing: 0.00625rem
	&--footer
		padding 0.75rem
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		gap 0.75rem
		z-index 1
		.target-job-card--footer-left-controls
			display flex
			flex-direction row
			align-items center
			justify-content flex-start
			gap 0.75rem
		.b-unstyled-button
			width 2.5rem
			height 2.5rem
			padding 0.5rem
			border-radius 50%
	.target-job-card-progress
		position absolute
		top 0
		left 0
		bottom 0
		width 0%
		background var(--neutral-100)
		border-radius 1.75rem

@keyframes pulse
	0%
		transform: scale(1)
	50%
		transform: scale(1.1)
	100%
		transform: scale(1)
</style>
