<template>
	<nav class="applications-view">
		<ApplicationButton
			v-for="app in applications"
			:key="`application-button-${app.id}`"
			:value="app"
			@onClick="onClickApplication(app)"
		/>
	</nav>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { cartModel } from '@/features/cart';
import { ApplicationButton } from '@/features/home';
import type { ApplicationButtonProps } from '@/features/home/application-button/ui.vue';
import { IconLigandSearch, IconStructureSearch } from '@/shared/uikit';
import { createLBSSModel } from '@/widgets/ligand-based-search';
import { markRaw, ref } from 'vue';

const applications = ref<ApplicationButtonProps[]>([
	{
		id: 1,
		name: 'Ligand Based Search',
		desc: 'Search based on your HIT',
		icon: markRaw(IconLigandSearch),
		isDisabled: false,
		isNew: false,
		isBeta: false,
	},
	{
		id: 3,
		name: 'Fingerprint-based search',
		desc: 'Tanimoto similarity',
		icon: markRaw(IconStructureSearch),
		isDisabled: false,
		isNew: true,
		isBeta: false,
	},
]);

const onClickApplication = (item: ApplicationButtonProps) => {
	const { isVisible, isStructureSearch, smiles } = createLBSSModel;

	switch (item.id) {
		case 1:
			isVisible.value = true;
			break;
		case 3:
			isVisible.value = true;
			isStructureSearch.value = true;
			break;
		default:
			break;
	}

	const { hits } = cartModel;

	if (hits.value.length > 0) {
		smiles.value = hits.value.map((hit) => hit.smiles);
	}

	Analytics.send(AnalyticsEvents.Home.CLICK_APPLICATION_BY_SEARCH_TYPE, {
		name: item.name,
		desc: item.desc,
	});
};
</script>

<style lang="stylus" scoped>
.applications-view
	display flex
	flex-flow row wrap
	justify-content center
	align-items center
	gap 0.75rem
	max-width 80%
</style>
