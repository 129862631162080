<template>
	<div
		class="b-password-contriner"
		:class="{
			'b-password-contriner--success': isValid,
			'b-password-contriner--error': !isValid && modelValue !== '',
		}"
	>
		<bSimpleInput
			:modelValue="modelValue"
			size="large"
			placeholder="Password"
			:type="isShowPassword ? 'text' : 'password'"
			@update:modelValue="(value) => $emit('update:modelValue', value)"
		/>
		<div class="b-password-requirements">
			<bChip
				v-for="requirement in requirements"
				:label="requirement.label"
				:type="requirement.isValid ? 'primary' : 'neutral'"
				:isGhost="requirement.isValid"
			>
				<template v-if="requirement.isValid" v-slot:left-icon>
					<IconCheck
						size="1.125rem"
						:color="ThemeColors.primary[600]"
						style="margin-left: -0.5rem"
					/>
				</template>
			</bChip>
		</div>

		<div class="b-password-input-eye">
			<IconEyeClose
				v-if="isShowPassword"
				@click="isShowPassword = false"
			/>
			<IconEye v-else @click="isShowPassword = true" />
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	IconCheck,
	IconEye,
	IconEyeClose,
	ThemeColors,
	bChip,
	bSimpleInput,
} from '@/shared/uikit';
import { computed, ref } from 'vue';

const isShowPassword = ref<boolean>(false);

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: {
		type: String,
		required: true,
	},
});

const isValid = computed(() => {
	return requirements.value.every((requirement) => requirement.isValid);
});

const requirements = computed(() => {
	return [
		{
			label: 'Numbers',
			isValid: /\d/.test(props.modelValue),
		},
		{
			label: 'Lowercase letter',
			isValid: /[a-z]/.test(props.modelValue),
		},
		{
			label: 'Uppercase letter',
			isValid: /[A-Z]/.test(props.modelValue),
		},
		{
			label: '8 characters',
			isValid: props.modelValue.length > 8,
		},
	];
});
</script>

<style lang="stylus">
.b-password-contriner
	position relative
	width 100%
	display flex
	flex-direction column
	gap 0.5rem
	.b-password-input-eye
		width 3rem
		height 3rem
		position absolute
		right 0
		top 0.25rem
		display flex
		align-items center
		justify-content center
		cursor pointer
		transition all 0.2s ease-in-out
		&:hover
			opacity 0.9
			transition all 0.2s ease-in-out
	&--success
		.b-simple-text-input
			.b-text-input-container
				.b-text-input
					border-color var(--success-600) !important
	&--error
		.b-simple-text-input
			.b-text-input-container
				.b-text-input
					border-color var(--danger-600) !important
	.b-password-requirements
		width 100%
		display flex
		flex-flow row wrap
		gap 0.5rem
</style>
