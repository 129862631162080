import { router } from '@/app/providers';
import { HitEntity } from '@/shared/api';
import { IndexedDBHitsInstance } from '@/shared/api/client';
import { ocularRestErrorHandler, saveAs } from '@/shared/lib';
import { hitsToCSV } from '@/shared/lib/csv';
import { cartListModel } from '@/widgets/cart';
import { clusterResultsListModel } from '@/widgets/cluster';
import { jobResultsListModel } from '@/widgets/job';
import { reactive, toRaw, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	hits: HitEntity[];
}

const data: IViewModel = {
	isLoading: false,
	hits: [],
};

const state = reactive(data);

async function fetchSelectedHits() {
	state.isLoading = true;
	try {
		const dbHits = await IndexedDBHitsInstance.get();
		state.hits = await dbHits.getAll();
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

async function clearCart() {
	state.isLoading = true;
	try {
		const dbHits = await IndexedDBHitsInstance.get();
		await dbHits.clear();
		state.hits = [];

		if (router.currentRoute.value.name === 'cluster-details') {
			const { hits } = clusterResultsListModel;
			hits.value.forEach((hit) => {
				hit.isFavorite = false;
			});
		} else if (router.currentRoute.value.name === 'job-details') {
			const { hits } = jobResultsListModel;
			hits.value.forEach((hit) => {
				hit.isFavorite = false;
			});
		} else if (router.currentRoute.value.name === 'cart-details') {
			const { fetchCartDetails } = cartListModel;
			fetchCartDetails();
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

async function exportSelectedHits() {
	state.isLoading = true;

	try {
		const csv = hitsToCSV(toRaw(state.hits));
		saveAs(csv, 'molecules.csv');
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}
export const cartModel = {
	...toRefs(state),
	fetchSelectedHits,
	clearCart,
	exportSelectedHits,
};
