<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 31 30"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.3714 13.2644C10.073 11.8903 9.91584 10.4635 9.91584 9V1H29.9158V29C25.2962 29 21.0423 27.4337 17.6564 24.8033C14.3865 27.3414 8.81409 29.9675 2 28.6269C3.7581 27.8115 10.4925 22.9574 10.3714 13.2644Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
