import { router } from '@/app/providers';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { IndexedDBNavigationTabsInstance } from '@/shared/api/client';
import { reactive, toRaw, toRefs } from 'vue';

interface IViewModel {
	tabs: NavigationTabEntity[];
}
const data: IViewModel = {
	tabs: [],
};

const state = reactive(data);

async function removeTab(item: NavigationTabEntity) {
	if (router.currentRoute.value.path === item.path) {
		if (state.tabs.length === 1) {
			router.push('/dashboard');
		} else {
			const index = state.tabs.findIndex((tab) => tab.id === item.id);
			const nextTab = state.tabs[index + 1] || state.tabs[index - 1];
			router.push(nextTab.path);
		}
	}
	try {
		const dbCluster = await IndexedDBNavigationTabsInstance.get();
		await dbCluster.delete(item.id);
		state.tabs = state.tabs.filter((tab) => tab.id !== item.id);

		state.tabs.forEach((tab, index) => {
			tab.index = index;
		});

		const promises: Promise<void>[] = state.tabs.map((tab) =>
			dbCluster.put(toRaw(tab))
		);
		await Promise.all(promises);
	} catch (error) {
		console.error('Failed to remove tab:', error);
	}
}

async function addTab(item: any) {
	const existing = state.tabs.find((tab) => tab.id === item.id);

	if (existing && router.currentRoute.value.path === item.path) {
		return;
	}

	try {
		const dbCluster = await IndexedDBNavigationTabsInstance.get();

		const existingNewTab = state.tabs.find(
			(tab) => tab.type === NavigationTabType.NewTab
		);

		if (existingNewTab) {
			state.tabs = state.tabs.filter(
				(tab) => tab.id !== existingNewTab.id
			);
			await dbCluster.delete(existingNewTab.id);
		}

		if (!existing) {
			const newTab = NavigationTabEntity.fromObject({
				...item,
				index: state.tabs.length,
			});

			await dbCluster.add(newTab);
			state.tabs.push(newTab);

			state.tabs.forEach((tab, index) => {
				tab.index = index;
			});
		}

		router.push(item.path);
	} catch (error) {
		console.error('Failed to add tab:', error);
	}
}
async function init() {
	try {
		const dbCluster = await IndexedDBNavigationTabsInstance.get();
		const tabs = await dbCluster.getAll();
		state.tabs = tabs.sort((a, b) => a.index - b.index);
	} catch (error) {
		console.error('Failed to initialize tabs:', error);
	}
}

async function removeCurrentTab() {
	const currentTab = state.tabs.find(
		(tab) => tab.path === router.currentRoute.value.path
	);
	if (currentTab) {
		await removeTab(currentTab);
	}
}

async function removeTabById(id: string) {
	const currentTab = state.tabs.find((tab) => tab.id === id);
	if (currentTab) {
		await removeTab(currentTab);
	}
}

async function addTabIfNotExists(item: NavigationTabEntity) {
	const existing = state.tabs.find((tab) => tab.id === item.id);
	if (!existing) {
		await addTab(item);
	}
}

async function moveTab(droppedTab: any, targetTab: any) {
	const droppedIndex = state.tabs.findIndex((t) => t.id === droppedTab.id);
	const targetIndex = state.tabs.findIndex((t) => t.id === targetTab.id);

	// Check if either tab is pinned
	if (droppedIndex !== -1 && targetIndex !== -1) {
		const droppedTabEntity = state.tabs[droppedIndex];
		const targetTabEntity = state.tabs[targetIndex];

		if (droppedTabEntity.isPinned || targetTabEntity.isPinned) {
			return;
		}

		const [removedTab] = state.tabs.splice(droppedIndex, 1);
		state.tabs.splice(targetIndex, 0, removedTab);

		state.tabs.forEach((tab, index) => {
			tab.index = index;
		});

		const dbCluster = await IndexedDBNavigationTabsInstance.get();
		const promises: Promise<void>[] = state.tabs.map((tab) =>
			dbCluster.put(toRaw(tab))
		);
		await Promise.all(promises);
	}
}
function movingTab(droppedTab: any, targetTab: any) {
	const droppedIndex = state.tabs.findIndex((t) => t.id === droppedTab.id);
	const targetIndex = state.tabs.findIndex((t) => t.id === targetTab.id);

	// Check if either tab is pinned
	if (droppedIndex !== -1 && targetIndex !== -1) {
		const droppedTabEntity = state.tabs[droppedIndex];
		const targetTabEntity = state.tabs[targetIndex];

		if (droppedTabEntity.isPinned || targetTabEntity.isPinned) {
			return;
		}

		const [removedTab] = state.tabs.splice(droppedIndex, 1);
		state.tabs.splice(targetIndex, 0, removedTab);

		state.tabs.forEach((tab, index) => {
			tab.index = index;
		});
	}
}

async function pinTab(tab: NavigationTabEntity) {
	const dbCluster = await IndexedDBNavigationTabsInstance.get();
	const currentTab = state.tabs.find((t) => t.id === tab.id);

	if (currentTab) {
		currentTab.isPinned = !currentTab.isPinned;

		const index = state.tabs.findIndex((t) => t.id === tab.id);
		state.tabs.splice(index, 1);

		if (currentTab.isPinned) {
			state.tabs.unshift(currentTab);
		} else {
			state.tabs.push(currentTab);
		}

		state.tabs.forEach((t, i) => {
			t.index = i;
		});

		await dbCluster.put(toRaw(currentTab));
	}
}

async function unpinTab(tab: NavigationTabEntity) {
	const dbCluster = await IndexedDBNavigationTabsInstance.get();
	const currentTab = state.tabs.find((t) => t.id === tab.id);

	if (currentTab) {
		currentTab.isPinned = false;

		const index = state.tabs.findIndex((t) => t.id === tab.id);
		state.tabs.splice(index, 1);
		state.tabs.push(currentTab);

		state.tabs.forEach((t, i) => {
			t.index = i;
		});

		await dbCluster.put(toRaw(currentTab));
	}
}

async function closeOtherTabs(tab: NavigationTabEntity) {
	router.push(tab.path);

	const dbCluster = await IndexedDBNavigationTabsInstance.get();
	const currentTab = state.tabs.find((t) => t.id === tab.id);
	const pinnedTabs = state.tabs.filter((t) => t.isPinned);

	if (currentTab) {
		currentTab.index = 0;
		state.tabs = [...pinnedTabs, currentTab];
		await dbCluster.clear();
		await dbCluster.add(toRaw(currentTab));
	}
}
async function closeTabsToRight(tab: NavigationTabEntity) {
	router.push(tab.path);
	const dbCluster = await IndexedDBNavigationTabsInstance.get();
	const currentTab = state.tabs.find((t) => t.id === tab.id);
	const pinnedTabs = state.tabs.filter((t) => t.isPinned);

	if (currentTab) {
		const index = state.tabs.findIndex((t) => t.id === tab.id);
		const tabs = state.tabs.slice(0, index + 1);
		state.tabs = [...pinnedTabs, ...tabs];

		state.tabs.forEach((tab, index) => {
			tab.index = index;
		});

		await dbCluster.clear();

		const promises: Promise<void>[] = state.tabs.map((tab) =>
			dbCluster.put(toRaw(tab))
		);
		await Promise.all(promises);
	}
}

async function closeAllTabs() {
	const dbCluster = await IndexedDBNavigationTabsInstance.get();
	state.tabs = state.tabs.filter((tab) => tab.isPinned);
	await dbCluster.clear();
	router.push('/dashboard');
}

function getCurrentTab(): NavigationTabEntity | null {
	return (
		state.tabs.find((tab) => tab.path === router.currentRoute.value.path) ??
		null
	);
}

export const navigationTabsModel = {
	...toRefs(state),
	removeTab,
	addTab,
	init,
	removeCurrentTab,
	removeTabById,
	addTabIfNotExists,
	moveTab,
	movingTab,
	pinTab,
	closeOtherTabs,
	closeTabsToRight,
	closeAllTabs,
	unpinTab,
	getCurrentTab,
};
