<template>
	<div
		v-if="message"
		class="pipeline-message-container"
		:class="messageClass"
	>
		<div
			class="answer-message-container"
			:class="{
				'answer-message-container--left':
					message.type !== PipelineMessageType.INPUT,
				'answer-message-container--right':
					message.type === PipelineMessageType.INPUT,
			}"
		>
			<div class="answer-message-container--title">{{ title }}</div>

			<div class="answer-message">
				<IconChatMessageRight
					class="answer-message--icon-right"
					size="1.5rem"
					:color="color"
				/>
				<IconChatMessageLeft
					class="answer-message--icon-left"
					size="1.5rem"
					:color="color"
				/>
				<div
					v-if="message.type === PipelineMessageType.OUTPUT"
					class="footnoteRegular"
				>
					<bButton
						label="Show pipeline"
						type="primary"
						@onClick="onGeneratePipeline"
					/>
				</div>
				<div v-else class="footnoteRegular">
					<VMarkdownView :content="message.content" />
				</div>
				<div class="captionRegular">
					{{ message.formattedDate }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ocularUserModel } from '@/entities/app';
import {
	ForeignObjectEntity,
	PipelineMessageEntity,
	PipelineMessageType,
} from '@/shared/api';
import { getCardSizeByStepType } from '@/shared/api/pipeline/PipelineService';
import {
	IconChatMessageLeft,
	IconChatMessageRight,
	ThemeColors,
	bButton,
} from '@/shared/uikit';
import { computed, toRaw } from 'vue';
import { VMarkdownView } from 'vue3-markdown';

const { user } = ocularUserModel;
const emit = defineEmits(['onGeneratePipeline']);

const props = defineProps({
	message: {
		type: Object as () => PipelineMessageEntity,
		required: true,
	},
});

const messageClass = computed(() => ({
	input: props.message.type === PipelineMessageType.INPUT,
	output: props.message.type === PipelineMessageType.OUTPUT,
	thought: props.message.type === PipelineMessageType.THOUGHT,
}));

const title = computed(() => {
	switch (props.message.type) {
		case PipelineMessageType.INPUT:
			return user.value?.name;
		case PipelineMessageType.OUTPUT:
			return 'BIOPTIC Copilot Conclusion';
		case PipelineMessageType.THOUGHT:
			return 'Thought';
	}
});

const color = computed(() => {
	switch (props.message.type) {
		case PipelineMessageType.INPUT:
			return ThemeColors.neutral[100];
		case PipelineMessageType.OUTPUT:
			return ThemeColors.primary[100];
		case PipelineMessageType.THOUGHT:
			return ThemeColors.secondary[100];
	}
});

const onGeneratePipeline = () => {
	const steps: ForeignObjectEntity[] = JSON.parse(
		toRaw(props.message.content)
	).steps.map((step: any) => {
		const size = getCardSizeByStepType(step.data.type, step.data);

		return ForeignObjectEntity.fromObject({
			id: step.id,
			type: step.data.type,
			data: step.data,
			width: size.width,
			height: size.height,
			input: step.input,
			output: step.output,
			comment: step.comment,
		});
	});

	emit('onGeneratePipeline', steps);
};
</script>

<style lang="stylus" scoped>
.pipeline-message-container
	width 100%
	display flex
	flex-direction row
	align-items center
	&.input
		justify-content flex-end
		.answer-message
			background var(--neutral-100)
	&.output
		justify-content flex-start
		.answer-message
			background var(--primary-100)

	&.thought
		justify-content flex-start
		.answer-message
			background var(--secondary-100)

.answer-message-container
	position relative
	padding 0.5rem
	display flex
	flex-direction column
	justify-content flex-start
	.answer-message-container--title
		width 100%
		padding 0.25rem 1.25rem
		font-family: var(--font-family);
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color var(--neutral-600)

	.captionRegular
		white-space nowrap
		display flex
		align-items center
		flex-direction row
		gap 0.5rem
	&--left
		align-items flex-start
		.answer-message--icon-right
			display none
		.answer-message--icon-left
			display block
	&--right
		align-items flex-end
		.answer-message--icon-right
			display block
		.answer-message--icon-left
			display none
	.b-unstyled-button
		padding 0
	.answer-message
		position relative
		padding 1rem
		max-width 24rem
		border-radius 1.25rem
		display flex
		flex-direction column
		justify-content flex-start
		align-items flex-end
		gap 0.5rem
		.answer-message--icon-right
			position absolute
			bottom 0
			right -0.475rem
		.answer-message--icon-left
			position absolute
			bottom 0
			left -0.475rem
		.answer-message--header
			width 100%
			display flex
			flex-direction column
			gap 0.25rem
		.footnoteRegular
			width 100%
			text-align left
</style>

<style lang="stylus">
.pipeline-message-container
    ul
        padding 1rem
    li
        margin-left 1rem
	.markdown-body
		p
			a
				color inherit !important
				pointer-events none
				text-decoration none
		pre
			overflow hidden !important
			text-overflow ellipsis
</style>
