import { type ChatEntity } from '@/shared/api';
import { ChatService } from '@/shared/api/chemcrow/ChatService';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	chat: ChatEntity | null;
}

const data: IViewModel = {
	isLoading: false,
	chat: null,
};

const state = reactive(data);

async function fetchChat(id: string) {
	state.isLoading = true;
	try {
		state.chat = await ChatService.getInstance().fetchChat(id);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const chatDetailsModel = {
	...toRefs(state),
	fetchChat,
};
