<template>
	<div
		class="b-unstyled-button"
		:class="{
			'b-unstyled-button--disabled': isDisabled,
			'b-unstyled-button--hover': isHovered,
		}"
		@click.stop="clicked"
	>
		<slot name="content"></slot>
	</div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	isDisabled: { type: Boolean, required: false, default: false },
	isHovered: { type: Boolean, required: false, default: false },
});

const clicked = () => {
	emit('onClick');
};
</script>

<style lang="stylus" scoped>

.b-unstyled-button
    user-select none
    padding 0.25rem
    border-radius: 0.25rem
    display flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    transition all .25s
    &:hover
        cursor pointer
        background: var(--neutral-50)
    &--disabled
        pointer-events: none
        user-select: none
        opacity: 0.5
        &:hover
            cursor: not-allowed
    &--hover
        background: var(--neutral-100)
        transition all .25s
</style>
