<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 33 32"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24.3333 22.1465L29.28 27.0532C29.5203 27.3018 29.6546 27.6341 29.6546 27.9799C29.6546 28.3257 29.5203 28.6579 29.28 28.9065C29.156 29.0315 29.0086 29.1307 28.8461 29.1984C28.6836 29.2661 28.5093 29.3009 28.3333 29.3009C28.1573 29.3009 27.983 29.2661 27.8205 29.1984C27.6581 29.1307 27.5106 29.0315 27.3867 28.9065L22.48 23.9999C20.0859 25.92 17.047 26.8499 13.9884 26.5983C10.9297 26.3467 8.08369 24.9328 6.03551 22.6472C4.45696 20.8858 3.4448 18.7098 3.10205 16.4H5.82904C5.94753 17.0269 6.13055 17.643 6.37711 18.2383C7.08353 19.9437 8.2798 21.4014 9.81466 22.4269C11.3495 23.4525 13.154 23.9999 15 23.9999C17.4753 23.9999 19.8493 23.0165 21.5996 21.2662C22.9357 19.9301 23.8249 18.2306 24.171 16.4H26.8422C26.5323 18.4881 25.6743 20.4745 24.3333 22.1465ZM24.0539 12.4H26.7615C26.2725 9.82457 24.9502 7.46708 22.9807 5.70206C20.6951 3.65388 17.7117 2.55922 14.6439 2.64317C11.576 2.72711 8.65695 3.98329 6.48684 6.1534C4.77316 7.86708 3.62939 10.0478 3.18274 12.4H5.94602C6.35586 10.7628 7.20288 9.26431 8.40032 8.06688C9.70561 6.76159 11.3687 5.87267 13.1791 5.51254C14.9896 5.15241 16.8663 5.33725 18.5717 6.04366C20.2771 6.75008 21.7348 7.94636 22.7604 9.48122C23.3592 10.3774 23.7949 11.3655 24.0539 12.4Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#1E293B'"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.2926 8.41067C13.7469 8.47151 14.1268 8.78578 14.2717 9.22058L16.6959 16.4929L18.5349 13.7344C18.7574 13.4006 19.1321 13.2001 19.5333 13.2001H27.5333C28.1961 13.2001 28.7333 13.7373 28.7333 14.4001C28.7333 15.0628 28.1961 15.6001 27.5333 15.6001H20.1755L17.3318 19.8657C17.0776 20.247 16.6283 20.4503 16.174 20.3894C15.7198 20.3286 15.3398 20.0143 15.1949 19.5795L12.7708 12.3072L10.9318 15.0657C10.7092 15.3995 10.3345 15.6001 9.93333 15.6001H2.73333C2.07058 15.6001 1.53333 15.0628 1.53333 14.4001C1.53333 13.7373 2.07058 13.2001 2.73333 13.2001H9.2911L12.1349 8.93441C12.3891 8.55307 12.8383 8.34984 13.2926 8.41067Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#0D9488'"
		/>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { sizeProp } from '../config';

const props = defineProps({
	isDisabled: Boolean,
	size: sizeProp,
});

const { size } = toRefs(props);
</script>
