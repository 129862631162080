<template>
	<div class="ligand-job-card" @click="onShowPreview">
		<div v-if="!job.is_viewed" class="ligand-job-card-notification"></div>

		<div class="ligand-job-card-progress" :style="progressStyle"></div>
		<div class="ligand-job-card--header">
			<section>
				<div class="bodySmallRegular">Hits for</div>
				<div class="subheadlineSemiBold">
					<bChip
						v-for="(smile, index) in job.allSmiles.slice(0, 3)"
						:key="index"
						:label="smile"
						:isGhost="false"
						:type="job.statusColor"
						size="small"
					/>
					<bChip
						v-if="job.allSmiles.length > 3"
						:label="`+${job.allSmiles.length - 3} more`"
						:isGhost="false"
						type="primary"
						size="small"
					/>
				</div>
			</section>
			<div>
				<bChip
					:label="job.statusTitle"
					:isGhost="false"
					:type="job.statusColor"
					size="small"
				/>
			</div>
		</div>
		<div
			class="ligand-job-card--body"
			:class="{
				'ligand-job-card--body-grid': job.allSmiles.length > 1,
			}"
		>
			<canvas
				v-if="job.allSmiles.length === 1"
				:id="'ligand-smiles-' + job.id"
				width="300"
				height="300"
			>
			</canvas>
			<div
				v-else
				v-for="(smiles, index) in job.allSmiles.slice(0, 4)"
				class="ligand-job-card--body-grid--item"
			>
				<canvas
					:id="'ligand-smiles-' + job.id + '-' + smiles + '-' + index"
					width="150"
					height="150"
				>
				</canvas>
			</div>
			<div
				v-if="job.allSmiles.length > 4"
				class="ligand-job-card--body-grid--counter"
			>
				<div class="calloutSemiBold">
					+{{ job.allSmiles.length - 4 }} more
				</div>
			</div>
		</div>
		<div class="ligand-job-card--details">
			<div class="ligand-job-card--details--item">
				<IconDNS size="1.5rem" :color="job.catalog.color" />
				<section>
					<div
						class="bodySmallRegular"
						:style="{ color: job.catalog.color }"
					>
						Search base
					</div>
					<div
						class="subheadlineSemiBold"
						:style="{ color: job.catalog.color }"
					>
						{{ job.catalog.name }}
					</div>
				</section>
			</div>
			<div class="ligand-job-card--details--item">
				<IconInbox size="1.5rem" :color="ThemeColors.neutral[800]" />
				<section>
					<div class="bodySmallRegular">Number of molecules</div>
					<div class="subheadlineSemiBold">
						{{ toStringBy(job.catalog.size) }}
					</div>
				</section>
			</div>
			<div class="ligand-job-card--details--item">
				<IconCalendar size="1.5rem" :color="ThemeColors.neutral[800]" />
				<section>
					<div class="bodySmallRegular">Search date</div>
					<div class="subheadlineSemiBold">
						{{ job.formattedCreatedAt }}
					</div>
				</section>
			</div>
		</div>

		<div
			v-if="job.state === JobState.SUCCESS"
			class="ligand-job-card--footer"
		>
			<div class="ligand-job-card--footer-left-controls">
				<DownloadSearchResultsButton :searchId="job.id" />

				<bButton
					label="Preview results"
					type="primary"
					:isGhost="true"
					@onClick="onShowPreview"
				/>

				<bButton
					label="Ask AI"
					type="primary"
					:is-ghost="true"
					@onClick="onTappedAskAI"
				>
					<template v-slot:icon-left>
						<IconAI
							size="1.125rem"
							:color="ThemeColors.primary[600]"
						/>
					</template>
				</bButton>
			</div>

			<DeleteJobButton :jobId="job.id" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { DeleteJobButton, DownloadSearchResultsButton } from '@/features/job';
import { ActiveJobEntity, JobEntity, JobState } from '@/shared/api/';
import { toStringBy } from '@/shared/lib/';
import { drawSmiles } from '@/shared/lib/rdkit';
import {
	IconAI,
	IconCalendar,
	IconDNS,
	IconInbox,
	ThemeColors,
	bButton,
	bChip,
} from '@/shared/uikit';
import { computed, onMounted, type PropType } from 'vue';

const emit = defineEmits(['onDownload', 'onShowPreview']);

const props = defineProps({
	job: {
		type: Object as PropType<JobEntity>,
		required: true,
	},
	isDownloading: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const progressStyle = computed(() => {
	if (props.job instanceof ActiveJobEntity) {
		const width = Math.floor(props.job.progress * 100);
		return {
			width: `${width > 8 ? width : 0}%`,
		};
	}

	return {
		width: '100%',
	};
});

onMounted(() => {
	if (props.job.allSmiles.length === 1) {
		const smiles = props.job.allSmiles[0];
		drawSmiles(smiles, 'ligand-smiles-' + props.job.id, 500, 300);
	} else {
		props.job.allSmiles.slice(0, 4).forEach((smiles, index) => {
			drawSmiles(
				smiles,
				'ligand-smiles-' + props.job.id + '-' + smiles + '-' + index,
				150,
				150
			);
		});
	}
});

const onShowPreview = () => {
	emit('onShowPreview', props.job);
};

const onTappedAskAI = () => {
	router.push(`/chats?question=${props.job.id}`);
};
</script>

<style lang="stylus" scoped>
.ligand-job-card
	position relative
	width 100%
	padding 0.75rem
	border-radius 1.75rem
	background var(--neutral-50)
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content flex-start
	gap 0.75rem
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	cursor pointer
	.ligand-job-card-notification
		position absolute
		top -0.25rem
		right -0.25rem
		width 1rem
		height 1rem
		border-radius 50%
		background var(--danger-600)
		transition all 0.25s ease
		z-index 1
		animation: pulse 1s infinite
	&--header
		padding 0.75rem
		width 100%
		display grid
		grid-template-columns minmax(0, 1fr) auto
		gap 0.31rem
		z-index 1
		section
			width calc(100% - 1rem)
			.subheadlineSemiBold
				padding-top 0.25rem
				display flex
				flex-flow row wrap
				align-items center
				justify-content flex-start
				gap 0.5rem
		.bodySmallRegular
			color var(--neutral-600)
			letter-spacing: 0.025rem;
		.subheadlineSemiBold
			color var(--neutral-800)
			letter-spacing: 0.00625rem
			white-space nowrap
			overflow hidden
			text-overflow ellipsis
			width 100%
	&--body
		position relative
		width 100%
		background white
		padding 0.75rem
		// height 20.25rem
		border-radius 1rem
		display flex
		flex-direction column
		align-items center
		justify-content center
		border 1px solid var(--neutral-200)
		z-index 1
		&.ligand-job-card--body-grid
			border none
			padding 0
			background none
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			// grid-template-rows repeat(2, minmax(0, 1fr))
			align-items center
			justify-content center
			gap 0.5rem
			.ligand-job-card--body-grid--item
				background white
				border 1px solid var(--neutral-200)
				border-radius 0.5rem
				display flex
				flex-direction column
				align-items center
				justify-content center
			.ligand-job-card--body-grid--counter
				position absolute
				bottom 0rem
				right 0rem
				height calc(calc(100% / 2) - 0.25rem)
				width calc(calc(100% / 2) - 0.25rem)
				border-radius 0.5rem
				background: rgba(30, 41, 59, 0.5)
				display flex
				flex-direction column
				align-items center
				justify-content center
				.calloutSemiBold
					color white
	&--details
		width 100%
		display grid
		grid-template-columns repeat(3, minmax(0, 1fr))
		align-items center
		justify-content flex-start
		gap 0.75rem
		padding 0.75rem
		z-index 1
		&--item
			width 100%
			display grid
			grid-template-columns auto 1fr
			align-items center
			justify-content flex-start
			gap 1.25rem
			.bodySmallRegular
				color var(--neutral-600)
				letter-spacing: 0.025rem;
			.subheadlineSemiBold
				color var(--neutral-800)
				letter-spacing: 0.00625rem
	&--footer
		padding 0.75rem
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		gap 0.75rem
		z-index 1
		.ligand-job-card--footer-left-controls
			display flex
			flex-direction row
			align-items center
			justify-content flex-start
			gap 0.75rem
	.ligand-job-card-progress
		border-radius 1.75rem
		position absolute
		top 0
		left 0
		bottom 0
		width 0%
		background var(--neutral-100)
		border-radius 1.75rem

@keyframes pulse
	0%
		transform: scale(1)
	50%
		transform: scale(1.1)
	100%
		transform: scale(1)
</style>
