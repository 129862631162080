<template>
	<div
		class="pipeline-chat-container"
		:class="{
			'pipeline-chat-container--is-tools': props.isOpenedTools,
		}"
		ref="elementRef"
	>
		<div class="pipeline-chat-container-wrapper">
			<bUnstyledButton
				class="pipeline-chat-target"
				@onClick="toggleDropdown"
			>
				<template v-slot:content>
					<IconMagic2 size="1.25rem" color="white" />
				</template>
			</bUnstyledButton>

			<Transition name="slide-fade">
				<PipelineMessages
					v-if="isOpened"
					@on-toggle-dropdown="toggleDropdown"
					@on-generate="onGenerate"
				/>
			</Transition>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import type { ForeignObjectEntity } from '@/shared/api';
import { IconMagic2, bUnstyledButton } from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import PipelineMessages from './PipelineMessages.vue';

const emit = defineEmits(['onGenerate']);

const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);
const message = ref('');

const props = defineProps({
	isOpenedTools: {
		type: Boolean,
		required: true,
	},
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
	if (isOpened.value) {
		message.value = '';
		Analytics.send(AnalyticsEvents.PIPELINE.CLICK_OPEN_GENERATE_PIPELINE);
	}
};

const onGenerate = (steps: ForeignObjectEntity[]) => {
	emit('onGenerate', steps);
	isOpened.value = false;
};
</script>

<style lang="stylus" scoped>
.pipeline-chat-container
	position: fixed
	left 1.25rem
	bottom 1.25rem
	z-index 1
.pipeline-chat-container-wrapper
	position: relative
	.pipeline-chat-target
		background var(--primary-600)
		padding 0.75rem
		border-radius 1rem
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
</style>

<style lang="stylus">
.slide-fade-enter-active {
	transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.25s ease-in;
}

.slide-fade-enter-from {
	transform: translateX(-50vw);
	opacity: 0;
}

.slide-fade-leave-to {
	transform: translateX(-50vw);
	opacity: 0;
}
</style>
