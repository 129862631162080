import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLogout: boolean;
}
const data: IViewModel = {
	isLogout: false,
};

const state = reactive(data);

export const modalsModel = {
	...toRefs(state),
};
