import { generateUuid } from '@/shared/lib';
import { ThemeColors } from '@/shared/uikit';
import moment from 'moment';

export class MessageEntity {
	get isToolMessage(): boolean {
		return this.toolType !== null;
	}

	get color(): string {
		switch (this.type) {
			case MessageType.INPUT:
				return ThemeColors.neutral[100];
			case MessageType.OUTPUT:
				return ThemeColors.primary[100];
			case MessageType.THOUGHT:
				return ThemeColors.neutral[100];
			case MessageType.ERROR:
				return ThemeColors.danger[600];
			case MessageType.TOOL_START:
			case MessageType.TOOL_END:
				return ThemeColors.secondary[100];
		}
	}

	get parsedContent(): {
		content: string;
		file_id: string | null;
		file_name: string | null;
	} {
		const fileIdMatch = this.content.match(/<file_id>(.*?)<\/file_id>/);
		const fileNameMatch = this.content.match(
			/<file_name>(.*?)<\/file_name>/
		);
		const file_id = fileIdMatch ? fileIdMatch[1] : null;
		const file_name = fileNameMatch ? fileNameMatch[1] : null;
		const cleanContent = this.content
			.replace(/<file_id>.*?<\/file_id>/, '')
			.replace(/<file_name>.*?<\/file_name>/, '')
			.trim();

		return {
			content: cleanContent,
			file_id: file_id,
			file_name: file_name,
		};
	}

	get formattedDate(): string {
		return moment(this.date).format('DD MMMM YYYY HH:mm:ss');
	}

	constructor(
		public id: string,
		public content: string,
		public type: MessageType,
		public toolType: ToolType | null = null,
		public date: Date = new Date(),
		public output: MessageEntity | null = null,
		public file: File | null = null
	) {}

	static fromObject(obj: any): MessageEntity {
		return new MessageEntity(
			obj?.id ?? generateUuid(),
			obj.content,
			obj.type,
			obj.toolType,
			obj?.date ? new Date(obj.date) : new Date(),
			obj.output ? MessageEntity.fromObject(obj.output) : null,
			obj.file ?? null
		);
	}
}

export enum MessageType {
	INPUT = 'input',
	OUTPUT = 'output',
	THOUGHT = 'thought',
	ERROR = 'error',
	TOOL_START = 'tool_start',
	TOOL_END = 'tool_end',
}

export enum ToolType {
	SMILES_TO_WEIGHT = 'smiles_to_weight',
	NAME_TO_SMILES = 'name_to_smiles',
	QUERY_TO_CAS = 'query_to_cas',
	SMILES_TO_NAME = 'smiles_to_name',
	PATENT_CHECK = 'patent_check',
	MOL_SIMILARITY = 'mol_similarity',
	FUNC_GROUPS = 'func_groups',
	EXPLOSIVE_CHECK = 'explosive_check',
	CONTROL_CHEM_CHECK = 'control_chem_check',
	SIMILAR_CONTROL_CHEM_CHECK = 'similar_control_chem_check',
	SAFETY_SUMMARY = 'safety_summary',
	SCHOLAR_TO_RESULT_LLM = 'scholar_to_result_llm',
	DEFAULT = 'default',
	BIOPTIC_LIGAND_SEARCH = 'bioptic_ligand_search',
	BIOPTIC_JOB_RESULTS = 'bioptic_job_results',
	BIOPTIC_EUROFINS_ASSISTANT = 'bioptic_eurofins_assistant',
	BIOPTIC_ADME_PREDICTOR = 'bioptic_adme_predictor',
}

export class ChatEntity {
	constructor(
		public id: string,
		public user_id: string,
		public name: string,
		public messages: MessageEntity[],
		public created_at: Date
	) {}

	get formattedDate(): string {
		return moment(this.created_at).format('DD/MM/YYYY');
	}

	static fromObject(obj: any): ChatEntity {
		const messages = obj.messages.map((message: any) => {
			return new MessageEntity(
				message?.id ?? generateUuid(),
				message.content,
				message.type,
				message.tool,
				new Date(obj.created_at)
			);
		});

		return new ChatEntity(
			obj.id,
			obj.user_id,
			obj.name,
			messages,
			new Date(obj.created_at)
		);
	}
}

export class UploadFileResponse {
	constructor(
		public file_id: string,
		public url: string
	) {}
}

export class NewMappedMessage {
	constructor(
		public input: MessageEntity | null,
		public timeline: MessageEntity[],
		public output: MessageEntity | null
	) {}

	isToolIncluded(toolType: ToolType): boolean {
		return this.timeline.some((message) => message.toolType === toolType);
	}
}

export function mapNewMessages(messages: MessageEntity[]): NewMappedMessage[] {
	const result: NewMappedMessage[] = [];
	let currentMapping: NewMappedMessage = new NewMappedMessage(null, [], null);

	for (const message of messages) {
		switch (message.type) {
			case 'input':
				if (currentMapping.input) {
					result.push(currentMapping);
					currentMapping = new NewMappedMessage(null, [], null);
				}
				currentMapping.input = message;
				break;
			case 'output':
				currentMapping.output = message;
				result.push(currentMapping);
				currentMapping = new NewMappedMessage(null, [], null);
				break;
			default:
				currentMapping.timeline.push(message);
				break;
		}
	}

	if (
		currentMapping.input ||
		currentMapping.timeline.length ||
		currentMapping.output
	) {
		result.push(currentMapping);
	}

	return result;
}
