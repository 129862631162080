import { reactive, toRefs } from 'vue';
import { DockingProviderOptions } from './entities';

interface IViewModel {
	isLoading: boolean;
	options: DockingProviderOptions[];
}
const data: IViewModel = {
	isLoading: false,
	options: [],
};

const state = reactive(data);

export const dockingProviderDropdownModel = {
	...toRefs(state),
};
