<template>
	<nav class="applications-view">
		<ApplicationButton
			v-for="app in applications"
			:key="`application-button-${app.id}`"
			:value="app"
			@onClick="onClickApplication(app)"
		/>
	</nav>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { ApplicationButton } from '@/features/home';
import type { ApplicationButtonProps } from '@/features/home/application-button/ui.vue';
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabType } from '@/shared/api';
import {
	IconFlows,
	IconLigandSearch,
	IconRobot,
	IconStructureSearch,
	IconTargetSearch,
} from '@/shared/uikit';
import { createLBSSModel } from '@/widgets/ligand-based-search';
import { createTDSSModel } from '@/widgets/target-driven-search/create-tdss-modal';
import { markRaw, ref } from 'vue';

const applications = ref<ApplicationButtonProps[]>([
	{
		id: 1,
		name: 'Ligand Based Search',
		desc: 'Search based on your HIT',
		icon: markRaw(IconLigandSearch),
		isDisabled: false,
		isNew: false,
		isBeta: false,
	},
	{
		id: 2,
		name: 'Target Driven Search',
		desc: 'Start search with Targets',
		icon: markRaw(IconTargetSearch),
		isDisabled: false,
		isNew: false,
		isBeta: false,
	},
	{
		id: 3,
		name: 'Fingerprint-based search',
		desc: 'Tanimoto similarity',
		icon: markRaw(IconStructureSearch),
		isDisabled: false,
		isNew: true,
		isBeta: false,
	},
	{
		id: 4,
		name: 'BIOPTIC Copilot',
		desc: 'Your personal Chem-Assist',
		icon: markRaw(IconRobot),
		isDisabled: false,
		isNew: true,
		isBeta: false,
	},
	{
		id: 5,
		name: 'BIOPTIC Flows',
		desc: 'Create Drug Discovery pipeline',
		icon: markRaw(IconFlows),
		isDisabled: false,
		isNew: false,
		isBeta: true,
	},
	{
		id: 7,
		name: 'Ligands Dashboard',
		desc: 'Ligand data hub',
		icon: markRaw(IconLigandSearch),
		isDisabled: false,
		isNew: false,
		isBeta: true,
	},
	{
		id: 6,
		name: 'Docking',
		desc: 'Organize. Manage. Simplify.',
		icon: markRaw(IconRobot),
		isDisabled: true,
		isNew: false,
		isBeta: false,
	},
]);

const onClickApplication = (item: ApplicationButtonProps) => {
	const { isVisible: isVisibleLBSS, isStructureSearch } = createLBSSModel;
	const { isVisible: isVisibleTDSS } = createTDSSModel;
	const { addTab } = navigationTabsModel;

	switch (item.id) {
		case 1:
			isVisibleLBSS.value = true;
			break;
		case 2:
			isVisibleTDSS.value = true;
			break;
		case 3:
			isVisibleLBSS.value = true;
			isStructureSearch.value = true;
			break;
		case 4:
			// addTab({
			// 	id: `new-copilot-chat-tab`,
			// 	title: item.name,
			// 	description: item.desc,
			// 	path: `/`,
			// 	type: NavigationTabType.Chat,
			// });

			router.push('/');

			break;
		case 5:
			router.push('/pipeline');
			break;
		case 7:
			addTab({
				id: `new-competitive-chat-tab`,
				title: 'Ligands Dashboard',
				description: 'Ligand data hub',
				path: `/competitive`,
				type: NavigationTabType.TARGET,
			});

			break;
		default:
			break;
	}

	Analytics.send(AnalyticsEvents.Home.CLICK_APPLICATION, {
		name: item.name,
		desc: item.desc,
	});
};
</script>

<style lang="stylus" scoped>
.applications-view
	display flex
	flex-flow row wrap
	justify-content center
	align-items center
	gap 0.75rem
	max-width 80%
</style>
