<template>
	<div class="execute-button-container">
		<bUnstyledButton
			class="execute-button"
			:class="{ 'execute-button--loading': isLoading }"
			@onClick="executePipeline"
		>
			<template v-slot:content>
				<div v-if="isLoading" class="b-loader"></div>
				<IconPlay v-else size="1.25rem" color="white" />
			</template>
		</bUnstyledButton>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconPlay, bUnstyledButton } from '@/shared/uikit';

const emit = defineEmits(['executePipeline']);

const props = defineProps({
	isLoading: {
		type: Boolean,
		required: true,
	},
});

const executePipeline = () => {
	if (props.isLoading) return;

	emit('executePipeline');
	Analytics.send(AnalyticsEvents.PIPELINE.CLICK_EXECUTE_PIPELINE);
};
</script>

<style lang="stylus" scoped>
.execute-button-container
	position: fixed
	right 4.75rem
	top calc(1.25rem + 6.25rem)
	z-index 1
	.execute-button
		background white
		padding 0.75rem
		border-radius 1rem
		border-radius: var(--rounded-large, 1rem);
		background: var(--neutral-900);
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
		&.execute-button--loading
			background: var(--neutral-100)
			cursor: not-allowed
			pointer-events: none
</style>

<style lang="stylus">
.b-loader {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	position: relative;
	animation: rotate 1s linear infinite;
}
.b-loader::before,
.b-loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 2px solid var(--neutral-400);
	animation: prixClipFix 2s linear infinite;
}
.b-loader::after {
	border-color: var(--primary-600);
	animation: prixClipFix 2s linear infinite,
		rotate 0.5s linear infinite reverse;
	inset: 2px;
}
</style>
