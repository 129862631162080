<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H11C11.5523 21 12 20.5523 12 20C12 19.4477 11.5523 19 11 19H4V5H11C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3H3ZM18.2071 7.79289C17.8166 7.40237 17.1834 7.40237 16.7929 7.79289C16.4024 8.18342 16.4024 8.81658 16.7929 9.20711L18.5858 11H9.5C8.94772 11 8.5 11.4477 8.5 12C8.5 12.5523 8.94772 13 9.5 13H18.5858L16.7929 14.7929C16.4024 15.1834 16.4024 15.8166 16.7929 16.2071C17.1834 16.5976 17.8166 16.5976 18.2071 16.2071L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L18.2071 7.79289Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
