<template>
	<div class="ligand-3d-controls-container">
		<bUnstyledButton @onClick="onClickReset">
			<template v-slot:content>
				<IconReload size="1.25rem" />
			</template>
		</bUnstyledButton>
		<bUnstyledButton @onClick="onClickZoomIn">
			<template v-slot:content>
				<IconZoomIn size="1.25rem" />
			</template>
		</bUnstyledButton>

		<!-- <div class="zoom-percent">
			<div class="captionRegular">{{ scalePercentage }}%</div>
		</div> -->

		<bUnstyledButton @onClick="onClickZoomOut">
			<template v-slot:content>
				<IconZoomOut size="1.25rem" />
			</template>
		</bUnstyledButton>

		<!-- <bUnstyledButton @onClick="">
			<template v-slot:content>
				<IconCropFree size="1.25rem" />
			</template>
		</bUnstyledButton> -->
	</div>
</template>

<script setup lang="ts">
import {
	IconReload,
	IconZoomIn,
	IconZoomOut,
	bUnstyledButton,
} from '@/shared/uikit';

const emit = defineEmits(['onClickZoomIn', 'onClickZoomOut', 'onClickReset']);

// interface MiniMapControlsProps {
// 	minZoom: number;
// 	maxZoom: number;
// 	initScalePercentage: number;
// }

// const props = defineProps<MiniMapControlsProps>();
// const scalePercentage = ref<number>(0);

const onClickZoomIn = () => {
	emit('onClickZoomIn');
};

const onClickZoomOut = () => {
	emit('onClickZoomOut');
};

const onClickReset = () => {
	emit('onClickReset');
};

// onMounted(() => {
// 	scalePercentage.value = props.initScalePercentage;
// 	window.addEventListener('scaleValue' as any, (event: Event) => {
// 		const customEvent = event as CustomEvent<{
// 			scale: number;
// 		}>;

// 		scalePercentage.value = Math.round(
// 			((customEvent.detail.scale - props.minZoom) /
// 				(props.maxZoom - props.minZoom)) *
// 				100
// 		);
// 	});
// });
</script>

<style lang="stylus" scoped>
.ligand-3d-controls-container
	height 100%
	border: 1px solid var(--neutral-100);
	background: #fff;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	border-radius: 0.5rem;
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	padding 0.25rem
	align-items: center;
	.zoom-percent
		height 100%
		display: flex;
		align-items: center;
		justify-content: center;
</style>
