import InternalServerError from '../internal-server-error';
import MobileStubPage from '../mobile-stub';
import PageNotFound from '../page-not-found';

export const errorRoutes = [
	{
		path: '/:catchAll(.*)',
		name: 'PageNotFound',
		component: PageNotFound,
	},
	{
		path: '/500',
		name: 'InternalServerError',
		component: InternalServerError,
	},
	{
		path: '/mobile',
		name: 'mobile',
		component: MobileStubPage,
	},
];
