import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import {
	CatalogEntity,
	CatalogService,
	HitEntity,
	OrderService,
} from '@/shared/api';
import { IndexedDBHitsInstance } from '@/shared/api/client';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

export enum CompoundFormatType {
	Powder = 'Powder',
	Liquid = 'Liquid',
	Plate = 'Plate',
	Solvent = 'Solvent',
	Other = 'Other',
}

export type OptionsCompoundFormatType = {
	title: string;
};

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	shippingAddress: string;
	comment: string;
	isSended: boolean;
	hits: HitEntity[];
	catalogs: CatalogEntity[];
	compoundFormat: OptionsCompoundFormatType;
	compoundFormatOptions: OptionsCompoundFormatType[];
	customCompoundFormat: string;
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	shippingAddress: '',
	comment: '',
	isSended: false,
	hits: [],
	catalogs: [],
	compoundFormat: {
		title: '',
	} as OptionsCompoundFormatType,
	compoundFormatOptions: Object.values(CompoundFormatType).map((format) => {
		return {
			title: format,
		} as OptionsCompoundFormatType;
	}),
	customCompoundFormat: '',
};

const state = reactive(data);

async function submit() {
	state.isLoading = true;
	try {
		const selectedRole =
			state.compoundFormat.title === CompoundFormatType.Other
				? state.customCompoundFormat
				: state.compoundFormat.title;

		await OrderService.getInstance().submitEmolecules(
			state.hits.filter((hit) => hit.selected),
			state.shippingAddress,
			state.comment.length > 0 ? state.comment : null,
			selectedRole
		);

		Analytics.send(AnalyticsEvents.CART.SENDED_COMPOUNDS, {
			shippingAddress: state.shippingAddress,
			comment: state.comment.length > 0 ? state.comment : null,
			compounds: state.hits
				.filter((hit) => hit.selected)
				.map((hit) => hit.id),
			format: selectedRole,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.isSended = true;
		state.shippingAddress = '';
		state.comment = '';
		state.hits.forEach((hit) => (hit.selected = false));
		state.customCompoundFormat = '';
		state.compoundFormat.title = '';
		setTimeout(() => {
			state.isSended = false;
			state.isVisible = false;
			router.push('/');
		}, 2000);
	}
}

async function fetchHits() {
	state.isLoading = true;
	try {
		const dbHits = await IndexedDBHitsInstance.get();
		const result = await dbHits.getAll();

		state.catalogs =
			await CatalogService.getInstance().fetchLocalCatalogs();

		state.hits = result;

		if (window.location.host === 'app.bioptic.io') {
			state.hits = result.filter(
				(hit) =>
					hit.catalog_id === '3aae946c-06cc-4737-b00f-097223cb7326' ||
					hit.catalog_id === '8bc8012a-bb68-4271-8005-4c5c270fa924'
			);
		} else {
			state.hits = result;
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const sendEmoleculesModel = {
	...toRefs(state),
	submit,
	fetchHits,
};
