<template>
	<router-view />
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	navigationHeaderModel,
	navigationTabsModel,
} from '@/features/navigation';
import { NavigationTabType } from '@/shared/api';
import { jobDetailsModel } from '@/widgets/job/model';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { fetchJobDetails, job } = jobDetailsModel;
const { title, description, clear: clearNavigation } = navigationHeaderModel;

onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	const id = router.currentRoute.value.params.id as string;

	if (!id) {
		return;
	}

	await fetchJobDetails(id);

	if (router.currentRoute.value.name !== 'job-details') {
		return;
	}

	const { getCurrentTab } = navigationTabsModel;
	const currentTab = getCurrentTab();

	if (currentTab?.type === NavigationTabType.Structure) {
		title.value = 'Fingerprint-based search';
		description.value = 'for ' + job.value?.query ?? '';
	} else {
		title.value = job.value?.title ?? '';

		const smiles = job.value?.allSmiles || [];
		if (smiles.length > 4) {
			const extraCount = smiles.length - 3;
			description.value = `for ${smiles.slice(0, 3).join(' ')} + ${extraCount} more`;
		} else {
			description.value = `for ${smiles.join(' ')}`;
		}
	}
};

onBeforeUnmount(() => {
	clearNavigation();
	job.value = null;
});
</script>
