<template>
	<div v-if="hits.length > 0" class="lbss-cart-list-container">
		<div class="lbss-cart-list-container--info">
			<div class="subheadlineBold">Molecules</div>
			<div class="bodySmallRegular">Selected molecules from cart</div>
		</div>
		<div class="lbss-cart-list">
			<SmilesCartCard
				v-for="(hit, index) in hits"
				:key="`sel-smiles-key-${hit.smiles}-${index}`"
				:smiles="hit.smiles"
				:index="index"
				:isCheck="selectedSmiles.includes(hit.smiles)"
				@onClick="onSelect(hit)"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { SmilesCartCard } from '@/entities/job/';
import { cartModel } from '@/features/cart';
import type { HitEntity } from '@/shared/api';
import { onMounted, type PropType } from 'vue';

const emit = defineEmits(['onSelect', 'onSelect']);

const props = defineProps({
	selectedSmiles: {
		type: Array as PropType<string[]>,
		required: true,
	},
});
const { hits, fetchSelectedHits } = cartModel;

const onSelect = (item: HitEntity) => {
	emit('onSelect', item);
};

onMounted(() => {
	fetchSelectedHits();
});
</script>

<style lang="stylus" scoped>
.lbss-cart-list-container
	width 100%
	display flex
	flex-direction column
	gap 1rem
	padding-bottom 1rem
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem

	.lbss-cart-list
		display grid
		grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
		gap 0.75rem
		justify-content center
		transition all 0.25s
		&:hover
			cursor pointer
			transition all 0.25s
</style>
