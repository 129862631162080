<template>
	<BottomDrawerTemplate
		title="The Eurofins form"
		info="Bioptic Eurofins Assistant tool to fill out the form"
		:isVisible="isVisible"
		@onClose="onClose"
		@onNext=""
	>
		<template v-slot:body>
			<div class="eurofin-body-container">
				<el-table :data="tableData" v-if="!isLoading">
					<el-table-column
						v-for="column in columns"
						:key="column.prop"
						:prop="column.prop"
						:label="column.label"
						width="180"
					/>
				</el-table>
				<div v-else>
					<div class="empty-view"><bLoader size="4" /></div>
				</div>
			</div>
		</template>

		<template v-slot:footer-controls></template>
	</BottomDrawerTemplate>
</template>

<script setup lang="ts">
import { BottomDrawerTemplate } from '@/features/home/';
import { bLoader } from '@/shared/uikit';
import { ElTable, ElTableColumn } from 'element-plus';
import { eurofinFilePreviewModel } from './model';

const { isVisible, url, isLoading, columns, tableData } =
	eurofinFilePreviewModel;

const onClose = () => {
	url.value = '';
};
</script>

<style lang="stylus" scoped>
.eurofin-body-container
	width 100%
	height 100%;
	padding 1.25rem
	display: flex
	flex-direction: column
	justify-content: flex-start
	padding-top 2.5rem
	gap 2.5rem
	&::-webkit-scrollbar
		none
	.empty-view
		position relative
</style>

<style lang="stylus">

.eurofin-body-container
	.cell
		font-family var(--font-family)
	tr
		.cell
			font-family var(--font-family)
			color var(--neutral-800)
</style>
