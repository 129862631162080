import { SearchesAttributeEntity } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	attributes: SearchesAttributeEntity[];
}
const data: IViewModel = {
	isLoading: false,
	attributes: [],
};

const state = reactive(data);

const filters = computed(() => {
	return state.attributes.flatMap((item) => {
		if (item.absolute_max !== item.max || item.absolute_min !== item.min) {
			return [
				{
					attribute: item.type,
					minimum: item.min,
					maximum: item.max,
				},
			];
		} else {
			return [];
		}
	});
});

async function fetchClusterAttributes(jobId: string, clusterId: string) {
	state.isLoading = true;
	try {
		// const attributes =
		// 	await JobsService.getInstance().fetchClusterAttributes(
		// 		jobId,
		// 		clusterId
		// 	);
		// state.attributes = attributes;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

function setFilters(attributes: SearchesAttributeEntity[]) {
	state.attributes = attributes;
}

function setDefaultFilters() {
	state.attributes.forEach((item) => {
		item.min = item.absolute_min;
		item.max = item.absolute_max;
	});
}

export const collectionFiltersModel = {
	...toRefs(state),
	fetchClusterAttributes,
	setDefaultFilters,
	filters,
	setFilters,
};
