<template>
	<div
		class="application-button"
		:class="{
			'application-button--disabled': value.isDisabled,
		}"
		@click="onClick"
	>
		<component
			:is="value.icon"
			size="2rem"
			:isDisabled="value.isDisabled"
		/>
		<div class="application-button--desc">
			<div class="footnoteSemiBold">
				{{ value.name }}
				<bChip
					v-if="value.isDisabled"
					label="Soon"
					:isGhost="true"
					type="primary"
					size="small"
				/>
				<bChip
					v-if="value.isBeta"
					class="beta-chip"
					label="Beta"
					:isGhost="true"
					type="primary"
					size="small"
				/>
				<bChip
					v-if="value.isNew"
					label="New"
					class="new-chip"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="captionSemiBold">{{ value.desc }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { bChip } from '@/shared/uikit';
import type { PropType } from 'vue';

export type ApplicationButtonProps = {
	id: number;
	name: string;
	desc: string;
	icon: any;
	isDisabled: boolean;
	isNew: boolean;
	isBeta: boolean;
};

const emit = defineEmits(['onClick']);

const props = defineProps({
	value: {
		type: Object as PropType<ApplicationButtonProps>,
		required: true,
	},
});

const onClick = () => {
	if (props.value.isDisabled) return;

	emit('onClick', props.value.id);
};
</script>

<style lang="stylus" scoped>
.application-button
	user-select none
	width 16rem
	padding 1.25rem
	display flex
	flex-direction column
	align-items center
	justify-content center
	gap 0.75rem
	border-radius:1.75rem
	border: 1px solid var(--neutral-200);
	background: white
	&:hover
		background-color var(--neutral-100)
		cursor pointer
		transition all 0.25s
	&.application-button--disabled
		pointer-events none
		border none
		background var(--neutral-50)
		.application-button--desc
			.footnoteSemiBold
				color var(--neutral-400)
			.captionSemiBold
				color var(--neutral-400)

	&--desc
		display flex
		flex-direction column
		align-items center
		justify-content center
		gap 0.12rem
		.footnoteSemiBold
			display flex
			flex-direction row
			align-items center
			justify-content center
			gap 0.25rem
			color var(--neutral-800)
			text-align center
			white-space: nowrap;
			.beta-chip
				text-transform uppercase
				font-size: 0.625rem;
				font-weight: 600;
			.new-chip
				text-transform uppercase
				font-size: 0.625rem;
				font-weight: 600;
				color var(--primary-100)
		.captionSemiBold
			color var(--neutral-500)
</style>
