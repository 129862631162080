import { reactive, toRefs } from 'vue';
import { AvailableToolEntity } from './entities';

interface IViewModel {
	isVisible: boolean;
	tools: AvailableToolEntity[];
}

const data: IViewModel = {
	isVisible: false,
	tools: AvailableToolEntity.options,
};

const state = reactive(data);

export const chatAvailableToolsModalModel = {
	...toRefs(state),
};
