<template>
	<bButton
		:label="$t('auth.button.signin')"
		type="primary"
		:isDisabled="isDisabled"
		:isLoading="isLoading"
		@click="onSubmit"
	/>
</template>

<script setup lang="ts">
import { bButton } from '@/shared/uikit';
import { buttonLoginWithEmailModel } from './model';
const props = defineProps({
	isDisabled: {
		type: Boolean,
		default: false,
	},
	email: {
		type: String,
		required: true,
	},
	password: {
		type: String,
		required: true,
	},
});

const { login, isLoading } = buttonLoginWithEmailModel;

const onSubmit = () => {
	login(props.email, props.password);
};
</script>

<style lang="stylus" scoped>
.google-button
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 0.625rem 0.875rem;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.625rem;
    border: 1px solid var(--neutral-200);
    background: var(--neutral-0);
    transition: all 0.2s ease-in-out;
    &:hover
        cursor: pointer
        border: 1px solid var(--neutral-300, #cbd5e1);
        background: var(--neutral-100, #f1f5f9);
        transition: all 0.2s ease-in-out;
    &--disabled
        cursor: not-allowed
        color: var(--neutral-500);
        border: 1px solid var(--neutral-300, #cbd5e1);
        background: var(--neutral-200, #e2e8f0);
        transition: all 0.2s ease-in-out;
</style>
