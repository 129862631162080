import {
	AuthErrorCodes,
	applyActionCode,
	checkActionCode,
	confirmPasswordReset,
	getAuth,
	sendEmailVerification,
	updatePassword,
	type ActionCodeInfo,
	type User,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Endpoints } from '../shared';
import { RestClient } from '../client';

export interface AuthProvider {
	login(credentials: any): Promise<firebase.auth.UserCredential>;
	logout(): Promise<void>;
	signUp(credentials: any): Promise<firebase.auth.UserCredential>;
	sendPasswordResetEmail(email: string): Promise<void>;
	updatePassword(password: string): Promise<void>;
	resetPasswordByCode(oobCode: string, password: string): Promise<void>;
	checkActionCode(oobCode: string): Promise<ActionCodeInfo>;
	verifyUserEmail(oobCode: string): Promise<void>;
	sendEmailVerification(email: string): Promise<void>;
	refreshToken(): Promise<string>;

	getFirebaseUser(): Promise<User | null>;
	authWithCustomToken(token: string): Promise<any>;
}

abstract class AbstractAuthProvider implements AuthProvider {
	abstract login(credentials: any): Promise<firebase.auth.UserCredential>;
	abstract logout(): Promise<void>;

	async signUp(credentials: any): Promise<firebase.auth.UserCredential> {
		return firebase
			.auth()
			.createUserWithEmailAndPassword(
				credentials.email,
				credentials.password
			);
	}

	async sendPasswordResetEmail(email: string): Promise<void> {
		return firebase.auth().sendPasswordResetEmail(email);
	}

	async updatePassword(password: string): Promise<void> {
		const currentUser = getAuth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		return await updatePassword(currentUser, password);
	}

	async resetPasswordByCode(
		oobCode: string,
		password: string
	): Promise<void> {
		return await confirmPasswordReset(getAuth(), oobCode, password);
	}

	async checkActionCode(oobCode: string): Promise<ActionCodeInfo> {
		return await checkActionCode(getAuth(), oobCode);
	}

	async verifyUserEmail(oobCode: string): Promise<void> {
		const currentUser = getAuth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		await applyActionCode(getAuth(), oobCode);
		await currentUser.reload();
	}

	async sendEmailVerification(email: string): Promise<void> {
		const currentUser = getAuth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		return await sendEmailVerification(currentUser);
	}

	async refreshToken(): Promise<string> {
		const currentUser = getAuth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		return await currentUser.getIdToken(true);
	}

	async getFirebaseUser(): Promise<User | null> {
		return getAuth().currentUser;
	}

	async authWithCustomToken(token: string): Promise<any> {
		return await firebase.auth().signInWithCustomToken(token);
	}
}

export class EmailAuthProvider extends AbstractAuthProvider {
	async login(credentials: {
		email: string;
		password: string;
	}): Promise<firebase.auth.UserCredential> {
		return firebase
			.auth()
			.signInWithEmailAndPassword(
				credentials.email,
				credentials.password
			);
	}

	async logout(): Promise<void> {
		return firebase.auth().signOut();
	}

	override async sendEmailVerification(email: string): Promise<void> {
		const currentUser = getAuth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		const client: RestClient = new RestClient(
			import.meta.env.VITE_REST_BASE_URL
		);

		await client.post(Endpoints.Users.ACTIVATE, { email });
	}
}

export class GoogleAuthProvider extends AbstractAuthProvider {
	async login(): Promise<firebase.auth.UserCredential> {
		const provider = new firebase.auth.GoogleAuthProvider();
		return firebase.auth().signInWithPopup(provider);
	}

	async logout(): Promise<void> {
		return firebase.auth().signOut();
	}
}

export class MicrosoftAuthProvider extends AbstractAuthProvider {
	async login(): Promise<firebase.auth.UserCredential> {
		const provider = new firebase.auth.OAuthProvider('microsoft.com');
		return firebase.auth().signInWithPopup(provider);
	}

	async logout(): Promise<void> {
		return firebase.auth().signOut();
	}
}
