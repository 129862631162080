<template>
	<DashboardCollectionTemplate
		ref="scrollContainer"
		:isLoading="isLoading"
		:isEmpty="!isLoading && jobs.length === 0"
		title="Ligand Based results"
		emptyTitle="No results found"
		emptyDesc="Create a new Ligand Based search to get results"
	>
		<template v-slot:body>
			<LigandJobCard
				v-for="job in jobs"
				:key="'job-key-' + job.id"
				:job="job"
				@onShowPreview="onClickByJob"
			/>
			<bPagination
				:limit="pageLimit.value"
				:total="total"
				:currentPage="currentPage"
				@currentChange="changePage"
			>
				<template v-slot:select-page>
					<div class="pagination-select-page">
						<bSelect
							v-model="pageLimit"
							:options="pageOptions"
							size="small"
							position="top"
						/>
					</div>
				</template>
			</bPagination>
		</template>
		<template v-slot:empty-controls>
			<bButton
				label="New Ligand Based search"
				type="primary"
				@onClick="openCreateJobModal"
			>
				<template v-slot:icon-left>
					<IconPlus size="1.125rem" color="white" />
				</template>
			</bButton>
		</template>
	</DashboardCollectionTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { LigandJobCard } from '@/entities/job';
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabType, type JobEntity } from '@/shared/api';
import { IconPlus, bButton, bPagination, bSelect } from '@/shared/uikit';
import { DashboardCollectionTemplate } from '@/widgets/dashboard';
import { onMounted, ref, watch } from 'vue';
import { createLBSSModel } from '../create-lbss-modal';
import { lbssJobsModel } from './model';

const scrollContainer = ref<any>(null);

const {
	isLoading,
	jobs,
	fetchLigandBasedResults,
	pageLimit,
	total,
	currentPage,
	pageOptions,
} = lbssJobsModel;

const { addTab } = navigationTabsModel;

onMounted(() => {
	fetchLigandBasedResults(1);
	Analytics.send(AnalyticsEvents.BIOPTIC_VIEW.VIEW_LIGAND_BASED_JOBS);
});

watch(
	() => pageLimit.value,
	() => {
		changePage(1);
	},
	{ deep: true }
);

const changePage = (page: number) => {
	scrollContainer.value.resetScroll();
	fetchLigandBasedResults(page);
};

const onClickByJob = (job: JobEntity) => {
	addTab({
		id: job.id,
		title: 'Ligand Based',
		description: job.query,
		path: `/job/${job.id}`,
		type: NavigationTabType.LIGAND,
	});
};

const openCreateJobModal = () => {
	const { isVisible } = createLBSSModel;
	isVisible.value = true;
};
</script>

<style lang="stylus" scoped></style>
