<template>
	<div class="chat-promt-button" @click="onClick">
		<div class="chat-promt-button--desc">
			<div class="footnoteSemiBold">
				{{ prompt }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const emit = defineEmits(['onClick']);

const props = defineProps({
	prompt: {
		type: String,
		required: true,
	},
});

const onClick = () => {
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.chat-promt-button
	user-select none
	width 16rem
	height 6rem
	padding 1.25rem
	display flex
	flex-direction column
	align-items center
	justify-content center
	gap 0.75rem
	border-radius:1.75rem
	border: 1px solid var(--neutral-200);
	background: white
	&:hover
		background-color var(--neutral-100)
		cursor pointer
		transition all 0.25s
	&--desc
		display flex
		flex-direction column
		align-items center
		justify-content center
		gap 0.12rem
		.footnoteSemiBold
			display flex
			flex-direction row
			align-items center
			justify-content center
			text-align center
			gap 0.25rem
			color var(--neutral-800)
</style>
