import { ResultsSortOption } from '@/shared/api';
import { clusterResultsFiltersModel } from '@/widgets/cluster';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	selectedOption: ResultsSortOption;
}
const data: IViewModel = {
	selectedOption: ResultsSortOption.defaultOption,
};

const state = reactive(data);

const options = computed(() => {
	const { attributes } = clusterResultsFiltersModel;

	return attributes.value
		.map((attribute) => ResultsSortOption.mapWithSortDirection(attribute))
		.flat();
});

export const hitResultsSortModel = {
	...toRefs(state),
	options,
};
