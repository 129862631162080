import { ConnectionManager } from './ConnectionManager';
import { ElementManager } from './ElementManager';
import { Grid } from './Grid';
import type { DashboardElement, IDraggable } from './entities';
import { buildGraph, type Graph } from './graphUtils';
import { transformCoordinates } from './helpers';

abstract class AbstractZoomableSVG implements IDraggable {
	abstract scale: number;
	abstract originX: number;
	abstract originY: number;
	abstract svg: SVGSVGElement;
	abstract grid: Grid;

	abstract elementManager: ElementManager;
	abstract connectionManager: ConnectionManager;

	abstract contentWidth: number;
	abstract contentHeight: number;

	abstract handleZoom(event: WheelEvent): void;
	abstract handleMouseDown(event: MouseEvent): void;
	abstract handleMouseMove(event: MouseEvent): void;
	abstract handleMouseUp(event: MouseEvent): void;
	abstract handleClick(event: MouseEvent): void;

	public init(): void {
		this.svg.setAttribute(
			'viewBox',
			`0 0 ${this.svg.clientWidth} ${this.svg.clientHeight}`
		);
		this.updateViewBox();
		this.grid.draw();
		this.initEventListeners();
	}

	public updateViewBox() {
		const viewBox = `${this.originX} ${this.originY} ${this.svg.clientWidth / this.scale} ${this.svg.clientHeight / this.scale}`;
		this.svg.setAttribute('viewBox', viewBox);
	}

	public clear() {
		this.elementManager.clear();
		this.connectionManager.clear();
	}

	public destroyEventListeners() {
		this.svg.removeEventListener('wheel', this.handleZoom.bind(this));
		this.svg.removeEventListener('resize', this.grid.draw.bind(this.grid));
		this.svg.removeEventListener(
			'mousedown',
			this.handleMouseDown.bind(this)
		);
		this.svg.removeEventListener(
			'mousemove',
			this.handleMouseMove.bind(this)
		);
		this.svg.removeEventListener('mouseup', this.handleMouseUp.bind(this));
		this.svg.removeEventListener(
			'mouseleave',
			this.handleMouseUp.bind(this)
		);
		this.svg.removeEventListener('click', this.handleClick.bind(this));
	}

	public addHtmlElement(
		element: DashboardElement,
		graph: Graph,
		startNode: string
	) {
		const updateCallback = () => {
			this.connectionManager.draw();
		};

		this.elementManager.addHtmlElement(
			element,
			graph,
			startNode,
			updateCallback
		);

		this.elementManager.draw();
		this.connectionManager.draw();
	}

	public setElements(elements: DashboardElement[]) {
		const nodes = elements.map((element) => {
			return {
				id: element.id,
				output: element.data.input,
				input: element.data.output,
			};
		});

		const graph = buildGraph(nodes);

		for (const element of elements) {
			this.addHtmlElement(
				element,
				graph,
				elements[elements.length - 1].id
			);
		}
	}

	public zoomIn() {
		if (this.scale >= 2) {
			return;
		}

		this.scale += 0.01;
		this.updateViewBox();
		this.grid.updateScale(this.scale);
		this.grid.updateOrigin(this.originX, this.originY);
		this.grid.draw();
		this.sendScaleValue();
	}

	public zoomOut() {
		if (this.scale <= 0.1) {
			return;
		}
		this.scale -= 0.01;
		this.updateViewBox();
		this.grid.updateScale(this.scale);
		this.grid.updateOrigin(this.originX, this.originY);
		this.grid.draw();
		this.sendScaleValue();
	}

	public resetZoom() {
		this.scale = 0.45;
		this.originX = (this.contentWidth - this.svg.clientWidth) / 2;
		this.originY = (this.contentHeight - this.svg.clientHeight) / 2;
		this.updateViewBox();
		this.grid.updateScale(this.scale);
		this.grid.updateOrigin(this.originX, this.originY);
		this.grid.draw();
		this.sendScaleValue();
	}

	public sendScaleValue() {
		const customEvent = new CustomEvent('scaleValue', {
			detail: {
				scale: this.scale,
			},
			bubbles: true,
		});

		window.dispatchEvent(customEvent);
	}

	private initEventListeners() {
		this.svg.addEventListener('wheel', this.handleZoom.bind(this), {
			passive: false,
		});
		this.svg.addEventListener('resize', this.grid.draw.bind(this.grid));
		this.svg.addEventListener('mousedown', this.handleMouseDown.bind(this));
		this.svg.addEventListener('mousemove', this.handleMouseMove.bind(this));
		this.svg.addEventListener('mouseup', this.handleMouseUp.bind(this));
		this.svg.addEventListener('mouseleave', this.handleMouseUp.bind(this));
		this.svg.addEventListener('click', this.handleClick.bind(this));
	}
}

export class ZoomableSVG extends AbstractZoomableSVG {
	scale: number;
	originX: number;
	originY: number;

	svg: SVGSVGElement;
	grid: Grid;

	elementManager: ElementManager;
	connectionManager: ConnectionManager;

	minZoom: number;
	maxZoom: number;
	isDragging: boolean;
	isConnecting: boolean;
	lastX: number;
	lastY: number;
	contentWidth: number;
	contentHeight: number;

	forceUpdate?: () => void;

	constructor(
		svgId: string,
		contentWidth: number,
		contentHeight: number,
		updateCallback: () => void,
		minZoom: number = 0.1,
		maxZoom: number = 2,
		baseGridSize: number = 50
	) {
		super();
		const element = document.getElementById(svgId);
		if (!(element instanceof SVGSVGElement)) {
			throw new Error(
				`Element with id ${svgId} is not an SVGSVGElement.`
			);
		}
		this.svg = element;
		this.scale = 0.45;
		this.minZoom = minZoom;
		this.maxZoom = maxZoom;
		this.originX = (contentWidth - this.svg.clientWidth) / 2;
		this.originY = (contentHeight - this.svg.clientHeight) / 2;
		this.isDragging = false;
		this.isConnecting = false;
		this.lastX = 0;
		this.lastY = 0;
		this.contentWidth = contentWidth;
		this.contentHeight = contentHeight;
		this.elementManager = new ElementManager(
			this.svg,
			contentWidth,
			contentHeight,
			() => {
				this.connectionManager.draw();
				updateCallback();
			}
		);
		this.connectionManager = new ConnectionManager(
			this.svg,
			this.elementManager
		);

		this.grid = new Grid(
			baseGridSize,
			this.svg,
			contentWidth,
			contentHeight,
			this.scale,
			this.originX,
			this.originY
		);

		this.init();
	}

	handleZoom(event: WheelEvent) {
		event.preventDefault();
		const zoomSpeed = 0.05;

		let mouseX = event.offsetX;
		let mouseY = event.offsetY;

		const target = event.target as HTMLElement;
		if (target.closest('foreignObject')) {
			const svgRect = this.svg.getBoundingClientRect();
			mouseX = event.clientX - svgRect.left;
			mouseY = event.clientY - svgRect.top;
		}

		const wheel = event.deltaY < 0 ? 1 : -1;
		const zoom = Math.exp(wheel * zoomSpeed);

		if (
			this.scale * zoom < this.minZoom ||
			this.scale * zoom > this.maxZoom
		) {
			return;
		}

		this.originX =
			mouseX / this.scale + this.originX - mouseX / (this.scale * zoom);
		this.originY =
			mouseY / this.scale + this.originY - mouseY / (this.scale * zoom);

		this.scale *= zoom;

		this.grid.updateScale(this.scale);
		this.grid.updateOrigin(this.originX, this.originY);
		this.grid.draw();
		this.updateViewBox();

		this.connectionManager.handleZoom(event);
		this.elementManager.handleZoom(event);

		this.sendScaleValue();
	}

	handleMouseDown(event: MouseEvent) {
		this.connectionManager.handleMouseDown(event, {
			elements: this.elementManager.elements,
		});
		this.elementManager.handleMouseDown(event);

		if (this.connectionManager.startConnectionPoint) {
			this.isConnecting = true;
		} else if (!this.elementManager.isActivelyDragging) {
			this.isDragging = true;
		}

		this.lastX = event.clientX;
		this.lastY = event.clientY;
	}

	handleMouseMove(event: MouseEvent) {
		if (this.isConnecting && this.connectionManager.startConnectionPoint) {
			this.connectionManager.handleMouseMove(event);
		} else if (this.isDragging) {
			const dx = (event.clientX - this.lastX) / this.scale;
			const dy = (event.clientY - this.lastY) / this.scale;

			this.originX -= dx;
			this.originY -= dy;

			const viewBoxWidth = this.svg.clientWidth / this.scale;
			const viewBoxHeight = this.svg.clientHeight / this.scale;

			this.originX = Math.max(
				Math.min(this.originX, this.contentWidth - viewBoxWidth),
				0
			);
			this.originY = Math.max(
				Math.min(this.originY, this.contentHeight - viewBoxHeight),
				0
			);

			this.lastX = event.clientX;
			this.lastY = event.clientY;

			this.updateViewBox();
			this.grid.updateOrigin(this.originX, this.originY);
			this.grid.draw();
		} else if (this.elementManager.isActivelyDragging) {
			this.connectionManager.clearSelectedConnections();
			this.elementManager.handleMouseMove(event, {
				scale: this.scale,
				lastX: this.lastX,
				lastY: this.lastY,
			});

			this.lastX = event.clientX;
			this.lastY = event.clientY;
		}

		this.connectionManager.handleMouseMove(event);
	}

	handleMouseUp(event: MouseEvent) {
		if (this.isConnecting && this.connectionManager.startConnectionPoint) {
			this.connectionManager.handleMouseUp(event);
			this.isConnecting = false;
		} else if (this.isDragging) {
			this.isDragging = false;
		}

		this.elementManager.handleMouseUp(event, {
			grid: this.grid,
		});
	}

	handleClick(event: MouseEvent) {
		this.connectionManager.handleClick(event);
		this.elementManager.handleClick(event);
	}

	addElement(element: DashboardElement, isLast: boolean = false) {
		if (!isLast) {
			const { x, y } = transformCoordinates(
				element.x,
				element.y,
				this.svg
			);

			element.x = x;
			element.y = y;
		} else {
			const { x, y } = this.elementManager.getLastPosition();
			element.x = x;
			element.y = y - element.height * 1.25;
		}

		this.elementManager.addElement(element, isLast);
	}

	draw() {
		this.elementManager.draw();
		this.connectionManager.draw();
	}
}
