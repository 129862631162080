<template>
	<div class="target-search-page">
		<TargetDrivenResultsWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { TargetDrivenResultsWidget } from '@/widgets/target-driven-search/';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.DASHBOARD.VIEW_TARGET_BASED_SEARCH);
});
</script>

<style lang="stylus" scoped>
.target-search-page
	width 100%
	height 100%
</style>
