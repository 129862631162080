import ClusterDetailsPage from '../ClusterDetailsPage.vue';
import JobDetailsPage from '../JobDetailsPage.vue';
import JobPage from '../JobPage.vue';

export const jobRoutes = [
	{
		path: '/job',
		component: JobPage,
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
		children: [
			{
				path: '/job/:id',
				name: 'job-details',
				component: JobDetailsPage,
			},
		],
	},
	{
		path: '/job/:id/cluster/:clusterId',
		name: 'cluster-details',
		component: ClusterDetailsPage,
	},
];
