<template>
	<div class="ligand-based-results-message">
		<div class="tool-message-section--header">
			<div class="subheadlineRegular">
				Top {{ hits.length }} hits for job:
			</div>

			<bButton
				label="Preview results"
				type="primary"
				@onClick="openJob"
			/>
		</div>
		<div class="ligand-based-results-message-grid">
			<HitCard
				v-for="(hit, index) in hits"
				:key="hit.id"
				:hit="hit"
				:index="index * getRandomInteger(1, 100000)"
				:isGrid="true"
				:collectionItems="[]"
			/>
		</div>
	</div>
</template>
<script setup lang="ts">
import { router } from '@/app/providers';
import { HitCard } from '@/entities/hit';
import { getRandomInteger } from '@/shared/lib';
import { bButton } from '@/shared/uikit';
import { defineProps, onMounted } from 'vue';
import { ligandBasedResultsMessageModel } from './model';

const { fetchJobHitResults, hits } = ligandBasedResultsMessageModel;

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
});

onMounted(() => {
	if (!props.id) {
		return;
	}

	fetchJobHitResults(props.id);
});

const openJob = (id: string) => {
	router.push(`/job/${props.id}`);
};
</script>

<style lang="stylus" scoped>
.ligand-based-results-message-grid
	padding-top 1rem
	display grid
	grid-template-columns repeat(2, minmax(15rem, 1fr))
	gap 0.75rem
	justify-content center
	transition all 0.25s
.tool-message-section--header
	width 100%
	display flex
	justify-content space-between
	align-items center
	padding-top 1rem
</style>
