import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	JobsService,
	NavigationTabType,
	SearchType,
	SucceededJobEntity,
	type JobEntity,
} from '@/shared/api';
import { ocularRestErrorHandler, sleep } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { jobResultsFiltersModel } from './job-results-filters-widget';

interface IViewModel {
	isLoading: boolean;
	job: JobEntity | null;
}
const data: IViewModel = {
	isLoading: false,
	job: null,
};

const state = reactive(data);

async function fetchJobDetails(id: string, withLoading = true) {
	if (!id) {
		return;
	}

	state.isLoading = withLoading;

	try {
		state.job = await JobsService.getInstance().getJob(id);

		if (state.job instanceof SucceededJobEntity) {
			const { setJobAttributes } = jobResultsFiltersModel;
			setJobAttributes(state.job.attributes);
			const { addTab } = navigationTabsModel;

			addTab({
				id: state.job.id,
				title:
					state.job.parameters.type === SearchType.LIGAND
						? 'Ligand Based'
						: 'Target Driven',
				description: state.job.query,
				path: `/job/${state.job.id}`,
				type:
					state.job.parameters.type === SearchType.LIGAND
						? NavigationTabType.LIGAND
						: NavigationTabType.TARGET,
			});

			Analytics.send(AnalyticsEvents.Job.VIEW_JOB_RESULTS, {
				job_id: state.job.id,
				type: state.job.parameters.type,
			});
		} else {
			const { id: paramId } = router.currentRoute.value.params;

			if (state.job?.id === id && id === paramId) {
				// TODO: Move to SSE instead of long pooling.
				await sleep(500);
				fetchJobDetails(state.job?.id ?? '', false);
			}

			return;
		}
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
			state.job = null;
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

export const jobDetailsModel = {
	...toRefs(state),
	fetchJobDetails,
};
