<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_9997_251905)">
			<path
				d="M15.5 13.9999H14.71L14.43 13.7299C15.63 12.3299 16.25 10.4199 15.91 8.38989C15.44 5.60989 13.12 3.38989 10.32 3.04989C6.08999 2.52989 2.53999 6.08989 3.04999 10.3199C3.38999 13.1199 5.60999 15.4399 8.38999 15.9099C10.42 16.2499 12.33 15.6299 13.73 14.4299L14 14.7099V15.4999L18.26 19.7499C18.67 20.1599 19.33 20.1599 19.74 19.7499L19.75 19.7399C20.16 19.3299 20.16 18.6699 19.75 18.2599L15.5 13.9999ZM9.49999 13.9999C7.00999 13.9999 4.99999 11.9899 4.99999 9.49989C4.99999 7.00989 7.00999 4.99989 9.49999 4.99989C11.99 4.99989 14 7.00989 14 9.49989C14 11.9899 11.99 13.9999 9.49999 13.9999ZM9.49999 6.99989C9.21999 6.99989 8.99999 7.21989 8.99999 7.49989V8.99989H7.49999C7.21999 8.99989 6.99999 9.21989 6.99999 9.49989C6.99999 9.77989 7.21999 9.99989 7.49999 9.99989H8.99999V11.4999C8.99999 11.7799 9.21999 11.9999 9.49999 11.9999C9.77999 11.9999 9.99999 11.7799 9.99999 11.4999V9.99989H11.5C11.78 9.99989 12 9.77989 12 9.49989C12 9.21989 11.78 8.99989 11.5 8.99989H9.99999V7.49989C9.99999 7.21989 9.77999 6.99989 9.49999 6.99989Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_9997_251905">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
