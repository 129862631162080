import { router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import { JobsService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { lbssJobsModel } from '@/widgets/ligand-based-search';
import { tdssJobsModel } from '@/widgets/target-driven-search';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	jobId: string;
	isLoading: boolean;
}

const data: IViewModel = {
	jobId: '',
	isLoading: false,
};

const state = reactive(data);

async function deleteJob() {
	if (!state.jobId) {
		return;
	}

	state.isLoading = true;

	try {
		await JobsService.getInstance().delete(state.jobId);

		if (router.currentRoute.value.fullPath.includes('/dashboard')) {
			const { fetchLigandBasedResults } = lbssJobsModel;
			const { fetchTargetDrivenResults } = tdssJobsModel;

			if (router.currentRoute.value.fullPath.includes('/target-search')) {
				fetchTargetDrivenResults(1);
			}

			fetchLigandBasedResults(1);
		}

		const { removeCurrentTab, removeTabById } = navigationTabsModel;
		removeCurrentTab();
		removeTabById(`${state.jobId}`);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.jobId = '';
	}
}

export const deleteJobModel = {
	...toRefs(state),
	deleteJob,
};
