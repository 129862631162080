<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2 5C3.51388 4.69722 4.69722 3.51388 5 2C5.30278 3.51388 6.48612 4.69722 8 5C6.48612 5.30278 5.30278 6.48612 5 8C4.69722 6.48612 3.51388 5.30278 2 5ZM19.3484 2.43436C18.8798 1.96573 18.12 1.96573 17.6514 2.43436L2.43424 17.6515C1.96561 18.1201 1.96561 18.8799 2.43424 19.3486L4.65139 21.5657C5.12002 22.0344 5.87982 22.0344 6.34845 21.5657L21.5656 6.34857C22.0342 5.87994 22.0342 5.12014 21.5656 4.65152L19.3484 2.43436ZM14.9141 8.00004L18.4999 4.41426L19.5857 5.50004L15.9999 9.08583L14.9141 8.00004ZM13.4999 9.41426L14.5857 10.5L5.49992 19.5858L4.41413 18.5L13.4999 9.41426ZM20 12C19.6852 12.9443 18.9443 13.6852 18 14C18.9443 14.3148 19.6852 15.0557 20 16C20.3148 15.0557 21.0557 14.3148 22 14C21.0557 13.6852 20.3148 12.9443 20 12ZM15 20C15.9443 19.6852 16.6852 18.9443 17 18C17.3148 18.9443 18.0557 19.6852 19 20C18.0557 20.3148 17.3148 21.0557 17 22C16.6852 21.0557 15.9443 20.3148 15 20Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
