<template>
	<div class="b-button" @click.stop="clicked" :class="typeClass">
		<slot v-if="!isLoading" name="icon-left"></slot>
		<div :class="relaoadIconSizeClass" v-if="isLoading">
			<IconReload :color="ThemeColors.neutral[400]" />
		</div>
		<div class="b-button-label label">{{ label }}</div>
		<slot name="icon-right"></slot>
	</div>
</template>

<script setup lang="ts">
import { IconReload, ThemeColors } from '@/shared/uikit/';
import { computed, defineEmits, defineProps } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	type: { type: String, required: false, default: 'primary' },
	size: { type: String, required: false, default: 'default' },
	label: { type: String, required: false, default: '' },
	disabledIcon: { type: String, required: false, default: '' },
	isDisabled: { type: Boolean, required: false, default: false },
	isOnlyIcon: { type: Boolean, required: false, default: false },
	isPressed: { type: Boolean, required: false, default: false },
	isGhost: { type: Boolean, required: false, default: false },
	isLoading: { type: Boolean, required: false, default: false },
});

const typeClass = computed(() => {
	var className: string = props.isGhost
		? `b-button-ghost__${props.type.toLowerCase()}`
		: `b-button__${props.type.toLowerCase()}`;

	className += ` b-button__${props.size.toLowerCase()}`;

	if (props.isPressed) {
		className += ` b-button__pressed-${props.type.toLowerCase()}`;
	}

	if (props.isDisabled) {
		className += ' b-button__disabled';
	}

	if (props.isOnlyIcon) {
		className += ` b-button__only-icon-${props.size.toLowerCase()}`;
	}

	if (props.isLoading) {
		className += ' b-button__disabled';
	}

	return className;
});

const relaoadIconSizeClass = computed(() => {
	return {
		'is-loading-logo': true,
		'is-loading-logo--large': props.size === 'large',
		'is-loading-logo--small': props.size === 'small',
		'is-loading-logo--default': props.size === 'default',
	};
});

const clicked = () => {
	emit('onClick');
};
</script>

<style lang="stylus">
@import '../../../app/styles/theme/variables.styl';

.b-button
    user-select none
    padding 2px 10px
    border-radius: 8px;
    display flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap 6px
    &:hover
        cursor pointer
    .b-button-label
        color white
        user-select: none
        font-family: $font-family;
        white-space: nowrap;

.b-button__pressed-tertiary
    background: $neutral-200 !important
    border: 1px solid $neutral-200 !important
.b-button__pressed-neutral
    background: $neutral-200 !important
    border: 1px solid $neutral-200;
.b-button__large
    height 44px
    padding 1rem 0.62rem
    border-radius 62.4375rem
    .b-button-label
        font-size: 0.875rem;
    img
        width 21px
        height 21px
        // padding 1.75px
        // margin 9.5px 4px
.b-button__default
    height 2.5rem
    border-radius 62.4375rem
    padding 0.625rem 1rem
    .b-button-label
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.00625rem;
    img
        width 18px
        height 18px
        // padding 1.5px
        // margin 8px 2px
.b-button__small
    height 32px
    border-radius 1rem
    .b-button-label
        padding 6px 0px
        font-size: 13px;
        line-height: 16px;
    img
        width 16px
        height 16px
        // padding 1.33px
        // margin 6px 2px
.b-button__tertiary
    .label
        color $primary-600
.b-button__primary
    background: $primary-600;
    transition: all .25s
    &:hover
        background: $primary-400;
        transition: all .25s
    &:active
        background: $primary-700;
        transition: all .25s

.b-button__success
    background: $success-600;
    transition: all .25s
    &:hover
        background: $success-400;
        transition: all .25s
    &:active
        background: $success-700;
        transition: all .25s
.b-button__danger
    background: $danger-600;
    transition: all .25s
    &:hover
        background: $danger-400;
        transition: all .25s
    &:active
        background: $danger-700;
        transition: all .25s

.b-button__warning
    background: $warning-500;
    transition: all .25s
    &:hover
        background: $warning-400;
        transition: all .25s
    &:active
        background: $warning-700;
        transition: all .25s

.b-button__neutral
    background: $neutral-600;
    transition: all .25s
    &:hover
        background: $neutral-400;
        transition: all .25s
    &:active
        background: $neutral-700;
        transition: all .25s

.b-button-ghost__neutral
    .b-button-label
        color $neutral-600
    &:hover
        background: $neutral-200;
        transition: all .25s

.b-button-ghost__primary
    background: $primary-100;
    transition: all .25s
    .b-button-label
        color $primary-600
        font-weight: 500

    &:hover
        background: $primary-200;
        transition: all .25s
    &:active
        color $primary-700
        background: white
        transition: all .25s

.b-button-ghost__success
    background: $success-100;
    transition: all .25s
    .b-button-label
        color $success-500
    &:hover
        background: $success-200;
        transition: all .25s
    &:active
        color $success-700
        background: white
        transition: all .25s
.b-button-ghost__danger
    background: $danger-100;
    transition: all .25s
    .b-button-label
        color $danger-500
    &:hover
        background: $danger-200;
        transition: all .25s
    &:active
        color $danger-700
        background: white
        transition: all .25s
.b-button-ghost__warning
    background: $warning-100;
    transition: all .25s
    .b-button-label
        color $warning-500
    &:hover
        background: $warning-200;
        transition: all .25s
    &:active
        color $warning-700
        background: white
        transition: all .25s
.b-button-ghost__tertiary
    background: $neutral-0;
    transition: all .25s
    .b-button-label
        color $neutral-800
    &:hover
        background: $neutral-200;
        transition: all .25s
    &:active
        color $neutral-700
        background: $neutral-300
        transition: all .25s

.b-button__only-icon-large
    padding 2px 8px
    .b-button-label
        display none
    img
        width 18px
        height 18px
.b-button__only-icon-default
    padding 2px 9px
    .b-button-label
        display none
    img
        width 18px
        height 18px
.b-button__only-icon-small
    width 32px
    display flex
    flex-direction: row
    align-items: center
    justify-content: center
    .b-button-label
        display none
    img
        width 16px
        height 16px

.b-button__disabled
    user-select: none
    pointer-events: none
    background: $neutral-200 !important
    .b-button-label
        color $neutral-400
    img
        path
            fill: #000

.is-loading-logo
    animation: spin 1s linear infinite
    svg
        width 100%
        height 100%
    &--large
        width 1.5rem
        height 1.5rem
    &--small
        width 1rem
        height 1rem
    &--default
        width 1.25rem
        height 1.25rem
</style>
