<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M16.29 14.29L12 18.59L7.71 14.29C7.5217 14.1017 7.2663 13.9959 7 13.9959C6.7337 13.9959 6.47831 14.1017 6.29 14.29C6.1017 14.4783 5.99591 14.7337 5.99591 15C5.99591 15.2663 6.1017 15.5217 6.29 15.71L11.29 20.71C11.383 20.8037 11.4936 20.8781 11.6154 20.9289C11.7373 20.9797 11.868 21.0058 12 21.0058C12.132 21.0058 12.2627 20.9797 12.3846 20.9289C12.5064 20.8781 12.617 20.8037 12.71 20.71L17.71 15.71C17.8032 15.6168 17.8772 15.5061 17.9277 15.3843C17.9781 15.2624 18.0041 15.1319 18.0041 15C18.0041 14.8681 17.9781 14.7376 17.9277 14.6158C17.8772 14.4939 17.8032 14.3832 17.71 14.29C17.6168 14.1968 17.5061 14.1228 17.3843 14.0723C17.2624 14.0219 17.1319 13.9959 17 13.9959C16.8681 13.9959 16.7376 14.0219 16.6158 14.0723C16.4939 14.1228 16.3832 14.1968 16.29 14.29Z"
			:fill="color"
		/>
		<path
			d="M7.71 9.71L12 5.41L16.29 9.71C16.383 9.80373 16.4936 9.87812 16.6154 9.92889C16.7373 9.97966 16.868 10.0058 17 10.0058C17.132 10.0058 17.2627 9.97966 17.3846 9.92889C17.5064 9.87812 17.617 9.80373 17.71 9.71C17.8037 9.61704 17.8781 9.50644 17.9289 9.38458C17.9797 9.26272 18.0058 9.13201 18.0058 9C18.0058 8.86799 17.9797 8.73728 17.9289 8.61542C17.8781 8.49356 17.8037 8.38296 17.71 8.29L12.71 3.29C12.617 3.19627 12.5064 3.12188 12.3846 3.07111C12.2627 3.02034 12.132 2.9942 12 2.9942C11.868 2.9942 11.7373 3.02034 11.6154 3.07111C11.4936 3.12188 11.383 3.19627 11.29 3.29L6.29 8.29C6.1017 8.4783 5.99591 8.7337 5.99591 9C5.99591 9.2663 6.1017 9.5217 6.29 9.71C6.47831 9.8983 6.7337 10.0041 7 10.0041C7.2663 10.0041 7.5217 9.8983 7.71 9.71Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
