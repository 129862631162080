<template>
	<div class="probe-card" @click.stop="onClick">
		<div class="probe-card--header">
			<div>
				<bCheckbox
					:modelValue="isCheck"
					@update:modelValue="onClickCheckbox"
				/>
			</div>
		</div>

		<div class="probe-card--body">
			<canvas
				:id="'smiles-cart-' + index + '-' + smiles"
				width="180px"
				height="80px"
			></canvas>
		</div>
	</div>
</template>

<script setup lang="ts">
import { drawSmiles } from '@/shared/lib/rdkit';
import { bCheckbox } from '@/shared/uikit';
import { defineProps, onMounted } from 'vue';

const emit = defineEmits(['onClick', 'openPreview']);

const props = defineProps({
	isCheck: {
		type: Boolean,
		required: true,
	},
	smiles: {
		type: String,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
});

onMounted(() => {
	drawSmiles(
		props.smiles,
		'smiles-cart-' + props.index + '-' + props.smiles,
		180,
		80
	);
});

const onClick = () => {
	emit('onClick');
};

const onClickCheckbox = () => {
	emit('onClick');
};

const stopPropagation = (e: Event) => {
	e.stopPropagation();
};
</script>

<style lang="stylus" scoped>
.probe-card
	height 7.5rem
	padding 1.25rem 1.5rem
	background-color white
	border-radius: 1.75rem
	border 1px solid var(--neutral-200)
	display flex
	flex-direction column
	justify-content center
	align-items center
	gap 0.25rem
	transition all 0.25s
	position relative
	&:hover
		cursor pointer
		border 1px solid var(--neutral-400)
	&--checkbox
		position absolute
		top 0.75rem
		left 0.75rem
	.external-link-button
		position absolute
		bottom 0.75rem
		right 0.75rem
	&--header
		padding 0.75rem
		position absolute
		top 0
		left 0
		right 0
		display flex
		justify-content space-between
		align-items center

	&--body
		display flex
		flex-direction column
		justify-content center
		align-items center
		.probe-card-preview-hover
			transition all 0.25s
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			background: rgba(30, 41, 59, 0.5)
			display flex
			display none
			flex-direction row
			align-items center
			justify-content center
			gap 0.25rem
			border-radius: 1.75rem
			z-index 10
			transition all 0.25s ease
			.bodySmallRegular
				color white
		&:hover
			// cursor pointer
			.probe-card-preview-hover
				display flex
				transition all 0.25s
	.bodySmallSemiBold
		color var(--neutral-400)
</style>
