<template>
	<div class="b-toggle" :class="className" @click="toggle">
		<div
			class="b-toggle__switch"
			:class="{ 'b-toggle__switch--on': modelValue }"
		></div>
	</div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
	size: {
		type: String,
		default: 'medium',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const className = computed(() => {
	return {
		'b-toggle--active': props.modelValue,
		[`b-toggle--${props.size}`]: props.size,
		'b-toggle--disabled': props.disabled,
	};
});

const toggle = () => {
	emit('update:modelValue', !props.modelValue);
};
</script>

<style lang="stylus">
.b-toggle
    position: relative
    cursor: pointer
    background-color: #ccc

    &--small
        width: 40px
        height: 20px
        border-radius: 10px

        .b-toggle__switch
            width: 16px
            height: 16px
            top: 2px
            left: 2px
            border-radius: 50%

    &--medium
        width: 60px
        height: 30px
        border-radius: 15px

        .b-toggle__switch
            width: 26px
            height: 26px
            top: 2px
            left: 2px
            border-radius: 50%

    &--large
        width: 80px
        height: 40px
        border-radius: 20px

        .b-toggle__switch
            width: 36px
            height: 36px
            top: 2px
            left: 2px
            border-radius: 50%

    &__switch
        position: absolute
        background-color: #fff
        transition: transform 0.3s

        &--on
            background-color: #27ae60
            transform: translateX(calc(100% - 2px))
            transition: background-color 0.3s, transform 0.3s

        &--small
            width: 16px
            height: 16px
            top: 2px
            left: 2px
            border-radius: 50%

        &--medium
            width: 26px
            height: 26px
            top: 2px
            left: 2px
            border-radius: 50%

        &--large
            width: 36px
            height: 36px
            top: 2px
            left: 2px
            border-radius: 50%

    &--active
        background-color: var(--primary-600)
        transition: background-color 0.3s, transform 0.3s

        .b-toggle__switch
            &--on
                background-color: #fff
                transform: translateX(calc(100% + 4px))
                transition: background-color 0.3s, transform 0.3s
</style>
