import CompetitivePage from '../CompetitivePage.vue';

export const competitiveRoutes = [
	{
		path: '/competitive',
		component: CompetitivePage,
		name: 'competitive',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
];
