<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 31 30"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.5797 13.1616C20.8788 11.7817 21.0363 10.349 21.0363 8.87954V1H0.91584V29C5.55594 29 9.82919 27.4293 13.2329 24.7906C16.5202 27.3326 22.1335 29.9698 29 28.6269C27.2251 27.8086 20.4085 22.9231 20.5797 13.1616Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
