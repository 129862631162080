<template>
	<div
		v-if="isVisible"
		class="cart-button-container"
		:class="{
			'cart-button-container--chats': isChats,
		}"
		ref="elementRef"
	>
		<div
			class="cart-button-target"
			:class="{
				'cart-button-target--empty': hits.length === 0 || isLoading,
				'cart-button-target--active': isOpened,
			}"
			@click="toggleDropdown"
		>
			<IconTwoLayer
				v-if="hits.length > 0 && !isLoading"
				size="1.5rem"
				:color="isLoading ? ThemeColors.neutral[400] : 'white'"
			/>

			<IconReload
				v-if="isLoading"
				:color="ThemeColors.neutral[400]"
				size="1.25rem"
				class="is-loading-icon"
			/>

			<div class="footnoteBold">{{ title }}</div>
		</div>
		<div v-if="isOpened" class="cart-button-dropdown">
			<ExportSelectedButton @onClick="toggleDropdown" />
			<CreateCollectionByCartButton @onClick="toggleDropdown" />
			<SearchShortcutButton @onClick="toggleDropdown" />
			<OpenCompoundsButton @onClick="toggleDropdown" />
			<bDivider />
			<OpenCartButton @onClick="toggleDropdown" />
			<ClearCartButton @onClick="toggleDropdown" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { CreateCollectionByCartButton } from '@/features/collections/';
import {
	IconReload,
	IconTwoLayer,
	ThemeColors,
	bDivider,
} from '@/shared/uikit';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import ClearCartButton from './ClearCartButton.vue';
import ExportSelectedButton from './ExportSelectedButton.vue';
import OpenCartButton from './OpenCartButton.vue';
import OpenCompoundsButton from './OpenCompoundsButton.vue';
import SearchShortcutButton from './SearchShortcutButton.vue';
import { cartModel } from './model';

const { hits, fetchSelectedHits, isLoading } = cartModel;
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const isVisible = computed(() => {
	return (
		router.currentRoute.value.path !== '/signup' &&
		router.currentRoute.value.path !== '/login' &&
		router.currentRoute.value.path !== '/reset' &&
		router.currentRoute.value.path !== '/link-expired' &&
		router.currentRoute.value.path !== '/verify-email' &&
		router.currentRoute.value.path !== '/onboarding' &&
		router.currentRoute.value.path !== '/' &&
		router.currentRoute.value.name !== 'chat-details' &&
		router.currentRoute.value.path !== '/pipeline' &&
		router.currentRoute.value.name !== 'job-pipeline' &&
		router.currentRoute.value.name !== 'chat-table-result' &&
		router.currentRoute.value.path !== '/competitive' &&
		router.currentRoute.value.path !== '/cart/compounds' &&
		router.currentRoute.value.path !== '/tools'
	);
});

const isChats = computed(() => {
	return router.currentRoute.value.name === 'chat-details';
});

const title = computed(() => {
	if (hits.value.length === 0) {
		return 'Cart is empty';
	}

	return `${hits.value.length} ${hits.value.length === 1 ? 'molecule' : 'molecules'}`;
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	fetchSelectedHits();
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};
</script>

<style lang="stylus" scoped>
.cart-button-container
	position: fixed
	bottom: 1.5rem
	right: 1.5rem
	z-index 10
	&.cart-button-container--chats
		bottom 5.5rem
	.cart-button-target
		height: 3.5rem;
		padding 1rem
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		gap 0.5rem
		border-radius 1rem
		background: var(--primary-700)
		cursor pointer
		user-select none
		transition all 0.2s ease-in-out
		.footnoteBold
			color white
		&:hover
			background var(--primary-800)
		&--active
			background var(--primary-800)
			transition all 0.2s ease-in-out
		&--empty
			pointer-events none
			background var(--neutral-200)
			.footnoteBold
				color var(--neutral-400)

		.is-loading-icon
			animation: spin 1s linear infinite

	.cart-button-dropdown
		position: absolute
		right: 0
		bottom 4rem
		margin-top: 0.5rem
		display: flex;
		max-height 23rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.cart-button-item
			white-space: nowrap
</style>
