<template>
	<div
		class="b-histogram-slider"
		:class="{
			'b-histogram-slider--opened': isOpened && !isDisabled,
			'b-histogram-slider--disaled': isDisabled,
		}"
	>
		<div class="b-histogram-slider--header" @click="onOpen">
			<section>
				<div class="subheadlineSemiBold">{{ title }}</div>
				<div v-if="isDisabled" class="bodySmallRegular">
					{{ value[0] }}
				</div>
				<div v-else class="bodySmallRegular">
					{{ value[0] + ' - ' + value[1] }}
				</div>
			</section>
			<bUnstyledButton @onClick="onOpen">
				<template v-slot:content>
					<IconArrowUp v-if="isOpened" size="1.125rem" />
					<IconArrowDown v-else size="1.125rem" />
				</template>
			</bUnstyledButton>
		</div>
		<template v-if="isOpened && !isDisabled">
			<bHistogram
				:min="value[0]"
				:max="value[1]"
				:absoluteMin="absoluteMin"
				:absoluteMax="absoluteMax"
				:histogramData="items"
			/>
			<Slider
				v-model="value"
				:min="absoluteMin"
				:max="absoluteMax"
				:class="{ 'b-slider-input__disabled': false }"
				:step="byStep"
				:format="{
					decimals: byStep === -1 ? 2 : 0,
				}"
				@change="sliderUpdate"
			/>
			<div v-if="value" class="b-histogram-slider--inputs">
				<bNumberInput
					v-model="value[0]"
					type="number"
					size="medium"
					:min="absoluteMin"
					:max="absoluteMax"
					:isMin="true"
					@onBlur="updateValue"
				/>
				<div class="bodySmallRegular">-</div>
				<bNumberInput
					v-model="value[1]"
					type="number"
					size="medium"
					:min="absoluteMin"
					:max="absoluteMax"
					:isMax="true"
					@onBlur="updateValue"
				/>

				<bTooltip
					:id="`reset-filter-tooltip-${index}`"
					hoverTitle="Reset filter"
					type="primary"
				>
					<template v-slot:body>
						<bUnstyledButton @onClick="onReset">
							<template v-slot:content>
								<IconReplay size="1.25rem" />
							</template>
						</bUnstyledButton>
					</template>
				</bTooltip>
			</div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { HistogramEntity, RangeFilter } from '@/shared/api';
import {
	IconArrowDown,
	IconArrowUp,
	IconReplay,
	bHistogram,
	bNumberInput,
	bUnstyledButton,
	bTooltip,
} from '@/shared/uikit';
import Slider from '@vueform/slider';
import type { PropType } from 'vue';
import { nextTick, ref, watch, watchEffect } from 'vue';

const emit = defineEmits(['update:modelValue', 'onChange']);

const internalUpdate = ref(false);
const value = ref<Array<number>>([0, 0]);
const isOpened = ref(true);

const props = defineProps({
	modelValue: {
		type: Object as () => RangeFilter,
		required: true,
	},
	min: {
		type: Number,
		default: 0,
	},
	max: {
		type: Number,
		default: 100,
	},
	absoluteMin: {
		type: Number,
		default: 0,
	},
	absoluteMax: {
		type: Number,
		default: 100,
	},
	items: {
		type: Array as PropType<HistogramEntity[]>,
		default: [],
	},
	title: {
		type: String,
		default: '',
	},
	byStep: {
		type: Number,
		default: -1,
	},
	index: {
		type: Number,
		default: 0,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
	isForceOpen: {
		type: Boolean,
		default: false,
	},
});

watchEffect(() => {
	if (props.modelValue && !internalUpdate.value) {
		value.value = [props.modelValue.min, props.modelValue.max];
	}

	nextTick(() => {
		isOpened.value = props.isForceOpen
			? true
			: localStorage.getItem('isOpenedFilter' + props.index) === 'true';
	});
});

watch(value, (newValue, oldValue) => {
	if (newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) {
		emit('update:modelValue', new RangeFilter(newValue[0], newValue[1]));
		nextTick(() => {
			internalUpdate.value = false;
		});
	} else if (newValue[0] == newValue[1]) {
		emit(
			'update:modelValue',
			new RangeFilter(props.absoluteMin, props.absoluteMax)
		);
		nextTick(() => {
			internalUpdate.value = false;
		});
	}
});

const updateValue = (event: InputEvent) => {
	if (!value.value) return;

	if (
		value.value[0] < props.absoluteMin ||
		value.value[0] > props.absoluteMax
	) {
		value.value[0] = props.absoluteMin;
	}

	if (
		value.value[1] < props.absoluteMin ||
		value.value[1] > props.absoluteMax
	) {
		value.value[1] = props.absoluteMax;
	}

	if (value.value[0] === value.value[1]) {
		value.value[0] = props.absoluteMin;
		value.value[1] = props.absoluteMax;
	}

	emit('update:modelValue', new RangeFilter(value.value[0], value.value[1]));
};

const sliderUpdate = (newValue: number[]) => {
	if (newValue[0] === newValue[1]) {
		value.value[0] = props.absoluteMin;
		value.value[1] = props.absoluteMax;
		emit(
			'update:modelValue',
			new RangeFilter(value.value[0], value.value[1])
		);
	}
};

const onOpen = () => {
	if (props.isDisabled) return;
	isOpened.value = !isOpened.value;
	localStorage.setItem(
		'isOpenedFilter' + props.index,
		isOpened.value.toString()
	);
};

const onReset = () => {
	value.value = [props.absoluteMin, props.absoluteMax];

	emit(
		'update:modelValue',
		new RangeFilter(props.absoluteMin, props.absoluteMax)
	);
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="stylus" scoped>
.b-histogram-slider
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content center
	padding 1rem
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	z-index 1
	&:hover
		background var(--neutral-50)
		cursor pointer
	&--disaled
		background var(--neutral-50)
		&:hover
			cursor not-allowed !important
		.b-histogram-slider--header
			&:hover
				cursor not-allowed !important

	&--opened
		background var(--neutral-50)
		&:hover
			cursor default
		.b-histogram-slider--header
			padding-bottom 1rem
	&--header
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		cursor pointer
		section
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			.bodySmallRegular
				color var(--neutral-600)
				white-space nowrap
		.b-unstyled-button
			border-radius 50%
	&--controls
		width 100%
		margin-top 1rem
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		gap 1rem
		.b-unstyled-button
			padding 0.5rem
			border-radius 50%
	&--inputs
		width 100%
		margin-top 1rem
		display flex
		flex-direction row
		align-items center
		justify-content center
		gap 0.75rem
		// .b-simple-number-input
		// 	width 6rem
		.bodySmallRegular
			color var(--neutral-400)
</style>

<style lang="stylus">
.slider-target
    width 100%
.slider-connect
    background: var(--primary-600)
.slider-tooltip
    display: none
    border none
    background: var(--neutral-800)
    font-family: var(--font-family)
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding 2px 4px
    transition: all .25s

.slider-tooltip-top
	bottom: calc(14px + var(--slider-tooltip-arrow-size, 5px) + var(--slider-tooltip-distance, 3px)) !important
	&::before
		display: none
.slider-handle
	// border: 1px solid var(--neutral-300);
	// box-shadow: none !important
	// &:hover
		// border: 1px solid var(--neutral-800);
	box-shadow: none !important
	&:active
		border: 1px solid var(--primary-600);
		transition: all .25s
		.slider-tooltip
			display: block

.slider-base
	background-color: var(--primary-200)
	.slider-origin
		&:nth-child(2)
			margin-right -10px !important
		&:nth-child(3)
			margin-right 10px !important

.slider-active
    box-shadow: none !important

.b-sliders__disabled
    .slider-base
        background-color: var(--neutral-100)
    .slider-handle
        background var(--neutral-200)
        user-select: none
        pointer-events: none
        border: 1px solid var(--neutral-200)
        box-shadow: none
    .slider-connect
        background-color: var(--neutral-200)


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input:disabled {
    color var(--neutral-500) !important
}
.slider-base
    height 4px !important
.slider-touch-area
	width: 20px;
	height: 20px;
.slider-handle
	background var(--primary-600)
	border: 1px solid var(--primary-600)
:root {
    --slider-handle-width: 20px;
    --slider-handle-height: 20px;
}
</style>
