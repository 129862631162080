export const colorProp = {
	type: String,
	default: '#334155',
};

export const sizeProp = {
	type: [String, Number],
	default: '1.5rem',
};

export type IconType = {
	color?: string;
	size?: string | number;
};
