import type { ZoomableSVG } from './ZoomableSVG';

export class DashboardMinimap {
	minimap: SVGSVGElement;
	minimapViewbox: HTMLDivElement;
	dashboard: ZoomableSVG;
	width: number;
	height: number;
	aspectRatio: number;

	constructor(
		minimap: SVGSVGElement,
		minimapViewbox: HTMLDivElement,
		dashboard: ZoomableSVG,
		width: number,
		height: number,
		aspectRatio: number = 2 / 3
	) {
		this.minimap = minimap;
		this.minimapViewbox = minimapViewbox;
		this.dashboard = dashboard;
		this.width = width;
		this.height = height;
		this.aspectRatio = aspectRatio;
	}

	draw() {
		const { scale, originX, originY, svg, contentHeight, contentWidth } =
			this.dashboard;
		const viewBoxWidth = svg.clientWidth / scale;
		const viewBoxHeight = svg.clientHeight / scale;

		const minimapWidth = this.width;
		const minimapHeight = this.height * this.aspectRatio;

		let viewBoxScaledWidth = minimapWidth * (viewBoxWidth / contentWidth);
		let viewBoxScaledHeight =
			minimapHeight * (viewBoxHeight / contentHeight);
		let viewBoxLeft = minimapWidth * (originX / contentWidth);
		let viewBoxTop = minimapHeight * (originY / contentHeight);

		// Проверка на выход за границы
		if (viewBoxLeft < 0) {
			viewBoxLeft = 0;
		} else if (viewBoxLeft + viewBoxScaledWidth > minimapWidth) {
			viewBoxLeft = minimapWidth - viewBoxScaledWidth;
		}

		if (viewBoxTop < 0) {
			viewBoxTop = 0;
		} else if (viewBoxTop + viewBoxScaledHeight > minimapHeight) {
			viewBoxTop = minimapHeight - viewBoxScaledHeight;
		}

		if (viewBoxScaledWidth > minimapWidth) {
			viewBoxLeft = 0;
			viewBoxScaledWidth = minimapWidth;
		} else if (viewBoxScaledHeight > minimapHeight) {
			viewBoxTop = 0;
			viewBoxScaledHeight = minimapHeight;
		}

		this.minimapViewbox.style.width = `${viewBoxScaledWidth}px`;
		this.minimapViewbox.style.height = `${viewBoxScaledHeight}px`;
		this.minimapViewbox.style.left = `${viewBoxLeft}px`;
		this.minimapViewbox.style.top = `${viewBoxTop}px`;

		// Очистка миникарты перед отрисовкой
		while (this.minimap.firstChild) {
			this.minimap.removeChild(this.minimap.firstChild);
		}

		// Отрисовка элементов дашборда на миникарте
		const fragment = document.createDocumentFragment();
		this.dashboard.elementManager.elements.forEach((element: any) => {
			const rect = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'rect'
			);
			rect.setAttribute(
				'x',
				`${(minimapWidth * element.x) / contentWidth}`
			);
			rect.setAttribute(
				'y',
				`${(minimapHeight * element.y) / contentHeight}`
			);
			rect.setAttribute(
				'width',
				`${(minimapWidth * element.width) / contentWidth}`
			);
			rect.setAttribute(
				'height',
				`${(minimapHeight * element.height) / contentHeight}`
			);
			rect.setAttribute('fill', '#cbd5e1');
			const borderRadius = 0.5;
			rect.setAttribute('rx', `${borderRadius}`);
			rect.setAttribute('ry', `${borderRadius}`);

			fragment.appendChild(rect);
		});
		this.minimap.appendChild(fragment);
	}

	initListeners() {
		this.dashboard.svg.addEventListener('wheel', this.draw.bind(this));
		this.dashboard.svg.addEventListener('mousedown', this.draw.bind(this));
		this.dashboard.svg.addEventListener('mousemove', this.draw.bind(this));
		this.dashboard.svg.addEventListener('mouseup', this.draw.bind(this));
	}

	removeListeners() {
		this.dashboard.svg.removeEventListener('wheel', this.draw.bind(this));
		this.dashboard.svg.removeEventListener(
			'mousedown',
			this.draw.bind(this)
		);
		this.dashboard.svg.removeEventListener(
			'mousemove',
			this.draw.bind(this)
		);
		this.dashboard.svg.removeEventListener('mouseup', this.draw.bind(this));
	}
}
