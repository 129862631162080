import { ExternalHitMetadata, SearchAttributeType } from '../jobs';

export class TargetEntity {
	constructor(
		public id: string,
		public probes_count: number,
		public hgnc_symbol: string,
		public uni_prot_kb_ac: string,
		public external_url: ExternalHitMetadata
	) {}

	static fromObject(data: any): TargetEntity {
		return new TargetEntity(
			data.id,
			data.probes_count,
			data.hgnc_symbol,
			data.uni_prot_kb_ac,
			ExternalHitMetadata.fromObject(
				data.external_url
			) as ExternalHitMetadata
		);
	}
	get formattedProbesCount(): string {
		const str = this.probes_count.toLocaleString('en-US');
		return str + (this.probes_count > 1 ? ' ligands' : ' ligand');
	}
	get name(): string {
		if (!this.hgnc_symbol) {
			return this.uni_prot_kb_ac;
		}

		return this.hgnc_symbol + ' (' + this.uni_prot_kb_ac + ')';
	}
}

export class ProbesMetadata {
	constructor(
		public smiles: string,
		public external_id: string
	) {}

	static fromObject(data: any): ProbesMetadata {
		return new ProbesMetadata(data.smiles, data.external_id);
	}
}

export class ProbesEntity {
	constructor(
		public catalog_id: string,
		public index: number,
		public attributes: Record<SearchAttributeType, number>,
		public external_url: ExternalHitMetadata,
		public activity: number,
		public smiles: string
	) {}

	static fromObject(data: any): ProbesEntity {
		const attributes: Record<SearchAttributeType, number> = {} as Record<
			SearchAttributeType,
			number
		>;

		for (const key in data.attributes) {
			if (key in SearchAttributeType) {
				attributes[key as SearchAttributeType] = data.attributes[key];
			}
		}

		return new ProbesEntity(
			data.catalog_id,
			data.index,
			attributes,
			ExternalHitMetadata.fromObject(
				data.external_url
			) as ExternalHitMetadata,
			data.activity,
			data.smiles
		);
	}

	get formatActivityToLaTeX(): string {
		if (this.activity < 0.001 || this.activity > 1000) {
			const expString = this.activity.toExponential();
			const [base, exponent] = expString.split('e');
			const sign = exponent.startsWith('+') ? '' : '-';

			let truncatedNumber: number =
				Math.floor(Number(base) * 1000) / 1000;
			let formattedNumber: string = truncatedNumber.toFixed(3);

			return `${parseFloat(formattedNumber)} \\times 10^{${sign}${exponent.slice(1)}}`;
		} else {
			let truncatedNumber: number =
				Math.floor(Number(this.activity) * 1000) / 1000;
			let formattedNumber: string = truncatedNumber.toFixed(3);

			return formattedNumber.toString();
		}
	}
}
