<template>
	<div class="b-simple-number-input">
		<input
			ref="inputRef"
			:value="modelValue"
			@input="updateValue($event as InputEvent)"
			:class="classInputName"
			:placeholder="placeholder"
			:max="max"
			:min="min"
			type="number"
			autocomplete="off"
			class="b-number-input"
			@blur="onBlur($event)"
			@focus="$emit('onFocus', $event)"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus']);

const inputRef = ref<any>(null);

const props = defineProps({
	modelValue: Number,
	type: { type: String, required: false },
	size: { type: String, required: false, default: 'medium' },
	placeholder: { type: String, required: false },
	isDisabled: { type: Boolean, required: false, default: false },
	min: { type: Number, required: true },
	max: { type: Number, required: true },
	isMin: { type: Boolean, required: false, default: false },
	isMax: { type: Boolean, required: false, default: false },
});

const classInputName = computed((): any => {
	var result = {
		'b-number-input--size-medium': props.size === 'medium',
		'b-number-input--size-small': props.size === 'small',
		'b-number-input--size-large': props.size === 'large',
		'b-number-input--disabled': props.isDisabled,
	};
	return result;
});

const updateValue = (event: InputEvent) => {
	const target = event.target as HTMLInputElement;
	let value = Number(target.value);

	emit('update:modelValue', value);
};

const onBlur = (event: FocusEvent) => {
	emit('onBlur', event);

	let value = Number(props.modelValue);

	if (value < props.min) {
		emit('update:modelValue', props.min);
		return;
	} else if (value > props.max) {
		emit('update:modelValue', props.max);
		return;
	}
};
</script>

<style lang="stylus" scoped>
.b-simple-number-input
	width 100%
	input
		all: unset;
	.b-number-input
		width 100%
		border: 1px solid black;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		font-family: var(--font-family);
		font-style: normal;
		font-weight: 400;
		background white
		border: 1px solid var(--neutral-200);
		border-radius: 0.5rem;
		transition-property: background;
		transition-duration: .25s;
		transition-timing-function: ease-in
		&:focus
			border-color: var(--primary-600);
			transition-property: background;
			transition-duration: .25s;
			transition-timing-function: ease-in
		&:hover
			background var(--neutral-50);
			transition-property: background;
			transition-duration: .25s;
			transition-timing-function: ease-in
		&::placeholder
			color: var(--neutral-500)
		&--size-large
			height 3.5rem
			font-size: 0.875rem;
			padding: 1rem
		&--size-medium
			height 2.375rem
			font-size: 1rem;
			padding: 0.75rem 1rem;
		&--size-small
			height 2rem
			font-size: 0.75rem;
			padding: 0.5rem 0.75rem;
		&--disabled
			pointer-events none
			user-select none
			background var(--neutral-200)
</style>
