<template>
	<div class="logout-menu-item" @click="onClick">
		<IconLogout size="1.25rem" :color="ThemeColors.danger[600]" />
		<div class="footnoteSemiBold">{{ $t('auth.button.logout') }}</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { modalsModel } from '@/entities/modals/';
import { IconLogout, ThemeColors } from '@/shared/uikit/';

const emit = defineEmits(['onClick']);
const { isLogout } = modalsModel;

const onClick = () => {
	isLogout.value = true;
	Analytics.send(AnalyticsEvents.Logout.OPEN_LOGOUT_MODAL);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.logout-menu-item
	padding 0.75rem
	width 100%
	display: flex
	flex-direction: row
	align-items: center
	justify-content: flex-start
	gap 0.75rem
	border-radius 0.5rem
	&:hover
		background var(--danger-50)
		cursor pointer
	.footnoteSemiBold
		color var(--danger-600)
</style>
