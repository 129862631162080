<template>
	<div class="create-collection-with-molecule" @click.stop="onClick">
		Create a new collection and add to it
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import type { HitEntity } from '@/shared/api';
import { type PropType } from 'vue';
import { createCollectionModel } from './model';

const emit = defineEmits(['onClick']);

const { isVisible, includedHit } = createCollectionModel;

const props = defineProps({
	hit: {
		type: Object as PropType<HitEntity>,
		required: true,
	},
});

const onClick = () => {
	includedHit.value = props.hit;
	isVisible.value = true;
	Analytics.send(AnalyticsEvents.Collections.OPEN_CREATE_COLLECTION_MODAL);

	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.create-collection-with-molecule
	width 100%
	display flex
	flex-direction row
	align-items center
	justify-content flex-start
	padding: 0.625rem 1rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	color var(--neutral-700)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
