import { ChatService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { copilotChatsModel } from '@/widgets/chats';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
}

const data: IViewModel = {
	isLoading: false,
};

const state = reactive(data);

async function deleteChat(id: string) {
	state.isLoading = true;

	try {
		const { chats, fetchChats } = copilotChatsModel;
		chats.value = chats.value.filter((chat) => chat.id !== id);

		await ChatService.getInstance().deleteChat(id);
		await fetchChats(1, false);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const deleteChatButtonModel = {
	...toRefs(state),
	deleteChat,
};
