<template>
	<aside class="chats-widget">
		<template v-if="isOpened">
			<div
				class="chats-widget--header"
				:class="{ 'chats-widget--header--shadow': isShadow }"
			>
				<bTooltip
					:id="`close-side-panel-tooltip`"
					hoverTitle="Close side panel"
					type="primary"
				>
					<template v-slot:body>
						<bUnstyledButton @click="togglePanel">
							<template v-slot:content>
								<IconSidePanel size="1.25rem" />
							</template>
						</bUnstyledButton>
					</template>
				</bTooltip>
				<bTooltip
					:id="`new-chat-tooltip`"
					hoverTitle="New chat"
					type="primary"
				>
					<template v-slot:body>
						<bUnstyledButton @onClick="onClickNewChat">
							<template v-slot:content>
								<IconEdit size="1.25rem" />
							</template>
						</bUnstyledButton>
					</template>
				</bTooltip>
			</div>

			<div class="chats-widget--body">
				<div class="main-chat-item" @click="onClickNewChat">
					<IconRobot size="1.25rem" />
					<div class="footnoteSemiBold">Get Started</div>
				</div>
				<div class="main-chat-item" @click="isVisibleTools = true">
					<IconGrid size="1.25rem" />
					<div class="footnoteSemiBold">Available tools</div>
				</div>
			</div>

			<bDivider v-if="chats.length > 0" />

			<div class="chats-widget--footer">
				<ChatCardItem
					v-for="chat in chats"
					:key="'chats-key-' + chat.id"
					:chat="chat"
					:isCurrentChat="currentChat?.id === chat.id"
					@on-remove="onRemoveChat(chat.id)"
				/>
			</div>
		</template>
	</aside>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { ChatCardItem } from '@/entities/chats';
import { chatAvailableToolsModalModel } from '@/features/chats';
import {
	IconEdit,
	IconGrid,
	IconSidePanel,
	bDivider,
	bTooltip,
	bUnstyledButton,
} from '@/shared/uikit';
import IconRobot from '@/shared/uikit/components/icons/applications/IconRobot.vue';
import { chatDetailsModel, chatWithMainModel } from '@/widgets/chats';
import { onMounted, ref } from 'vue';
import { asideChatsModel } from './model';

const { isVisible: isVisibleTools } = chatAvailableToolsModalModel;
const { chats, fetchChats, onRemoveChat } = asideChatsModel;
const { newChat, isLoading } = chatWithMainModel;
const { chat: currentChat } = chatDetailsModel;

const isShadow = ref<boolean>(false);

const emit = defineEmits(['update:isOpened']);

const props = defineProps({
	isOpened: {
		type: Boolean,
		default: false,
	},
});

const togglePanel = () => {
	emit('update:isOpened', !props.isOpened);
};

const onClickNewChat = () => {
	newChat();
	isLoading.value = false;
	currentChat.value = null;
	router.push('/');
};

onMounted(() => {
	fetchChats();
});
</script>

<style lang="stylus" scoped>
.chats-widget
	width 100%
	height 100%
	position relative
	border-right 1px solid var(--neutral-200)
	&--header
		position absolute
		width 100%
		padding 1rem
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		z-index 10
		&--shadow
			border-bottom-left-radius 1rem
			border-bottom-right-radius 1rem
			box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05)

	&--body
		overflow-y auto
		width 100%
		display flex
		flex-direction column
		align-items flex-start
		justify-content flex-start
		gap 0.25rem
		padding 4rem 0.5rem
		padding-bottom 0.5rem
		&::-webkit-scrollbar
			display none
		.main-chat-item
			width 100%
			display flex
			flex-flow row nowrap
			align-items center
			justify-content flex-start
			gap 0.5rem
			padding: 0.5rem
			border-radius 0.25rem

			&:hover
				background: var(--neutral-100)
				cursor pointer
			.footnoteSemiBold
				color var(--neutral-800)
				letter-spacing: 0.00625rem
	&--footer
		width 100%
		height calc(100vh - 15.5rem)
		display flex
		flex-direction column
		align-items flex-start
		justify-content flex-start
		gap 0.25rem
		padding 0.5rem
		padding-bottom 1rem
		overflow scroll
		&::-webkit-scrollbar
			display none
		.main-chat-item
			width 100%
			display grid
			grid-template-columns auto 1fr
			align-items center
			justify-content flex-start
			gap 0.5rem
			padding: 0.5rem
			border-radius 0.25rem

			&:hover
				background: var(--neutral-100)
				cursor pointer
			.footnoteSemiBold
				color var(--neutral-800)
				letter-spacing: 0.00625rem
</style>
