import { decimalAdjust } from '@/shared/lib';

export class HistogramEntity {
	start: number;
	end: number;
	count: number;

	constructor(start: number, end: number, count: number) {
		this.start = start;
		this.end = end;
		this.count = count;
	}
}

export class RangeFilter {
	min: number;
	max: number;

	constructor(min: number, max: number) {
		this.min = min;
		this.max = max;
	}

	static null(): RangeFilter {
		return new RangeFilter(0, 0);
	}
}

export class MoleculeRangeFilter {
	min: number;
	max: number;
	histogramItems: HistogramEntity[];

	constructor(min: number, max: number, histogramItems: HistogramEntity[]) {
		this.min = min;
		this.max = max;
		this.histogramItems = histogramItems;
	}

	compare(other: MoleculeRangeFilter): boolean {
		return (
			this.min === other.min &&
			this.max === other.max &&
			this.histogramItems.length === other.histogramItems.length &&
			this.histogramItems.every((item, index) => {
				return (
					item.start === other.histogramItems[index].start &&
					item.end === other.histogramItems[index].end &&
					item.count === other.histogramItems[index].count
				);
			})
		);
	}
}

export class MoleculeEntity {
	id: string;
	metadata: any;
	index_id: string;
	index: number;
	hba: number;
	hbd: number;
	hac: number;
	log_p: number;
	score: number;
	tpsa: number;
	weight: number;
	f_csp3: number;
	rotatable_bonds: number;
	molar_refractivity: number;
	collections: string[];
	props: any[] = [];

	constructor(
		id: string,
		metadata: any,
		index_id: string,
		index: number,
		hba: number,
		hbd: number,
		hac: number,
		log_p: number,
		score: number,
		tpsa: number,
		weight: number,
		f_csp3: number,
		rotatable_bonds: number,
		molar_refractivity: number,
		collections: string[]
	) {
		this.id = id;
		this.metadata = metadata;
		this.index_id = index_id;
		this.index = index;
		this.hba = hba;
		this.hbd = hbd;
		this.hac = hac;
		this.log_p = log_p;
		this.score = score;
		this.tpsa = tpsa;
		this.weight = weight;
		this.f_csp3 = f_csp3;
		this.rotatable_bonds = rotatable_bonds;
		this.molar_refractivity = molar_refractivity;
		this.collections = collections;
	}

	get smiles(): string {
		return this.metadata.smiles;
	}

	get isFavorite(): boolean {
		return this.collections.length > 0;
	}

	get druglikeness(): DruglikenessEntity[] {
		const druglikeness = DruglikenessEntity.options.filter((option) => {
			return option.attributes.every((attribute) => {
				const value = this[attribute.attribute as keyof MoleculeEntity]; // Cast the attribute to keyof MoleculeEntity
				return value >= attribute.min && value <= attribute.max;
			});
		});

		return druglikeness;
	}
}

export enum SearchAttributeType {
	HBA = 'hba',
	HBD = 'hbd',
	LOG_P = 'log_p',
	SCORE = 'score',
	WEIGHT = 'weight',
	HAC = 'hac',
	FCSP3 = 'f_csp3',
	MOLAR_REFRACTIVITY = 'molar_refractivity',

	TOP_K = 'top_k',
	MAX_ACTIVITY = 'max_activity',
	ACTIVITY = 'activity',
	SIMILARITY = 'similarity',

	SIZE = 'size',
	INDEX = 'index',

	NOVELTY = 'novelty',

	//this is need for shared ADMET
	ROTATABLE_BONDS = 'rotatable_bonds',
	TPSA = 'tpsa',

	LOGP = 'logP',
	HBD_FULL = 'hydrogen_bond_donors',
	HBA_FULL = 'hydrogen_bond_acceptors',
	WEIGHT_FULL = 'molecular_weight',
}

const jobAttributeLabels: Record<SearchAttributeType, string> = {
	[SearchAttributeType.HBA]: 'HBA',
	[SearchAttributeType.HBD]: 'HBD',
	[SearchAttributeType.LOG_P]: 'LogP (Crippen)',
	[SearchAttributeType.SCORE]: 'Activity profile similarity',
	[SearchAttributeType.TPSA]: 'TPSA',
	[SearchAttributeType.WEIGHT]: 'MW',
	[SearchAttributeType.HAC]: 'HAC',
	[SearchAttributeType.FCSP3]: 'FSP3',
	[SearchAttributeType.ROTATABLE_BONDS]: 'Rotatable bonds',
	[SearchAttributeType.MOLAR_REFRACTIVITY]: 'Molar refractivity',
	[SearchAttributeType.TOP_K]: 'Top k',
	[SearchAttributeType.MAX_ACTIVITY]: 'Max activity',
	[SearchAttributeType.ACTIVITY]: 'Activity Value',
	[SearchAttributeType.SIMILARITY]: 'Similarity',
	[SearchAttributeType.SIZE]: 'Size',
	[SearchAttributeType.INDEX]: 'Index',
	[SearchAttributeType.NOVELTY]: 'ECFP4 Similarity',

	//this is need for shared ADMET

	[SearchAttributeType.LOGP]: 'LogP',
	[SearchAttributeType.HBA_FULL]: 'HBA',
	[SearchAttributeType.HBD_FULL]: 'HBD',
	[SearchAttributeType.WEIGHT_FULL]: 'Weight',
};

const toFixAttribute: Record<SearchAttributeType, number> = {
	[SearchAttributeType.HBA]: 0,
	[SearchAttributeType.HBD]: 0,
	[SearchAttributeType.LOG_P]: 2,
	[SearchAttributeType.SCORE]: 0,
	[SearchAttributeType.TPSA]: 2,
	[SearchAttributeType.WEIGHT]: 0,
	[SearchAttributeType.HAC]: 0,
	[SearchAttributeType.FCSP3]: 2,
	[SearchAttributeType.ROTATABLE_BONDS]: 0,
	[SearchAttributeType.MOLAR_REFRACTIVITY]: 2,
	[SearchAttributeType.TOP_K]: 0,
	[SearchAttributeType.MAX_ACTIVITY]: 2,
	[SearchAttributeType.ACTIVITY]: 0,
	[SearchAttributeType.SIMILARITY]: 2,
	[SearchAttributeType.SIZE]: 0,
	[SearchAttributeType.INDEX]: 0,
	[SearchAttributeType.NOVELTY]: 2,

	//this is need for shared ADMET

	[SearchAttributeType.LOGP]: 2,
	[SearchAttributeType.HBA_FULL]: 0,
	[SearchAttributeType.HBD_FULL]: 0,
	[SearchAttributeType.WEIGHT_FULL]: 0,
};

export const getSearchAttributeTypeToFix = (
	type: SearchAttributeType
): number => toFixAttribute[type] || 0;

export const getSearchAttributeTypeLabel = (
	type: SearchAttributeType
): string => jobAttributeLabels[type] || type;

export class SearchesAttributeEntity {
	min: number;
	max: number;
	absolute_min: number;
	absolute_max: number;
	type: SearchAttributeType;
	histogram: HistogramEntity[];
	isDisabled: boolean = false;
	resetDefault: RangeFilter = RangeFilter.null();

	constructor(
		min: number,
		max: number,
		absolute_min: number,
		absolute_max: number,
		type: SearchAttributeType,
		histogram: HistogramEntity[]
	) {
		const toFixedValue = (): number => {
			switch (type) {
				case SearchAttributeType.HBA:
					return 0;
				case SearchAttributeType.HBD:
					return 0;
				case SearchAttributeType.TPSA:
					return 2;
				case SearchAttributeType.LOG_P:
					return 3;
				case SearchAttributeType.SCORE:
					return 6;
				case SearchAttributeType.WEIGHT:
					return 0;
				default:
					return 2;
			}
		};

		this.min = decimalAdjust('floor', min, -toFixedValue());
		this.max = decimalAdjust('ceil', max, -toFixedValue());
		this.absolute_min = decimalAdjust(
			'floor',
			absolute_min,
			-toFixedValue()
		);
		this.absolute_max = decimalAdjust(
			'ceil',
			absolute_max,
			-toFixedValue()
		);

		this.type = type;
		this.histogram = histogram;
		this.isDisabled = min === max;
	}

	get filterRange(): RangeFilter {
		return new RangeFilter(this.min, this.max);
	}

	get absuluteFilterRange(): RangeFilter {
		return new RangeFilter(this.absolute_min, this.absolute_max);
	}

	static fromObject(data: any): SearchesAttributeEntity {
		if (data?.type) {
			return new SearchesAttributeEntity(
				data.min,
				data.max,
				data.absolute_min,
				data.absolute_max,
				data.type,
				data?.histogram?.map((item: any) => {
					return new HistogramEntity(
						item.start,
						item.end,
						item.count
					);
				}) ?? []
			);
		}

		return new SearchesAttributeEntity(
			data.minimum,
			data.maximum,
			data.minimum,
			data.maximum,
			data.attribute,
			data?.histogram?.map((item: any) => {
				return new HistogramEntity(item.start, item.end, item.count);
			}) ?? []
		);
	}

	get originalData(): any {
		return {
			attribute: this.type,
			minimum: this.min,
			maximum: this.max,
		};
	}
}

export class SearchesResultResponse {
	count: number;
	results: MoleculeEntity[];

	constructor(count: number, results: MoleculeEntity[]) {
		this.count = count;
		this.results = results;
	}
}

export interface FilterAttribute {
	attribute: SearchAttributeType;
	min: number;
	max: number;
}

export class DruglikenessEntity {
	label: string;
	attributes: FilterAttribute[];
	isSelected: boolean = false;

	constructor(label: string, attributes: any[]) {
		this.label = label;
		this.attributes = attributes;
	}

	static get options(): DruglikenessEntity[] {
		return [
			new DruglikenessEntity('Eagen', [
				{
					attribute: SearchAttributeType.TPSA,
					min: -1,
					max: 131.6,
				},
				{
					attribute: SearchAttributeType.LOG_P,
					min: -1,
					max: 5.88,
				},
			]),
			new DruglikenessEntity('Veber', [
				{
					attribute: SearchAttributeType.ROTATABLE_BONDS,
					min: -1,
					max: 10,
				},
				{
					attribute: SearchAttributeType.TPSA,
					min: -1,
					max: 140,
				},
			]),
			new DruglikenessEntity('Lipinski', [
				{
					attribute: SearchAttributeType.WEIGHT,
					min: -1,
					max: 500,
				},
				{
					attribute: SearchAttributeType.LOG_P,
					min: -1,
					max: 4.15,
				},
				{
					attribute: SearchAttributeType.HBD,
					min: -1,
					max: 5,
				},
				{
					attribute: SearchAttributeType.HBA,
					min: -1,
					max: 10,
				},
			]),
			new DruglikenessEntity('Ghose', [
				{
					attribute: SearchAttributeType.WEIGHT,
					min: 160,
					max: 480,
				},
				{
					attribute: SearchAttributeType.LOG_P,
					min: -0.4,
					max: 5.6,
				},
				{
					attribute: SearchAttributeType.HAC,
					min: 20,
					max: 70,
				},
				{
					attribute: SearchAttributeType.MOLAR_REFRACTIVITY,
					min: 40,
					max: 130,
				},
			]),
		];
	}
}

export enum SortDirection {
	Ascending = 'ascending',
	Descending = 'descending',
}

export class ResultsSortOption {
	label: string;
	type: SearchAttributeType;
	sortDirection: SortDirection;
	isSelected: boolean = false;

	constructor(type: SearchAttributeType, sortDirection: SortDirection) {
		this.label = getSearchAttributeTypeLabel(type);
		this.type = type;
		this.sortDirection = sortDirection;
	}

	static get defaultOption(): ResultsSortOption {
		return new ResultsSortOption(
			SearchAttributeType.SIMILARITY,
			SortDirection.Descending
		);
	}

	static get defaultCartOption(): ResultsSortOption {
		return new ResultsSortOption(
			SearchAttributeType.SIMILARITY,
			SortDirection.Descending
		);
	}

	static get defaultClusterOption(): ResultsSortOption {
		return new ResultsSortOption(
			SearchAttributeType.SIZE,
			SortDirection.Descending
		);
	}

	static mapWithSortDirection(data: any): ResultsSortOption[] {
		return [
			new ResultsSortOption(data.type, SortDirection.Ascending),
			new ResultsSortOption(data.type, SortDirection.Descending),
		];
	}
}
