import { router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	CollectionItemEntity,
	CollectionService,
	type PaginationPageType,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';
import { collectionFiltersModel } from '../collection-filters-widget';

interface IViewModel {
	isLoading: boolean;
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	collectibles: CollectionItemEntity[];
}
const data: IViewModel = {
	isLoading: false,
	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	collectibles: [],
};

const state = reactive(data);

watch(
	() => state.pageLimit,
	() => {
		fetchCollectionItems(1);
	},
	{ deep: true }
);

async function fetchCollectionItems(page: number) {
	const { id } = router.currentRoute.value.params;

	if (!id) {
		return;
	}

	state.currentPage = page;
	state.isLoading = true;
	try {
		const { filters } = collectionFiltersModel;

		const result =
			await CollectionService.getInstance().fetchCollectionItems(
				id as string,
				state.pageLimit.value,
				(page - 1) * state.pageLimit.value,
				filters.value
			);

		state.total = result.count;
		state.collectibles = result.collectibles;
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

function clear() {
	state.collectibles = [];
	state.total = 0;
	state.currentPage = 1;
	state.pageLimit = {
		title: '25',
		value: 25,
	} as PaginationPageType;
}

export const collectionItemsListModel = {
	...toRefs(state),
	fetchCollectionItems,
	clear,
};
