import { TokenService } from './TokenService';
import type { AuthProvider } from './providers';

export class AuthService {
	private static instance: AuthService;
	private provider: AuthProvider;
	private tokenService: TokenService = TokenService.getInstance();

	private constructor(provider: AuthProvider) {
		this.provider = provider;
	}

	public static getInstance(provider: AuthProvider): AuthService {
		if (
			!AuthService.instance ||
			AuthService.instance.provider !== provider
		) {
			AuthService.instance = new AuthService(provider);
		}
		return AuthService.instance;
	}

	async login(credentials: any): Promise<void> {
		const userCredential = await this.provider.login(credentials);
		const token = await userCredential.user?.getIdToken();
		if (token) {
			this.tokenService.saveToken(token);
		}
	}

	async logout(): Promise<void> {
		await this.provider.logout();
		this.tokenService.removeToken();
	}

	async signUp(credentials: any): Promise<void> {
		const userCredential = await this.provider.signUp(credentials);
		const token = await userCredential.user?.getIdToken();
		await this.login(credentials);
	}

	async sendPasswordResetEmail(email: string): Promise<void> {
		return await this.provider.sendPasswordResetEmail(email);
	}

	async updatePassword(password: string, oobCode?: string): Promise<void> {
		if (oobCode) {
			return await this.provider.resetPasswordByCode(password, oobCode);
		}

		return await this.provider.updatePassword(password);
	}

	async isExpiredOobCode(oobCode: string): Promise<Object> {
		return await this.provider.checkActionCode(oobCode);
	}

	async verifyUserEmail(oobCode: string): Promise<void> {
		await this.provider.verifyUserEmail(oobCode);
		const token = await this.provider.refreshToken();
		if (token) {
			this.tokenService.saveToken(token);
		}
	}

	async sendEmailVerification(email: string): Promise<void> {
		await this.provider.sendEmailVerification(email);
	}

	async getUser(): Promise<any> {
		return await this.provider.getFirebaseUser();
	}

	async authWithCustomToken(token: string): Promise<void> {
		await this.provider.authWithCustomToken(token);
		const newToken = await this.provider.refreshToken();
		if (token) {
			this.tokenService.saveToken(newToken);
		}
	}
}
