<template>
	<div class="container">
		<IconLogo size="2.75rem" :color="ThemeColors.primary[600]" />

		<div class="login-container">
			<div class="header-block">
				<div class="calloutRegular">{{ $t('auth.signin.title') }}</div>
			</div>

			<bDivider />

			<form @keyup.enter="tappedLogin">
				<bInput
					ref="inputRef"
					:placeholder="$t('auth.signin.form.emailPlaceholder')"
					type="email"
					size="large"
					@onChange="onChangeEmail"
					:isDisabled="false"
					:isCaption="false"
				/>
				<bInput
					ref="inputRef"
					:placeholder="$t('auth.signin.form.passwordPlaceholder')"
					type="password"
					size="large"
					@onChange="onChangePassword"
					:isDisabled="false"
					:isCaption="false"
				/>
				<LoginWithEmailButton
					:isDisabled="!(form.emailValid && form.passwordValid)"
					:email="form.email"
					:password="form.password"
				/>
				<bDivider label="or" />
				<LoginWithGoogleButton />
				<!-- <LoginWithMicrosoftButton /> -->
			</form>
		</div>
		<div class="login-footer">
			<bButton
				:label="$t('auth.button.signup')"
				type="tertiary"
				@onClick="router.push('/signup')"
			/>
			<bButton
				:label="$t('auth.button.forgotPassword')"
				type="tertiary"
				@onClick="router.push('/reset')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	LoginWithEmailButton,
	LoginWithGoogleButton,
	buttonLoginWithEmailModel,
} from '@/features/auth/';
import {
	IconLogo,
	ThemeColors,
	bButton,
	bDivider,
	bInput,
} from '@/shared/uikit';
import { reactive } from 'vue';

const { login } = buttonLoginWithEmailModel;

const form = reactive({
	email: '',
	emailValid: false,
	password: '',
	passwordValid: false,
});

const onChangeEmail = (value: any) => {
	form.email = value.value;
	form.emailValid = value.isValid;
};

const onChangePassword = (value: any) => {
	form.password = value.value;
	form.passwordValid = value.isValid;
};

const tappedLogin = () => {
	if (!(form.emailValid && form.passwordValid)) {
		return;
	}

	login(form.email, form.password);
};
</script>
<style lang="stylus">
.login-footer
	.b-button .b-button-label
		color var(--primary-600)
</style>

<style lang="stylus" scoped>
@import '../shared/auth.styl';

.b-divider
	margin 0 !important
</style>
