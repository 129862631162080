<template>
	<AuthPageTemplate>
		<template v-slot:body>
			<SignUpWidget />
		</template>
	</AuthPageTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { SignUpWidget } from '@/widgets/auth/';
import { onMounted } from 'vue';
import AuthPageTemplate from './AuthPageTemplate.vue';
onMounted(() => {
	Analytics.send(AnalyticsEvents.SignUp.VIEW_PAGE);
});
</script>
