<template>
	<div class="b-divider">
		<div v-if="label" class="b-divider-label footnoteSemiBold">
			<div :style="dividerStyle"></div>
			{{ label }}
			<div :style="dividerStyle"></div>
		</div>
		<div v-else :style="dividerStyle"></div>
	</div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';

const props = defineProps({
	color: {
		type: String,
		default: '#e2e8f0',
	},
	isVertical: {
		type: Boolean,
		default: false,
	},
	height: {
		type: String,
		default: '100%',
	},
	maring: {
		type: String,
		default: '0',
	},
	label: {
		type: String,
		required: false,
	},
});

const { label, color, isVertical, height, maring } = toRefs(props);

const dividerStyle = computed(() => ({
	backgroundColor: color.value,
	width: isVertical.value ? '1px' : height.value,
	height: isVertical.value ? height.value : '1px',
	margin: maring.value,
}));
</script>

<style lang="stylus" scoped>
.b-divider
	width 100%
	display flex
	flex-direction column
	justify-content center
	align-items center

.b-divider-label
	width 100%
	display flex
	align-items center
	justify-content center
	gap 0.5rem
	color var(--neutral-800)
	.dividerStyle
		flex-grow 1
		height 1px
		background-color #e2e8f0
		&:first-child
			margin-right 0.5rem
		&:last-child
			margin-left 0.5rem
</style>
