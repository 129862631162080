import {
	HistogramEntity,
	SearchAttributeType,
	SearchesAttributeEntity,
	type PaginationPageType,
} from '@/shared/api';
import { ocularRestErrorHandler, roundValues } from '@/shared/lib';
import Papa from 'papaparse';
import { computed, reactive, toRefs } from 'vue';
import { ADMETColumnEntity } from './entities';

interface IViewModel {
	isLoading: boolean;
	url: string;
	tableData: any[];
	parsedData: any[];
	columns: any[];
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	attributes: SearchesAttributeEntity[];
	downloadLink: string;
}

const data: IViewModel = {
	url: '',
	isLoading: false,
	tableData: [],
	parsedData: [],
	columns: [],

	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	attributes: [],
	downloadLink: '',
};

const state = reactive(data);

const isVisible = computed(() => state.url !== '');

async function downdloadAdmetFile(newUrl: string, isModal: boolean = true) {
	state.isLoading = true;
	if (isModal) {
		state.url = newUrl;
	} else {
		state.downloadLink = newUrl;
	}

	try {
		const response = await fetch(newUrl);
		const csvText = await response.text();
		const parsedData = Papa.parse(csvText, { header: true });

		const reorderedData = parsedData.data;
		state.total = parsedData.data.length;
		const filteredParsedData = reorderedData.map(roundValues);

		state.parsedData = parsedData.data;
		updateFilters();

		updateTableData();

		const preparedInfo = ADMETColumnEntity.options;
		state.columns = Object.keys(filteredParsedData[0] || {}).map((key) => ({
			prop: key,
			label: preparedInfo.find((item) => item.label === key)?.name || key,
		}));
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

function updateFilters() {
	if (state.parsedData.length === 0) {
		state.attributes = [];
		return;
	}

	if (state.parsedData.length < 10) {
		state.attributes = [];
		return;
	}

	const keysToInclude: SearchAttributeType[] = [
		SearchAttributeType.WEIGHT_FULL,
		SearchAttributeType.HBA_FULL,
		SearchAttributeType.HBD_FULL,
		SearchAttributeType.TPSA,
		SearchAttributeType.ROTATABLE_BONDS,
	];

	const filteredMap = state.parsedData.map((item) => {
		const filteredItem: Partial<Record<SearchAttributeType, number>> = {};
		Object.entries(item).forEach(([key, value]) => {
			if (keysToInclude.includes(key as SearchAttributeType)) {
				filteredItem[key as SearchAttributeType] = parseFloat(
					value as string
				);
			}
		});
		return filteredItem;
	});

	const histograms = keysToInclude.map((type) => {
		const values = filteredMap
			.map((item) => item[type])
			.filter(
				(value) => value !== undefined && !isNaN(value)
			) as number[];

		if (values.length === 0) {
			return { type, min: 0, max: 0, bins: [] };
		}

		const min = Math.min(...values);
		const max = Math.max(...values);
		const range = max - min;
		const binSize = range / 20;

		const bins = Array.from(
			{ length: 20 },
			(_, i) =>
				new HistogramEntity(
					min + i * binSize,
					min + (i + 1) * binSize,
					0
				)
		);

		values.forEach((value) => {
			const binIndex = Math.min(Math.floor((value - min) / binSize), 19);
			bins[binIndex].count += 1;
		});

		return { type, min, max, bins };
	});

	const attributes = histograms.map(({ type, min, max, bins }) => {
		return new SearchesAttributeEntity(min, max, min, max, type, bins);
	});

	state.attributes = attributes;
}

const filteredTableData = computed(() => {
	const isEmpty =
		state.attributes.map((item) => item.histogram).flat().length == 0;
	if (isEmpty) {
		return state.parsedData;
	}

	return state.parsedData.filter((item) => {
		return state.attributes.every((attr) => {
			const value = parseFloat(item[attr.type as string]);
			if (isNaN(value)) return false;
			return value >= attr.min && value <= attr.max;
		});
	});
});

function updateTableData() {
	const filteredData = filteredTableData;
	const start = (state.currentPage - 1) * state.pageLimit.value;
	const end = start + state.pageLimit.value;
	state.tableData = filteredData.value.slice(start, end);
}

function changePage(page: number) {
	state.currentPage = page;
	updateTableData();
}

function clear() {
	state.tableData = [];
	state.isLoading = false;
	state.url = '';
	state.columns = [];
	state.total = 0;
	state.currentPage = 1;
	state.pageLimit = {
		title: '25',
		value: 25,
	};
	state.downloadLink = '';
	setDefaultFilters();
}

function setDefaultFilters() {
	state.attributes.forEach((item) => {
		item.min = item.absolute_min;
		item.max = item.absolute_max;
	});
}

export const admetTableResultModel = {
	...toRefs(state),
	isVisible,
	downdloadAdmetFile,
	changePage,
	clear,
	updateTableData,
	filteredTableData,
	setDefaultFilters,
};
