<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_1030_6209)">
			<path
				d="M11.9999 4.50098C6.99988 4.50098 2.72988 7.61098 0.999878 12.001C2.72988 16.391 6.99988 19.501 11.9999 19.501C16.9999 19.501 21.2699 16.391 22.9999 12.001C21.2699 7.61098 16.9999 4.50098 11.9999 4.50098ZM11.9999 17.001C9.23988 17.001 6.99988 14.761 6.99988 12.001C6.99988 9.24098 9.23988 7.00098 11.9999 7.00098C14.7599 7.00098 16.9999 9.24098 16.9999 12.001C16.9999 14.761 14.7599 17.001 11.9999 17.001ZM11.9999 9.00098C10.3399 9.00098 8.99988 10.341 8.99988 12.001C8.99988 13.661 10.3399 15.001 11.9999 15.001C13.6599 15.001 14.9999 13.661 14.9999 12.001C14.9999 10.341 13.6599 9.00098 11.9999 9.00098Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1030_6209">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(-0.00012207 0.000976562)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
