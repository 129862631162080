<template>
	<AuthPageTemplate>
		<template v-slot:body>
			<TellAboutYourselfForm />
		</template>
	</AuthPageTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { TellAboutYourselfForm } from '@/features/onboarding/';
import { AuthPageTemplate } from '@/pages/auth';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.Onboarding.OPEN_ONBOARDING_PAGE);
});
</script>
