<template>
	<BottomDrawerTemplate
		title="Bioptic ADMET Predictor result"
		info=""
		:isVisible="isVisible"
		:isFull="true"
		@onClose="onClose"
		@onNext=""
	>
		<template v-slot:body>
			<ADMETTableWidget :isFull="false" />
		</template>
	</BottomDrawerTemplate>
</template>

<script setup lang="ts">
import { BottomDrawerTemplate } from '@/features/home/';
import { ADMETTableWidget, admetTableResultModel } from '@/widgets/chats';

const { isVisible, clear } = admetTableResultModel;

const onClose = () => {
	clear();
};
</script>

<style lang="stylus"></style>
