import { router } from '@/app/providers';
import { ocularUserModel } from '@/entities/app';
import {
	ChemcrowService,
	MessageEntity,
	MessageType,
	ToolType,
} from '@/shared/api';
import {
	generateUuid,
	getRandomInteger,
	ocularRestErrorHandler,
} from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { chatDetailsModel } from '..';
import { asideChatsModel } from '../chats-aside-widget/model';

interface IViewModel {
	messages: MessageEntity[];
	isLoading: boolean;
	isConnected: boolean;
	file: any;
}
const data: IViewModel = {
	messages: [],
	isLoading: false,
	isConnected: false,
	file: null,
};

const state = reactive(data);

async function connect(chat_id: string | null): Promise<void> {
	state.isLoading = true;
	try {
		const onopen = () => {};

		const onmessage = (event: MessageEvent) => {
			const receivedMessage = JSON.parse(event.data);

			const message = new MessageEntity(
				generateUuid(),
				receivedMessage.content,
				receivedMessage.type,
				receivedMessage.tool,
				new Date(Date.now())
			);

			state.messages.push(message);

			if (receivedMessage.type === MessageType.OUTPUT) {
				state.isLoading = false;

				router.push('/chat/' + receivedMessage.chat_id);
			}

			if (receivedMessage?.chat_id.toString() !== 'None') {
				const { fetchChats, chats } = asideChatsModel;
				const { fetchChat } = chatDetailsModel;

				fetchChats();
				fetchChat(receivedMessage.chat_id);
			}
			// concatToolMessages();
		};

		const onerror = (event: Event) => {
			throw new Error('WebSocket error');
		};

		const onclose = (event: CloseEvent) => {
			console.log('WebSocket closed:', event);
			state.isConnected = false;
		};

		await ChemcrowService.getInstance().connect(
			onopen,
			onmessage,
			onerror,
			onclose,
			chat_id
		);
	} catch (error: any) {
		const errorMessage = new MessageEntity(
			getRandomInteger(1, 1000).toString(),
			error.message,
			MessageType.ERROR,
			ToolType.NAME_TO_SMILES // Пример использования ToolType, можно изменить в зависимости от контекста
		);
		state.messages.push(errorMessage);
		state.isLoading = false;
		disconnect();
	}
}

async function disconnect(): Promise<void> {
	const chemcrowService = ChemcrowService.getInstance();
	await chemcrowService.disconnect();
	state.isConnected = false;
}

async function sendMessage(content: string, id: string | null): Promise<void> {
	const { fetchUser } = ocularUserModel;
	await fetchUser();

	try {
		if (!state.isConnected) {
			await connect(id);
		}

		if (state.file !== null) {
			content += ` <file_id>${state.file.id}</file_id>`;
			content += ` <file_name>${state.file.name}</file_name>`;
			state.file = null;
		}
		const chemcrowService = ChemcrowService.getInstance();
		await chemcrowService.sendMessage(content, id, (message) => {
			console.log('Message sent:', message);
		});
	} catch (error: any) {
		console.error('Error sending message:', error);
		const errorMessage = new MessageEntity(
			getRandomInteger(1, 1000).toString(),
			error.message,
			MessageType.ERROR,
			ToolType.DEFAULT
		);
		state.messages.push(errorMessage);
	}
}

function newChat() {
	state.messages = [];
	state.isConnected = false;
	disconnect();
}

function concatToolMessages() {
	const result: MessageEntity[] = [];
	const messageEntities = state.messages.map((msg) =>
		MessageEntity.fromObject(msg)
	);

	for (let i = 0; i < messageEntities.length; i++) {
		const message = messageEntities[i];

		if (message.type === 'tool_start') {
			const nextMessage = messageEntities[i + 1];
			if (nextMessage && nextMessage.type === 'tool_end') {
				message.output = nextMessage;
				result.push(message);
				i++;
			} else {
				result.push(message);
			}
		} else {
			result.push(message);
		}
	}

	state.messages = result;
}

async function uploadFile(file: File) {
	// state.isLoading = true;
	try {
		const fileId = await ChemcrowService.getInstance().uploadFile(file);

		state.file = {
			id: fileId,
			name: file.name,
			file: file,
		};
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const chatWithMainModel = {
	...toRefs(state),
	disconnect,
	sendMessage,
	newChat,
	concatToolMessages,
	uploadFile,
};
