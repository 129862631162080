import { cartSortModel } from '@/features/cart';
import {
	HitEntity,
	ResultsSortOption,
	SearchAttributeType,
	SortDirection,
	type PaginationPageType,
} from '@/shared/api';
import { IndexedDBHitsInstance } from '@/shared/api/client';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';

interface IViewModel {
	isLoading: boolean;
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	hits: HitEntity[];
}
const data: IViewModel = {
	isLoading: false,
	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	hits: [],
};

const state = reactive(data);

watch(
	() => state.pageLimit,
	() => {},
	{ deep: true }
);

async function fetchCartDetails() {
	state.isLoading = true;
	try {
		const { selectedOption } = cartSortModel;
		const dbHits = await IndexedDBHitsInstance.get();
		const result = await dbHits.getAll();

		state.hits = sortHits(result, selectedOption.value);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

function sortHits(
	entities: HitEntity[],
	selectedOption: ResultsSortOption
): HitEntity[] {
	return entities.sort((a, b) => {
		let aValue = getValue(a, selectedOption.type);
		let bValue = getValue(b, selectedOption.type);

		if (selectedOption.sortDirection === SortDirection.Ascending) {
			return aValue - bValue;
		} else {
			return bValue - aValue;
		}
	});
}

function getValue(
	entity: HitEntity,
	attributeType: SearchAttributeType
): number {
	switch (attributeType) {
		case SearchAttributeType.HAC:
		case SearchAttributeType.HBA:
		case SearchAttributeType.HBD:
		case SearchAttributeType.LOG_P:
		case SearchAttributeType.SCORE:
		case SearchAttributeType.TPSA:
		case SearchAttributeType.WEIGHT:
		case SearchAttributeType.FCSP3:
		case SearchAttributeType.ROTATABLE_BONDS:
		case SearchAttributeType.MOLAR_REFRACTIVITY:
			return entity.attributes[attributeType];
		case SearchAttributeType.TOP_K:
		case SearchAttributeType.MAX_ACTIVITY:
		case SearchAttributeType.ACTIVITY:
		case SearchAttributeType.SIMILARITY:
			// Для атрибутов, которые прямо не указаны в `attributes`, но являются частью HitEntity:
			return entity[attributeType as keyof HitEntity] as number;
		default:
			throw new Error('Attribute type not supported for sorting.');
	}
}

export const cartListModel = {
	...toRefs(state),
	fetchCartDetails,
};
