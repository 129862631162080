<template>
	<div
		class="clustering-container"
		:class="{ 'clustering-container--opened': isOpened }"
		ref="elementRef"
	>
		<div
			class="clustering-target--header"
			@click="() => (isOpened = !isOpened)"
		>
			<section>
				<div class="subheadlineSemiBold">View</div>
			</section>
			<bUnstyledButton @onClick="() => (isOpened = !isOpened)">
				<template v-slot:content>
					<IconArrowUp v-if="isOpened" size="1.125rem" />
					<IconArrowDown v-else size="1.125rem" />
				</template>
			</bUnstyledButton>
		</div>
		<div v-if="isOpened" class="clustering-dropdown">
			<div
				class="clustering-item"
				v-for="(option, index) in clusteringOptions"
				:key="'clustering-item-key' + index"
				:class="{
					'clustering-item--active': option.isSelected,
				}"
				@click="selectOption(option)"
			>
				<div class="clustering-item-info">
					<div class="footnoteBold">{{ option.label }}</div>
				</div>
				<bRadio
					v-model="option.isSelected"
					size="small"
					@update:modelValue="selectOption(option)"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { jobResultsSortModel } from '@/features/job';
import {
	IconArrowDown,
	IconArrowUp,
	bRadio,
	bUnstyledButton,
} from '@/shared/uikit';
import { defineEmits, onMounted, ref } from 'vue';
import { JobResultViewOption, JobResultViewType } from './entities';
import { jobResultsFiltersModel } from './model';

const emit = defineEmits(['onChange']);

const { clusteringOptions } = jobResultsFiltersModel;
const { updateOptions } = jobResultsSortModel;

const isOpened = ref(true);
const elementRef = ref<HTMLElement | null>(null);

const selectOption = (option: JobResultViewOption) => {
	clusteringOptions.value.forEach((item) => {
		item.isSelected = false;
	});

	option.isSelected = !option.isSelected;

	updateOptions();

	localStorage.setItem(JobResultViewOption.key, option.type);
	emit('onChange');

	Analytics.send(AnalyticsEvents.Filter.SELECT_CLUSTERING_TYPE, {
		type: option.type,
	});
};

onMounted(() => {
	const selectedType = localStorage.getItem(JobResultViewOption.key);

	if (selectedType) {
		const findSelected = clusteringOptions.value.find(
			(option) => option.type === selectedType
		);
		if (findSelected) {
			findSelected.isSelected = true;
		}
	} else {
		localStorage.setItem(
			JobResultViewOption.key,
			JobResultViewType.Ungrouped
		);
		clusteringOptions.value.find(
			(option) => option.type === JobResultViewType.Ungrouped
		)!.isSelected = true;
	}
});
</script>

<style lang="stylus" scoped>
.clustering-container
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content center
	padding 1rem
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	z-index 1
	&:hover
		background var(--neutral-50)
		cursor pointer
	&--opened
		background var(--neutral-50)
		&:hover
			cursor default
		.clustering-target--header
			padding-bottom 1rem
	.clustering-target--header
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		cursor pointer
		section
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			.bodySmallRegular
				color var(--neutral-600)
				white-space nowrap
		.b-unstyled-button
			border-radius 50%

	.clustering-dropdown
		margin-top: 0.5rem
		display: flex;
		width 100%
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border-radius: 0.25rem;
		background: var(--neutral-50);
		z-index 2
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.clustering-item
			width 100%
			height 2.5rem
			display grid
			grid-template-columns max-content auto
			align-items center
			justify-content space-between
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer
			&--active
				user-select none
				pointer-events none
			&--disabled
				user-select none
				pointer-events none
				.footnoteBold
					color var(--neutral-400) !important

			.clustering-item-info
				display flex
				flex-direction column
				align-items flex-start
				justify-content center
				gap 0
				.footnoteBold
					color var(--neutral-700)
				span
					color var(--neutral-400) !important


.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
