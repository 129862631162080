import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import type { NavigationTabEntity } from '@/shared/api';
import { copyToClipboard } from '@/shared/lib';
import { navigationTabsModel } from './model';

export enum ContextMenuAction {
	CopyLink = 'copyLink',
	Reload = 'reload',
	Pin = 'pin',
	Unpin = 'unpin',
	Close = 'close',
	CloseOtherTabs = 'closeOtherTabs',
	CloseTabsToRight = 'closeTabsToRight',
	CloseAllTabs = 'closeAllTabs',
}

function handleContextMenuAction(
	action: ContextMenuAction,
	item: NavigationTabEntity
) {
	const {
		pinTab,
		removeTabById,
		closeOtherTabs,
		closeTabsToRight,
		closeAllTabs,
		unpinTab,
	} = navigationTabsModel;

	switch (action) {
		case ContextMenuAction.CopyLink:
			copyToClipboard(window.location.host + item.path);
			break;
		case ContextMenuAction.Reload:
			router.push(item.path);
			setTimeout(() => {
				window.location.reload();
			}, 500);
			break;
		case ContextMenuAction.Pin:
			pinTab(item);
			break;
		case ContextMenuAction.Unpin:
			unpinTab(item);
			break;
		case ContextMenuAction.Close:
			removeTabById(item.id);
			break;
		case ContextMenuAction.CloseOtherTabs:
			closeOtherTabs(item);
			break;
		case ContextMenuAction.CloseTabsToRight:
			closeTabsToRight(item);
			break;
		case ContextMenuAction.CloseAllTabs:
			closeAllTabs();
			break;
		default:
			break;
	}

	Analytics.send(AnalyticsEvents.App.CLICK_TAB_ACTION, {
		action: action,
	});
}

export const navigationTabContextMenuModel = {
	handleContextMenuAction,
};
