<template>
	<div v-if="isVisibleNavigation" class="navigation-widget">
		<NavigationTabs />
		<NavigationHeader />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { NavigationHeader, NavigationTabs } from '@/features/navigation/';
import { computed } from 'vue';

const isVisibleNavigation = computed(() => {
	return (
		router.currentRoute.value.path !== '/signup' &&
		router.currentRoute.value.path !== '/login' &&
		router.currentRoute.value.path !== '/reset' &&
		router.currentRoute.value.path !== '/link-expired' &&
		router.currentRoute.value.path !== '/verify-email' &&
		router.currentRoute.value.path !== '/onboarding'
	);
});
</script>

<style lang="stylus" scoped>
.navigation-widget
	width 100%
	display grid
	grid-template-columns 1fr
	grid-template-rows auto auto
</style>
