<template>
	<transition
		enter-active-class="animate-slideUp"
		leave-active-class="animate-slideDown"
	>
		<!-- <div
			v-show="isVisible"
			class="bottom-modal-details-container"
			@keydown="handleKeydown"
		> -->
		<div v-show="isVisible" class="bottom-modal-details-container">
			<div class="bottom-modal-details-bg" @click="onClose()"></div>
			<div
				class="bottom-modal-container-close"
				:style="{
					top: isFull ? '1.5rem' : '4rem',
				}"
			></div>
			<div
				class="bottom-modal-container"
				:style="{
					width: isFull ? '95vw' : '85vw',
					maxWidth: isFull ? '95vw' : '1200px',
					top: isFull ? '2.5rem' : '4.5rem',
				}"
				:class="{
					'bottom-modal-container--full': isFull,
				}"
			>
				<div class="bottom-modal-container--header">
					<div class="calloutSemiBold">{{ title }}</div>
					<div class="bottom-modal-container--header-close">
						<bUnstyledButton @onClick="onClose">
							<template v-slot:content>
								<IconClose size="1.25rem" />
							</template>
						</bUnstyledButton>
						<div class="captionBold">Esc</div>
					</div>
				</div>
				<div class="bottom-modal-container--body">
					<slot name="body"></slot>
				</div>
				<div v-if="!isFull" class="bottom-modal-container--footer">
					<div class="bottom-modal-container--footer-controls">
						<slot name="footer-controls-info"> </slot>

						<bTooltip
							v-if="info.length > 0"
							:id="'modal-info-tooltip' + info"
							:hoverTitle="info"
							type="primary"
						>
							<template v-slot:body>
								<bUnstyledButton style="pointer-events: none">
									<template v-slot:content>
										<IconInfo size="1.25rem" />
									</template>
								</bUnstyledButton>
							</template>
						</bTooltip>
					</div>

					<div class="bottom-modal-container--footer-controls">
						<slot name="footer-controls"></slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script setup lang="ts">
import { IconClose, IconInfo, bTooltip, bUnstyledButton } from '@/shared/uikit';

const emit = defineEmits(['onClose']);

const props = defineProps({
	isVisible: {
		type: Boolean,
		default: false,
	},
	title: {
		type: String,
		default: '',
	},
	info: {
		type: String,
		default: '',
	},
	isInfo: {
		type: Boolean,
		default: true,
	},
	isFull: {
		type: Boolean,
		default: false,
	},
});

const onClose = () => {
	emit('onClose');
};

// const handleKeydown = (event: any) => {
// 	if (event.key === 'Escape') {
// 		onClose();
// 	}
// };

// onMounted(() => {
// 	window.addEventListener('keydown', handleKeydown);
// });

// onUnmounted(() => {
// 	window.removeEventListener('keydown', handleKeydown);
// });
</script>

<style lang="stylus" scoped>
.bottom-modal-details-container
    position fixed
    top 0
    right 0
    left 0
    bottom 0
    z-index 100
    transition all 0.2s ease-in-out
    .bottom-modal-details-bg
        position fixed
        top 0
        right 0
        left 0
        bottom 0
        z-index 1
        background rgba(0, 0, 0, 0.3)
        backdrop-filter blur(0.25rem)
        transition all 0.2s ease-in-out


.bottom-modal-container-close
	position: fixed;
	top 4rem
	bottom 0
	left 50%
	transform: translateX(-50%)
	height 0.25rem
	width 2rem
	background: white;
	border-radius 0.25rem
	z-index 2
	display block
.bottom-modal-container
	position: fixed;
	top 4.5rem
	bottom 0
	left 50%
	transform: translateX(-50%)
	// width 85vw
	// max-width 1200px
	z-index 2
	overflow hidden
	display grid
	background white
	border-top-left-radius 1.75rem
	border-top-right-radius 1.75rem
	display grid
	grid-template-rows 4.5rem 1fr 4.5rem
	grid-template-columns 1fr
	&.bottom-modal-container--full
		height 100%
		grid-template-rows 4.5rem 1fr

	&--header
		width 100%
		border-top-left-radius 1.75rem
		border-top-right-radius 1.75rem
		display flex
		justify-content space-between
		align-items center
		padding 1.5rem
		.bottom-modal-container--header-close
			display flex
			flex-direction column
			align-items center
			justify-content center
			gap 0.38rem
			margin-right -0.25rem
			margin-top 1.5rem
			.b-unstyled-button
				padding 0.62rem
				background-color var(--neutral-100)
				border-radius 50%
				&:hover
					background-color var(--neutral-200)
	&--body
		width 100%
		padding 1rem
		overflow auto
	&--footer
		width 100%
		border-top 1px solid var(--neutral-200)
		display flex
		justify-content space-between
		align-items center
		padding 1rem
		padding-left 1.5rem
		.bottom-modal-container--footer-controls
			display flex
			align-items center
			justify-content center
			gap 0.5rem
		.b-tooltip
			.b-unstyled-button
				padding 0.62rem
				pointer-events none
				&:hover
					background none

.animate-slideUp
	.bottom-modal-container-close
		animation: slideUp 0.25s ease-in-out forwards
	.bottom-modal-container
		animation: slideUp 0.25s ease-in-out forwards

.animate-slideDown
	.bottom-modal-container-close
		animation: slideDown 0.45s ease-in-out forwards
	.bottom-modal-container
		animation: slideDown 0.45s ease-in-out forwards

@keyframes slideUp
	from
		top 100vh
		opacity 0
	to
		top 4.5rem
		opacity 1
@keyframes slideDown
	0%
		top 4.5rem
		opacity 1

	100%
		top 100vh
		opacity 1
</style>
