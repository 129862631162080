import { router } from '@/app/providers';
import { jobResultsSortModel } from '@/features/job';
import { navigationTabsModel } from '@/features/navigation';
import {
	HitEntity,
	JobState,
	JobsService,
	type PaginationPageType,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';
import { jobDetailsModel, jobResultsFiltersModel } from '..';
import { JobResultViewType } from '../job-results-filters-widget/entities';

interface IViewModel {
	isLoading: boolean;
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	hits: HitEntity[];
	clusters: any[];
}
const data: IViewModel = {
	isLoading: false,
	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	hits: [],
	clusters: [],
};

const state = reactive(data);

watch(
	() => state.pageLimit,
	() => {
		fetchJobResults(1);
	},
	{ deep: true }
);

async function fetchJobResults(page: number) {
	const { job } = jobDetailsModel;

	if (!job.value || job.value?.state !== JobState.SUCCESS) {
		return;
	}

	state.currentPage = page;
	state.isLoading = true;
	try {
		const { filters, selectedClusteringOption } = jobResultsFiltersModel;
		const { selectedOption } = jobResultsSortModel;

		if (
			selectedClusteringOption.value?.type === JobResultViewType.Ungrouped
		) {
			const result = await JobsService.getInstance().fetchJobHits(
				job.value.id,
				state.pageLimit.value,
				(page - 1) * state.pageLimit.value,
				selectedOption.value,
				filters.value
			);

			state.clusters = [];
			state.total = result.count;
			state.hits = result.hits;
		} else {
			const result = await JobsService.getInstance().fetchJobClusters(
				job.value.id,
				state.pageLimit.value,
				(page - 1) * state.pageLimit.value,
				selectedOption.value,
				filters.value
			);

			state.hits = [];
			state.total = result.count;
			state.clusters = result.clusters;
		}
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

export const jobResultsListModel = {
	...toRefs(state),
	fetchJobResults,
};
