<template>
	<div
		v-if="isGrid"
		ref="card"
		class="hit-card-container-grid"
		@click="onSelectCard"
	>
		<div class="hit-card">
			<div class="hit-card--checkbox">
				<bCheckbox v-model="hit.isFavorite" @onClick="onSelectCard" />
			</div>
			<div class="hit-card--preview">
				<canvas :id="`hit-card-smiles-${hit.smiles}-${index}`">
				</canvas>
			</div>
			<div class="hit-card-preview-button" @click.stop="onOpenPreview">
				<bUnstyledButton @onClick="onOpenPreview">
					<template v-slot:content>
						<IconZoomIn size="1.25rem" />
					</template>
				</bUnstyledButton>
			</div>
			<div class="hit-card--similarity-chip">
				<bChip
					:label="`#${hit.rank}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>

				<bChip
					v-if="isEMolecules"
					label="eMolecule"
					:isGhost="false"
					type="secondary"
					size="small"
					style="text-transform: none"
				/>
			</div>

			<div class="hit-card--tooltip-info">
				<bTooltip
					:id="`info-${index}-${hit.smiles}`"
					:hoverTitle="hit.tooltipString"
					type="primary"
				>
					<template v-slot:body>
						<IconInfo size="1rem" />
					</template>
				</bTooltip>
			</div>
		</div>
	</div>
	<div
		v-else
		ref="card"
		class="hit-card-container-list"
		@click="onSelectCard"
	>
		<div class="hit-card">
			<div class="hit-card--checkbox">
				<bCheckbox v-model="hit.isFavorite" @onClick="onSelectCard" />
			</div>
			<div class="hit-card--preview" @click.stop="onOpenPreview">
				<canvas :id="`hit-card-smiles-${hit.smiles}-${index}`">
				</canvas>
				<div class="hit-card--preview-hover">
					<IconSearch size="1rem" color="white" />
					<div class="bodySmallRegular">Tap to zoom</div>
				</div>
			</div>
			<div class="hit-card--content">
				<div class="hit-card--content-info">
					<div class="subheadlineBold">
						#{{ hit.rank }} {{ ' ' + hit.smiles }}
					</div>

					<div class="hit-card--content-info-right">
						<AddHitToCollectionDropdown
							:hit="hit"
							:items="collectionItems"
						/>
					</div>
				</div>
				<div class="hit-card--content-info">
					<div class="subheadlineRegular">
						<bChip
							:label="`Similarity: ${hit.similarity.toFixed(5)}`"
							:isGhost="false"
							type="primary"
							size="small"
						/>
						<bChip
							v-if="isEMolecules"
							label="eMolecule"
							:isGhost="false"
							type="secondary"
							size="small"
							style="text-transform: none"
						/>
					</div>

					<div
						v-if="hit.external_url"
						class="hit-card-external bodySmallRegular"
					>
						{{ hit.external_url.title }}
						<TargetLinkButton
							v-if="hit.external_url"
							class="external-link-button"
							:data="hit.external_url"
							:id="`external-${index}-${hit.smiles}`"
							size="1rem"
						/>
					</div>
				</div>

				<div class="hit-card--content-info-alerts">
					<bTooltip
						v-for="(alert, index) in hit.alerts"
						:key="`druglikeness-${alert.type}`"
						:id="`druglikeness-${alert.type}-${hit.id}-${hit.smiles}-${index}`"
						alignDirection="center"
						:hoverTitle="alert.description"
						type="primary"
						@click.stop="onSelectCard"
					>
						<template v-slot:body>
							<bChip
								:label="getTitleForAlert(alert.type)"
								:isGhost="false"
								type="danger"
								size="small"
							/>
						</template>
					</bTooltip>
				</div>

				<div class="hit-card--content-details">
					<div class="hit-card--content-details">
						<span
							v-for="(value, type) in hit.attributes"
							:key="'hit-card-key-' + type"
						>
							<div class="captionBold">
								{{ getSearchAttributeTypeLabel(type) }}
							</div>
							<div class="footnoteBold">
								{{
									value.toFixed(
										getSearchAttributeTypeToFix(type)
									)
								}}
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { AddHitToCollectionDropdown } from '@/features/collections/';
import { addHitToCartDropdownModel } from '@/features/hit/';
import { previewSmilesModel } from '@/features/job';
import { TargetLinkButton } from '@/features/target-driven-search';
import {
	CollectionEntity,
	HitEntity,
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
	getTitleForAlert,
} from '@/shared/api';
import { drawSmiles } from '@/shared/lib/rdkit';
import {
	IconInfo,
	IconSearch,
	IconZoomIn,
	bCheckbox,
	bChip,
	bTooltip,
	bUnstyledButton,
} from '@/shared/uikit';
import {
	computed,
	onMounted,
	onUnmounted,
	onUpdated,
	ref,
	type PropType,
} from 'vue';

const card = ref<HTMLElement | null>(null);
const observer = ref<IntersectionObserver | null>(null);

const props = defineProps({
	hit: {
		type: Object as PropType<HitEntity>,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	collectionItems: {
		type: Array as PropType<CollectionEntity[]>,
		required: true,
	},
	isGrid: {
		type: Boolean,
		required: false,
	},
});

// only prod
const isEMolecules = computed(() => {
	return (
		props.hit.catalog_id === '3aae946c-06cc-4737-b00f-097223cb7326' ||
		props.hit.catalog_id === '8bc8012a-bb68-4271-8005-4c5c270fa924'
	);
});

onUpdated(() => {
	drawCanvas();
});

onMounted(() => {
	observer.value = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				drawCanvas();

				observer.value?.unobserve(entry.target);
			}
		});
	});

	if (card.value) observer.value?.observe(card.value);
});

onUnmounted(() => {
	observer.value?.disconnect();
});

const drawCanvas = () => {
	drawSmiles(
		props.hit.smiles,
		`hit-card-smiles-${props.hit.smiles}-${props.index}`,
		props.isGrid ? 200 : 150,
		props.isGrid ? 150 : 120
	);
};

const onOpenPreview = () => {
	previewSmilesModel.previewMolecule.value = props.hit;
};

const { addHitToCollection, removeHitFromCollection } =
	addHitToCartDropdownModel;

const onSelectCard = () => {
	const { id } = router.currentRoute.value.params;

	if (!id && router.currentRoute.value.name !== 'cart-details') {
		return;
	}

	props.hit.isFavorite = !props.hit.isFavorite;

	if (props.hit.isFavorite) {
		addHitToCollection(id as string, props.hit);
	} else {
		removeHitFromCollection(id as string, props.hit);
	}
};
</script>

<style lang="stylus" scoped>
.hit-card-container-list
	width 100%
	.hit-card
		position relative
		background-color var(--neutral-50)
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		display grid
		grid-template-columns 10rem 1fr
		padding 0.25rem
		transition all 0.25s ease
		&:hover
			cursor pointer
			background-color var(--neutral-100)
			transition all 0.25s ease

		.hit-card--checkbox
			position absolute
			top 0.75rem
			left 0.75rem
			z-index 2
		&--preview
			position relative
			width 100%
			height 100%
			border-radius 0.75rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			user-select none
			z-index 1
			&:hover
				cursor pointer
				.hit-card--preview-hover
					opacity 1 !important
					transition all 0.25s ease
			.hit-card--preview-hover
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				background: rgba(30, 41, 59, 0.5)
				display flex
				flex-direction row
				align-items center
				justify-content center
				gap 0.25rem
				border-radius 0.75rem
				z-index 10
				transition all 0.25s ease
				opacity 0
				.bodySmallRegular
					color white

			.hit-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem

		&--content
			padding 1rem
			display flex
			flex-direction column
			justify-content space-between
			align-items flex-start
			gap 0.75rem
			.hit-card--content-info-alerts
				display flex
				flex-direction row
				justify-content flex-start
				align-items center
				gap 0.5rem
			.hit-card--content-info
				display grid
				grid-template-columns auto auto
				align-items center
				gap 0.5rem
				justify-content space-between
				width 100%
				.subheadlineRegular
					display flex
					flex-direction row
					align-items center
					justify-content center
					gap 0.25rem
				.hit-card-external
					display flex
					flex-direction row
					justify-content flex-end
					align-items center
					gap 0.25rem
				.subheadlineBold
					width 100%
					white-space nowrap
					overflow hidden
					text-overflow ellipsis

				span
					display flex
					flex-direction column
					justify-content center
					align-items flex-start
					gap 0.25rem
					.bodySmallRegular
						color var(--neutral-600)

			.hit-card--content-details
				display flex
				flex-flow row wrap
				justify-content flex-start
				align-items center
				gap 0.75rem
				width 100%
				span
					height 100%
					display flex
					flex-direction column
					justify-content flex-start
					align-items flex-start
					gap 0.25rem
					min-width 2rem
					.footnoteBold
						font-size 0.75rem
				.captionBold
					color var(--neutral-600)


	.hit-card-stack
		position absolute
		width 100%
		height 7.5rem
		background-color var(--neutral-50)
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		&.hit-card-stack--1
			top 0.25rem
			left 0.25rem
			z-index 9
			border-radius: 1.25rem;
			background: linear-gradient(0deg, rgba(30, 41, 59, 0.05) 0%, rgba(30, 41, 59, 0.05) 100%), #F1F5F9;
			transition all 0.25s ease
		&.hit-card-stack--2
			top 0.5rem
			left 0.5rem
			z-index 8
			border-radius: 1.5rem;
			background: linear-gradient(0deg, rgba(30, 41, 59, 0.10) 0%, rgba(30, 41, 59, 0.10) 100%), #F1F5F9;
			transition all 0.25s ease

.hit-card-container
	&:hover
		// .hit-card
		// 	margin-top -0.25rem
		// 	margin-left -0.25rem
		// 	transition all 0.25s
		.hit-card-stack--1
			top 0.35rem
			left 0.35rem
			transition all 0.25s
		.hit-card-stack--2
			top 0.65rem
			left 0.65rem
			transition all 0.25s

.hit-card-container-grid
	width 100%
	height 11.25rem
	.hit-card
		position relative
		height 100%
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		transition all 0.25s ease
		&:hover
			cursor pointer
			background-color var(--neutral-100)
			transition all 0.25s ease

		.hit-card--checkbox
			position absolute
			top 0.75rem
			left 0.75rem
			z-index 2
		.hit-card-preview-button
			position absolute
			bottom 0.75rem
			right 0.75rem
			z-index 2
		.hit-card--similarity-chip
			position absolute
			bottom 0.75rem
			left 0.75rem
			z-index 2
			display flex
			flex-direction row
			align-items center
			justify-content center
			gap 0.25rem
		.hit-card--tooltip-info
			position absolute
			top 0.75rem
			right 0.75rem
			z-index 2
		&--preview
			position relative
			width 100%
			height 100%
			border-radius: 1rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			user-select none
			z-index 1

			.hit-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem
</style>
