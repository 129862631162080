import { Analytics, AnalyticsEvents } from '@/app/providers';
import { reactive, toRefs } from 'vue';

export class WalkthroughEntity {
	id: string;
	title: string;
	description: string;
	closeTitle: string;
	nextTitle: string;
	elementIds: string[];
	onCloseCallback: (() => void) | null = null;
	onNextCallback: (() => void) | null = null;

	constructor(
		id: string,
		title: string,
		description: string,
		closeTitle: string,
		nextTitle: string,
		elementIds: string[],
		onCloseCallback: (() => void) | null = null,
		onNextCallback: (() => void) | null = null
	) {
		this.id = id;
		this.title = title;
		this.description = description;
		this.closeTitle = closeTitle;
		this.nextTitle = nextTitle;
		this.elementIds = elementIds;
		this.onCloseCallback = onCloseCallback;
		this.onNextCallback = onNextCallback;
	}
}

interface IViewModel {
	walkthroughs: WalkthroughEntity[];
}

const data: IViewModel = {
	walkthroughs: [],
};

const state = reactive(data);

function addWalkthrough(walkthrough: WalkthroughEntity) {
	state.walkthroughs.push(walkthrough);
}

function close(id: string) {
	const index = state.walkthroughs.findIndex(
		(walkthrough) => walkthrough.id === id
	);

	if (index !== -1) {
		state.walkthroughs.splice(index, 1);
	}
}

function closeAll() {
	state.walkthroughs = [];
}

function skipAll() {
	state.walkthroughs = [];
	localStorage.setItem('onboardingIsDone', 'true');

	Analytics.send(AnalyticsEvents.Onboarding.SKIP_WALKTHROUGH);
}

function finishOnboarding() {
	state.walkthroughs = [];
	localStorage.setItem('onboardingIsDone', 'true');

	Analytics.send(AnalyticsEvents.Onboarding.FINISH_WALKTHROUGH_SUCCESS);
}

function initTabOnboarding() {
	// const onNextTab1 = () => {
	// 	close(`result-tab-${1}`);
	// 	addWalkthrough(
	// 		new WalkthroughEntity(
	// 			`result-tab-${2}`,
	// 			'And where can I find "In progress" searches?',
	// 			'Of course, in the «In progress» tab',
	// 			'',
	// 			'Okay, I figured it out.',
	// 			[`result-tab-${2}`],
	// 			skipAll,
	// 			finishOnboarding
	// 		)
	// 	);

	// 	Analytics.send(AnalyticsEvents.Onboarding.FINISH_WALKTHROUGH_STEP, {
	// 		step: 3,
	// 	});
	// };

	const onNextTab0 = () => {
		close(`result-tab-${0}`);

		addWalkthrough(
			new WalkthroughEntity(
				`result-tab-${1}`,
				'And where can I find "In progress" searches?',
				'Of course, in the «In progress» tab',
				'',
				'Okay, I figured it out.',
				[`result-tab-${1}`],
				skipAll,
				finishOnboarding
			)
		);

		Analytics.send(AnalyticsEvents.Onboarding.FINISH_WALKTHROUGH_STEP, {
			step: 2,
		});
	};

	addWalkthrough(
		new WalkthroughEntity(
			`result-tab-${0}`,
			'Where can I find the results?',
			'As soon as the result is ready, we will notify you and put it in the «Results» tab',
			'Skip',
			'Okay, what else?',
			[`result-tab-${0}`],
			skipAll,
			onNextTab0
		)
	);
}

export const walkthroughModel = {
	...toRefs(state),
	addWalkthrough,
	close,
	initTabOnboarding,
	closeAll,
	skipAll,
};
