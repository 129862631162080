<template>
	<AuthPageTemplate>
		<template v-slot:body>
			<ResetPasswordView v-if="isResetPassword" />
			<CreatePasswordView v-if="isCreateNewPassword" :oobCode="oobCode" />
		</template>
	</AuthPageTemplate>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	CreatePasswordView,
	ResetPasswordView,
	checkExpiredLinkModel,
	verifyUserEmailModel,
} from '@/features/auth';
import { computed, onMounted } from 'vue';
import AuthPageTemplate from './AuthPageTemplate.vue';

const { verifyUserEmail } = verifyUserEmailModel;
const { isExpiredOobCode } = checkExpiredLinkModel;

const query: { mode?: string; oobCode?: string; apiKey?: string } =
	router.currentRoute.value.query;

const oobCode = computed<string>(() => query.oobCode || '');

const isResetPassword = computed<boolean>(
	() => Object.keys(query).length === 0
);

const isCreateNewPassword = computed<boolean>(() => {
	return query.mode === 'resetPassword' && Boolean(query.oobCode);
});

onMounted(async () => {
	if (query.oobCode) {
		const isExpired = await isExpiredOobCode(query.oobCode);
		if (!isExpired && query.mode === 'verifyEmail') {
			await verifyUserEmail(query.oobCode);
		}
	}
});
</script>
