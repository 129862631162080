<template>
	<bUnstyledButton @onClick="onClick">
		<template v-slot:content>
			<IconTrash size="1.25rem" :color="ThemeColors.danger[600]" />
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { IconTrash, ThemeColors, bUnstyledButton } from '@/shared/uikit/';
import { deleteJobModel } from './model';

const emit = defineEmits(['onClick']);
const { jobId } = deleteJobModel;

const props = defineProps({
	jobId: {
		type: String,
		required: true,
	},
});

const onClick = () => {
	jobId.value = props.jobId;
	emit('onClick');
};
</script>
<style lang="stylus" scoped>
.b-unstyled-button
	width 2.5rem
	height 2.5rem
	background-color var(--danger-200)
	padding 0.75rem
	border-radius 50%
	display flex
	align-items center
	justify-content center
</style>
