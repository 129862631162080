<template>
	<bUnstyledButton v-if="user" @onClick="onClick">
		<template v-slot:content>
			<div class="subheadlineSemiBold">{{ user.shortName }}</div>
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { ocularUserModel } from '@/entities/app';
import { bUnstyledButton } from '@/shared/uikit';
import { accountSettingsModel } from '../model';

const { isVisible } = accountSettingsModel;
const { user, fetchUser } = ocularUserModel;

const onClick = () => {
	isVisible.value = true;

	Analytics.send(AnalyticsEvents.Home.OPEN_ACCOUNT_MODAL);

	fetchUser(true);
};
</script>

<style lang="stylus" scoped>
.b-unstyled-button
	width 2.25rem
	height 2.25rem
	background var(--primary-200)
	border-radius 50%
	display flex
	flex-direction row
	justify-content center
	align-items center
	&:hover
		cursor pointer
		background var(--primary-300)
</style>
