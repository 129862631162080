<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M20 2.00201H4C2.9 2.00201 2 2.90201 2 4.00201V22.002L6 18.002H20C21.1 18.002 22 17.102 22 16.002V4.00201C22 2.90201 21.1 2.00201 20 2.00201ZM19 16.002H6L4 18.002V5.00201C4 4.45201 4.45 4.00201 5 4.00201H19C19.55 4.00201 20 4.45201 20 5.00201V15.002C20 15.552 19.55 16.002 19 16.002Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
