<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 32 32"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M23.0627 9.59995H24.6627L24.9033 9.35934C25.6175 10.7186 26.0595 12.2192 26.1875 13.7773C26.4289 16.7141 25.5368 19.6319 23.6945 21.9306L28.4405 26.6418C28.6711 26.8805 28.7999 27.1995 28.7999 27.5315C28.7999 27.8635 28.6711 28.1825 28.4405 28.4213C28.3216 28.5413 28.1801 28.6365 28.0242 28.7015C27.8683 28.7665 27.7011 28.7999 27.5322 28.7999C27.3634 28.7999 27.1962 28.7665 27.0403 28.7015C26.8844 28.6365 26.7429 28.5413 26.624 28.4213L21.9164 23.7101C19.6194 25.5537 16.7039 26.4465 13.7693 26.205C10.8347 25.9634 8.10415 24.6058 6.13906 22.4113C4.17397 20.2169 3.12372 17.3523 3.20426 14.4067C3.2848 11.4611 4.49002 8.65829 6.57208 6.57464C8.65414 4.491 11.4548 3.28487 14.3982 3.20427C16.0667 3.15858 17.7092 3.47653 19.2184 4.11875L19.1999 4.13721V5.73721L18.3773 6.5598C18.3076 6.52876 18.2373 6.49858 18.1666 6.46928C16.5304 5.79101 14.7299 5.61354 12.9929 5.95932C11.2558 6.3051 9.66026 7.1586 8.40793 8.41189C7.1556 9.66517 6.30275 11.262 5.95723 13.0003C5.61171 14.7387 5.78904 16.5405 6.4668 18.178C7.14456 19.8155 8.2923 21.2151 9.76489 22.1998C11.2375 23.1845 12.9688 23.7101 14.7398 23.7101C17.1148 23.7101 19.3924 22.7659 21.0718 21.0853C22.7511 19.4047 23.6945 17.1253 23.6945 14.7486C23.6945 13.1477 23.266 11.5792 22.458 10.2046L23.0627 9.59995ZM21.5131 8.88677L22.3999 7.99995H23.9999L24.0371 7.96276C23.5601 7.30369 23.0113 6.69241 22.3968 6.14129C21.8969 5.69305 21.3624 5.29237 20.7999 4.94181V6.39995L19.8265 7.37335C20.4496 7.80375 21.0166 8.31212 21.5131 8.88677Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#1E293B'"
		/>
		<path
			d="M28.8 3.2L24 8H22.4L16 14.4H14.4V12.8L20.8 6.4V4.8L25.6 0V3.2H28.8Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#0D9488'"
		/>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { sizeProp } from '../config';

const props = defineProps({
	isDisabled: Boolean,
	size: sizeProp,
});

const { size } = toRefs(props);
</script>
