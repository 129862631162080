<template>
	<div class="probe-card" @click.stop="onClick">
		<!-- <div class="probe-card--checkbox" @click="onClick">
			<bCheckbox v-model="isCheck" />
		</div> -->

		<div class="probe-card--header">
			<div>
				<bCheckbox v-model="isCheck" @update:modelValue="onClick" />
			</div>

			<TargetLinkButton
				@click="stopPropagation"
				class="external-link-button"
				:data="probe.external_url"
				:id="'probe-' + probe.smiles"
			/>
		</div>

		<div class="probe-card--body">
			<canvas
				:id="'probe-smiles-' + index + '-' + probe.smiles"
				width="180px"
				height="80px"
			></canvas>
			<!-- 
			<div class="probe-card-preview-hover" @click="openPreview">
				<IconSearch color="white" />
				<div class="bodySmallRegular">Tap to zoom</div>
			</div> -->
		</div>

		<div class="probe-card--footer">
			<bTooltip
				:id="`probe-${probe.smiles}-tooltip`"
				:hoverTitle="`${probe.activity} nmol`"
				type="primary"
			>
				<template v-slot:body>
					<div class="latex-chip">
						Activity Value:
						<bLaTeXRenderer
							:formula="probe.formatActivityToLaTeX"
						/>
						nmol
					</div>
				</template>
			</bTooltip>

			<div class="probe-card-preview-button" @click="stopPropagation">
				<bUnstyledButton @onClick="openPreview">
					<template v-slot:content>
						<IconZoomIn size="1.25rem" />
					</template>
				</bUnstyledButton>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { TargetLinkButton } from '@/features/target-driven-search';
import { ProbesEntity } from '@/shared/api';
import { drawSmiles } from '@/shared/lib/rdkit';
import {
	IconZoomIn,
	bCheckbox,
	bLaTeXRenderer,
	bTooltip,
	bUnstyledButton,
} from '@/shared/uikit';
import { defineProps, onMounted, ref, watchEffect, type PropType } from 'vue';

const emit = defineEmits(['onClick', 'openPreview']);
const isCheck = ref<boolean>(false);

const props = defineProps({
	probe: {
		type: Object as PropType<ProbesEntity>,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	isSelected: {
		type: Boolean,
		required: false,
		default: false,
	},
});

onMounted(() => {
	const smiles = props.probe.smiles.split(' ')[0] ?? props.probe.smiles;

	drawSmiles(
		smiles,
		'probe-smiles-' + props.index + '-' + props.probe.smiles,
		180,
		80
	);
});

watchEffect(() => {
	isCheck.value = props.isSelected;
});

const onClick = () => {
	emit('onClick');
};

const openPreview = () => {
	emit('openPreview');
};

const stopPropagation = (e: Event) => {
	e.stopPropagation();
};
</script>

<style lang="stylus" scoped>
.probe-card
	height 7.5rem
	padding 1.25rem 1.5rem
	background-color white
	border-radius: 1.75rem
	border 1px solid var(--neutral-200)
	display flex
	flex-direction column
	justify-content center
	align-items center
	gap 0.25rem
	transition all 0.25s
	position relative
	&:hover
		cursor pointer
		border 1px solid var(--neutral-400)
	&--checkbox
		position absolute
		top 0.75rem
		left 0.75rem
	.external-link-button
		position absolute
		bottom 0.75rem
		right 0.75rem
	&--header
		padding 0.75rem
		position absolute
		top 0
		left 0
		right 0
		display flex
		justify-content space-between
		align-items center

	&--body
		display flex
		flex-direction column
		justify-content center
		align-items center
		.probe-card-preview-hover
			transition all 0.25s
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			background: rgba(30, 41, 59, 0.5)
			display flex
			display none
			flex-direction row
			align-items center
			justify-content center
			gap 0.25rem
			border-radius: 1.75rem
			z-index 10
			transition all 0.25s ease
			.bodySmallRegular
				color white
		&:hover
			// cursor pointer
			.probe-card-preview-hover
				display flex
				transition all 0.25s
	&--footer
		padding 0.75rem
		position absolute
		bottom 0
		left 0
		right 0
		display flex
		justify-content space-between
		align-items center
		.latex-chip
			display flex
			align-items center
			justify-content center
			gap 0.25rem
			transition all 0.25s
			padding 0.25rem 0.5rem
			border-radius: 1.75rem
			font-size 0.75rem
			font-weight 500
			font-family var(--font-family)
			background var(--primary-500)
			color white
			line-height 1rem
	.bodySmallSemiBold
		color var(--neutral-400)
</style>
