<template>
	<div class="dashboard-collection-template" ref="scrollContainer">
		<div class="calloutSemiBold">{{ title }}</div>

		<template v-if="!isLoading">
			<slot name="body"></slot>
		</template>
		<bEmptyView v-if="isEmpty" :title="emptyTitle" :description="emptyDesc">
			<template v-slot:image>
				<bImageEmpty2 :width="200" :height="200" />
			</template>
			<template v-slot:controls>
				<slot name="empty-controls"></slot>
			</template>
		</bEmptyView>

		<template v-if="isLoading">
			<div class="empty-view"><bLoader size="4" /></div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { bEmptyView, bImageEmpty2, bLoader } from '@/shared/uikit';
import { ref } from 'vue';

const scrollContainer = ref<any>(null);

const props = defineProps({
	isLoading: {
		type: Boolean,
		required: true,
	},
	isEmpty: {
		type: Boolean,
		required: true,
	},
	title: {
		type: String,
		required: true,
	},
	emptyTitle: {
		type: String,
		required: true,
	},
	emptyDesc: {
		type: String,
		required: true,
	},
});

const resetScroll = () => {
	if (scrollContainer.value) {
		scrollContainer.value.scrollTop = 0;
	}
};

defineExpose({
	resetScroll,
});
</script>

<style lang="stylus" scoped>
.dashboard-collection-template
	width 100%
	height calc(100vh - 6.25rem)
	padding 1rem
	padding-top 4rem
	padding-bottom 6rem
	position relative
	overflow-y scroll
	overflow-x hidden
	display flex
	flex-direction column
	gap 1.5rem
	&::-webkit-scrollbar
		width 0.25rem
	.empty-view
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
