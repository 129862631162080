<template>
	<div
		class="catalog-card"
		:class="{
			'catalog-card--selected': isSelected,
		}"
		@click="onClick"
	>
		<div class="footnoteBold">{{ catalog.name }}</div>
		<div class="bodySmallSemiBold">{{ catalog.formattedSearchTime }}</div>
	</div>
</template>

<script setup lang="ts">
import { CatalogEntity } from '@/shared/api';
import { defineProps, type PropType } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	catalog: {
		type: Object as PropType<CatalogEntity>,
		required: true,
	},
	isSelected: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const onClick = () => {
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.catalog-card
	padding 1.25rem 1.5rem
	background-color white
	border-radius: 1.75rem
	border 1px solid var(--neutral-200)
	display flex
	flex-direction column
	justify-content center
	align-items flex-start
	gap 0.25rem
	transition all 0.25s
	.bodySmallSemiBold
		color var(--neutral-400)
	&:hover
		cursor pointer
		border 1px solid var(--neutral-400)
		background-color var(--neutral-50)
		transition all 0.25s
	&--selected
		border 1px solid var(--primary-500)
		background-color var(--primary-50)
		.footnoteBold
			color var(--primary-600)
		.bodySmallSemiBold
			color var(--primary-500)
		pointer-events none
</style>
