import { isValidateSmiles } from '@/shared/lib/rdkit';
import { reactive, toRefs, watch } from 'vue';

interface IViewModel {
	isValidatedSmiles: boolean;
	smilesQuery: string;
	selectedSmiles: string[];
}
const data: IViewModel = {
	isValidatedSmiles: false,
	smilesQuery: '',
	selectedSmiles: [],
};

const state = reactive(data);

watch(
	() => state.smilesQuery,
	async (newValue) => {
		try {
			const isValidated = await isValidateSmiles(newValue);
			state.isValidatedSmiles = isValidated;
		} catch (error) {
			state.isValidatedSmiles = false;
		}
	}
);

function clear() {
	state.smilesQuery = '';
	state.selectedSmiles = [];
	state.isValidatedSmiles = false;
}

export const ligandSearchInputModel = {
	...toRefs(state),
	clear,
};
