export type PaginationPageType = {
	title: string;
	value: number;
};

export class NavigationTabEntity {
	constructor(
		public id: string,
		public title: string,
		public description: string,
		public path: string,
		public type: NavigationTabType,
		public index: number = 0,
		public isPinned: boolean = false
	) {}

	static fromObject(data: any): NavigationTabEntity {
		return new NavigationTabEntity(
			data.id,
			data.title,
			data.description,
			data.path,
			data.type,
			data?.index ?? 0,
			data?.isPinned ?? false
		);
	}
}

export enum NavigationTabType {
	LIGAND = 'LIGAND',
	TARGET = 'TARGET',
	Collection = 'Collection',
	Cart = 'Cart',
	Chat = 'ChemCrow',
	NewTab = 'NewTab',
	Pipeline = 'Pipeline',
	Structure = 'Structure',
}
