import { createApp } from 'vue';
import { Analytics, router } from './providers';
import { i18n } from './providers/i18n';
import './styles/theme/root.css';
import App from './ui.vue';

Analytics.init();
Analytics.identify();

export const app = createApp(App).use(router).use(i18n);
