import 'firebase/compat/auth';
import { AuthService, EmailAuthProvider } from '..';
import { RestClient } from '../client';
import { Endpoints } from '../shared';
import { OcularUser, PaymentOptionEntity } from './entities';

export class UserService {
	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	async getUser(): Promise<OcularUser | null> {
		const response = await this.client.get<any>(Endpoints.Users.ME);

		if (!response?.data) {
			return null;
		}

		const provider = new EmailAuthProvider();
		const user = await AuthService.getInstance(provider).getUser();

		return this.userMapper({
			...response,
			photoURL: user.photoURL,
		});
	}

	async createUser(
		name: string,
		referralUserId: string | null = null
	): Promise<OcularUser> {
		let body: any = {
			name: name,
		};

		if (referralUserId) {
			body = {
				...body,
				referral_user_id: referralUserId,
			};
		}

		const response = await this.client.post<void>(
			Endpoints.Users.CREATE,
			body
		);

		return this.userMapper(response);
	}

	async deleteMe(): Promise<void> {
		await this.client.delete<void>(Endpoints.Users.ME);
	}

	async updateName(name: string): Promise<OcularUser> {
		const response = await this.client.patch<any>(Endpoints.Users.ME, {
			name: name,
		});

		return this.userMapper(response);
	}

	async getPaymentUrls(): Promise<PaymentOptionEntity[]> {
		const response = await this.client.get<any>(Endpoints.Users.PAYMENT);

		return response.data.urls.map((url: any) => {
			return new PaymentOptionEntity(url.amount, url.url, url.price);
		});
	}

	async redeemCode(code: string): Promise<OcularUser> {
		const response = await this.client.post<any>(Endpoints.Users.REDEEM, {
			code: code,
		});

		return this.userMapper(response);
	}

	private userMapper(response: any): OcularUser {
		return new OcularUser(
			response.data.id,
			response.data.name,
			response.data.email,
			response.data.firebase_uid,
			response.data.created_at,
			response.data.balance
		);
	}
}
