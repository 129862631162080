<template>
	<div class="container">
		<IconLogo size="2.5rem" :color="ThemeColors.primary[600]" />

		<div class="calloutRegular">{{ $t('auth.expired.title') }}</div>
		<div class="footnoteRegular">
			{{ $t('auth.expired.subtitle') }}
		</div>

		<bButton
			:label="$t('auth.button.signup')"
			type="primary"
			size="large"
			@click="onClick"
		/>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { IconLogo, ThemeColors, bButton } from '@/shared/uikit';
import { onMounted } from 'vue';

const onClick = () => {
	router.push('/signup');
};

onMounted(() => {
	Analytics.send(AnalyticsEvents.ExpiredLink.VIEW_PAGE);
});
</script>
<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';
.container
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 1.5rem
    padding 1rem
    .footnoteRegular
        text-align center
    .b-button
        width 100%
        justify-content center
</style>
