<template>
	<bButton
		:key="searchId"
		label="Download results"
		type="primary"
		:isLoading="isDownloading && loadingIds.includes(searchId)"
		@onClick="downloadById(searchId)"
	>
		<template v-slot:icon-left>
			<IconDownload size="1.125rem" color="white" />
		</template>
	</bButton>
</template>

<script setup lang="ts">
import { IconDownload, bButton } from '@/shared/uikit/';
import { downloadSearchResultsModel } from './model';

const { downloadById, isDownloading, loadingIds } = downloadSearchResultsModel;

const props = defineProps({
	searchId: {
		type: String,
		required: true,
	},
});
</script>

<style lang="stylus" scoped>
.b-unstyled-button
    padding 0.75rem
    border-radius 50%
</style>
