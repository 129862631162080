import BiopticCopilotChatsPage from '../BiopticCopilotChatsPage.vue';
import CollectionsPage from '../CollectionsPage.vue';
import DashboardPage from '../DashboardPage.vue';
import LigandSearchPage from '../LigandSearchPage.vue';
import StructureSearchPage from '../StructureSearchPage.vue';
import TargetSearchPage from '../TargetSearchPage.vue';

export const dashboardRoutes = [
	{
		path: '/dashboard',
		component: DashboardPage,
		name: 'dashboard',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
		children: [
			{
				path: '/dashboard',
				name: 'ligand-search',
				component: LigandSearchPage,
			},
			{
				path: '/dashboard/target-search',
				name: 'target-search',
				component: TargetSearchPage,
			},
			{
				path: '/dashboard/structure-search',
				name: 'structure-search',
				component: StructureSearchPage,
			},
			{
				path: '/dashboard/chats',
				name: 'bioptic-copilot-chats',
				component: BiopticCopilotChatsPage,
			},
			{
				path: '/dashboard/collections',
				name: 'collections',
				component: CollectionsPage,
			},
		],
	},
];
