<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 20 20"
		fill="none"
	>
		<g clip-path="url(#clip0_2782_3125)">
			<path
				d="M7.7418 5.59173C7.4168 5.91673 7.4168 6.44173 7.7418 6.76673L10.9751 10.0001L7.7418 13.2334C7.4168 13.5584 7.4168 14.0834 7.7418 14.4084C8.0668 14.7334 8.5918 14.7334 8.9168 14.4084L12.7418 10.5834C13.0668 10.2584 13.0668 9.73339 12.7418 9.40839L8.9168 5.58339C8.60013 5.26673 8.0668 5.26673 7.7418 5.59173Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2782_3125">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
