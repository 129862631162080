<template>
	<bUnstyledButton
		:class="{
			'b-unstyled-button--white': isCurrentChat,
		}"
		@onClick="onClick"
	>
		<template v-slot:content>
			<IconTrash
				size="1rem"
				:color="isCurrentChat ? 'white' : ThemeColors.danger[600]"
			/>
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { IconTrash, ThemeColors, bUnstyledButton } from '@/shared/uikit/';
import { deleteChatButtonModel } from './model';

const emit = defineEmits(['onClick']);
const { deleteChat } = deleteChatButtonModel;

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	isCurrentChat: {
		type: Boolean,
		default: false,
	},
});

const onClick = () => {
	deleteChat(props.id);
	emit('onClick');
};
</script>
<style lang="stylus" scoped>
.b-unstyled-button
	width 1.5rem
	height 1.5rem
	padding 0.25rem
	border-radius 50%
	display flex
	align-items center
	justify-content center
	&--white
		background-color transparent
		&:hover
			background-color var(--neutral-500)
</style>
