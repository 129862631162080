import NewHomePage from '../NewHomePage.vue';
import SearchTypePage from '../SearchTypePage.vue';

export const homeRoutes = [
	// {
	// 	path: '/',
	// 	component: HomePage,
	// 	name: 'home',
	// 	meta: {
	// 		requiresAuth: true,
	// 		requiresVerifiedEmail: true,
	// 	},
	// },
	{
		path: '/new-tab/:id',
		component: NewHomePage,
		name: 'new-tab',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
	{
		path: '/search-type',
		component: SearchTypePage,
		name: 'search-type',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
];
