<template>
	<div class="b-empty-view">
		<slot name="image"></slot>
		<div v-if="title" class="headlineRegular">{{ title }}</div>
		<div v-if="description" class="subheadlineRegular">
			{{ description }}
		</div>
		<slot name="controls"></slot>
	</div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps({
	title: {
		type: String,
		required: false,
	},
	description: {
		type: String,
		required: false,
	},
});

const { title, description } = toRefs(props);
</script>

<style lang="stylus" scoped>
.b-empty-view
	width 100%
	height 100%
	display flex
	flex-flow column nowrap
	align-items center
	justify-content center
	gap 2rem
	padding 1.5rem
	.headlineRegular
		text-align center
	.subheadlineRegular
		text-align center
		margin-top -1.25rem
		color var(--neutral-600)
</style>
