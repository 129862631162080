import { ToolType } from '@/shared/api';

export class AvailableToolEntity {
	constructor(
		public id: string,
		public name: string,
		public description: string,
		public emoji: string,
		public type: ToolType
	) {}

	static fromObject(data: any): AvailableToolEntity {
		return new AvailableToolEntity(
			data.id,
			data.name,
			data.description,
			data.emoji,
			data.type
		);
	}

	static get options(): AvailableToolEntity[] {
		return [
			new AvailableToolEntity(
				'1',
				'Python_REPL',
				'A Python shell. Use this to execute python commands. Input should be a valid python command. If you want to see the output of a value, you should print it out with `print(...)`.',
				'🐍',
				ToolType.DEFAULT
			),
			new AvailableToolEntity(
				'2',
				'Wikipedia',
				'A wrapper around Wikipedia. Useful for when you need to answer general questions about people, places, companies, facts, historical events, or other subjects. Input should be a search query.',
				'📚',
				ToolType.DEFAULT
			),
			new AvailableToolEntity(
				'3',
				'Name2SMILES',
				'Input a molecule name, returns SMILES.',
				'🔬',
				ToolType.NAME_TO_SMILES
			),
			new AvailableToolEntity(
				'4',
				'Mol2CAS',
				'Input molecule (name or SMILES), returns CAS number.',
				'🆔',
				ToolType.QUERY_TO_CAS
			),
			new AvailableToolEntity(
				'5',
				'SMILES2Name',
				'Input SMILES, returns molecule name.',
				'🔢',
				ToolType.SMILES_TO_NAME
			),
			new AvailableToolEntity(
				'6',
				'PatentCheck',
				'Input SMILES, returns if molecule is patented. You may also input several SMILES, separated by a period.',
				'📝',
				ToolType.PATENT_CHECK
			),
			new AvailableToolEntity(
				'7',
				'MolSimilarity',
				"Input two molecule SMILES (separated by '.'), returns Tanimoto similarity.",
				'🔍',
				ToolType.MOL_SIMILARITY
			),
			new AvailableToolEntity(
				'8',
				'SMILES2Weight',
				'Input SMILES, returns molecular weight.',
				'⚖️',
				ToolType.SMILES_TO_WEIGHT
			),
			new AvailableToolEntity(
				'9',
				'FunctionalGroups',
				'Input SMILES, return list of functional groups in the molecule.',
				'🧪',
				ToolType.FUNC_GROUPS
			),
			new AvailableToolEntity(
				'10',
				'ExplosiveCheck',
				'Input CAS number, returns if molecule is explosive.',
				'💥',
				ToolType.EXPLOSIVE_CHECK
			),
			new AvailableToolEntity(
				'11',
				'ControlChemCheck',
				'Input CAS number, True if molecule is a controlled chemical.',
				'🚨',
				ToolType.CONTROL_CHEM_CHECK
			),
			new AvailableToolEntity(
				'12',
				'SimilarityToControlChem',
				'Input SMILES, returns similarity to controlled chemicals.',
				'🔒',
				ToolType.SIMILAR_CONTROL_CHEM_CHECK
			),
			new AvailableToolEntity(
				'13',
				'SafetySummary',
				'Input CAS number, returns a summary of safety information. The summary includes Operator safety, GHS information, Environmental risks, and Societal impact.',
				'⚠️',
				ToolType.SAFETY_SUMMARY
			),
			new AvailableToolEntity(
				'14',
				'LiteratureSearch',
				'Useful to answer questions that require technical knowledge. Ask a specific question.',
				'🔎',
				ToolType.SCHOLAR_TO_RESULT_LLM
			),
			new AvailableToolEntity(
				'15',
				'Boptic Ligand Search',
				'Input SMILES, returns ligands with similar properties.',
				'🧬',
				ToolType.BIOPTIC_LIGAND_SEARCH
			),
			new AvailableToolEntity(
				'16',
				'Boptic Job Results',
				'Input job ID, returns job results.',
				'🎯',
				ToolType.BIOPTIC_JOB_RESULTS
			),
			new AvailableToolEntity(
				'17',
				'Boptic Eurofins Assistant',
				'',
				'🧬',
				ToolType.BIOPTIC_EUROFINS_ASSISTANT
			),
			new AvailableToolEntity(
				'18',
				'Boptic ADME Predictor',
				'',
				'🧬',
				ToolType.BIOPTIC_ADME_PREDICTOR
			),
		];
	}
}
