<template>
	<div
		v-if="isGrid"
		ref="card"
		class="cluster-card-container-grid"
		@click="onClickByCluster"
	>
		<div class="cluster-card" @click="onClickByCluster">
			<div class="cluster-card--preview">
				<canvas
					:id="`cluster-smiles-${index}-${cluster.scaffold_smiles}`"
					width="150"
					height="100"
				>
				</canvas>
			</div>

			<div class="cluster-card--size">
				<bChip
					:label="`Size: ${cluster.size}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="cluster-card--similarity-chip">
				<bChip
					:label="`Cluster #${cluster.index}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="cluster-card--tooltip-info">
				<bTooltip
					:id="`tooltip-${index}-${cluster.scaffold_smiles}`"
					:hoverTitle="cluster.tooltipString"
					type="primary"
				>
					<template v-slot:body>
						<IconInfo size="1rem" />
					</template>
				</bTooltip>
			</div>
		</div>
	</div>
	<div v-else ref="card" class="cluster-card-container-list">
		<div class="cluster-card" @click="onClickByCluster">
			<div class="cluster-card--preview">
				<canvas
					:id="`cluster-smiles-${index}-${cluster.scaffold_smiles}`"
					width="150"
					height="100"
				>
				</canvas>

				<!-- <div class="cluster-card--preview-hover">
					<IconSearch size="1rem" color="white" />
					<div class="bodySmallRegular">Tap to zoom</div>
				</div> -->
				<!-- <div class="cluster-card--preview-selected">
					<bCheckbox v-model="cluster.isSelected" />
				</div> -->
			</div>
			<div class="cluster-card--content">
				<div class="cluster-card--content-info">
					<span
						><div class="subheadlineBold">
							Cluster #{{ cluster.index }}
							<bChip
								:label="`${cluster.size}`"
								:isGhost="false"
								type="primary"
								size="small"
							/>
						</div>
						<div class="bodySmallRegular">
							{{ cluster.scaffold_smiles }}
						</div>
					</span>

					<!-- <OpenClusterButton :cluster="cluster" /> -->
				</div>
				<div class="cluster-card--content-details">
					<div class="cluster-card--content-details">
						<span
							v-for="attribute in cluster.attributes"
							:key="'hit-card-key-' + attribute.type"
						>
							<div class="captionBold">
								{{
									getSearchAttributeTypeLabel(attribute.type)
								}}
							</div>
							<div class="footnoteBold">
								{{
									attribute.absolute_min.toFixed(
										getSearchAttributeTypeToFix(
											attribute.type
										)
									) +
									'-' +
									attribute.absolute_max.toFixed(
										getSearchAttributeTypeToFix(
											attribute.type
										)
									)
								}}
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
	type ClusterEntity,
} from '@/shared/api';

import { drawSmiles } from '@/shared/lib/rdkit';
import { bChip, bTooltip, IconInfo } from '@/shared/uikit';
import { onMounted, onUnmounted, onUpdated, ref, type PropType } from 'vue';

const emit = defineEmits(['openCluster']);
const card = ref<HTMLElement | null>(null);
const observer = ref<IntersectionObserver | null>(null);

const props = defineProps({
	cluster: {
		type: Object as PropType<ClusterEntity>,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	isGrid: {
		type: Boolean,
		required: false,
	},
});

onUpdated(() => {
	drawCanvas();
});

onMounted(() => {
	observer.value = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				drawCanvas();

				observer.value?.unobserve(entry.target);
			}
		});
	});

	if (card.value) observer.value?.observe(card.value);
});

onUnmounted(() => {
	observer.value?.disconnect();
});

const drawCanvas = () => {
	drawSmiles(
		props.cluster.scaffold_smiles,
		`cluster-smiles-${props.index}-${props.cluster.scaffold_smiles}`,
		150,
		120
	);
};

const onClickByCluster = () => {
	emit('openCluster');
};
</script>

<style lang="stylus" scoped>
.cluster-card-container-list
	position relative
	width 100%
	.cluster-card
		position relative
		background-color var(--neutral-50)
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		display grid
		grid-template-columns 10rem 1fr
		padding 0.25rem
		cursor pointer
		transition all 0.25s ease
		&:hover
			background-color var(--neutral-100)
			transition all 0.25s ease
		&--preview
			position relative
			width 100%
			height 100%
			border-radius 0.75rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			&:hover
				cursor pointer
				.cluster-card--preview-hover
					opacity 1 !important
					transition all 0.25s ease
			.cluster-card--preview-selected
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				display flex
				flex-direction row
				align-items flex-start
				justify-content flex-start
				padding 0.5rem
				gap 0.25rem
				border-radius 0.75rem
				z-index 100
				transition all 0.25s ease
				// background: rgba(30, 41, 59, 0.5)
				// opacity 0
			.cluster-card--preview-hover
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				background: rgba(30, 41, 59, 0.5)
				display flex
				flex-direction row
				align-items center
				justify-content center
				gap 0.25rem
				border-radius 0.75rem
				z-index 101
				transition all 0.25s ease
				opacity 0
				.bodySmallRegular
					color white

			.cluster-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem

		&--content
			padding 1rem
			display flex
			flex-direction column
			justify-content space-between
			align-items flex-start
			gap 0.75rem
			.cluster-card--content-info
				display flex
				flex-direction row
				justify-content space-between
				align-items center
				width 100%
				span
					display flex
					flex-direction column
					justify-content center
					align-items flex-start
					.subheadlineBold
						display flex
						flex-direction row
						justify-content space-between
						align-items center
						gap 0.25rem
					.bodySmallRegular
						color var(--neutral-600)

			.cluster-card--content-details
				display flex
				flex-flow row wrap
				justify-content flex-start
				align-items center
				gap 0.75rem
				width 100%
				span
					display flex
					flex-direction column
					justify-content flex-start
					align-items flex-start
					gap 0.25rem
					min-width 2rem
					.footnoteBold
						font-size 0.75rem

				.captionBold
					color var(--neutral-600)


	.cluster-card-stack
		position absolute
		width 100%
		height 7.5rem
		background-color var(--neutral-50)
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		&.cluster-card-stack--1
			top 0.25rem
			left 0.25rem
			z-index 9
			border-radius: 1.25rem;
			background: linear-gradient(0deg, rgba(30, 41, 59, 0.05) 0%, rgba(30, 41, 59, 0.05) 100%), #F1F5F9;
			transition all 0.25s ease
		&.cluster-card-stack--2
			top 0.5rem
			left 0.5rem
			z-index 8
			border-radius: 1.5rem;
			background: linear-gradient(0deg, rgba(30, 41, 59, 0.10) 0%, rgba(30, 41, 59, 0.10) 100%), #F1F5F9;
			transition all 0.25s ease

.cluster-card-container-list
	&:hover
		// .cluster-card
		// 	margin-top -0.25rem
		// 	margin-left -0.25rem
		// 	transition all 0.25s
		.cluster-card-stack--1
			top 0.35rem
			left 0.35rem
			transition all 0.25s
		.cluster-card-stack--2
			top 0.65rem
			left 0.65rem
			transition all 0.25s

.cluster-card-container-grid
	width 100%
	height 11.25rem
	.cluster-card
		position relative
		height 100%
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		transition all 0.25s ease
		border 1px solid transparent
		&:hover
			cursor pointer
			box-shadow: none
			background-color var(--neutral-200)
			border 1px solid var(--neutral-100)
			transition all 0.25s ease
		.cluster-card--similarity-chip
			position absolute
			bottom 0.75rem
			left 0.75rem
			z-index 2
		.cluster-card--size
			position absolute
			top 0.75rem
			left 0.75rem
			z-index 2
		.cluster-card--tooltip-info
			position absolute
			top 0.75rem
			right 0.75rem
			z-index 2
		&--preview
			position relative
			width 100%
			height 100%
			border-radius: 1rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			user-select none
			z-index 1

			.hit-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem
</style>
