<template>
	<div class="b-modal-container">
		<div class="b-modal-bg" @click="onClose()"></div>
		<div class="b-modal" :style="modalStyle">
			<div class="b-modal-header">
				<div class="b-modal-icon" :class="iconClassName">
					<slot name="icon"></slot>
				</div>
				<div class="bodyRegular">
					{{ title }}
					<slot name="title-chip"></slot>
				</div>
				<div class="footnoteRegular" v-if="message">
					{{ message }}
				</div>
			</div>

			<div class="b-modal-body">
				<slot name="body"></slot>
			</div>
			<div class="b-modal-footer">
				<div class="b-modal-controls">
					<slot name="left-controls"></slot>
				</div>
				<div class="b-modal-controls">
					<slot name="right-controls"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['onClose']);

const props = defineProps({
	title: { type: String, required: false },
	message: { type: String, required: false },
	type: { type: String, required: true },
	width: { type: Number, required: false },
});

const iconClassName = computed((): any => {
	return {
		'b-modal-icon--primary': props.type === 'primary',
		'b-modal-icon--success': props.type === 'success',
		'b-modal-icon--danger': props.type === 'danger',
	};
});

const modalStyle = ref<any>({});

// const handleKeydown = (event: any) => {
// 	if (event.key === 'Escape') {
// 		onClose();
// 	}
// };

onMounted(() => {
	// window.addEventListener('keydown', handleKeydown);

	if (props.width) {
		modalStyle.value = {
			width: props.width + 'px',
		};
	}
});

// onUnmounted(() => {
// 	window.removeEventListener('keydown', handleKeydown);
// });

const onClose = () => {
	emit('onClose');
};
</script>

<style lang="stylus" scoped>
@import '../../../app/styles/theme/variables.styl';

.b-modal-container
    position fixed
    top 0
    right 0
    left 0
    bottom 0
    z-index 9999
    transition all 0.2s ease-in-out
    .b-modal-bg
        position fixed
        top 0
        right 0
        left 0
        bottom 0
        z-index 1
        background rgba(0, 0, 0, 0.3)
        backdrop-filter blur(0.25rem)
        z-index 1
        transition all 0.2s ease-in-out
    .b-modal-header
        width 100%
        display flex
        flex-direction column
        align-items flex-start
        justify-content flex-start
        gap 1rem
        .bodyRegular
            width 100%
            text-align center
            display flex
            flex-direction row
            align-items center
            justify-content center
        .footnoteRegular
            color var(--neutral-500)
        .b-modal-icon
            width 100%
            // height 1.8125rem
            display flex
            flex-direction row
            align-items center
            justify-content center
            img
                width 1.5rem
                height 1.5rem
    .b-modal-body
        width 100%
    .b-modal-footer
        width 100%
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        .b-modal-controls
            width 100%
            display flex
            flex-direction row
            align-items center
            justify-content flex-end
            gap 0.5rem
.b-modal
    position: relative;
    width 28rem
    top: 50%;
    left: 50%;
    z-index 2
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 1.75rem
    padding 1rem 1.5rem 1.5rem 1.5rem
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 1rem
    box-shadow: 0px 5.5px 16px 0px rgba(11, 10, 13, 0.19), 0px 1px 6px 0px rgba(11, 10, 13, 0.04);
</style>
