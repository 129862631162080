<template>
	<bModal
		v-if="previewMolecule"
		type="success"
		title=""
		message=""
		:width="800"
		@onClose="onClose"
	>
		<template v-slot:body>
			<Smiles3DViewer :smiles="previewMolecule.smiles" id="101" />
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { Smiles3DViewer } from '@/features/tools';
import { bModal } from '@/shared/uikit';
import { computed, ref } from 'vue';
import { previewSmiles3DModel } from './model';

const smilesSDFData = ref<string>('');
const { previewMolecule, is3D } = previewSmiles3DModel;

const alerts = computed(() => previewMolecule.value?.alerts);

const onClose = () => {
	previewMolecule.value = null;
};
</script>

<style lang="stylus">
#preview-smiles-modaL-container
	.b-modal
		gap 0
		background var(--neutral-50) !important
		padding 0.5rem
		.b-modal-body
			display: flex
			justify-content: center
			align-items: center
			background white
			border-radius 0.5rem
		.b-modal-header
			display: none !important
</style>
