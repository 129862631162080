import { Analytics, AnalyticsEvents, i18n, router } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api';
import { firebaseErrorHandler } from '@/shared/lib';
import {
	Notification,
	notificationModel,
	pageLoaderModel,
} from '@/shared/uikit';

async function verifyUserEmail(code: string) {
	try {
		pageLoaderModel.isLoading.value = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.verifyUserEmail(code);

		notificationModel.addNotification(
			new Notification(
				'email-verified-success',
				'success',
				i18n.global.t('auth.verifyEmail.notification.success.title'),
				i18n.global.t('auth.verifyEmail.notification.success.message'),
				null,
				2000
			)
		);

		Analytics.send(AnalyticsEvents.VerifyEmail.VERIFY_EMAIL_SUCCESS);
	} catch (error) {
		firebaseErrorHandler(error);
		Analytics.send(AnalyticsEvents.VerifyEmail.VERIFY_EMAIL_FAILURE);
	} finally {
		pageLoaderModel.isLoading.value = false;
		router.push('/onboarding');
	}
}

async function sendEmailVerification(isResend: boolean = false) {
	if (isResend) {
		Analytics.send(AnalyticsEvents.VerifyEmail.RESEND_EMAIL);
	} else {
		Analytics.send(AnalyticsEvents.VerifyEmail.VERIFY_EMAIL);
	}

	try {
		pageLoaderModel.isLoading.value = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		const currentEmail = await authService.getUser();

		if (!currentEmail.email) {
			throw new Error('Invalid auth');
		}

		await authService.sendEmailVerification(currentEmail.email);

		notificationModel.addNotification(
			new Notification(
				'sent-verify-email-success',
				'success',
				'Verification email sent',
				'Please check your email for a verification link',
				null,
				3000
			)
		);

		localStorage.setItem('sendedVerifyEmail', JSON.stringify(Date.now()));
	} catch (error) {
		firebaseErrorHandler(error);
	} finally {
		pageLoaderModel.isLoading.value = false;
	}
}

async function resend() {
	await sendEmailVerification(true);
}

export const verifyUserEmailModel = {
	verifyUserEmail,
	sendEmailVerification,
	resend,
};
