import { createApp, toRaw } from 'vue';
import { generateUuid } from '..';
import type { Grid } from './Grid';
import { DashboardElement, type IDraggable } from './entities';
import { calculateGraphPositions, type Graph } from './graphUtils';
import { transformCoordinates } from './helpers';

export class ElementManager implements IDraggable {
	get isActivelyDragging(): boolean {
		return this.elements.some((el) => el.isSelected);
	}

	elements: DashboardElement[] = [];

	private svg: SVGSVGElement;
	private elementsGroup: SVGGElement;
	private contentWidth: number;
	private contentHeight: number;
	private graph: Graph = {};
	private forceUpdate: () => void;

	get isLinear(): boolean {
		if (this.elements?.length === 0) {
			return false;
		}

		return this.elements?.every(
			(el) =>
				el?.data?.input?.length <= 1 && el?.data?.output?.length <= 1
		);
	}

	get isValid(): boolean {
		return this.elements.every(
			(el) =>
				(el.data.input !== undefined && el.data.input.length === 0) ||
				el.data.input.every((id) => this.getElementById(id) !== null)
		);
	}

	constructor(
		svg: SVGSVGElement,
		contentWidth: number,
		contentHeight: number,
		forceUpdate: () => void
	) {
		this.svg = svg;
		this.elementsGroup = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'g'
		);
		this.svg.appendChild(this.elementsGroup);
		this.contentWidth = contentWidth;
		this.contentHeight = contentHeight;
		this.forceUpdate = forceUpdate;
	}

	handleZoom(event: WheelEvent): void {}

	handleMouseDown(event: MouseEvent): void {
		const { x, y } = transformCoordinates(
			event.clientX,
			event.clientY,
			this.svg
		);

		this.elements.forEach((element) => element.setSelected(x, y));
	}

	handleMouseMove(event: MouseEvent, props?: any): void {
		const activeElement = this.elements.find((el) => el.isSelected);

		if (!activeElement || !props.lastX || !props.lastY || !props.scale) {
			return;
		}

		const dx = (event.clientX - props.lastX) / props.scale;
		const dy = (event.clientY - props.lastY) / props.scale;
		activeElement.x += dx;
		activeElement.y += dy;

		this.draw();
	}

	handleMouseUp(event: MouseEvent, props?: any): void {
		this.elements.forEach((el) => (el.isSelected = false));
	}

	handleClick(event: MouseEvent): void {
		// console.log('handleClick');
	}

	public addHtmlElement(
		element: DashboardElement,
		graph: Graph,
		startNode: string,
		callback: () => void
	) {
		const positions = calculateGraphPositions(
			graph,
			startNode,
			element.data,
			this.contentWidth,
			this.contentHeight
		);

		const { x, y } = positions[element.id] || { x: 0, y: 0 };

		if (element.x === 0 && element.y === 0) {
			this.prepareLastPosition(x, y);
		}

		element.x = x;
		element.y = y;

		this.prepareForeignObject(element, callback);
	}

	public clear() {
		this.elements = [];
		this.draw();
	}

	public getElementById(id: string): DashboardElement | null {
		return this.elements.find((el) => el.id === id) ?? null;
	}

	public snapActiveElementToGrid(grid: Grid) {
		const activeElement = this.elements.find((el) => el.isSelected);

		if (activeElement) {
			const gridSize = grid.getGridSize();
			const x = activeElement.x;
			const y = activeElement.y;

			const snappedX = Math.round(x / gridSize) * gridSize - 16;
			const snappedY = Math.round(y / gridSize) * gridSize - 16;

			activeElement.x = snappedX;
			activeElement.y = snappedY;
		}
	}

	public getLastPosition(): { x: number; y: number } {
		const lastElement = this.elements[this.elements.length - 1];
		if (!lastElement) {
			return { x: 0, y: 0 };
		}

		return { x: lastElement.x, y: lastElement.y };
	}

	private prepareLastPosition(x: number, y: number) {
		const lastElement = this.elements[this.elements.length - 1];
		if (!lastElement) {
			return;
		}

		x = lastElement.x + lastElement.width + 20;
		y = lastElement.y;
	}

	private prepareAbovePosition(element: DashboardElement): {
		x: number;
		y: number;
	} {
		return {
			x: element.x,
			y: element.y - element.height * 1.25,
		};
	}

	private prepareForeignObject(
		element: DashboardElement,
		callback: () => void
	) {
		if (!element) {
			return;
		}
		const foreignObject = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'foreignObject'
		);
		foreignObject.setAttribute('x', element.x.toString());
		foreignObject.setAttribute('y', element.y.toString());
		foreignObject.setAttribute('width', `${element.width}`);
		foreignObject.setAttribute('height', `${element.height}`);
		foreignObject.classList.add('draggable');

		const div = document.createElement('div');
		div.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
		div.style.width = '100%';
		div.style.height = '100%';
		foreignObject.id = element.id;
		foreignObject.appendChild(div);
		this.elementsGroup.appendChild(foreignObject);

		const app = createApp(element.vueComponent, {
			...element.data,
			id: element.id,
		});
		app.mount(div);

		if (!this.elements.includes(element)) {
			this.elements.push(element);
		}

		foreignObject.addEventListener(
			'addConnection' as any,
			(event: Event) => {
				const customEvent = event as CustomEvent<{
					fromElementId: string;
					toElementId: string;
				}>;

				this.addConnection(
					customEvent.detail.fromElementId,
					customEvent.detail.toElementId
				);
			}
		);

		foreignObject.addEventListener(
			'onRemoveCard' as any,
			(event: Event) => {
				const customEvent = event as CustomEvent<{
					id: string;
				}>;

				this.deleteElement(customEvent.detail.id);
				callback();
			}
		);

		foreignObject.addEventListener(
			'onDuplicateCard' as any,
			(event: Event) => {
				const customEvent = event as CustomEvent<{
					id: string;
				}>;

				this.duplicateElement(customEvent.detail.id);
				callback();
			}
		);

		foreignObject.addEventListener(
			'finishExecute' as any,
			(event: Event) => {
				const customEvent = event as CustomEvent<{
					id: string;
				}>;

				this.elements.forEach((item) => {
					if (item.id === customEvent.detail.id) {
						item.setIsExecuted(true);
						item.connections = [];
					}
				});

				callback();
			}
		);

		foreignObject.addEventListener(
			'updateAllData' as any,
			(event: Event) => {
				this.forceUpdate();
			}
		);
	}

	public addConnection(fromElementId: string, toElementId: string) {
		const fromElement = this.getElementById(fromElementId);
		const toElement = this.getElementById(toElementId);

		if (fromElement && toElement) {
			fromElement.data.output.push(toElement.id);
			toElement.data.input.push(fromElement.id);

			fromElement.connections = [];
			toElement.connections = [];
			this.forceUpdate();
		}
	}

	public removeConnection(fromElementId: string, toElementId: string) {
		const fromElement = this.getElementById(fromElementId);
		const toElement = this.getElementById(toElementId);

		if (!fromElement || !toElement) {
			console.error(
				'Elements not found for given IDs:',
				fromElementId,
				toElementId
			);
			return;
		}

		fromElement.data.input = fromElement.data.input.filter(
			(id) => id !== toElementId
		);
		fromElement.data.output = fromElement.data.output.filter(
			(id) => id !== toElementId
		);
		toElement.data.input = toElement.data.input.filter(
			(id) => id !== fromElementId
		);
		toElement.data.output = toElement.data.output.filter(
			(id) => id !== fromElementId
		);

		fromElement.resetConnections();
		toElement.resetConnections();

		this.forceUpdate();
	}

	public addElement(element: DashboardElement, isLast: boolean = false) {
		this.elements.push(element);
		this.draw();
	}

	public deleteElement(id: string) {
		const element = this.getElementById(id);
		if (element) {
			const sanitizedId = CSS.escape(element.id);
			const foreignObject = this.elementsGroup.querySelector(
				`#${sanitizedId}`
			) as SVGForeignObjectElement;
			this.elementsGroup.removeChild(foreignObject);
			this.elements = this.elements.filter((el) => el.id !== id);
		}
	}

	public duplicateElement(id: string) {
		const element = this.getElementById(id);
		if (element) {
			const { x, y } = this.prepareAbovePosition(element);
			if (element.x === 0 && element.y === 0) {
				this.prepareLastPosition(x, y);
			}

			const newId = generateUuid();
			const rawData = toRaw(element.data);
			const data = JSON.parse(JSON.stringify(rawData));
			data.id = newId;
			data.input = [];
			data.output = [];

			const duplicate = DashboardElement.fromObject({
				id: newId,
				data: data,
				vueComponent: toRaw(element.vueComponent),
				x: x,
				y: y,
				width: element.width,
				height: element.height,
				isSelected: false,
			});

			this.addElement(duplicate);
		}
	}

	public updateElements(elements: DashboardElement[]) {
		this.elements = elements;
		this.draw();
	}

	draw() {
		if (this.elements.length === 0) {
			while (this.elementsGroup.firstChild) {
				this.elementsGroup.removeChild(this.elementsGroup.firstChild);
			}
		}

		this.elements.forEach((element) => {
			// Экранируем ID для корректного использования в селекторе
			const sanitizedId = CSS.escape(element.id);
			let foreignObject = this.elementsGroup.querySelector(
				`#${sanitizedId}`
			) as SVGForeignObjectElement;

			if (!foreignObject) {
				this.prepareForeignObject(element, this.forceUpdate);
			} else {
				if (
					Number(foreignObject.width) !== element.width ||
					Number(foreignObject.height) !== element.height
				) {
					foreignObject.setAttribute(
						'width',
						element.width.toString()
					);
					foreignObject.setAttribute(
						'height',
						element.height.toString()
					);
				}

				foreignObject.setAttribute('x', element.x.toString());
				foreignObject.setAttribute('y', element.y.toString());
			}
		});
	}
}
