<template>
	<div class="structure-search-page">
		<StructureBasedResultsWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { StructureBasedResultsWidget } from '@/widgets/structure-based-search/';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.DASHBOARD.VIEW_STRUCTURE_BASED_SEARCH);
});
</script>

<style lang="stylus" scoped>
.structure-search-page
	width 100%
	height 100%
</style>
