import { router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	HitEntity,
	JobsService,
	ResultsSortOption,
	type PaginationPageType,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';
import { clusterResultsFiltersModel } from '../cluster-results-filters-widget';

interface IViewModel {
	isLoading: boolean;
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	hits: HitEntity[];
}
const data: IViewModel = {
	isLoading: false,
	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	hits: [],
};

const state = reactive(data);

watch(
	() => state.pageLimit,
	() => {
		fetchClusterResults(1);
	},
	{ deep: true }
);

async function fetchClusterResults(page: number) {
	state.currentPage = page;
	state.isLoading = true;
	try {
		const { id, clusterId } = router.currentRoute.value.params;
		const { filters } = clusterResultsFiltersModel;

		const result = await JobsService.getInstance().fetchJobHits(
			id as string,
			state.pageLimit.value,
			(page - 1) * state.pageLimit.value,
			ResultsSortOption.defaultOption,
			filters.value,
			clusterId as string
		);
		state.total = result.count;
		state.hits = result.hits;
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

export const clusterResultsListModel = {
	...toRefs(state),
	fetchClusterResults,
};
