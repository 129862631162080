<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.707 3.70808C22.0975 3.31756 22.0975 2.68439 21.707 2.29387C21.3165 1.90335 20.6833 1.90335 20.2928 2.29387L2.29277 20.2939C1.90225 20.6844 1.90225 21.3176 2.29277 21.7081C2.6833 22.0986 3.31646 22.0986 3.70698 21.7081L7.0156 18.3995C8.5298 19.1062 10.2185 19.501 11.9999 19.501C16.9999 19.501 21.2699 16.391 22.9999 12.001C22.1424 9.82502 20.6609 7.96353 18.7757 6.63936L21.707 3.70808ZM16.171 9.24406L14.7083 10.7068C14.8953 11.0984 14.9999 11.5372 14.9999 12.001C14.9999 13.661 13.6599 15.001 11.9999 15.001C11.5361 15.001 11.0973 14.8964 10.7057 14.7094L9.24296 16.1721C10.0335 16.6959 10.9813 17.001 11.9999 17.001C14.7599 17.001 16.9999 14.761 16.9999 12.001C16.9999 10.9824 16.6948 10.0346 16.171 9.24406ZM3.80713 16.1937L7.08374 12.9171C7.02867 12.6201 6.99988 12.3139 6.99988 12.001C6.99988 9.24098 9.23988 7.00098 11.9999 7.00098C12.3128 7.00098 12.619 7.02977 12.916 7.08484L15.0908 4.91004C14.1057 4.6433 13.0695 4.50098 11.9999 4.50098C6.99988 4.50098 2.72988 7.61098 0.999878 12.001C1.62887 13.5971 2.59361 15.024 3.80713 16.1937Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
