export function toStringBy(number: number): string {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e18, symbol: 'E' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	var item = lookup
		.slice()
		.reverse()
		.find(function (item) {
			return number >= item.value;
		});
	return item
		? (number / item.value).toFixed(1).replace(rx, '$1') + item.symbol
		: '0';
}

export function parseJwt(token: string) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

export function ellipsize(
	value: string | null | undefined,
	length: number
): string {
	if (!value) {
		return '';
	}
	return value.length > length
		? value.substring(0, length - 3) + '...'
		: value;
}

export function validateEmail(email: string) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!re.test(String(email).toLowerCase())) {
		throw new Error('Invalid email address');
	}

	return true;
}

export function validatePassword(password: string): boolean {
	if (password.length < 8) {
		return false;
	}

	if (!/\d/.test(password)) {
		return false;
	}

	if (!/[a-z]/.test(password)) {
		return false;
	}

	if (!/[A-Z]/.test(password)) {
		return false;
	}

	return true;
}

export function copyToClipboard(text: string) {
	const textarea = document.createElement('textarea');
	textarea.value = text;

	textarea.setAttribute('readonly', '');
	textarea.style.position = 'absolute';
	textarea.style.left = '-9999px';

	document.body.appendChild(textarea);

	textarea.select();

	try {
		const successful = document.execCommand('copy');
		const msg = successful ? 'success' : 'failure';
	} catch (err) {
		console.error(err);
	}

	document.body.removeChild(textarea);
}

export function generateUuid(): string {
	const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
	return template.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function getLinkFromText(text: string): string {
	const mdLinkRegex = /\[.*?\]\((http[s]?:\/\/[^\s/$.?#].[^\s]*)\)/g;
	const urls = text.match(mdLinkRegex);
	if (urls) {
		const match = mdLinkRegex.exec(urls[0]);
		if (match && match[1]) {
			return match[1];
		}
	}

	const urlRegex = /http[s]?:\/\/[^\s/$.?#].[^\s]*/g;
	const url = text.match(urlRegex);
	if (url) {
		return url[0];
	}

	return '';
}

export const roundValues = (obj: any) => {
	for (let key in obj) {
		if (!isNaN(obj[key]) && obj[key] !== null && obj[key] !== '') {
			obj[key] = parseFloat(obj[key]).toFixed(2);
		}
	}

	return obj;
};
