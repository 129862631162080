<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M18.7 7.1999C18.3 6.7999 17.7 6.7999 17.3 7.1999L9.8 14.6999L6.7 11.5999C6.3 11.1999 5.7 11.1999 5.3 11.5999C4.9 11.9999 4.9 12.5999 5.3 12.9999L9.1 16.7999C9.3 16.9999 9.5 17.0999 9.8 17.0999C10.1 17.0999 10.3 16.9999 10.5 16.7999L18.7 8.5999C19.1 8.1999 19.1 7.5999 18.7 7.1999Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
