<template>
	<div
		v-if="isVisible"
		class="account-settigns-container"
		@keydown="handleKeydown"
	>
		<div class="account-settigns-bg" @click="onClose()"></div>
		<div class="account" ref="accountBodyRef">
			<div v-if="user" class="account-header">
				<div class="account-preview">
					<div class="subheadlineRegular">{{ user.shortName }}</div>
				</div>
				<div class="account-info">
					<div class="subheadlineSemiBold">{{ user.name }}</div>
					<div class="bodySmallRegular">{{ user.email }}</div>
				</div>
				<bUnstyledButton class="account-close" @onClick="onClose">
					<template v-slot:content>
						<IconClose size="1.25rem" />
					</template>
				</bUnstyledButton>
			</div>

			<div class="account-body">
				<bDivider />
				<SuggestFeatureMenuItem @onClick="isVisible = false" />
				<LogoutMenuItem @onClick="isVisible = false" />
			</div>
			<bDivider />
			<div class="account-footer">
				<a
					href="https://bioptic.io/about"
					class="bodySmallRegular"
					target="_blank"
				>
					Company
				</a>
				<div class="bodySmallRegular dot">•</div>
				<a
					href="https://www.bioptic.io/privacy-policy"
					class="bodySmallRegular"
					target="_blank"
					>Privacy Policy</a
				>
				<div class="bodySmallRegular dot">•</div>
				<a
					href="https://www.bioptic.io/terms-of-service"
					class="bodySmallRegular"
					target="_blank"
				>
					Terms of Service
				</a>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { ocularUserModel } from '@/entities/app';
import { LogoutMenuItem } from '@/features/auth/';
import { SuggestFeatureMenuItem } from '@/features/navigation';
import { IconClose, bDivider, bUnstyledButton } from '@/shared/uikit';
import { onMounted, onUnmounted, ref } from 'vue';
import { accountSettingsModel } from '../model';

const { user } = ocularUserModel;

const accountBodyRef = ref<HTMLElement | null>(null);

const emit = defineEmits(['onClose', 'onReload']);
const { isVisible } = accountSettingsModel;

const onClose = () => {
	isVisible.value = false;

	Analytics.send(AnalyticsEvents.Home.CLOSE_ACCOUNT_MODAL);
};

const handleKeydown = (event: any) => {
	if (event.key === 'Escape') {
		onClose();
	}
};

onMounted(() => {
	window.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
	window.removeEventListener('keydown', handleKeydown);
});
</script>

<style lang="stylus" scoped>
.account-settigns-container
    position fixed
    top 0
    right 0
    left 0
    bottom 0
    z-index 100
    transition all 0.2s ease-in-out
    .account-settigns-bg
        position fixed
        top 0
        right 0
        left 0
        bottom 0
        z-index 1
        z-index 1
        transition all 0.2s ease-in-out
	.account-header
		position relative
		transition: all .25s
		display flex
		flex-direction column
		align-items center
		justify-content center
		.account-close
			position absolute
			top -1rem
			right 0rem
			&.b-unstyled-button
				padding 0.5rem
				border-radius 50%
		.account-preview
			display flex
			align-items center
			justify-content center
			width 4rem
			height 4rem
			border-radius 50%
			background var(--primary-200)
			.subheadlineRegular
				color var(--primary-800)
		.account-info
			display flex
			flex-direction column
			align-items center
			justify-content center
			gap 0.31rem
			padding 0.75rem 0
			.subheadlineSemiBold
				color var(--neutral-900)
			.bodySmallRegular
				color var(--neutral-600)
			.headlineSemiBold
				color var(--neutral-600)
	.account-body
		display flex
		flex-direction column
		align-items center
		justify-content flex-start
		img
			width 100%
		.subheadlineRegular
			font-style: normal;
			font-weight: 500;
			line-height: 1.5rem;
			letter-spacing: 0.03125rem;
			span
				font-weight 600
.account
	position: fixed;
	width 23.75rem
	right: 0.5rem;
	top: 3rem;
	z-index 2
	background: white;
	border-radius 1rem
	overflow-y auto
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	padding 1.5rem 0.75rem 0.75rem 0.75rem
	.b-divider
		margin 0.5rem 0
	.account-credits
		width 100%
		padding 1rem
		display flex
		flex-direction column
		align-items flex-start
		justify-content center
		gap 0.37rem
	.account-footer
		padding 1rem 0
		display flex
		flex-direction row
		align-items center
		justify-content center
		gap 0.38rem
		a
			text-decoration none
		.bodySmallRegular
			color var(--neutral-900)
			white-space nowrap
			&.dot
				padding 0 0.38rem
				user-select none
				pointer-events none
				color var(--neutral-900) !important

			&:hover
				cursor pointer
				color var(--primary-600)
</style>
