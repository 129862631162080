<template>
	<div class="threshold-container">
		<div class="threshold">
			<div class="bodySmallSemiBold">
				Tanimoto
				<bChip
					:label="`${modelValue}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="threshold--input">
				<Slider
					:value="modelValue"
					:min="0.1"
					:max="1"
					:step="0.01"
					:tooltips="false"
					@change="handleUpdate"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { bChip } from '@/shared/uikit';
import Slider from '@vueform/slider';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: {
		type: Number,
		required: true,
	},
	threshold: {
		type: Number,
		required: false,
	},
});

const handleUpdate = (value: number) => {
	emit('update:modelValue', value);
};
</script>

<style lang="stylus" scoped>
.threshold-container
	width 100%
	background white
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
	.threshold
		position relative
		display flex
		flex-direction column
		gap 0.25rem
		padding 0.75rem 0.5rem
		border 1px solid var(--neutral-200)
		border-radius 0.75rem
		.bodySmallSemiBold
			width 100%
			display flex
			flex-direction row
			align-items center
			justify-content space-between

		.threshold--input
			position absolute
			bottom -0.2rem
			left 0.5rem
			right 0.5rem
</style>
