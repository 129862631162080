<template>
	<div v-if="modelValue" class="search-filter-container" ref="elementRef">
		<div
			:class="{
				'search-filter-target': true,
				'search-filter-target--active': isOpened || isChanged,
				'search-filter-target--small': isSmall,
			}"
			@click="toggleDropdown"
		>
			{{
				isChanged
					? `${getSearchAttributeTypeLabel(modelValue.type)}: ${
							modelValue?.min
						} - ${modelValue?.max}`
					: `${getSearchAttributeTypeLabel(modelValue.type)}`
			}}
			<IconDropUp
				v-if="isOpened"
				size="1.125rem"
				:color="ThemeColors.primary[600]"
			/>
			<IconDropDown
				v-else
				size="1.125rem"
				:color="ThemeColors.primary[600]"
			/>
		</div>
		<div v-if="isOpened && modelValue" class="search-filter-dropdown">
			<bHistogramSliderEmpty
				:model-value="modelValue"
				:title="getSearchAttributeTypeLabel(modelValue.type)"
				:min="modelValue?.min"
				:max="modelValue?.max"
				:absoluteMin="modelValue?.absolute_min"
				:absoluteMax="modelValue?.absolute_max"
				:items="modelValue?.histogram"
				:byStep="
					modelValue.type === SearchAttributeType.WEIGHT ||
					modelValue.type === SearchAttributeType.HBA ||
					modelValue.type === SearchAttributeType.HBD ||
					modelValue.type === SearchAttributeType.HAC ||
					modelValue.type === SearchAttributeType.ROTATABLE_BONDS
						? 1
						: -1
				"
				@update:modelValue="handleUpdate"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	getSearchAttributeTypeLabel,
	SearchAttributeType,
	SearchesAttributeEntity,
} from '@/shared/api';
import {
	bHistogramSliderEmpty,
	IconDropDown,
	IconDropUp,
	ThemeColors,
} from '@/shared/uikit';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['update:modelValue']);
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const props = defineProps({
	modelValue: Object as () => SearchesAttributeEntity,
	isSmall: Boolean,
});

const isChanged = computed(() => {
	return (
		props.modelValue?.min !== props.modelValue?.absolute_min ||
		props.modelValue?.max !== props.modelValue?.absolute_max
	);
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};

function handleUpdate(newValue: any) {
	emit('update:modelValue', newValue);
}
</script>

<style lang="stylus" scoped>
.search-filter-container
	position: relative
	height: 100%;
	&--loading
		width: 10rem
		height: 100%
		border-radius 1.75rem
		border-top-right-radius: 0
		border-bottom-right-radius: 0
	.search-filter-target
		height: 100%;
		padding: 1.25rem 1.5rem
		display: flex
		align-items: center
		justify-content: space-between
		gap 0.5rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.00625rem;
		border: 1px solid var(--neutral-200);
		color var(--primary-600)
		border-radius 1.75rem
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		white-space: nowrap
		&:hover
			background var(--neutral-50)
			transition all 0.2s ease-in-out
		&--active
			color var(--primary-600)
			border-color var(--primary-600) !important
			background var(--primary-200) !important
			transition all 0.2s ease-in-out
		&--small
			padding: 0.5rem 0.75rem
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.00625rem;
			transition all 0.2s ease-in-out

	.search-filter-dropdown
		position: absolute
		left: 0
		margin-top: 0.5rem
		width 17.4375rem
		max-height 15rem
		padding: 0.25rem
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
</style>
