export const wait = async (ms: number, value: any = undefined): Promise<any> =>
	await new Promise((resolve) => {
		setTimeout(() => resolve(value), ms);
	});

export const sleep = async (
	ms: number,
	value: any = undefined
): Promise<any> => {
	return await wait(ms, value);
};
