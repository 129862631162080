<template>
	<div class="b-text-input-container">
		<div class="b-text-input-left-icon">
			<slot name="left-icon"></slot>
		</div>

		<input
			ref="inputRef"
			:value="modelValue"
			@input="updateValue($event as InputEvent)"
			:class="classInputName"
			:placeholder="placeholder"
			:type="type"
			autocomplete="off"
			class="b-text-input"
			@blur="$emit('onBlur', $event)"
			@focus="$emit('onFocus', $event)"
		/>

		<div class="b-text-input-right-icon">
			<slot name="right-icon"></slot>
		</div>
		<slot name="dropdown"></slot>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus']);
const inputRef = ref<any>(null);

const props = defineProps({
	modelValue: String,
	type: { type: String, required: false },
	size: { type: String, required: false, default: 'medium' },
	placeholder: { type: String, required: false },
	isDisabled: { type: Boolean, required: false, default: false },
});

const classInputName = computed((): any => {
	var result = {
		'b-text-input--size-medium': props.size === 'medium',
		'b-text-input--size-small': props.size === 'small',
		'b-text-input--size-large': props.size === 'large',
		'b-text-input--disabled': props.isDisabled,
	};
	return result;
});

const updateValue = (event: InputEvent) => {
	const target = event.target as HTMLInputElement;
	emit('update:modelValue', target.value);
};
</script>

<style lang="stylus" scoped>
.b-text-input-container
	position relative
	width 100%
	.b-text-input-right-icon
		position absolute
		right 0.5rem
		top 50%
		transform translateY(-50%)
		display flex
		align-items center
		justify-content center
	.b-text-input-left-icon
		position absolute
		left 0.5rem
		top 50%
		transform translateY(-50%)
		display flex
		align-items center
		justify-content center
	input
		all: unset;

	.b-text-input
		width 100%
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		font-family: var(--font-family);
		font-style: normal;
		font-weight: 400;
		background white
		outline: 1px solid var(--neutral-200);
		outline-offset -1px;
		border-radius: 0.5rem;
		transition all 0.2s ease-in-out

		&:focus
			outline-color: var(--primary-600);
			background var(--neutral-50);

		&:hover
			background var(--neutral-50);


		&::placeholder
			color: var(--neutral-500)
		&--size-large
			height 3.5rem
			font-size: 0.875rem;
			padding: 1rem
			transition all 0.2s ease-in-out
		&--size-medium
			height 2.5rem
			font-size: 0.75rem;
			padding: 0.75rem 1rem;
			transition all 0.2s ease-in-out
		&--size-small
			height 2rem
			font-size: 0.75rem;
			padding: 0.5rem 0.75rem;
			transition all 0.2s ease-in-out
		&--disabled
			pointer-events none
			user-select none
			background var(--neutral-200)
			&:focus
				display none
</style>
