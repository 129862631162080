import { ThemeColors } from '@/shared/uikit';
import moment from 'moment';
import type { MetricType } from '..';

export class CatalogEntity {
	constructor(
		public id: string,
		public name: string,
		public size: number,
		public color: string,
		public max_top_k: number,
		public estimated_waiting_time: number,
		public metric: MetricType
	) {}

	get formattedSearchTime(): string {
		if (this.estimated_waiting_time < 60) {
			return `(up to ${Math.ceil(
				this.estimated_waiting_time
			)} sec estimated time)`;
		} else {
			const duration = moment.duration(
				this.estimated_waiting_time,
				'seconds'
			);
			const minutes = duration.asMinutes();
			return `(up to ${Math.ceil(minutes)} min estimated time)`;
		}
	}

	static fromObject(obj: any): CatalogEntity {
		return new CatalogEntity(
			obj.id,
			obj.name,
			obj.size,
			obj.color,
			obj.max_top_k,
			obj.estimated_waiting_time,
			obj.metric
		);
	}
}

export const getDBLabelById = (id: string): string => {
	const jobs: Record<string, string> = {
		'854ca4da-77cf-4f82-b1bf-da65c7443478': 'PubChem',
		'9ab95943-90ba-4d1e-bdb1-b6a15bdb5ba8': 'Enamine Real 6B',
		'1f8932df-6a56-4546-843f-52e6bba2b18c': 'Life Chemicals Stock',
		'fd4ecf21-e449-4b28-b9c4-a66e33acbda7': 'Life Chemicals Tangible',
		'6c8819d5-8073-4be0-97e3-827f6f5f1a3e': 'ZINC20',
	};

	return jobs[id];
};

export const getDBColorById = (id: string): string => {
	const jobs: Record<string, string> = {
		'854ca4da-77cf-4f82-b1bf-da65c7443478': ThemeColors.indigo[600],
		'9ab95943-90ba-4d1e-bdb1-b6a15bdb5ba8': ThemeColors.amber[600],
		'1f8932df-6a56-4546-843f-52e6bba2b18c': ThemeColors.sky[600],
		'fd4ecf21-e449-4b28-b9c4-a66e33acbda7': ThemeColors.pink[600],
		'6c8819d5-8073-4be0-97e3-827f6f5f1a3e': ThemeColors.primary[600],
	};

	return jobs[id];
};
