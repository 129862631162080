import {
	CatalogService,
	DruglikenessEntity,
	SearchesAttributeEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	attributes: SearchesAttributeEntity[];
	druglikenessOptions: DruglikenessEntity[];
}
const data: IViewModel = {
	isLoading: false,
	attributes: [],
	druglikenessOptions: DruglikenessEntity.options,
};

const state = reactive(data);

const filters = computed(() => {
	return state.attributes.flatMap((item) => {
		if (item.absolute_max !== item.max || item.absolute_min !== item.min) {
			return [
				{
					attribute: item.type,
					minimum: item.min,
					maximum: item.max,
				},
			];
		} else {
			return [];
		}
	});
});

function updateFiltersByDruglikeness() {
	state.attributes.forEach((filterItem) => {
		const relevantAttributes = state.druglikenessOptions
			.filter((option) => option.isSelected)
			.flatMap((option) => option.attributes)
			.filter((attr) => attr.attribute === filterItem.type);

		if (relevantAttributes.length > 0) {
			const minValues = relevantAttributes
				.map((attr) => attr.min)
				.concat(filterItem.min);
			const maxValues = relevantAttributes
				.map((attr) => attr.max)
				.concat(filterItem.max);

			const minIntersection = Math.max(...minValues);
			const maxIntersection = Math.min(...maxValues);

			if (minIntersection <= maxIntersection) {
				filterItem.min =
					minIntersection === -1 ? filterItem.min : minIntersection;
				filterItem.max = maxIntersection;
			}
		} else {
			filterItem.min = filterItem.absolute_min;
			filterItem.max = filterItem.absolute_max;
		}
	});
}

function setDefaultDilters() {
	state.attributes.forEach((item) => {
		item.min = item.absolute_min;
		item.max = item.absolute_max;
	});

	state.druglikenessOptions.forEach((option) => {
		option.isSelected = false;
	});
}

function clear() {
	state.attributes = [];
	setDefaultDilters();
}

async function fetchPreFilters(catalogId: string) {
	state.isLoading = true;
	try {
		state.attributes =
			await CatalogService.getInstance().fetchAttributes(catalogId);
		updateFiltersByDruglikeness();
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const tdssPrefiltersListModel = {
	...toRefs(state),
	clear,
	fetchPreFilters,
	setDefaultDilters,
	updateFiltersByDruglikeness,
	filters,
};
