export class FeedbackService {
	private static instance: FeedbackService;

	private constructor() {}

	public static getInstance(): FeedbackService {
		if (!FeedbackService.instance) {
			FeedbackService.instance = new FeedbackService();
		}
		return FeedbackService.instance;
	}

	public async submit(email: string, message: string): Promise<void> {
		const url =
			'https://api.telegram.org/bot' +
			import.meta.env.VITE_TELEGRAM_BOT_TOKEN +
			'/sendMessage';

		let text = `<b>Feedback from: </b>` + window.location.href;
		text += `\n\n<b>Email:</b> ${email}`;
		text += `\n\n<b>Message:</b> ${message}`;

		await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				chat_id: import.meta.env.VITE_TELEGRAM_CHAT_ID,
				parse_mode: 'html',
				text: text,
			}),
		});
	}
}
