import { Endpoints, MessageType } from '@/shared/api';
import { TokenService } from '../auth/TokenService';
import { RestClient } from '../client';

export class ChemcrowService {
	private static instance: ChemcrowService;
	private ws: WebSocket | null = null;
	private connectPromise: Promise<void> | null = null;
	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): ChemcrowService {
		if (!ChemcrowService.instance) {
			ChemcrowService.instance = new ChemcrowService();
		}
		return ChemcrowService.instance;
	}

	private async ensureConnected(chat_id: string | null): Promise<void> {
		if (this.ws && this.ws.readyState === WebSocket.OPEN) {
			return;
		}

		if (this.connectPromise) {
			return this.connectPromise;
		}

		this.connectPromise = new Promise<void>((resolve, reject) => {
			const token = TokenService.getInstance().getToken();

			const baseUrl = import.meta.env.VITE_REST_BASE_URL.replace(
				'https',
				'wss'
			);

			const wsUrl = chat_id
				? `${baseUrl}chats?chat_id=${chat_id}&access_token=${token}`
				: `${baseUrl}chats?access_token=${token}`;
			this.ws = new WebSocket(wsUrl);

			this.ws.onopen = () => {
				console.log('WebSocket connection opened');
				this.connectPromise = null;
				resolve();
			};

			this.ws.onerror = (event) => {
				this.connectPromise = null;
				reject(event);
			};

			this.ws.onclose = (event) => {
				this.connectPromise = null;
			};
		});

		return this.connectPromise;
	}

	async connect(
		onopen: () => void,
		onmessage: (event: MessageEvent) => void,
		onerror: (event: Event) => void,
		onclose: (event: CloseEvent) => void,
		chat_id: string | null
	): Promise<void> {
		await this.ensureConnected(chat_id);

		if (this.ws) {
			this.ws.onopen = onopen;
			this.ws.onmessage = onmessage;
			this.ws.onerror = onerror;
			this.ws.onclose = onclose;
		}
	}

	async disconnect(): Promise<void> {
		if (this.ws) {
			this.ws.close();
			this.ws = null;
		}
	}

	async sendMessage(
		message: string,
		chat_id: string | null,
		displayMessage: (message: string) => void
	): Promise<void> {
		await this.ensureConnected(chat_id);

		if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
			throw new Error('WebSocket connection is not open.');
		}
		this.ws.send(
			JSON.stringify({
				type: MessageType.INPUT,
				content: message,
			})
		);

		displayMessage(message);
	}

	// public async uploadFile(
	// 	file: File,
	// 	onProgress: (percentage: number) => void
	// ): Promise<string> {
	// 	const response = await this.client.get<any>(
	// 		Endpoints.Chats.UPLOAD_FILE
	// 	);

	// 	const totalSize = file.size;
	// 	let uploaded = 0;

	// 	const reader = file.stream().getReader();
	// 	let done = false;

	// 	while (!done) {
	// 		const { value, done: readDone } = await reader.read();
	// 		done = readDone;

	// 		if (value) {
	// 			await fetch(response.data.url, {
	// 				method: 'PUT',
	// 				body: value,
	// 				// headers: {
	// 				// 	'Content-Type': 'text/csv',
	// 				// },
	// 			});

	// 			uploaded += value.length;
	// 			onProgress(Math.round((uploaded / totalSize) * 100));
	// 		}
	// 	}

	// 	return response.data.file_id;
	// }

	public async uploadFile(file: File): Promise<string> {
		const response = await this.client.get<any>(
			Endpoints.Chats.UPLOAD_FILE
		);

		// Чтение файла как ArrayBuffer
		const arrayBuffer = await file.arrayBuffer();
		const byteArray = new Uint8Array(arrayBuffer);

		// Отправка byteArray
		const fetchResponse = await fetch(response.data.url, {
			method: 'PUT',
			body: byteArray, // Используем byteArray в теле запроса
		});

		if (!fetchResponse.ok) {
			throw new Error('Failed to upload file');
		}

		return response.data.file_id;
	}
}
