<template>
	<span
		v-if="isSended"
		class="resend-button resend-button--disabled"
		@click="onClickResend"
		>Try again after {{ remainingSeconds }}s</span
	>
	<span v-else class="resend-button" @click="onClickResend"
		>Let's resend it</span
	>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
const emit = defineEmits(['onResend']);

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	waitDuration: {
		type: Number,
		default: 180,
	},
});

const isSended = ref(false);
const remainingSeconds = ref(props.waitDuration);

const onClickResend = () => {
	isSended.value = true;
	remainingSeconds.value = props.waitDuration;
	const now = Date.now();
	localStorage.setItem(props.id, JSON.stringify(now));
	startTimer();

	emit('onResend');
};

const intervalId = ref<NodeJS.Timeout | null>(null);

function startTimer() {
	intervalId.value = setInterval(() => {
		if (remainingSeconds.value > 1) {
			remainingSeconds.value--;
		} else {
			clearInterval(intervalId.value as NodeJS.Timeout);
			isSended.value = false;
		}
	}, 1000);
}

onMounted(() => {
	const sendedVerifyEmail = localStorage.getItem(props.id);
	if (sendedVerifyEmail) {
		const sentTime = JSON.parse(sendedVerifyEmail);
		const elapsedTime = Math.floor((Date.now() - sentTime) / 1000);
		remainingSeconds.value = Math.max(0, props.waitDuration - elapsedTime);
		isSended.value = remainingSeconds.value > 0;
		if (isSended.value) {
			startTimer();
		}
	}
});

onUnmounted(() => {
	if (intervalId.value) {
		clearInterval(intervalId.value as NodeJS.Timeout);
	}
});
</script>

<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';

.resend-button
    color var(--primary-600)
    cursor pointer
    transition all 0.2s ease-in-out
    &--disabled
        color var(--neutral-400) !important
        cursor default
        pointer-events none
    &:hover
        color var(--primary-500)
        transition all 0.2s ease-in-out
</style>
