<template>
	<canvas :id="`modal-scene-smiles-${props.smiles}`"></canvas>
</template>

<script setup lang="ts">
import { drawSmiles } from '@/shared/lib/rdkit';
import { onMounted, onUpdated } from 'vue';

const props = defineProps({
	smiles: {
		type: String,
		required: true,
	},
});

onUpdated(() => drawCanvas());
onMounted(() => drawCanvas());

const drawCanvas = () => {
	console.log(props.smiles);
	drawSmiles(props.smiles, `modal-scene-smiles-${props.smiles}`, 500, 444);
};
</script>

<style lang="stylus">
#preview-smiles-modaL-container
	.b-modal
		gap 0
		background var(--neutral-50) !important
		padding 0.5rem
		.b-modal-body
			display: flex
			justify-content: center
			align-items: center
			background white
			border-radius 0.5rem
		.b-modal-header
			display: none !important
.preview-smiles-modal-container
	display: flex
	flex-direction row
	justify-content: center
	align-items: center
	gap 1rem
	.preview-smiles-modal--legend
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: center
		gap 0.5rem
		.subheadlineBold
			color: var(--neutral-900)
			font-size: 1.25rem
			margin-top: 1rem
		.legend-tag
			padding 0.5rem
			display: flex
			justify-content: center
			align-items: center
			gap 0.5rem
			border-radius: 0.5rem
			color white
</style>
