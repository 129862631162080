<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 21 20"
		fill="none"
	>
		<path
			d="M10.6848 4.98108C11.8693 4.96115 13.0139 5.40892 13.8704 6.22729L16.2606 3.83714C14.7522 2.42047 12.754 1.64265 10.6848 1.66668C7.53405 1.66547 4.6522 3.44207 3.23785 6.2576L6.02195 8.41668C6.67845 6.38988 8.55453 5.00759 10.6848 4.98108Z"
			fill="#EA4335"
		/>
		<path
			d="M3.23787 6.25757C2.05605 8.61256 2.05605 11.3874 3.23787 13.7424L6.02193 11.5833C5.6735 10.5565 5.6735 9.44347 6.02193 8.41667L3.23787 6.25757Z"
			fill="#FBBC04"
		/>
		<path
			d="M13.5069 14.2235C11.1702 15.7233 8.06009 15.0449 6.56025 12.7083C6.33483 12.3571 6.15402 11.9792 6.02197 11.5833L3.23792 13.7424C4.65223 16.5579 7.5341 18.3345 10.6849 18.3333C12.7135 18.3883 14.6864 17.6661 16.2 16.3144L13.5069 14.2235Z"
			fill="#34A853"
		/>
		<path
			d="M18.5333 8.48486H10.6849V11.7083H15.1697C14.9822 12.7387 14.3814 13.6474 13.5068 14.2235C13.5042 14.2252 13.5014 14.2265 13.4988 14.2282C13.5014 14.2265 13.5042 14.2252 13.5068 14.2235L16.2 16.3145L16.2 16.3144C17.8586 14.717 18.7617 12.4909 18.6849 10.1894C18.6853 9.61783 18.6346 9.0474 18.5333 8.48486Z"
			fill="#1A73E8"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { sizeProp } from './config';

const props = defineProps({
	size: sizeProp,
});

const { size } = toRefs(props);
</script>
