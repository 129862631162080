<template>
	<div class="collections-list-widget-container">
		<div class="collections-list-widget-container--title">
			<div class="calloutSemiBold">Collections</div>
		</div>

		<div
			v-if="!isLoading && collections.length > 0"
			class="collections-list-widget"
			:class="{
				'collections-list-widget--empty':
					!isLoading && collections.length === 0,
			}"
		>
			<CollectionCard
				v-for="collection in collections"
				:key="'collection-key-' + collection.id"
				:collection="collection"
				@onClick="openOnNewTab(collection)"
			/>
		</div>
		<bPagination
			v-if="!isLoading && collections.length > 0"
			:limit="pageLimit.value"
			:total="total"
			:currentPage="currentPage"
			@currentChange="fetchCollections"
		>
			<template v-slot:select-page>
				<div class="pagination-select-page">
					<bSelect
						v-model="pageLimit"
						:options="pageOptions"
						size="small"
						position="top"
					/>
				</div>
			</template>
		</bPagination>

		<bEmptyView
			v-if="!isLoading && collections.length === 0"
			title="You haven't created any collections yet"
			description="Create a new collection to catalog your molecules"
		>
			<template v-slot:image>
				<bImageEmpty2 :width="345" :height="258" />
			</template>
		</bEmptyView>
		<template v-if="isLoading">
			<div class="empty-view"><bLoader size="4" /></div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { CollectionCard } from '@/entities/collection/';
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabType, type CollectionEntity } from '@/shared/api';
import {
	bEmptyView,
	bImageEmpty2,
	bLoader,
	bPagination,
	bSelect,
} from '@/shared/uikit';
import { onMounted } from 'vue';
import { collectionsListModel } from './model';

const {
	fetchCollections,
	collections,
	isLoading,
	pageLimit,
	total,
	currentPage,
	pageOptions,
} = collectionsListModel;

onMounted(() => {
	fetchCollections(1);
});

const openOnNewTab = (collection: CollectionEntity) => {
	const { addTab } = navigationTabsModel;

	addTab({
		id: `collection-${collection.id}`,
		title: 'Collection',
		description: collection.name,
		path: `/collection/${collection.id}`,
		type: NavigationTabType.Collection,
	});
};
</script>

<style lang="stylus" scoped>
.collections-list-widget
	width 100%
	display grid
	grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
	gap 0.75rem
	justify-content center
	padding 1rem
	gap 1.5rem
	&--empty
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
.collections-list-widget-container
	width 100%
	height calc(100vh - 6.25rem)
	position relative
	display flex
	flex-direction column
	justify-content flex-start
	align-items flex-start
	gap 1.5rem
	overflow-y scroll
	overflow-x hidden
	padding-top 4rem
	padding-bottom 4rem
	&::-webkit-scrollbar
		width 0.25rem
	&--title
		padding-left 1.25rem
	.b-pagination
		padding 0 1rem

	.empty-view
		position relative
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
