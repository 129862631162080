import 'firebase/compat/auth';
import { TokenService } from '../auth/TokenService';
import { MessageType } from '../chemcrow';
import { RestClient } from '../client';
import { Endpoints } from '../shared';
import { ForeignObjectEntity, StepType } from './entities';

export class PipelineService {
	private static instance: PipelineService;
	private ws: WebSocket | null = null;
	private connectPromise: Promise<void> | null = null;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): PipelineService {
		if (!PipelineService.instance) {
			PipelineService.instance = new PipelineService();
		}
		return PipelineService.instance;
	}

	private async ensureConnected(): Promise<void> {
		if (this.ws && this.ws.readyState === WebSocket.OPEN) {
			return;
		}

		if (this.connectPromise) {
			return this.connectPromise;
		}

		this.connectPromise = new Promise<void>((resolve, reject) => {
			const token = TokenService.getInstance().getToken();

			const baseUrl = import.meta.env.VITE_REST_BASE_URL.replace(
				'https',
				'wss'
			);
			const wsUrl = `${baseUrl}ai-scientist/relevanceai?access_token=${token}`;

			this.ws = new WebSocket(wsUrl);

			this.ws.onopen = () => {
				console.log('WebSocket connection opened');
				this.connectPromise = null;
				resolve();
			};

			this.ws.onerror = (event) => {
				this.connectPromise = null;
				reject(event);
			};

			this.ws.onclose = (event) => {
				this.connectPromise = null;
			};
		});

		return this.connectPromise;
	}

	async connect(
		onopen: () => void,
		onmessage: (event: MessageEvent) => void,
		onerror: (event: Event) => void,
		onclose: (event: CloseEvent) => void
	): Promise<void> {
		await this.ensureConnected();

		if (this.ws) {
			this.ws.onopen = onopen;
			this.ws.onmessage = onmessage;
			this.ws.onerror = onerror;
			this.ws.onclose = onclose;
		}
	}

	async disconnect(): Promise<void> {
		if (this.ws) {
			this.ws.close();
			this.ws = null;
		}
	}

	async sendMessage(
		message: string,
		displayMessage: (message: string) => void
	): Promise<void> {
		await this.ensureConnected();

		if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
			throw new Error('WebSocket connection is not open.');
		}
		this.ws.send(
			JSON.stringify({
				type: MessageType.INPUT,
				content: message,
			})
		);

		displayMessage(message);
	}

	async fetchPipeline(): Promise<ForeignObjectEntity[]> {
		const response = await fetch('/data/pipeline.json').then((res) =>
			res.json()
		);

		return response.steps.map((step: any) => {
			const size = getCardSizeByStepType(step.data.type, step.data);

			return ForeignObjectEntity.fromObject({
				id: step.id,
				type: step.data.type,
				data: step.data,
				width: size.width,
				height: size.height,
				input: step.input,
				output: step.output,
				comment: step.comment,
			});
		});
	}

	async fetchTools(): Promise<ForeignObjectEntity[]> {
		const response = await fetch('/data/tools.json').then((res) =>
			res.json()
		);

		return response.tools.map((step: any) => {
			const size = getCardSizeByStepType(step.data.type, step.data);

			return ForeignObjectEntity.fromObject({
				id: step.id,
				type: step.data.type,
				data: step.data,
				width: size.width,
				height: size.height,
				input: step.input,
				output: step.output,
				comment: step.comment,
			});
		});
	}

	async fetchJobPipeline(jobId: string): Promise<ForeignObjectEntity[]> {
		const response = await this.client.get<any>(
			Endpoints.Pipeline.FETCH_PIPELINE(jobId)
		);

		return response.data.steps.map((step: any) => {
			const size = getCardSizeByStepType(step.data.type, step.data);

			return ForeignObjectEntity.fromObject({
				id: step.id,
				type: step.data.type,
				data: step.data,
				width: size.width,
				height: size.height,
				input: step.input,
				output: step.output,
				comment: step.comment,
			});
		});
	}
}

export const getCardSizeByStepType = (
	type: StepType,
	data: any = {}
): { width: number; height: number } => {
	switch (type) {
		case StepType.CATALOG_LOADER:
			return { width: 400, height: 345 };
		case StepType.DOCKING:
			return { width: 400, height: 460 };
		case StepType.LIGAND_BASED_SEARCH:
			return { width: 400, height: 345 };
		case StepType.TARGET_BASED_SEARCH:
			return { width: 400, height: 450 };
		case StepType.NOVELTY_FILTER:
			return { width: 400, height: 400 };
		case StepType.ATTRIBUTE_FILTER:
			const height = data.filters
				.map((attr: any) => {
					return 50;
				})
				.reduce((a: number, b: number) => a + b, 0);

			return { width: 400, height: height + 345 };
		default:
			return { width: 400, height: 500 };
	}
};
