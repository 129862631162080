import { ProbesEntity, TargetEntity } from '.';
import { SearchesAttributeEntity } from '..';
import { RestClient } from '../client';
import { Endpoints } from '../shared';

export class TargetsService {
	private static instance: TargetsService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): TargetsService {
		if (!TargetsService.instance) {
			TargetsService.instance = new TargetsService();
		}
		return TargetsService.instance;
	}

	async fetchTargets(
		keyword: string,
		limit: number = 5,
		offset: number = 0
	): Promise<TargetEntity[]> {
		const response = await this.client.get<any>(Endpoints.Targets.GET, {
			keyword,
			limit,
			offset,
		});

		return response.data.targets.map((target: any) =>
			TargetEntity.fromObject(target)
		);
	}

	async fetchTargetAttributes(
		id: string
	): Promise<SearchesAttributeEntity[]> {
		const response = await this.client.get<any>(
			Endpoints.Targets.GET_ATTRIBUTES(id)
		);

		return response.data.attributes.map((item: any) =>
			SearchesAttributeEntity.fromObject(item)
		);
	}

	async fetchTargetProbes(
		id: string,
		limit: number,
		offset: number,
		isClustered: boolean
	): Promise<{
		count: number;
		probes: ProbesEntity[];
	}> {
		const response = await this.client.post<any>(
			Endpoints.Targets.GET_PROBES(id),
			{
				order_by: 'activity',
				is_descending: false,
				filters: [],
				limit: limit,
				offset: offset,
				is_clustered: isClustered,
			}
		);

		return {
			count: response.data.count,
			probes: response.data.probes.map((probe: any) =>
				ProbesEntity.fromObject(probe)
			),
		};
	}
}
