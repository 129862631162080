import { toRaw } from 'vue';
import { IDBService, RestClient } from '../client';
import { SearchesAttributeEntity } from '../jobs';
import { Endpoints } from '../shared';
import { CatalogEntity } from './entities';

export class CatalogService {
	private static instance: CatalogService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): CatalogService {
		if (!CatalogService.instance) {
			CatalogService.instance = new CatalogService();
		}
		return CatalogService.instance;
	}

	public async fetchCatalogs(
		top_k: number,
		queries_count: number,
		isStructureSearch: boolean = false
	): Promise<CatalogEntity[]> {
		const response = await this.client.get<any>(Endpoints.Catalogs.GET, {
			queries_count: queries_count,
			top_k: top_k,
			metric: isStructureSearch ? 'tanimoto' : 'activity',
		});

		let results = response.data.catalogs.map((catalog: any) =>
			CatalogEntity.fromObject(catalog)
		);

		await this.updateLocalCatalogs(results);

		return results;
	}

	public async fetchCatalogById(id: string): Promise<CatalogEntity> {
		const response = await this.client.get<any>(
			Endpoints.Catalogs.GET_BY_ID(id)
		);

		return CatalogEntity.fromObject(response.data);
	}

	public async fetchAttributes(
		catalog_id: string
	): Promise<SearchesAttributeEntity[]> {
		const response = await this.client.get<any>(
			Endpoints.Catalogs.GET_ATTRIBUTES(catalog_id)
		);

		return response.data.attributes.map((attribute: any) =>
			SearchesAttributeEntity.fromObject(attribute)
		);
	}

	public async fetchLocalCatalogs(): Promise<CatalogEntity[]> {
		const db = await IndexedDBCatalogsInstance.get();
		const catalogs = await db.getAll();

		if (catalogs.length === 0) {
			await this.fetchCatalogs(10, 10);
		}

		return catalogs;
	}

	private async updateLocalCatalogs(
		catalogs: CatalogEntity[]
	): Promise<void> {
		const db = await IndexedDBCatalogsInstance.get();
		const promises = [];

		for (const catalog of catalogs) {
			promises.push(db.addOrUpdate(toRaw(catalog)));
		}

		await Promise.all(promises);
	}
}

export class IndexedDBCatalogsInstance {
	private static dbName = 'catalogs';
	private static storeName = 'cashedCatalogs';

	static async get(): Promise<IDBService<CatalogEntity>> {
		try {
			const dbService = new IDBService<CatalogEntity>(
				IndexedDBCatalogsInstance.dbName,
				IndexedDBCatalogsInstance.storeName,
				CatalogEntity
			);

			await dbService.init();

			return dbService;
		} catch (error) {
			console.error(
				'Error working with IndexedDBCatalogsInstance:',
				error
			);
			throw Error('Error working with IndexedDBCatalogsInstance');
		}
	}
}
