<template>
	<div class="home-page">
		<div class="home-page--logo">
			<IconLogo />
		</div>

		<ApplicationsView />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconLogo } from '@/shared/uikit';
import { ApplicationsView } from '@/widgets/home';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.Home.OPEN_HOME_PAGE);
});
</script>

<style lang="stylus" scoped>
.home-page
	width 100%
	height 100%
	display flex
	flex-direction column
	align-items center
	justify-content center
	gap 1.25rem
	.home-page--logo
		margin-top -8rem
		height 6.25rem
		display flex
		flex-direction row
		align-items center
		justify-content center
</style>
