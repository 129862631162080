<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 281 280"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_281_9057)">
			<path
				opacity="0.1"
				d="M103.671 280C160.928 280 207.343 271.813 207.343 261.714C207.343 251.614 160.928 243.427 103.671 243.427C46.4153 243.427 0 251.614 0 261.714C0 271.813 46.4153 280 103.671 280Z"
				fill="#334155"
			/>
			<path
				opacity="0.05"
				d="M166.681 202.325C222.638 202.325 268 157.033 268 101.162C268 45.2919 222.638 0 166.681 0C110.724 0 65.3618 45.2919 65.3618 101.162C65.3618 157.033 110.724 202.325 166.681 202.325Z"
				fill="#334155"
			/>
			<path
				opacity="0.05"
				d="M166.681 184.675C212.875 184.675 250.323 147.285 250.323 101.162C250.323 55.0396 212.875 17.6497 166.681 17.6497C120.487 17.6497 83.0388 55.0396 83.0388 101.162C83.0388 147.285 120.487 184.675 166.681 184.675Z"
				fill="#334155"
			/>
			<path
				opacity="0.05"
				d="M166.681 169.608C204.541 169.608 235.233 138.964 235.233 101.162C235.233 63.3606 204.541 32.7163 166.681 32.7163C128.821 32.7163 98.1289 63.3606 98.1289 101.162C98.1289 138.964 128.821 169.608 166.681 169.608Z"
				fill="#334155"
			/>
			<path
				opacity="0.05"
				d="M167.125 150.149C194.271 150.149 216.276 128.178 216.276 101.075C216.276 73.9714 194.271 52 167.125 52C139.98 52 117.975 73.9714 117.975 101.075C117.975 128.178 139.98 150.149 167.125 150.149Z"
				fill="#334155"
			/>
			<path
				d="M66.2592 110.216C66.2592 110.216 58.2935 132.603 61.8338 140.557C65.3741 148.51 70.9796 156.169 70.9796 156.169C70.9796 156.169 68.9144 111.689 66.2592 110.216Z"
				fill="#0D9488"
			/>
			<path
				opacity="0.1"
				d="M66.2592 110.216C66.2592 110.216 58.2935 132.603 61.8338 140.557C65.3741 148.51 70.9796 156.169 70.9796 156.169C70.9796 156.169 68.9144 111.689 66.2592 110.216Z"
				fill="#334155"
			/>
			<path
				d="M71.8647 162.06C71.8647 162.06 71.2746 167.657 70.9796 167.951C70.6846 168.246 71.2746 168.835 70.9796 169.719C70.6846 170.602 70.3895 171.781 70.9796 172.075C71.5696 172.37 67.7343 198.292 67.7343 198.292C67.7343 198.292 58.2934 210.664 62.1288 230.105L63.3089 249.842C63.3089 249.842 72.4547 250.431 72.4547 247.191C72.4547 247.191 71.8647 243.361 71.8647 241.594C71.8647 239.826 73.3398 239.826 72.4547 238.943C71.5696 238.059 71.5696 237.47 71.5696 237.47C71.5696 237.47 73.0448 236.291 72.7497 235.997C72.4547 235.702 75.405 214.788 75.405 214.788C75.405 214.788 78.6503 211.548 78.6503 209.78V208.013C78.6503 208.013 80.1254 204.183 80.1254 203.889C80.1254 203.594 88.0911 185.625 88.0911 185.625L91.3364 198.587L94.8767 217.144C94.8767 217.144 96.6469 233.935 100.187 240.415C100.187 240.415 106.383 261.624 106.383 261.035C106.383 260.446 116.709 258.973 116.414 256.322C116.119 253.671 110.218 216.555 110.218 216.555L111.693 161.471L71.8647 162.06Z"
				fill="#334155"
			/>
			<path
				d="M63.8989 248.369C63.8989 248.369 55.9332 263.981 61.2437 264.57C66.5542 265.159 68.6194 265.159 70.9796 262.803C72.2699 261.514 74.8829 259.786 76.9867 258.484C78.2332 257.724 79.2402 256.63 79.8922 255.325C80.5442 254.021 80.8147 252.559 80.6728 251.108C80.5172 249.667 79.9779 248.479 78.6503 248.369C75.1099 248.074 70.9796 244.834 70.9796 244.834L63.8989 248.369Z"
				fill="#334155"
			/>
			<path
				d="M107.858 259.857C107.858 259.857 99.8922 275.469 105.203 276.058C110.513 276.648 112.578 276.648 114.939 274.291C116.229 273.003 118.842 271.274 120.946 269.972C122.192 269.213 123.199 268.118 123.851 266.813C124.503 265.509 124.774 264.047 124.632 262.596C124.476 261.155 123.937 259.968 122.609 259.857C119.069 259.563 114.939 256.322 114.939 256.322L107.858 259.857Z"
				fill="#334155"
			/>
			<path
				d="M99.4389 84.6666C106.288 84.6666 111.841 79.1227 111.841 72.284C111.841 65.4453 106.288 59.9014 99.4389 59.9014C92.5896 59.9014 87.0371 65.4453 87.0371 72.284C87.0371 79.1227 92.5896 84.6666 99.4389 84.6666Z"
				fill="#FEE2E2"
			/>
			<path
				d="M91.4663 76.3525C91.4663 76.3525 82.6079 92.6268 81.8992 92.6268C81.1905 92.6268 97.8444 97.9337 97.8444 97.9337C97.8444 97.9337 102.451 82.367 103.159 80.9518L91.4663 76.3525Z"
				fill="#FEE2E2"
			/>
			<path
				d="M105.055 94.1618C105.055 94.1618 87.3535 84.441 85.5834 84.7356C83.8132 85.0301 64.9315 101.526 65.2266 108.301C65.5216 115.076 67.8818 126.27 67.8818 126.27C67.8818 126.27 68.7669 157.494 70.537 157.789C72.3072 158.083 70.242 163.386 70.8321 163.386C71.4221 163.386 112.136 163.386 112.431 162.502C112.726 161.618 105.055 94.1618 105.055 94.1618Z"
				fill="#0D9488"
			/>
			<path
				d="M114.938 164.122C114.938 164.122 120.544 181.207 115.823 180.618C111.103 180.029 109.038 165.889 109.038 165.889L114.938 164.122Z"
				fill="#FEE2E2"
			/>
			<path
				d="M99.8922 93.1307C99.8922 93.1307 88.9762 95.4873 90.7464 110.216C92.5166 124.944 95.7619 139.673 95.7619 139.673L106.678 163.533L107.858 167.951L115.824 165.889L109.923 131.719C109.923 131.719 107.858 95.1927 105.203 94.0144C103.528 93.3017 101.708 92.9987 99.8922 93.1307Z"
				fill="#0D9488"
			/>
			<path
				opacity="0.1"
				d="M93.2539 139.231L106.825 163.385L95.3912 137.933L93.2539 139.231Z"
				fill="#334155"
			/>
			<path
				d="M111.786 68.6378L111.827 67.6916L113.712 68.1601C113.692 67.8563 113.606 67.5606 113.46 67.2932C113.314 67.0259 113.112 66.7933 112.867 66.6116L114.875 66.4995C113.19 64.1086 111.037 62.0835 108.547 60.5466C106.056 59.0096 103.279 57.9927 100.383 57.5571C96.0398 56.9285 91.2032 57.8382 88.2245 61.0569C86.7798 62.6181 85.872 64.6035 85.2262 66.629C84.0368 70.3595 83.7945 74.8065 86.2745 77.8388C88.7951 80.9207 93.1978 81.5244 97.1645 81.9058C98.5603 82.0401 100.023 82.1649 101.316 81.6242C101.46 80.143 101.27 78.6484 100.761 77.25C100.548 76.8171 100.447 76.3384 100.465 75.8567C100.642 74.6784 102.216 74.3816 103.398 74.5408C104.581 74.7 106.002 74.9435 106.779 74.0394C107.315 73.4166 107.283 72.5097 107.354 71.6921C107.547 69.4663 111.766 69.1045 111.786 68.6378Z"
				fill="#334155"
			/>
			<path
				d="M187.852 264.23C195.833 264.23 202.302 257.771 202.302 249.802C202.302 241.834 195.833 235.375 187.852 235.375C179.871 235.375 173.402 241.834 173.402 249.802C173.402 257.771 179.871 264.23 187.852 264.23Z"
				fill="#0D9488"
			/>
			<path
				d="M18.1467 259.197C26.1273 259.197 32.5968 252.737 32.5968 244.769C32.5968 236.801 26.1273 230.341 18.1467 230.341C10.1661 230.341 3.69653 236.801 3.69653 244.769C3.69653 252.737 10.1661 259.197 18.1467 259.197Z"
				fill="#0D9488"
			/>
			<path
				d="M18.1468 236.046C23.9003 236.046 28.5644 231.389 28.5644 225.644C28.5644 219.9 23.9003 215.243 18.1468 215.243C12.3933 215.243 7.72925 219.9 7.72925 225.644C7.72925 231.389 12.3933 236.046 18.1468 236.046Z"
				fill="#0D9488"
			/>
			<path
				d="M18.1468 216.921C22.2299 216.921 25.5399 213.616 25.5399 209.539C25.5399 205.462 22.2299 202.157 18.1468 202.157C14.0637 202.157 10.7537 205.462 10.7537 209.539C10.7537 213.616 14.0637 216.921 18.1468 216.921Z"
				fill="#0D9488"
			/>
		</g>
		<defs>
			<clipPath id="clip0_281_9057">
				<rect width="268" height="280" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';

const props = defineProps({
	width: {
		type: Number,
		default: 268,
	},
	height: {
		type: Number,
		default: 280,
	},
});

const { width, height } = toRefs(props);
</script>
