<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.5 0L5.00554 1.87759C5.28352 2.91003 6.08997 3.71648 7.12241 3.99446L9 4.5L7.12241 5.00554C6.08997 5.28352 5.28352 6.08997 5.00554 7.12241L4.5 9L3.99446 7.12241C3.71648 6.08997 2.91003 5.28352 1.87759 5.00554L0 4.5L1.87759 3.99446C2.91003 3.71648 3.71648 2.91003 3.99446 1.87759L4.5 0ZM12.5 5L12.9431 6.64566C13.1284 7.33395 13.666 7.87159 14.3543 8.05691L16 8.5L14.3543 8.94309C13.666 9.12841 13.1284 9.66605 12.9431 10.3543L12.5 12L12.0569 10.3543C11.8716 9.66605 11.334 9.12841 10.6457 8.94309L9 8.5L10.6457 8.05691C11.334 7.87159 11.8716 7.33395 12.0569 6.64566L12.5 5ZM6.33703 11.2517L6 10L5.66297 11.2517C5.47765 11.94 4.94002 12.4777 4.25173 12.663L3 13L4.25173 13.337C4.94002 13.5223 5.47765 14.06 5.66297 14.7483L6 16L6.33703 14.7483C6.52235 14.06 7.05998 13.5223 7.74827 13.337L9 13L7.74827 12.663C7.05998 12.4777 6.52235 11.94 6.33703 11.2517Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
