<template>
	<DraggableFormTemplate
		:id="props.id"
		:input="props.input"
		:output="props.output"
		:data="props.data"
		:isLoading="props.isLoading"
		:type="props.type"
		:comment="props.comment"
	>
		<template v-slot:input>
			<AttributeFilterSlider
				v-for="(filter, index) in props.data?.filters"
				:key="index + '-key-filter'"
				:type="filter.attribute"
				:min="Number(filter.minimum)"
				:max="Number(filter.maximum)"
				@update:modelValue="updateFilter(filter, $event)"
			/>
		</template>
		<template v-slot:output> </template>
	</DraggableFormTemplate>
</template>

<script lang="ts" setup>
import { AttributeFilterSlider } from '@/features/pipeline';
import { StepType } from '@/shared/api';
import { DraggableFormTemplate } from '@/widgets/pipeline';
import { onMounted, ref, type PropType } from 'vue';

const threshold = ref<number>(0.4);
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	input: {
		type: Array as PropType<String[]>,
		required: true,
	},
	output: {
		type: Array as PropType<String[]>,
		required: true,
	},
	data: {
		type: Object as PropType<any>,
		required: true,
	},
	isLoading: {
		type: Boolean,
		required: false,
	},
	type: {
		type: String as PropType<StepType>,
		required: true,
	},
	comment: {
		type: String,
		required: false,
	},
});

onMounted(() => {
	threshold.value = props.data?.threshold;
});

const updateFilter = (filter: any, value: number[]) => {
	if (filter) {
		filter.minimum = value[0];
		filter.maximum = value[1];
	}
};
</script>
