<template>
	<div
		class="b-pagination"
		v-if="total > limit"
		:class="{ 'b-pagination__deselect-first': currentPage == 1 }"
	>
		<slot name="select-page"></slot>
		<div class="footnoteRegular">{{ totalOfTitle }}</div>
		<el-pagination
			layout="prev, pager, next"
			:hide-on-single-page="true"
			:page-size="limit"
			:total="total"
			:current-page="currentPage"
			:pagerCount="5"
			@current-change="currentChange"
		>
			<template #prev-icon>
				<IconArrowDown />
			</template>
		</el-pagination>
	</div>
</template>

<script lang="ts" setup>
import { ElPagination } from 'element-plus';
import 'element-plus/dist/index.css';
import { computed } from 'vue';
import { IconArrowDown } from '.';

const emit = defineEmits(['currentChange']);

const props = defineProps({
	total: { type: Number, required: true },
	limit: { type: Number, required: true },
	currentPage: { type: Number, required: false },
});

const pages = computed(() => {
	return Math.floor(props.total / props.limit);
});

const totalOfTitle = computed(() => {
	const startTotal = props.limit * ((props.currentPage ?? 0) - 1) + 1;
	return `${startTotal === 0 ? 1 : startTotal}-${
		props.limit * (props.currentPage ?? 0)
	} of ${props.total}`;
});

const currentChange = (page: number) => {
	emit('currentChange', page);
};
</script>

<style lang="stylus">
.b-pagination
	display: flex
	flex-direction: row
	justify-content: flex-end
	align-items: center
	gap 1rem
	width 100%
	height: 32px;
	.el-pagination
		padding 0px
	.is-first,
	.is-last
		background: var(--neutral-100) !important;
		border: none
		border-radius: 50%;
		&:hover
			background: var(--neutral-200) !important
			transition: all .25s
			.el-icon
				svg
					stroke-width: 2px;
					path
						fill: var(--neutral-800) !important
	.el-pager
		margin-left 8px
		margin-right 8px
		background: none
		li
			font-family: var(--font-family);
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 15px;
			display: flex;
			align-items: center;
			text-align: center;
			border-radius: 50%;
			margin-right 4px
			margin-left 4px
			color: var(--neutral-800)
			background: var(--neutral-100)
			&:hover
				color: var(--neutral-800);
				background: var(--neutral-200);
		.is-active
			background: var(--primary-50)
			color var(--primary-600)
.b-pagination__deselect-first
	.el-pagination
		.is-first
			&:hover
				background: var(--neutral-50) !important
			.el-icon
				color var(--neutral-800) !important
</style>
