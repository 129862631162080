<template>
	<div class="dashboard-page">
		<aside>
			<DashboardLeftSidebarWidget />
		</aside>
		<main>
			<router-view />
		</main>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { DashboardLeftSidebarWidget } from '@/widgets/dashboard';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.DASHBOARD.VIEW_DASHBOARD_PAGE);
});
</script>

<style lang="stylus" scoped>
.dashboard-page
	width 100%
	height 100%
	padding 0 calc(10vw)
	display grid
	grid-template-columns 17.5rem minmax(40rem, 1fr)
	gap 1.5rem
	@media (max-width calc(59rem + 20vw))
		padding 0 calc(5vw)
	@media (max-width calc(59rem + 10vw))
		padding 0 calc(2vw)
</style>
