<template>
	<div :class="className" @click.stop="onClick" class="b-checkbox">
		<IconCheckbox v-if="props.modelValue" :color="color" />
		<IconCheckboxEmpty v-else :color="color" />
	</div>
</template>

<script setup lang="ts">
import { IconCheckbox, IconCheckboxEmpty, ThemeColors } from '@/shared/uikit';
import { computed, defineProps } from 'vue';

const emit = defineEmits(['update:modelValue', 'onClick']);
const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
	size: { type: String, required: false, default: 'default' },
	type: { type: String, required: false, default: 'primary' },
	isDisabled: { type: Boolean, required: false, default: false },
});

const className = computed(() => {
	let className = `b-checkbox-${props.type}`;
	className += ` b-checkbox__${props.size.toLowerCase()}`;

	if (props.isDisabled) {
		className += ' b-checkbox__disabled';
	}

	return className;
});

const color = computed(() => {
	if (props.isDisabled) {
		return ThemeColors.neutral[400];
	}

	switch (props.type) {
		case 'primary':
			return ThemeColors.primary[600];
		case 'secondary':
			return ThemeColors.secondary[600];
		case 'tertiary':
			return ThemeColors.tertiary[600];
		default:
			return ThemeColors.primary[600];
	}
});

const onClick = () => {
	emit('onClick');
	if (!props.isDisabled) {
		emit('update:modelValue', !props.modelValue);
	}
};
</script>
<style lang="stylus">
.b-checkbox
    display flex
    flex-direction: row
    align-items: center
    justify-content: center
    background: white
    &:hover
        cursor pointer

.b-checkbox__small
    width 18px
    height 18px


.b-checkbox__default
    width 1.5rem
    height 1.5rem
</style>
