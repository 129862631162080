import ChatPage from '../ChatPage.vue';
import ChatsPage from '../ChatsPage.vue';
import ChatTableResultPage from '../ChatTableResultPage.vue';

export const chatsRoutes = [
	{
		path: '/',
		component: ChatsPage,
		name: 'chats',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
	// {
	// 	path: '/chats',
	// 	component: ChatsPage,
	// 	name: 'chats',
	// 	meta: {
	// 		requiresAuth: true,
	// 		requiresVerifiedEmail: true,
	// 	},
	// },
	{
		path: '/chat/:id',
		component: ChatPage,
		name: 'chat-details',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
	{
		path: '/chat-table-result',
		component: ChatTableResultPage,
		name: 'chat-table-result',
		meta: {
			requiresAuth: false,
			requiresVerifiedEmail: false,
		},
	},
];
