<template>
	<div class="chat-message-container" :class="messageClass">
		<div class="answer-message-container answer-message-container--left">
			<div class="answer-message-container--title">{{ title }}</div>

			<div class="answer-message">
				<IconChatMessageRight
					class="answer-message--icon-right"
					size="1.5rem"
					:color="message.color"
				/>
				<IconChatMessageLeft
					class="answer-message--icon-left"
					size="1.5rem"
					:color="message.color"
				/>

				<div class="answer-message--header">
					<template v-if="isToolMessage(message.type)">
						<div class="subheadlineRegularItalic">
							{{ toolOption?.description }}
						</div>
					</template>
				</div>
				<div v-if="isToolMessage(message.type)" class="tool-message">
					<div class="tool-message-section">
						<div class="subheadlineBold">Input:</div>

						<SmilesCard
							v-if="isValidatedInput"
							:smiles="message.content"
							:id="message.id"
						/>

						<div v-else class="subheadlineRegular">
							<VMarkdownView :content="message.content" />
						</div>
					</div>
					<template v-if="message.output">
						<div class="tool-message-section">
							<div class="subheadlineBold">Output:</div>
							<SmilesCard
								v-if="isValidatedOutnput"
								:smiles="message.output.content"
								:id="message.output.id"
							/>

							<LigandBasedResultsMessage
								v-else-if="
									message.output.toolType ===
									ToolType.BIOPTIC_JOB_RESULTS
								"
								:id="message.content"
							/>
							<LigandBasedToolMessage
								v-else-if="
									message.output.toolType ===
									ToolType.BIOPTIC_LIGAND_SEARCH
								"
								:id="message.output.content"
							/>

							<div
								v-else-if="
									(message.output.toolType ===
										ToolType.BIOPTIC_EUROFINS_ASSISTANT ||
										message.output.toolType ===
											ToolType.BIOPTIC_ADME_PREDICTOR) &&
									getLinkFromText(message.output.content)
										.length > 0
								"
								class="open-new-link-container"
							>
								<bButton
									label="Open file"
									type="primary"
									@onClick="openLink(message.output.content)"
								/>
							</div>

							<div v-else class="subheadlineRegular">
								<VMarkdownView
									:content="message.output.content"
								/>
							</div>
						</div>
					</template>
				</div>
				<template v-else>
					<div
						v-if="message.type === MessageType.INPUT"
						class="subheadlineRegular"
					>
						{{ message.parsedContent.content }}
					</div>
					<div v-else class="subheadlineRegular final-answer">
						<VMarkdownView
							:content="message.parsedContent.content"
						/>
					</div>

					<div
						v-if="message.parsedContent.file_name"
						class="file-chip-container"
					>
						<bChip
							class="file-chip"
							:label="message.parsedContent.file_name"
							type="neutral"
						>
							<template v-slot:left-icon>
								<IconFile size="0.85rem" />
							</template>
						</bChip>
					</div>

					<div
						v-if="getLinkFromText(message.content).length > 0"
						class="open-new-link-container"
					>
						<bButton
							label="Download file"
							type="primary"
							@onClick="
								openLink(getLinkFromText(message.content))
							"
						>
							<template v-slot:icon-left>
								<IconDownload size="1.125rem" color="white" />
							</template>
						</bButton>
					</div>

					<div v-if="message.file" class="file-chip">
						{{ message.file.name }}
					</div>
				</template>
				<div class="captionRegular">
					{{ message.formattedDate }}
					<bUnstyledButton
						v-if="message.type !== MessageType.INPUT"
						class="answer-message--copy"
						@onClick="copyToClipboardContent"
					>
						<template v-slot:content>
							<IconCopy size="1.5rem" />
						</template>
					</bUnstyledButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ocularUserModel } from '@/entities/app';
import { SmilesCard } from '@/entities/chats';
import {
	LigandBasedResultsMessage,
	LigandBasedToolMessage,
} from '@/entities/chats/ligand-based-results';
import { AvailableToolEntity, eurofinFilePreviewModel } from '@/features/chats';
import { MessageType, ToolType, type MessageEntity } from '@/shared/api';
import { copyToClipboard, getLinkFromText } from '@/shared/lib';
import { isValidateSmiles } from '@/shared/lib/rdkit';
import {
	IconChatMessageLeft,
	IconChatMessageRight,
	IconCopy,
	IconDownload,
	IconFile,
	bButton,
	bChip,
	bUnstyledButton,
} from '@/shared/uikit';
import { computed, ref, toRaw, watch } from 'vue';
import { VMarkdownView } from 'vue3-markdown';

const { user } = ocularUserModel;

const props = defineProps({
	message: {
		type: Object as () => MessageEntity,
		required: true,
	},
});

const isValidatedInput = ref(false);
const isValidatedOutnput = ref(false);

const messageClass = computed(() => ({
	input: props.message.type === MessageType.INPUT,
	output: props.message.type === MessageType.OUTPUT,
	thought: props.message.type === MessageType.THOUGHT,
	error: props.message.type === MessageType.ERROR,
	tool_start: props.message.type === MessageType.TOOL_START,
	tool_end: props.message.type === MessageType.TOOL_END,
}));

const toolOption = computed(() => {
	return AvailableToolEntity.options.find(
		(tool) => tool.type === props.message.toolType
	);
});

const title = computed(() => {
	switch (props.message.type) {
		case MessageType.INPUT:
			return user.value?.name;
		case MessageType.OUTPUT:
			return 'BIOPTIC Copilot Conclusion';
		case MessageType.THOUGHT:
			return 'Thought';
		case MessageType.ERROR:
			return 'Error';
		case MessageType.TOOL_START:
		case MessageType.TOOL_END:
			return toolOption.value?.name;
	}
});

const copyToClipboardContent = () => {
	if (
		props.message.type === MessageType.TOOL_START ||
		props.message.type === MessageType.TOOL_END
	) {
		copyToClipboard(
			toolOption.value?.name +
				'\n' +
				toolOption.value?.description +
				'\n' +
				props.message.content +
				'\n' +
				props.message.output?.content
		);
	} else {
		copyToClipboard(props.message.content);
	}
};

const isToolMessage = (type: MessageType) => {
	return type === MessageType.TOOL_START || type === MessageType.TOOL_END;
};

watch(
	() => props.message,
	async (newValue) => {
		if (!newValue || !isToolMessage(props.message.type)) {
			return;
		}

		try {
			const isValidated = await isValidateSmiles(
				toRaw(props.message.content)
			);

			isValidatedInput.value = isValidated;
		} catch (error) {
			isValidatedInput.value = false;
		}

		if (props.message.output) {
			try {
				const isValidated = await isValidateSmiles(
					toRaw(props.message.output.content)
				);

				isValidatedOutnput.value = isValidated;
			} catch (error) {
				isValidatedOutnput.value = false;
			}
		}
	},
	{ immediate: true }
);

const openLink = (link: string) => {
	window.open(link, '_blank');
};

const showPreviewFile = (fileUrl: string) => {
	const { downdloadFile } = eurofinFilePreviewModel;
	downdloadFile(fileUrl);
};
</script>

<style lang="stylus" scoped>
.chat-message-container
    width 80%
    display flex
    flex-direction row
    align-items center

    &.input
        justify-content flex-end
        .answer-message
            background var(--neutral-100)

    &.output
        justify-content flex-start
        .answer-message
            background var(--primary-100)

    &.thought
        justify-content flex-start
        .answer-message
            background var(--neutral-100)

    &.error
        justify-content flex-start
        .answer-message
            background var(--danger-600)
            .subheadlineRegular
                color white
            .captionRegular
                color white

    &.tool_start
        justify-content flex-start
        .answer-message
            background var(--secondary-100)

    &.tool_end
        justify-content flex-start
        .answer-message
            background var(--secondary-100)

.answer-message-container
	position relative
	width 100%
	.open-new-link-container
		width 100%
		display flex
		align-items center
		justify-content flex-start
		padding-top 1rem
		gap 0.5rem
	.answer-message-container--title
		padding 0.25rem 1.25rem
		font-family: var(--font-family);
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal
		color var(--neutral-600)
	.file-chip-container
		width 100%
		display flex
		align-items center
		justify-content flex-start

		.file-chip
			font-size 0.75rem
			text-transform none
	.captionRegular
		display flex
		align-items center
		flex-direction row
		gap 0.5rem
	&--left
		align-items flex-start
		.answer-message--icon-right
			display none
		.answer-message--icon-left
			display block
	&--right
		align-items flex-end
		.answer-message--icon-right
			display block
		.answer-message--icon-left
			display none
	.b-unstyled-button
		padding 0
	.answer-message
		width 100%
		position relative
		padding 1rem
		min-width 20rem
		border-radius 1.25rem
		display flex
		flex-direction column
		justify-content flex-start
		align-items flex-end
		gap 0.5rem
		.answer-message--icon-right
			position absolute
			bottom 0
			right -0.475rem
		.answer-message--icon-left
			position absolute
			bottom 0
			left -0.475rem
		.answer-message--header
			width 100%
			display flex
			flex-direction column
			gap 0.25rem
		.subheadlineRegular
			width 100%
			text-align left

.tool-message-section-canvas
	width 100%
	display flex
	justify-content center
	align-items center
	background white
	border-radius 0.5rem
	margin 0.5rem 0
	overflow hidden
.tool-message-section
	width 100%
	.subheadlineRegular
		width 100%
.tool-message
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    gap 1rem
</style>

<style lang="stylus">
.chat-message-container
    ul
        padding 1rem
    li
        margin-left 1rem
	.markdown-body
		p
			a
				color inherit !important
				pointer-events none
				text-decoration none
		pre
			overflow hidden !important
			text-overflow ellipsis
</style>
