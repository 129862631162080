<template>
	<bModal
		v-if="isVisible"
		type="primary"
		:title="isSended ? 'Thank you for your request!' : 'Suggest a feature'"
		:message="
			isSended
				? 'We will consider your request and get back to you as soon as possible.'
				: 'Describe in a couple of sentences your request for the necessary features that may be useful to you and we will be happy to consider it'
		"
		:width="500"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bButton
				label="Cancel"
				type="tertiary"
				:isGhost="true"
				size="small"
				@onClick="onClose"
			/>
			<bButton
				v-if="!isSended"
				label="Send feature request"
				type="primary"
				:isGhost="true"
				size="small"
				:isDisabled="message.length === 0"
				:isLoading="isLoading"
				@onClick="onClick"
			/>
		</template>
		<template v-slot:icon>
			<IconLogout
				v-if="!isSended"
				size="1.5rem"
				:color="ThemeColors.primary[600]"
			/>
			<IconDoubleCheck
				v-else
				size="1.5rem"
				:color="ThemeColors.success[600]"
			/>
		</template>
		<template v-slot:body>
			<div v-if="!isSended" class="suggest-modal-body">
				<bSimpleTextArea
					v-model="message"
					label=""
					size="medium"
					placeholder="Your message here ..."
				/>
			</div>
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import {
	IconDoubleCheck,
	IconLogout,
	ThemeColors,
	bButton,
	bModal,
	bSimpleTextArea,
} from '@/shared/uikit/';
import { defineEmits } from 'vue';
import { suggestedFeatureModel } from './model';

const emit = defineEmits(['onClose']);
const { isVisible, submit, message, isLoading, isSended } =
	suggestedFeatureModel;

const onClose = () => {
	isVisible.value = false;

	Analytics.send(AnalyticsEvents.Home.CLOSE_SUGGEST_FEATURE_MODAL);
};

const onClick = () => {
	submit();
};
</script>

<style lang="stylus" scoped>
.suggest-modal-body
	padding-top 2rem
</style>
