import { router } from '@/app/providers';
import { cartModel } from '@/features/cart';
import type { HitEntity } from '@/shared/api';
import { IndexedDBHitsInstance } from '@/shared/api/client';
import { ocularRestErrorHandler } from '@/shared/lib';
import { cartListModel } from '@/widgets/cart';
import { reactive, toRaw, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
};

const state = reactive(data);

async function addHitToCollection(collectionId: string, hit: HitEntity) {
	state.isLoading = true;
	try {
		const dbHits = await IndexedDBHitsInstance.get();
		await dbHits.addOrUpdate(toRaw(hit));

		const { fetchSelectedHits } = cartModel;
		fetchSelectedHits();

		if (router.currentRoute.value.name === 'cart-details') {
			const { fetchCartDetails } = cartListModel;
			fetchCartDetails();
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

async function removeHitFromCollection(collectionId: string, hit: HitEntity) {
	state.isLoading = true;
	try {
		const dbCluster = await IndexedDBHitsInstance.get();
		await dbCluster.delete(hit.id);
		const { fetchSelectedHits } = cartModel;
		fetchSelectedHits();

		if (router.currentRoute.value.name === 'cart-details') {
			const { fetchCartDetails } = cartListModel;
			fetchCartDetails();
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const addHitToCartDropdownModel = {
	...toRefs(state),
	addHitToCollection,
	removeHitFromCollection,
};
