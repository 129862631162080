<template>
	<div class="add-molecule-to-collection-container" ref="elementRef">
		<bButton
			:label="hit.collections.length > 0 ? 'Added' : 'Add to'"
			type="primary"
			:isGhost="!(hit.collections.length > 0)"
			@onClick="toggleDropdown"
		>
			<template v-slot:icon-left>
				<IconFillBookmark
					v-if="hit.collections.length > 0"
					size="1.15rem"
					color="white"
				/>
				<IconBookmark
					v-else
					size="1.15rem"
					:color="ThemeColors.primary[600]"
				/>
			</template>
		</bButton>

		<div v-if="isOpened" class="add-molecule-to-collection-dropdown">
			<div
				class="add-molecule-to-collection-item"
				v-for="collection in items"
				:key="'collection-key-dropdown-' + collection.id"
				@click="onClickByCollection($event, collection)"
			>
				{{ collection.name }}
				<IconCheck
					v-if="
						hit.collections.find(
							(item) => item.id === collection.id
						)
					"
					size="1.125rem"
				/>
			</div>
			<bDivider v-if="items.length !== 0" />
			<CreateCollectionWithMolecule
				:hit="hit"
				@onClick="isOpened = false"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CreateCollectionWithMolecule } from '@/features/collections/';
import type { CollectionEntity, HitEntity } from '@/shared/api';
import {
	IconBookmark,
	IconCheck,
	IconFillBookmark,
	ThemeColors,
	bButton,
	bDivider,
} from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref, type PropType } from 'vue';
import { addHitToCollectionDropdownModel } from './model';

const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);
const { addHitToCollection, removeHitFromCollection } =
	addHitToCollectionDropdownModel;

const props = defineProps({
	hit: {
		type: Object as PropType<HitEntity>,
		required: true,
	},
	items: {
		type: Array as PropType<CollectionEntity[]>,
		required: true,
	},
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	// if (collectionItems.value.length === 0) {
	// 	const { isVisible, includedMoleculeId } = createCollectionModel;
	// 	includedMoleculeId.value = Number(props.hit.id);
	// 	isVisible.value = true;
	// 	return;
	// }

	isOpened.value = !isOpened.value;
};

const onClickByCollection = (e: Event, collection: CollectionEntity) => {
	const findedItem = props.hit.collections.find(
		(item) => item.id === collection.id
	);

	if (findedItem) {
		removeHitFromCollection(collection.id, props.hit);
		isOpened.value = false;
	} else {
		addHitToCollection(collection, props.hit);
		isOpened.value = false;
	}

	e.stopPropagation();
	e.stopImmediatePropagation();
};
</script>

<style lang="stylus" scoped>
.add-molecule-to-collection-container
	position: relative
	.add-molecule-to-collection-target
		height: 2rem;
		width: 2rem;
		padding: 0.25rem
		display: flex
		align-items: center
		justify-content: center
		gap 0.5rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.00625rem;
		border: 1px solid var(--neutral-200);
		border-radius 50%
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		&:hover
			border-color var(--neutral-200)
			background var(--neutral-100)
		&--active
			color var(--primary-600)
			border-color var(--primary-600)
			background var(--primary-200)
			transition all 0.2s ease-in-out
		&--disabled
			border var(--neutral-200)
			background none



	.add-molecule-to-collection-dropdown
		position: absolute
		right: 0
		margin-top: 0.5rem
		display: flex;
		width 19rem
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.add-molecule-to-collection-item
			width 100%
			display grid
			grid-template-columns 1fr auto
			flex-direction row
			align-items center
			justify-content flex-start
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer

.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
