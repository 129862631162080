import { reactive, toRefs } from 'vue';

enum NotificationDirection {
	TopLeft = 'topLeft',
	TopCenter = 'topCenter',
	TopRight = 'topRight',
	BottomLeft = 'bottomLeft',
	BottomCenter = 'bottomCenter',
	BottomRight = 'bottomRight',
	RightCenter = 'rightCenter',
	LeftCenter = 'leftCenter',
}

interface NotificationType {
	id: string;
	type: 'success' | 'error' | 'warning' | 'info';
	title: string;
	description: string;
	icon?: any;
	delay: number;
	position: NotificationDirection;
}

export class Notification {
	id: string;
	type: 'success' | 'error' | 'warning' | 'info';
	title: string;
	description: string;
	icon?: any;
	delay: number;
	position: NotificationDirection = NotificationDirection.TopRight;

	constructor(
		id: string,
		type: 'success' | 'error' | 'warning' | 'info',
		title: string,
		description: string,
		icon?: any,
		delay?: number,
		position?: NotificationDirection
	) {
		this.id = id;
		this.type = type;
		this.title = title;
		this.description = description;
		this.icon = icon;
		this.delay = delay ?? 5000;
		this.position = position ?? NotificationDirection.TopRight;
	}
}

interface IViewModel {
	notifications: Notification[];
}

const data: IViewModel = {
	notifications: [],
};

const state = reactive(data);

function addNotification(notification: Notification | NotificationType) {
	state.notifications.push(notification);
	scheduleRemoval(notification);
}

function scheduleRemoval(notification: Notification) {
	const currentTime = Date.now();
	const duration = Date.now() + notification.delay - currentTime;

	if (duration > 0) {
		setTimeout(() => {
			removeNotification(notification.id);
		}, duration);
	} else {
		removeNotification(notification.id);
	}
}

function removeNotification(id: string) {
	state.notifications = state.notifications.filter((n) => n.id !== id);
}

export const notificationModel = {
	...toRefs(state),
	addNotification,
	removeNotification,
};
