<template>
	<bTooltip
		v-if="isVisible"
		id="reset-compounds-tooltip"
		hoverTitle="Reset all compounds"
		type="primary"
	>
		<template v-slot:body>
			<bUnstyledButton @onClick="onClick">
				<template v-slot:content>
					<IconReplay size="1.25rem" />
				</template>
			</bUnstyledButton>
		</template>
	</bTooltip>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconReplay, bTooltip, bUnstyledButton } from '@/shared/uikit';
import { computed } from 'vue';
import { sendEmoleculesModel } from '../send-emolecules-modal';

const emit = defineEmits(['onClick']);
const { hits } = sendEmoleculesModel;

const isVisible = computed(() =>
	hits.value.every((hit) => hit.quantity > 0 || hit.purity > 0)
);

const onClick = () => {
	hits.value.forEach((hit) => {
		hit.quantity = 0;
		hit.purity = 0;
	});

	Analytics.send(AnalyticsEvents.CART.RELOAD_COMPOUNDS);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.b-unstyled-button
	padding 0.75rem
	border-radius 50%
	background-color var(--neutral-100)
</style>
