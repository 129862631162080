<template>
	<DashboardCollectionTemplate
		ref="scrollContainer"
		:isLoading="isLoading"
		:isEmpty="!isLoading && jobs.length === 0"
		title="Target Driven results"
		emptyTitle="No results found"
		emptyDesc="Create a new Target Driven search to get results"
	>
		<template v-slot:body>
			<TargetJobCard
				v-for="job in jobs"
				:key="'job-key-' + job.id"
				:job="job"
				@onShowPreview="onClickByJob(job)"
			/>

			<bPagination
				:limit="pageLimit.value"
				:total="total"
				:currentPage="currentPage"
				@currentChange="changePage"
			>
				<template v-slot:select-page>
					<div class="pagination-select-page">
						<bSelect
							v-model="pageLimit"
							:options="pageOptions"
							size="small"
							position="top"
						/>
					</div>
				</template>
			</bPagination>
		</template>
		<template v-slot:empty-controls>
			<bButton
				label="New Target Driven search"
				type="primary"
				@onClick="openCreateJobModal"
			>
				<template v-slot:icon-left>
					<IconPlus size="1.125rem" color="white" />
				</template>
			</bButton>
		</template>
	</DashboardCollectionTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { TargetJobCard } from '@/entities/job';
import { navigationTabsModel } from '@/features/navigation';
import {
	NavigationTabType,
	TargetJobParameters,
	type JobEntity,
} from '@/shared/api';
import { IconPlus, bButton, bPagination, bSelect } from '@/shared/uikit';
import { DashboardCollectionTemplate } from '@/widgets/dashboard';
import { onMounted, ref, watch } from 'vue';
import { createTDSSModel } from '../create-tdss-modal';
import { tdssJobsModel } from './model';

const scrollContainer = ref<any>(null);

const {
	isLoading,
	jobs,
	fetchTargetDrivenResults,
	pageLimit,
	total,
	currentPage,
	pageOptions,
} = tdssJobsModel;

const { addTab } = navigationTabsModel;

onMounted(() => {
	fetchTargetDrivenResults(1);
	Analytics.send(AnalyticsEvents.BIOPTIC_VIEW.VIEW_TARGET_DRIVEN_JOBS);
});

watch(
	() => pageLimit.value,
	() => {
		changePage(1);
	},
	{ deep: true }
);

const changePage = (page: number) => {
	scrollContainer.value.resetScroll();
	fetchTargetDrivenResults(page);
};

const onClickByJob = (job: JobEntity) => {
	if (job.parameters instanceof TargetJobParameters) {
		addTab({
			id: job.id,
			title: 'Target Driven',
			description: job.parameters.target.name,
			path: `/job/${job.id}`,
			type: NavigationTabType.TARGET,
		});
	}
};

const openCreateJobModal = () => {
	const { isVisible } = createTDSSModel;
	isVisible.value = true;
};
</script>

<style lang="stylus" scoped></style>
