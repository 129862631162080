import { CatalogService, type CatalogEntity } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	catalogs: CatalogEntity[];
	selectedCatalog: CatalogEntity | null;
}
const data: IViewModel = {
	isLoading: false,
	catalogs: [],
	selectedCatalog: null,
};

const state = reactive(data);

function clear() {
	state.catalogs = [];
	state.selectedCatalog = null;
}

async function fetchCatalogs(
	top_k: number,
	queries_count: number,
	isStructureSearch: boolean = false
) {
	state.isLoading = true;
	try {
		state.catalogs = await CatalogService.getInstance().fetchCatalogs(
			top_k,
			queries_count,
			isStructureSearch
		);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const lbssCatalogListModel = {
	...toRefs(state),
	clear,
	fetchCatalogs,
};
