import { reactive, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
}
const data: IViewModel = {
	isVisible: false,
};

const state = reactive(data);

export const accountSettingsModel = {
	...toRefs(state),
};
