<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_2941_8384)">
			<path
				d="M12.5999 18.06C12.2399 18.34 11.7299 18.34 11.3699 18.06L5.21993 13.28C4.85993 13 4.35993 13 3.99993 13.28C3.48993 13.68 3.48993 14.45 3.99993 14.85L10.7599 20.11C11.4799 20.67 12.4899 20.67 13.2199 20.11L19.9799 14.85C20.4899 14.45 20.4899 13.68 19.9799 13.28L19.9699 13.27C19.6099 12.99 19.1099 12.99 18.7499 13.27L12.5999 18.06ZM13.2299 15.04L19.9899 9.78002C20.4999 9.38002 20.4999 8.60002 19.9899 8.20002L13.2299 2.94002C12.5099 2.38002 11.4999 2.38002 10.7699 2.94002L4.00993 8.21002C3.49993 8.61002 3.49993 9.39002 4.00993 9.79002L10.7699 15.05C11.4899 15.61 12.5099 15.61 13.2299 15.04Z"
				:fill="isDisabled ? ThemeColors.neutral[400] : color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2941_8384">
				<rect
					width="24"
					height="24"
					:fill="isDisabled ? ThemeColors.neutral[400] : color"
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from '../config';

const props = defineProps({
	isDisabled: {
		type: Boolean,
		default: false,
	},
	size: sizeProp,
	color: colorProp,
});

const { size } = toRefs(props);
</script>
