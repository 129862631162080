import * as Sentry from '@sentry/vue';
import type { Vue } from '@sentry/vue/types/types';
import router from './router';

export const initSentry = (app: Vue | Vue[] | undefined) => {
	Sentry.init({
		app,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		enabled: import.meta.env.PROD,
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: [
					'localhost',
					/^https:\/\/app\.stage\.essense\.optic\.xyz\//,
				],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
};

export const sendSentryCaptureEvent = (eventName: string, data?: object) => {
	Sentry.captureEvent({
		message: eventName,
		...data,
	});
};
