<template>
	<div
		class="ligand-search-smiles-input"
		:class="{
			'ligand-search-smiles-input--error':
				smilesQuery.length > 0 && !isValidatedSmiles,
		}"
	>
		<div class="ligand-search-smiles-input--title">
			<div class="subheadlineBold">
				Paste your HIT
				<bChip
					v-if="selectedSmiles.length"
					:label="`${selectedSmiles.length}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="bodySmallRegular">
				Add a SMILES to ligand based search. Maximum is 75 SMILES.
			</div>
		</div>

		<bTextInput
			v-model="smilesQuery"
			placeholder="Put SMILES here"
			size="large"
			@onBlur="isFocused = false"
			@onFocus="isFocused = true"
		>
			<template v-slot:left-icon>
				<div class="ligand-search-smiles-input--left-icon">
					<IconSearch
						size="1.5rem"
						:color="
							smilesQuery.length > 0 && !isValidatedSmiles
								? ThemeColors.danger[600]
								: ThemeColors.neutral[600]
						"
					/>
				</div>
			</template>
			<template v-slot:right-icon>
				<div class="ligand-search-smiles-input--controls">
					<bUnstyledButton
						v-if="smilesQuery.length > 0"
						@onClick="onClearInput"
					>
						<template v-slot:content>
							<IconClose size="1.25rem" />
						</template>
					</bUnstyledButton>
					<bButton
						:label="
							selectedSmiles.length >= 75
								? ' Maximum is 75 SMILES'
								: 'Add'
						"
						type="primary"
						:isDisabled="
							!isValidatedSmiles ||
							smilesQuery.length === 0 ||
							selectedSmiles.length >= 75
						"
						@click="onNext"
					>
						<template v-slot:icon-left>
							<IconPlus
								size="1.125rem"
								:color="
									!isValidatedSmiles ||
									smilesQuery.length === 0
										? ThemeColors.neutral[400]
										: 'white'
								"
							/>
						</template>
					</bButton>
				</div>
			</template>
			<template v-slot:dropdown>
				<LigandSearchSmilesPreview
					v-show="smilesQuery.length > 0 && isFocused"
					:isValid="isValidatedSmiles"
					:smile="smilesQuery"
				/>
			</template>
		</bTextInput>

		<div
			v-if="selectedSmiles.length > 0"
			class="ligand-search-smiles-input--smiles"
		>
			<SmilesCard
				v-for="(smiles, index) in selectedSmiles"
				:key="`selected-smiles-key-${smiles}`"
				:smiles="smiles"
				:index="index"
				:predefinded-selection="true"
				@onClick="removeSmiles(index)"
			/>
		</div>

		<LBSSCartList
			:selected-smiles="selectedSmiles"
			@onSelect="onSelectFromCart"
		/>
	</div>
</template>

<script setup lang="ts">
import { SmilesCard } from '@/entities/job/';
import { LBSSCartList } from '@/features/ligand-based-search/';
import type { HitEntity } from '@/shared/api';
import {
	IconClose,
	IconPlus,
	IconSearch,
	ThemeColors,
	bButton,
	bChip,
	bTextInput,
	bUnstyledButton,
} from '@/shared/uikit';
import { defineEmits, ref, watch } from 'vue';
import LigandSearchSmilesPreview from './LigandSearchSmilesPreview.vue';
import { ligandSearchInputModel } from './model';

const emit = defineEmits(['onNext', 'onClear', 'onSelectFromCart']);

const isFocused = ref<boolean>(false);
const { smilesQuery, selectedSmiles, isValidatedSmiles, clear } =
	ligandSearchInputModel;

const props = defineProps({
	initSmiles: {
		type: Array as () => string[],
		default: () => [],
	},
});

watch(
	() => props.initSmiles,
	(newValue) => {
		if (selectedSmiles.value.length === 0) {
			selectedSmiles.value = newValue.slice(0, 75);
		}
	},
	{ immediate: true }
);

const onNext = () => {
	selectedSmiles.value.push(smilesQuery.value);
	smilesQuery.value = '';
	emit('onNext', selectedSmiles.value);
};

const onClearInput = () => {
	smilesQuery.value = '';
};

const removeSmiles = (index: number) => {
	selectedSmiles.value.splice(index, 1);
	emit('onNext', selectedSmiles.value);
};

const onSelectFromCart = (hit: HitEntity) => {
	const isSmilesAlreadySelected = selectedSmiles.value.includes(hit.smiles);

	if (isSmilesAlreadySelected) {
		selectedSmiles.value = selectedSmiles.value.filter(
			(smiles) => smiles !== hit.smiles
		);
	} else {
		selectedSmiles.value.push(hit.smiles);
	}

	emit('onNext', selectedSmiles.value);
};

const onClear = () => {
	clear();
	emit('onClear');
};
</script>

<style lang="stylus" scoped>
.ligand-search-smiles-input
	width 100%
	display flex
	flex-direction column
	gap 1rem
	&--title
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
		.subheadlineBold
			display flex
			flex-direction row
			align-items center
			gap 0.25rem
	&--smiles
		display grid
		grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
		gap 0.75rem
		justify-content center
		transition all 0.25s
		padding-bottom 1rem
	&--left-icon
		margin-left 0.5rem
		display: flex
		align-items: center
		justify-content: center
	&--controls
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		gap 0.37rem
		.b-unstyled-button
			padding 0.5rem
			border-radius 1.75rem
			&:hover
				background-color: var(--neutral-100)
				transition all 0.25s
</style>

<style lang="stylus">
.ligand-search-smiles-input
	.b-text-input-container
		.b-text-input
			padding-left 3rem !important
		input
			border-radius: 1.75rem !important
.ligand-search-smiles-input--error
	.b-text-input-container
		.b-text-input
			background-color: var(--danger-100) !important
		input
			outline-color: var(--danger-600) !important
			color var(--danger-600) !important

// .search-smiles-input
	// .b-text-input-container
	// 	.b-text-input
	// 		border-radius 1.75rem !important
	// 		border-top-left-radius: 0 !important
	// 		border-bottom-left-radius: 0 !important
	// 		padding-left 1rem !important
	// 		padding-right 10.625rem !important
	// 		&:focus
	// 			transition none

	// .b-text-input-left-icon
	// 	margin-left 0.5rem
	// .b-text-input
	// 	padding 1rem 3rem !important
	// .b-text-input-container--error
	// 	background-color: var(--danger-100) !important
	// 	input
	// 		border-color: var(--danger-600) !important
</style>
