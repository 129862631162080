import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api/auth';
import { firebaseErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { verifyUserEmailModel } from '../verify-email/';

const state = reactive({
	isLoading: false,
});

async function signUp(email: string, password: string) {
	Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_EMAIL);

	if (!email || !password) {
		return;
	}

	try {
		state.isLoading = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.signUp({
			email,
			password,
		});

		await verifyUserEmailModel.sendEmailVerification();

		router.push('/');
		Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_EMAIL_SUCCESS);
	} catch (error: any) {
		firebaseErrorHandler(error);
		Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_EMAIL_FAILURE);
	} finally {
		state.isLoading = false;
	}
}

export const buttonSignupWithEmailModel = {
	...toRefs(state),
	signUp,
};
