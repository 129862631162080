<template>
	<BottomDrawerTemplate
		title="Available Tools"
		info="Here you can find all the tools available in ChemCrow."
		:isVisible="isVisible"
		@onClose="onClose"
	>
		<template v-slot:body>
			<div class="available-tools-container">
				<AvailableToolCard
					v-for="tool in tools"
					:key="tool.id"
					:tool="tool"
				/>
			</div>
		</template>
	</BottomDrawerTemplate>
</template>

<script setup lang="ts">
import { AvailableToolCard } from '@/entities/chats';
import { BottomDrawerTemplate } from '@/features/home';
import { chatAvailableToolsModalModel } from './model';

const { isVisible, tools } = chatAvailableToolsModalModel;

const onClose = () => {
	isVisible.value = false;
};
</script>
<style lang="stylus" scoped>
.available-tools-container
	display grid
	grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
	gap 0.75rem
	justify-content center
	transition all 0.25s
	padding-bottom 1.25rem
	padding: 0 10%
</style>
