<template>
	<div class="job-results-sort-container" ref="elementRef">
		<div
			:class="{
				'job-results-sort-target': true,
				'job-results-sort-target--active': isOpened,
			}"
			@click="toggleDropdown"
		>
			<IconDownward
				v-if="selectedOption.sortDirection == SortDirection.Descending"
				size="1.125rem"
				:color="
					isOpened
						? ThemeColors.primary[600]
						: ThemeColors.neutral[900]
				"
			/>
			<IconUpward
				v-else
				size="1.125rem"
				:color="
					isOpened
						? ThemeColors.primary[600]
						: ThemeColors.neutral[900]
				"
			/>

			{{ selectedOption.label + ': ' + selectedOption.sortDirection }}
		</div>
		<div v-if="isOpened" class="job-results-sort-dropdown">
			<div
				class="job-results-sort-item"
				v-for="(option, index) in options"
				:key="'job-results-sort-item-key' + index"
				@click="selectOption(option)"
			>
				<IconDownward
					v-if="option.sortDirection == SortDirection.Descending"
					:color="ThemeColors.neutral[700]"
				/>
				<IconUpward v-else :color="ThemeColors.neutral[700]" />
				{{ option.label + ': ' + option.sortDirection }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ResultsSortOption, SortDirection } from '@/shared/api';
import { IconDownward, IconUpward, ThemeColors } from '@/shared/uikit';
import { clusterResultsListModel } from '@/widgets/cluster';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { hitResultsSortModel } from './model';

const { options, selectedOption } = hitResultsSortModel;
const { fetchClusterResults } = clusterResultsListModel;
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};

const selectOption = (option: ResultsSortOption) => {
	selectedOption.value = option;
	isOpened.value = false;
	fetchClusterResults(1);
};
</script>

<style lang="stylus" scoped>
.job-results-sort-container
	position: relative
	.job-results-sort-target
		height: 2.5rem;
		padding: 0.625rem 1rem
		display: flex
		align-items: center
		justify-content: center
		gap 0.5rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.00625rem;
		border: 1px solid var(--neutral-200);
		border-radius 62.4375rem
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		&--active
			color var(--primary-600)
			border-color var(--primary-600)
			background var(--primary-200)
			transition all 0.2s ease-in-out



	.job-results-sort-dropdown
		position: absolute
		right: 0
		margin-top: 0.5rem
		display: flex;
		width 17.4375rem
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.job-results-sort-item
			width 100%
			display grid
			grid-template-columns 3rem auto
			align-items center
			justify-content flex-start
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer

.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
