<template>
	<bButton
		label="Send"
		type="primary"
		:is-disabled="isDisabled"
		@onClick="onClick"
	>
		<template v-slot:icon-left>
			<IconSent
				size="0.75rem"
				:color="isDisabled ? ThemeColors.neutral[400] : 'white'"
			/>
		</template>
	</bButton>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconSent, ThemeColors, bButton } from '@/shared/uikit/';
import { computed } from 'vue';
import { sendEmoleculesModel } from './model';

const { isVisible, hits } = sendEmoleculesModel;

const isDisabled = computed(() => {
	const selectedHits = hits.value.filter((hit) => hit.selected);

	for (const hit of selectedHits) {
		if (hit.quantity === 0 || hit.purity === 0) {
			return true;
		}
	}

	return selectedHits.length === 0;
});

const onClick = () => {
	isVisible.value = true;
	Analytics.send(AnalyticsEvents.CART.OPEN_EMOLECULES_MODAL);
};
</script>
