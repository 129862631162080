<template>
	<bUnstyledButton @onClick="onClick">
		<template v-slot:content>
			<IconPlus size="1rem" color="white" />
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { NavigationTabType } from '@/shared/api';
import { generateUuid } from '@/shared/lib';
import { bUnstyledButton } from '@/shared/uikit';
import IconPlus from '@/shared/uikit/components/icons/IconPlus.vue';
import { navigationTabsModel } from '.';

const props = defineProps({
	index: {
		type: Number,
		required: true,
	},
});

const onClick = () => {
	const { addTab } = navigationTabsModel;
	const tabId = generateUuid();

	addTab({
		id: `new-tab-${tabId}`,
		title: 'New Tab',
		description: '',
		path: `/new-tab/${tabId}`,
		type: NavigationTabType.NewTab,
	});

	Analytics.send(AnalyticsEvents.App.CLICK_NEW_TAB);
};
</script>

<style lang="stylus" scoped>
.b-unstyled-button
	cursor pointer
	display flex
	align-items center
	justify-content center
	width 2.25rem
	height 2.25rem
	background-color var(--neutral-900)
	color var(--neutral-100)
	&:hover
		background-color var(--neutral-800)
</style>
