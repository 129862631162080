<template>
	<div
		class="chat-page"
		:class="{
			'chat-page--opened': isOpened,
		}"
	>
		<bTooltip
			v-if="!isOpened"
			class="chat-page--open-side-panel"
			:id="`open-side-panel-tooltip`"
			hoverTitle="Open side panel"
			type="primary"
		>
			<template v-slot:body>
				<bUnstyledButton @click="isOpened = !isOpened">
					<template v-slot:content>
						<IconSidePanel size="1.25rem" />
					</template>
				</bUnstyledButton>
			</template>
		</bTooltip>
		<ChatsAsideWidget v-model:isOpened="isOpened" />

		<ChatsMainWidget />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import { IconSidePanel, bTooltip, bUnstyledButton } from '@/shared/uikit';
import {
	ChatsAsideWidget,
	ChatsMainWidget,
	chatDetailsModel,
	chatWithMainModel,
} from '@/widgets/chats';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const isOpened = ref<boolean>(false);

const { title, description, clear: clearNavigation } = navigationHeaderModel;
const { chat } = chatDetailsModel;
const { sendMessage } = chatWithMainModel;

onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	chat.value = null;
	if (router.currentRoute.value.name !== 'chats') {
		return;
	}

	title.value = 'BIOPTIC Copilot';
	description.value = 'Your personal Chem-Assist';

	const question = router.currentRoute.value.query.question as string;

	if (!question) {
		return;
	}

	sendMessage(`Give me results from job ${question}`, null);
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.chat-page
	position relative
	width 100%
	height calc(100% - 6.25rem)
	display grid
	grid-template-columns 0 1fr
	transition all 0.3s ease
	&--opened
		grid-template-columns 16.25rem 1fr
		transition all 0.3s ease


.chat-page--open-side-panel
	position absolute
	top 1rem
	left 1rem
	z-index 10

.chats-widget
	width 100%
	height 100%
	position relative
	&--header
		position absolute
		width 100%
		padding 1rem
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		z-index 10
		&--shadow
			border-bottom-left-radius 1rem
			border-bottom-right-radius 1rem
			box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05)

	&--body
		overflow-y auto
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items flex-start
		justify-content flex-start
		gap 0.25rem
		padding 4rem 0.5rem
		&::-webkit-scrollbar
			display none
		.main-chat-item
			width 100%
			display grid
			grid-template-columns auto 1fr
			align-items center
			justify-content flex-start
			gap 0.5rem
			padding: 0.5rem
			border-radius 0.25rem

			&:hover
				background: var(--neutral-100)
				cursor pointer
			.footnoteSemiBold
				color var(--neutral-800)
				letter-spacing: 0.00625rem
</style>
