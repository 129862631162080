<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M18 10.82C17.7348 10.82 17.4804 10.9254 17.2929 11.1129C17.1054 11.3004 17 11.5548 17 11.82V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H12.18C12.4452 7 12.6996 6.89464 12.8871 6.70711C13.0746 6.51957 13.18 6.26522 13.18 6C13.18 5.73478 13.0746 5.48043 12.8871 5.29289C12.6996 5.10536 12.4452 5 12.18 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V11.82C19 11.5548 18.8946 11.3004 18.7071 11.1129C18.5196 10.9254 18.2652 10.82 18 10.82ZM21.92 2.62C21.8185 2.37565 21.6243 2.18147 21.38 2.08C21.2598 2.02876 21.1307 2.00158 21 2H15C14.7348 2 14.4804 2.10536 14.2929 2.29289C14.1054 2.48043 14 2.73478 14 3C14 3.26522 14.1054 3.51957 14.2929 3.70711C14.4804 3.89464 14.7348 4 15 4H18.59L12.29 10.29C12.1963 10.383 12.1219 10.4936 12.0711 10.6154C12.0203 10.7373 11.9942 10.868 11.9942 11C11.9942 11.132 12.0203 11.2627 12.0711 11.3846C12.1219 11.5064 12.1963 11.617 12.29 11.71C12.383 11.8037 12.4936 11.8781 12.6154 11.9289C12.7373 11.9797 12.868 12.0058 13 12.0058C13.132 12.0058 13.2627 11.9797 13.3846 11.9289C13.5064 11.8781 13.617 11.8037 13.71 11.71L20 5.41V9C20 9.26522 20.1054 9.51957 20.2929 9.70711C20.4804 9.89464 20.7348 10 21 10C21.2652 10 21.5196 9.89464 21.7071 9.70711C21.8946 9.51957 22 9.26522 22 9V3C21.9984 2.86932 21.9712 2.74022 21.92 2.62Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
