import { RestClient } from '../client';
import { Endpoints } from '../shared';
import { ChatEntity } from './entities';

export class ChatService {
	private static instance: ChatService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): ChatService {
		if (!ChatService.instance) {
			ChatService.instance = new ChatService();
		}
		return ChatService.instance;
	}

	public async fetchAll(
		limit: number,
		offset: number
	): Promise<{
		count: number;
		chats: ChatEntity[];
	}> {
		const response = await this.client.get<any>(Endpoints.Chats.FETCH_ALL, {
			limit: limit,
			offset: offset,
		});

		const chats = response.data.chats.map((job: any) =>
			ChatEntity.fromObject(job)
		);

		return {
			count: response.data.count,
			chats: chats,
		};
	}

	public async fetchChat(id: string): Promise<ChatEntity> {
		const response = await this.client.get<any>(Endpoints.Chats.FETCH(id));
		return ChatEntity.fromObject(response.data);
	}

	public async deleteChat(id: string): Promise<void> {
		await this.client.delete(Endpoints.Chats.DELETE(id));
	}
}
