// rdkit.ts

import type { RDKitModule } from '@rdkit/rdkit';

// Проверка и ожидание инициализации RDKit
export async function ensureRDKitInitialized(): Promise<RDKitModule> {
	if (window.RDKit) {
		return window.RDKit;
	}

	return new Promise((resolve, reject) => {
		const checkRDKit = () => {
			if (window.RDKit) {
				resolve(window.RDKit);
			} else {
				setTimeout(checkRDKit, 100);
			}
		};
		checkRDKit();
	});
}

export async function drawSmiles(
	smiles: string,
	canvasId: string,
	width: number,
	height: number,
	retray: number = 0
) {
	const RDKit = await ensureRDKitInitialized();

	const mol = RDKit.get_mol(smiles);
	if (!mol) {
		console.error('Failed to get molecule from RDKit.');
		return;
	}

	const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
	if (!canvas) {
		console.error('Canvas element not found.');
		return;
	}

	const dpr = window.devicePixelRatio || 1;

	canvas.width = width * dpr;
	canvas.height = height * dpr;
	canvas.style.width = width + 'px';
	canvas.style.height = height + 'px';

	const context = canvas.getContext('2d');
	if (!context) {
		console.error('Failed to get canvas context.');
		return;
	}

	mol.draw_to_canvas_with_highlights(
		canvas,
		JSON.stringify({
			scaleBondWidth: true,
			addStereoAnnotation: true,
		})
	);

	mol.delete();
}

export async function drawSmilesWithParams(
	smiles: string,
	canvasId: string,
	width: number,
	height: number,
	params: any
) {
	const RDKit = await ensureRDKitInitialized();

	const mol = RDKit.get_mol(smiles);
	if (!mol) {
		console.error('Failed to get molecule from RDKit.');
		return;
	}

	const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
	if (!canvas) {
		console.error('Canvas element not found.');
		return;
	}

	const dpr = window.devicePixelRatio || 1;

	canvas.width = width * dpr;
	canvas.height = height * dpr;
	canvas.style.width = width + 'px';
	canvas.style.height = height + 'px';

	const context = canvas.getContext('2d');
	if (!context) {
		console.error('Failed to get canvas context.');
		return;
	}

	mol.draw_to_canvas_with_highlights(
		canvas,
		JSON.stringify({
			...params,
			addStereoAnnotation: true,
		})
	);

	mol.delete();
}

export async function isValidateSmiles(smiles: string): Promise<boolean> {
	const RDKit = await ensureRDKitInitialized();

	const mol = RDKit.get_mol(smiles);

	return mol !== null;
}

// export async function isValidateSmiles(smiles: string): Promise<boolean> {
// 	try {
// 		console.log('smiles', smiles);
// 		const molecule = Molecule.fromSmiles(smiles);
// 		console.log(molecule);
// 		molecule.validate();
// 		return true;
// 	} catch (error) {
// 		return false;
// 	}
// }

export async function smilesToSDFData(smiles: string): Promise<string> {
	const RDKit: any = await ensureRDKitInitialized();

	if (!RDKit) {
		console.error('RDKit module not loaded');
		return '';
	}

	const mol = RDKit.get_mol(smiles);

	// mol.add_hs();
	// mol.ETKDG();
	// mol.optimize_uff();

	const result = mol.get_v3Kmolblock() + '\n$$$$\n';

	mol.delete();

	return result;
}
