<template>
	<div v-if="isGrid" ref="card" class="collection-item-card-container-grid">
		<div class="collection-card">
			<div class="collection-card--preview">
				<canvas :id="`smiles-item-${index}-${collection.smiles}`">
				</canvas>
			</div>
			<div
				class="collection-card-preview-button"
				@click.stop="onOpenPreview"
			>
				<bUnstyledButton @onClick="onOpenPreview">
					<template v-slot:content>
						<IconZoomIn size="1.25rem" />
					</template>
				</bUnstyledButton>
			</div>
			<div class="collection-card--similarity-chip">
				<TargetLinkButton
					v-if="collection.external_url"
					class="external-link-button"
					:data="collection.external_url"
					:id="'collection-' + collection.smiles"
					size="1rem"
				/>
			</div>

			<div class="collection-card--size">
				<DeleteCollectionItemButton :collectionItem="collection" />
			</div>

			<div class="collection-card--tooltip-info">
				<bTooltip
					:id="`tooltip-${index}-${collection.smiles}`"
					:hoverTitle="collection.tooltipString"
					type="primary"
				>
					<template v-slot:body>
						<IconInfo size="1rem" />
					</template>
				</bTooltip>
			</div>
		</div>
	</div>
	<div v-else ref="card" class="collection-item-card-container-list">
		<div class="collection-item-card">
			<div class="collection-item-card--preview" @click="onOpenPreview">
				<canvas :id="`smiles-item-${index}-${collection.smiles}`">
				</canvas>
				<div class="collection-item-card--preview-hover">
					<IconSearch size="1rem" color="white" />
					<div class="bodySmallRegular">Tap to zoom</div>
				</div>
			</div>
			<div class="collection-item-card--content">
				<div class="collection-item-card--content-info">
					<span
						><div class="subheadlineBold">
							{{ collection.smiles }}
						</div>
						<div
							v-if="collection.external_url"
							class="bodySmallRegular"
						>
							{{ collection.external_url.title }}
						</div>
					</span>

					<div class="collection-item-card--content-info-right">
						<bChip
							:label="`${collection.forrmatedCreatedAt}`"
							:isGhost="false"
							type="primary"
							size="small"
						/>

						<DeleteCollectionItemButton
							:collectionItem="collection"
						/>
					</div>
				</div>
				<div class="collection-item-card--content-details">
					<div class="collection-item-card--content-details">
						<span
							v-for="(value, type) in collection.attributes"
							:key="'collection-item-card-key-' + type"
						>
							<div class="captionBold">
								{{ getSearchAttributeTypeLabel(type) }}
							</div>
							<div class="footnoteBold">
								{{
									value.toFixed(
										getSearchAttributeTypeToFix(type)
									)
								}}
							</div>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { DeleteCollectionItemButton } from '@/features/collections';
import { previewSmilesModel } from '@/features/job';
import { TargetLinkButton } from '@/features/target-driven-search';
import {
	CollectionItemEntity,
	HitEntity,
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
} from '@/shared/api';
import { drawSmiles } from '@/shared/lib/rdkit';
import {
	IconInfo,
	IconSearch,
	IconZoomIn,
	bChip,
	bTooltip,
	bUnstyledButton,
} from '@/shared/uikit';
import {
	onMounted,
	onUnmounted,
	onUpdated,
	ref,
	toRaw,
	type PropType,
} from 'vue';

const card = ref<HTMLElement | null>(null);
const observer = ref<IntersectionObserver | null>(null);

const props = defineProps({
	collection: {
		type: Object as PropType<CollectionItemEntity>,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	isGrid: {
		type: Boolean,
		required: false,
		default: false,
	},
});

onUpdated(() => {
	drawCanvas();
});

onMounted(() => {
	observer.value = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				drawCanvas();

				observer.value?.unobserve(entry.target);
			}
		});
	});

	if (card.value) observer.value?.observe(card.value);
});

onUnmounted(() => {
	observer.value?.disconnect();
});

const drawCanvas = () => {
	drawSmiles(
		props.collection.smiles,
		`smiles-item-${props.index}-${props.collection.smiles}`,
		props.isGrid ? 200 : 150,
		props.isGrid ? 150 : 120
	);
};

const onOpenPreview = () => {
	previewSmilesModel.previewMolecule.value = HitEntity.fromObject(
		toRaw(props.collection)
	);
};
</script>

<style lang="stylus" scoped>
.collection-item-card-container-list
	width 100%
	.collection-item-card
		background-color var(--neutral-50)
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		display grid
		grid-template-columns 10rem 1fr
		padding 0.25rem
		transition all 0.25s ease
		// &:hover
		// 	background-color var(--neutral-100)
		// 	transition all 0.25s ease
		&--preview
			position relative
			width 100%
			height 100%
			border-radius 0.75rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			&:hover
				cursor pointer
				.collection-item-card--preview-hover
					opacity 1 !important
					transition all 0.25s ease
			.collection-item-card--preview-hover
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				background: rgba(30, 41, 59, 0.5)
				display flex
				flex-direction row
				align-items center
				justify-content center
				gap 0.25rem
				border-radius 0.75rem
				z-index 10
				transition all 0.25s ease
				opacity 0
				.bodySmallRegular
					color white

			.collection-item-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem

		&--content
			padding 1rem
			display flex
			flex-direction column
			justify-content space-between
			align-items flex-start
			gap 0.75rem
			.collection-item-card--content-info
				display flex
				flex-direction row
				justify-content space-between
				align-items center
				width 100%
				.collection-item-card--content-info-right
					display flex
					flex-direction row
					justify-content flex-end
					align-items center
					gap 0.25rem
				span
					display flex
					flex-direction column
					justify-content center
					align-items flex-start
					.subheadlineBold
						display flex
						flex-direction row
						justify-content space-between
						align-items center
						gap 0.25rem
					.bodySmallRegular
						color var(--neutral-600)

			.collection-item-card--content-details
				display flex
				flex-flow row wrap
				justify-content flex-start
				align-items center
				gap 0.75rem
				width 100%
				span
					height 100%
					display flex
					flex-direction column
					justify-content flex-start
					align-items flex-start
					gap 0.25rem
					min-width 2rem
					.footnoteBold
						font-size 0.75rem
				.captionBold
					color var(--neutral-600)
.collection-item-card-container-grid
	width 100%
	height 11.25rem
	.collection-card
		position relative
		height 100%
		border-radius: 1rem
		box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
		transition all 0.25s ease
		border 1px solid transparent
		.collection-card--similarity-chip
			position absolute
			bottom 0.75rem
			left 0.75rem
			z-index 2
		.collection-card--size
			position absolute
			top 0.75rem
			left 0.75rem
			z-index 2
		.collection-card-preview-button
			position absolute
			bottom 0.75rem
			right 0.75rem
			z-index 2
		.collection-card--tooltip-info
			position absolute
			top 0.75rem
			right 0.75rem
			z-index 2
		&--preview
			position relative
			width 100%
			height 100%
			border-radius: 1rem
			display grid
			grid-template-columns repeat(2, minmax(0, 1fr))
			grid-template-rows repeat(2, minmax(0, 1fr))
			gap 0.12rem
			background-color white
			display flex
			align-items center
			justify-content center
			overflow hidden
			user-select none
			z-index 1

			.collection-card--preview-canvas
				display flex
				align-items center
				justify-content center
				background white
				border-radius 0.75rem
</style>
