import {
	ProbesEntity,
	TargetsService,
	type PaginationPageType,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { probesListPreselectModel } from '..';

interface IViewModel {
	isLoading: boolean;
	probes: ProbesEntity[];
	total: number;
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
	isClustered: boolean;
}
const data: IViewModel = {
	isLoading: false,
	probes: [],
	total: 0,
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
	isClustered: true,
};

const state = reactive(data);

function clear() {
	state.probes = [];
	state.total = 0;
	state.currentPage = 1;
	state.pageLimit = {
		title: '25',
		value: 25,
	};
}

async function fetchProbes(selectedTargetId: string, page: number) {
	state.isLoading = true;
	state.currentPage = page;
	try {
		const { selectedOption } = probesListPreselectModel;

		if (selectedOption.value.id === 0) {
			const result = await TargetsService.getInstance().fetchTargetProbes(
				selectedTargetId,
				state.pageLimit.value,
				(page - 1) * state.pageLimit.value,
				state.isClustered
			);
			state.total = result.count;
			state.probes = result.probes;
		} else {
			const result = await TargetsService.getInstance().fetchTargetProbes(
				selectedTargetId,
				selectedOption.value.value,
				0,
				state.isClustered
			);
			state.total = result.count;
			state.probes = result.probes;
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const tdssProbesListModel = {
	...toRefs(state),
	clear,
	fetchProbes,
};
