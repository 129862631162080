<template>
	<div
		ref="elementRef"
		class="contextmenu-container"
		@contextmenu.prevent="openMenu"
	>
		<slot name="target" />
	</div>

	<Teleport to="body">
		<div
			v-if="isOpened"
			class="contextmenu-dropdown"
			:style="dropdownStyle"
			ref="dropdownRef"
		>
			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.CopyLink)"
			>
				Copy link
				<IconCopy size="1rem" />
			</div>
			<bDivider />
			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.Reload)"
			>
				Reload
				<IconReload size="1rem" />
			</div>
			<div
				class="contextmenu-dropdown--item"
				:class="{
					'contextmenu-dropdown--item--disabled': tab.isPinned,
				}"
				@click="onClickOption(ContextMenuAction.Pin)"
			>
				Pin
				<IconPin size="1rem" />
			</div>
			<div
				class="contextmenu-dropdown--item"
				:class="{
					'contextmenu-dropdown--item--disabled': !tab.isPinned,
				}"
				@click="onClickOption(ContextMenuAction.Unpin)"
			>
				Unpin
			</div>
			<bDivider />

			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.Close)"
			>
				Close
				<IconClose size="1rem" />
			</div>
			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.CloseOtherTabs)"
			>
				Close Other Tabs
			</div>
			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.CloseTabsToRight)"
			>
				Close Tabs to the Right
			</div>
			<div
				class="contextmenu-dropdown--item"
				@click="onClickOption(ContextMenuAction.CloseAllTabs)"
			>
				Close All Tabs
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
import type { NavigationTabEntity } from '@/shared/api';
import { IconClose, IconPin, IconReload, bDivider } from '@/shared/uikit';
import IconCopy from '@/shared/uikit/components/icons/IconCopy.vue';
import { onBeforeUnmount, onMounted, ref, type PropType } from 'vue';
import {
	ContextMenuAction,
	navigationTabContextMenuModel,
} from './contextmenu';

const { handleContextMenuAction } = navigationTabContextMenuModel;

const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);
const dropdownStyle = ref({
	top: '0px',
	left: '0px',
});

const props = defineProps({
	tab: {
		type: Object as PropType<NavigationTabEntity>,
		required: true,
	},
});

const close = (e: MouseEvent) => {
	if (dropdownRef.value && !dropdownRef.value.contains(e.target as Node)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const openMenu = (e: MouseEvent) => {
	e.preventDefault();
	isOpened.value = true;

	const { clientX: mouseX, clientY: mouseY } = e;
	const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

	const dropdownWidth = dropdownRef.value?.offsetWidth || 0;
	const dropdownHeight = dropdownRef.value?.offsetHeight || 0;

	let top = mouseY;
	let left = mouseX;

	if (mouseY + dropdownHeight > windowHeight) {
		top = windowHeight - dropdownHeight;
	}
	if (mouseX + dropdownWidth > windowWidth) {
		left = windowWidth - dropdownWidth;
	}

	dropdownStyle.value = {
		top: `${top}px`,
		left: `${left}px`,
	};
};

const onClickOption = (action: ContextMenuAction) => {
	handleContextMenuAction(action, props.tab);
	isOpened.value = false;
};
</script>

<style lang="stylus" scoped>
.contextmenu-container
	position: relative

.contextmenu-dropdown
	position: absolute
	z-index: 9999
	margin-top: 0.5rem
	display: flex;
	padding: 0.625rem 0
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	border: 1px solid rgba(0, 0, 0, 0.10);
	border-radius: 0.25rem;
	background: var(--neutral-50);
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
	&--item
		width 100%
		display grid
		grid-template-columns 1fr auto
		align-items center
		justify-content flex-start
		padding: 0.625rem 1rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem; /* 142.857% */
		letter-spacing: 0.00625rem;
		color var(--neutral-700)
		white-space nowrap
		&:hover
			background: var(--neutral-100)
			cursor pointer
		&--disabled
			opacity 0.5
			pointer-events none
</style>
