<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M4.39944 14.94C5.95943 15.24 7.33944 14.34 7.81944 13H18.0094V14.79C18.0094 15.24 18.5494 15.46 18.8594 15.15L21.6494 12.36C21.8494 12.16 21.8494 11.85 21.6494 11.65L18.8594 8.86003C18.5494 8.54003 18.0094 8.76003 18.0094 9.21003V11H7.82944C7.40944 9.84003 6.30943 9.00003 5.00944 9.00003C3.16944 9.00003 1.70944 10.66 2.04944 12.56C2.26944 13.74 3.21944 14.71 4.39944 14.94Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#115E59'"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.8803 13.5001H10.8291C10.3905 15.5097 9.09562 17.188 7.33943 18.14C6.78943 17.45 5.95943 17 5.00943 17C3.16943 17 1.71943 18.65 2.05943 20.55C2.26943 21.75 3.25943 22.74 4.45943 22.95C6.34943 23.28 7.97943 21.86 8.00943 20.05C10.5441 18.7921 12.3989 16.3805 12.8803 13.5001ZM10.839 10.5001H12.8703C12.389 7.61969 10.5341 5.20789 7.99943 3.95C7.96943 2.31 6.64943 1 5.00943 1C3.17943 1 1.72943 2.65 2.05943 4.54C2.26943 5.77 3.29943 6.77 4.52943 6.96C5.66943 7.14 6.69943 6.66 7.33943 5.86C9.09565 6.8213 10.3991 8.49173 10.839 10.5001Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#1E293B'"
		/>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from '../config';

const props = defineProps({
	isDisabled: {
		type: Boolean,
		default: false,
	},
	size: sizeProp,
	color: colorProp,
});

const { size } = toRefs(props);
</script>
