<template>
	<DraggableFormTemplate
		:id="props.id"
		:input="props.input"
		:output="props.output"
		:data="props.data"
		:isLoading="props.isLoading"
		:type="props.type"
		:comment="props.comment"
	>
		<template v-slot:input>
			<PipelineTopKSlider v-model="data.top_k" :max="10000" />
			<PipelineProteinInput
				:protein="data.protein_uni_prot_kb_ac"
				@onSelectProtein="onSelectProtein"
			/>
			<TopActiveStrategyDropdown
				:strategy="data?.strategy"
				@updateStrategy="updateStrategy"
			/>
		</template>
		<template v-slot:output> </template>
	</DraggableFormTemplate>
</template>

<script lang="ts" setup>
import {
	PipelineProteinInput,
	PipelineTopKSlider,
	TopActiveStrategyDropdown,
} from '@/features/pipeline';
import {
	StepType,
	TargetBasedSearchStrategyType,
	TargetEntity,
} from '@/shared/api';
import { DraggableFormTemplate } from '@/widgets/pipeline';
import { ref, type PropType } from 'vue';

const elementRef = ref<HTMLElement | null>(null);
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	input: {
		type: Array as PropType<String[]>,
		required: true,
	},
	output: {
		type: Array as PropType<String[]>,
		required: true,
	},
	data: {
		type: Object as PropType<any>,
		required: true,
	},
	isLoading: {
		type: Boolean,
		required: false,
	},
	type: {
		type: String as PropType<StepType>,
		required: true,
	},
	comment: {
		type: String,
		required: false,
	},
});

const updateStrategy = (strategy: TargetBasedSearchStrategyType) => {
	props.data.strategy = strategy;
};

const onSelectProtein = (target: TargetEntity) => {
	props.data.protein_uni_prot_kb_ac = target.uni_prot_kb_ac;
};
</script>

<style lang="stylus" scoped></style>
