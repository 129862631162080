<template>
	<DashboardCollectionTemplate
		ref="scrollContainer"
		:isLoading="isLoading"
		:isEmpty="!isLoading && chats.length === 0"
		title="Chats"
		emptyTitle="You haven't created any chats yet"
		emptyDesc="Create a new chat to try your personal Chem-Assist"
	>
		<template v-slot:body>
			<div
				v-if="!isLoading && chats.length > 0"
				class="copilot-chats-widget"
				:class="{
					'copilot-chats-widget--empty':
						!isLoading && chats.length === 0,
				}"
			>
				<ChatCard
					v-for="chat in chats"
					:key="'chat-key-' + chat.id"
					:chat="chat"
					@onClick=""
				/>
			</div>
			<bPagination
				v-if="!isLoading && chats.length > 0"
				:limit="pageLimit.value"
				:total="total"
				:currentPage="currentPage"
				@currentChange="fetchChats"
			>
				<template v-slot:select-page>
					<div class="pagination-select-page">
						<bSelect
							v-model="pageLimit"
							:options="pageOptions"
							size="small"
							position="top"
						/>
					</div>
				</template>
			</bPagination>
		</template>
		<template v-slot:empty-controls>
			<bButton label="Try a new chat" type="primary" @onClick="openChat">
				<template v-slot:icon-left>
					<IconPlus size="1.125rem" color="white" />
				</template>
			</bButton>
		</template>
	</DashboardCollectionTemplate>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { ChatCard } from '@/entities/chats';
import { type CollectionEntity } from '@/shared/api';
import { IconPlus, bButton, bPagination, bSelect } from '@/shared/uikit';
import { DashboardCollectionTemplate } from '@/widgets/dashboard';
import { onMounted, ref, watch } from 'vue';
import { copilotChatsModel } from './model';

const scrollContainer = ref<any>(null);

const {
	chats,
	fetchChats,
	isLoading,
	pageLimit,
	total,
	currentPage,
	pageOptions,
} = copilotChatsModel;

onMounted(() => {
	fetchChats(1);
});

watch(
	() => pageLimit.value,
	() => {
		changePage(1);
	},
	{ deep: true }
);

const changePage = (page: number) => {
	scrollContainer.value.resetScroll();
	fetchChats(page);
};

const openOnNewTab = (collection: CollectionEntity) => {
	// const { addTab } = navigationTabsModel;
	// addTab({
	// 	id: `collection-${collection.id}`,
	// 	title: 'Collection',
	// 	description: collection.name,
	// 	path: `/collection/${collection.id}`,
	// 	type: NavigationTabType.Collection,
	// });
};

const openChat = () => {
	router.push('/chats/');
};
</script>

<style lang="stylus" scoped>
.copilot-chats-widget
	width 100%
	display grid
	grid-template-columns repeat(auto-fill, minmax(20rem, 1fr))
	gap 0.75rem
	justify-content center
	&--empty
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
