<template>
	<div
		class="drag-wrapper"
		:draggable="true"
		@dragstart="onDragStart"
		@dragleave="onDragLeave"
		@dragover="onDragOver"
		@drop="onDrop"
		@dragend="onDragEnd"
		@drag="onDrag"
	>
		<slot name="content" />
	</div>
</template>

<script setup lang="ts">
const emit = defineEmits([
	'onDrop',
	'onStart',
	'onOver',
	'update:isDragging',
	'update:isOver',
]);
const props = defineProps({
	data: {
		type: Object,
		required: true,
	},
	isDragging: {
		type: Boolean,
		required: true,
	},
	isOver: {
		type: Boolean,
		required: true,
	},
});

const onDragStart = (event: DragEvent) => {
	const dataTransfer = event.dataTransfer;
	if (dataTransfer) {
		dataTransfer.setData('text/plain', JSON.stringify(props.data));
		dataTransfer.effectAllowed = 'move';
		emit('update:isDragging', true);
		emit('onStart', props.data);
	}
};

const onDrag = (event: DragEvent) => {};

const onDragLeave = (event: DragEvent) => {
	emit('update:isOver', false);
};

const onDragOver = (event: DragEvent) => {
	event.preventDefault();
	const dataTransfer = event.dataTransfer;
	if (dataTransfer) {
		dataTransfer.dropEffect = 'move';
	}

	emit('update:isOver', true);
	emit('onOver', props.data);
};

const onDrop = (event: DragEvent) => {
	event.preventDefault();
	const dataTransfer = event.dataTransfer;
	if (dataTransfer) {
		const droppedTab = JSON.parse(
			dataTransfer.getData('text/plain') || '{}'
		);
		emit('onDrop', droppedTab, props.data);
	}

	emit('update:isDragging', false);
	emit('update:isOver', false);
};

const onDragEnd = (event: DragEvent) => {
	event.preventDefault();
	emit('update:isDragging', false);
	emit('update:isOver', false);
	const droppedTab = JSON.parse(
		event.dataTransfer?.getData('text/plain') || '{}'
	);

	if (Object.keys(droppedTab).length === 0) {
		return;
	}

	emit('onDrop', droppedTab, props.data);
};
</script>

<style lang="stylus" scoped>
.drag-wrapper
	position relative
	&:not(:last-child)::after
		content ''
		position absolute
		right -0.25rem
		top 50%
		transform translateY(-50%)
		width 1px
		height 1.625rem
		background-color var(--neutral-600) !important
</style>
