<template>
	<div class="b-new-chip" :class="className">
		<slot name="left-icon"></slot>
		{{ label }}
		<slot name="right-icon"></slot>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
	type: { type: String, default: 'primary' },
	label: { type: String, default: '' },
});

const className = computed(() => {
	let baseClassName = `b-new-chip__${props.type.toLowerCase()}`;

	return baseClassName;
});
</script>

<style lang="stylus" scoped>
.b-new-chip
	user-select: none
	display: flex
	flex-direction: row
	align-items: center
	justify-content: center
	box-sizing: border-box
	gap: 0.38rem
	padding 0.38rem 0.62rem
	transition: all .25s
	color: var(--neutral-800);
	font-family: var(--font-family);
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.25rem;
	white-space: nowrap
	text-transform: uppercase
	border-radius 0.5rem
	border 1px solid transparent
	text-transform: none

.b-new-chip__primary
	background: var(--primary-100)
	border: none
	color: var(--primary-600)
	font-weight: 600
	border 1px solid var(--primary-100)

.b-new-chip__danger
	background: var(--danger-100)
	border: none
	color: var(--danger-600)
	font-weight: 600
	border 1px solid var(--danger-100)
.b-new-chip__tertiary
	background: white
	color: var(--neutral-400)
	font-weight: 600
	border 1px solid var(--neutral-400)
.b-new-chip__neutral
	background: var(--neutral-200)
	color: var(--neutral-800)
	border: 1px solid var(--neutral-200);
</style>
