import moment from 'moment';
import {
	ExternalHitMetadata,
	SearchAttributeType,
	SearchesAttributeEntity,
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
} from '..';

export class CollectionEntity {
	constructor(
		public id: string,
		public name: string,
		public size: number,
		public created_at: string,
		public collectibles: CollectionItemEntity[],
		public user_id: string = '',
		public attributes: SearchesAttributeEntity[] = []
	) {}

	static fromObject(obj: any): CollectionEntity {
		return new CollectionEntity(
			obj.id,
			obj.name,
			obj.size,
			obj.created_at,
			obj?.collectibles?.map((collectible: any) =>
				CollectionItemEntity.fromObject(collectible)
			) ?? [],
			obj?.user_id ?? '',
			obj?.attributes?.map((attr: any) =>
				SearchesAttributeEntity.fromObject(attr)
			) ?? []
		);
	}

	get formattedSize(): string {
		if (this.size === 1) {
			return '1 molecule';
		} else {
			return `${this.size} molecules`;
		}
	}

	updateSize(newValue: number): void {
		this.size = newValue;
	}
}

export class CollectionItemEntity {
	constructor(
		public catalog_id: string,
		public index: number,
		public smiles: string,
		public collections: CollectionEntity[],
		public attributes: Record<SearchAttributeType, number>,
		public external_url: ExternalHitMetadata | null,
		public added_at: string,
		public id: string = `${catalog_id}-${index}-${smiles}`
	) {}

	static fromObject(data: any): CollectionItemEntity {
		const attributes: Record<SearchAttributeType, number> = {} as Record<
			SearchAttributeType,
			number
		>;

		const isEnum = (value: any): value is SearchAttributeType => {
			return Object.values(SearchAttributeType).includes(value);
		};

		for (const key in data.attributes) {
			if (isEnum(key)) {
				attributes[key as SearchAttributeType] = data.attributes[key];
			}
		}

		return new CollectionItemEntity(
			data.catalog_id,
			data.index,
			data.smiles,
			data.collections.map((collection: any) =>
				CollectionEntity.fromObject(collection)
			),
			attributes,
			data.external_url,
			data.added_at,
			data?.id ?? `${data.catalog_id}-${data.index}-${data.smiles}`
		);
	}

	get forrmatedCreatedAt(): string {
		return moment(this.added_at).format('DD MMMM YYYY');
	}

	get tooltipString(): string {
		let tooltipString = `Smiles: ${this.smiles} \n`;
		for (const [type, value] of Object.entries(this.attributes)) {
			tooltipString += `${getSearchAttributeTypeLabel(type as SearchAttributeType)} : ${value.toFixed(
				getSearchAttributeTypeToFix(type as SearchAttributeType)
			)} \n`;
		}

		return tooltipString;
	}
}
