import { i18n, router, sendSentryCaptureEvent } from '@/app/providers';
import { Notification, notificationModel } from '@/shared/uikit';
import { AuthErrorCodes } from 'firebase/auth';

export function firebaseErrorHandler(error: any) {
	const delay = 5000;
	let notification: Notification;
	const type = 'error';
	const id = 'firebase-error-handler' + Date.now();
	switch (error.code) {
		case AuthErrorCodes.INVALID_PASSWORD:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.wrongPassword.title'),
				i18n.global.t('error.notification.wrongPassword.message'),
				null,
				delay
			);
			break;
		case 'auth/invalid-login-credentials':
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.wrongPassword.title'),
				i18n.global.t('error.notification.wrongPassword.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
			notification = new Notification(
				id,
				type,
				'Please try again later',
				'To many attempts, please try again later',
				null,
				delay
			);
			break;
		case AuthErrorCodes.INVALID_PASSWORD:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.wrongPassword.title'),
				i18n.global.t('error.notification.wrongPassword.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.INVALID_EMAIL:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.wrongEmailAddress.title'),
				i18n.global.t('error.notification.wrongEmailAddress.message'),
				null,
				delay
			);
			break;

		case AuthErrorCodes.EXPIRED_OOB_CODE:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.codeExpired.title'),
				i18n.global.t('error.notification.codeExpired.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.INVALID_OOB_CODE:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.invalidCode.title'),
				i18n.global.t('error.notification.invalidCode.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.popupRequestExpired.title'),
				i18n.global.t('error.notification.popupRequestExpired.message'),
				null,
				delay
			);

		case AuthErrorCodes.EMAIL_EXISTS:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.emailExist.title'),
				i18n.global.t('error.notification.emailExist.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.NULL_USER:
			notification = new Notification(
				id,
				type,
				'',
				'User information is missing. Please log in again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.NO_AUTH_EVENT:
			notification = new Notification(
				id,
				type,
				'',
				'Authentication event not found. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.NO_AUTH_EVENT:
			notification = new Notification(
				id,
				type,
				'',
				'Provider not found. Please check your authentication provider settings.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.OPERATION_NOT_ALLOWED:
			notification = new Notification(
				id,
				type,
				'',
				'This operation is not allowed. Please contact support for assistance.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.POPUP_BLOCKED:
			notification = new Notification(
				id,
				type,
				'',
				'Popup blocked. Please enable popups and try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.POPUP_CLOSED_BY_USER:
			notification = new Notification(
				id,
				type,
				'',
				'Popup closed by the user. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
			notification = new Notification(
				id,
				type,
				'',
				'Provider is already linked to another account.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.QUOTA_EXCEEDED:
			notification = new Notification(
				id,
				type,
				'',
				'Quota exceeded. Please try again later.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
			notification = new Notification(
				id,
				type,
				'',
				'Redirect cancelled by the user. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
			notification = new Notification(
				id,
				type,
				'',
				'Redirect operation is pending. Please wait and try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.REJECTED_CREDENTIAL:
			notification = new Notification(
				id,
				type,
				'',
				'The provided credential is invalid. Please check and try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
			notification = new Notification(
				id,
				type,
				'',
				'Second factor is already in use.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
			notification = new Notification(
				id,
				type,
				'',
				'Maximum second factor count exceeded.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.TIMEOUT:
			notification = new Notification(
				id,
				type,
				'',
				'Operation timed out. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.TOKEN_EXPIRED:
			notification = new Notification(
				id,
				type,
				'',
				'User token has expired. Please log in again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
			notification = new Notification(
				id,
				type,
				'',
				'Unauthorized continue URI. Please contact support.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.UNVERIFIED_EMAIL:
			notification = new Notification(
				id,
				type,
				'',
				'Email is not verified. Please verify your email address.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.UNVERIFIED_EMAIL:
			notification = new Notification(
				id,
				type,
				'',
				'User cancelled the operation. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.UNVERIFIED_EMAIL:
			notification = new Notification(
				id,
				type,
				'',
				'User cancelled the operation. Please try again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.INTERNAL_ERROR:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.internalError.title'),
				i18n.global.t('error.notification.internalError.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.INVALID_AUTH:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.invalidAuth.title'),
				i18n.global.t('error.notification.invalidAuth.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.NETWORK_REQUEST_FAILED:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.networkRequestFailed.title'),
				'Unable to connect to the server. Please check your internet connection and try again',
				null,
				delay
			);
			break;
		case AuthErrorCodes.USER_DELETED:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.userNotFound.title'),
				i18n.global.t('error.notification.userNotFound.message'),
				null,
				delay
			);
			break;
		case AuthErrorCodes.USER_DISABLED:
			notification = new Notification(
				id,
				type,
				'',
				'User account is disabled. Please contact support',
				null,
				delay
			);
			break;
		case AuthErrorCodes.USER_MISMATCH:
			notification = new Notification(
				id,
				type,
				'',
				'User mismatch. Please log in with the correct account.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.USER_SIGNED_OUT:
			notification = new Notification(
				id,
				type,
				'',
				'User has signed out. Please log in again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.USER_SIGNED_OUT:
			notification = new Notification(
				id,
				type,
				'',
				'User has signed out. Please log in again.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.WEAK_PASSWORD:
			notification = new Notification(
				id,
				type,
				'',
				'Weak password. Please use a stronger password.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED:
			notification = new Notification(
				id,
				type,
				'',
				'Web storage is not supported. Please use a different browser.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.ALREADY_INITIALIZED:
			notification = new Notification(
				id,
				type,
				'',
				'Authentication is already initialized.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.MISSING_CLIENT_TYPE:
			notification = new Notification(
				id,
				type,
				'',
				'Missing client type. Please check your configuration.',
				null,
				delay
			);
			break;
		case AuthErrorCodes.INVALID_REQ_TYPE:
			notification = new Notification(
				id,
				type,
				'',
				'nvalid request type. Please check your request and try again.',
				null,
				delay
			);
			break;
		default:
			notification = new Notification(
				id,
				type,
				i18n.global.t('error.notification.default.title'),
				i18n.global.t('error.notification.default.message'),
				null,
				delay
			);

			sendSentryCaptureEvent('firebaseErrorHandler', error);

			break;
	}

	notificationModel.addNotification(notification);
}

export const ocularRestErrorHandler = (error: any) => {
	const delay = 3000;
	let notification: Notification;
	const type = 'error';
	const id = 'firebase-error-handler' + Date.now();

	notification = new Notification(
		id,
		type,
		error?.response?.data.detail ?? 'Something went wrong',
		error?.message ?? 'Something went wrong',
		null,
		delay
	);

	sendSentryCaptureEvent('ocularRestErrorHandler', error);

	if (error?.response?.status === 404 || error.code === 'ERR_NETWORK') {
		router.push('/404');
		return;
	}

	if (error?.response?.status === 500 || error?.response?.status === 502) {
		router.push('/500');
		return;
	}

	if (error?.response?.status === 403) {
		router.push('/onboarding');
		return;
	}

	notificationModel.addNotification(notification);
};
