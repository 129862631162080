<template>
	<BottomDrawerTemplate
		title="New Target Driven search"
		info="Search by target protein and probes."
		:isVisible="isVisible"
		@onClose="onClose"
		@onNext=""
	>
		<template v-slot:body>
			<div class="tdss-body-container">
				<template v-if="step === 0">
					<TDSSInput @onNext="onNext" @onClear="clear" />
					<TDSSCatalogList
						v-if="selectedTarget"
						:topK="topK"
						:queriesCount="1"
						:selectedCatalogId="selectedCatalog?.id"
						@onSelect="selectCatalog"
					/>
				</template>
				<template v-if="step === 1">
					<TDSSTopkSlider
						v-if="selectedCatalog"
						v-model="topK"
						:max="selectedCatalog.max_top_k"
					/>
				</template>
				<template v-if="step === 2">
					<TDSSProbesList
						:selectedTargetId="selectedTarget?.id ?? ''"
						:selectedProbes="selectedProbes"
						@onSelect="onSelectProbes"
						@onDeselectAll="selectedProbes = []"
					/>
				</template>
				<template v-if="step === 3">
					<TDSSPrefiltersList
						v-if="selectedCatalog"
						:catalogId="selectedCatalog.id"
					/>
				</template>
			</div>
		</template>

		<template v-slot:footer-controls>
			<div class="tdss-footer">
				<template v-if="step === 0">
					<bButton
						label="Next"
						type="primary"
						:isDisabled="!selectedTarget || !selectedCatalog"
						@onClick="step = 1"
					/>
				</template>

				<template v-if="step === 1">
					<bButton label="Back" type="tertiary" @onClick="step = 0" />
					<bButton
						label="Next"
						type="primary"
						:isDisabled="topK === 0"
						@onClick="step = 2"
					/>
				</template>
				<template v-if="step === 2">
					<bButton label="Back" type="tertiary" @onClick="step = 1" />
					<bButton
						label="Next"
						type="primary"
						:isDisabled="selectedProbes.length < 5"
						@onClick="step = 3"
					/>
				</template>
				<template v-if="step === 3">
					<bButton label="Back" type="tertiary" @onClick="step = 2" />
					<bButton
						label="Start search"
						type="primary"
						@onClick="finishCreating"
					/>
				</template>
			</div>
		</template>
	</BottomDrawerTemplate>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { BottomDrawerTemplate } from '@/features/home/';
import {
	TDSSCatalogList,
	TDSSInput,
	TDSSPrefiltersList,
	TDSSProbesList,
	TDSSTopkSlider,
} from '@/features/target-driven-search/';
import {
	CatalogEntity,
	ProbesEntity,
	SearchType,
	type TargetEntity,
} from '@/shared/api';
import { Notification, bButton, notificationModel } from '@/shared/uikit';
import { onMounted } from 'vue';
import { createTDSSModel } from './model';

const {
	isVisible,
	step,
	clear,
	selectedTarget,
	topK,
	selectedCatalog,
	finishCreating,
	selectedProbes,
} = createTDSSModel;

const onClose = () => {
	isVisible.value = false;
	clear();

	Analytics.send(AnalyticsEvents.Job.CLOSE_CREATE_JOB_MODAL, {
		type: SearchType.TARGET,
		step: step.value,
	});
};

const onNext = (value: TargetEntity) => {
	selectedTarget.value = value;
};

const selectCatalog = (catalog: CatalogEntity) => {
	selectedCatalog.value = catalog;
};

const onSelectProbes = (probe: ProbesEntity) => {
	const index = selectedProbes.value.findIndex(
		(p) => p.index === probe.index
	);

	if (index === -1) {
		if (selectedProbes.value.length >= 75) {
			notificationModel.addNotification(
				new Notification(
					'maximum-probes-reached',
					'error',
					'',
					`You can select up to 75 probes. Remove one to add another`,
					null,
					3000
				)
			);
		} else {
			selectedProbes.value.push(probe);
		}
	} else {
		selectedProbes.value.splice(index, 1);
	}
};

onMounted(() => {
	Analytics.send(AnalyticsEvents.Job.OPEN_CREATE_JOB_MODAL, {
		type: SearchType.TARGET,
	});
});
</script>

<style lang="stylus" scoped>
.tdss-body-container
	width 100%
	height 100%;
	padding: 0 10%
	display: flex
	flex-direction: column
	justify-content: flex-start
	padding-top 2.5rem
	gap 2.5rem
	&::-webkit-scrollbar
		none
.tdss-footer
	width 100%
	display flex
	flex-direction row
	justify-content: center
	gap 1rem
</style>
