<template>
	<bUnstyledButton
		:class="{ 'dashboard-button--active': isActive }"
		@onClick="onClick"
	>
		<template v-slot:content>
			<IconDashboard size="1.5rem" color="white" />
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { bUnstyledButton, IconDashboard } from '@/shared/uikit';
import { computed } from 'vue';

const onClick = () => {
	router.push('/dashboard');
};

const isActive = computed(() => {
	return router.currentRoute.value.fullPath.includes('/dashboard');
});
</script>

<style lang="stylus" scoped>
.dashboard-button--active
	pointer-events none
	background var(--neutral-800) !important
.b-unstyled-button
	cursor pointer
	display flex
	align-items center
	justify-content center
	width 2.25rem
	height 2.25rem
	background-color var(--neutral-900)
	color var(--neutral-100)
	&:hover
		background-color var(--neutral-800)
</style>
