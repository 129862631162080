import { router } from '@/app/providers';
import axios, {
	AxiosError,
	type AxiosInstance,
	type AxiosResponse,
} from 'axios';
import { AuthErrorCodes } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { TokenService } from '../auth/TokenService';

export class RestClient {
	private client: AxiosInstance;
	private isRefreshing: boolean = false;
	private failedQueue: Array<(token: string) => void> = [];
	private refreshAttempts: number = 0;

	constructor(baseURL: string) {
		this.client = axios.create({ baseURL });
		this.setAuthHeader();

		this.client.interceptors.response.use(
			(response) => response,
			async (error: AxiosError) => {
				return await this.errorHandler(error);
			}
		);
	}

	public get<T>(
		url: string,
		params?: Record<string, any>
	): Promise<AxiosResponse<T>> {
		return this.client.get<T>(url, { params });
	}

	public post<T>(
		url: string,
		data?: Record<string, any>,
		config?: Record<string, any>
	): Promise<AxiosResponse<T>> {
		return this.client.post<T>(url, data, config);
	}

	public put<T>(
		url: string,
		data?: Record<string, any>,
		config?: Record<string, any>
	): Promise<AxiosResponse<T>> {
		return this.client.put<T>(url, data, config);
	}

	public delete<T>(
		url: string,
		config?: Record<string, any>
	): Promise<AxiosResponse<T>> {
		return this.client.delete<T>(url, config);
	}

	public patch<T>(
		url: string,
		data?: Record<string, any>,
		config?: Record<string, any>
	): Promise<AxiosResponse<T>> {
		return this.client.patch<T>(url, data, config);
	}

	// private async errorHandler(error: AxiosError) {
	// 	const originalRequest = error.config;

	// 	if (originalRequest) {
	// 		if (error?.response?.status === 401 && !this.isRefreshing) {
	// 			this.isRefreshing = true;

	// 			try {
	// 				const newToken = await this.refreshToken();
	// 				TokenService.getInstance().saveToken(newToken);
	// 				this.setAuthHeader(newToken);
	// 				this.isRefreshing = false;

	// 				// Retry the failed requests with the new token
	// 				this.failedQueue.forEach((cb) => cb(newToken));
	// 				this.failedQueue = [];

	// 				// Set the Authorization header with the new token
	// 				originalRequest.headers[
	// 					'Authorization'
	// 				] = `Bearer ${newToken}`;
	// 				return this.client(originalRequest);
	// 			} catch (refreshError) {
	// 				this.isRefreshing = false;
	// 				return Promise.reject(refreshError);
	// 			}
	// 		}

	// 		// Handling the case where the token is being refreshed
	// 		if (error?.response?.status === 401 && this.isRefreshing) {
	// 			return new Promise((resolve, reject) => {
	// 				this.failedQueue.push((token: string) => {
	// 					originalRequest.headers[
	// 						'Authorization'
	// 					] = `Bearer ${token}`;
	// 					resolve(this.client(originalRequest));
	// 				});
	// 			});
	// 		}
	// 	}

	// 	return Promise.reject(error);
	// }

	private async errorHandler(error: AxiosError) {
		const originalRequest = error.config;

		if (originalRequest && error?.response?.status === 401) {
			if (!this.isRefreshing) {
				if (this.refreshAttempts < 3) {
					this.isRefreshing = true;
					this.refreshAttempts++;

					try {
						const newToken = await this.refreshToken();
						TokenService.getInstance().saveToken(newToken);
						this.setAuthHeader(newToken);
						this.isRefreshing = false;

						this.failedQueue.forEach((cb) => cb(newToken));
						this.failedQueue = [];

						originalRequest.headers[
							'Authorization'
						] = `Bearer ${newToken}`;
						return this.client(originalRequest);
					} catch (refreshError) {
						this.isRefreshing = false;
						if (this.refreshAttempts >= 3) {
							this.handleRefreshFailure();
						}
						return Promise.reject(refreshError);
					}
				} else {
					this.handleRefreshFailure();
				}
			} else {
				return new Promise((resolve, reject) => {
					this.failedQueue.push((token: string) => {
						originalRequest.headers[
							'Authorization'
						] = `Bearer ${token}`;
						resolve(this.client(originalRequest));
					});
				});
			}
		}

		return Promise.reject(error);
	}

	private setAuthHeader(token?: string) {
		const authToken = token || TokenService.getInstance().getToken();
		this.client.defaults.headers.common[
			'Authorization'
		] = `Bearer ${authToken}`;
	}

	public async refreshToken(): Promise<string> {
		const currentUser = firebase.auth().currentUser;

		if (!currentUser) {
			throw AuthErrorCodes.INVALID_AUTH;
		}

		return await currentUser.getIdToken(true);
	}

	private handleRefreshFailure() {
		localStorage.removeItem('auth_token');
		router.push('/login');
	}
}
