<template>
	<div class="cart-button-item" @click="onClick">
		{{ label }}
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabType } from '@/shared/api';
import { computed } from 'vue';
import { cartModel } from './model';

const emit = defineEmits(['onClick']);
const { hits } = cartModel;
const props = defineProps({
	count: Number,
});

const label = computed(() => {
	const count = props.count || hits.value.length;
	return `Start new search with ${count} molecule${count > 1 ? 's' : ''}`;
});

const onClick = () => {
	emit('onClick');

	const { addTab } = navigationTabsModel;

	addTab({
		id: `search-type`,
		title: 'Search type',
		description: `Select search type`,
		path: `/search-type`,
		type: NavigationTabType.NewTab,
	});

	Analytics.send(AnalyticsEvents.CART.CLICK_CREATE_JOB_SHORTCUT);
};
</script>

<style lang="stylus" scoped>
.cart-button-item
	width 100%
	display flex
	flex-direction row
	align-items center
	justify-content space-between
	padding: 0.85rem 0.75rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	color var(--neutral-700)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
