<template>
	<div class="collection-page">
		<CollectionsListWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { CollectionsListWidget } from '@/widgets/collections';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.Collections.OPEN_COLLECTIONS_PAGE);
});
</script>

<style lang="stylus" scoped>
.collection-page
	width 100%
	height 100%
</style>
