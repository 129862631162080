<template>
	<div class="b-simple-text-input">
		<div v-if="label" class="footnoteRegular">{{ label }}</div>
		<bTextArea
			ref="inputRef"
			:modelValue="modelValue"
			:placeholder="placeholder"
			:type="type"
			:size="size"
			:isDisabled="isDisabled"
			@onBlur="$emit('onBlur', $event)"
			@onFocus="$emit('onFocus', $event)"
			@update:modelValue="
				(value: any) => $emit('update:modelValue', value)
			"
		/>
	</div>
</template>

<script setup lang="ts">
import { bTextArea } from '@/shared/uikit';
import { ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus']);

const inputRef = ref<any>(null);

const props = defineProps({
	modelValue: String,
	type: { type: String, required: false },
	size: { type: String, required: false, default: 'medium' },
	placeholder: { type: String, required: false },
	isDisabled: { type: Boolean, required: false, default: false },
	label: { type: String, required: false },
});
</script>

<style lang="stylus" scoped>
.b-simple-text-input
	width 100%
	.footnoteRegular
		padding-left 0.5rem
		margin-bottom 0.38rem
		color var(--neutral-600)
</style>
