<template>
	<div class="dag-pipeline-page">
		<SvgPipelineWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import {
	navigationHeaderModel,
	navigationTabsModel,
} from '@/features/navigation';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { SvgPipelineWidget } from '@/widgets/pipeline/';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { title, description, clear: clearNavigation } = navigationHeaderModel;

onMounted(() => {
	reloadPage();
	Analytics.send(AnalyticsEvents.PIPELINE.VIEW_JOB_PIPELINE, {
		jobId: router.currentRoute.value.params.id as string,
	});
});

watch(
	() => router.currentRoute.value.name,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	if (router.currentRoute.value.name === 'job-pipeline') {
		title.value = 'Job Pipeline';
		description.value = 'Planed flow';
		const { id } = router.currentRoute.value.params;
		const { addTabIfNotExists, tabs } = navigationTabsModel;

		addTabIfNotExists(
			NavigationTabEntity.fromObject({
				id: `job-pipeline-${id}`,
				title: 'Job Pipeline',
				description: 'Planed flow',
				path: `/pipeline/${id}`,
				type: NavigationTabType.Pipeline,
				tabs: tabs.value.length + 1,
				isPinned: false,
			})
		);
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style scoped>
.dag-pipeline-page {
	width: 100%;
	height: 100%;
	position: relative;
}
</style>
