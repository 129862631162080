export class TokenService {
	private static instance: TokenService;
	private key = 'auth_token';

	private constructor() {}

	public static getInstance(): TokenService {
		if (!TokenService.instance) {
			TokenService.instance = new TokenService();
		}
		return TokenService.instance;
	}

	saveToken(token: string): void {
		localStorage.setItem(this.key, token);
	}

	removeToken(): void {
		localStorage.removeItem(this.key);
	}

	getToken(): string | null {
		return localStorage.getItem(this.key);
	}
}
