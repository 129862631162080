<template>
	<div class="switch-molecule-grid">
		<bUnstyledButton
			:class="{ 'b-unstyled-button--active': !modelValue }"
			@onClick="toggleGrid"
		>
			<template v-slot:content>
				<div class="captionRegular">2D</div>
			</template>
		</bUnstyledButton>
		<bUnstyledButton
			:class="{ 'b-unstyled-button--active': modelValue }"
			@onClick="toggleGrid"
		>
			<template v-slot:content>
				<Icon3D
					size="1rem"
					:color="
						modelValue
							? ThemeColors.primary[600]
							: ThemeColors.neutral[400]
					"
				/>
			</template>
		</bUnstyledButton>
	</div>
</template>

<script setup lang="ts">
import { Icon3D, ThemeColors, bUnstyledButton } from '@/shared/uikit';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: Boolean,
});

const toggleGrid = () => {
	emit('update:modelValue', !props.modelValue);
};
</script>

<style lang="stylus" scoped>
.switch-molecule-grid
	display: flex
	flex-direction: row
	align-items: center
	justify-content center
	width 5em
	height 1.75rem
	border-radius: 1rem;
	border: 1px solid var(--neutral-100);
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);

	.b-unstyled-button
		height 100%
		width 50%
		display flex
		align-items center
		justify-content center
		border-radius 2rem
	.b-unstyled-button--active
		background: var(--primary-200)
</style>
