<template>
	<div class="error">
		<bImageServerError />
		<div class="titleLargeBold">500</div>
		<div class="titleBold">{{ $t('error.500.title') }}</div>
		<div class="headlineRegular">
			{{ $t('error.500.subtitle') }}
		</div>

		<bButton
			:label="$t('error.500.button.back')"
			type="primary"
			@onClick="onGoToHome"
		/>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { bButton, bImageServerError } from '@/shared/uikit';

const onGoToHome = () => {
	router.push('/');
};
</script>
<style lang="stylus" scoped>
.error
	width 100vw
	height calc(100vh - 6.25rem)
	display flex
	flex-direction: column
	align-items: center
	justify-content: center
	gap 1.5rem
	.titleLargeBold
		font-size 4rem
</style>
