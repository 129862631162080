import { JobState } from '@/shared/api';
import { computed, reactive, toRefs } from 'vue';

function getJobStateLabel(type: JobState): string {
	switch (type) {
		case JobState.QUEUED:
			return 'Scheduled';
		case JobState.ACTIVE:
			return 'Running';
		case JobState.ENRICHMENT:
			return 'Running';
		case JobState.SUCCESS:
			return 'Ready';
		case JobState.FAILURE:
			return 'Failed';
		default:
			return 'Unknown';
	}
}

export class JobResultsSortOption {
	label: string;
	type: JobState;
	isSelected: boolean = true;

	constructor(type: JobState) {
		this.label = getJobStateLabel(type);
		this.type = type;
	}
}

interface IViewModel {
	options: JobResultsSortOption[];
}
const data: IViewModel = {
	options: [
		new JobResultsSortOption(JobState.QUEUED),
		new JobResultsSortOption(JobState.ACTIVE),
		new JobResultsSortOption(JobState.SUCCESS),
		new JobResultsSortOption(JobState.FAILURE),
	],
};

const state = reactive(data);

const selectedJobStates = computed(() => {
	return state.options
		.filter((option) => option.isSelected)
		.map((option) => option.type);
});

export const jobResultsTBSSSortModel = {
	...toRefs(state),
	selectedJobStates,
};
