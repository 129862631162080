export class OcularUser {
	id: string;
	name: string;
	email: string;
	firebase_uid: string;
	created_at: Date;
	balance: number;

	constructor(
		id: string,
		name: string,
		email: string,
		firebase_uid: string,
		created_at: Date,
		balance: number
	) {
		this.id = id;
		this.name = name;
		this.email = email;
		this.firebase_uid = firebase_uid;
		this.created_at = created_at;
		this.balance = balance;
	}

	get shortName(): string {
		return this.name
			.split(' ')
			.map((n) => n[0])
			.join('');
	}
}

export class OrganizationEntity {
	id: string;
	name: string;
	created_at: Date;
	role: Role;

	constructor(id: string, name: string, created_at: Date, role: Role) {
		this.id = id;
		this.name = name;
		this.created_at = created_at;
		this.role = role;
	}
}

export enum Role {
	Viewer = 'viewer',
	Member = 'member',
	Admin = 'admin',
	Owner = 'owner',
}
export class MemberEntity {
	id: string;
	name: string;
	email: string;
	firebase_uid: string;
	created_at: Date;
	role: Role;

	constructor(
		id: string,
		name: string,
		email: string,
		firebase_uid: string,
		created_at: Date,
		role: Role
	) {
		this.id = id;
		this.name = name;
		this.email = email;
		this.firebase_uid = firebase_uid;
		this.created_at = created_at;
		this.role = role;
	}
}

export class UsageEntity {
	id: string;
	title: string;
	total: number;
	used: number;

	constructor(id: string, title: string, total: number, used: number) {
		this.id = id;
		this.title = title;
		this.total = total;
		this.used = used;
	}
}

export enum SubscriptionType {
	STARTER = 'starter',
	GROWTH = 'growth',
	ENTERPRISE = 'ENTERPRISE',
}

export enum PlanStatus {
	ACTIVE = 'active',
	CANCELED = 'canceled',
	TRIALING = 'trialing',
}

export class PlanEntity {
	type: SubscriptionType;
	status: PlanStatus;

	constructor(type: SubscriptionType, status: PlanStatus) {
		this.type = type;
		this.status = status;
	}

	get title(): string {
		switch (this.type) {
			case SubscriptionType.STARTER:
				return 'Starter';
			case SubscriptionType.GROWTH:
				return 'Growth';
			case SubscriptionType.ENTERPRISE:
				return 'Enterprise';
			default:
				return 'Starter';
		}
	}

	get price(): number {
		switch (this.type) {
			case SubscriptionType.STARTER:
				return 99;
			case SubscriptionType.GROWTH:
				return 499;
			case SubscriptionType.ENTERPRISE:
				return 0;
			default:
				return 0;
		}
	}

	get isTrial(): boolean {
		return this.status === PlanStatus.TRIALING;
	}
}

export class SubscriptionEntity {
	id: string;
	type: string;
	price: number;
	list: string[];
	isActive: boolean;

	constructor(
		id: string,
		type: SubscriptionType,
		price: number,
		list: string[],
		isActive: boolean
	) {
		this.id = id;
		this.type = type;
		this.price = price;
		this.list = list;
		this.isActive = isActive;
	}

	get title(): string {
		switch (this.type) {
			case SubscriptionType.STARTER:
				return 'Starter';
			case SubscriptionType.GROWTH:
				return 'Growth';
			case SubscriptionType.ENTERPRISE:
				return 'Enterprise';
			default:
				return 'Starter';
		}
	}
}

export class PaymentOptionEntity {
	amount: number | null;
	url: string;
	price: number;
	isSelected: boolean = false;

	constructor(amount: number | null, url: string, price: number) {
		this.amount = amount ? amount / 100 : null;
		this.url = url;
		this.price = price ?? 1;
	}

	get isDefault(): boolean {
		return this.amount === null;
	}
}
