import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { AuthService, GoogleAuthProvider } from '@/shared/api';
import { firebaseErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

const state = reactive({
	isLoading: false,
});

async function login() {
	if (router.currentRoute.value.fullPath === '/login') {
		Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_GOOGLE);
	} else {
		Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_GOOGLE);
	}

	try {
		state.isLoading = true;
		const provider = new GoogleAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.login(provider);
		router.push('/');

		if (router.currentRoute.value.fullPath === '/login') {
			Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_GOOGLE_SUCCESS);
		} else {
			Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_GOOGLE_SUCCESS);
		}
	} catch (error) {
		firebaseErrorHandler(error);
		if (router.currentRoute.value.fullPath === '/login') {
			Analytics.send(AnalyticsEvents.Login.LOGIN_WITH_GOOGLE_FAILURE);
		} else {
			Analytics.send(AnalyticsEvents.SignUp.SIGN_UP_WITH_EMAIL_FAILURE);
		}
	} finally {
		state.isLoading = false;
	}
}

export const buttonModel = {
	...toRefs(state),
	login,
};
