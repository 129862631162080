<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 19 19"
		fill="none"
	>
		<path
			d="M11.4453 18.9014C11.0234 18.9014 10.7129 18.752 10.5137 18.4531C10.3145 18.1602 10.1475 17.7881 10.0127 17.3369L8.65918 12.8721C8.57129 12.5615 8.53906 12.3008 8.5625 12.0898C8.5918 11.8789 8.7002 11.6738 8.8877 11.4746L17.9229 1.66602C17.9814 1.60742 18.0107 1.5459 18.0107 1.48145C18.0107 1.41113 17.9873 1.35254 17.9404 1.30566C17.8877 1.25879 17.8262 1.23535 17.7559 1.23535C17.6914 1.23535 17.6299 1.26172 17.5713 1.31445L7.78906 10.3848C7.57227 10.584 7.36133 10.6953 7.15625 10.7188C6.95117 10.7363 6.69629 10.6982 6.3916 10.6045L1.83008 9.20703C1.40234 9.07812 1.04785 8.91699 0.766602 8.72363C0.485352 8.52441 0.344727 8.2168 0.344727 7.80078C0.344727 7.46094 0.470703 7.1709 0.722656 6.93066C0.980469 6.69043 1.2998 6.49707 1.68066 6.35059L16.8945 0.523438C17.0938 0.453125 17.2812 0.397461 17.457 0.356445C17.6328 0.30957 17.7939 0.286133 17.9404 0.286133C18.251 0.286133 18.4971 0.379883 18.6787 0.567383C18.8604 0.749023 18.9512 0.995117 18.9512 1.30566C18.9512 1.45801 18.9307 1.61914 18.8896 1.78906C18.8486 1.95898 18.79 2.14355 18.7139 2.34277L12.9219 17.5039C12.7578 17.9258 12.5557 18.2627 12.3154 18.5146C12.0752 18.7725 11.7852 18.9014 11.4453 18.9014Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
