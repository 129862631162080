<template>
	<bModal
		v-if="previewMolecule"
		id="preview-smiles-modal-container"
		type="success"
		title=""
		message=""
		:width="800"
		@onClose="onClose"
	>
		<template v-slot:body>
			<div class="preview-smiles-modal-container">
				<canvas
					id="preview-smiles-modal"
					width="500"
					height="444"
				></canvas>
				<div
					v-if="(alerts?.length ?? 0) > 0"
					class="preview-smiles-modal--legend"
				>
					<bTooltip
						v-for="alert in alerts"
						:key="`preview-smiles-${alert.type}`"
						:id="`tooltip-${alert.type}`"
						alignDirection="center"
						:hoverTitle="alert.description"
						type="primary"
						@mouseover="mouseOver(alert)"
						@mouseleave="mouseLeave"
					>
						<template v-slot:body>
							<div
								class="subheadlineSemiBold legend-tag"
								:style="`background: ${
									MoleculeAlertsTypeColorsHex[alert.type]
								}`"
							>
								{{ getTitleForAlert(alert.type) }}
							</div>
						</template>
					</bTooltip>
				</div>
			</div>
		</template>
	</bModal>
</template>

<script setup lang="ts">
import {
	MoleculeAlertsEntity,
	MoleculeAlertsTypeColors,
	MoleculeAlertsTypeColorsHex,
	getTitleForAlert,
} from '@/shared/api';
import { drawSmilesWithParams } from '@/shared/lib/rdkit';
import { bModal, bTooltip } from '@/shared/uikit/';
import { computed, onMounted, onUpdated } from 'vue';
import { previewSmilesModel } from './model';

const { previewMolecule } = previewSmilesModel;

const alerts = computed(() => previewMolecule.value?.alerts);

const onClose = () => {
	previewMolecule.value = null;
};

onUpdated(() => drawCanvas());
onMounted(() => drawCanvas());

const drawCanvas = () => {
	if (!previewMolecule.value) return;

	if (!previewMolecule.value) return;

	const atom_colors: { [key: number]: number[] } = {};
	const bond_colors: { [key: number]: number[] } = {};

	alerts.value?.forEach((alert) => {
		alert.atoms.forEach(
			(atom) => (atom_colors[atom] = MoleculeAlertsTypeColors[alert.type])
		);

		alert.bonds.forEach(
			(bond) => (bond_colors[bond] = MoleculeAlertsTypeColors[alert.type])
		);
	});

	drawSmilesWithParams(
		previewMolecule.value?.smiles,
		'preview-smiles-modal',
		500,
		444,
		{
			atoms: Object.keys(atom_colors).map(Number),
			bonds: Object.keys(bond_colors).map(Number),
			highlightAtomColors: atom_colors,
			highlightBondColors: bond_colors,
			scaleBondWidth: true,
		}
	);
};

const mouseOver = (alert: MoleculeAlertsEntity) => {
	if (!previewMolecule.value) return;

	drawSmilesAlerts('preview-smiles-modal', alert);
};

const mouseLeave = () => {
	if (!previewMolecule.value) return;

	drawCanvas();
};

const drawSmilesAlerts = (elementId: string, alert: MoleculeAlertsEntity) => {
	if (!previewMolecule.value) return;

	const atom_colors: { [key: number]: number[] } = {};
	const bond_colors: { [key: number]: number[] } = {};

	alerts.value?.forEach((alert) => {
		alert.atoms.forEach((atom) => (atom_colors[atom] = [0.75, 0.75, 0.75]));

		alert.bonds.forEach((bond) => (bond_colors[bond] = [0.75, 0.75, 0.75]));
	});

	alert.atoms.forEach(
		(atom) => (atom_colors[atom] = MoleculeAlertsTypeColors[alert.type])
	);

	alert.bonds.forEach(
		(bond) => (bond_colors[bond] = MoleculeAlertsTypeColors[alert.type])
	);

	drawSmilesWithParams(
		previewMolecule.value?.smiles,
		'preview-smiles-modal',
		500,
		444,
		{
			atoms: Object.keys(atom_colors).map(Number),
			bonds: Object.keys(bond_colors).map(Number),
			highlightAtomColors: atom_colors,
			highlightBondColors: bond_colors,
			scaleBondWidth: true,
		}
	);
};
</script>

<style lang="stylus">
#preview-smiles-modaL-container
	.b-modal
		gap 0
		background var(--neutral-50) !important
		padding 0.5rem
		.b-modal-body
			display: flex
			justify-content: center
			align-items: center
			background white
			border-radius 0.5rem
		.b-modal-header
			display: none !important
.preview-smiles-modal-container
	display: flex
	flex-direction row
	justify-content: center
	align-items: center
	gap 1rem
	.preview-smiles-modal--legend
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: center
		gap 0.5rem
		.subheadlineBold
			color: var(--neutral-900)
			font-size: 1.25rem
			margin-top: 1rem
		.legend-tag
			padding 0.5rem
			display: flex
			justify-content: center
			align-items: center
			gap 0.5rem
			border-radius: 0.5rem
			color white
</style>
