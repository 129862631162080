<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 5C4 3.34315 5.34315 2 7 2H15C16.6569 2 18 3.34315 18 5V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6V5C16 4.44772 15.5523 4 15 4H7C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H15C15.5523 20 16 19.5523 16 19V18C16 17.4477 16.4477 17 17 17C17.5523 17 18 17.4477 18 18V19C18 20.6569 16.6569 22 15 22H7C5.34315 22 4 20.6569 4 19V5Z"
			:fill="color"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.2929 8.29289C15.6834 7.90237 16.3166 7.90237 16.7071 8.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L16.7071 15.7071C16.3166 16.0976 15.6834 16.0976 15.2929 15.7071C14.9024 15.3166 14.9024 14.6834 15.2929 14.2929L16.5858 13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H16.5858L15.2929 9.70711C14.9024 9.31658 14.9024 8.68342 15.2929 8.29289Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
