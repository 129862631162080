<template>
	<div v-if="notifications.length > 0" class="b-notification-wrapper">
		<div
			v-for="notification in notifications"
			:key="notification.id"
			class="b-notification"
			:class="{
				'b-notification--success': notification.type === 'success',
				'b-notification--error': notification.type === 'error',
				'b-notification--warning': notification.type === 'warning',
				'b-notification--info': notification.type === 'info',
			}"
		>
			<!-- <div class="b-notification-border"></div> -->
			<div class="b-notification-body">
				<!-- <div class="footnoteSemiBold">
					{{ notification.title }}
				</div> -->
				<div class="footnoteRegular">
					{{ notification.description }}
				</div>
			</div>

			<div
				class="b-notification-close"
				@click="removeNotification(notification.id)"
			>
				<IconClose size="1rem" :color="iconColor(notification.type)" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ThemeColors } from '../..';
import { IconClose } from '../icons';
import { notificationModel } from './model';

const { notifications, removeNotification } = notificationModel;

const iconColor = (type: 'success' | 'error' | 'warning' | 'info') => {
	switch (type) {
		case 'success':
			return ThemeColors.secondary[600];
		case 'error':
			return ThemeColors.danger[600];
		case 'warning':
			return ThemeColors.warning[600];
		case 'info':
			return ThemeColors.secondary[600];
		default:
			return ThemeColors.secondary[600];
	}
};
</script>

<style lang="stylus" scoped>
.b-notification-wrapper
	position fixed
	top: 0
	right 0
	padding: 1rem
	width: 20rem
	z-index 100
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: flex-end
	gap: 1rem

.b-notification
	width 100%
	padding: 0.88rem 1rem;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 0.625rem;
	transition: all 0.3s ease-in-out
	border-radius: 0.625rem;
	background: var(--neutral-0, #FFF);
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	.b-notification-border
		width 0.375rem
		border-radius: 0.125rem;
	.b-notification-body
		width 100%
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: flex-start
		gap: 0.38rem
		.footnoteRegular
			color: var(--neutral-500)
	.b-notification-close
		width 2rem
		height 2rem
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease-in-out
		&:hover
			cursor: pointer
			transform: scale(0.95)
			opacity 0.8
			transition: all 0.3s ease-in-out

	&--success
		background-color: var(--neutral-50)
		// .b-notification-border
		// 	background-color: var(--success-600)
	&--error
		background-color: var(--danger-50)
		.b-notification-body
			.footnoteRegular
				color: var(--danger-600)
		// .b-notification-border
		// 	background-color: var(--danger-600)
	&--warning
		background-color: var(--warning-50)
		// .b-notification-border
		// 	background-color: var(--warning-600)
	&--info
		background-color: var(--primary-50)
		// .b-notification-border
		// 	background-color: var(--primary-600)
</style>
