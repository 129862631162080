<template>
	<div class="tdss-proteins-preview-container">
		<template v-if="results.length > 0 && !isLoading">
			<div
				v-for="item in results"
				class="search-protein-item"
				:class="{
					'search-protein-item--selected':
						selectedProtein === item.id,
				}"
				:key="'protein-key-' + item.id"
				@click="onClick($event, item)"
			>
				<div class="search-protein-item--info">
					<div class="footnoteBold">{{ item.name }}</div>
					<div class="captionBold">
						{{ item.formattedProbesCount }}
					</div>
				</div>
				<TargetLinkButton :id="item.id" :data="item.external_url" />
			</div>
		</template>

		<bEmptyView
			v-if="results.length === 0 && !isLoading"
			title="Sorry, no proteins found"
			description="Please check your search query and try again later"
		>
			<template v-slot:image>
				<bImageEmpty2 :width="100" :height="100" />
			</template>
		</bEmptyView>

		<div v-if="isLoading" class="tdss-proteins-preview-container">
			<div class="empty-view"><bLoader siz4e="4" /></div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { TargetLinkButton } from '@/features/target-driven-search';
import type { TargetEntity } from '@/shared/api';
import { bEmptyView, bImageEmpty2, bLoader } from '@/shared/uikit';

const emit = defineEmits(['onClick']);

const props = defineProps({
	selectedProtein: {
		type: String,
		required: false,
	},
	results: {
		type: Array as () => TargetEntity[],
		required: true,
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
});

const onClick = (event: MouseEvent, item: TargetEntity) => {
	emit('onClick', item);
};
</script>

<style lang="stylus" scoped>
.tdss-proteins-preview-container
	width 30rem
	transition all 0.25s
	height 16.875rem
	border-radius: 0.25rem
	background: var(--neutral-50);
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
	display: flex
	flex-direction: column
	align-items: flex-start
	gap: 0rem
	padding 0.44rem 0
	overflow-y scroll
	&::-webkit-scrollbar
		width 0.25rem
	.search-protein-item
		display: grid
		grid-template-columns 1fr auto
		align-items center
		width 100%
		padding 0.88rem 0.75rem
		transition all 0.25s
		&:hover
			cursor pointer
			background var(--neutral-100)
			transition all 0.25s
		.captionBold
			color var(--neutral-400)
		&--selected
			background var(--neutral-100)
			transition all 0.25s
</style>
