import { TargetsService, type TargetEntity } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';

interface IViewModel {
	searchQuery: string;
	isLoading: boolean;
	targets: TargetEntity[];
	selectedTarget: TargetEntity | null;
}
const data: IViewModel = {
	searchQuery: '',
	isLoading: false,
	targets: [],
	selectedTarget: null,
};

const state = reactive(data);

watch(
	() => state.searchQuery,
	async (newValue) => {
		fetchProteinTargets(newValue);
	}
);

function clear() {
	state.searchQuery = '';
	state.selectedTarget = null;
	state.targets = [];
}

async function fetchProteinTargets(query: string, isForse = true) {
	state.isLoading = true;
	try {
		const targets = await TargetsService.getInstance().fetchTargets(
			query,
			10,
			0
		);

		state.targets = targets;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const tdssInputModel = {
	...toRefs(state),
	clear,
	fetchProteinTargets,
};
