<template>
	<div
		:id="id"
		ref="tooltipElement"
		@mouseover="isHovered = true"
		@mouseleave="isHovered = false"
		@click.stop="(event) => event.stopPropagation()"
	>
		<slot name="body"></slot>
	</div>
</template>

<script lang="ts" setup>
import { nextTick, onUnmounted, ref, watch } from 'vue';

enum AlignDirection {
	LEFT = 'left',
	RIGHT = 'right',
	CENTER = 'center',
}

const emit = defineEmits(['onCopy']);

const props = defineProps({
	id: { type: String, required: false, default: 'b-tooltip' },
	hoverTitle: { type: String, required: false, default: 'Copy link' },
	type: { type: String, required: false, default: 'primary' },
	alignDirection: {
		type: String,
		required: false,
		default: 'left',
	},
});

const isHovered = ref(false);
const tooltipElement = ref<HTMLElement | null>(null);

const calculateTooltipPosition = () => {
	const target = document.getElementById(props.id);
	if (!target) return;

	tooltipElement.value = document.createElement('div');
	tooltipElement.value.setAttribute('class', `b-tooltip`);
	tooltipElement.value.setAttribute('id', `b-tooltip-${props.id}`);
	tooltipElement.value.style.position = 'fixed';
	tooltipElement.value.style.zIndex = '9999';
	tooltipElement.value.style.opacity =
		props.hoverTitle.length > 0 ? '1' : '0';
	tooltipElement.value.innerText = props.hoverTitle;
	document.body.appendChild(tooltipElement.value);

	const tooltipRect = tooltipElement.value.getBoundingClientRect();
	const targetRect = target.getBoundingClientRect();

	let top = targetRect.top + window.scrollY;
	let left = targetRect.left + window.scrollX;

	const spaceAbove = targetRect.top;
	const spaceBelow = window.innerHeight - targetRect.bottom;
	const spaceLeft = targetRect.left;
	const spaceRight = window.innerWidth - targetRect.right;

	if (spaceBelow >= tooltipRect.height) {
		top += targetRect.height;
	} else if (spaceAbove >= tooltipRect.height) {
		top -= tooltipRect.height;
	} else if (spaceRight >= tooltipRect.width) {
		left += targetRect.width;
	} else if (spaceLeft >= tooltipRect.width) {
		left -= tooltipRect.width;
	} else {
		left += (targetRect.width - tooltipRect.width) / 2;
		top -= tooltipRect.height;
	}

	// Ensure tooltip is fully visible
	if (left + tooltipRect.width > window.innerWidth) {
		left = window.innerWidth - tooltipRect.width - 10; // 10px padding
	} else if (left < 0) {
		left = 10; // 10px padding
	}

	if (top + tooltipRect.height > window.innerHeight) {
		top = window.innerHeight - tooltipRect.height - 10; // 10px padding
	} else if (top < 0) {
		top = 10; // 10px padding
	}

	tooltipElement.value.style.top = `${top + 5}px`;
	tooltipElement.value.style.left = `${left}px`;
};

const destroyTooltip = () => {
	nextTick(() => {
		const tooltip = document.getElementById(`b-tooltip-${props.id}`);
		if (tooltip) {
			document.body?.removeChild(tooltip);
		}
	});
};

watch(
	() => isHovered.value,
	(newValue) => {
		if (newValue) {
			calculateTooltipPosition();
		} else {
			destroyTooltip();
		}
	}
);

onUnmounted(() => {
	destroyTooltip();
});
</script>

<style lang="stylus">
.b-tooltip
	padding 0.5rem
	background: var(--neutral-600)
	border-radius 0.5rem
	font-family: var(--font-family)
	font-size: 0.75rem
	color: white
	white-space: nowrap
	cursor default !important
	line-height 1.25rem
</style>
