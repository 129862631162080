<template>
	<div class="compounds-page">
		<div class="compounds-page--header">
			<div class="bodySemiBold">eMolecules compound form</div>
			<div class="compounds-page--header-right">
				<SendEmoleculesButton />
				<ResetCompoundsButton
					@onClick="compoundsListWidgetRef?.reset()"
				/>
			</div>
		</div>
		<CompoundsListWidget ref="compoundsListWidgetRef" />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import {
	CompoundsListWidget,
	ResetCompoundsButton,
	SendEmoleculesButton,
	sendEmoleculesModel,
} from '@/widgets/cart';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const compoundsListWidgetRef = ref<InstanceType<typeof CompoundsListWidget>>();

const { title, description, clear: clearNavigation } = navigationHeaderModel;
const { fetchHits } = sendEmoleculesModel;

onMounted(() => {
	reloadPage();
	Analytics.send(AnalyticsEvents.CART.VIEW_COMPOUNDS_PAGE);
});

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	clearNavigation();

	await fetchHits();
	if (router.currentRoute.value.name === 'compounds-page') {
		title.value = 'eMolecules order';
		description.value = `Select compounds`;
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.compounds-page
	width 100%
	height 100%
	padding 0 calc(5vw)
	display flex
	flex-direction column
	gap 1rem
	.compounds-page--header
		height 5.125rem
		padding 1rem 0
		display flex
		justify-content space-between
		align-items center
		.compounds-page--header-right
			display flex
			flex-direction row
			align-items center
			gap 1rem
</style>
