import DAGPipelinePage from '../DAGPipelinePage.vue';
import JobPipelinePage from '../JobPipelinePage.vue';

export const dagPipelineRoutes = [
	{
		path: '/pipeline',
		component: DAGPipelinePage,
		name: 'pipeline',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
	{
		path: '/pipeline/:id',
		component: JobPipelinePage,
		name: 'job-pipeline',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
];
