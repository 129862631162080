import { NavigationTabType } from '@/shared/api';
import {
	IconCollections,
	IconFlows,
	IconLigandSearch,
	IconRobot,
	IconStructureSearch,
	IconTargetSearch,
	IconTwoLayer,
} from '@/shared/uikit';

export const getNavigationTabIcon = (type: NavigationTabType) => {
	switch (type) {
		case NavigationTabType.LIGAND:
			return IconLigandSearch;
		case NavigationTabType.TARGET:
			return IconTargetSearch;
		case NavigationTabType.Collection:
			return IconCollections;
		case NavigationTabType.Cart:
			return IconTwoLayer;
		case NavigationTabType.Chat:
			return IconRobot;
		case NavigationTabType.Pipeline:
			return IconFlows;
		case NavigationTabType.Structure:
			return IconStructureSearch;
		default:
			return IconLigandSearch;
	}
};
