<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 4H9.5V9.5H4L4 4ZM2 4C2 2.89543 2.89543 2 4 2H9.5C10.6046 2 11.5 2.89543 11.5 4V9.5C11.5 10.6046 10.6046 11.5 9.5 11.5H4C2.89543 11.5 2 10.6046 2 9.5V4ZM4 14.5H9.5V20H4L4 14.5ZM2 14.5C2 13.3954 2.89543 12.5 4 12.5H9.5C10.6046 12.5 11.5 13.3954 11.5 14.5V20C11.5 21.1046 10.6046 22 9.5 22H4C2.89543 22 2 21.1046 2 20V14.5ZM20 4H14.5V9.5H20V4ZM14.5 2C13.3954 2 12.5 2.89543 12.5 4V9.5C12.5 10.6046 13.3954 11.5 14.5 11.5H20C21.1046 11.5 22 10.6046 22 9.5V4C22 2.89543 21.1046 2 20 2H14.5ZM14.5 14.5H20V20H14.5V14.5ZM12.5 14.5C12.5 13.3954 13.3954 12.5 14.5 12.5H20C21.1046 12.5 22 13.3954 22 14.5V20C22 21.1046 21.1046 22 20 22H14.5C13.3954 22 12.5 21.1046 12.5 20V14.5Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
