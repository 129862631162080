<template>
	<div ref="scrollRef" class="chat-messages">
		<el-row
			v-for="(messageGroup, index) in mappedMessages"
			:key="index"
			class="message-group"
		>
			<el-col v-if="messageGroup.input" :span="24">
				<ChatMessageCard :message="messageGroup.input" />
			</el-col>
			<el-col :span="24" class="timeline-wrapper bodySmallRegular">
				<el-collapse>
					<el-collapse-item
						title="Performed in the background"
						name="1"
					>
						<el-timeline>
							<el-timeline-item
								v-for="(
									timelineMessage, tIndex
								) in messageGroup.timeline"
								:key="tIndex"
								:color="timelineMessage.color"
								size="large"
							>
								<ChatMessageTimelineCard
									:message="timelineMessage"
								/>
							</el-timeline-item>
						</el-timeline>
					</el-collapse-item>
				</el-collapse>
			</el-col>
			<el-col v-if="messageGroup.output" :span="24">
				<ChatMessageCard
					:message="messageGroup.output"
					:isADMEPredictor="isADMEPredictor"
					:isEurofinsAssistant="isEurofinsAssistant"
				/>
			</el-col>
		</el-row>

		<div
			v-if="isContuneButtonVisible && !isLoading"
			class="chat-messages--footer"
		>
			<bButton
				label="Continue"
				type="primary"
				@onClick="onClickContinue"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ChatMessageCard, ChatMessageTimelineCard } from '@/entities/chats';
import {
	MessageEntity,
	NewMappedMessage,
	ToolType,
	mapNewMessages,
} from '@/shared/api';
import { bButton } from '@/shared/uikit';
import { computed, onMounted, ref, type PropType } from 'vue';

const emit = defineEmits(['onClickContinue']);
const scrollRef = ref<HTMLElement | null>(null);

const props = defineProps({
	messages: Array as PropType<MessageEntity[]>,
	isLoading: {
		type: Boolean,
		default: false,
	},
	isContuneButtonVisible: {
		type: Boolean,
		default: false,
	},
});

const mappedMessages = computed(() => {
	return mapNewMessages(props?.messages ?? []);
});

const updateScroll = () => {
	setTimeout(() => {
		if (scrollRef.value) {
			scrollRef.value.scrollTop = scrollRef.value.scrollHeight + 32;
		}
	}, 0);
};

onMounted(() => {
	updateScroll();
});

defineExpose({
	updateScroll,
});

const onClickContinue = () => {
	emit('onClickContinue');
};

const isADMEPredictor = computed(() => {
	return mappedMessages.value.find((group: NewMappedMessage) => {
		return group.isToolIncluded(ToolType.BIOPTIC_ADME_PREDICTOR);
	})
		? true
		: false;
});

const isEurofinsAssistant = computed(() => {
	return mappedMessages.value.find((group: NewMappedMessage) => {
		return group.isToolIncluded(ToolType.BIOPTIC_EUROFINS_ASSISTANT);
	})
		? true
		: false;
});
</script>

<style lang="stylus" scoped>
.chat-messages
	width 100%
	height calc(100vh - 6.25rem)
	margin-top -2rem
	overflow-y auto
	overflow-x hidden
	display flex
	flex-direction column
	align-items center
	justify-content flex-start
	gap 1.25rem
	padding 1rem
	padding-bottom 6rem
.message-group
	width 100%
	padding-top 2rem
.chat-messages--footer
	width 100%
	display flex
	align-items center
	justify-content flex-end
	padding 1rem
</style>

<style lang="stylus">
.message-group
	.el-row
		gap 2rem
.timeline-wrapper
	padding 1rem
	margin 2rem 0
	ul
		padding 1rem
	li
		margin-left 1rem
	.markdown-body
		p
			a
				color inherit !important
				pointer-events none
				text-decoration none
		pre
			overflow hidden !important
			text-overflow ellipsis
</style>
