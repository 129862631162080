<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<g clip-path="url(#clip0_719_1831)">
			<path
				d="M8.71005 11.711L11.3001 14.301C11.6901 14.691 12.3201 14.691 12.7101 14.301L15.3001 11.711C15.9301 11.081 15.4801 10.001 14.5901 10.001H9.41005C8.52005 10.001 8.08005 11.081 8.71005 11.711Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_719_1831">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 0.000976562)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
