<template>
	<div
		ref="inputWithSelectRef"
		class="pipeline-protein-input"
		@click="openMenu"
	>
		<bInputWithSelect
			v-model="searchQuery"
			placeholder="Search for a UniProt ID"
			size="medium"
			:isSelectable="!selectedTarget"
		>
			<template v-slot:right-icon v-if="searchQuery.length > 0">
				<div class="pipeline-protein-input--controls">
					<TargetLinkButton
						v-if="selectedTarget"
						:id="selectedTarget.id"
						:data="selectedTarget.external_url"
						size="1rem"
					/>
					<bUnstyledButton @click="onClearInput">
						<template v-slot:content>
							<IconClose size="1rem" />
						</template>
					</bUnstyledButton>
				</div>
			</template>
			<template v-if="selectedTarget" v-slot:selected-target>
				<div
					class="pipeline-protein-input--target"
					@click="onClearInput"
				>
					<div class="bodySmallRegular">
						{{ selectedTarget.formattedProbesCount }}
					</div>
					<div class="bodySmallSemiBold">
						{{ selectedTarget.name }}
					</div>
				</div>
			</template>
			<template v-slot:dropdown>
				<Teleport to="body">
					<div
						v-if="searchQuery.length > 0 && !selectedTarget"
						class="docking-provider-dropdown"
						:style="dropdownStyle"
						ref="dropdownRef"
					>
						<TDSSProteinsPreview
							:results="targets"
							:isLoading="false"
							:selectedTarget="searchQuery"
							@onClick="onSelectProtein"
						/>
					</div>
				</Teleport>
			</template>
		</bInputWithSelect>
	</div>
</template>

<script setup lang="ts">
import { TargetLinkButton } from '@/features/target-driven-search';
import { TargetsService, type TargetEntity } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { IconClose, bInputWithSelect, bUnstyledButton } from '@/shared/uikit';
import { defineEmits, onMounted, ref, watch } from 'vue';
import TDSSProteinsPreview from './TDSSProteinsPreview.vue';

const emit = defineEmits(['onSelectProtein', 'onClear']);
const isOpened = ref(false);
const inputWithSelectRef = ref<HTMLElement | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);
const targets = ref<TargetEntity[]>([]);
const selectedTarget = ref<TargetEntity | null>(null);
const isLoading = ref(false);
const searchQuery = ref('');

const dropdownStyle = ref({
	top: '0px',
	left: '0px',
	width: '0px',
});

const props = defineProps({
	protein: {
		type: String,
		required: true,
	},
});

const onClearInput = () => {
	searchQuery.value = '';
	targets.value = [];
	selectedTarget.value = null;
	emit('onClear');
};

const onSelectProtein = (target: TargetEntity) => {
	selectedTarget.value = target;
	emit('onSelectProtein', target);
};

const openMenu = (e: MouseEvent) => {
	e.preventDefault();
	isOpened.value = true;

	const targetRect = inputWithSelectRef.value?.getBoundingClientRect();

	if (!targetRect) {
		return;
	}

	const top = targetRect.bottom;
	const left = targetRect.left;

	dropdownStyle.value = {
		top: `${top}px`,
		left: `${left}px`,
		width: `${targetRect.width}px`,
	};
};

const fetchProteinTargets = async (query: string, isForse = true) => {
	isLoading.value = true;
	try {
		targets.value = await TargetsService.getInstance().fetchTargets(
			query,
			10,
			0
		);

		if (query === props.protein) {
			const findedProtein = targets.value.find(
				(target) => target.uni_prot_kb_ac === props.protein
			);

			if (findedProtein) {
				selectedTarget.value = findedProtein;
				searchQuery.value = findedProtein.uni_prot_kb_ac;
			}
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		isLoading.value = false;
	}
};

onMounted(async () => {
	await fetchProteinTargets(props.protein);
});

watch(
	() => searchQuery.value,
	async (newValue) => {
		fetchProteinTargets(newValue);
	}
);
</script>

<style lang="stylus" scoped>
.pipeline-protein-input
	display flex
	flex-direction column
	align-items flex-start
	justify-content flex-start
	gap 1rem
	position relative
	width 100%
	.b-text-input-with-select-container
		.b-text-input-with-select
			border-radius 0.5rem
	&--controls
		display flex
		flex-direction row
		align-items center
		justify-content center
		gap 0.37rem
		.b-unstyled-button
			padding 0.25rem
	&--target
		position absolute
		top 0.5rem
		left 0.75rem
		width calc(100% - 10rem)
		height calc(100% - 1rem)
		background white
		display flex
		flex-direction column
		align-items flex-start
		justify-content center
		transition all 0.25s
		cursor text
		.bodySmallRegular
			font-size 0.5rem
			color var(--neutral-400)

.docking-provider-dropdown
	position: absolute
	z-index: 9999
</style>

<style lang="stylus">
.pipeline-protein-input
	.b-text-input-with-select-container
		.b-text-input-with-select
			border-radius 0.75rem
			padding 0.5rem 1rem

 .tdss-proteins-preview-container
	position: absolute
	z-index: 9999
	margin-top: 0.5rem
	display: flex
	flex-direction: column
	align-items: flex-start
	gap: 0.25rem
	border: 1px solid rgba(0, 0, 0, 0.10)
	border-radius: 0.25rem
	background: var(--neutral-50)
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)
	&--item
		width 15rem
		display grid
		grid-template-columns 1fr auto
		align-items center
		justify-content flex-start
		padding 0.625rem 1rem
		font-family var(--font-family)
		font-size 0.875rem
		font-style normal
		font-weight 500
		line-height 1.25rem
		letter-spacing 0.00625rem
		color var(--neutral-700)
		white-space nowrap
		&:hover
			background var(--neutral-100)
			cursor pointer
		&--active
			pointer-events none
			background var(--neutral-100)
</style>
