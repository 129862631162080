<template>
	<nav class="dashboard-leftbar-widget">
		<div class="dashboard-leftbar-widget--title calloutSemiBold">
			BIOPTIC View
		</div>
		<RouterLink
			class="dashboard-link"
			v-for="item in links"
			:key="`dashboard-link-${item.route}`"
			:to="item.route"
		>
			<component :is="item.icon" size="1.5rem" />
			<div class="subheadlineSemiBold">{{ item.name }}</div>
		</RouterLink>
		<CollectionsItemsDropdown :items="items" />
	</nav>
</template>

<script setup lang="ts">
import {
	CollectionsItemsDropdown,
	collectionsItemsListModel,
} from '@/features/collections/';
import {
	IconLigandSearch,
	IconRobot,
	IconStructureSearch,
	IconTargetSearch,
} from '@/shared/uikit';
import { markRaw, onMounted, ref } from 'vue';

const { items, fetchCollections } = collectionsItemsListModel;
type Link = {
	name: string;
	icon: any;
	route: string;
};

const links = ref<Link[]>([
	{
		name: 'Ligand Based results',
		icon: markRaw(IconLigandSearch),
		route: '/dashboard',
	},
	{
		name: 'Target Driven results',
		icon: markRaw(IconTargetSearch),
		route: '/dashboard/target-search',
	},
	{
		name: 'Fingerprint-based results',
		icon: markRaw(IconStructureSearch),
		route: '/dashboard/structure-search',
	},
	{
		name: 'BIOPTIC Copilot chats',
		icon: markRaw(IconRobot),
		route: '/dashboard/chats',
	},
	// {
	// 	name: 'History',
	// 	icon: markRaw(IconTargetSearch),
	// 	route: '/dashboard/history',
	// },
	// {
	// 	name: 'Collections',
	// 	icon: markRaw(IconCollections),
	// 	route: '/dashboard/collections',
	// },
]);

onMounted(() => {
	fetchCollections();
});
</script>

<style lang="stylus" scoped>
.dashboard-leftbar-widget
	width 100%
	padding-top 3.75rem
	display flex
	flex-direction column
	justify-content flex-start
	gap 0.75rem
	&--title
		padding 1rem

	a
		text-decoration none
		color inherit
	.dashboard-link
		display flex
		align-items center
		justify-content flex-start
		gap 0.75rem
		padding 0.75rem 1rem
		border-top-right-radius 0.5rem
		border-bottom-right-radius 0.5rem
		transition all 0.25s
		border-left 0.125rem solid transparent
		&:hover
			background-color var(--primary-50)
			transition all 0.25s
			border-left-color var(--primary-800)
			.subheadlineSemiBold
				color var(--primary-800)
		&.router-link-exact-active
			background-color var(--primary-50)
			border-left-color var(--primary-800)
			.subheadlineSemiBold
				color var(--primary-800)
</style>
