export const ThemeColors: { [key: string]: { [key: string]: string } } = {
	primary: {
		900: '#134E4A',
		800: '#115E59',
		700: '#0F766E',
		600: '#0D9488',
		500: '#14B8A6',
		400: '#2DD4BF',
		300: '#5EEAD4',
		200: '#99F6E4',
		100: '#CCFBF1',
		50: '#F0FDFA',
	},
	danger: {
		900: '#7f1d1d',
		800: '#991b1b',
		700: '#b91c1c',
		600: '#dc2626',
		500: '#ef4444',
		400: '#f87171',
		300: '#fca5a5',
		200: '#fecaca',
		100: '#FEE2E2',
		50: '#fef2f2',
	},
	warning: {
		900: '#7c2d12',
		800: '#991B1B',
		700: '#c2410c',
		600: '#ea580c',
		500: '#F97316',
		400: '#fb923c',
		300: '#fdba74',
		200: '#fed7aa',
		100: '#ffedd5',
		50: '#fff7ed',
	},
	success: {
		900: '#064E3B',
		800: '#065F46',
		700: '#319E4A',
		600: '#3DC55D',
		500: '#64D17D',
		400: '#9EE2AE',
		300: '#B1E8BE',
		200: '#C5EECE',
		100: '#D1FAE5',
		50: '#ECF9EF',
	},
	neutral: {
		900: '#0f172a',
		800: '#334155',
		700: '#334155',
		600: '#475569',
		500: '#64748b',
		400: '#94a3b8',
		300: '#cbd5e1',
		200: '#e2e8f0',
		100: '#f1f5f9',
		50: '#f8fafc',
	},
	information: {
		700: '#2A4CD3',
		600: '#2F54EB',
		500: '#637FF0',
		400: '#8298F3',
		300: '#97A9F5',
		200: '#C1CCF9',
		50: '#EAEEFD',
	},
	blueGray: {
		900: '#0F172A',
		800: '#1E293B',
		700: '#334155',
		600: '#475569',
		500: '#64748B',
		400: '#94A3B8',
		300: '#CBD5E1',
		200: '#E2E8F0',
		100: '#F1F5F9',
		50: '#F8FAFC',
	},
	cyan: {
		900: '#164E63',
		800: '#155E75',
		700: '#0E7490',
		600: '#0891B2',
		500: '#06B6D4',
		400: '#22D3EE',
		300: '#67E8F9',
		200: '#A5F3FC',
		100: '#CFFAFE',
		50: '#ECFEFF',
	},
	lime: {
		900: '#365314',
		800: '#3F6212',
		700: '#4D7C0F',
		600: '#65A30D',
		500: '#84CC16',
		400: '#A3E635',
		300: '#BEF264',
		200: '#D9F99D',
		100: '#ECFCCB',
		50: '#F7FEE7',
	},
	purple: {
		900: '#581C87',
		800: '#6B21A8',
		700: '#7E22CE',
		600: '#9333EA',
		500: '#A855F7',
		400: '#C084FC',
		300: '#D8B4FE',
		200: '#E9D5FF',
		100: '#F3E8FF',
		50: '#FAF5FF',
	},
	pink: {
		900: '#831843',
		800: '#9D174D',
		700: '#BE185D',
		600: '#DB2777',
		500: '#EC4899',
		400: '#F472B6',
		300: '#F9A8D4',
		200: '#FBCFE8',
		100: '#FCE7F3',
		50: '#FDF2F8',
	},
	rose: {
		900: '#881337',
		800: '#9F1239',
		700: '#BE123C',
		600: '#E11D48',
		500: '#F43F5E',
		400: '#FB7185',
		300: '#FDA4AF',
		200: '#FECDD3',
		100: '#FFE4E6',
		50: '#FFF1F2',
	},
	yellow: {
		900: '#713F12',
		800: '#854D0E',
		700: '#A16207',
		600: '#CA8A04',
		500: '#EAB308',
		400: '#FACC15',
		300: '#FDE047',
		200: '#FEF08A',
		100: '#FEF9C3',
		50: '#FEFCE8',
	},
	secondary: {
		900: '#0c4a6e',
		800: '#075985',
		700: '#0369a1',
		600: '#0284c7',
		500: '#0ea5e9',
		400: '#38bdf8',
		300: '#7dd3fc',
		200: '#bae6fd',
		100: '#e0f2fe',
		50: '#f0f9ff',
	},
	indigo: {
		600: '#4F46E5',
	},
	sky: {
		600: '#0284C7',
	},
	amber: {
		600: '#D97706',
	},
};

export function getRandomColors(length: number): string[] {
	const colorKeys = Object.keys(ThemeColors);
	let randomColors: string[] = [];

	for (let i = 0; i < length; i++) {
		const randomColorGroupKey =
			colorKeys[Math.floor(Math.random() * colorKeys.length)];
		const colorShades = Object.keys(ThemeColors[randomColorGroupKey]);
		const randomShadeKey =
			colorShades[Math.floor(Math.random() * colorShades.length)];
		randomColors.push(ThemeColors[randomColorGroupKey][randomShadeKey]);
	}

	return randomColors;
}
