import {
	CollectionService,
	type CollectionEntity,
} from '@/shared/api/collections';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	total: number;
	items: CollectionEntity[];
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	total: 0,
	items: [],
};

const state = reactive(data);

async function fetchCollections() {
	state.isLoading = true;

	try {
		const result = await CollectionService.getInstance().fetchCollections(
			80,
			0
		);
		state.items = result.collections;
		state.total = result.count;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const collectionsItemsListModel = {
	...toRefs(state),
	fetchCollections,
};
