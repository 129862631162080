<template>
	<div class="b-textarea-container">
		<textarea
			ref="editable"
			:value="modelValue"
			@input="updateValue($event as InputEvent)"
			:class="classInputName"
			:placeholder="placeholder"
			:disabled="isDisabled"
			class="b-textarea"
			@blur="$emit('onBlur', $event)"
			@focus="$emit('onFocus', $event)"
		/>
		<div class="b-textarea-right-icon">
			<slot name="right-icon"></slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus']);

const props = defineProps({
	modelValue: String,
	size: { type: String, required: false, default: 'medium' },
	placeholder: {
		type: String,
		required: false,
		default: 'Your message here...',
	},
	isDisabled: { type: Boolean, required: false, default: false },
});

const classInputName = computed((): any => {
	var result = {
		'b-textarea--size-medium': props.size === 'medium',
		'b-textarea--size-small': props.size === 'small',
		'b-textarea--size-large': props.size === 'large',
		'b-textarea--disabled': props.isDisabled,
	};
	return result;
});

const updateValue = (event: InputEvent) => {
	const target = event.target as HTMLInputElement;
	emit('update:modelValue', target.value);
};
</script>

<style lang="stylus" scoped>
.b-textarea-container
	position: relative
	width: 100%
	.b-textarea-right-icon
		position: absolute
		right: 0.5rem
		top: 50%
		transform: translateY(-50%)
		display: flex
		align-items: center
		justify-content: center

.b-textarea
	resize: none
	width: 100%
	min-height 15rem
	box-sizing: border-box
	font-family: var(--font-family)
	font-style: normal
	font-weight: 400
	background: white
	border: 1px solid var(--neutral-200)
	border-radius: 0.5rem
	transition-property: background
	transition-duration: .25s
	transition-timing-function: ease-in
	&:focus
		border-color: var(--primary-600)
		transition-property: background
		transition-duration: .25s
		transition-timing-function: ease-in
	&:hover
		background: var(--neutral-50)
		transition-property: background
		transition-duration: .25s
		transition-timing-function: ease-in
	&::placeholder
		color: var(--neutral-500)
	&--size-large
		font-size: 1.125rem
		padding: 1rem 1.5rem
	&--size-medium
		font-size: 1rem
		padding: 0.75rem 1rem
	&--size-small
		font-size: 0.75rem
		padding: 0.5rem 0.75rem
	&--disabled
		pointer-events: none
		user-select: none
		background: var(--neutral-200)
</style>
