<template>
	<div class="container">
		<IconLogo size="2.5rem" :color="ThemeColors.primary[600]" />

		<div class="calloutRegular">{{ $t('auth.newPassword.title') }}</div>

		<div class="login-container">
			<form>
				<bInput
					ref="inputRef"
					:placeholder="
						$t('auth.newPassword.form.passwordPlaceholder')
					"
					type="password"
					size="large"
					:isDisabled="false"
					:isCaption="false"
					@onChange="onChangePassword"
				/>

				<bButton
					:label="$t('auth.newPassword.button.reset')"
					type="primary"
					:isDisabled="!form.passwordValid"
					:isLoading="isLoading"
					@click="onConfirm"
				/>
			</form>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconLogo, ThemeColors, bButton, bInput } from '@/shared/uikit';
import { onMounted, reactive } from 'vue';
import { createNewPasswordModel } from './model';

const props = defineProps({
	oobCode: {
		type: String,
		required: true,
	},
});

const form = reactive({
	password: '',
	passwordValid: false,
});

const { isLoading, createNewPassword } = createNewPasswordModel;

const onChangePassword = (value: any) => {
	form.password = value.value;
	form.passwordValid = value.isValid;
};

const onConfirm = () => {
	createNewPassword(form.password, props.oobCode);
};

onMounted(() => {
	Analytics.send(AnalyticsEvents.CreateNewPassword.VIEW_PAGE);
});
</script>
<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';

.title3Bold
	margin-top 1.5rem
</style>
