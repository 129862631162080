<template>
	<div class="table-image-column" @click="onClick">
		<canvas
			:id="'table-image-column-' + id + '-' + smiles"
			width="200"
			height="150"
		></canvas>
	</div>
</template>

<script lang="ts" setup>
import { drawSmiles } from '@/shared/lib/rdkit';
import { onMounted } from 'vue';

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	smiles: {
		type: String,
		required: true,
	},
});

onMounted(() => {
	drawSmiles(
		props.smiles,
		'table-image-column-' + props.id + '-' + props.smiles,
		200,
		150
	);
});

const onClick = () => {};
</script>
<style lang="stylus" scoped>
.table-image-column
	pointer-events auto !important
	width 100%
	height 100%
	display flex
	justify-content center
	align-items center
	box-shadow: 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04), 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19);
	border-radius 1rem
	overflow hidden
	margin 0.5rem 0
	background-color white
</style>
