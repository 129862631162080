import { Analytics, AnalyticsEvents } from '@/app/providers';
import { JobsService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { Notification, notificationModel } from '@/shared/uikit';
import { jobResultsFiltersModel } from '@/widgets/job';
import { reactive, toRefs } from 'vue';
import { jobResultsSortModel } from '../job-results-sort-dropdown';

interface IViewModel {
	isDownloading: boolean;
	loadingIds: string[];
}

const data: IViewModel = {
	isDownloading: false,
	loadingIds: [],
};

const state = reactive(data);

async function downloadCollection(collectionId: string) {
	notificationModel.addNotification(
		new Notification(
			'email-verified-success',
			'success',
			'',
			`Your download will start soon, please don't close the tab.`,
			null,
			3000
		)
	);

	state.loadingIds.push(collectionId);
	state.isDownloading = true;
	try {
		// await CollectionService.getInstance().downloadArchive(collectionId);
		Analytics.send(AnalyticsEvents.Collections.DOWNLOAD_COLLECTION, {
			collection_id: collectionId,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.loadingIds = state.loadingIds.filter((id) => id !== collectionId);
		state.isDownloading = false;
	}
}

async function downloadById(loadingId: string) {
	notificationModel.addNotification(
		new Notification(
			'email-verified-success',
			'success',
			'',
			`Your download will start soon, please don't close the tab.`,
			null,
			3000
		)
	);

	state.loadingIds.push(loadingId);
	state.isDownloading = true;
	Analytics.send(AnalyticsEvents.Job.CLICK_DOWNLOAD_ARCHIVE, {
		jobId: loadingId,
	});

	try {
		await JobsService.getInstance().downloadArchive(loadingId);

		Analytics.send(AnalyticsEvents.Job.DOWNLOAD_ARCHIVE_SUCCESS, {
			jobId: loadingId,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
		Analytics.send(AnalyticsEvents.Job.DOWNLOAD_ARCHIVE_FAILURE, {
			jobId: loadingId,
		});
	} finally {
		state.loadingIds = state.loadingIds.filter((id) => id !== loadingId);
		state.isDownloading = false;
	}
}
async function downloadByFilters(loadingId: string) {
	notificationModel.addNotification(
		new Notification(
			'email-verified-success',
			'success',
			'',
			`Your download will start soon, please don't close the tab.`,
			null,
			3000
		)
	);

	state.isDownloading = true;
	const { filters } = jobResultsFiltersModel;
	const { selectedOption } = jobResultsSortModel;

	try {
		await JobsService.getInstance().downloadArchiveByFilters(
			loadingId,
			selectedOption.value,
			filters.value
		);

		Analytics.send(AnalyticsEvents.Job.DOWNLOAD_ARCHIVE_BY_FILTERS, {
			search_id: loadingId,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isDownloading = false;
	}
}

export const downloadSearchResultsModel = {
	...toRefs(state),
	downloadCollection,
	downloadById,
	downloadByFilters,
};
