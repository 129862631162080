<template>
	<template v-if="isLoading">
		<div class="empty-view"><bLoader size="4" /></div>
	</template>
	<template v-else-if="job">
		<template v-if="job.state === JobState.SUCCESS">
			<slot name="ready-state"></slot>
		</template>
		<template v-else>
			<bEmptyView
				v-if="
					job.state === JobState.ACTIVE ||
					job.state === JobState.ENRICHMENT
				"
				:title="title"
				:description="description"
			>
				<template v-slot:image>
					<div class="progress-image">
						<bImageProgress :width="400" :height="380" />
						<bProgress
							:progress="(job as ActiveJobEntity).progress * 100"
						/>
					</div>
				</template>
			</bEmptyView>
			<bEmptyView v-else :title="title" :description="description">
				<template v-slot:image>
					<bImageEmpty2 :width="200" :height="200" />
				</template>
			</bEmptyView>
		</template>
	</template>
</template>

<script setup lang="ts">
import { ActiveJobEntity, JobEntity, JobState } from '@/shared/api';
import {
	bEmptyView,
	bImageEmpty2,
	bImageProgress,
	bLoader,
	bProgress,
} from '@/shared/uikit';
import { computed, type PropType } from 'vue';

const props = defineProps({
	isLoading: Boolean,
	job: Object as PropType<JobEntity | null>,
});

const title = computed(() => {
	switch (props.job?.state) {
		case JobState.SUCCESS:
			return 'Results are ready';
		case JobState.FAILURE:
			return 'Job failed';
		case JobState.ACTIVE:
			return 'Search is in progress';
		case JobState.ENRICHMENT:
			return 'Enrichment is in progress';
		case JobState.QUEUED:
			return 'Search is in the queue';
		default:
			return 'No results found';
	}
});

const description = computed(() => {
	switch (props.job?.state) {
		case JobState.SUCCESS:
			return 'You can preview or download the results.';
		case JobState.FAILURE:
			return 'Try to change your search criteria.';
		case JobState.ACTIVE:
			return 'Please wait until the search is completed.';
		case JobState.ENRICHMENT:
			return 'Please wait until the enrichment is completed.';
		case JobState.QUEUED:
			return 'Your search is in the queue.';
		default:
			return 'Try to change your search criteria.';
	}
});
</script>

<style lang="stylus" scoped>

.b-progress-container
	position: relative
	top: -4px;
.b-empty-view
	height: calc(100vh - 10rem)
</style>
