<template>
	<div class="pipeline-generate-input">
		<bTextInput
			ref="textInput"
			:class="{
				'b-text-input-container--loading': isLoading,
			}"
			v-model:modelValue="newMessage"
			@keyup.enter.prevent="onEnterPress"
			:isDisabled="isLoading"
			:placeholder="
				isLoading ? 'Thinking...' : 'Type your Flow request here...'
			"
			@update:modelValue="(value) => $emit('update:modelValue', value)"
		>
			<template v-if="isLoading" v-slot:left-icon>
				<div class="calloutRegular">🤔</div>
			</template>
			<template v-slot:right-icon>
				<bUnstyledButton
					:class="{
						'b-unstyled-button--loading': isLoading,
					}"
					@onClick="onSendMessage"
					:isDisabled="isLoading || newMessage.trim().length === 0"
				>
					<template v-slot:content>
						<div v-if="isLoading" class="b-loader"></div>
						<IconSent v-else color="white" size="0.75rem" />
					</template>
				</bUnstyledButton>
			</template>
		</bTextInput>
	</div>
</template>

<script setup lang="ts">
import { IconSent, bTextInput, bUnstyledButton } from '@/shared/uikit';
import { ref } from 'vue';

const textInput = ref<HTMLInputElement | null>(null);
const newMessage = ref<string>('');
const emit = defineEmits(['update:modelValue', 'onSendMessage']);

const props = defineProps({
	isLoading: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const onEnterPress = (event: any) => {
	if (!event.shiftKey) {
		onSendMessage();
	}
};

const onSendMessage = () => {
	if (newMessage.value.trim().length === 0) {
		return;
	}

	emit('onSendMessage', newMessage.value);
	newMessage.value = '';
};
</script>

<style lang="stylus" scoped>
.pipeline-generate-input
	width 100%
	display flex
	flex-direction row
	align-items flex-end
	justify-content space-between
	gap 0.625rem
	.b-unstyled-button
		display: flex;
		height: 2rem;
		width 2rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 50%
		background var(--primary-600);
		&.b-unstyled-button--loading
			background white
</style>

<style lang="stylus">
.pipeline-generate-input
	.b-text-input-container
		input
			border-radius 1.25rem !important
			padding-right 2.75rem !important
			padding-left 1.25rem !important

	.b-text-input-container--loading
		input
			padding-left 2.5rem !important

.b-loader {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	position: relative;
	animation: rotate 1s linear infinite;
}
.b-loader::before,
.b-loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 2px solid var(--neutral-400);
	animation: prixClipFix 2s linear infinite;
}
.b-loader::after {
	border-color: var(--primary-600);
	animation: prixClipFix 2s linear infinite,
		rotate 0.5s linear infinite reverse;
	inset: 2px;
}
</style>
