<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 16 16"
		fill="none"
	>
		<g clip-path="url(#clip0_3234_5070)">
			<path
				d="M11.8333 10.6668H4.77993C3.3866 10.6668 2.1466 9.64683 2.01327 8.26016C1.85993 6.6735 3.1066 5.3335 4.6666 5.3335H12.9066C13.7799 5.3335 14.5733 5.96016 14.6599 6.82683C14.7599 7.82683 13.9799 8.66683 12.9999 8.66683H5.99993C5.63327 8.66683 5.33327 8.36683 5.33327 8.00016C5.33327 7.6335 5.63327 7.3335 5.99993 7.3335H11.8333C12.1066 7.3335 12.3333 7.10683 12.3333 6.8335C12.3333 6.56016 12.1066 6.3335 11.8333 6.3335H6.09327C5.21993 6.3335 4.4266 6.96016 4.33993 7.82683C4.23993 8.82683 5.01993 9.66683 5.99993 9.66683H12.8866C14.2799 9.66683 15.5199 8.64683 15.6533 7.26016C15.8066 5.66683 14.5599 4.3335 12.9999 4.3335H4.81993C2.9066 4.3335 1.19327 5.7335 1.01327 7.64016C0.813267 9.8335 2.51993 11.6668 4.6666 11.6668H11.8333C12.1066 11.6668 12.3333 11.4402 12.3333 11.1668C12.3333 10.8935 12.1066 10.6668 11.8333 10.6668Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3234_5070">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
