<template>
	<bModal
		class="logout-modal"
		v-if="isLogout"
		type="danger"
		:title="$t('auth.modal.logout.title')"
		:message="$t('auth.modal.logout.message')"
		:width="400"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bTextButton
				:label="$t('auth.modal.logout.button.cancel')"
				type="danger"
				@onClick="onClose"
			/>
			<bButton
				:label="$t('auth.modal.logout.button.logout')"
				type="danger"
				:isGhost="true"
				@onClick="onClick"
			/>
		</template>
		<template v-slot:icon>
			<IconLogout size="1.5rem" :color="ThemeColors.danger[600]" />
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { modalsModel } from '@/entities/modals/';
import {
	IconLogout,
	ThemeColors,
	bButton,
	bModal,
	bTextButton,
} from '@/shared/uikit/';
import { defineEmits } from 'vue';
import { logoutButtonModel } from './model';

const emit = defineEmits(['onClose']);
const { isLogout } = modalsModel;
const { logout } = logoutButtonModel;
const onClose = () => {
	isLogout.value = false;
};

const onClick = () => {
	logout();
	onClose();
};
</script>
