<template>
	<div class="chat-card">
		<div class="chat-card--header">
			<div class="captionRegular">
				{{ chat.formattedDate }}
			</div>
			<div class="footnoteBold">
				{{ chat.name }}
			</div>
		</div>
		<div class="chat-card--footer">
			<bButton label="Open Chat" type="primary" @onClick="onClick" />

			<RemoveChatButton :id="chat.id" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { RemoveChatButton } from '@/features/chats';
import { type ChatEntity } from '@/shared/api';
import { bButton } from '@/shared/uikit';
import type { PropType } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	chat: {
		type: Object as PropType<ChatEntity>,
		required: true,
	},
});

const onClick = () => {
	emit('onClick');
	router.push('/chat/' + props.chat.id);
};
</script>

<style lang="stylus" scoped>
.chat-card
	display flex
	flex-direction column
	align-items flex-start
	justify-content space-between
	gap 1rem
	padding 1rem
	border-radius 1rem
	transition all 0.25s
	background: var(--Palette-slate-50, #F8FAFC);
	box-shadow: 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04), 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19);
	&--header
		display flex
		flex-direction column
		align-items flex-start
		width 100%
		.captionRegular
			color var(--neutral-600)
	&--footer
		display flex
		align-items center
		justify-content space-between
		gap 0.25rem
		width 100%
</style>
