<template>
	<bButton
		label="Order from eMolecules"
		type="primary"
		:is-disabled="isDisabled"
		@onClick="openCompoundsForm"
	>
		<template v-slot:icon-left>
			<IconRequest
				size="1rem"
				:color="isDisabled ? ThemeColors.neutral[400] : 'white'"
			/>
		</template>
	</bButton>
</template>

<script setup lang="ts">
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { IconRequest, ThemeColors, bButton } from '@/shared/uikit';

const props = defineProps({
	isDisabled: {
		type: Boolean,
		default: false,
	},
});

const openCompoundsForm = () => {
	const { addTab, tabs } = navigationTabsModel;

	addTab(
		NavigationTabEntity.fromObject({
			id: `coumpounds-form`,
			title: 'eMolecules',
			description: 'Select compounds',
			path: `/cart/compounds`,
			type: NavigationTabType.Cart,
			tabs: tabs.value.length + 1,
			isPinned: false,
		})
	);
};
</script>

<style lang="stylus" scoped></style>
