export class ADMETColumnEntity {
	constructor(
		public label: string,
		public name: string,
		public type: string,
		public units: string,
		public description: string,
		public min_value: number | null,
		public max_value: number | null,
		public colors: Array<{
			color: string;
			min: number | null;
			max: number | null;
		}>
	) {}

	static fromObject(data: any): ADMETColumnEntity {
		return new ADMETColumnEntity(
			data.label,
			data.name,
			data.type,
			data.units,
			data.description,
			data.min_value,
			data.max_value,
			data.colors
		);
	}

	static get options(): ADMETColumnEntity[] {
		return predictors.map(ADMETColumnEntity.fromObject);
	}
}

const predictors = [
	{
		label: 'Solubility, log(mol/L)',
		name: 'Sol',
		type: 'regression',
		units: 'log(mol/L)',
		description: 'Solubility. Ability to dissolve in water.',
		min_value: -3,
		max_value: 2,
		colors: [
			{
				color: 'green',
				min: -2,
				max: -1,
			},
			{
				color: 'yellow',
				min: -1,
				max: 0,
			},
			{
				color: 'red',
				min: 0,
				max: null,
			},
		],
	},
	{
		label: 'Lipophilicity, log-ratio',
		name: 'Lipophilicity',
		type: 'regression',
		units: 'log-ratio',
		description: 'Ability to dissolve in a lipid.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'Caco2_Wang',
		name: 'Caco-2',
		type: 'regression',
		units: 'log(10^-6 cm/s)',
		description:
			'Permeability. Rate of passage through the Caco-2 human cancer cell line.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'PPBR, %',
		name: 'PPBR',
		type: 'regression',
		units: '%',
		description:
			'Plasma Protein Binding Rate. Fraction bound to plasma proteins in the blood.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'HC, uL/min/10^6 cells',
		name: 'HC',
		type: 'regression',
		units: 'uL/min/10^6 cells',
		description:
			'Hepatocyte Clearance. Volume of plasma perfusing the hepatocytes that is cleared of the drug per unit of time.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'Clearance_Microsome_AZ',
		name: 'MC',
		type: 'regression',
		units: 'uL/min/mg',
		description:
			'Microsome Clearance. Volume of plasma perfusing the liver microsomes that is cleared of the drug per unit of time.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'BBBP',
		name: 'BBBP',
		type: 'classification',
		units: null,
		description: 'Blood-Brain Barrier Permeability.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'CYP3A4',
		name: 'CYP3A4',
		type: 'classification',
		units: null,
		description: 'Ability to inhibit the CYP3A4 enzyme.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'CYP2D6',
		name: 'CYP2D6',
		type: 'classification',
		units: null,
		description: 'Ability to inhibit the CYP2D6 enzyme.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'CYP2C9',
		name: 'CYP2C9',
		type: 'classification',
		units: null,
		description: 'Ability to inhibit the CYP2C9 enzyme.',
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
	{
		label: 'hERG',
		name: 'hERG',
		type: 'classification',
		units: null,
		description:
			"Ability to inhibit hERG, which coordinates the heart's beating.",
		min_value: null,
		max_value: null,
		colors: [
			{
				color: 'green',
				min: -1,
				max: 1,
			},
		],
	},
];
