import {
	TargetBasedSearchStrategyType,
	getTargetBasedSearchStrategyTypeString,
} from '@/shared/api';

export class TopActiveOptions {
	get name(): string {
		return getTargetBasedSearchStrategyTypeString(this.type);
	}

	static get options(): TopActiveOptions[] {
		return [
			new TopActiveOptions(TargetBasedSearchStrategyType.TOP_5_ACTIVE),
			new TopActiveOptions(TargetBasedSearchStrategyType.TOP_10_ACTIVE),
			new TopActiveOptions(
				TargetBasedSearchStrategyType.TOP_10_ACTIVE_DIVERSE
			),
		];
	}

	constructor(public type: TargetBasedSearchStrategyType) {}
}
