<template>
	<div class="collection-list-widget">
		<div
			v-if="collectionDetails"
			class="collection-list-widget--header"
			:class="{
				'collection-list-widget--header--shadow':
					isShadow && !isLoading,
			}"
		>
			<SwitchCardGrid />

			<div class="collection-list-widget--header-right">
				<CollectionItemsSortDropdown />
				<CollectionSettingsDropdown :collection="collectionDetails" />
			</div>
		</div>

		<div
			:class="{
				'collection-list-widget--body-grid': isGrid,
				'collection-list-widget--body-list': !isGrid,
			}"
			@scroll="onScroll"
		>
			<template v-if="collectibles.length > 0 && !isLoading">
				<CollectionItemCard
					v-for="(collection, index) in collectibles"
					:key="'collection-item-key-' + index"
					:collection="collection"
					:index="index"
					:isGrid="isGrid"
				/>

				<bPagination
					:limit="pageLimit.value"
					:total="total"
					:currentPage="currentPage"
					@currentChange="changePage"
					:class="{
						'empty-wrapper--is-grid': isGrid,
					}"
				>
					<template v-slot:select-page>
						<div class="pagination-select-page">
							<bSelect
								v-model="pageLimit"
								:options="pageOptions"
								size="small"
								position="top"
							/>
						</div>
					</template>
				</bPagination>
			</template>
			<bEmptyView
				v-if="collectibles.length === 0 && !isLoading"
				title="No results found."
				description="Try to change your search criteria."
				:class="{
					'empty-wrapper--is-grid': isGrid,
				}"
			>
				<template v-slot:image>
					<bImageEmpty2 :width="200" :height="200" />
				</template>
			</bEmptyView>

			<template v-if="isLoading">
				<div class="empty-view"><bLoader size="4" /></div>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CollectionItemCard } from '@/entities/collection';
import {
	CollectionItemsSortDropdown,
	CollectionSettingsDropdown,
} from '@/features/collections';
import { SwitchCardGrid, switchCardGridModel } from '@/features/hit';
import {
	bEmptyView,
	bImageEmpty2,
	bLoader,
	bPagination,
	bSelect,
} from '@/shared/uikit';
import { ref } from 'vue';
import { collectionDetailsModel } from '../model';
import { collectionItemsListModel } from './model';

const isShadow = ref(false);
const {
	fetchCollectionItems,
	isLoading,
	collectibles,
	total,
	pageLimit,
	pageOptions,
	currentPage,
} = collectionItemsListModel;
const { collectionDetails } = collectionDetailsModel;
const { isGrid } = switchCardGridModel;

const onScroll = (e: any) => {
	const el = e.target;
	isShadow.value = el.scrollTop > 0;
};

const changePage = (page: number) => {
	fetchCollectionItems(page);
};
</script>

<style lang="stylus" scoped>
.collection-list-widget
	width 100%
	height 100%
	position relative
	&--header
		position sticky
		padding 1.25rem 1rem
		background white
		display flex
		flex-direction row
		justify-content space-between
		align-items center
		z-index 20
		.collection-list-widget--header-right
			display flex
			flex-direction row
			justify-content flex-start
			align-items center
			gap 0.5rem
	.collection-list-widget--header--shadow
		border-radius 1rem
		box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05);
	&--body-list
		overflow-y auto
		overflow-x hidden
		height calc(100vh - 11.25rem)
		padding 1rem
		padding-bottom 6rem
		display flex
		flex-direction column
		align-items center
		justify-content flex-start
		gap 1.25rem
		&::-webkit-scrollbar
			width 0.25rem
		.footnoteRegular
			color var(--neutral-600)
		.empty-view
			width 100%
			height 100%
			display flex
			flex-direction column
			align-items center
			justify-content center
	&--body-grid
		overflow-y auto
		overflow-x hidden
		height calc(100vh - 11.25rem)
		padding 1rem
		padding-bottom 6rem
		display grid
		grid-template-columns repeat(3, minmax(0, 1fr))
		grid-template-rows min-content
		gap 1rem
		&::-webkit-scrollbar
			width 0.25rem
		.footnoteRegular
			color var(--neutral-600)
		.empty-wrapper--is-grid
			grid-column 1 / -1
		.empty-view
			grid-column-start 1
			grid-column-end -1
</style>
