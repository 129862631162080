<template>
	<div class="competitive-page">
		<iframe
			ref="iframe"
			:src="iframeSrc"
			frameborder="0"
			allowtransparency
			@load="onIframeLoad"
		></iframe>

		<div v-if="!iframeLoaded" class="empty-view">
			<bLoader size="4" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	navigationHeaderModel,
	navigationTabsModel,
} from '@/features/navigation';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { bLoader } from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const { title, description, clear: clearNavigation } = navigationHeaderModel;
const iframeLoaded = ref(true);
const iframeSrc =
	'https://bioptic.metabaseapp.com/public/dashboard/67bc47a6-a7f7-417b-8ea7-ea529278cd88';

onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.name,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	if (router.currentRoute.value.name === 'competitive') {
		title.value = 'Ligands Dashboard';
		description.value = 'Ligand data hub';
		const { addTabIfNotExists, tabs } = navigationTabsModel;

		addTabIfNotExists(
			NavigationTabEntity.fromObject({
				id: `new-competitive-chat-tab`,
				title: 'Ligands Dashboard',
				description: 'Ligand data hub',
				path: `/competitive`,
				type: NavigationTabType.TARGET,
				tabs: tabs.value.length + 1,
				isPinned: false,
			})
		);
		iframeLoaded.value = false;
	}
};

const onIframeLoad = () => {
	iframeLoaded.value = true;
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.competitive-page
	width 100vw
	height calc(100vh - 6.25rem)
	iframe
		width 100%
		height 100%
		border none
		footer
			display none !important
	.empty-view
		position relative
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
