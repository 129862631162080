<template>
	<div
		class="admet-widget-wrapper"
		:class="{
			'admet-widget-wrapper--full': isFull,
			'admet-widget-wrapper--closed': !isOpenedFilters,
		}"
	>
		<bTooltip
			v-if="attributes.length > 0"
			class="open-side-filters--button"
			:id="`open-side-filters-tooltip`"
			:hoverTitle="
				isOpenedFilters ? 'Close side filters' : 'Open side filters'
			"
			type="primary"
		>
			<template v-slot:body>
				<bUnstyledButton @click="isOpenedFilters = !isOpenedFilters">
					<template v-slot:content>
						<IconSidePanel size="1.25rem" />
					</template>
				</bUnstyledButton>
			</template>
		</bTooltip>
		<div v-if="isOpenedFilters" class="admet-widget-aside">
			<ADMETFiltersHeader
				:isShadow="isShadow"
				:total="total"
				:currentCount="filteredTableData.length"
				@onResetFilters="setDefaultFilters"
			/>
			<div class="admet-widget-aside--body" @scroll="onScroll">
				<bHistogramSlider
					v-for="(filter, index) in attributes"
					:key="'filter-histogramm-key' + filter.type"
					:model-value="filter"
					:title="getSearchAttributeTypeLabel(filter.type)"
					:min="filter.min"
					:max="filter.max"
					:absoluteMin="filter.absolute_min"
					:absoluteMax="filter.absolute_max"
					:items="filter.histogram"
					:index="index"
					:byStep="
						filter.type === SearchAttributeType.WEIGHT ||
						filter.type === SearchAttributeType.HBA ||
						filter.type === SearchAttributeType.HBD
							? 1
							: -1
					"
					:isForceOpen="true"
					@update:modelValue="
						(newValue: any) => {
							filter.max = newValue.max;
							filter.min = newValue.min;
							onReload();
						}
					"
					:isDisabled="filter.isDisabled"
				/>
			</div>
		</div>
		<div
			class="admet-widget-body"
			:class="{
				'admet-widget-body--full': attributes.length === 0,
			}"
		>
			<ADMETTableResults
				class="admet-table-container"
				ref="tableRef"
				:is-loading="isLoading"
				:table-data="tableData"
				:columns="columns"
				:current-page="currentPage"
				:limit="pageLimit.value"
			/>
		</div>
		<div class="admet-widget-footer">
			<bButton label="Download file" type="primary" @onClick="openLink()">
				<template v-slot:icon-left>
					<IconDownload size="1.125rem" color="white" />
				</template>
			</bButton>

			<bCopy
				copied-title="Copied!"
				hover-title="Share Link"
				:link="shareLink"
				:isForce="true"
			/>
			<bPagination
				v-if="!isLoading"
				:limit="pageLimit.value"
				:total="filteredTableData.length"
				:currentPage="currentPage"
				@currentChange="changePage"
			>
				<template v-slot:select-page>
					<div class="pagination-select-page">
						<bSelect
							v-model="pageLimit"
							:options="pageOptions"
							size="small"
							position="top"
							@update:model-value="changePage(1)"
						/>
					</div>
				</template>
			</bPagination>
		</div>
	</div>
</template>

<script setup lang="ts">
import { getSearchAttributeTypeLabel, SearchAttributeType } from '@/shared/api';
import { getLinkFromText } from '@/shared/lib';
import {
	bButton,
	bCopy,
	bHistogramSlider,
	bPagination,
	bSelect,
	bTooltip,
	bUnstyledButton,
	IconDownload,
	IconSidePanel,
} from '@/shared/uikit';
import { ADMETTableResults } from '@/widgets/chats';
import { computed, ref } from 'vue';
import ADMETFiltersHeader from './ADMETFiltersHeader.vue';
import { admetTableResultModel } from './model';

const tableRef = ref<any>(null);
const isOpenedFilters = ref<boolean>(false);
const isShadow = ref<boolean>(false);

const shareLink = computed(() => {
	return `${window.origin}/chat-table-result?fileUrl=${encodeURIComponent(getLinkFromText(url.value))}`;
});

const props = defineProps({
	isFull: {
		type: Boolean,
		default: true,
	},
});

const {
	isLoading,
	tableData,
	changePage: changeTablePage,
	columns,
	currentPage,
	pageLimit,
	total,
	pageOptions,
	url,
	attributes,
	updateTableData,
	filteredTableData,
	setDefaultFilters,
	downloadLink,
} = admetTableResultModel;

const changePage = (page: number) => {
	changeTablePage(page);

	tableRef.value?.updateScroll();
};

const openLink = () => {
	window.open(downloadLink.value, '_blank');
};

const onReload = () => {
	updateTableData();
};

const onScroll = (e: any) => {
	const el = e.target;
	isShadow.value = el.scrollTop > 0;
};
</script>

<style lang="stylus">
.admet-widget-wrapper
	position relative
	height 100%
	display grid
	grid-template-columns 20rem calc(95vw - 22rem)
	grid-template-rows calc(100vh - 12rem) 4rem
	&.admet-widget-wrapper--full
		grid-template-columns 20rem calc(100vw - 20rem)
		grid-template-rows calc(100vh - 10.25rem) 4rem
		.open-side-filters--button
			left 0.5rem
	&.admet-widget-wrapper--closed
		grid-template-columns calc(95vw - 2rem)
		&.admet-widget-wrapper--full
			grid-template-columns 100vw
		.admet-widget-body
			grid-column-start 1
			grid-column-end 2
			grid-row-start: 1;
			grid-row-end: 2;
			margin-left 2rem
		.admet-widget-footer
			grid-column-start 1
			grid-column-end 2
			grid-row-start: 2;
			grid-row-end: 3;
	.admet-widget-aside
		grid-column-start 1
		grid-column-end 2
		grid-row-start: 1;
		grid-row-end: 3;
		position relative
		&--body
			overflow-y auto
			width 100%
			height calc(100vh - 6.25rem)
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			gap 0.75rem
			padding 6rem 1rem
			padding-bottom 3rem
			&::-webkit-scrollbar
				display none
	.admet-widget-body
		margin-left 0rem
		grid-column-start 2
		grid-column-end 3
		grid-row-start: 1;
		grid-row-end: 2;
		padding 1rem
		&.admet-widget-body--full
			margin-left 0 !important
	.admet-widget-footer
		grid-column-start 2
		grid-column-end 3
		grid-row-start: 2;
		grid-row-end: 3;
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		gap 0.5rem
		padding 0 1rem
.open-side-filters--button
	position absolute
	top 1rem
	left 0rem
	z-index 100
	.b-unstyled-button
		width 2rem
		height 2rem
		padding 0.25rem
		border-radius 0.5rem
		display flex
		align-items center
		justify-content center
		&:hover
			background-color var(--neutral-200)
</style>
