import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { UserService } from '@/shared/api';
import {
	OcularUser,
	type OrganizationEntity,
} from '@/shared/api/user/entities';
import { ocularRestErrorHandler } from '@/shared/lib';
import { pageLoaderModel } from '@/shared/uikit';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	user: OcularUser | null;
	currentOrganization: OrganizationEntity | null;
}
const data: IViewModel = {
	user: null,
	currentOrganization: null,
};

const state = reactive(data);

async function fetchUser(isForse = false) {
	if (state.user && !isForse) {
		return;
	}

	if (!isForse) {
		pageLoaderModel.isLoading.value = true;
	}

	try {
		const user = localStorage.getItem('currentUser') ?? '';

		const service = new UserService();
		const result = await service.getUser();
		state.user = result;

		localStorage.setItem('currentUser', JSON.stringify(result));
		Analytics.identify();
	} catch (error: any) {
		if (error?.response?.status === 404) {
			router.push('/onboarding');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		if (!isForse) {
			pageLoaderModel.isLoading.value = false;
		}
	}
}

async function createUser(
	name: string,
	role: string,
	haveYouHeardAboutUs: string,
	referralUserId: string | null = null
) {
	try {
		const service = new UserService();
		const result = await service.createUser(name, referralUserId);
		state.user = result;

		Analytics.send(AnalyticsEvents.Onboarding.CREATE_USER_SUCCESS, {
			userId: result.id,
			role: role,
			heardAboutUs: haveYouHeardAboutUs,
		});

		localStorage.setItem('currentUser', JSON.stringify(result));
		Analytics.identify();
	} catch (error) {
		ocularRestErrorHandler(error);
		Analytics.send(AnalyticsEvents.Onboarding.CREATE_USER_FAILURE);
	} finally {
		router.push('/');
	}
}

const userEmail = computed(() => {
	return state.user?.email ?? '';
});

export const ocularUserModel = {
	...toRefs(state),
	fetchUser,
	createUser,
	userEmail,
};
