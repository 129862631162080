import {
	NoveltyFilterMethodType,
	getNoveltyFilterMethodTypeString,
} from '@/shared/api';

export class NoveltyFilterOptions {
	get name(): string {
		return getNoveltyFilterMethodTypeString(this.type);
	}

	static get options(): NoveltyFilterOptions[] {
		return [
			new NoveltyFilterOptions(NoveltyFilterMethodType.TANIMOTO_ECFP),
		];
	}

	constructor(public type: NoveltyFilterMethodType) {}
}
