<template>
	<div class="smiles-viewer" :id="id">
		<div v-if="is3D" class="smiles-viewer smiles-viewer--3d">
			<ThreeDScene
				v-if="sdfData"
				:sdfData="sdfData"
				id="3d-scene-viwer"
			/>
		</div>
		<div v-else class="smiles-viewer smiles-viewer--2d">
			<SmilesScene :smiles="props.smiles" />
		</div>

		<div class="smiles-viewer-switch-control">
			<SwitchScene v-model="is3D" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { smilesToSDFData } from '@/shared/lib/rdkit';
import { defineProps, onUpdated, ref, watch } from 'vue';
import SmilesScene from './SmilesScene.vue';
import SwitchScene from './SwitchScene.vue';
import ThreeDScene from './ThreeDScene.vue';

const is3D = ref<boolean>(false);
const sdfData = ref<string>('');

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	smiles: {
		type: String,
		required: true,
	},
});

watch(
	() => is3D.value,
	() => {
		if (is3D.value) {
			renderSmiles();
		} else {
			drawSmiles();
		}
	}
);

onUpdated(() => {
	if (is3D.value) {
		renderSmiles();
	} else {
		drawSmiles();
	}
});
const drawSmiles = () => {
	console.log('drawSmiles');
};

const renderSmiles = async () => {
	sdfData.value = await smilesToSDFData(props.smiles);
};
</script>

<style lang="stylus" scoped>
.smiles-viewer
	position relative
	width 100%
	height 400px
	border: 0.5px solid var(--neutral-100);
	background: #fff;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	border-radius: 0.5rem;
	overflow hidden
	.smiles-viewer-switch-control
		position absolute
		bottom 0.5rem
		left 0.5rem
		z-index 1
	.smiles-viewer
		position absolute
		bottom 0
		left 0
		right 0
		top 0
		&.smiles-viewer--2d
			display flex
			flex-direction row
			align-items center
			justify-content center
		// &.smiles-viewer--3d
		// 	background red
</style>
