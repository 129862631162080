<template>
	<div class="download-job-dropdown-item" @click="onClick">
		<IconDownload size="1.125rem" />
		Download all results
	</div>
</template>

<script setup lang="ts">
import { IconDownload } from '@/shared/uikit/';
import { downloadSearchResultsModel } from './model';
const emit = defineEmits(['onClick']);

const { downloadById, isDownloading, loadingIds } = downloadSearchResultsModel;

const props = defineProps({
	jobId: {
		type: String,
		required: true,
	},
});

const onClick = () => {
	downloadById(props.jobId);
	emit('onClick');
};
</script>
<style lang="stylus" scoped>
.download-job-dropdown-item
	width 100%
	display grid
	grid-template-columns auto 1fr
	align-items center
	justify-content flex-start
	padding: 0.625rem 1rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	gap 0.5rem
	color var(--neutral-600)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
