<template>
	<div class="b-text-button" @click.stop="clicked" :class="typeClass">
		<div class="b-text-button-label">{{ label }}</div>
	</div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	type: { type: String, required: false, default: 'primary' },
	size: { type: String, required: false, default: 'medium' },
	label: { type: String, required: false, default: '' },
	isDisabled: { type: Boolean, required: false, default: false },
});

const typeClass = computed(() => {
	var className: string = `b-text-button__${props.type.toLowerCase()}`;
	className += ` b-text-button__${props.size.toLowerCase()}`;

	if (props.isDisabled) {
		className += ' b-text-button__disabled';
	}

	return className;
});

const clicked = () => {
	emit('onClick');
};
</script>

<style lang="stylus">

.b-text-button
    user-select none
    padding 0.625rem 1rem
    border-radius: 8px;
    display flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    gap 6px
    transition all .25s
    &:hover
        cursor pointer
    .b-text-button-label
        color white
        user-select: none
        font-family: var(--font-family)
        white-space: nowrap;
        font-weight: 500;
        line-height: 1.25rem;
        letter-spacing: 0.00625rem;
    &__medium
        height 2.5rem
        border-radius 62.4375rem
        .b-text-button-label
            font-size: 0.875rem
    &__danger
        background transparent
        .b-text-button-label
            color var(--danger-600)
        &:hover
            background: var(--danger-100)
    &__primary
        .b-text-button-label
            color var(--primary-600)
        &:hover
            background: var(--primary-100)
    &__neutral
        .b-text-button-label
            color var(--neutral-600)
        &:hover
            background: var(--neutral-100)
</style>
