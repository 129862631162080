<template>
	<div class="lbss-catalog-list-container">
		<div class="lbss-catalog-list-container--info">
			<div class="subheadlineBold">Select search database</div>
			<div class="bodySmallRegular">
				Each database has a different search time, pay attention to that
			</div>
		</div>
		<div v-if="catalogs.length > 0 && !isLoading" class="lbss-catalog-list">
			<CatalogCard
				v-for="catalog in catalogs"
				:key="catalog.id"
				:catalog="catalog"
				:isSelected="catalog.id === props.selectedCatalogId"
				@onClick="() => $emit('onSelect', catalog)"
			/>
		</div>

		<bEmptyView
			v-if="catalogs.length === 0 && !isLoading"
			title="Sorry, no catalogs found"
			description="Please try again later"
		>
			<template v-slot:image>
				<bImageEmpty2 :width="100" :height="100" />
			</template>
		</bEmptyView>

		<template v-if="isLoading">
			<div class="empty-view"><bLoader size="4" /></div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { CatalogCard } from '@/entities/catalog';
import { bEmptyView, bImageEmpty2, bLoader } from '@/shared/uikit';
import { onMounted } from 'vue';
import { lbssCatalogListModel } from './model';

const emit = defineEmits(['onSelect']);

const { fetchCatalogs, catalogs, isLoading } = lbssCatalogListModel;

const props = defineProps({
	topK: {
		type: Number,
		required: true,
	},
	queriesCount: {
		type: Number,
		required: true,
	},
	selectedCatalogId: {
		type: String,
		required: false,
	},
	isStructureSearch: {
		type: Boolean,
		required: false,
	},
});

onMounted(() => {
	fetchCatalogs(props.topK, props.queriesCount, props.isStructureSearch);
});
</script>

<style lang="stylus" scoped>
.lbss-catalog-list-container
	width 100%
	display flex
	flex-direction column
	gap 1rem
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem

	.lbss-catalog-list
		display grid
		grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
		gap 0.75rem
		justify-content center
		transition all 0.25s
		&:hover
			cursor pointer
			transition all 0.25s
</style>
