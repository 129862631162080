<template>
	<bModal
		v-if="jobId.length > 0"
		type="danger"
		title="Are you sure you want to delete this job?"
		message="You won't be able to restore this job later"
		:width="400"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bTextButton label="Cancel" type="neutral" @onClick="onClose" />
			<bButton
				label="Delete"
				type="danger"
				:isGhost="true"
				:isLoading="isLoading"
				@onClick="deleteJob"
			>
				<template v-slot:icon-left>
					<IconTrash
						size="1.25rem"
						:color="ThemeColors.danger[600]"
					/>
				</template>
			</bButton>
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { ThemeColors, bButton, bModal, bTextButton } from '@/shared/uikit/';
import IconTrash from '@/shared/uikit/components/icons/IconTrash.vue';
import { deleteJobModel } from './model';

const { jobId, deleteJob, isLoading } = deleteJobModel;

const onClose = () => {
	jobId.value = '';
};
</script>

<style lang="stylus">
// .b-modal-header
// 	.bodyRegular
// 		text-align left !important
</style>
