<template>
	<bTooltip
		:id="`tooltip-${id}`"
		alignDirection="center"
		:hoverTitle="'Open in ' + data.title"
		type="primary"
	>
		<template v-slot:body>
			<bUnstyledButton @onClick="onClickExternalLink">
				<template v-slot:content>
					<IconExternalLink :size="size" :color="color" />
				</template>
			</bUnstyledButton>
		</template>
	</bTooltip>
</template>

<script setup lang="ts">
import type { ExternalHitMetadata } from '@/shared/api';
import {
	IconExternalLink,
	ThemeColors,
	bTooltip,
	bUnstyledButton,
} from '@/shared/uikit';
import type { PropType } from 'vue';

const emit = defineEmits(['onClick']);

const props = defineProps({
	data: {
		type: Object as PropType<ExternalHitMetadata>,
		required: true,
	},
	id: {
		type: String,
		required: true,
	},
	size: {
		type: String,
		default: '1.25rem',
	},
	color: {
		type: String,
		default: ThemeColors.neutral[600],
	},
});

const onClickExternalLink = (e: Event) => {
	window.open(props.data.url, '_blank');
	emit('onClick');
};
</script>
