import { Analytics, AnalyticsEvents } from '@/app/providers';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isGrid: boolean;
}
const data: IViewModel = {
	isGrid: localStorage.getItem('isGrid') === 'true',
};

const state = reactive(data);

function toggleGrid() {
	state.isGrid = !state.isGrid;

	localStorage.setItem('isGrid', state.isGrid ? 'true' : 'false');

	Analytics.send(AnalyticsEvents.App.CARD_IS_GRID, {
		isGrid: state.isGrid,
	});
}

export const switchCardGridModel = {
	...toRefs(state),
	toggleGrid,
};
