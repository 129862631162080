import { SearchesAttributeEntity } from '.';
import { CatalogEntity } from '../catalogs';
import { TargetEntity } from '../targets';

export const MAX_TOP_K: number = 10000;
export const DEFAULT_TOP_K: number = 500;

export enum MetricType {
	Tanimoto = 'tanimoto',
	Activity = 'activity',
}

export enum JobStatus {
	Scheduled = 'scheduled',
	Running = 'running',
	Ready = 'ready',
}

export enum SearchType {
	LIGAND = 'ligand',
	TARGET = 'target',
}

export class JobEntityResponse {
	count: number;
	jobs: JobEntity[];

	constructor(count: number, jobs: JobEntity[]) {
		this.count = count;
		this.jobs = jobs;
	}

	static fromObject(data: any): JobEntityResponse {
		return new JobEntityResponse(
			data.count,
			data.jobs.map((job: any) => JobEntity.fromObject(job))
		);
	}
}

export class JobParameters {
	constructor(
		public type: SearchType,
		public top_k: number,
		public filters: any[] = []
	) {}

	static fromObject(data: any): LigandJobParameters | TargetJobParameters {
		if (data.type === SearchType.LIGAND) {
			return new LigandJobParameters(
				data.type,
				data.top_k,
				data.ligand_smiles,
				data.filters
			);
		} else {
			return new TargetJobParameters(
				data.type,
				data.top_k,
				TargetEntity.fromObject(data.target),
				data.filters
			);
		}
	}
}

export class LigandJobParameters extends JobParameters {
	constructor(
		type: SearchType,
		top_k: number,
		public ligand_smiles: string[],
		public filters: any[] = []
	) {
		super(type, top_k);
		this.ligand_smiles = ligand_smiles;
	}
}

export class TargetJobParameters extends JobParameters {
	constructor(
		type: SearchType,
		top_k: number,
		public target: TargetEntity,
		public filters: any[] = []
	) {
		super(type, top_k);
		this.target = target;
	}
}

export enum JobState {
	QUEUED = 'queued',
	ACTIVE = 'active',
	ENRICHMENT = 'enrichment',
	SUCCESS = 'success',
	FAILURE = 'failure',
}

export class JobEntity {
	constructor(
		public id: string,
		public catalog: CatalogEntity,
		public state: JobState,
		public is_viewed: boolean,
		public created_at: string,
		public parameters: JobParameters
	) {}

	static fromObject(
		data: any
	): QueuedJobEntity | ActiveJobEntity | SucceededJobEntity {
		if (data.state === JobState.QUEUED)
			return QueuedJobEntity.fromObject(data);

		if (
			data.state === JobState.ACTIVE ||
			data.state === JobState.ENRICHMENT
		) {
			return ActiveJobEntity.fromObject(data);
		}

		return SucceededJobEntity.fromObject(data);
	}

	get formattedCreatedAt(): string {
		return new Date(this.created_at).toLocaleString();
	}

	get statusTitle(): string {
		switch (this.state) {
			case JobState.QUEUED:
				return 'Scheduled';
			case JobState.ACTIVE:
				return 'Running';
			case JobState.ENRICHMENT:
				return 'Running';
			case JobState.SUCCESS:
				return 'Ready';
			case JobState.FAILURE:
				return 'SOMETHING WENT WRONG';
			default:
				return 'Unknown';
		}
	}

	get statusColor(): string {
		switch (this.state) {
			case JobState.QUEUED:
				return 'warning';
			case JobState.ACTIVE:
				return 'secondary';
			case JobState.ENRICHMENT:
				return 'secondary';
			case JobState.SUCCESS:
				return 'primary';
			case JobState.FAILURE:
				return 'danger';
		}
	}

	get allSmiles(): string[] {
		if (this.parameters instanceof LigandJobParameters) {
			return this.parameters.ligand_smiles;
		} else {
			return [];
		}
	}

	get title(): string {
		if (this.parameters instanceof LigandJobParameters) {
			return 'Ligand Based Search';
		} else {
			return 'Target Driven Search';
		}
	}

	get query(): string {
		if (this.parameters instanceof LigandJobParameters) {
			return this.parameters.ligand_smiles.join(', ');
		}

		if (this.parameters instanceof TargetJobParameters) {
			return this.parameters.target.name;
		}

		return '';
	}

	get totalHits(): number {
		if (this instanceof SucceededJobEntity) {
			return this.hits_count;
		}

		return 0;
	}

	get totalClusters(): number {
		if (this instanceof SucceededJobEntity) {
			return this.clusters_count;
		}

		return 0;
	}
}

export class SucceededJobEntity extends JobEntity {
	constructor(
		id: string,
		catalog: CatalogEntity,
		state: JobState,
		is_viewed: boolean,
		created_at: string,
		parameters: JobParameters,
		public started_at: string,
		public finished_at: string,
		public attributes: SearchesAttributeEntity[],
		public hits_count: number,
		public clusters_count: number
	) {
		super(id, catalog, state, is_viewed, created_at, parameters);
		this.started_at = started_at;
		this.finished_at = finished_at;
		this.attributes = attributes;
	}

	static fromObject(data: any): SucceededJobEntity {
		const attributes =
			data?.attributes?.map((attribute: any) =>
				SearchesAttributeEntity.fromObject(attribute)
			) ?? [];

		return new SucceededJobEntity(
			data.id,
			CatalogEntity.fromObject(data.catalog),
			data.state,
			data.is_viewed,
			data.created_at,
			JobParameters.fromObject(data.parameters),
			data.started_at,
			data.finished_at,
			attributes,
			data.hits_count,
			data.clusters_count
		);
	}
}

export class FailedJobEntity extends JobEntity {
	constructor(
		id: string,
		catalog: CatalogEntity,
		state: JobState,
		is_viewed: boolean,
		created_at: string,
		parameters: JobParameters,
		public started_at: string,
		public finished_at: string,
		public attributes: SearchesAttributeEntity[] = [],
		public hits_count: number,
		public clusters_count: number
	) {
		super(id, catalog, state, is_viewed, created_at, parameters);
		this.started_at = started_at;
		this.finished_at = finished_at;
	}

	static fromObject(data: any): FailedJobEntity {
		return new FailedJobEntity(
			data.id,
			CatalogEntity.fromObject(data.catalog),
			data.state,
			data.is_viewed,
			data.created_at,
			JobParameters.fromObject(data.parameters),
			data.started_at,
			data.finished_at,
			data.attributes,
			data.hits_count,
			data.hits_count
		);
	}
}
export class QueuedJobEntity extends JobEntity {
	constructor(
		id: string,
		catalog: CatalogEntity,
		state: JobState,
		is_viewed: boolean,
		created_at: string,
		parameters: JobParameters,
		public estimated_waiting_time: number
	) {
		super(id, catalog, state, is_viewed, created_at, parameters);
		this.estimated_waiting_time = estimated_waiting_time;
	}

	static fromObject(data: any): QueuedJobEntity {
		return new QueuedJobEntity(
			data.id,
			CatalogEntity.fromObject(data.catalog),
			data.state,
			data.is_viewed,
			data.created_at,
			JobParameters.fromObject(data.parameters),
			data.estimated_waiting_time
		);
	}
}

export class ActiveJobEntity extends JobEntity {
	constructor(
		id: string,
		catalog: CatalogEntity,
		state: JobState,
		is_viewed: boolean,
		created_at: string,
		parameters: JobParameters,
		public started_at: string,
		public estimated_waiting_time: number,
		public progress: number
	) {
		super(id, catalog, state, is_viewed, created_at, parameters);
		this.started_at = started_at;
		this.estimated_waiting_time = estimated_waiting_time;
		this.progress = progress;
	}

	static fromObject(data: any): ActiveJobEntity {
		return new ActiveJobEntity(
			data.id,
			CatalogEntity.fromObject(data.catalog),
			data.state,
			data.is_viewed,
			data.created_at,
			JobParameters.fromObject(data.parameters),
			data.started_at,
			data.estimated_waiting_time,
			data.progress
		);
	}
}
