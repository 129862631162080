<template>
	<DraggableFormTemplate
		:id="props.id"
		:input="props.input"
		:output="props.output"
		:data="props.data"
		:isLoading="props.isLoading"
		:type="props.type"
		:comment="props.comment"
	>
		<template v-slot:input>
			<PipelineTopKSlider v-model="topK" :max="data?.top_k" />
		</template>
		<template v-slot:output> </template>
	</DraggableFormTemplate>
</template>

<script lang="ts" setup>
import { PipelineTopKSlider } from '@/features/pipeline';
import { StepType } from '@/shared/api';
import { DraggableFormTemplate } from '@/widgets/pipeline';
import { onMounted, ref, type PropType } from 'vue';

const topK = ref<number>(10000);
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	input: {
		type: Array as PropType<String[]>,
		required: true,
	},
	output: {
		type: Array as PropType<String[]>,
		required: true,
	},
	data: {
		type: Object as PropType<any>,
		required: true,
	},
	isLoading: {
		type: Boolean,
		required: false,
	},
	type: {
		type: String as PropType<StepType>,
		required: true,
	},
	comment: {
		type: String,
		required: false,
	},
});

onMounted(() => {
	topK.value = props.data?.top_k;
});
</script>

<style lang="stylus" scoped></style>
