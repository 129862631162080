import { generateUuid } from '@/shared/lib';
import moment from 'moment';
import { getCardSizeByStepType } from './PipelineService';

export enum StepType {
	CATALOG_LOADER = 'catalog_loader',
	DOCKING = 'docking',
	TARGET_BASED_SEARCH = 'target_based_search',
	LIGAND_BASED_SEARCH = 'ligand_based_search',
	ATTRIBUTE_FILTER = 'attribute_filter',
	NOVELTY_FILTER = 'novelty_filter',
}

export enum DockingProviderType {
	DIFF_DOCK = 'diff_dock',
	AMBER = 'amber',
	NEUROSNAP_AI = 'neurosnap_ai',
}

export enum TargetBasedSearchStrategyType {
	TOP_5_ACTIVE = 'top_5_active',
	TOP_10_ACTIVE = 'top_10_active',
	TOP_10_ACTIVE_DIVERSE = 'top_10_active_diverse',
}

export enum NoveltyFilterMethodType {
	TANIMOTO_ECFP = 'tanimoto_ecfp',
}

// Create mappings for string representations
const StepTypeStringMapping: { [key in StepType]: string } = {
	[StepType.CATALOG_LOADER]: 'Catalog Loader',
	[StepType.DOCKING]: 'Docking',
	[StepType.TARGET_BASED_SEARCH]: 'Target Based Search',
	[StepType.LIGAND_BASED_SEARCH]: 'Ligand Based Search',
	[StepType.ATTRIBUTE_FILTER]: 'Attribute Filter',
	[StepType.NOVELTY_FILTER]: 'Novelty Filter',
};

const DockingProviderTypeStringMapping: {
	[key in DockingProviderType]: string;
} = {
	[DockingProviderType.DIFF_DOCK]: 'Diff Dock',
	[DockingProviderType.AMBER]: 'Amber',
	[DockingProviderType.NEUROSNAP_AI]: 'Neurosnap AI',
};

const TargetBasedSearchStrategyTypeStringMapping: {
	[key in TargetBasedSearchStrategyType]: string;
} = {
	[TargetBasedSearchStrategyType.TOP_5_ACTIVE]: 'Top 5 Active',
	[TargetBasedSearchStrategyType.TOP_10_ACTIVE]: 'Top 10 Active',
	[TargetBasedSearchStrategyType.TOP_10_ACTIVE_DIVERSE]:
		'Top 10 Active Diverse',
};

const NoveltyFilterMethodTypeStringMapping: {
	[key in NoveltyFilterMethodType]: string;
} = {
	[NoveltyFilterMethodType.TANIMOTO_ECFP]: 'Tanimoto ECFP',
};

// Functions to get custom string representations
export function getStepTypeString(stepType: StepType): string {
	return StepTypeStringMapping[stepType];
}

export function getDockingProviderTypeString(
	dockingProviderType: DockingProviderType
): string {
	return DockingProviderTypeStringMapping[dockingProviderType];
}

export function getTargetBasedSearchStrategyTypeString(
	strategyType: TargetBasedSearchStrategyType
): string {
	return TargetBasedSearchStrategyTypeStringMapping[strategyType];
}

export function getNoveltyFilterMethodTypeString(
	methodType: NoveltyFilterMethodType
): string {
	return NoveltyFilterMethodTypeStringMapping[methodType];
}

export enum PipelineMessageType {
	INPUT = 'input',
	OUTPUT = 'output',
	THOUGHT = 'thought',
}

export class PipelineMessageEntity {
	constructor(
		public id: string,
		public content: string,
		public type: PipelineMessageType,
		public date: Date = new Date()
	) {}

	get formattedDate(): string {
		return moment(this.date).format('DD MMMM HH:mm:ss');
	}

	static fromObject(obj: any): PipelineMessageEntity {
		return new PipelineMessageEntity(
			obj?.id ?? generateUuid(),
			obj.content,
			obj.type,
			obj?.date ? new Date(obj.date) : new Date()
		);
	}
}

export class ForeignObjectEntity {
	constructor(
		public id: string,
		public type: StepType,
		public data: any,
		public width: number,
		public height: number,
		public input: string[],
		public output: string[],
		public comment: string | null,
		public isLoading: boolean = false
	) {}

	static fromObject(obj: any): ForeignObjectEntity {
		return new ForeignObjectEntity(
			obj.id,
			obj.type,
			obj.data,
			obj.width,
			obj.height,
			obj.input,
			obj.output,
			obj.comment,
			obj?.isLoading ?? false
		);
	}

	updateTopK(topK: number): void {
		if (this.type === StepType.TARGET_BASED_SEARCH) {
			this.data.top_k = topK;
		}
	}

	updateAttributeFilter(filters: any[]): void {
		if (this.type === StepType.ATTRIBUTE_FILTER) {
			this.data.filters = filters;
			const size = getCardSizeByStepType(this.type, this.data);
			this.width = size.width;
			this.height = size.height;
		}
	}
}
