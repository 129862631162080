export function transformCoordinates(
	x: number,
	y: number,
	svg: SVGSVGElement
): { x: number; y: number } {
	const svgPoint = svg.createSVGPoint();
	svgPoint.x = x;
	svgPoint.y = y;
	const transformedPoint = svgPoint.matrixTransform(
		svg.getScreenCTM()?.inverse()
	);
	return { x: transformedPoint.x, y: transformedPoint.y };
}
export function pointDistanceToBezierCurve(
	point: { x: number; y: number },
	path: string
): number {
	// Парсинг координат из строки path
	const match = path.match(
		/M (\d+\.?\d*),(\d+\.?\d*) C (\d+\.?\d*),(\d+\.?\d*) (\d+\.?\d*),(\d+\.?\d*) (\d+\.?\d*),(\d+\.?\d*)/
	);
	if (!match) {
		throw new Error('Invalid path');
	}

	const [, x0, y0, x1, y1, x2, y2, x3, y3] = match.map(Number);

	// Функция для вычисления координат точки на кривой Безье
	function bezier(
		t: number,
		p0: number,
		p1: number,
		p2: number,
		p3: number
	): number {
		const mt = 1 - t;
		return (
			mt * mt * mt * p0 +
			3 * mt * mt * t * p1 +
			3 * mt * t * t * p2 +
			t * t * t * p3
		);
	}

	// Функция для вычисления квадрата расстояния между двумя точками
	function distanceSq(
		x1: number,
		y1: number,
		x2: number,
		y2: number
	): number {
		const dx = x1 - x2;
		const dy = y1 - y2;
		return dx * dx + dy * dy;
	}

	// Поиск минимального расстояния от точки до кривой
	let minDistSq = Infinity;
	for (let t = 0; t <= 1; t += 0.01) {
		const bx = bezier(t, x0, x1, x2, x3);
		const by = bezier(t, y0, y1, y2, y3);
		const distSq = distanceSq(point.x, point.y, bx, by);
		if (distSq < minDistSq) {
			minDistSq = distSq;
		}
	}

	return Math.sqrt(minDistSq);
}
