<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 18 18"
		fill="none"
	>
		<g clip-path="url(#clip0_1652_4775)">
			<path
				d="M14.25 9.75H3.75C2.925 9.75 2.25 10.425 2.25 11.25V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V11.25C15.75 10.425 15.075 9.75 14.25 9.75ZM5.25 14.25C4.425 14.25 3.75 13.575 3.75 12.75C3.75 11.925 4.425 11.25 5.25 11.25C6.075 11.25 6.75 11.925 6.75 12.75C6.75 13.575 6.075 14.25 5.25 14.25ZM14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V6.75C2.25 7.575 2.925 8.25 3.75 8.25H14.25C15.075 8.25 15.75 7.575 15.75 6.75V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM5.25 6.75C4.425 6.75 3.75 6.075 3.75 5.25C3.75 4.425 4.425 3.75 5.25 3.75C6.075 3.75 6.75 4.425 6.75 5.25C6.75 6.075 6.075 6.75 5.25 6.75Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1652_4775">
				<rect width="18" height="18" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
