<template>
	<div
		class="google-button"
		:class="{ 'google-button--disabled': isLoading }"
		@click="login"
	>
		<IconReload
			class="spin google-button--icon"
			v-if="isLoading"
			size="1.25rem"
			:color="ThemeColors.neutral[400]"
		/>
		<IconGoogle class="google-button--icon" v-else size="1.25rem" />

		{{ $t('auth.button.continueWithGoogle') }}
	</div>
</template>

<script setup lang="ts">
import { IconGoogle, IconReload, ThemeColors } from '@/shared/uikit';
import { buttonModel } from './model';

const { login, isLoading } = buttonModel;
</script>

<style lang="stylus" scoped>
.google-button
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 0.625rem 0.875rem;
    gap: 0.625rem;
    align-self: stretch;
    border-radius: 0.625rem;
    transition: all 0.2s ease-in-out;
    border-radius:  62.4375rem;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    font-family: var(--font-family);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.00625rem;
    color var(--primary-600);
    position: relative;
    &:hover
        cursor: pointer
        background: var(--neutral-100, #F1F3F6);
        transition: all 0.2s ease-in-out;
    &--disabled
        cursor: not-allowed
        color: var(--neutral-500);
        background: var(--neutral-200, #E7E9ED);
        transition: all 0.2s ease-in-out;

    .google-button--icon
        position: absolute;
        left: 0.5rem;
        top: 0.625rem;
        bottom: 0.625rem;
</style>
