import { router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import { CollectionEntity, CollectionService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { collectionFiltersModel } from './collection-filters-widget';
import { collectionItemsListModel } from './collection-list-widget';

interface IViewModel {
	isLoading: boolean;
	collectionDetails: CollectionEntity | null;
}
const data: IViewModel = {
	isLoading: false,
	collectionDetails: null,
};

const state = reactive(data);

async function fetchCollectionDetails(id: string) {
	state.isLoading = true;
	try {
		state.collectionDetails =
			await CollectionService.getInstance().fetchCollection(id);

		const { setFilters } = collectionFiltersModel;
		setFilters(state.collectionDetails.attributes);
		const { fetchCollectionItems } = collectionItemsListModel;
		fetchCollectionItems(1);
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

function clear() {
	state.collectionDetails = null;
	const { setFilters } = collectionFiltersModel;
	const { clear: clearCollectionItems } = collectionItemsListModel;

	setFilters([]);
	clearCollectionItems();
}

export const collectionDetailsModel = {
	...toRefs(state),
	fetchCollectionDetails,
	clear,
};
