import { reactive, toRefs } from 'vue';
import { ProbesStrategyOptioin } from './entities';

interface IViewModel {
	selectedOption: ProbesStrategyOptioin;
}

const data: IViewModel = {
	selectedOption: ProbesStrategyOptioin.defaultProbe,
};

const state = reactive(data);

export const probesListPreselectModel = {
	...toRefs(state),
};
