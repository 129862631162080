/**
 * ### Event Naming Prefixes
 *
 * Prefixes are used to provide context or indicate the triggering action for an event. Here are some common prefixes that can be considered:
 *
 * 1. **Based on UI Element**:
 *    - `btn_`: for buttons (e.g., `btn_click_create_source`)
 *    - `modal_`: for modals (e.g., `modal_open_delete_source`)
 *    - `nav_`: for navigation elements (e.g., `nav_select_item`)
 *    - `input_`: for input fields (e.g., `input_change_value`)
 *    - `dropdown_`: for dropdown lists (e.g., `dropdown_select_option`)
 *
 * 2. **Based on Action**:
 *    - `view_`: when viewing an element or a page (e.g., `view_product_page`)
 *    - `open_`: when opening something (e.g., `open_settings_modal`)
 *    - `close_`: when closing something (e.g., `close_info_popup`)
 *    - `submit_`: when submitting a form (e.g., `submit_registration_form`)
 *
 * 3. **Based on Functional Module**:
 *    - `auth_`: for authentication-related events (e.g., `auth_login_success`)
 *    - `payment_`: for payment-related events (e.g., `payment_transaction_failed`)
 *    - `profile_`: for user profile events (e.g., `profile_update_avatar`)
 *
 * 4. **Miscellaneous**:
 *    - `error_`: for errors (e.g., `error_404_page`)
 *    - `success_`: for successful actions (e.g., `success_order_placed`)
 *    - `warning_`: for warning events (e.g., `warning_low_balance`)
 *
 * These are general prefixes that can be useful in various contexts. Depending on your application and needs, you might require unique prefixes that are specific to your business or industry.
 */

import mixpanel from 'mixpanel-browser';

export const AnalyticsEvents = {
	App: {
		CLICK_HELP_FRESHDESK: 'click_help_freshdesk',
		CARD_IS_GRID: 'card_is_grid',
		CLICK_NEW_TAB: 'click_new_tab',
		CLICK_TAB_ACTION: 'click_tab_action',
	},
	Logout: {
		LOGOUT: 'btn_click_logout',
		OPEN_LOGOUT_MODAL: 'btn_click_open_logout_modal',
	},
	Login: {
		VIEW_PAGE: 'view_login_page',
		LOGIN_WITH_GOOGLE: 'btn_click_login_with_google',
		LOGIN_WITH_GOOGLE_SUCCESS: 'login_with_google_success',
		LOGIN_WITH_GOOGLE_FAILURE: 'login_with_google_failure',
		LOGIN_WITH_EMAIL: 'btn_click_login_with_email',
		LOGIN_WITH_EMAIL_SUCCESS: 'login_with_email_success',
		LOGIN_WITH_EMAIL_FAILURE: 'login_with_email_failure',
		LOGIN_WITH_MICROSOFT: 'btn_click_login_with_microsoft',
		LOGIN_WITH_MICROSOFT_SUCCESS: 'login_with_microsoft_success',
		LOGIN_WITH_MICROSOFT_FAILURE: 'login_with_microsoft_failure',
	},
	SignUp: {
		VIEW_PAGE: 'view_signup_page',
		SIGN_UP_WITH_GOOGLE: 'btn_click_signup_with_google',
		SIGN_UP_WITH_GOOGLE_SUCCESS: 'signup_with_google_success',
		SIGN_UP_WITH_EMAIL: 'btn_click_signup_with_email',
		SIGN_UP_WITH_EMAIL_SUCCESS: 'signup_with_email_success',
		SIGN_UP_WITH_EMAIL_FAILURE: 'signup_with_email_failure',
		SIGN_UP_WITH_MICROSOFT: 'btn_click_signup_with_microsoft',
		SIGN_UP_WITH_MICROSOFT_SUCCESS: 'signup_with_microsoft_success',
		SIGN_UP_WITH_MICROSOFT_FAILURE: 'signup_with_microsoft_failure',
	},
	ResetPassword: {
		VIEW_PAGE: 'view_reset_password_page',
		SEND_RECOVERY_EMAIL: 'btn_click_send_recovery_email',
		SEND_RECOVERY_EMAIL_SUCCESS: 'send_recovery_email_success',
		SEND_RECOVERY_EMAIL_FAILURE: 'send_recovery_email_failure',
	},
	ExpiredLink: {
		VIEW_PAGE: 'view_expired_link_page',
	},
	CreateNewPassword: {
		VIEW_PAGE: 'view_create_new_password_page',
		CREATE_NEW_PASSWORD: 'btn_click_create_new_password',
		CREATE_NEW_PASSWORD_SUCCESS: 'create_new_password_success',
		CREATE_NEW_PASSWORD_FAILURE: 'create_new_password_failure',
	},
	VerifyEmail: {
		VIEW_PAGE: 'view_verify_email_page',
		VERIFY_EMAIL: 'btn_click_verify_email',
		VERIFY_EMAIL_SUCCESS: 'verify_email_success',
		VERIFY_EMAIL_FAILURE: 'verify_email_failure',
		RESEND_EMAIL: 'btn_click_resend_email',
	},
	Onboarding: {
		OPEN_ONBOARDING_PAGE: 'view_onboarding_page',

		CREATE_USER_SUCCESS: 'create_user_success',
		CREATE_USER_FAILURE: 'create_user_failure',

		SKIP_WALKTHROUGH: 'btn_click_skip_walkthrough',
		FINISH_WALKTHROUGH_STEP: 'btn_click_finish_walkthrough_step',
		FINISH_WALKTHROUGH_SUCCESS: 'finish_walkthrough_success',
	},
	Home: {
		OPEN_HOME_PAGE: 'view_home_page',

		OPEN_SUGGEST_FEATURE_MODAL: 'btn_click_open_suggest_feature_modal',
		CLOSE_SUGGEST_FEATURE_MODAL: 'btn_click_close_suggest_feature_modal',

		OPEN_ACCOUNT_MODAL: 'btn_click_open_account_modal',
		CLOSE_ACCOUNT_MODAL: 'btn_click_close_account_modal',

		CLICK_APPLICATION: 'click_application',
		CLICK_APPLICATION_BY_SEARCH_TYPE: 'click_application_by_search_type',
	},
	Collections: {
		OPEN_COLLECTION_PAGE: 'view_collection_page',
		OPEN_COLLECTIONS_PAGE: 'view_collections_page',

		OPEN_CREATE_COLLECTION_MODAL: 'btn_click_open_create_collection_modal',
		CLOSE_CREATE_COLLECTION_MODAL:
			'btn_click_close_create_collection_modal',

		OPEN_DELETE_COLLECTION_MODAL: 'btn_click_open_delete_collection_modal',
		CLOSE_DELETE_COLLECTION_MODAL:
			'btn_click_close_delete_collection_modal',

		DOWNLOAD_COLLECTION: 'download_collection',

		ADD_MOLECULE_TO_COLLECTION: 'add_molecule_to_collection',
		REMOVE_MOLECULE_FROM_COLLECTION: 'remove_molecule_from_collection',
	},
	Job: {
		OPEN_CREATE_JOB_MODAL: 'btn_click_open_create_job_modal',
		CLOSE_CREATE_JOB_MODAL: 'btn_click_close_create_job_modal',

		CREATE_JOB_SUCCESS: 'create_job_success',
		CREATE_JOB_FAILURE: 'create_job_failure',

		CLICK_DOWNLOAD_ARCHIVE: 'btn_click_download_archive',
		DOWNLOAD_ARCHIVE_SUCCESS: 'download_archive_success',
		DOWNLOAD_ARCHIVE_FAILURE: 'download_archive_failure',
		DOWNLOAD_ARCHIVE_BY_FILTERS: 'download_archive_by_filters',

		VIEW_JOB_RESULTS: 'view_job_results',
	},
	BIOPTIC_VIEW: {
		VIEW_TARGET_DRIVEN_JOBS: 'view_target_driven_jobs',
		VIEW_LIGAND_BASED_JOBS: 'view_ligand_based_jobs',
	},
	CLUSTER: {
		VIEW_CLUSTER_PAGE: 'view_cluster_page',
	},
	Filter: {
		SELECTED_SORT_OPTION: 'selected_sort_option',
		RESET_FILTERS: 'btn_click_reset_filters',

		//REOS - filter
		SELECT_OPTION: 'reos_select_option',
		REMOVE_OPTION: 'reos_remove_option',
		DRAG_START_OPTION: 'reos_drag_start_option',
		DROP_OPTION: 'reos_drop_option',
		OPEN_REOS_FILTER: 'reos_open_filter',
		CLOSE_REOS_FILTER: 'reos_close_filter',

		// CLUSTEring

		SELECT_CLUSTERING_TYPE: 'select_clustering_type',

		// DRUG LIKENESS

		SELECTED_DRUG_LIKENESS_PREFILTER: 'selected_drug_likeness_prefilter',
		SELECTED_DRUG_LIKENESS_POSTFILTER: 'selected_drug_likeness_postfilter',
	},
	CART: {
		VIEW_CART_PAGE: 'view_cart_page',
		ADD_ITEM_TO_CART: 'add_item_to_cart',
		REMOVE_ITEM_FROM_CART: 'remove_item_from_cart',

		CLICK_CLEAR_CART: 'btn_click_clear_cart',
		CLICK_EXPORT_ITEMS: 'btn_click_export_items',
		CLICK_OPEN_CART: 'btn_click_open_cart',
		CLICK_CREATE_JOB_SHORTCUT: 'btn_click_create_job_shortcut',

		VIEW_COMPOUNDS_PAGE: 'view_compounds_page',
		OPEN_EMOLECULES_MODAL: 'btn_click_open_emolecules_modal',
		CLOSE_EMOLECULES_MODAL: 'btn_click_close_emolecules_modal',

		RELOAD_COMPOUNDS: 'reload_compounds',
		SENDED_COMPOUNDS: 'sended_compounds',
	},
	DASHBOARD: {
		VIEW_DASHBOARD_PAGE: 'view_dashboard_page',
		VIEW_LIGAND_BASED_SEARCH: 'view_ligand_based_search',
		VIEW_TARGET_BASED_SEARCH: 'view_target_based_search',
		VIEW_STRUCTURE_BASED_SEARCH: 'view_structure_based_search',
		VIEW_CHATS: 'view_chats',
	},
	PIPELINE: {
		CLICK_OPEN_PIPELINE: 'btn_click_open_pipeline',
		VIEW_JOB_PIPELINE: 'show_job_pipeline',
		CLICK_OPEN_GENERATE_PIPELINE: 'btn_click_open_generate_pipeline',
		CLICK_OPEN_TOOLS_DRAWER: 'btn_click_open_tools_drawer',
		CLICK_CLEAR_PIPELINE: 'btn_click_clear_pipeline',
		CLICK_EXECUTE_PIPELINE: 'btn_click_execute_pipeline',
	},
	CHAT: {
		VIEW_EUROFIN_FORM: 'view_eurofin_form',
	},
};

export class Analytics {
	/**
	 * Send analytic event.
	 *
	 * @param eventName - Name of the event. Should be one of the values from AnalyticsEvents.
	 * @param data - Additional data for the event.
	 */
	public static send(eventName: string, data?: object) {
		mixpanel.track(eventName, data);
	}

	public static init() {
		const env = import.meta.env;
		const token = env.VITE_PUBLIC_MIXPANEL_TOKEN;
		mixpanel.init(token, {
			debug: env.DEV,
		});
	}

	public static identify() {
		let user = null;
		const currentUserData = localStorage.getItem('currentUser');
		if (currentUserData) {
			try {
				user = JSON.parse(currentUserData);
			} catch (e) {
				console.error('Error parsing currentUser data:', e);
			}
		}

		if (!user) {
			return;
		}

		mixpanel.identify(user.id);

		mixpanel.people.set(
			{
				id: user.id,
				$email: user.email,
				$name: user.name,
				created_at: user.created_at,
				firebase_uid: user.firebase_uid,
			},
			(result) => {
				console.log('Mixpanel identify result:', result);
			}
		);
	}
}
