<template>
	<div class="top-k-slider-container">
		<div class="top-k-slider-container--info">
			<div class="subheadlineBold">How many results you want to see</div>
			<div class="bodySmallRegular">
				Keep in mind that the less Top K you set, the better the results
				will be
			</div>
		</div>

		<div class="top-k-slider">
			<div class="footnoteBold">
				Top K
				<bChip
					:label="`${modelValue.toLocaleString()}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="bodySmallRegular">
				Range {{ 1 + ' - ' + max.toLocaleString() }}
			</div>

			<div class="top-k-slider--input">
				<Slider
					:value="modelValue"
					:min="1"
					:max="max"
					:step="1"
					@change="handleUpdate"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { bChip } from '@/shared/uikit';
import Slider from '@vueform/slider';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
	modelValue: {
		type: Number,
		required: true,
	},
	max: {
		type: Number,
		required: true,
	},
});

const handleUpdate = (value: number) => {
	emit('update:modelValue', value);
};
</script>

<style lang="stylus" scoped>
.top-k-slider-container
	width 100%
	display flex
	flex-direction column
	gap 1rem
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
	.top-k-slider
		position relative
		display flex
		flex-direction column
		gap 0.25rem
		padding 1.12rem 1.5rem
		border 1px solid var(--neutral-200)
		border-radius 1.75rem
		.footnoteBold
			width 100%
			display flex
			flex-direction row
			align-items center
			justify-content space-between

		.top-k-slider--input
			position absolute
			bottom -0.2rem
			left 1.5rem
			right 1.5rem
</style>
