<template>
	<div class="admet-results-page">
		<ADMETTableWidget />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import { ADMETTableWidget, admetTableResultModel } from '@/widgets/chats';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { title, description, clear: clearNavigation } = navigationHeaderModel;
const { downdloadAdmetFile } = admetTableResultModel;
onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	if (router.currentRoute.value.name !== 'chat-table-result') {
		return;
	}

	title.value = 'ADMET Results';
	description.value = 'Your ADMET results are displayed below.';

	const fileUrl = router.currentRoute.value.query.fileUrl as string;

	if (!fileUrl) {
		router.push('/');
		return;
	}

	const url = decodeURIComponent(fileUrl);
	await downdloadAdmetFile(url, false);
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.admet-results-page
	width 100%
	height calc(100% - 6.25rem)
</style>
