import {
	SearchesAttributeEntity,
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
} from './shared';

export class ClusterEntity {
	constructor(
		public size: number,
		public index: number,
		public scaffold_smiles: string,
		public attributes: SearchesAttributeEntity[]
	) {}

	static fromObject(data: any): ClusterEntity {
		return new ClusterEntity(
			data.size,
			data.index,
			data.scaffold_smiles,
			data.attributes.map((attribute: any) =>
				SearchesAttributeEntity.fromObject(attribute)
			)
		);
	}

	get tooltipString(): string {
		let tooltipString = ` Scaffold: ${this.scaffold_smiles} \n`;
		for (const attribute of this.attributes) {
			tooltipString += `${getSearchAttributeTypeLabel(attribute.type)} : ${attribute.absolute_min.toFixed(
				getSearchAttributeTypeToFix(attribute.type)
			)} - ${attribute.absolute_max.toFixed(getSearchAttributeTypeToFix(attribute.type))} \n`;
		}
		return tooltipString;
	}
}
