<template>
	<div class="container">
		<IconLogo size="2.75rem" :color="ThemeColors.primary[600]" />

		<div class="login-container">
			<div class="header-block">
				<div class="calloutRegular">{{ $t('auth.signup.title') }}</div>
				<div class="footnoteRegular">
					{{ $t('auth.signup.subtitle') }}
				</div>
			</div>
			<bDivider />

			<form @keyup.enter="tappedSignup">
				<bInput
					ref="inputRef"
					:placeholder="$t('auth.signup.form.emailPlaceholder')"
					type="email"
					size="large"
					@onChange="onChangeEmail"
					:isDisabled="false"
					:isCaption="false"
				/>

				<bPasswordInput v-model="password" />
				<SignUpWithEmailButton
					:isDisabled="
						!(form.emailValid && validatePassword(password))
					"
					:email="form.email"
					:password="password"
				/>
				<bDivider label="or" />
				<LoginWithGoogleButton />
				<!-- <LoginWithMicrosoftButton /> -->
			</form>
		</div>
		<div class="login-footer">
			<bButton
				:label="$t('auth.button.signin')"
				type="tertiary"
				@onClick="router.push('/login')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import {
	LoginWithGoogleButton,
	SignUpWithEmailButton,
	buttonSignupWithEmailModel,
} from '@/features/auth/';
import { validatePassword } from '@/shared/lib';
import {
	IconLogo,
	ThemeColors,
	bButton,
	bDivider,
	bInput,
	bPasswordInput,
} from '@/shared/uikit';
import { onMounted, reactive, ref } from 'vue';

const { signUp } = buttonSignupWithEmailModel;
const password = ref<string>('');
const form = reactive({
	email: '',
	emailValid: false,
});

const onChangeEmail = (value: any) => {
	form.email = value.value;
	form.emailValid = value.isValid;
};

const tappedSignup = () => {
	if (!(form.emailValid && validatePassword(password.value))) {
		return;
	}

	signUp(form.email, password.value);
};

onMounted(() => {
	const referralUserId =
		(router.currentRoute.value.query.ref as string) ?? null;

	if (referralUserId) {
		localStorage.setItem('referralUserId', referralUserId);
	}
});
</script>

<style lang="stylus">
.login-footer
	.b-button .b-button-label
		color var(--primary-600)
</style>

<style lang="stylus" scoped>
@import '../shared/auth.styl';
</style>
