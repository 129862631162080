export interface IGrid {
	updateScale(scale: number): void;
	updateOrigin(originX: number, originY: number): void;
	getGridSize(): number;
	draw(): void;
}

export class Grid implements IGrid {
	private baseGridSize: number;
	private svg: SVGSVGElement;
	private contentWidth: number;
	private contentHeight: number;
	private scale: number;
	private originX: number;
	private originY: number;
	private gridGroup: SVGGElement;

	constructor(
		baseGridSize: number,
		svg: SVGSVGElement,
		contentWidth: number,
		contentHeight: number,
		scale: number,
		originX: number,
		originY: number
	) {
		this.baseGridSize = baseGridSize;
		this.svg = svg;
		this.contentWidth = contentWidth;
		this.contentHeight = contentHeight;
		this.scale = scale;
		this.originX = originX;
		this.originY = originY;
		this.gridGroup = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'g'
		);
		this.svg.insertBefore(this.gridGroup, this.svg.firstChild);

		// Define a reusable circle template
		const defs = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'defs'
		);
		const circle = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'circle'
		);
		circle.setAttribute('id', 'grid-dot');
		circle.setAttribute('r', '1');
		circle.setAttribute('fill', 'gray');
		defs.appendChild(circle);
		this.svg.appendChild(defs);
	}

	public updateScale(scale: number) {
		this.scale = scale;
	}

	public updateOrigin(originX: number, originY: number) {
		this.originX = originX;
		this.originY = originY;
	}

	public getGridSize(): number {
		return this.baseGridSize * Math.max(1, Math.floor(1 / this.scale));
	}

	public draw() {
		while (this.gridGroup.firstChild) {
			this.gridGroup.removeChild(this.gridGroup.firstChild);
		}

		const gridSize = this.getGridSize();
		const width = this.svg.clientWidth / this.scale;
		const height = this.svg.clientHeight / this.scale;

		const startX = Math.floor(this.originX / gridSize) * gridSize;
		const startY = Math.floor(this.originY / gridSize) * gridSize;

		const fragment = document.createDocumentFragment();

		for (let x = startX; x < startX + width; x += gridSize) {
			for (let y = startY; y < startY + height; y += gridSize) {
				if (
					x >= 0 &&
					x <= this.contentWidth &&
					y >= 0 &&
					y <= this.contentHeight
				) {
					const use = document.createElementNS(
						'http://www.w3.org/2000/svg',
						'use'
					);
					use.setAttribute('href', '#grid-dot');
					use.setAttribute('x', x.toString());
					use.setAttribute('y', y.toString());
					fragment.appendChild(use);
				}
			}
		}

		this.gridGroup.appendChild(fragment);
	}
}
