<template>
	<div class="job-settings-container" ref="elementRef">
		<div
			:class="{
				'job-settings-target': true,
				'job-settings-target--active': isOpened,
			}"
			@click="toggleDropdown"
		>
			<IconEllipsisV
				size="1.125rem"
				:color="
					isOpened
						? ThemeColors.primary[600]
						: ThemeColors.neutral[900]
				"
			/>
		</div>
		<div v-if="isOpened" class="job-settings-dropdown">
			<DeleteCollectionDropdownItem
				:collectionId="collection.id"
				@onClick="toggleDropdown"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { DeleteCollectionDropdownItem } from '@/features/collections/';
import type { CollectionEntity } from '@/shared/api';
import { IconEllipsisV, ThemeColors } from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref, type PropType } from 'vue';
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const props = defineProps({
	collection: {
		type: Object as PropType<CollectionEntity>,
		required: true,
	},
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};
</script>

<style lang="stylus" scoped>
.job-settings-container
	position: relative
	.job-settings-target
		height: 2.5rem;
		width: 2.5rem;
		display: flex
		align-items: center
		justify-content: center
		gap 0.5rem
		border: 1px solid var(--neutral-200);
		border-radius 50%
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		&--active
			color var(--primary-600)
			border-color var(--primary-600)
			background var(--primary-200)
			transition all 0.2s ease-in-out



	.job-settings-dropdown
		position: absolute
		right: 0
		margin-top: 0.5rem
		display: flex;
		width 17.4375rem
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.job-settings-item
			width 100%
			display grid
			grid-template-columns 3rem auto
			align-items center
			justify-content flex-start
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer

.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
