<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 20 20"
		fill="none"
	>
		<g clip-path="url(#clip0_2782_3123)">
			<path
				d="M12.2584 5.59165C11.9334 5.26665 11.4084 5.26665 11.0834 5.59165L7.2584 9.41665C6.9334 9.74165 6.9334 10.2666 7.2584 10.5916L11.0834 14.4166C11.4084 14.7416 11.9334 14.7416 12.2584 14.4166C12.5834 14.0916 12.5834 13.5666 12.2584 13.2416L9.02507 9.99998L12.2584 6.76665C12.5834 6.44165 12.5751 5.90832 12.2584 5.59165Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2782_3123">
				<rect width="20" height="20" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
