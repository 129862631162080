<template>
	<div :id="id" class="draggable-element-wrapper" :draggable="false">
		<div class="draggable-element-wrapper--controls">
			<bUnstyledButton @onClick="onDuplicate">
				<template v-slot:content>
					<IconCopy size="1.25rem" />
				</template>
			</bUnstyledButton>
			<bUnstyledButton @onClick="onRemove">
				<template v-slot:content>
					<IconTrash
						size="1.25rem"
						:color="ThemeColors.danger[600]"
					/>
				</template>
			</bUnstyledButton>
		</div>
		<div
			class="draggable-element-wrapper--body"
			:class="{ 'draggable-element-wrapper--body-selected': isSelected }"
		>
			<slot name="body"></slot>
			<div
				v-if="progress > 0"
				class="draggable-element-wrapper--body-loading"
				:style="{ width: progressWidth }"
			></div>
		</div>
		<div
			v-if="type !== StepType.CATALOG_LOADER"
			class="connection-point input-point"
			:data-id="id"
			:data-input-ids="input?.join(',')"
			@mousedown="onDragStart('input', $event)"
		></div>
		<div
			class="connection-point output-point"
			:data-id="id"
			:data-output-ids="output?.join(',')"
			@mousedown="onDragStart('output', $event)"
		></div>
	</div>
</template>

<script lang="ts" setup>
import { StepType } from '@/shared/api';
import {
	IconCopy,
	IconTrash,
	ThemeColors,
	bUnstyledButton,
} from '@/shared/uikit';
import { computed, ref, type PropType } from 'vue';

const emit = defineEmits(['addConnection']);
const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	type: {
		type: String as PropType<StepType>,
		required: true,
	},
	input: {
		type: Array as PropType<String[]>,
		required: true,
	},
	output: {
		type: Array as PropType<String[]>,
		required: true,
	},
	progress: {
		type: Number,
		required: false,
		default: 0,
	},
});

const isDragging = ref(false);
const isSelected = ref(false);
const dragType = ref<'input' | 'output' | null>(null);
const startElementId = ref<string | null>(null);
const progressWidth = computed(() => `${props.progress}%`);

const onDragStart = (type: 'input' | 'output', event: MouseEvent) => {
	dragType.value = type;
	startElementId.value = props.id;
	isDragging.value = true;
	document.addEventListener('mousemove', onMouseMove);
	document.addEventListener('mouseup', onDragEnd);
	event.preventDefault();
};

const onDragEnd = (event: MouseEvent) => {
	if (isDragging.value) {
		isDragging.value = false;
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseup', onDragEnd);

		const toElement = document
			.elementFromPoint(event.clientX, event.clientY)
			?.closest('.input-point') as HTMLElement;

		if (toElement && startElementId.value && dragType.value === 'output') {
			const fromElementId = startElementId.value;
			const toElementId = toElement.getAttribute('data-id');
			if (toElementId) {
				const customEvent = new CustomEvent('addConnection', {
					detail: { fromElementId, toElementId },
					bubbles: true,
				});
				document.getElementById(props.id)?.dispatchEvent(customEvent);
			}
		}
	}
};

const onMouseMove = (event: MouseEvent) => {
	if (isDragging.value && dragType.value === 'output') {
		// console.log('Dragging connection line');
		// Здесь вы можете добавить визуальную индикацию линии соединения
	}
};

const onRemove = () => {
	const customEvent = new CustomEvent('onRemoveCard', {
		detail: { id: props.id },
		bubbles: true,
	});

	document.getElementById(props.id)?.dispatchEvent(customEvent);
};

const onDuplicate = () => {
	const customEvent = new CustomEvent('onDuplicateCard', {
		detail: { id: props.id },
		bubbles: true,
	});

	document.getElementById(props.id)?.dispatchEvent(customEvent);
};
</script>

<style lang="stylus" scoped>
.draggable-element-wrapper
	position absolute
	width 100%
	height 100%
	padding 1rem
	padding-top 2.75rem
	&:hover
		.draggable-element-wrapper--controls
			opacity 1
			transition all 0.25s
		.draggable-element-wrapper--body
			border 1px solid var(--primary-400)
			transition all 0.25s
			overflow hidden
	&--controls
		position absolute
		top 0rem
		right 50%
		transform translateX(50%)
		border-radius: 62.4375rem
		border: 1px solid var(--primary-400)
		background white
		z-index 10
		display flex
		flex-direction row
		align-items center
		justify-content center
		gap 0.5rem
		padding 0.25rem
		opacity 0
		transition all 0.25s
		.b-unstyled-button
			padding 0.25rem
			border-radius 50%

	&--body
		position relative
		user-select none
		padding 1rem
		width 100%
		height 100%
		background white
		border-radius: 1.5rem
		border: 1px solid var(--neutral-200)
		box-shadow: 0px 0.5px 5px 0px rgba(11, 10, 13, 0.04), 0px 3.75px 11px 0px rgba(11, 10, 13, 0.19);
		transition all 0.25s
		&.draggable-element-wrapper--body-selected
			border: 1px solid var(--primary-400)
		.draggable-element-wrapper--body-loading
			position absolute
			top 0
			left 0
			right 0
			width 50%
			height 100%
			border-radius: 1.5rem
			background-color var(--neutral-200)

.connection-point {
	position: absolute;
	width: 1rem;
	height: 1rem;
	background white
	border: 3px solid var(--primary-400)
	border-radius: 50%;
	transition: all 0.25s;
	&:hover {
		cursor: crosshair;
		transform: scale(1.2);
		transition: all 0.25s;
	}
}

.input-point {
	top: 4.5rem;
	left 0.5rem;
}
.output-point {
	bottom 2.5rem;
	right 0.5rem;
}
</style>
