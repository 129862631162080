<template>
	<div class="tools-drawer-container" ref="elementRef">
		<div class="tools-drawer-container-wrapper">
			<Transition name="slide-fade">
				<div v-if="modelValue" class="tools-drawer-dropdown">
					<div class="tools-drawer-dropdown--header">
						<div class="subheadlineBold">Add Tool</div>
					</div>
					<div class="tools-drawer-dropdown--body">
						<div
							v-for="tool in tools"
							:key="`tool-key-${tool.id}`"
							class="tools-drawer-dropdown-item"
							:draggable="true"
							@dragstart="(e) => onDragStart(e, tool)"
						>
							<span>
								<div class="footnoteBold">
									{{ getStepTypeString(tool.type) }}
								</div>

								<div class="bodySmallSemiBold">
									<!-- Some description about the application -->
								</div>
							</span>

							<bUnstyledButton
								class="tools-drawer-dropdown-item-plus"
								@onClick="onAddTool(tool)"
							>
								<template v-slot:content>
									<IconPlus size="1.25rem" color="white" />
								</template>
							</bUnstyledButton>
						</div>
					</div>
				</div>
			</Transition>
			<bUnstyledButton
				class="tools-drawer-target"
				@onClick="toggleDropdown"
			>
				<template v-slot:content>
					<IconGrid size="1.25rem" color="white" />
				</template>
			</bUnstyledButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { ForeignObjectEntity, getStepTypeString } from '@/shared/api';
import { IconGrid, IconPlus, bUnstyledButton } from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { pipelineToolsDrawerModel } from './model';

const emit = defineEmits(['update:modelValue', 'onAddTool']);

const elementRef = ref<HTMLElement | null>(null);
const { tools, fetchTools } = pipelineToolsDrawerModel;

const props = defineProps({
	modelValue: {
		type: Boolean,
		required: true,
	},
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		emit('update:modelValue', false);
	}
};
const onDragStart = (event: DragEvent, tool: any) => {
	if (event.dataTransfer) {
		event.dataTransfer.setData('tool', JSON.stringify(tool));
	}
};
onMounted(() => {
	fetchTools();
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	emit('update:modelValue', !props.modelValue);
	if (!props.modelValue) {
		Analytics.send(AnalyticsEvents.PIPELINE.CLICK_OPEN_TOOLS_DRAWER);
	}
};

const onAddTool = (tool: ForeignObjectEntity) => {
	emit('onAddTool', tool);
};
</script>

<style lang="stylus" scoped>
.tools-drawer-container
	position: fixed
	left 4.75rem
	bottom 1.25rem
	z-index 1
.tools-drawer-container-wrapper
	position relative
	.tools-drawer-target
		background white
		padding 0.75rem
		border-radius 1rem
		border-radius: var(--rounded-large, 1rem);
		background: var(--neutral-900);
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

	.tools-drawer-dropdown
		position: absolute
		width: 25rem;
		left: -3.75rem
		bottom 3.5rem
		height: calc(100vh - 11.5rem);
		padding: 0.5rem
		display: grid;
		grid-template-rows: auto 1fr auto;
		gap: 0.5rem;
		background: white
		border-radius: 1rem;
		box-shadow: 0px 0.5px 5px 0px rgba(11, 10, 13, 0.04), 0px 3.75px 11px 0px rgba(11, 10, 13, 0.19);
		.tools-drawer-dropdown--body
			padding-top 0.5rem
			border-radius: 1rem
			display flex
			flex-direction column
			align-items flex-start
			gap 0.5rem
			.tools-drawer-dropdown-item
				width 100%
				padding 1rem
				display grid
				grid-template-columns 1fr auto
				align-items center
				gap 0.5rem
				border-radius: 1rem
				background: var(--neutral-50);
				box-shadow: 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04), 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19);
				&:hover
					background: var(--neutral-100)
					cursor pointer
				.bodySmallSemiBold
					color var(--neutral-500)
				.tools-drawer-dropdown-item-plus
					background: var(--primary-600)
					border-radius: 50%
					padding 0.5rem
					&:hover
						background: var(--primary-700)

		.tools-drawer-dropdown--header
			width 100%
			padding-left 0.5rem
			padding-top 0.5rem
			display: flex
			flex-direction: row
			justify-content: space-between
			align-items: center
			.subheadlineBold
				display: flex
				flex-direction: row
				align-items: center
				gap: 0.25rem
</style>

<style lang="stylus">
.slide-fade-enter-active {
	transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.25s ease-in;
}

.slide-fade-enter-from {
	transform: translateX(-50vw);
	opacity: 0;
}

.slide-fade-leave-to {
	transform: translateX(-50vw);
	opacity: 0;
}
</style>
