<template>
	<nav class="navigation-tabs-container">
		<div class="navigation-tabs-container--main-controls">
			<DashboardButton />
			<HomeButton />
		</div>

		<ul class="navigation-tabs">
			<NavigationTab
				v-for="tab in tabs"
				:key="tab.id + '-' + tab.index"
				:tab="tab"
				@onRemove="removeTab(tab)"
				@onDrop="handleDrop"
				@onOver="handleOver"
				@onStart="handleStart"
			/>
			<NavigationNewTab :index="tabs.length" />
		</ul>
	</nav>
</template>

<script setup lang="ts">
import { DashboardButton, HomeButton } from '@/features/navigation/';
import { onMounted, ref } from 'vue';
import NavigationNewTab from './NavigationNewTab.vue';
import NavigationTab from './NavigationTab.vue';
import { navigationTabsModel } from './model';

const startData = ref<any>(null);
const overData = ref<any>(null);

const { tabs, removeTab, init, moveTab, movingTab } = navigationTabsModel;

onMounted(() => {
	init();
});

const handleDrop = (droppedTab: any, targetTab: any) => {
	if (!droppedTab || !targetTab) {
		return;
	}
	moveTab(droppedTab, targetTab);
	startData.value = null;
	overData.value = null;
};

const handleOver = (overTab: any) => {
	if (!overTab || !startData.value || overData.value?.id === overTab?.id) {
		return;
	}

	movingTab(overTab, startData.value);
};

const handleStart = (startTab: any) => {
	startData.value = startTab;
};
</script>

<style lang="stylus" scoped>
.navigation-tabs-container
	width 100%
	padding 0.25rem
	background-color var(--neutral-900)
	display grid
	grid-template-columns auto 1fr
	position relative
	user-select none
	.navigation-tabs-container--main-controls
		display flex
		flex-direction row
		justify-content center
		align-items center
		gap 0.12rem
		margin-right 0.5rem
	.navigation-tabs
		width calc(100vw - 5.5rem)
		height 100%
		padding-right 2rem
		display flex
		flex-direction row
		align-items center
		justify-content flex-start
		gap 0.5rem
		list-style-type none
		overflow-x auto
		&::-webkit-scrollbar
			display none
</style>

<style>
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
</style>
