<template>
	<bModal
		v-if="collectionId.length > 0"
		type="danger"
		title="Are you sure you want to delete this collection?"
		message="You won't be able to restore this collection later"
		:width="400"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bTextButton label="Cancel" type="neutral" @onClick="onClose" />
			<bButton
				label="Delete"
				type="danger"
				:isGhost="true"
				:isLoading="isLoading"
				@onClick="deleteCollection"
			>
				<template v-slot:icon-left>
					<IconTrash
						size="1.25rem"
						:color="ThemeColors.danger[600]"
					/>
				</template>
			</bButton>
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { ThemeColors, bButton, bModal, bTextButton } from '@/shared/uikit/';
import IconTrash from '@/shared/uikit/components/icons/IconTrash.vue';
import { deleteCollectionModel } from './model';

const { collectionId, deleteCollection, isLoading } = deleteCollectionModel;

const onClose = () => {
	collectionId.value = '';
	Analytics.send(AnalyticsEvents.Collections.CLOSE_DELETE_COLLECTION_MODAL);
};
</script>
