<template>
	<div class="pipeline-chat-container">
		<bUnstyledButton class="pipeline-chat-target" @onClick="onClick">
			<template v-slot:content>
				<IconTrash size="1.25rem" color="white" />
			</template>
		</bUnstyledButton>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconTrash, bUnstyledButton } from '@/shared/uikit';

const emit = defineEmits(['onClick']);

const onClick = () => {
	emit('onClick');
	Analytics.send(AnalyticsEvents.PIPELINE.CLICK_CLEAR_PIPELINE);
};
</script>

<style lang="stylus" scoped>
.pipeline-chat-container
	position: fixed
	right 1.25rem
	top calc(1.25rem + 6.25rem)
	z-index 1
	.pipeline-chat-target
		background var(--danger-600)
		padding 0.75rem
		border-radius 1rem
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
</style>
