import { Analytics, AnalyticsEvents, i18n, router } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api';
import { firebaseErrorHandler } from '@/shared/lib';
import { Notification, notificationModel } from '@/shared/uikit';
import { reactive, toRefs } from 'vue';

const state = reactive({
	isLoading: false,
});

async function createNewPassword(obbCode: string, password: string) {
	Analytics.send(AnalyticsEvents.CreateNewPassword.CREATE_NEW_PASSWORD);

	try {
		state.isLoading = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.updatePassword(password, obbCode);

		notificationModel.addNotification(
			new Notification(
				'password-updated-success',
				'success',
				i18n.global.t('auth.newPassword.notification.success.title'),
				i18n.global.t('auth.newPassword.notification.success.message'),
				null,
				3000
			)
		);

		router.push('/login');

		Analytics.send(
			AnalyticsEvents.CreateNewPassword.CREATE_NEW_PASSWORD_SUCCESS
		);
	} catch (error) {
		firebaseErrorHandler(error);
		Analytics.send(
			AnalyticsEvents.CreateNewPassword.CREATE_NEW_PASSWORD_FAILURE
		);
	} finally {
		state.isLoading = false;
	}
}

export const createNewPasswordModel = {
	...toRefs(state),
	createNewPassword,
};
