<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M12 5.00052V2.21052C12 1.76052 11.46 1.54052 11.15 1.86052L7.35003 5.65052C7.15003 5.85052 7.15003 6.16052 7.35003 6.36052L11.14 10.1505C11.46 10.4605 12 10.2405 12 9.79052V7.00052C15.73 7.00052 18.68 10.4205 17.86 14.2905C17.39 16.5605 15.55 18.3905 13.29 18.8605C9.72003 19.6105 6.54003 17.1605 6.06003 13.8505C5.99003 13.3705 5.57003 13.0005 5.08003 13.0005C4.48003 13.0005 4.00003 13.5305 4.08003 14.1305C4.70003 18.5205 8.88003 21.7705 13.61 20.8505C16.73 20.2405 19.24 17.7305 19.85 14.6105C20.84 9.48052 16.94 5.00052 12 5.00052Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
