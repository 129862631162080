<template>
	<div class="tdss-probes-list-container">
		<div class="tdss-probes-list-container--info">
			<div class="subheadlineBold">
				Select probes
				<bChip
					v-if="selectedProbes && selectedProbes.length > 0"
					:label="`${selectedProbes.length}`"
					:isGhost="false"
					type="primary"
					size="small"
				/>
			</div>
			<div class="bodySmallRegular">
				Choose probes to search for similar ligands. You can select up
				to 75 probes, with a minimum selection of 5 probes.
			</div>
		</div>
		<div class="tdss-probes-list-prestrategy">
			<div class="tdss-probes-list-prestrategy--left">
				<bToggle
					v-model="isClustered"
					size="small"
					@update:modelValue=""
				/>
				<div class="subheadlineSemiBold">Cluster probes</div>
			</div>
			<ProbesListPreselectDropdown
				:isClustered="isClustered"
				@onChange="onChangeProbes"
			/>
		</div>

		<div v-if="probes.length > 0 && !isLoading" class="tdss-probes-list">
			<ProbesCard
				v-for="(probe, index) in probes"
				:key="`probe-key-${probe.index}-${index}`"
				:index="index"
				:probe="probe"
				:isSelected="
					selectedProbes?.some(
						(selectedProbe) => selectedProbe.index === probe.index
					)
				"
				@onClick="() => $emit('onSelect', probe)"
				@openPreview="openProbePreview(probe)"
			/>
		</div>
		<template v-if="selectedOption.id === 0">
			<bPagination
				v-if="probes.length > 0 && !isLoading"
				:limit="pageLimit.value"
				:total="total"
				:currentPage="currentPage"
				@currentChange="changePage"
			>
				<template v-slot:select-page>
					<div class="pagination-select-page">
						<bSelect
							v-model="pageLimit"
							:options="pageOptions"
							size="small"
							position="top"
						/>
					</div>
				</template>
			</bPagination>
		</template>

		<bEmptyView
			v-if="probes.length === 0 && !isLoading"
			title="Sorry, no probes found"
			description="Please try again later"
		>
			<template v-slot:image>
				<bImageEmpty2 :width="100" :height="100" />
			</template>
		</bEmptyView>

		<template v-if="isLoading">
			<div class="empty-view"><bLoader size="4" /></div>
		</template>
	</div>
</template>

<script setup lang="ts">
import { ProbesCard } from '@/entities/probes';
import { previewSmilesModel } from '@/features/job';
import {
	ProbesListPreselectDropdown,
	probesListPreselectModel,
} from '@/features/target-driven-search';
import { HitEntity, type ProbesEntity } from '@/shared/api';
import {
	bChip,
	bEmptyView,
	bImageEmpty2,
	bLoader,
	bPagination,
	bSelect,
	bToggle,
} from '@/shared/uikit';
import { onMounted, watch, type PropType } from 'vue';
import type { ProbesStrategyOptioin } from '../probes-list-preselect-dropdown/entities';
import { tdssProbesListModel } from './model';

const emit = defineEmits(['onSelect', 'onDeselectAll', 'onReset']);

const {
	fetchProbes,
	probes,
	isLoading,
	pageLimit,
	total,
	currentPage,
	pageOptions,
	isClustered,
} = tdssProbesListModel;
const { selectedOption } = probesListPreselectModel;
const props = defineProps({
	selectedTargetId: {
		type: String,
		required: true,
	},
	selectedProbes: {
		type: Array as PropType<ProbesEntity[]>,
		required: false,
	},
});

onMounted(async () => {
	await fetchProbes(props.selectedTargetId, 1);
});

watch(
	() => pageLimit.value,
	() => {
		fetchProbes(props.selectedTargetId, 1);
	},
	{ deep: true }
);

watch(
	() => isClustered.value,
	() => {
		emit('onDeselectAll');
		fetchProbes(props.selectedTargetId, 1);
	},
	{ immediate: true }
);

const changePage = (page: number) => {
	fetchProbes(props.selectedTargetId, page);
};

const openProbePreview = (probe: ProbesEntity) => {
	previewSmilesModel.previewMolecule.value = HitEntity.fromObject(probe);
};

const onChangeProbes = async (option: ProbesStrategyOptioin) => {
	emit('onDeselectAll');

	if (option.id === 2 || option.id === 3) {
		probes.value = [];

		await fetchProbes(props.selectedTargetId, 1);

		probes.value.forEach((probe) => {
			emit('onSelect', probe);
		});
	}
};
</script>

<style lang="stylus" scoped>
.tdss-probes-list-container
	width 100%
	display flex
	flex-direction column
	gap 1rem
	padding-bottom 1.25rem
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
		.subheadlineBold
			display flex
			flex-direction row
			align-items center
			gap 0.5rem

	.tdss-probes-list-prestrategy
		width 100%
		display flex
		flex-direction row
		justify-content space-between
		padding-left 1.25rem
		&--left
			display flex
			flex-direction row
			align-items center
			gap 0.5rem


	.tdss-probes-list
		display grid
		grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
		gap 0.75rem
		justify-content center
		transition all 0.25s
		padding-bottom 1.25rem
		&:hover
			cursor pointer
			transition all 0.25s
</style>
