import { Analytics, AnalyticsEvents } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	tdssCatalogListModel,
	tdssInputModel,
	tdssPrefiltersListModel,
	tdssProbesListModel,
} from '@/features/target-driven-search';
import {
	CatalogEntity,
	DEFAULT_TOP_K,
	JobsService,
	NavigationTabType,
	ProbesEntity,
	SearchType,
	type TargetEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	step: number;
	selectedTarget: TargetEntity | null;
	topK: number;
	selectedCatalog: CatalogEntity | null;
	selectedProbes: ProbesEntity[];
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	step: 0,
	selectedTarget: null,
	topK: DEFAULT_TOP_K,
	selectedCatalog: null,
	selectedProbes: [],
};

const state = reactive(data);

function clear() {
	state.step = 0;
	state.selectedTarget = null;
	state.topK = DEFAULT_TOP_K;
	state.selectedCatalog = null;
	state.selectedProbes = [];

	const { clear: inputClear } = tdssInputModel;
	const { clear: catalogClear } = tdssCatalogListModel;
	const { clear: prefiltersClear } = tdssPrefiltersListModel;
	const { clear: probesClear } = tdssProbesListModel;

	inputClear();
	catalogClear();
	prefiltersClear();
	probesClear();
}

async function finishCreating() {
	if (!state.selectedCatalog || !state.selectedTarget) {
		return;
	}

	state.isLoading = true;
	const { filters } = tdssPrefiltersListModel;

	const jobParameters = {
		type: SearchType.TARGET,
		top_k: state.topK,
		filters: filters.value || [],
		target_id: state.selectedTarget.id,
		probes: state.selectedProbes.map((probe) => {
			return {
				index: probe.index,
				catalog_id: probe.catalog_id,
			};
		}),
	};

	try {
		const newJob = await JobsService.getInstance().createJob(
			state.selectedCatalog.id,
			jobParameters
		);

		const { addTab } = navigationTabsModel;
		addTab({
			id: newJob.id,
			title: 'Target Driven',
			description: state.selectedTarget.name,
			path: `/job/${newJob.id}`,
			type: NavigationTabType.TARGET,
		});

		Analytics.send(AnalyticsEvents.Job.CREATE_JOB_SUCCESS, {
			type: SearchType.TARGET,
			catalog_name: state.selectedCatalog.name,
			job_parameters: {
				...jobParameters,
				uni_prot_kb_ac: state.selectedTarget.uni_prot_kb_ac,
			},
		});
	} catch (error) {
		ocularRestErrorHandler(error);
		Analytics.send(AnalyticsEvents.Job.CREATE_JOB_FAILURE, {
			type: SearchType.LIGAND,
			catalog_id: state.selectedCatalog?.id,
			job_parameters: jobParameters,
		});
	} finally {
		state.isLoading = false;
		state.isVisible = false;
		clear();
	}
}
export const createTDSSModel = {
	...toRefs(state),
	clear,
	finishCreating,
};
