import type { PaginationPageType } from '@/shared/api';
import {
	CollectionService,
	type CollectionEntity,
} from '@/shared/api/collections';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	total: number;
	collections: CollectionEntity[];
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	total: 0,
	collections: [],
	currentPage: 1,
	pageLimit: {
		title: '25',
		value: 25,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '25',
			value: 25,
		},
		{
			title: '50',
			value: 50,
		},
		{
			title: '100',
			value: 100,
		},
	],
};

const state = reactive(data);

watch(
	() => state.pageLimit,
	() => {
		fetchCollections(1);
	},
	{ deep: true }
);

async function fetchCollections(page: number) {
	state.currentPage = page;
	state.isLoading = true;
	try {
		const result = await CollectionService.getInstance().fetchCollections(
			state.pageLimit.value,
			(page - 1) * state.pageLimit.value
		);
		state.collections = result.collections;
		state.total = result.count;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const collectionsListModel = {
	...toRefs(state),
	fetchCollections,
};
