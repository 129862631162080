import type { HitEntity } from '../api';

export function toCSV(data: any[]): Blob {
	const header: string = [
		// Identifiers.
		'source',
		'id',
		'cluster_id',
		// Data.
		'smiles',
		// Properties.
		'hac',
		'hba',
		'hbd',
		'tpsa',
		'log_p',
		'f_csp3',
		'weight',
		'rotatable_bonds',
		'molar_refractivity',
	].join(',');

	const rows: string = data
		.map((item: any) =>
			[
				// Identifiers.
				item.source,
				item.id,
				item.cluster_id,
				// Data.
				item.smiles,
				// Properties.
				item.hac.toString(),
				item.hba.toString(),
				item.hbd.toString(),
				item.tpsa.toString(),
				item.log_p.toString(),
				item.f_csp3.toString(),
				item.weight.toString(),
				item.rotatable_bonds.toString(),
				item.molar_refractivity.toString(),
			]
				.map((value: string): string => {
					if (
						value.includes(',') ||
						value.includes('\n') ||
						value.includes('"')
					) {
						// Escape double quotes and wrap in double quotes.
						return `"${value.replace(/"/g, '""')}"`;
					}

					return value;
				})
				.join(',')
		)
		.join('\n');

	return new Blob([header + '\n' + rows], {
		type: 'text/csv;charset=utf-8;',
	});
}

export function hitsToCSV(data: HitEntity[]): Blob {
	const header: string = [
		'catalog_id',
		'index',
		'smiles',
		'hac',
		'hba',
		'hbd',
		'tpsa',
		'log_p',
		'f_csp3',
		'weight',
		'rotatable_bonds',
		'molar_refractivity',
		'similarity',
		'rank',
	].join(',');

	const rows: string = data
		.map((item: HitEntity) => {
			const attributeValues = [
				item.attributes.hac,
				item.attributes.hba,
				item.attributes.hbd,
				item.attributes.tpsa,
				item.attributes.log_p,
				item.attributes.f_csp3,
				item.attributes.weight,
				item.attributes.rotatable_bonds,
				item.attributes.molar_refractivity,
			].map((attr) => attr.toString());

			const externalUrl = item.external_url ? item.external_url.url : ''; // Предполагаем наличие свойства url

			return [
				item.catalog_id,
				item.index.toString(),
				item.smiles,
				...attributeValues,
				item.similarity.toString(),
				item.rank.toString(),
			]
				.map((value: string): string => {
					if (
						value.includes(',') ||
						value.includes('\n') ||
						value.includes('"')
					) {
						// Escape double quotes and wrap in double quotes.
						return `"${value.replace(/"/g, '""')}"`;
					}
					return value;
				})
				.join(',');
		})
		.join('\n');

	return new Blob([header + '\n' + rows], {
		type: 'text/csv;charset=utf-8;',
	});
}
