<template>
	<DraggableElementTemplate
		:id="id"
		:input="input"
		:output="output"
		:isLoading="isLoading"
		:type="type"
		:progress="progress"
		@addConnection="onAddConnection"
	>
		<template v-slot:body>
			<div class="form-container">
				<div class="form-container--header">
					<div class="form-container--header-left">
						<!-- <div class="captionRegular">
							User-Defined Input Form
						</div> -->
						<!-- <div class="captionBold">
							{{ getStepTypeString(type) }}
						</div> -->
						<div class="calloutBold">
							{{ getStepTypeString(type) }}
						</div>
					</div>
					<!-- <div class="form-container--header-right">
						<bUnstyledButton @click="">
							<template v-slot:content>
								<IconStars size="1.25rem" />
							</template>
						</bUnstyledButton>
						<StartPipelineButton
							:isLoading="isLoading"
							@click="onStart"
						/>
					</div> -->
				</div>
				<div class="form-container--body">
					<div class="form-container--body-title">
						<IconInput size="0.75rem" />
						<div class="captionRegular">Input</div>
					</div>
					<slot name="input"></slot>
				</div>
				<div class="form-container--footer">
					<div class="form-container--footer-title">
						<div class="captionRegular">Output</div>
						<IconOutput size="0.75rem" />
					</div>
					<slot name="output"></slot>
					<div
						class="captionBold"
						:style="{
							color: isLoading
								? 'var(--neutral-600)'
								: 'var(--primary-600)',
						}"
					>
						{{ isLoading ? 'Progress' : 'Success' }}
					</div>
				</div>
			</div>
		</template>
	</DraggableElementTemplate>
</template>

<script lang="ts" setup>
import { StepType, getStepTypeString } from '@/shared/api';
import { IconInput, IconOutput } from '@/shared/uikit';
import { ref, type PropType } from 'vue';
import DraggableElementTemplate from './DraggableElementTemplate.vue';

const emit = defineEmits(['addConnection']);

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	input: {
		type: Array as PropType<String[]>,
		required: true,
	},
	output: {
		type: Array as PropType<String[]>,
		required: true,
	},
	data: {
		type: Object as PropType<any>,
		required: true,
	},
	type: {
		type: String as PropType<StepType>,
		required: true,
	},
	comment: {
		type: String,
		required: false,
	},
});

const isLoading = ref(false);
const progress = ref(0);
const duration = 5000;

const onStart = () => {
	isLoading.value = true;
	progress.value = 0;

	const intervalDuration = 100;
	const totalIntervals = duration / intervalDuration;

	const interval = setInterval(() => {
		progress.value += 100 / totalIntervals;
		if (progress.value >= 100) {
			progress.value = 100;
			isLoading.value = false;
			clearInterval(interval);

			setTimeout(() => {
				progress.value = 0;
			}, 1000);

			const customEvent = new CustomEvent('finishExecute', {
				detail: { id: props.id },
				bubbles: true,
			});
			document.getElementById(props.id)?.dispatchEvent(customEvent);
		}
	}, intervalDuration);
};

const onAddConnection = (data: any) => {
	emit('addConnection', data);
};
</script>

<style lang="stylus" scoped>
.form-container
	position relative
	user-select none
	width 100%
	height 100%
	display grid
	grid-template-rows auto 1fr auto
	gap 1rem
	z-index 1
	.form-container--body
		position relative
		padding 0.75rem
		border-radius: 1rem
		padding-top 2.5rem
		background: var(--neutral-50)
		display flex
		flex-direction column
		gap 0.75rem
		.form-container--body-title
			position absolute
			top 0
			left 0
			right 0
			width 100%
			padding 0.37rem
			display flex
			flex-direction row
			align-items center
			justify-content center
			gap 0.5rem
			border-top-left-radius 1rem
			border-top-right-radius 1rem
			background-color var(--neutral-100)
	.form-container--footer
		position relative
		padding 0.75rem
		border-radius: 1rem
		padding-top 2.5rem
		background: var(--neutral-50)
		.captionBold
			width 100%
			text-align right
		.form-container--footer-title
			position absolute
			top 0
			left 0
			right 0
			width 100%
			padding 0.37rem
			display flex
			flex-direction row
			align-items center
			justify-content center
			gap 0.5rem
			border-top-left-radius 1rem
			border-top-right-radius 1rem
			background-color var(--neutral-100)
	.form-container--header
		width 100%
		padding 0.63rem 0.75rem
		padding-right 0
		display flex
		justify-content space-between
		align-items center
		.form-container--header-right
			display flex
			flex-direction row
			justify-content center
			align-items center
			gap 0.5rem
			.calloutRegular
				color var(--neutral-600)
			.b-unstyled-button
				cursor pointer
				padding 0.5rem
				border-radius 50%
			.play
				background-color var(--primary-200)
				color white
				&:hover
					background-color var(--primary-300)
					color white
</style>
