<template>
	<div class="search-druglikeness-container" ref="elementRef">
		<div
			:class="{
				'search-druglikeness-target': true,
				'search-druglikeness-target--active': isOpened || isSelected,
				'search-druglikeness-target--small': isSmall,
			}"
			@click="toggleDropdown"
		>
			{{ druglikenessSelectedOptionsLabel }}
			<IconDropUp
				v-if="isOpened"
				size="1.125rem"
				:color="ThemeColors.primary[600]"
			/>
			<IconDropDown
				v-else
				size="1.125rem"
				:color="ThemeColors.primary[600]"
			/>
		</div>
		<div v-if="isOpened" class="search-druglikeness-dropdown">
			<div
				class="search-druglikeness-item"
				v-for="(option, index) in druglikenessOptions"
				:key="'search-druglikeness-item-key' + index"
				:class="{
					'search-druglikeness-item--active': option.isSelected,
				}"
				@click="selectOption(option)"
			>
				<div class="search-druglikeness-item-info">
					<div class="footnoteBold">{{ option.label }}</div>
				</div>
				<IconCheck v-if="option.isSelected" size="1.125rem" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import type { DruglikenessEntity } from '@/shared/api';
import {
	IconCheck,
	IconDropDown,
	IconDropUp,
	ThemeColors,
} from '@/shared/uikit';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { lbssPrefiltersListModel } from './model';

const { druglikenessOptions, updateFiltersByDruglikeness } =
	lbssPrefiltersListModel;

const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const props = defineProps({
	isSmall: {
		type: Boolean,
		default: false,
	},
});

const isSelected = computed(() => {
	return (
		druglikenessOptions.value.filter((item) => item.isSelected).length > 0
	);
});

const druglikenessSelectedOptionsLabel = computed(() => {
	const selectedItems = druglikenessOptions.value.filter(
		(item) => item.isSelected
	);
	if (selectedItems.length > 0) {
		return selectedItems.map((item) => item.label).join(', ');
	} else {
		return 'Druglikeness';
	}
});

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};

const selectOption = (option: DruglikenessEntity) => {
	option.isSelected = !option.isSelected;
	isOpened.value = false;
	updateFiltersByDruglikeness();

	Analytics.send(AnalyticsEvents.Filter.SELECTED_DRUG_LIKENESS_PREFILTER, {
		label: option.label,
		isSelected: option.isSelected,
	});
};
</script>

<style lang="stylus" scoped>
.search-druglikeness-container
	position: relative
	height: 100%;
	.search-druglikeness-target
		height: 100%;
		padding: 0.625rem 1rem
		display: flex
		align-items: center
		justify-content: space-between
		gap 0.5rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.00625rem;
		border: 1px solid var(--neutral-200);
		color var(--primary-600)
		border-radius 1.75rem
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		&:hover
			background var(--neutral-50)
			transition all 0.2s ease-in-out
		&--active
			color var(--primary-600)
			border-color var(--primary-600) !important
			background var(--primary-200) !important
			transition all 0.2s ease-in-out
		&--small
			padding: 0.5rem 0.75rem
			font-size: 0.75rem;
			line-height: 1rem;
			letter-spacing: 0.00625rem;
			transition all 0.2s ease-in-out

	.search-druglikeness-dropdown
		position: absolute
		left: 0
		margin-top: 0.5rem
		display: flex;
		width 20rem
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 2
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.search-druglikeness-item
			width 100%
			height 2.5rem
			display grid
			grid-template-columns max-content auto
			align-items center
			justify-content space-between
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer
			&--active
				user-select none
			&--disabled
				user-select none
				pointer-events none
				.footnoteBold
					color var(--neutral-400) !important

			.search-druglikeness-item-info
				display flex
				flex-direction column
				align-items flex-start
				justify-content center
				gap 0
				.footnoteBold
					color var(--neutral-700)
				span
					color var(--neutral-400) !important


.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
