import { ResultsSortOption, SearchAttributeType } from '@/shared/api';
import { JobResultViewType, jobResultsFiltersModel } from '@/widgets/job';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	selectedOption: ResultsSortOption;
	options: ResultsSortOption[];
}
const data: IViewModel = {
	selectedOption: ResultsSortOption.defaultOption,
	options: [],
};

const state = reactive(data);

function updateOptions() {
	const { selectedClusteringOption } = jobResultsFiltersModel;

	switch (selectedClusteringOption.value?.type) {
		case JobResultViewType.BemisMurcko:
			state.selectedOption = ResultsSortOption.defaultClusterOption;

			const items = [
				ResultsSortOption.mapWithSortDirection({
					type: SearchAttributeType.SIZE,
				}),
				ResultsSortOption.mapWithSortDirection({
					type: SearchAttributeType.INDEX,
				}),
			].flat();

			state.options = items;
			break;

		case JobResultViewType.Ungrouped:
			state.selectedOption = ResultsSortOption.defaultOption;

			const { attributes } = jobResultsFiltersModel;

			state.options = attributes.value
				.map((attribute) =>
					ResultsSortOption.mapWithSortDirection(attribute)
				)
				.flat();
			break;
		default:
			state.options = [];
			break;
	}
}

export const jobResultsSortModel = {
	...toRefs(state),
	updateOptions,
};
