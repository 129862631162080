<template>
	<transition>
		<div class="ligand-search-smiles-preview-container">
			<div v-if="isValid" class="ligand-search-smiles-preview">
				<canvas
					:id="`smile-preview-${props.smile}`"
					width="300"
					height="300"
				></canvas>
			</div>
			<div
				v-else
				class="ligand-search-smiles-preview ligand-search-smiles-preview--error"
			>
				<bEmptyView
					title="Invalid SMILES"
					description="Please enter a valid SMILES string."
				>
					<template v-slot:image>
						<bImageEmpty :width="200" :height="200" />
					</template>
				</bEmptyView>
			</div>
		</div>
	</transition>
</template>

<script setup lang="ts">
import { drawSmiles } from '@/shared/lib/rdkit';
import { bEmptyView, bImageEmpty } from '@/shared/uikit';
import { onMounted, onUpdated } from 'vue';

const props = defineProps({
	smile: {
		type: String,
		required: true,
	},
	isValid: {
		type: Boolean,
		required: false,
		default: false,
	},
});

onMounted(() => drawCanvas());

onUpdated(() => drawCanvas());

const drawCanvas = () => {
	if (!props.smile) return;
	if (props.isValid) {
		const canvasId = `smile-preview-${props.smile}`;
		const smile = props.smile.split(' ')[0] ?? props.smile;

		drawSmiles(smile, canvasId, 500, 300);
	}
};
</script>

<style lang="stylus" scoped>
.ligand-search-smiles-preview-container
	position: absolute
	top: 4rem
	width 100%
	z-index 100
	transition all 0.25s
	border-radius: 1rem
	box-shadow: 0px 7px 24px 0px rgba(11, 10, 13, 0.19), 0px 3px 6px 0px rgba(11, 10, 13, 0.04);
	background: var(--neutral-50)
	z-index 100
	.ligand-search-smiles-preview
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		border-radius: 1rem
		border: 1px solid var(--neutral-200)
		background: white;
		&--error
			height 300px
</style>
