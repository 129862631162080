import { JobsService, SearchType, type PaginationPageType } from '@/shared/api';
import { type JobEntity } from '@/shared/api/';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	total: number;
	isLoading: boolean;
	jobs: JobEntity[];
	currentPage: number;
	pageLimit: PaginationPageType;
	pageOptions: PaginationPageType[];
}
const data: IViewModel = {
	total: 0,
	isLoading: false,
	jobs: [],
	currentPage: 1,
	pageLimit: {
		title: '10',
		value: 10,
	} as PaginationPageType,
	pageOptions: [
		{
			title: '10',
			value: 10,
		},
		{
			title: '15',
			value: 15,
		},
		{
			title: '25',
			value: 25,
		},
	],
};

const state = reactive(data);

async function fetchTargetDrivenResults(page: number) {
	state.currentPage = page;
	state.isLoading = true;
	try {
		const result = await JobsService.getInstance().fetchAll(
			state.pageLimit.value,
			(page - 1) * state.pageLimit.value,
			SearchType.TARGET
		);
		state.jobs = result.jobs;
		state.total = result.count;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const tdssJobsModel = {
	...toRefs(state),
	fetchTargetDrivenResults,
};
