<template>
	<div class="b-chip" :class="className">
		<slot name="left-icon"></slot>
		{{ label }}
		<slot name="right-icon"></slot>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
	type: { type: String, default: 'primary' },
	isGhost: { type: Boolean, default: true },
	size: { type: String, default: 'default' },
	label: { type: String, default: '' },
});

const className = computed(() => {
	let baseClassName = props.isGhost
		? `b-chip-ghost__${props.type.toLowerCase()}`
		: `b-chip__${props.type.toLowerCase()}`;

	return `${baseClassName} b-chip__${props.size.toLowerCase()}`;
});
</script>

<style lang="stylus" scoped>
.b-chip
	user-select: none
	display: flex
	flex-direction: row
	align-items: center
	justify-content: center
	box-sizing: border-box
	gap: 4px
	transition: all .25s
	font-family: var(--font-family)
	font-style: normal
	font-size: 13px
	line-height: 16px
	white-space: nowrap
	text-transform: uppercase

.b-chip__small
	padding: 0.12rem 0.38rem
	border-radius: 1.75rem
	font-weight: 500
	font-size: 0.6875rem
	line-height: 1rem
.b-chip__default
	padding: 8px 1rem
	border-radius: 0.5rem
	font-weight: 400
	font-size: 0.875rem
	height 2rem

.b-chip__large
	border-radius: 38px
	padding: 10px 12px
	font-weight: 600
	font-size: 15px
	line-height: 18px

.b-chip__primary
	background: var(--primary-500)
	border: none
	color: white
	font-weight: 600

.b-chip__text
	background: rgba(0, 0, 0, 0)
	border: none
	color: var(--primary-600)
	font-weight: 600

.b-chip__secondary
	background: var(--secondary-500)
	color: white
	font-weight: 600

.b-chip__warning
	background: var(--warning-500)
	color: white
	font-weight: 600

.b-chip__success
	background: var(--success-100)
	color: var(--success-600)
	font-weight: 600

.b-chip__danger
	background: var(--danger-100)
	color: var(--danger-600)
	font-weight: 600

.b-chip__tertiary
	background: var(--neutral-0)
	color: var(--neutral-800)
	font-weight: 600

.b-chip__information
	background: var(--information-50)
	color: var(--neutral-800)
	font-weight: 600
.b-chip__neutral
	background: var(--neutral-200)
	color: var(--neutral-800)
	text-transform: none

.b-chip-ghost__neutral
	background: var(--neutral-200)
	color: var(--neutral-800)

.b-chip-ghost__primary
	background: var(--primary-200)
	border: none
	color: var(--primary-600)
	text-transform: none

.b-chip-ghost__text
	background: rgba(0, 0, 0, 0)
	border: none
	color: var(--primary-600)
.b-chip-ghost__secondary
	background: var(--secondary-100)
	border: 1px solid var(--secondary-200)
	color: var(--secondary-600)

.b-chip-ghost__warning
	background: var(--warning-100)
	border: 1px solid var(--warning-200)
	color: var(--warning-600)

.b-chip-ghost__success
	background: var(--success-100)
	border: 1px solid var(--success-200)
	color: var(--success-600)

.b-chip-ghost__danger
	background: var(--danger-100)
	border: 1px solid var(--danger-200)
	color: var(--danger-600)

.b-chip-ghost__tertiary
	background: var(--neutral-0)
	border: 1px solid var(--neutral-200)
	color: var(--neutral-800)

.b-chip-ghost__information
	background: var(--information-50)
	border: 1px solid var(--information-200)
	color: var(--information-600)
</style>
