import {
	SearchAttributeType,
	getSearchAttributeTypeLabel,
	getSearchAttributeTypeToFix,
} from './shared';

export class HitMetadata {
	constructor(
		public smiles: string,
		public external_id: string
	) {}

	static fromObject(data: any): HitMetadata {
		return new HitMetadata(data.smiles, data.external_id);
	}
}

export class ExternalHitMetadata {
	constructor(
		public title: string,
		public url: string
	) {}

	static fromObject(data: any): ExternalHitMetadata | null {
		if (!data) {
			return null;
		}

		return new ExternalHitMetadata(data.title, data.url);
	}
}

export class ShortCollectionEntity {
	constructor(
		public id: string,
		public user_id: string,
		public name: string,
		public size: number,
		public created_at: string
	) {}

	static fromObject(data: any): ShortCollectionEntity {
		return new ShortCollectionEntity(
			data.id,
			data.user_id,
			data.name,
			data.size,
			data.created_at
		);
	}
}
export class HitEntity {
	constructor(
		public catalog_id: string,
		public external_id: string,
		public index: number,
		public smiles: string,
		public external_url: ExternalHitMetadata | null,
		public attributes: Record<SearchAttributeType, number>,
		public similarity: number,
		public rank: number,
		public isFavorite: boolean = false,
		public id = `${catalog_id}-${index}`,
		public collections: ShortCollectionEntity[],
		public alerts: MoleculeAlertsEntity[],
		public quantity: number = 0,
		public purity: number = 0,
		public selected: boolean = false
	) {}

	static fromObject(data: any): HitEntity {
		const attributes: Record<SearchAttributeType, number> = {} as Record<
			SearchAttributeType,
			number
		>;

		const isEnum = (value: any): value is SearchAttributeType => {
			return Object.values(SearchAttributeType).includes(value);
		};

		for (const key in data.attributes) {
			if (isEnum(key)) {
				attributes[key as SearchAttributeType] = data.attributes[key];
			}
		}

		const alerts =
			(data?.alerts &&
				MoleculeAlertsEntity.mapToMoleculeAlertsEntities(
					data.alerts
				)) ??
			[];

		return new HitEntity(
			data.catalog_id,
			data.external_id,
			data.index,
			data.smiles,
			ExternalHitMetadata.fromObject(data.external_url),
			attributes,
			data.similarity,
			data.rank,
			data?.isFavorite || false,
			data?.id ?? data.catalog_id + '-' + data.index,
			data?.collections?.map((collection: any) =>
				ShortCollectionEntity.fromObject(collection)
			) ?? [],
			alerts,
			0,
			0,
			false
		);
	}

	get tooltipString(): string {
		let tooltipString = `#${this.rank} ${this.smiles} \n Similarity : ${this.similarity.toFixed(2)} \n`;
		for (const [type, value] of Object.entries(this.attributes)) {
			tooltipString += `${getSearchAttributeTypeLabel(type as SearchAttributeType)} : ${value.toFixed(
				getSearchAttributeTypeToFix(type as SearchAttributeType)
			)} \n`;
		}

		return tooltipString;
	}
}

export enum MoleculeAlertsType {
	BMS = 'bms',
	Dundee = 'dundee',
	Glaxo = 'glaxo',
	Inpharmatica = 'inpharmatica',
	LINT = 'lint',
	MLSMR = 'mlsmr',
	PAINS = 'pains',
	SureChEMBL = 'sure_chembl',
}

export const MoleculeAlertsTypeColors: Record<MoleculeAlertsType, number[]> = {
	[MoleculeAlertsType.BMS]: [0.078, 0.722, 0.651], // Teal
	[MoleculeAlertsType.Dundee]: [0.937, 0.267, 0.267], // Red
	[MoleculeAlertsType.Glaxo]: [0.976, 0.451, 0.086], // Orange
	[MoleculeAlertsType.Inpharmatica]: [0.392, 0.82, 0.49], // Green
	[MoleculeAlertsType.LINT]: [0.388, 0.498, 0.941], // Blue
	[MoleculeAlertsType.MLSMR]: [0.024, 0.714, 0.831], // Cyan
	[MoleculeAlertsType.PAINS]: [0.518, 0.8, 0.086], // Lime Green
	[MoleculeAlertsType.SureChEMBL]: [0.659, 0.333, 0.969], // Purple
};

export class MoleculeAlertsEntity {
	constructor(
		public type: MoleculeAlertsType,
		public atoms: number[] = [],
		public bonds: number[] = [],
		public description: string = ''
	) {}

	static fromObject(data: any): MoleculeAlertsEntity {
		return new MoleculeAlertsEntity(
			data.type,
			data.atoms,
			data.bonds,
			data.description
		);
	}

	static mapToMoleculeAlertsEntities(
		data: { [key: string]: any } | undefined | null
	): MoleculeAlertsEntity[] {
		if (!data || typeof data !== 'object') {
			return [];
		}

		return Object.entries(data)
			.filter(([key, value]) => {
				return value !== null && typeof value === 'object';
			})
			.map(([key, value]) => {
				const { atoms = [], bonds = [], description = '' } = value;

				return new MoleculeAlertsEntity(
					getMoleculeAlertType(key),
					atoms,
					bonds,
					description
				);
			});
	}
}

export const getTitleForAlert = (type: MoleculeAlertsType): string => {
	switch (type) {
		case MoleculeAlertsType.BMS:
			return 'BMS';
		case MoleculeAlertsType.Dundee:
			return 'Dundee';
		case MoleculeAlertsType.Glaxo:
			return 'Glaxo';
		case MoleculeAlertsType.Inpharmatica:
			return 'Inpharmatica';
		case MoleculeAlertsType.LINT:
			return 'LINT';
		case MoleculeAlertsType.MLSMR:
			return 'MLSMR';
		case MoleculeAlertsType.PAINS:
			return 'PAINS';
		case MoleculeAlertsType.SureChEMBL:
			return 'SureChEMBL';
		default:
			console.error(`Unknown molecule alert type: ${type}`);
			throw new Error(`Unknown molecule alert type: ${type}`);
	}
};

const getMoleculeAlertType = (type: string): MoleculeAlertsType => {
	switch (type) {
		case 'bms':
			return MoleculeAlertsType.BMS;
		case 'dundee':
			return MoleculeAlertsType.Dundee;
		case 'glaxo':
			return MoleculeAlertsType.Glaxo;
		case 'inpharmatica':
			return MoleculeAlertsType.Inpharmatica;
		case 'lint':
			return MoleculeAlertsType.LINT;
		case 'mlsmr':
			return MoleculeAlertsType.MLSMR;
		case 'pains':
			return MoleculeAlertsType.PAINS;
		case 'sure_chembl':
			return MoleculeAlertsType.SureChEMBL;
		default:
			//TODO: Remove this after all the alerts are fixed
			return MoleculeAlertsType.BMS;
	}
};

const toHex = (value: number): string => {
	const hex = Math.round(value * 255).toString(16);
	return hex.length === 1 ? '0' + hex : hex;
};

export const MoleculeAlertsTypeColorsHex: Record<MoleculeAlertsType, string> =
	Object.fromEntries(
		Object.entries(MoleculeAlertsTypeColors).map(([key, [r, g, b]]) => [
			getMoleculeAlertType(key),
			`#${toHex(r)}${toHex(g)}${toHex(b)}`,
		])
	) as Record<MoleculeAlertsType, string>;
