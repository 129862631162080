import { Analytics, AnalyticsEvents } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api/auth';
import { firebaseErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

const state = reactive({
	isSent: false,
	isLoading: false,
});

async function sendRecoveryEmail(email: string) {
	Analytics.send(AnalyticsEvents.ResetPassword.SEND_RECOVERY_EMAIL);
	if (!email) {
		return;
	}

	try {
		state.isLoading = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.sendPasswordResetEmail(email);
		state.isSent = true;
		Analytics.send(
			AnalyticsEvents.ResetPassword.SEND_RECOVERY_EMAIL_SUCCESS
		);

		localStorage.setItem(
			'sendedVerifyPassword',
			JSON.stringify(Date.now())
		);
	} catch (error) {
		firebaseErrorHandler(error);
		Analytics.send(
			AnalyticsEvents.ResetPassword.SEND_RECOVERY_EMAIL_FAILURE
		);
	} finally {
		state.isLoading = false;
	}
}

export const resetModel = {
	...toRefs(state),
	sendRecoveryEmail,
};
