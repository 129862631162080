<template>
	<div class="target-driven-search-input">
		<div class="target-driven-search-input--title">
			<div class="subheadlineBold">Paste your Target</div>
		</div>
		<bInputWithSelect
			ref="inputWithSelect"
			v-model="searchQuery"
			placeholder="Search for a UniProt ID here to find your Target (Protein)."
			size="large"
			:isSelectable="!selectedTarget"
		>
			<template v-slot:right-icon v-if="searchQuery.length > 0">
				<div class="target-driven-search-input--controls">
					<TargetLinkButton
						v-if="selectedTarget"
						:id="selectedTarget.id"
						:data="selectedTarget.external_url"
					/>
					<bUnstyledButton @onClick="onClearInput">
						<template v-slot:content>
							<IconClose size="1.25rem" />
						</template>
					</bUnstyledButton>
				</div>
			</template>
			<template v-if="selectedTarget" v-slot:selected-target>
				<div
					class="target-driven-search-input--target"
					@click="onClearInput"
				>
					<div class="footnoteSemiBold">
						{{ selectedTarget.name }}
					</div>
					<div class="captionBold">
						{{ selectedTarget.formattedProbesCount }}
					</div>
				</div>
			</template>
			<template v-slot:dropdown>
				<TDSSProteinsPreview
					v-if="searchQuery.length > 0 && !selectedTarget"
					:results="targets"
					:isLoading="false"
					:selectedTarget="searchQuery"
					@onClick="onSelectProtein"
				/>
			</template>
		</bInputWithSelect>
	</div>
</template>

<script setup lang="ts">
import { TargetLinkButton } from '@/features/target-driven-search';
import type { TargetEntity } from '@/shared/api';
import { IconClose, bInputWithSelect, bUnstyledButton } from '@/shared/uikit';
import { defineEmits, onMounted, ref } from 'vue';
import TDSSProteinsPreview from './TDSSProteinsPreview.vue';
import { tdssInputModel } from './model';

const emit = defineEmits(['onNext', 'onClear']);
const inputWithSelectRef = ref<HTMLElement | null>(null);
const { searchQuery, selectedTarget, targets, clear, fetchProteinTargets } =
	tdssInputModel;

const onClearInput = () => {
	searchQuery.value = '';
	targets.value = [];
	selectedTarget.value = null;
	emit('onClear');
};

const onSelectProtein = (target: TargetEntity) => {
	selectedTarget.value = target;
	emit('onNext', target);
};

onMounted(() => {
	// fetchProteinTargets('');
});
</script>

<style lang="stylus" scoped>
.target-driven-search-input
	display flex
	flex-direction column
	align-items flex-start
	justify-content flex-start
	gap 1rem
	position: relative
	width 100%
	&--title
		.subheadlineBold
			padding-left 1.25rem

	&--controls
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		gap 0.37rem
		.b-unstyled-button
			padding 0.25rem
	&--target
		position: absolute
		top 0.5rem
		left 1.5rem
		width calc(100% - 10rem)
		height calc(100% - 1rem)
		background white
		display: flex
		flex-direction: column
		align-items: flex-start
		justify-content: center
		transition all 0.25s
		cursor text
		.captionBold
			color var(--neutral-400)
</style>
