<template>
	<div class="job-results-list-widget">
		<div
			v-if="job"
			class="job-results-list-widget--header"
			:class="{
				'job-results-list-widget--header--shadow':
					isShadow && !isLoading,
			}"
		>
			<SwitchCardGrid />

			<div class="job-results-list-widget--header-right">
				<JobResultsSortDropdown />
				<JobSettingsDropdown :job="job" />
			</div>
		</div>

		<div
			:class="{
				'job-results-list-widget--body-grid': isGrid,
				'job-results-list-widget--body-list': !isGrid,
			}"
			@scroll="onScroll"
		>
			<template v-if="isVisibleList">
				<template
					v-if="
						selectedClusteringOption &&
						selectedClusteringOption.type ===
							JobResultViewType.Ungrouped
					"
				>
					<HitCard
						v-for="(hit, index) in hits"
						:key="'molecule-key-' + index"
						:hit="hit"
						:index="index"
						:collectionItems="items"
						:isGrid="isGrid"
					/>
				</template>
				<template
					v-if="
						selectedClusteringOption &&
						selectedClusteringOption.type ===
							JobResultViewType.BemisMurcko
					"
				>
					<ClusterCard
						v-for="(cluster, index) in clusters"
						:key="'cluster-key-' + index"
						:cluster="cluster"
						:index="index"
						@openCluster="onOpenCluster(cluster)"
						:isGrid="isGrid"
					/>
				</template>

				<bPagination
					:class="{
						'empty-wrapper--is-grid': isGrid,
					}"
					:limit="pageLimit.value"
					:total="total"
					:currentPage="currentPage"
					@currentChange="changePage"
				>
					<template v-slot:select-page>
						<div class="pagination-select-page">
							<bSelect
								v-model="pageLimit"
								:options="pageOptions"
								size="small"
								position="top"
							/>
						</div>
					</template>
				</bPagination>
			</template>
			<bEmptyView
				v-if="isVisibleEmptyView"
				title="No results found."
				description="Try to change your search criteria."
				:class="{
					'empty-wrapper--is-grid': isGrid,
				}"
			>
				<template v-slot:image>
					<bImageEmpty2 :width="200" :height="200" />
				</template>
			</bEmptyView>

			<template v-if="isLoading">
				<div class="empty-view"><bLoader size="4" /></div>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ClusterCard } from '@/entities/cluster';
import { HitCard } from '@/entities/hit';
import { collectionsItemsListModel } from '@/features/collections';
import { SwitchCardGrid, switchCardGridModel } from '@/features/hit';
import { JobResultsSortDropdown, JobSettingsDropdown } from '@/features/job';
import { navigationTabsModel } from '@/features/navigation';
import {
	NavigationTabType,
	SearchType,
	type ClusterEntity,
} from '@/shared/api';
import {
	bEmptyView,
	bImageEmpty2,
	bLoader,
	bPagination,
	bSelect,
} from '@/shared/uikit';
import { JobResultViewType, jobDetailsModel } from '@/widgets/job/';
import { computed, onMounted, ref } from 'vue';
import { jobResultsFiltersModel } from '../job-results-filters-widget';
import { jobResultsListModel } from './model';
const isShadow = ref(false);
const {
	fetchJobResults,
	isLoading,
	hits,
	clusters,
	total,
	pageLimit,
	pageOptions,
	currentPage,
} = jobResultsListModel;

const { fetchCollections, items } = collectionsItemsListModel;
const { selectedClusteringOption } = jobResultsFiltersModel;
const { job } = jobDetailsModel;
const { isGrid } = switchCardGridModel;

const isVisibleList = computed(() => {
	if (!selectedClusteringOption.value) {
		return false;
	}

	if (selectedClusteringOption.value.type === JobResultViewType.Ungrouped) {
		return hits.value.length > 0 && !isLoading.value;
	} else {
		return clusters.value.length > 0 && !isLoading.value;
	}
});

const isVisibleEmptyView = computed(() => {
	if (selectedClusteringOption.value?.type === JobResultViewType.Ungrouped) {
		return hits.value.length === 0 && !isLoading.value;
	} else {
		return clusters.value.length === 0 && !isLoading.value;
	}
});

const onScroll = (e: any) => {
	const el = e.target;
	isShadow.value = el.scrollTop > 0;
};

const changePage = (page: number) => {
	fetchJobResults(page);
};

const onOpenCluster = (cluster: ClusterEntity) => {
	const { addTab } = navigationTabsModel;

	if (!job.value) {
		return;
	}

	addTab({
		id: `${job.value.id}-${cluster.index}`,
		title: `Cluster #${cluster.index}`,
		description: cluster.scaffold_smiles,
		path: `/job/${job.value.id}/cluster/${cluster.index}`,
		type:
			job.value.parameters.type === SearchType.LIGAND
				? NavigationTabType.LIGAND
				: NavigationTabType.TARGET,
	});
};

onMounted(() => {
	fetchCollections();
	fetchJobResults(1);
});
</script>

<style lang="stylus" scoped>
.job-results-list-widget
	width 100%
	height 100%
	position relative
	&--header
		position sticky
		padding 1.25rem 1rem
		background white
		z-index 1
		display flex
		flex-direction row
		justify-content space-between
		align-items center
		z-index 20
		.job-results-list-widget--header-right
			display flex
			flex-direction row
			justify-content flex-start
			align-items center
			gap 0.5rem

	.job-results-list-widget--header--shadow
		border-radius 1rem
		box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05);
	&--body-list
		overflow-y auto
		overflow-x hidden
		height calc(100vh - 11.25rem)
		padding 1rem
		padding-bottom 6rem
		display flex
		flex-direction column
		align-items center
		justify-content flex-start
		gap 1.25rem
		&::-webkit-scrollbar
			width 0.25rem
		.footnoteRegular
			color var(--neutral-600)
		.empty-view
			width 100%
			height 100%
			display flex
			flex-direction column
			align-items center
			justify-content center
	&--body-grid
		overflow-y auto
		overflow-x hidden
		height calc(100vh - 11.25rem)
		padding 1rem
		padding-bottom 6rem
		display grid
		grid-template-columns repeat(3, minmax(0, 1fr))
		grid-template-rows min-content
		gap 1rem
		&::-webkit-scrollbar
			width 0.25rem
		.footnoteRegular
			color var(--neutral-600)
		.empty-wrapper--is-grid
			grid-column 1 / -1
		.empty-view
			grid-column-start 1
			grid-column-end -1
</style>
