<template>
	<div
		v-if="collection"
		class="collection-card"
		:class="{
			'collection-card--empty': collection.collectibles.length === 0,
		}"
		@click="onClick"
	>
		<div
			v-if="collection.collectibles.length > 0"
			class="collection-card-canvas-container"
		>
			<div
				class="collection-card-canvas"
				v-for="(smiles, index) in collection.collectibles.slice(0, 4)"
				:key="`smiles-collection-${collection.id}-${index}-${smiles.smiles}`"
			>
				<canvas
					:id="`smiles-collection-${collection.id}-${index}-${smiles.smiles}`"
					width="100"
					height="100"
				>
				</canvas>
			</div>
			<div
				v-if="collection.size > 4"
				class="collection-card-smiles-count"
			>
				<div class="subheadlineBold">+{{ collection.size - 4 }}</div>
			</div>
		</div>
		<div v-else class="collection-card--empty-container">
			<bImageEmpty :width="100" :height="100" />
		</div>
		<div class="collection-card--content">
			<div class="subheadlineBold">{{ collection.name }}</div>
			<div class="bodySmallSemiBold">
				{{ collection.size }}
				{{ collection.size === 1 ? 'molecule' : 'molecules' }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { CollectionEntity } from '@/shared/api/';
import { drawSmiles } from '@/shared/lib/rdkit';
import { bImageEmpty } from '@/shared/uikit';
import { onMounted, onUpdated } from 'vue';

const emit = defineEmits(['onClick']);
const props = defineProps({
	collection: {
		type: Object as () => CollectionEntity,
		required: true,
	},
});

onUpdated(() => drawCanvas());
onMounted(() => drawCanvas());

const drawCanvas = () => {
	if (props.collection.collectibles.length === 0) return;
	for (let i = 0; i < props.collection.collectibles.slice(0, 4).length; i++) {
		const smiles = props.collection.collectibles[i].smiles;
		const canvasId = `smiles-collection-${props.collection.id}-${i}-${smiles}`;
		drawSmiles(smiles, canvasId, 100, 100);
	}
};

const onClick = () => {
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.collection-card
	height 18rem
	display flex
	flex-direction column
	align-items center
	justify-content flex-start
	gap 0.75rem
	&--empty
		pointer-events none
	&:hover
		cursor pointer
		.collection-card-canvas-container
			box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
			transition all 0.25s
	&--content
		display flex
		flex-direction column
		align-items center
		justify-content flex-start
		gap 0.12rem
	&--empty-container
		width 100%
		height 100%
		padding 0.75rem
		border-radius 1rem
		background var(--neutral-100)
		display flex
		align-items center
		justify-content center
		transition all 0.25s
	.collection-card-canvas-container
		position relative
		padding 0.75rem
		border-radius 1rem
		background var(--neutral-100)
		width 100%
		height 100%
		display grid
		grid-template-columns repeat(2, minmax(0, 1fr))
		grid-template-rows repeat(2, minmax(0, 1fr))
		gap 0.5rem
		cursor pointer
		transition all 0.25s
		.collection-card-canvas
			display flex
			align-items center
			justify-content center
			background white
			border-radius 0.5rem
		.collection-card-smiles-count
			position absolute
			bottom 0.75rem
			right 0.75rem
			border-radius: 0.5rem
			width: calc(calc(100% - 2rem) / 2)
			height: calc(calc(100% - 2rem) / 2)
			background: rgba(30, 41, 59, 0.7)
			display flex
			align-items center
			justify-content center
			.subheadlineBold
				color white
				letter-spacing: 0.00625rem
				white-space nowrap
				overflow hidden
				text-overflow ellipsis
</style>
