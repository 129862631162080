<template>
	<div class="smiles-column-card-wrapper">
		<canvas
			:id="`message-smiles-${props.smiles}-${props.id}`"
			width="170"
			height="120"
		>
		</canvas>
		<div class="smiles-column-card-wrapper--copy">
			<bUnstyledButton @onClick="copyToClipboardContent">
				<template v-slot:content>
					<IconCopy size="1.25rem" />
				</template>
			</bUnstyledButton>
		</div>
		<div class="smiles-column-card-wrapper--zoom">
			<bUnstyledButton @onClick="openPreview">
				<template v-slot:content>
					<IconZoomIn size="1.25rem" />
				</template>
			</bUnstyledButton>
		</div>
	</div>
</template>

<script setup lang="ts">
import { previewSmiles3DModel } from '@/features/job';
import { HitEntity } from '@/shared/api';
import { copyToClipboard } from '@/shared/lib';
import { drawSmiles } from '@/shared/lib/rdkit';
import { IconCopy, IconZoomIn, bUnstyledButton } from '@/shared/uikit';

import { onMounted, onUpdated } from 'vue';

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	smiles: {
		type: String,
		required: true,
	},
});

const copyToClipboardContent = () => {
	copyToClipboard(props.smiles);
};

onMounted(() => drawCanvas());
onUpdated(() => drawCanvas());

const drawCanvas = () => {
	const smiles = props.smiles.split(' ')[0] ?? props.smiles;
	drawSmiles(smiles, `message-smiles-${props.smiles}-${props.id}`, 170, 120);
};

const openPreview = () => {
	previewSmiles3DModel.previewMolecule.value = HitEntity.fromObject({
		catalog_id: '',
		index: 0,
		smiles: props.smiles,
		external_url: null,
		attributes: {},
		similarity: 0,
		rank: 0,
		isFavorite: false,
		id: `smiles-card-${props.smiles}-${props.id}`,
		collections: [],
	});
};
</script>

<style lang="stylus" scoped>
.smiles-column-card-wrapper
	pointer-events auto !important
	position relative
	width 100%
	height 100%
	display flex
	justify-content center
	align-items center
	background white
	border-radius 0.5rem
	overflow hidden
	&--zoom
		position absolute
		bottom 0
		left 0
		z-index 1
	&--copy
		position absolute
		top 0
		left 0
		z-index 1
</style>
