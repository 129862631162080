export const isMobilePlatform = () => {
	if (typeof navigator === 'undefined' || typeof window === 'undefined') {
		return false;
	}

	try {
		const userAgent = navigator.userAgent;
		const platform = navigator.platform;
		const maxWidth = window.matchMedia(
			'only screen and (max-width: 760px)'
		).matches;
		const touchSupport = 'ontouchstart' in document.documentElement;
		const mobilePattern =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

		return (
			mobilePattern.test(userAgent) ||
			mobilePattern.test(platform) ||
			maxWidth ||
			(touchSupport && !!userAgent.match(/Mobi/))
		);
	} catch (error) {
		return true;
	}
};

export const saveAs = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	window.URL.revokeObjectURL(url);
};
