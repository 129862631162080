<template>
	<div class="switch-molecule-grid">
		<bUnstyledButton
			:class="{ 'b-unstyled-button--active': isGrid }"
			@onClick="toggleGrid"
		>
			<template v-slot:content>
				<IconGrid
					size="1.25rem"
					:color="
						isGrid
							? ThemeColors.primary[600]
							: ThemeColors.neutral[400]
					"
				/>
			</template>
		</bUnstyledButton>
		<bUnstyledButton
			:class="{ 'b-unstyled-button--active': !isGrid }"
			@onClick="toggleGrid"
		>
			<template v-slot:content>
				<IconList
					size="1.25rem"
					:color="
						!isGrid
							? ThemeColors.primary[600]
							: ThemeColors.neutral[400]
					"
				/>
			</template>
		</bUnstyledButton>
	</div>
</template>

<script setup lang="ts">
import {
	IconGrid,
	IconList,
	ThemeColors,
	bUnstyledButton,
} from '@/shared/uikit';
import { switchCardGridModel } from './model';

const { isGrid, toggleGrid } = switchCardGridModel;
</script>

<style lang="stylus" scoped>
.switch-molecule-grid
	display: flex
	flex-direction: row
	align-items: center
	justify-content center
	width 5em
	height 2.5rem
	border-radius: 1.75rem;
	border: 1px solid var(--neutral-200);

	.b-unstyled-button
		height 100%
		width 50%
		display flex
		align-items center
		justify-content center
		border-radius 50%
	.b-unstyled-button--active
		background: var(--primary-200)
</style>
