import type { NavigationTabType } from '@/shared/api';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	title: string;
	description: string;
}
const data: IViewModel = {
	isLoading: false,
	title: '',
	description: '',
};

const state = reactive(data);

function setHeaderInfo(navigationType: NavigationTabType) {}

function clear() {
	state.isLoading = false;
	state.title = '';
	state.description = '';
}

export const navigationHeaderModel = {
	...toRefs(state),
	setHeaderInfo,
	clear,
};
