import { navigationTabsModel } from '@/features/navigation';
import {
	CatalogService,
	ForeignObjectEntity,
	JobsService,
	NavigationTabType,
	PipelineService,
	SearchType,
	StepType,
} from '@/shared/api/';
import { ZoomableSVG, ocularRestErrorHandler } from '@/shared/lib';
import type { DashboardElement } from '@/shared/lib/svg/entities';
import { Notification, notificationModel } from '@/shared/uikit';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isInited: boolean;
	isLoading: boolean;
	steps: DashboardElement[];
	jobSteps: ForeignObjectEntity[];
	dashboard: ZoomableSVG | null;
}
const data: IViewModel = {
	isInited: false,
	isLoading: false,
	steps: [],
	jobSteps: [],
	dashboard: null,
};

const state = reactive(data);

async function fetchJobPipeline(id: string) {
	state.isLoading = true;
	try {
		state.jobSteps =
			await PipelineService.getInstance().fetchJobPipeline(id);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

async function createJobBySteps(steps: ForeignObjectEntity[]) {
	state.isLoading = true;
	try {
		const mappedSteps = steps.map((step) => ({
			id: step.id,
			input: step.input,
			output: step.output,
			data: step.data,
			comment: step.comment,
		}));

		const newJob =
			await JobsService.getInstance().createJobBySteps(mappedSteps);

		if (newJob) {
			const { addTab } = navigationTabsModel;

			addTab({
				id: newJob.id,
				title:
					newJob.parameters.type === SearchType.LIGAND
						? 'Ligand Based'
						: 'Target Driven',
				description: newJob.query,
				path: `/job/${newJob.id}`,
				type:
					newJob.parameters.type === SearchType.LIGAND
						? NavigationTabType.LIGAND
						: NavigationTabType.TARGET,
			});
		}
	} catch (error: any) {
		const delay = 3000;
		const type = 'error';
		const id = 'firebase-error-handler' + Date.now();

		notificationModel.addNotification(
			new Notification(
				id,
				type,
				'Something went wrong',
				error?.response?.data?.detail ?? 'Pipeline should be linear.',
				null,
				delay
			)
		);
	} finally {
		state.isLoading = false;
	}
}

async function updateCallback() {
	state.isLoading = true;
	try {
		const catalogLoaderId = state.dashboard?.elementManager.elements.find(
			(step) => step.data.type === StepType.CATALOG_LOADER
		)?.data.data.catalog_id;

		if (catalogLoaderId) {
			const catalogs =
				await CatalogService.getInstance().fetchLocalCatalogs();

			const attributes =
				await CatalogService.getInstance().fetchAttributes(
					catalogLoaderId
				);

			const catalog = catalogs.find(
				(catalog) => catalog.id === catalogLoaderId
			);

			const targetBasedCard =
				state.dashboard?.elementManager.elements.find(
					(step) => step.data.type === StepType.TARGET_BASED_SEARCH
				);
			const ligandBasedCard =
				state.dashboard?.elementManager.elements.find(
					(step) => step.data.type === StepType.LIGAND_BASED_SEARCH
				);

			const attributesCard =
				state.dashboard?.elementManager.elements.find(
					(step) => step.data.type === StepType.ATTRIBUTE_FILTER
				);

			const catalogTopK = catalog?.max_top_k ?? 10;

			const targetTopK =
				targetBasedCard?.data.data.top_k < catalogTopK
					? targetBasedCard?.data.data.top_k
					: catalogTopK;
			const ligandTopK =
				ligandBasedCard?.data.data.top_k < catalogTopK
					? ligandBasedCard?.data.data.top_k
					: catalogTopK;

			targetBasedCard?.data.updateTopK(targetTopK);
			ligandBasedCard?.data.updateTopK(ligandTopK);

			attributesCard?.data.updateAttributeFilter(
				attributes.map((attribute) => ({
					attribute: attribute.type,
					minimum: attribute.absolute_min,
					maximum: attribute.absolute_max,
				}))
			);

			attributesCard?.updateSize();

			state.dashboard?.draw();
		}
	} catch (error: any) {
		const delay = 3000;
		const type = 'error';
		const id = 'firebase-error-handler' + Date.now();

		notificationModel.addNotification(
			new Notification(
				id,
				type,
				error?.detail ?? 'Something went wrong',
				error?.message ?? 'Something went wrong',
				null,
				delay
			)
		);
	} finally {
		state.isLoading = false;
	}
}

export const pipelineModel = {
	...toRefs(state),
	fetchJobPipeline,
	createJobBySteps,
	updateCallback,
};
