<template>
	<div ref="elementRef" class="nevelty-filter-container" @click="openMenu">
		<div v-if="selectedOption" class="nevelty-filter-container--target">
			<section>
				<div class="bodySmallRegular">Method</div>
				<div class="bodySmallSemiBold">
					{{ selectedOption.name }}
				</div>
			</section>
			<IconArrowUpDown size="1rem" />
		</div>
	</div>

	<Teleport to="body">
		<div
			v-if="isOpened"
			class="nevelty-filter-dropdown"
			:style="dropdownStyle"
			ref="dropdownRef"
		>
			<div
				v-for="option in options"
				class="nevelty-filter-dropdown--item"
				:class="{
					'nevelty-filter-dropdown--item--active':
						option.type === selectedOption?.type,
				}"
				@click="onClickOption(option)"
			>
				<div class="bodySmallSemiBold">
					{{ option.name }}
				</div>
				<IconCheck
					v-if="option.type === selectedOption?.type"
					size="1rem"
				/>
			</div>
		</div>
	</Teleport>
</template>

<script setup lang="ts">
import type { NoveltyFilterMethodType } from '@/shared/api';
import { IconArrowUpDown, IconCheck } from '@/shared/uikit';
import { onBeforeUnmount, onMounted, ref, type PropType } from 'vue';
import { NoveltyFilterOptions } from './entities';

const options = ref<NoveltyFilterOptions[]>(NoveltyFilterOptions.options);
const selectedOption = ref<NoveltyFilterOptions | null>(null);
const props = defineProps({
	method: {
		type: String as PropType<NoveltyFilterMethodType>,
		required: true,
	},
});

const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);
const dropdownRef = ref<HTMLElement | null>(null);
const dropdownStyle = ref({
	top: '0px',
	left: '0px',
});

const close = (e: MouseEvent) => {
	if (dropdownRef.value && !dropdownRef.value.contains(e.target as Node)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	selectedOption.value =
		options.value.find((catalog) => catalog.type === props.method) || null;
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const openMenu = (e: MouseEvent) => {
	e.preventDefault();
	isOpened.value = true;

	const { clientX: mouseX, clientY: mouseY } = e;
	const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

	const dropdownWidth = dropdownRef.value?.offsetWidth || 0;
	const dropdownHeight = dropdownRef.value?.offsetHeight || 0;

	let top = mouseY;
	let left = mouseX;

	if (mouseY + dropdownHeight > windowHeight) {
		top = windowHeight - dropdownHeight;
	}
	if (mouseX + dropdownWidth > windowWidth) {
		left = windowWidth - dropdownWidth;
	}

	dropdownStyle.value = {
		top: `${top}px`,
		left: `${left}px`,
	};
};

const onClickOption = (catalog: NoveltyFilterOptions) => {
	isOpened.value = false;
	selectedOption.value = catalog;
};
</script>

<style lang="stylus" scoped>
.nevelty-filter-container
	position: relative
	&--target
		display grid
		grid-template-columns 1fr auto
		align-items center
		gap 0.5rem
		padding 0.5rem
		border-radius 0.5rem
		background white
		border 1px solid var(--neutral-200)
		cursor pointer
		&:hover
			border-color var(--neutral-300)
		.bodySmallRegular
			font-size 0.5rem


.nevelty-filter-dropdown
	position: absolute
	z-index: 9999
	margin-top: 0.5rem
	display: flex;
	padding: 0.625rem 0
	flex-direction: column;
	align-items: flex-start;
	gap: 0.25rem;
	border: 1px solid rgba(0, 0, 0, 0.10);
	border-radius: 0.25rem;
	background: var(--neutral-50);
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
	&--item
		width 15rem
		display grid
		grid-template-columns 1fr auto
		align-items center
		justify-content flex-start
		padding: 0.625rem 1rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem; /* 142.857% */
		letter-spacing: 0.00625rem;
		color var(--neutral-700)
		white-space nowrap
		&:hover
			background: var(--neutral-100)
			cursor pointer
		&--active
			pointer-events none
			background: var(--neutral-100)
</style>
