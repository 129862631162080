export type Node = {
	id: string;
	output: string[];
	input: string[];
};

export type Graph = {
	[nodeId: string]: { [neighborId: string]: number };
};

export interface Position {
	x: number;
	y: number;
}

export function calculateGraphPositions(
	graph: Graph,
	startNode: string,
	props: any,
	contentWidth: number,
	contentHeight: number
): { [key: string]: Position } {
	const positions: { [key: string]: Position } = {};
	const visited: Set<string> = new Set();
	const queue: { id: string; x: number; y: number }[] = [
		{
			id: startNode,
			x: contentWidth / 1.5,
			y: contentHeight / 2,
		},
	];
	const levelMap: { [key: string]: number } = { [startNode]: 0 };
	const levels: { [level: number]: string[] } = {};

	while (queue.length > 0) {
		const { id, x, y } = queue.shift()!;
		if (visited.has(id)) continue;
		visited.add(id);

		const level = levelMap[id];
		if (!levels[level]) {
			levels[level] = [];
		}
		levels[level].push(id);

		positions[id] = { x, y };

		const neighbors = graph[id];
		for (const neighbor in neighbors) {
			if (!visited.has(neighbor)) {
				queue.push({
					id: neighbor,
					x: x - (props.width + props.width), // Move to the left
					y: y,
				});
				levelMap[neighbor] = level + 1;
			}
		}
	}

	// Center nodes at the same level
	for (const level in levels) {
		const nodes = levels[level];
		const totalHeight =
			nodes.length * (props.height + props.height / 2) - 50;
		const startY = (contentHeight - totalHeight) / 2;

		nodes.forEach((node, index) => {
			positions[node].y = startY + index * (props.height + 50);
		});
	}

	// Center all elements horizontally
	const minX = Math.min(...Object.values(positions).map((pos) => pos.x));
	const maxX = Math.max(...Object.values(positions).map((pos) => pos.x));
	const graphWidth = maxX - minX + props.width;
	const centerXOffset = (contentWidth - graphWidth) / 2 - minX;

	const additionalOffset = 750; // Adjust this value as needed
	const offsetX = centerXOffset + additionalOffset;
	const offsetY = 500;

	for (const id in positions) {
		positions[id].x += offsetX;
		positions[id].y += offsetY;
	}

	return positions;
}

export function buildGraph(nodes: Node[]): Graph {
	const graph: Graph = {};

	nodes.forEach((node) => {
		graph[node.id] = {};
		node.output.forEach((outputID) => {
			graph[node.id][outputID] = 1;
		});
	});

	return graph;
}
