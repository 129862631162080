import { router } from '@/app/providers';
import { AuthService, EmailAuthProvider } from '@/shared/api';
import { firebaseErrorHandler } from '@/shared/lib';
import { pageLoaderModel } from '@/shared/uikit';

async function isExpiredOobCode(code: string): Promise<boolean> {
	try {
		pageLoaderModel.isLoading.value = true;
		const provider = new EmailAuthProvider();
		const authService = AuthService.getInstance(provider);
		await authService.isExpiredOobCode(code);
		return false;
	} catch (error: any) {
		firebaseErrorHandler(error);
		router.push('/link-expired');
		return true;
	} finally {
		pageLoaderModel.isLoading.value = false;
	}
}

export const checkExpiredLinkModel = {
	isExpiredOobCode,
};
