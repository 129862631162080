import { MoleculeAlertsType } from '@/shared/api';

export enum JobResultViewType {
	BemisMurcko = 'Scaffolds (Bemis Murcko)',
	Ungrouped = 'Ungrouped',
}

export class JobResultViewOption {
	static key = 'JobResultViewType';
	label: string;
	isSelected: boolean = false;
	type: JobResultViewType;

	constructor(label: string, isSelected: boolean, type: JobResultViewType) {
		this.label = label;
		this.isSelected = isSelected;
		this.type = type;
	}

	static get options(): JobResultViewOption[] {
		return [
			new JobResultViewOption(
				JobResultViewType.Ungrouped,
				false,
				JobResultViewType.Ungrouped
			),
			new JobResultViewOption(
				JobResultViewType.BemisMurcko,
				false,
				JobResultViewType.BemisMurcko
			),
		];
	}
}

export class AlertFilterOption {
	label: string;
	isSelected: boolean = false;
	_state: number = 0;

	get state(): number {
		return this._state;
	}
	set state(value: number) {
		this._state = value;
	}

	get stateColor(): string {
		switch (this.state) {
			case 1:
				return 'primary';
			case 2:
				return 'danger';
			case 0:
				return 'tertiary';
			default:
				return 'neutral';
		}
	}

	constructor(label: string, isSelected: boolean, state: number = 0) {
		this.label = label;
		this.isSelected = isSelected;
		this.state = state;
	}

	static get options(): AlertFilterOption[] {
		return Object.values(MoleculeAlertsType).map((value) => {
			return new AlertFilterOption(value, true, 1);
		});
	}

	onClick() {
		if (this.state === 2) {
			this.state = 0;
		} else {
			this.state += 1;
		}
	}

	onReset() {
		this.state = 0;
	}
}
