<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 14 14"
		fill="none"
	>
		<g clip-path="url(#clip0_3292_1935)">
			<path
				d="M7.00008 1.16699C3.77425 1.16699 1.16675 3.77449 1.16675 7.00033C1.16675 10.2262 3.77425 12.8337 7.00008 12.8337C10.2259 12.8337 12.8334 10.2262 12.8334 7.00033C12.8334 3.77449 10.2259 1.16699 7.00008 1.16699ZM9.50841 9.50866C9.28091 9.73616 8.91341 9.73616 8.68591 9.50866L7.00008 7.82283L5.31425 9.50866C5.08675 9.73616 4.71925 9.73616 4.49175 9.50866C4.26425 9.28116 4.26425 8.91366 4.49175 8.68616L6.17758 7.00033L4.49175 5.31449C4.26425 5.08699 4.26425 4.71949 4.49175 4.49199C4.71925 4.26449 5.08675 4.26449 5.31425 4.49199L7.00008 6.17783L8.68591 4.49199C8.91341 4.26449 9.28091 4.26449 9.50841 4.49199C9.73591 4.71949 9.73591 5.08699 9.50841 5.31449L7.82258 7.00033L9.50841 8.68616C9.73008 8.90783 9.73008 9.28116 9.50841 9.50866Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3292_1935">
				<rect width="14" height="14" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
