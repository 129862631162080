import { ForeignObjectEntity } from '@/shared/api';

export class Point {
	constructor(
		public x: number,
		public y: number
	) {}

	static fromObject(obj: any): Point {
		return new Point(obj.x, obj.y);
	}
}

export interface IDraggable {
	handleZoom(event: WheelEvent): void;
	handleMouseDown(event: MouseEvent, props?: any): void;
	handleMouseMove(event: MouseEvent, props?: any): void;
	handleMouseUp(event: MouseEvent, props?: any): void;
	handleClick(event: MouseEvent): void;
}

export class ElementConnection {
	constructor(
		public id: string,
		public input: string,
		public output: string,
		public isSelected: boolean = false,
		public isExecuted: boolean = false
	) {}

	static fromObject(obj: any): ElementConnection {
		return new ElementConnection(
			obj.id,
			obj.input,
			obj.output,
			obj?.isSelected ?? false,
			obj?.isExecuted ?? false
		);
	}
}
export class DashboardElement {
	private _connections: ElementConnection[] = [];

	get connections(): ElementConnection[] {
		if (this._connections.length === 0) {
			(this.data.input ?? []).forEach((inputId, index) => {
				this._connections.push(
					ElementConnection.fromObject({
						id: `input-${this.id}-${index}`,
						input: inputId,
						output: this.id,
						isSelected: false,
						isExecuted: this.isExecuted,
					})
				);
			});

			(this.data.output ?? []).forEach((outputId, index) => {
				this._connections.push(
					ElementConnection.fromObject({
						id: `output-${this.id}-${index}`,
						input: this.id,
						output: outputId,
						isSelected: false,
						isExecuted: this.isExecuted,
					})
				);
			});
		}

		return this._connections;
	}

	set connections(value: ElementConnection[]) {
		this._connections = value;
	}

	get inputPoint(): { x: number; y: number } {
		return {
			x: this.x + this.width - 16,
			y: this.y + this.height - 48,
		};
	}

	get outputPoint(): { x: number; y: number } {
		return {
			x: this.x + 16,
			y: this.y + 80,
		};
	}

	constructor(
		public id: string,
		public data: ForeignObjectEntity,
		public vueComponent: any,
		public x: number,
		public y: number,
		public width: number,
		public height: number,
		public isSelected: boolean = false,
		public isExecuted: boolean = false
	) {}

	static fromObject(obj: any): DashboardElement {
		return new DashboardElement(
			obj.id,
			ForeignObjectEntity.fromObject(obj.data),
			obj.vueComponent,
			obj.x,
			obj.y,
			obj.width,
			obj.height,
			obj?.isSelected ?? false,
			obj?.isExecuted ?? false
		);
	}

	setSelected(x: number, y: number) {
		this.isSelected =
			x >= this.x &&
			x <= this.x + this.width &&
			y >= this.y &&
			y <= this.y + this.height;
	}

	setIsExecuted(value: boolean) {
		this.isExecuted = value;
	}

	updateSize() {
		this.width = this.data.width;
		this.height = this.data.height;
	}

	// Метод для сброса соединений
	resetConnections() {
		this._connections = [];
	}
}
