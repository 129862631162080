<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8 3C5.79086 3 4 4.79086 4 7V16C4 16.5523 4.44772 17 5 17C5.55228 17 6 16.5523 6 16V7C6 5.89543 6.89543 5 8 5H15C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3H8ZM10 8H17C17.5523 8 18 8.44771 18 9V18C18 18.5523 17.5523 19 17 19H10C9.44772 19 9 18.5523 9 18V9C9 8.44772 9.44771 8 10 8ZM7 9C7 7.34315 8.34315 6 10 6H17C18.6569 6 20 7.34315 20 9V18C20 19.6569 18.6569 21 17 21H10C8.34315 21 7 19.6569 7 18V9Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
