import {
	CollectionService,
	HitEntity,
	ShortCollectionEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs, watch } from 'vue';
import { collectionsItemsListModel } from '../collections-items-dropdown';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
	collectionName: string;
	includedHit: HitEntity | null;
	selectedHits: HitEntity[];
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
	collectionName: '',
	includedHit: null,
	selectedHits: [],
};

const state = reactive(data);

watch(
	() => state.collectionName,
	(collectionName) => {
		if (
			collectionName.length === 1 ||
			collectionName[collectionName.length - 2] === ' '
		) {
			state.collectionName = collectionName.trim();
		}
	}
);

async function createNewCollection(name: string) {
	state.isLoading = true;

	try {
		await CollectionService.getInstance().create(name);

		const { fetchCollections } = collectionsItemsListModel;
		await fetchCollections();

		state.isVisible = false;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.collectionName = '';
	}
}

async function createCollectionWithMolecule() {
	if (!state.includedHit) return;

	state.isLoading = true;
	try {
		const createdCollection = await CollectionService.getInstance().create(
			state.collectionName
		);

		await CollectionService.getInstance().addHitToCollection(
			createdCollection.id,
			[
				{
					catalog_id: state.includedHit.catalog_id,
					index: state.includedHit.index,
				},
			]
		);

		state.includedHit.collections.push(
			ShortCollectionEntity.fromObject(createdCollection)
		);

		const { fetchCollections } = collectionsItemsListModel;
		await fetchCollections();

		state.isVisible = false;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.collectionName = '';
		state.includedHit = null;
	}
}

async function createNewCollectionWithSelected() {
	if (!state.selectedHits.length) return;

	state.isLoading = true;
	try {
		const createdCollection = await CollectionService.getInstance().create(
			state.collectionName
		);

		await CollectionService.getInstance().addHitToCollection(
			createdCollection.id,
			state.selectedHits.map((hit) => ({
				catalog_id: hit.catalog_id,
				index: hit.index,
			}))
		);

		const { fetchCollections } = collectionsItemsListModel;
		await fetchCollections();
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.collectionName = '';
		state.isVisible = false;
		state.selectedHits = [];
	}
}

export const createCollectionModel = {
	...toRefs(state),
	createNewCollection,
	createCollectionWithMolecule,
	createNewCollectionWithSelected,
};
