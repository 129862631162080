import ExpiredLinkPage from '../ExpiredLinkPage.vue';
import ResetPasswordPage from '../ResetPasswordPage.vue';
import SentVerifyEmailPage from '../SentVerifyEmailPage.vue';
import SignInPage from '../SignInPage.vue';
import SignUpPage from '../SignUpPage.vue';

export const authRoutes = [
	{
		path: '/signup',
		name: 'signup',
		component: SignUpPage,
	},
	{
		path: '/login',
		name: 'login',
		component: SignInPage,
	},
	{
		path: '/reset',
		name: 'reset',
		component: ResetPasswordPage,
	},
	{
		path: '/link-expired',
		name: 'link-expired',
		component: ExpiredLinkPage,
	},
	{
		path: '/verify-email',
		name: 'verify-email',
		component: SentVerifyEmailPage,
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: false,
		},
	},
];
