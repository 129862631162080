<template>
	<div ref="mathContainer"></div>
</template>

<script setup lang="ts">
import { liteAdaptor } from 'mathjax-full/js/adaptors/liteAdaptor';
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html';
import { TeX } from 'mathjax-full/js/input/tex';
import { mathjax } from 'mathjax-full/js/mathjax';
import { SVG } from 'mathjax-full/js/output/svg';
import { defineProps, onMounted, ref, watch } from 'vue';

// Получение пропса
const props = defineProps({
	formula: String,
});

const mathContainer = ref<HTMLElement | null>(null);
const adaptor = liteAdaptor();
RegisterHTMLHandler(adaptor);

const tex = new TeX();
const svg = new SVG({ fontCache: 'none' });
const doc = mathjax.document('', {
	InputJax: tex,
	OutputJax: svg,
});

onMounted(() => {
	renderMath();
});

watch(
	() => props.formula,
	() => {
		renderMath();
	},
	{ immediate: true }
);

function renderMath() {
	if (mathContainer.value && props.formula) {
		const node = doc.convert(props.formula, {
			display: true,
			em: 16,
			ex: 8,
			containerWidth: 80 * 16,
		});
		mathContainer.value.innerHTML = adaptor.outerHTML(node);
	}
}
</script>
