<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 448 280"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 20.4862C0 51.5297 19.454 76.6497 43.4955 76.6497L0 20.4862Z"
			fill="#334155"
		/>
		<path
			d="M43.4954 76.6498C43.4954 45.2575 65.2049 19.8552 92.0338 19.8552L43.4954 76.6498Z"
			fill="#0D9488"
		/>
		<path
			d="M15.7593 23.3004C15.7593 52.7884 28.1647 76.6498 43.4955 76.6498L15.7593 23.3004Z"
			fill="#0D9488"
		/>
		<path
			d="M43.4954 76.6497C43.4954 36.5373 68.5882 4.07886 99.5982 4.07886L43.4954 76.6497Z"
			fill="#334155"
		/>
		<path
			d="M34.3452 77.0457C34.3452 77.0457 40.5136 76.8555 42.3726 75.5303C44.2316 74.2051 51.8611 72.6227 52.3223 74.7481C52.7834 76.8734 61.5922 85.3184 54.6281 85.3747C47.6641 85.4309 38.4467 84.2887 36.5913 83.1573C34.7359 82.0259 34.3452 77.0457 34.3452 77.0457Z"
			fill="#94a3b8"
		/>
		<path
			opacity="0.2"
			d="M54.7524 84.6348C47.7883 84.6911 38.571 83.5489 36.7156 82.4175C35.3026 81.5558 34.7395 78.464 34.5511 77.0376C34.4207 77.0432 34.3452 77.0456 34.3452 77.0456C34.3452 77.0456 34.7359 82.0258 36.5913 83.1573C38.4467 84.2887 47.6641 85.4309 54.6281 85.3746C56.6384 85.3584 57.3327 84.6424 57.2946 83.582C57.0153 84.2227 56.2486 84.6227 54.7524 84.6348Z"
			fill="#0f172a"
		/>
		<path
			opacity="0.2"
			d="M79.4714 180.233C120.797 180.233 154.298 175.671 154.298 170.043C154.298 164.415 120.797 159.852 79.4714 159.852C38.1461 159.852 4.64526 164.415 4.64526 170.043C4.64526 175.671 38.1461 180.233 79.4714 180.233Z"
			fill="#334155"
		/>
		<path
			opacity="0.2"
			d="M79.4713 178.599C114.167 178.599 142.293 174.768 142.293 170.043C142.293 165.318 114.167 161.487 79.4713 161.487C44.7758 161.487 16.6494 165.318 16.6494 170.043C16.6494 174.768 44.7758 178.599 79.4713 178.599Z"
			fill="#0f172a"
		/>
		<path
			opacity="0.2"
			d="M334.761 280C397.301 280 448 273.095 448 264.578C448 256.061 397.301 249.156 334.761 249.156C272.22 249.156 221.521 256.061 221.521 264.578C221.521 273.095 272.22 280 334.761 280Z"
			fill="#334155"
		/>
		<path
			opacity="0.2"
			d="M124.287 267.834C161.855 267.834 192.311 263.686 192.311 258.569C192.311 253.453 161.855 249.305 124.287 249.305C86.7184 249.305 56.2632 253.453 56.2632 258.569C56.2632 263.686 86.7184 267.834 124.287 267.834Z"
			fill="#334155"
		/>
		<path
			d="M185.108 250.758C221.121 259.971 290.345 238.741 297.948 214.707"
			stroke="#334155"
			stroke-width="2"
			stroke-miterlimit="10"
		/>
		<path
			d="M123.887 103.748C123.887 103.748 175.905 89.3276 179.106 135.794C182.307 182.26 136.291 195.479 172.304 204.692"
			stroke="#334155"
			stroke-width="2"
			stroke-miterlimit="10"
		/>
		<path
			d="M73.6304 215.221C73.6304 215.221 89.2647 210.887 90.2269 224.852C91.189 238.818 77.3586 242.791 88.1824 245.56"
			stroke="#334155"
			stroke-width="2"
			stroke-miterlimit="10"
		/>
		<path
			d="M311.591 225.618L308.438 245.765C308.438 245.765 292.92 254.018 303.831 254.261C314.743 254.503 366.147 254.261 366.147 254.261C366.147 254.261 376.088 254.261 360.327 245.522L357.175 224.405L311.591 225.618Z"
			fill="#64748b"
		/>
		<path
			opacity="0.1"
			d="M301.637 254.042C304.115 251.835 308.439 249.535 308.439 249.535L311.591 229.388L357.175 229.431L360.327 249.292C364.003 251.33 366.279 252.892 367.611 254.09C369.636 253.628 371.848 251.91 360.327 245.522L357.175 224.405L311.591 225.618L308.439 245.765C308.439 245.765 295.399 252.701 301.637 254.042Z"
			fill="#0f172a"
		/>
		<path
			d="M429.606 85.1971H238.675C234.687 85.1971 231.454 88.4335 231.454 92.4257V221.181C231.454 225.173 234.687 228.41 238.675 228.41H429.606C433.594 228.41 436.827 225.173 436.827 221.181V92.4257C436.827 88.4335 433.594 85.1971 429.606 85.1971Z"
			fill="#64748b"
		/>
		<path
			d="M429.916 92.8433H238.364V200.131H429.916V92.8433Z"
			fill="#334155"
		/>
		<path
			d="M334.14 90.6587C334.81 90.6587 335.353 90.1153 335.353 89.445C335.353 88.7747 334.81 88.2313 334.14 88.2313C333.471 88.2313 332.928 88.7747 332.928 89.445C332.928 90.1153 333.471 90.6587 334.14 90.6587Z"
			fill="#e2e8f0"
		/>
		<path
			d="M436.827 208.627V221.181C436.827 222.13 436.64 223.07 436.277 223.947C435.915 224.825 435.383 225.622 434.712 226.293C434.042 226.964 433.246 227.496 432.369 227.86C431.493 228.223 430.554 228.41 429.606 228.41H238.675C237.726 228.41 236.787 228.223 235.911 227.86C235.035 227.496 234.239 226.964 233.569 226.293C232.898 225.622 232.366 224.825 232.003 223.947C231.64 223.07 231.454 222.13 231.454 221.181V208.627H436.827Z"
			fill="#64748b"
		/>
		<path
			d="M387.727 267.368V269.796H257.277V267.854L257.457 267.368L260.672 258.63H385.059L387.727 267.368Z"
			fill="#334155"
		/>
		<path
			d="M437.928 264.992C437.69 266.009 436.793 267.082 434.766 268.097C427.492 271.738 412.701 267.126 412.701 267.126C412.701 267.126 401.305 265.184 401.305 260.086C401.625 259.867 401.959 259.668 402.304 259.492C405.362 257.872 415.502 253.875 433.482 259.661C434.806 260.077 435.99 260.853 436.899 261.903C437.628 262.757 438.199 263.84 437.928 264.992Z"
			fill="#334155"
		/>
		<path
			opacity="0.1"
			d="M437.928 264.992C429.024 268.407 421.088 268.662 412.944 262.999C408.836 260.145 405.104 259.438 402.304 259.492C405.362 257.872 415.502 253.875 433.482 259.661C434.806 260.077 435.99 260.853 436.9 261.903C437.628 262.757 438.199 263.84 437.928 264.992Z"
			fill="#0f172a"
		/>
		<path
			d="M426.764 262.999C428.505 262.999 429.917 262.565 429.917 262.028C429.917 261.492 428.505 261.057 426.764 261.057C425.024 261.057 423.612 261.492 423.612 262.028C423.612 262.565 425.024 262.999 426.764 262.999Z"
			fill="#e2e8f0"
		/>
		<path
			d="M334.14 223.191C336.685 223.191 338.747 221.126 338.747 218.579C338.747 216.032 336.685 213.967 334.14 213.967C331.596 213.967 329.533 216.032 329.533 218.579C329.533 221.126 331.596 223.191 334.14 223.191Z"
			fill="#e2e8f0"
		/>
		<path
			opacity="0.1"
			d="M387.727 267.368V269.796H257.277V267.854L257.457 267.368H387.727Z"
			fill="#0f172a"
		/>
		<path
			d="M126.688 63.691H43.4587V160.63H126.688V63.691Z"
			fill="#334155"
		/>
		<path
			d="M135.091 54.0773H35.0559V88.5265H135.091V54.0773Z"
			fill="#334155"
		/>
		<path
			d="M135.091 94.9357H35.0559V129.385H135.091V94.9357Z"
			fill="#334155"
		/>
		<path
			d="M135.091 135.794H35.0559V170.243H135.091V135.794Z"
			fill="#334155"
		/>
		<path
			opacity="0.4"
			d="M115.084 60.0858H108.682V66.495H115.084V60.0858Z"
			fill="#0D9488"
		/>
		<path
			opacity="0.8"
			d="M124.287 60.0858H117.885V66.495H124.287V60.0858Z"
			fill="#0D9488"
		/>
		<path
			d="M133.49 60.0858H127.088V66.495H133.49V60.0858Z"
			fill="#0D9488"
		/>
		<path
			opacity="0.4"
			d="M115.084 100.544H108.682V106.953H115.084V100.544Z"
			fill="#0D9488"
		/>
		<path
			opacity="0.8"
			d="M124.287 100.544H117.885V106.953H124.287V100.544Z"
			fill="#0D9488"
		/>
		<path
			d="M133.49 100.544H127.088V106.953H133.49V100.544Z"
			fill="#0D9488"
		/>
		<path
			opacity="0.4"
			d="M115.084 141.001H108.682V147.411H115.084V141.001Z"
			fill="#0D9488"
		/>
		<path
			opacity="0.8"
			d="M124.287 141.001H117.885V147.411H124.287V141.001Z"
			fill="#0D9488"
		/>
		<path
			d="M133.49 141.001H127.088V147.411H133.49V141.001Z"
			fill="#0D9488"
		/>
		<path
			d="M126.688 247.153C144.146 247.153 158.299 232.985 158.299 215.508C158.299 198.031 144.146 183.863 126.688 183.863C109.23 183.863 95.0769 198.031 95.0769 215.508C95.0769 232.985 109.23 247.153 126.688 247.153Z"
			fill="#334155"
		/>
		<path
			d="M121.886 240.343H112.283V257.568H121.886V240.343Z"
			fill="#334155"
		/>
		<path
			d="M141.093 240.343H131.49V257.568H141.093V240.343Z"
			fill="#334155"
		/>
		<path
			d="M120.286 260.773C124.705 260.773 128.288 259.427 128.288 257.768C128.288 256.109 124.705 254.764 120.286 254.764C115.866 254.764 112.283 256.109 112.283 257.768C112.283 259.427 115.866 260.773 120.286 260.773Z"
			fill="#334155"
		/>
		<path
			d="M139.492 260.372C143.912 260.372 147.495 259.027 147.495 257.368C147.495 255.708 143.912 254.363 139.492 254.363C135.072 254.363 131.49 255.708 131.49 257.368C131.49 259.027 135.072 260.372 139.492 260.372Z"
			fill="#334155"
		/>
		<path
			d="M127.488 218.312C133.455 218.312 138.292 213.47 138.292 207.496C138.292 201.523 133.455 196.681 127.488 196.681C121.521 196.681 116.684 201.523 116.684 207.496C116.684 213.47 121.521 218.312 127.488 218.312Z"
			fill="white"
		/>
		<path
			d="M127.488 211.102C129.477 211.102 131.089 209.487 131.089 207.496C131.089 205.505 129.477 203.891 127.488 203.891C125.499 203.891 123.887 205.505 123.887 207.496C123.887 209.487 125.499 211.102 127.488 211.102Z"
			fill="#334155"
		/>
		<path
			d="M96.024 186.079C93.4715 174.635 101.631 163.072 114.248 160.252C126.865 157.432 139.163 164.422 141.715 175.865C144.268 187.309 135.906 191.529 123.288 194.35C110.671 197.17 98.5764 197.522 96.024 186.079Z"
			fill="#0D9488"
		/>
		<path
			d="M156.336 209.196C164.361 205.758 169.992 200.926 168.914 198.404C167.836 195.882 160.456 196.625 152.431 200.063C144.406 203.502 138.775 208.334 139.853 210.856C140.931 213.378 148.311 212.635 156.336 209.196Z"
			fill="#334155"
		/>
		<path
			d="M97.1152 230.427C105.14 226.988 110.772 222.156 109.693 219.634C108.615 217.112 101.235 217.855 93.2105 221.294C85.1855 224.732 79.554 229.564 80.6323 232.086C81.7106 234.608 89.0902 233.865 97.1152 230.427Z"
			fill="#334155"
		/>
		<path
			d="M145.094 224.721C145.094 227.818 137.128 233.934 128.289 233.934C119.449 233.934 111.083 228.219 111.083 225.122C111.083 222.024 119.449 222.718 128.289 222.718C137.128 222.718 145.094 221.624 145.094 224.721Z"
			fill="white"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';

const props = defineProps({
	width: {
		type: Number,
		default: 488,
	},
	height: {
		type: Number,
		default: 280,
	},
});

const { width, height } = toRefs(props);
</script>
