import { Analytics, AnalyticsEvents } from '@/app/providers';
import {
	lbssCatalogListModel,
	lbssPrefiltersListModel,
	ligandSearchInputModel,
} from '@/features/ligand-based-search';
import { navigationTabsModel } from '@/features/navigation';
import {
	DEFAULT_TOP_K,
	JobParameters,
	JobsService,
	NavigationTabType,
	SearchType,
	type CatalogEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	isVisible: boolean;
	smiles: string[];
	top_k: number;
	step: number;
	selectedCatalog: CatalogEntity | null;
	isStructureSearch: boolean;
}

const data: IViewModel = {
	isLoading: false,
	isVisible: false,
	smiles: [],
	top_k: DEFAULT_TOP_K,
	step: 0,
	selectedCatalog: null,
	isStructureSearch: false,
};

const state = reactive(data);

function clear() {
	state.smiles = [];
	state.top_k = DEFAULT_TOP_K;
	state.step = 0;
	state.selectedCatalog = null;
	state.isStructureSearch = false;
	const { clear: clearCatalogs } = lbssCatalogListModel;
	const { clear: clearLigand } = ligandSearchInputModel;
	const { clear: clearPrefilters } = lbssPrefiltersListModel;
	clearCatalogs();
	clearLigand();
	clearPrefilters();
}

function selectCatalog(catalog: CatalogEntity) {
	state.selectedCatalog = catalog;

	state.top_k = Math.min(state.top_k, catalog.max_top_k);
}

async function finishCreating() {
	if (!state.selectedCatalog) {
		return;
	}

	state.isLoading = true;

	const { filters } = lbssPrefiltersListModel;

	const jobParameters = JobParameters.fromObject({
		type: SearchType.LIGAND,
		ligand_smiles: state.smiles,
		top_k: state.top_k,
		filters: filters.value || [],
	});

	try {
		const newJob = await JobsService.getInstance().createJob(
			state.selectedCatalog.id,
			jobParameters
		);

		const { addTab } = navigationTabsModel;

		addTab({
			id: newJob.id,
			title: state.isStructureSearch
				? 'Fingerprint-based'
				: 'Ligand Based',
			description: newJob.query,
			path: `/job/${newJob.id}`,
			type: state.isStructureSearch
				? NavigationTabType.Structure
				: NavigationTabType.LIGAND,
		});

		Analytics.send(AnalyticsEvents.Job.CREATE_JOB_SUCCESS, {
			type: SearchType.LIGAND,
			catalog_id: state.selectedCatalog?.id,
			job_parameters: jobParameters,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
		Analytics.send(AnalyticsEvents.Job.CREATE_JOB_FAILURE, {
			type: SearchType.LIGAND,
			catalog_id: state.selectedCatalog?.id,
			job_parameters: jobParameters,
		});
	} finally {
		state.isLoading = false;
		state.isVisible = false;
		clear();
	}
}

export const createLBSSModel = {
	...toRefs(state),
	clear,
	selectCatalog,
	finishCreating,
};
