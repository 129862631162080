<template>
	<div class="bioptic-copilot-chats-page">
		<CopilotChatsWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { CopilotChatsWidget } from '@/widgets/chats';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.DASHBOARD.VIEW_CHATS);
});
</script>

<style lang="stylus" scoped>
.bioptic-copilot-chats-page
	width 100%
	height 100%
</style>
