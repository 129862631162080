export const getRandomInteger = (min: number, max: number) => {
	const rand = min - 0.5 + Math.random() * (max - min + 1);
	return Math.round(rand);
};

export const decimalAdjust = (
	type: 'round' | 'floor' | 'ceil',
	value: number,
	exp: number
): number => {
	// If the exponent is undefined or zero...
	if (typeof exp === 'undefined' || exp === 0) {
		return Math[type](value);
	}

	value = +value;
	exp = +exp;
	// If the value is not a number, or if the exponent is not an integer...
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	}

	// Shift the decimal points
	let valueArray = value.toString().split('e');
	value = Math[type](
		+(valueArray[0] + 'e' + (valueArray[1] ? +valueArray[1] - exp : -exp))
	);

	// Reverse the shift
	valueArray = value.toString().split('e');
	return +(
		valueArray[0] +
		'e' +
		(valueArray[1] ? +valueArray[1] + exp : exp)
	);
};
