<template>
	<div class="compounds-list-widget">
		<el-table
			ref="multipleTableRef"
			:data="tableData"
			style="width: 100%"
			@selection-change="handleSelectionChange"
			:row-class-name="rowClassName"
			:border="true"
			table-layout="fixed"
		>
			<el-table-column fixed type="selection" width="55" />
			<el-table-column fixed property="image" label="Preview" width="250">
				<template #default="scope">
					<TableImageColumn
						:id="scope.row.id"
						:smiles="scope.row.smiles"
					/>
				</template>
			</el-table-column>
			<el-table-column
				property="external_id"
				label="Catalog Number"
				width="250"
			>
				<template #default="scope">
					<div class="footnoteRegular">
						{{ scope.row.external_id }}
					</div>
				</template>
			</el-table-column>
			<el-table-column property="catalog_id" label="Catalog" width="250">
				<template #default="scope">
					<div class="footnoteRegular">
						{{
							scope.row?.external_url?.title ??
							getCatalogById(scope.row.catalog_id)?.name
						}}
						<TargetLinkButton
							v-if="scope.row.external_url"
							class="external-link-button"
							:data="scope.row.external_url"
							:id="`external-${scope.row.smiles}-${scope.row.external_url}`"
							size="1rem"
							:color="ThemeColors.primary[600]"
						/>
					</div>
				</template>
			</el-table-column>
			<el-table-column
				property="quantity"
				label="Quantity (mg)"
				width="130"
			>
				<template #default="scope">
					<bNumberInput
						v-model="scope.row.quantity"
						:placeholder="'Quantity (mg)'"
						size="small"
						:min="0"
						:max="1000"
						@change="
							handleQuantityInput(scope.row, scope.row.quantity)
						"
					/>
				</template>
			</el-table-column>
			<el-table-column property="purity" label="Purity (%)" width="130">
				<template #default="scope">
					<bNumberInput
						v-model="scope.row.purity"
						:placeholder="'Purity (%)'"
						size="small"
						:min="0"
						:max="100"
						@change="handlePurityInput(scope.row, scope.row.purity)"
					/>
				</template>
			</el-table-column>
			<el-table-column property="smiles" label="SMILES" min-width="350">
				<template #default="scope">
					<div class="footnoteRegular">
						{{ scope.row.smiles }}
					</div>
				</template>
			</el-table-column>

			<template #empty>
				<bEmptyView
					title="No compounds found"
					description="You can order only molecules from eMolecules catalogs"
				>
					<template v-slot:image>
						<bImageEmpty2 :width="200" :height="200" />
					</template>
				</bEmptyView>
			</template>
		</el-table>
	</div>
</template>

<script lang="ts" setup>
import { TargetLinkButton } from '@/features/target-driven-search';
import { CatalogEntity, type HitEntity } from '@/shared/api';
import {
	ThemeColors,
	bEmptyView,
	bImageEmpty2,
	bNumberInput,
} from '@/shared/uikit';
import { ElTable, ElTableColumn } from 'element-plus';
import { computed, defineExpose, ref } from 'vue';
import { sendEmoleculesModel } from '../send-emolecules-modal';
import TableImageColumn from './TableImageColumn.vue';

const { hits, catalogs } = sendEmoleculesModel;

const multipleTableRef = ref<InstanceType<typeof ElTable>>();
const isFirstQuantityInput = ref(true);
const isFirstPurityInput = ref(true);

const handleSelectionChange = (val: HitEntity[]) => {
	hits.value.forEach((hit) => {
		hit.selected = val.includes(hit);
	});
};

const rowClassName = (row: any) => {
	return row.row.selected ? 'selected-row' : '';
};

const tableData = computed(() => {
	return hits.value;
});

const handlePurityInput = (row: HitEntity, value: number) => {
	if (isFirstPurityInput.value) {
		isFirstPurityInput.value = false;
		hits.value.forEach((hit) => {
			hit.purity = value > 100 ? 100 : value;
		});
	} else {
		row.purity = value > 100 ? 100 : value;
	}
};

const handleQuantityInput = (row: HitEntity, value: number) => {
	if (isFirstQuantityInput.value) {
		isFirstQuantityInput.value = false;
		hits.value.forEach((hit) => {
			hit.quantity = value;
		});
	} else {
		row.quantity = value;
	}
};

const reset = () => {
	isFirstPurityInput.value = true;
	isFirstQuantityInput.value = true;
};

const getCatalogById = (id: string): CatalogEntity | null => {
	const catalog = catalogs.value.find((catalog) => catalog.id === id);
	if (!catalog) {
		return null;
	}
	return catalog;
};

defineExpose({
	reset,
});
</script>
<style lang="stylus" scoped>
.compounds-list-widget
    width 100%
    height calc(100vh - 13.5rem)
    display flex
    flex-direction column
    gap 1rem
    .cell
        font-family var(--font-family)

.canvas-tooltip
    position absolute
    z-index 9999
    background-color white
    border 1px solid #ccc
    border-radius 8px
    box-shadow 0 4px 8px rgba(0, 0, 0, 0.1)
</style>

<style lang="stylus">
.compounds-list-widget
	.b-empty-view
		height 100%
		margin 2rem 0
	.footnoteRegular
		display flex
		justify-content flex-start
		align-items center
	.el-table--fit
		border-radius 0.75rem
		border 1px solid var(--neutral-200)
	.cell
		font-family var(--font-family)
		color var(--neutral-800)
	.el-table__row
		border-radius: 0.75rem

		&:last-child
			border none !important
	.b-simple-number-input
		text-align center !important
	.el-checkbox__inner
		&:hover
			border-color var(--primary-600)
	.is-indeterminate
		.el-checkbox__inner
			background-color var(--primary-600)
			border-color var(--primary-600)
	.el-checkbox__input.is-checked .el-checkbox__inner
		background-color var(--primary-600)
		border-color var(--primary-600)

	.el-table--border::after, .el-table--border::before, .el-table__border-left-patch, .el-table__inner-wrapper::before, .el-table__inner-wrapper::after
		display none
.selected-row
	background-color var(--neutral-100) !important
</style>
