import CartCompoundsPage from '../CartCompoundsPage.vue';
import CartDetailsPage from '../CartDetailsPage.vue';

export const cartRoutes = [
	{
		path: '/cart',
		component: CartDetailsPage,
		name: 'cart-details',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
	{
		path: '/cart/compounds',
		component: CartCompoundsPage,
		name: 'compounds-page',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
];
