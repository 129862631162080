import { IndexedDBHitsInstance, RestClient } from '../client';
import { Endpoints } from '../shared';
import { ClusterEntity } from './cluster';
import { JobEntity, MetricType, SearchType } from './entities';
import { HitEntity } from './hit';
import {
	MoleculeEntity,
	ResultsSortOption,
	SearchesAttributeEntity,
	SortDirection,
} from './shared';

export class JobsService {
	private static instance: JobsService;

	private client: RestClient = new RestClient(
		import.meta.env.VITE_REST_BASE_URL
	);

	private constructor() {}

	public static getInstance(): JobsService {
		if (!JobsService.instance) {
			JobsService.instance = new JobsService();
		}
		return JobsService.instance;
	}

	public async fetchAll(
		limit: number,
		offset: number,
		type: SearchType | null = null,
		selectedStates: string[] = [],
		metric: MetricType = MetricType.Activity
	): Promise<{
		count: number;
		jobs: JobEntity[];
	}> {
		const body: any = {
			limit: limit,
			offset: offset,
			state: selectedStates,
			metric: metric,
		};

		if (type) {
			body.type = type;
		}

		const response = await this.client.get<any>(
			Endpoints.Jobs.FETCH_ALL,
			body
		);

		const jobs = response.data.jobs
			.map((job: any) => JobEntity.fromObject(job))
			.sort((a: JobEntity, b: JobEntity) => {
				return (
					new Date(b.created_at).getTime() -
					new Date(a.created_at).getTime()
				);
			});

		return {
			count: response.data.count,
			jobs: jobs,
		};
	}

	public async fetchJobAttributes(
		id: string
	): Promise<SearchesAttributeEntity[]> {
		const response = await this.client.get<any>(
			Endpoints.Jobs.GET_ATTRIBUTES(id)
		);

		return response.data.attributes.map((attribute: any) =>
			SearchesAttributeEntity.fromObject(attribute)
		);
	}

	public async fetchCluster(
		jobId: string,
		clusterId: string
	): Promise<ClusterEntity> {
		const response = await this.client.get<any>(
			Endpoints.Jobs.GET_CLUSTER(jobId, clusterId)
		);

		return ClusterEntity.fromObject(response.data);
	}

	public async getJob(id: string): Promise<JobEntity> {
		const response = await this.client.get<any>(Endpoints.Jobs.GET(id));

		return JobEntity.fromObject(response.data);
	}

	public async createJob(
		catalog_id: string,
		parameters: any
	): Promise<JobEntity> {
		let body: any = {
			catalog_id: catalog_id,
			parameters: parameters,
		};

		const response = await this.client.post<any>(
			Endpoints.Jobs.CREATE,
			body
		);

		return JobEntity.fromObject(response.data);
	}

	public async downloadArchive(id: string): Promise<void> {
		const response = await this.client.post<any>(
			Endpoints.Jobs.DOWNLOAD(id),
			{}
		);

		this.downloadFileByURL(response.data.url);
	}

	public async downloadArchiveByFilters(
		id: string,
		sortOption: ResultsSortOption,
		filters: any[]
	): Promise<void> {
		const response = await this.client.post<any>(
			Endpoints.Jobs.DOWNLOAD(id),
			{
				sort_by: sortOption.type,
				is_descending:
					sortOption.sortDirection === SortDirection.Descending
						? true
						: false,
				filters: filters,
			}
		);

		this.downloadFileByURL(response.data.url);
	}

	public async fetchJobHits(
		id: string,
		limit: number,
		offset: number,
		sortOption: ResultsSortOption,
		filters: any[],
		clusterIndex: string | null = null
	): Promise<{
		count: number;
		hits: HitEntity[];
	}> {
		const body: any = {
			order_by: sortOption.type,
			is_descending:
				sortOption.sortDirection === SortDirection.Descending
					? true
					: false,
			filters: filters,
			limit: limit,
			offset: offset,
		};

		if (clusterIndex) {
			body.cluster_index = clusterIndex;
		}

		const response = await this.client.post<any>(
			Endpoints.Jobs.GET_HITS(id),
			body
		);

		const hits = response.data.hits.map((hit: any) =>
			HitEntity.fromObject(hit)
		);

		const dbCluster = await IndexedDBHitsInstance.get();
		const selectedHits = await dbCluster.getAll();

		hits.forEach((hit: HitEntity) => {
			hit.isFavorite = selectedHits.some(
				(selectedHit) => selectedHit.id === hit.id
			);
		});

		return {
			count: response.data.count,
			hits: hits,
		};
	}

	public async fetchJobClusters(
		id: string,
		limit: number,
		offset: number,
		sortOption: ResultsSortOption,
		filters: any[]
	): Promise<{
		count: number;
		clusters: ClusterEntity[];
	}> {
		const response = await this.client.post<any>(
			Endpoints.Jobs.GET_CLUSTERS(id),
			{
				order_by: sortOption.type,
				is_descending:
					sortOption.sortDirection === SortDirection.Descending
						? true
						: false,
				filters: filters,
				limit: limit,
				offset: offset,
			}
		);

		return {
			count: response.data.count,
			clusters: response.data.clusters.map((cluster: any) =>
				ClusterEntity.fromObject(cluster)
			),
		};
	}

	async delete(id: string): Promise<void> {
		await this.client.delete(Endpoints.Jobs.DELETE(id));
	}

	async createJobBySteps(steps: any[]): Promise<JobEntity> {
		const response = await this.client.post<any>(
			Endpoints.Jobs.CREATE_BY_STEPS,
			{
				steps: steps,
			}
		);

		return JobEntity.fromObject(response.data);
	}

	//TODO- fix this
	// async fetchProteinTargets(
	// 	query: string,
	// 	filters: any[]
	// ): Promise<{
	// 	results: TargetEntity[];
	// 	attributes: SearchesAttributeEntity[];
	// }> {
	// 	const response = await this.client.post<any>(
	// 		Endpoints.Jobs.FETCH_PROTEIN_TARGETS,
	// 		{
	// 			query: query,
	// 			filters: filters,
	// 		}
	// 	);

	// 	const results = response.data.results.map((protein: any) => {
	// 		return new TargetEntity(protein.protein, protein.compounds_count);
	// 	});

	// 	const attributes =
	// 		response.data.attributes.map((attribute: any) => {
	// 			const resetDefault =
	// 				attribute.attribute === SearchAttributeType.MAX_ACTIVITY
	// 					? new RangeFilter(0, 0.1)
	// 					: new RangeFilter(0, 20);

	// 			const result = SearchesAttributeEntity.fromObject(attribute);

	// 			result.resetDefault = resetDefault;

	// 			return result;
	// 		}) ?? [];

	// 	return {
	// 		results: results,
	// 		attributes: attributes,
	// 	};
	// }

	private mapMolecule(molecule: any): MoleculeEntity {
		return new MoleculeEntity(
			molecule.id,
			molecule.metadata,
			molecule.index_id,
			molecule.index,
			molecule.attributes.hba,
			molecule.attributes.hbd,
			molecule.attributes.hac,
			molecule.attributes.log_p,
			molecule?.score ?? 0,
			molecule.attributes.tpsa,
			molecule.attributes.weight,
			molecule.attributes.f_csp3,
			molecule.attributes.rotatable_bonds,
			molecule.attributes.molar_refractivity,
			molecule?.collections ?? []
		);
	}

	private downloadFileByURL(url: string) {
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', '');
		document.body.appendChild(link);
		link.click();
		link.remove();
	}
}
