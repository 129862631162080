import { navigationTabsModel } from '@/features/navigation';
import { CollectionService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { collectionsItemsListModel } from '../collections-items-dropdown';

interface IViewModel {
	collectionId: string;
	isLoading: boolean;
}

const data: IViewModel = {
	collectionId: '',
	isLoading: false,
};

const state = reactive(data);

async function deleteCollection() {
	if (!state.collectionId) {
		return;
	}

	state.isLoading = true;

	try {
		await CollectionService.getInstance().delete(state.collectionId);

		const { removeCurrentTab, removeTabById } = navigationTabsModel;
		removeCurrentTab();
		removeTabById(`collection-${state.collectionId}`);

		const { fetchCollections } = collectionsItemsListModel;
		await fetchCollections();
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
		state.collectionId = '';
	}
}

export const deleteCollectionModel = {
	...toRefs(state),
	deleteCollection,
};
