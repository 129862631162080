export class ProbesStrategyOptioin {
	constructor(
		public id: number,
		public label: string,
		public value: number
	) {}

	static fromObject(obj: any): ProbesStrategyOptioin {
		return new ProbesStrategyOptioin(obj.id, obj.label, obj.value);
	}

	static get default(): ProbesStrategyOptioin {
		return ProbesStrategyOptioin.fromObject({
			id: 0,
			label: 'Select strategy manuall',
			value: 0,
		});
	}

	static get defaultProbe(): ProbesStrategyOptioin {
		return ProbesStrategyOptioin.fromObject({
			id: 2,
			label: 'Select 5 most active probes',
			value: 5,
		});
	}
	static get defaultCluster(): ProbesStrategyOptioin {
		return ProbesStrategyOptioin.fromObject({
			id: 2,
			label: 'Select 5 most active clusters probes',
			value: 5,
		});
	}

	static get exampleProbes(): ProbesStrategyOptioin[] {
		return [
			ProbesStrategyOptioin.fromObject({
				id: 0,
				label: 'Select strategy manually',
				value: 0,
			}),
			ProbesStrategyOptioin.fromObject({
				id: 2,
				label: 'Select 5 most active probes',
				value: 5,
			}),
			ProbesStrategyOptioin.fromObject({
				id: 3,
				label: 'Select 10 most active probes',
				value: 10,
			}),
		];
	}

	static get exampleClusters(): ProbesStrategyOptioin[] {
		return [
			ProbesStrategyOptioin.fromObject({
				id: 0,
				label: 'Select strategy manually',
				value: 0,
			}),
			ProbesStrategyOptioin.fromObject({
				id: 2,
				label: 'Select 5 most active clusters probes',
				value: 5,
			}),
			ProbesStrategyOptioin.fromObject({
				id: 3,
				label: 'Select 10 most active clusters probes',
				value: 10,
			}),
		];
	}
}
