<template>
	<div class="minimap-widget">
		<MiniMap
			v-if="isVisibleMap"
			:dashboard="dashboard"
			:width="width"
			:height="height"
			:aspectRatio="aspectRatio"
		/>
		<MiniMapControls
			@onClickMap="isVisibleMap = !isVisibleMap"
			@onClickZoomIn="dashboard.zoomIn()"
			@onClickZoomOut="dashboard.zoomOut()"
			@onClickReset="dashboard.resetZoom()"
			:isVisibleMap="isVisibleMap"
			:initScalePercentage="initScalePercentage"
			:minZoom="dashboard.minZoom"
			:maxZoom="dashboard.maxZoom"
		/>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import MiniMap from './MiniMap.vue';
import MiniMapControls from './MiniMapControls.vue';

const props = defineProps({
	dashboard: {
		type: Object as PropType<any>,
		required: true,
	},
	width: {
		type: Number,
		required: true,
	},
	height: {
		type: Number,
		required: true,
	},
	aspectRatio: {
		type: Number,
		required: false,
	},
});

const isVisibleMap = ref<boolean>(false);

const initScalePercentage = computed(() => {
	return Math.round(
		((props.dashboard.scale - props.dashboard.minZoom) /
			(props.dashboard.maxZoom - props.dashboard.minZoom)) *
			100
	);
});

const aspectRatio = props.aspectRatio ?? 2 / 3;
</script>

<style lang="stylus" scoped>
.minimap-widget {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap 0.5rem;
}
</style>
