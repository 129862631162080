<template>
	<div v-if="isLoading" class="empty-view">
		<bLoader size="4" />
	</div>
	<div v-else class="cluster-details-page">
		<ClusterResultsFiltersWidget />
		<ClusterResultsListWidget />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import { bLoader } from '@/shared/uikit';
import {
	ClusterResultsFiltersWidget,
	ClusterResultsListWidget,
	clusterDetailsModel,
} from '@/widgets/cluster';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { fetchClusterDetails, isLoading, cluster } = clusterDetailsModel;
const { title, description, clear: clearNavigation } = navigationHeaderModel;

onMounted(() => reloadPage());
// onUpdated(() => reloadPage());

watch(
	() => [
		router.currentRoute.value.params.id,
		router.currentRoute.value.params.clusterId,
	],
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	clearNavigation();

	const { id, clusterId } = router.currentRoute.value.params;

	if (!id || !clusterId) {
		return;
	}

	await fetchClusterDetails(id as string, clusterId as string);

	if (router.currentRoute.value.name === 'cluster-details') {
		title.value = 'Cluster #' + cluster.value?.index ?? '';
		description.value = 'for scaffold  ' + cluster.value?.scaffold_smiles;
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.cluster-details-page
	width 100%
	height 100%
	padding 0 calc(5vw)
	display grid
	grid-template-columns 23rem minmax(40rem, 1fr)
	gap 1.5rem
	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(2vw)
	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(1vw)
	.empty-view
		width 100%
		height 100%
		display flex
		flex-direction column
		align-items center
		justify-content center
</style>
