<template>
	<canvas ref="canvas"></canvas>
</template>

<script setup lang="ts">
import type { HistogramEntity } from '@/shared/api';
import { defineProps, onMounted, ref, watch } from 'vue';
import { ThemeColors } from '..';

const props = defineProps({
	min: {
		type: Number,
		default: 0,
	},
	max: {
		type: Number,
		default: 1,
	},
	absoluteMax: {
		type: Number,
		default: 1,
	},
	absoluteMin: {
		type: Number,
		default: 0,
	},
	histogramData: {
		type: Array as () => HistogramEntity[],
		default: () => [],
	},
});

const canvas = ref<HTMLCanvasElement | null>(null);

const drawHistogram = () => {
	const ctx = canvas.value?.getContext('2d');
	if (!ctx) return;

	const { width, height } = ctx.canvas;
	ctx.clearRect(0, 0, width, height);

	const maxCount = Math.max(...props.histogramData.map((d) => d.count));
	const histogramWidth = Math.abs(props.absoluteMax - props.absoluteMin);
	const pointWidth = width / histogramWidth;

	for (let i = 0; i < props.histogramData.length; i++) {
		if (props.min !== props.max && i === 0) continue;

		const x =
			pointWidth * (props.histogramData[i].start - props.absoluteMin);
		const y = height - (props.histogramData[i].count / maxCount) * height;
		const prevX =
			i === 0
				? 0
				: pointWidth *
				  (props.histogramData[i - 1].start - props.absoluteMin);
		const prevY =
			i === 0
				? height
				: height -
				  (props.histogramData[i - 1].count / maxCount) * height;

		const isInRange =
			props.histogramData[i - 1]?.start >= props.min &&
			props.histogramData[i - 1]?.end <= props.max;

		ctx.beginPath();
		ctx.moveTo(prevX, prevY);
		const cp1x = (prevX + x) / 2;
		const cp1y = prevY;
		const cp2x = (prevX + x) / 2;
		const cp2y = y;
		ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);
		ctx.lineTo(x, height);
		ctx.lineTo(prevX, height);
		ctx.closePath();

		ctx.fillStyle = isInRange
			? ThemeColors.primary[300]
			: ThemeColors.primary[100];
		ctx.fill();
	}
};

onMounted(drawHistogram);

watch(
	() => [
		props.histogramData,
		props.max,
		props.min,
		props.absoluteMax,
		props.absoluteMin,
	],
	drawHistogram,
	{
		deep: true,
	}
);
</script>

<style lang="stylus" scoped>
canvas {
	width: 100%;
	height: 60px;
}
</style>
