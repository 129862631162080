<template>
	<div class="chemcrow-chat-input">
		<slot name="files"></slot>
		<bTextInput
			v-model:modelValue="newMessage"
			@keyup.enter.prevent="onEnterPress"
			:isDisabled="isLoading"
			:placeholder="
				isLoading ? 'Thinking...' : 'Type your message request here...'
			"
			size="large"
			@update:modelValue="(value) => $emit('update:modelValue', value)"
		>
			<template v-slot:left-icon>
				<div
					v-if="isLoading"
					class="file-button calloutRegular file-button--loading"
				>
					🤔
				</div>
				<bUnstyledButton
					v-else
					class="file-button"
					@click="onFileButtonClick"
				>
					<template v-slot:content>
						<IconFile size="1rem" />
					</template>
				</bUnstyledButton>
				<input
					type="file"
					ref="fileInput"
					@change="onFileChange"
					style="display: none"
					accept=".csv, .sdf, .tsv"
				/>
			</template>
			<template v-slot:right-icon>
				<bUnstyledButton
					class="sent-button"
					@click="onSendMessage"
					:isDisabled="isLoading || newMessage.trim().length === 0"
				>
					<template v-slot:content>
						<IconSent color="white" size="1rem" />
					</template>
				</bUnstyledButton>
			</template>
		</bTextInput>
	</div>
</template>

<script setup lang="ts">
import {
	IconFile,
	IconSent,
	bTextInput,
	bUnstyledButton,
} from '@/shared/uikit';
import { ref } from 'vue';

const newMessage = ref<string>('');
const emit = defineEmits([
	'update:modelValue',
	'onSendMessage',
	'onUploadFile',
]);

const props = defineProps({
	isLoading: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const onEnterPress = (event: any) => {
	if (!event.shiftKey) {
		onSendMessage();
	}
};

const onSendMessage = () => {
	if (newMessage.value.trim().length === 0) {
		return;
	}

	emit('onSendMessage', newMessage.value);
	newMessage.value = '';
};

const fileInput = ref<HTMLInputElement | null>(null);

const onFileButtonClick = () => {
	fileInput.value?.click();
};

const onFileChange = (event: Event) => {
	const input = event.target as HTMLInputElement;
	if (input.files && input.files.length > 0) {
		const file = input.files[0];
		emit('onUploadFile', file);
	}
};
</script>

<style lang="stylus" scoped>
.chemcrow-chat-input
	position absolute
	bottom 0
	left 10%
	right 10%
	z-index 10
	padding-bottom 1rem
	display flex
	flex-direction row
	align-items flex-end
	justify-content space-between
	gap 0.625rem

	.sent-button
		display: flex;
		height: 2.5rem;
		width 2.5rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 50%
		background var(--primary-600);
	.file-button
		display: flex;
		height: 2.5rem;
		width 2.5rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 50%
		background white;
		&:hover
			background var(--neutral-100)
		&.file-button--loading
			background var(--neutral-200)
			color var(--neutral-600)
			pointer-events none
			cursor not-allowed
</style>

<style lang="stylus">
.chemcrow-chat-input
	.b-text-input-container
		input
			padding-left 3.5rem !important
			border-radius 1.25rem !important
</style>
