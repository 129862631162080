<template>
	<NavigationTabDragWrapper
		v-model:isDragging="isDragging"
		v-model:isOver="isOver"
		:data="tab"
		@onDrop="handleDrop"
		@onStart="handleStart"
		@onOver="handleOver"
	>
		<template v-slot:content>
			<NavigationTabContextMenu :tab="tab">
				<template v-slot:target>
					<li
						v-if="tab"
						class="navigation-tab"
						:class="{
							'navigation-tab--active': isActive,
							'navigation-tab--over': isOver,
							'navigation-tab--dragging': isDragging,
						}"
						@click="onClick"
					>
						<component
							:is="getNavigationTabIcon(tab.type)"
							size="1rem"
							:isDisabled="true"
						/>

						<div class="navigation-tab--info">
							<div class="captionBold">{{ tab.title }}</div>
							<div class="captionRegular">
								{{ tab.description }}
							</div>
						</div>

						<bUnstyledButton
							v-if="!tab.isPinned"
							@onClick="onRemove"
						>
							<template v-slot:content>
								<IconClose size="0.75rem" color="white" />
							</template>
						</bUnstyledButton>
						<bUnstyledButton
							v-else
							@onClick="
								handleContextMenuAction(
									ContextMenuAction.Unpin,
									tab
								)
							"
						>
							<template v-slot:content>
								<IconPin size="0.75rem" color="white" />
							</template>
						</bUnstyledButton>
					</li>
				</template>
			</NavigationTabContextMenu>
		</template>
	</NavigationTabDragWrapper>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { type NavigationTabEntity } from '@/shared/api';
import { IconClose, IconPin, bUnstyledButton } from '@/shared/uikit';
import { computed, ref, type PropType } from 'vue';
import NavigationTabContextMenu from './NavigationTabContextMenu.vue';
import NavigationTabDragWrapper from './NavigationTabDragWrapper.vue';
import {
	ContextMenuAction,
	navigationTabContextMenuModel,
} from './contextmenu';
import { getNavigationTabIcon } from './entities';

const { handleContextMenuAction } = navigationTabContextMenuModel;

const isOver = ref(false);
const isDragging = ref(false);

const emit = defineEmits(['onRemove', 'onDrop', 'onOver', 'onStart']);
const props = defineProps({
	tab: {
		type: Object as PropType<NavigationTabEntity>,
		required: true,
	},
});

const isActive = computed(() => {
	const currentPath = router.currentRoute.value.path;
	return currentPath === props.tab.path;
});

const onRemove = () => emit('onRemove');
const onClick = () => router.push(props.tab.path);

const handleDrop = (droppedTab: any, targetTab: any) => {
	emit('onDrop', droppedTab, targetTab);
};

const handleStart = (value: any) => {
	emit('onStart', value);
};

const handleOver = (value: any) => {
	emit('onOver', value);
};
</script>

<style lang="stylus" scoped>
.navigation-tab
	position relative
	height 2.25rem
	display grid
	grid-template-columns auto 1fr auto
	gap 0.25rem
	align-items center
	justify-content center
	padding 0 0.5rem
	border-radius 0.25rem
	transition all 0.25s
	background-color var(--neutral-900)
	&:hover
		cursor pointer
		background-color var(--neutral-800)
		transition all 0.25s
	.b-unstyled-button
		&:hover
			opacity 1
			background-color var(--neutral-600)

	&--active
		background-color var(--neutral-800)
		transition all 0.25s
		box-shadow: 0px 0.5px 1.5px 0px rgba(11, 10, 13, 0.19), 0px 0px 1px 0px rgba(11, 10, 13, 0.04);
		&:hover
			cursor default

	&--dragging
		opacity 0
		transition transform 0.2s ease-in-out

	&--over
		background-color var(--neutral-900) !important
		transition transform 0.2s ease-in-out

	&--info
		max-width 9rem
		display flex
		flex-direction column
		align-items flex-start
		justify-content center
		.captionBold
			width 100%
			color var(--neutral-400)
			white-space nowrap
			font-size 0.5625rem
			overflow hidden
			text-overflow ellipsis
		.captionRegular
			width 100%
			color white
			white-space nowrap
			overflow hidden
			text-overflow ellipsis
</style>
