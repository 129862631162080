import { router } from '@/app/providers';
import { CollectionItemEntity, CollectionService } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import {
	collectionDetailsModel,
	collectionItemsListModel,
} from '@/widgets/collections';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
}

const data: IViewModel = {
	isLoading: false,
};

const state = reactive(data);

async function removeHitFromCollection(collectionItem: CollectionItemEntity) {
	const { id } = router.currentRoute.value.params;
	if (!id) return;

	state.isLoading = true;
	try {
		const { collectibles, fetchCollectionItems, total } =
			collectionItemsListModel;
		const { collectionDetails } = collectionDetailsModel;

		collectibles.value = collectibles.value.filter(
			(item) => item.id !== collectionItem.id
		);

		if (collectibles.value) {
			collectionDetails.value?.updateSize(
				collectionDetails.value.size - 1
			);
		}
		total.value -= 1;

		await CollectionService.getInstance().removeHitFromCollection(
			id as string,
			[
				{
					catalog_id: collectionItem.catalog_id,
					index: collectionItem.index,
				},
			]
		);

		if (collectibles.value.length === 0) {
			fetchCollectionItems(1);
		}
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const deleteCollectionItemButtonModel = {
	...toRefs(state),
	removeHitFromCollection,
};
