<template>
	<div
		class="minimap-container"
		:style="{
			width: `${props.width}px`,
			height: `${props.height * aspectRatio}px`,
		}"
	>
		<svg ref="minimapRef" id="minimap"></svg>
		<div ref="minimapViewboxRef" id="minimap-viewbox"></div>
	</div>
</template>

<script setup lang="ts">
import { DashboardMinimap } from '@/shared/lib/';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

interface MiniMapProps {
	dashboard: any;
	width: number;
	height: number;
	aspectRatio?: number;
}

const props = defineProps<MiniMapProps>();

const aspectRatio = props.aspectRatio ?? 2 / 3;

const minimapRef = ref<SVGSVGElement | null>(null);
const minimapViewboxRef = ref<HTMLDivElement | null>(null);
let dashboardMinimap: DashboardMinimap | null = null;

onMounted(() => {
	if (minimapRef.value && minimapViewboxRef.value && props.dashboard) {
		dashboardMinimap = new DashboardMinimap(
			minimapRef.value,
			minimapViewboxRef.value,
			props.dashboard,
			props.width,
			props.height,
			aspectRatio
		);
		dashboardMinimap.draw();
		dashboardMinimap.initListeners();

		watch(
			() => props.dashboard.elementManager.elements,
			() => {
				dashboardMinimap?.draw();
			},
			{ deep: true }
		);
	}
});

onBeforeUnmount(() => {
	dashboardMinimap?.removeListeners();
});
</script>

<style lang="stylus" scoped>
.minimap-container {
	border: 1px solid var(--neutral-100);
	background: #fff;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	border-radius: 0.5rem;
}

#minimap {
	width: 100%;
	height: 100%;
}

#minimap-viewbox {
	position: absolute;
	border: 1px solid var(--primary-400);
	pointer-events: none;
	border-radius: 0.125rem;
}
</style>
