<template>
	<div class="chat-main-widget-get-started">
		<div class="chat-main-widget--logo">
			<IconRobot size="6rem" />
			<div class="title2Regular">BIOPTIC Copilot</div>
		</div>
		<div class="headlineSemiBold">What can I ask?</div>
		<div class="chat-main-widget--promts">
			<ChatsPromtButton
				v-for="prompt in prompts"
				:key="prompt.id"
				:prompt="prompt.prompt"
				@onClick="onPromptSelected(prompt.prompt)"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ChatsPromtButton } from '@/features/chats';
import { IconRobot } from '@/shared/uikit';
import { ref } from 'vue';

const emit = defineEmits(['onPromptSelected']);

const prompts = ref<any[]>([
	{
		id: 1,
		prompt: 'Give me smiles of Aspirin',
	},
	{
		id: 2,
		prompt: 'What is the safety of \nCn1cnc2cc3c(Nc4cccc(Br)c4)\nncnc3cc21?',
	},
	{
		id: 3,
		prompt: 'Give me safery info about ethanol',
	},
	{
		id: 4,
		prompt: 'Find similarity between ethanol and aspirin',
	},
	{
		id: 5,
		prompt: 'Fill the eurofins form, compounds are liqued, DMSO, storage -20',
	},
]);

const onPromptSelected = (prompt: string) => {
	emit('onPromptSelected', prompt);
};
</script>

<style lang="stylus" scoped>
.chat-main-widget
	width 100%
	height 100%
	display flex
	flex-direction column
	align-items center
	justify-content center
	gap 2rem
	padding 0 10%
	.chat-main-widget-get-started
		display flex
		flex-direction column
		align-items center
		justify-content flex-start
		gap 2rem
		.chat-main-widget--logo
			display flex
			flex-direction column
			align-items center
			justify-content center
			gap 1rem
		.chat-main-widget--promts
			display flex
			flex-flow row wrap
			justify-content center
			align-items center
			gap 0.75rem
</style>
