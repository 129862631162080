<template>
	<div class="b-text-input-with-select-container">
		<slot name="selected-target"></slot>

		<input
			ref="inputRef"
			:value="modelValue"
			@input="updateValue($event as InputEvent)"
			:class="classInputName"
			:placeholder="placeholder"
			:type="type"
			autocomplete="off"
			class="b-text-input-with-select"
			@blur="$emit('onBlur', $event)"
			@focus="$emit('onFocus', $event)"
		/>

		<div class="b-text-input-with-select-right-icon">
			<slot name="right-icon"></slot>
		</div>
		<slot name="dropdown"></slot>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, defineEmits, defineExpose } from 'vue';

const emit = defineEmits(['update:modelValue', 'onBlur', 'onFocus']);
const inputRef = ref<any>(null);

const props = defineProps({
	modelValue: String,
	type: { type: String, required: false },
	size: { type: String, required: false, default: 'medium' },
	placeholder: { type: String, required: false },
	isDisabled: { type: Boolean, required: false, default: false },
	isSelectable: { type: Boolean, required: false, default: true },
});

const classInputName = computed((): any => {
	var result = {
		'b-text-input-with-select--size-medium': props.size === 'medium',
		'b-text-input-with-select--size-small': props.size === 'small',
		'b-text-input-with-select--size-large': props.size === 'large',
		'b-text-input-with-select--disabled': props.isDisabled,
		'b-text-input-with-select--unselectable': !props.isSelectable,
	};
	return result;
});

const focusInput = () => {
	inputRef.value.focus();
};

const updateValue = (event: InputEvent) => {
	const target = event.target as HTMLInputElement;
	emit('update:modelValue', target.value);
};

defineExpose({
	focusInput,
});
</script>

<style lang="stylus" scoped>
.b-text-input-with-select-container
	position relative
	width 100%
	.b-text-input-with-select-right-icon
		position absolute
		right 0.5rem
		top 50%
		transform translateY(-50%)
		display flex
		align-items center
		justify-content center
	.b-text-input-with-select-left-icon
		position absolute
		left 0.5rem
		top 50%
		transform translateY(-50%)
		display flex
		align-items center
		justify-content center
	input
		all: unset;

	.b-text-input-with-select
		width 100%
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		font-family: var(--font-family);
		font-style: normal;
		font-weight: 400;
		background white
		outline: 1px solid var(--neutral-200);
		outline-offset -1px;
		border-radius: 1.75rem;

		transition all 0.2s ease-in-out

		&:focus
			outline-color: var(--primary-600);
			background var(--neutral-50);

		&:hover
			background var(--neutral-50);


		&::placeholder
			color: var(--neutral-500)
		&--size-large
			height 3.5rem
			font-size: 0.875rem;
			padding: 1.12rem 1.5rem
			transition all 0.2s ease-in-out
		&--size-medium
			height 2.5rem
			font-size: 0.75rem;
			padding: 0.75rem 1rem;
			transition all 0.2s ease-in-out
		&--size-small
			height 2rem
			font-size: 0.75rem;
			padding: 0.5rem 0.75rem;
			transition all 0.2s ease-in-out
		&--disabled
			pointer-events none
			user-select none
			background var(--neutral-200)
		&--unselectable
			pointer-events none
			user-select none
</style>
