<template>
	<div class="pipeline-chat-dropdown">
		<div class="pipeline-chat-dropdown--header">
			<div class="subheadlineBold">
				Generate Flow
				<bChip
					label="Beta"
					class="beta-chip"
					:isGhost="true"
					type="primary"
					size="small"
				/>
			</div>

			<bUnstyledButton @onClick="toggleDropdown">
				<template v-slot:content>
					<IconClose size="1rem" />
				</template>
			</bUnstyledButton>
		</div>
		<div class="pipeline-chat-dropdown--body">
			<PipelineMessageCard
				v-for="(message, index) in messages"
				:key="`${message.id}-message-key-${index}`"
				:message="message"
				@on-generate-pipeline="onGenerate"
			/>
		</div>
		<div
			v-if="pipelines.length > 0"
			class="pipeline-chat-dropdown--pipelines"
		>
			<bButton
				v-for="(pipeline, index) in pipelines"
				:key="`key-pipeline-chip-${index}`"
				:label="`Pipeline ${index + 1}`"
				type="primary"
				@click="onGenerate(pipeline)"
			/>
		</div>

		<div class="pipeline-chat-dropdown--footer">
			<PipelineGenerateInput
				v-model="searchQuery"
				@onSendMessage="onSendMessage"
				:isLoading="isLoading"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { PipelineMessageCard } from '@/entities/chats';
import type { ForeignObjectEntity } from '@/shared/api';
import { IconClose, bButton, bChip, bUnstyledButton } from '@/shared/uikit';
import { ref } from 'vue';
import PipelineGenerateInput from './PipelineGenerateInput.vue';
import { pipelineChatModel } from './model';

const { messages, sendMessage, isLoading, pipelines } = pipelineChatModel;
const emit = defineEmits(['onToggleDropdown', 'onGenerate']);
const searchQuery = ref('');

const onSendMessage = (message: string) => {
	sendMessage(message);
};

const onGenerate = (steps: ForeignObjectEntity[]) => {
	emit('onGenerate', steps);
};

const toggleDropdown = () => {
	emit('onToggleDropdown');
};
</script>

<style lang="stylus" scoped>
.pipeline-chat-dropdown
	position: absolute
	width: 26.25rem;
	height: calc(100vh - 11.5rem);
	left: 0
	bottom 3.5rem
	padding: 0.5rem
	display: grid;
	grid-template-rows: auto 1fr auto;
	gap: 0.5rem;
	background: white
	border-radius: 1rem;
	box-shadow: 0px 0.5px 5px 0px rgba(11, 10, 13, 0.04), 0px 3.75px 11px 0px rgba(11, 10, 13, 0.19);
	.pipeline-chat-dropdown--body
		overflow-y scroll
		overflow-x hidden
		padding-top 0.5rem
		padding-bottom 4rem
		&::-webkit-scrollbar
			width: 0.25rem
		.b-textarea-container
			textarea
				background: transparent
	.pipeline-chat-dropdown--pipelines
		position absolute
		bottom 4rem
		padding 0.5rem
		display flex
		flex-flow row wrap
		align-items flex-start
		justify-content flex-start
		gap 0.5rem
		z-index 11
	.pipeline-chat-dropdown--footer
		display	flex
		flex-direction column
		gap 0.5rem

		.b-button
			display: flex
			justify-content: center
	.pipeline-chat-dropdown--header
		width 100%
		padding-left 0.5rem
		padding-top 0.5rem
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center
		.subheadlineBold
			display: flex
			flex-direction: row
			align-items: center
			gap: 0.25rem
			.beta-chip
				text-transform uppercase
				font-size: 0.625rem;
				font-weight: 600;
</style>

<style lang="stylus">
.pipeline-chat-dropdown--body
	.b-textarea-container
		textarea
			background: transparent
			border: none
			outline: none
			&:focus
				outline: none
				box-shadow: none
</style>
