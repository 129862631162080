<template>
	<div class="cart-details-page">
		<CartListWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import { CartListWidget, cartListModel } from '@/widgets/cart';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { title, description, clear: clearNavigation } = navigationHeaderModel;
const { fetchCartDetails, hits } = cartListModel;

onMounted(() => {
	reloadPage();
	Analytics.send(AnalyticsEvents.CART.VIEW_CART_PAGE);
});

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	clearNavigation();

	await fetchCartDetails();
	if (router.currentRoute.value.name === 'cart-details') {
		title.value = 'Cart details';
		description.value = `Selected molecules`;
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.cart-details-page
	width 100%
	height 100%
	padding 0 calc(10vw)
	display grid
	grid-template-columns minmax(40rem, 1fr)
	gap 1.5rem
</style>
