import { HitEntity, JobsService, ResultsSortOption } from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	hits: HitEntity[];
}

const data: IViewModel = {
	isLoading: false,
	hits: [],
};

const state = reactive(data);

async function fetchJobHitResults(id: string) {
	state.isLoading = true;
	try {
		const result = await JobsService.getInstance().fetchJobHits(
			id,
			10,
			0,
			ResultsSortOption.defaultOption,
			[]
		);

		state.hits = result.hits;
	} catch (error: any) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const ligandBasedResultsMessageModel = {
	...toRefs(state),
	fetchJobHitResults,
};
