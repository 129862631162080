import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	JobsService,
	NavigationTabType,
	SearchType,
	type ClusterEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';
import { clusterResultsFiltersModel } from './cluster-results-filters-widget';
import { clusterResultsListModel } from './cluster-results-list-widget';

interface IViewModel {
	isLoading: boolean;
	cluster: ClusterEntity | null;
}
const data: IViewModel = {
	isLoading: false,
	cluster: null,
};

const state = reactive(data);

async function fetchClusterDetails(jobId: string, clusterId: string) {
	state.isLoading = true;
	try {
		state.cluster = await JobsService.getInstance().fetchCluster(
			jobId,
			clusterId
		);

		const { setFilters } = clusterResultsFiltersModel;
		setFilters(state.cluster.attributes);
		const { fetchClusterResults } = clusterResultsListModel;
		fetchClusterResults(1);

		const { addTab } = navigationTabsModel;
		const job = await JobsService.getInstance().getJob(jobId);

		addTab({
			id: `${jobId}-${state.cluster.index}`,
			title: `Cluster #${state.cluster.index}`,
			description: state.cluster.scaffold_smiles,
			path: `/job/${jobId}/cluster/${state.cluster.index}`,
			type:
				job.parameters.type === SearchType.LIGAND
					? NavigationTabType.LIGAND
					: NavigationTabType.TARGET,
		});

		Analytics.send(AnalyticsEvents.CLUSTER.VIEW_CLUSTER_PAGE, {
			job_id: jobId,
			cluster_id: state.cluster.index,
		});
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

export const clusterDetailsModel = {
	...toRefs(state),
	fetchClusterDetails,
};
