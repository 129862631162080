<template>
	<div class="admet-body-container">
		<el-table
			v-if="!isLoading"
			ref="tableRef"
			:data="tableData"
			style="width: 100%"
			:border="true"
			table-layout="auto"
			:cell-class-name="tableCellClassName"
		>
			<el-table-column prop="SMILES" width="250" fixed>
				<template #header>
					<div class="subheadlineBold">SMILES</div>
				</template>
				<template #default="scope">
					<SmilesColumnCard
						:smiles="scope.row.SMILES"
						:key="scope.row.SMILES"
						:id="`scope.row.id-${currentPage}-${limit}`"
					/>
				</template>
			</el-table-column>

			<el-table-column
				v-for="(column, index) in preparedColumns"
				:key="`column-key${column.prop}`"
				:prop="column.prop"
				sortable
			>
				<template #header>
					<bTooltip
						:id="`tooltip-${column.label}-${index}`"
						:hoverTitle="
							getPredictorInfo(column.prop, column.label)
						"
						type="primary"
					>
						<template v-slot:body>
							<div class="subheadlineBold">
								{{ column.label }}
							</div>
						</template>
					</bTooltip>
				</template>
				<template #default="scope">
					<div class="subheadlineSemiBold">
						{{ scope.row[column.prop] }}
					</div>
				</template>
			</el-table-column>

			<template #empty>
				<div class="flex items-center justify-center h-100%">
					<el-empty description="No Data" />
				</div>
			</template>
		</el-table>

		<div v-else>
			<div class="empty-view"><bLoader size="4" /></div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { SearchAttributeType } from '@/shared/api';
import { bLoader, bTooltip } from '@/shared/uikit';
import {
	ElEmpty,
	ElTable,
	ElTableColumn,
	type TableColumnCtx,
} from 'element-plus';
import { computed, ref, type PropType } from 'vue';
import SmilesColumnCard from './SmilesColumnCard.vue';
import { ADMETColumnEntity } from './entities';

const tableRef = ref<InstanceType<typeof ElTable>>();

const props = defineProps({
	isLoading: {
		type: Boolean,
		default: false,
	},
	tableData: {
		type: Array,
		required: true,
	},
	columns: {
		type: Array as PropType<{ prop: string; label: string }[]>,
		required: true,
	},
	currentPage: {
		type: Number,
		required: true,
	},
	limit: {
		type: Number,
		required: true,
	},
});

const tableCellClassName = ({
	row,
	rowIndex,
	column,
	columnIndex,
}: {
	row: any;
	rowIndex: number;
	column: TableColumnCtx<any>;
	columnIndex: number;
}) => {
	const randomColor = Math.floor(Math.random() * 3);

	switch (randomColor) {
		case 0:
			return 'red-cell';
		case 1:
			return 'yellow-cell';
		case 2:
			return 'green-cell';
		default:
			return '';
	}
};

const preparedColumns = computed(() => {
	const keysToInclude: string[] = [
		SearchAttributeType.WEIGHT_FULL,
		SearchAttributeType.HBA_FULL,
		SearchAttributeType.HBD_FULL,
		SearchAttributeType.TPSA,
		SearchAttributeType.ROTATABLE_BONDS,
		'SMILES',
	];

	return props.columns.filter((item) => !keysToInclude.includes(item.label));
});

const updateScroll = () => {
	tableRef.value?.scrollTo({
		top: 0,
		behavior: 'smooth',
	});
};

const getPredictorInfo = (key: string, label: string): string => {
	const findItem = ADMETColumnEntity.options.find(
		(item) => item.label === key
	);

	if (findItem) {
		return `${findItem.units ? findItem.units : ''} ${findItem.description}`;
	}

	return label;
};

defineExpose({
	updateScroll,
});
</script>

<style lang="stylus">
// .el-table.is-scrolling-left.el-table--border .el-table-fixed-column--left.is-last-column.el-table__cell,
// .el-table.is-scrolling-middle .el-table-fixed-column--left.is-last-column.el-table__cell,
// .el-table.is-scrolling-right .el-table-fixed-column--left.is-last-column.el-table__cell
// 	.cell
// 		max-height auto
// 		writing-mode: horizontal-tb;
// 		transform: none
.admet-body-container
	width 100%
	height 100%;
	display: flex
	flex-direction: column
	justify-content: flex-start
	gap 2.5rem
	.subheadlineBold
		padding 0.5rem

	.el-table__row
		pointer-events none
	.el-table__header
		.cell
			height 100%
			// text-align left
			// // writing-mode: vertical-rl;
			// // writing-mode: horizontal-tb;
			// writing-mode: vertical-lr;
			// transform: rotate(-180deg);
			white-space nowrap
			// // width 100%
			display flex
			flex-direction row
			align-items center
			justify-content center
			gap 1rem


	.el-empty__description
		font-family var(--font-family)
	.b-empty-view
		height 100%
		margin 2rem 0
	.footnoteRegular
		display flex
		justify-content flex-start
		align-items center
	.el-table--fit
		border-radius 0.75rem
		border 1px solid var(--neutral-200)
	.cell
		font-family var(--font-family)
		color var(--neutral-800)
	.el-table__row
		border-radius: 0.75rem

		&:last-child
			border none !important
	.b-simple-number-input
		text-align center !important
	.is-indeterminate
		.el-checkbox__inner
			background-color var(--primary-600)
			border-color var(--primary-600)
	.el-checkbox__input.is-checked .el-checkbox__inner
		background-color var(--primary-600)
		border-color var(--primary-600)

	.el-table--border::after, .el-table--border::before, .el-table__border-left-patch, .el-table__inner-wrapper::before, .el-table__inner-wrapper::after
		display none
	.el-table--fit
		border-radius 0.5rem
	.cell
		width 100%
		font-family var(--font-family)
		white-space nowrap
		display flex
		flex-direction row
		align-items center
		justify-content center
		&:hover
			background none

	tr
		.cell
			font-family var(--font-family)
			color var(--neutral-800)
			font-weight bold
			padding 0
</style>
<style>
.red-cell {
	background-color: var(--danger-200);
}

.yellow-cell {
	background-color: var(--warning-200);
}

.green-cell {
	background-color: var(--success-200);
}
</style>
