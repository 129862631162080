<template>
	<div
		class="druglikeness-container"
		:class="{ 'druglikeness-container--opened': isOpened }"
		ref="elementRef"
	>
		<div
			class="druglikeness-target--header"
			@click="() => (isOpened = !isOpened)"
		>
			<section>
				<div class="subheadlineSemiBold">
					{{ druglikenessSelectedOptionsLabel }}
				</div>
			</section>
			<bUnstyledButton @onClick="() => (isOpened = !isOpened)">
				<template v-slot:content>
					<IconArrowUp v-if="isOpened" size="1.125rem" />
					<IconArrowDown v-else size="1.125rem" />
				</template>
			</bUnstyledButton>
		</div>
		<div v-if="isOpened" class="druglikeness-dropdown">
			<div
				class="druglikeness-item"
				v-for="(option, index) in druglikenessOptions"
				:key="'druglikeness-item-key' + index"
				:class="{
					'druglikeness-item--active': option.isSelected,
				}"
				@click="selectOption(option)"
			>
				<div class="druglikeness-item-info">
					<div class="footnoteBold">{{ option.label }}</div>
				</div>
				<bToggle
					v-model="option.isSelected"
					size="small"
					@update:modelValue="selectOption(option)"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers/';
import type { DruglikenessEntity } from '@/shared/api';
import {
	IconArrowDown,
	IconArrowUp,
	bToggle,
	bUnstyledButton,
} from '@/shared/uikit';
import { computed, ref } from 'vue';
import { jobResultsFiltersModel } from './model';

const { druglikenessOptions, updateFiltersByDruglikeness } =
	jobResultsFiltersModel;

const isOpened = ref(true);
const elementRef = ref<HTMLElement | null>(null);

const druglikenessSelectedOptionsLabel = computed(() => {
	const selectedItems = druglikenessOptions.value.filter(
		(item) => item.isSelected
	);
	if (selectedItems.length > 0) {
		return selectedItems.map((item) => item.label).join(', ');
	} else {
		return 'Druglikeness';
	}
});

const selectOption = (option: DruglikenessEntity) => {
	option.isSelected = !option.isSelected;

	updateFiltersByDruglikeness();

	Analytics.send(AnalyticsEvents.Filter.SELECTED_DRUG_LIKENESS_POSTFILTER, {
		label: option.label,
		isSelected: option.isSelected,
	});
};
</script>

<style lang="stylus" scoped>
.druglikeness-container
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content center
	padding 1rem
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04);
	z-index 1
	&:hover
		background var(--neutral-50)
		cursor pointer
	&--opened
		background var(--neutral-50)
		&:hover
			cursor default
		.druglikeness-target--header
			padding-bottom 1rem
	.druglikeness-target--header
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		cursor pointer
		section
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			.bodySmallRegular
				color var(--neutral-600)
				white-space nowrap
		.b-unstyled-button
			border-radius 50%

	.druglikeness-dropdown
		margin-top: 0.5rem
		display: flex;
		width 20rem
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border-radius: 0.25rem;
		background: var(--neutral-50);
		z-index 2
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.druglikeness-item
			width 100%
			height 2.5rem
			display grid
			grid-template-columns max-content auto
			align-items center
			justify-content space-between
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			&:hover
				background: var(--neutral-100)
				cursor pointer
			&--active
				user-select none
			&--disabled
				user-select none
				pointer-events none
				.footnoteBold
					color var(--neutral-400) !important

			.druglikeness-item-info
				display flex
				flex-direction column
				align-items flex-start
				justify-content center
				gap 0
				.footnoteBold
					color var(--neutral-700)
				span
					color var(--neutral-400) !important


.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
