import { computed, reactive, toRefs } from 'vue';

export enum OnboardingUserRoles {
	Bioinformatician = 'Bioinformatician',
	ComputationalChemist = 'Computational chemist',
	StructuralBiologist = 'Structural biologist',
	MolecularBiologist = 'Molecular biologist',
	Other = 'Other',
}

export type OnboardingUserRolesType = {
	title: string;
};

interface IViewModel {
	name: string;
	role: OnboardingUserRolesType;
	roleOptions: OnboardingUserRolesType[];
	haveYouHeardAboutUs: string;
	customRole: string;
	isLoading: boolean;
}
const data: IViewModel = {
	name: '',
	role: {
		title: '',
	} as OnboardingUserRolesType,
	roleOptions: Object.values(OnboardingUserRoles).map((role) => {
		return {
			title: role,
		} as OnboardingUserRolesType;
	}),
	haveYouHeardAboutUs: '',
	customRole: '',
	isLoading: false,
};

const state = reactive(data);

const isValid = computed(() => {
	if (state.role.title === OnboardingUserRoles.Other) {
		return (
			state.name.length > 0 &&
			state.customRole.length > 0 &&
			state.haveYouHeardAboutUs.length > 0
		);
	}

	return (
		state.name.length > 0 &&
		state.role.title.length > 0 &&
		state.haveYouHeardAboutUs.length > 0
	);
});

export const tellAboutYourselfStepModel = {
	...toRefs(state),
	isValid,
};
