<template>
	<nav class="navigation-header">
		<div class="navigation-header--history">
			<bUnstyledButton
				:isDisabled="isDisabledBack"
				@onClick="onClickBack"
			>
				<template v-slot:content>
					<IconChevronLeft size="1.25rem" />
				</template>
			</bUnstyledButton>
			<bUnstyledButton
				:isDisabled="isDisabledForward"
				@onClick="onClickForward"
			>
				<template v-slot:content>
					<IconChevronRight size="1.25rem" />
				</template>
			</bUnstyledButton>
		</div>

		<div v-if="!isLoading" class="navigation-header--title">
			<div class="subheadlineBold">{{ title }}</div>
			<div v-if="description.length > 0" class="bodySmallRegular">
				{{ description }}
			</div>
		</div>
		<OpenAccountSettingsButton />
	</nav>
</template>

<script setup lang="ts">
import {
	OpenAccountSettingsButton,
	navigationHeaderModel,
} from '@/features/navigation/';
import {
	IconChevronLeft,
	IconChevronRight,
	bUnstyledButton,
} from '@/shared/uikit';
import { onMounted, ref } from 'vue';

const { title, description, isLoading } = navigationHeaderModel;

const isDisabledForward = ref<boolean>(true);
const isDisabledBack = ref<boolean>(true);

onMounted(() => {
	updateButtonStates();

	window.addEventListener('popstate', updateButtonStates);
});

const updateButtonStates = () => {
	isDisabledBack.value = window.history.length <= 1;
	isDisabledForward.value = !window.history.state?.forward;
};

const onClickBack = () => {
	window.history.back();
};

const onClickForward = () => {
	window.history.forward();
};
</script>

<style lang="stylus" scoped>
.navigation-header
	height 3.5rem
	padding 0.5rem 1rem
	display grid
	grid-template-columns auto 1fr auto
	align-items center
	gap 0.5rem
	border-bottom: 1px solid var(--neutral-200);
	&--title
		width calc(100vw - 10rem)
		height 100%
		display flex
		flex-direction column
		justify-content center
		.bodySmallRegular
			width 100%
			overflow hidden
			text-overflow ellipsis
			white-space nowrap
			color var(--neutral-400)
	&--history
		display flex
		flex-direction row
		justify-content center
		align-items center
		gap 0.25rem
		.history-button-disabled
			cursor not-allowed
			pointer-events none
</style>
