<template>
	<div class="collection-details-page">
		<CollectionFiltersWidget />
		<CollectionListWidget />
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { navigationHeaderModel } from '@/features/navigation';
import {
	CollectionFiltersWidget,
	CollectionListWidget,
	collectionDetailsModel,
} from '@/widgets/collections';
import { onBeforeUnmount, onMounted, watch } from 'vue';

const { fetchCollectionDetails, clear, collectionDetails } =
	collectionDetailsModel;
const { title, description, clear: clearNavigation } = navigationHeaderModel;

onMounted(() => reloadPage());

watch(
	() => router.currentRoute.value.params.id,
	() => {
		reloadPage();
	}
);

const reloadPage = async () => {
	clear();
	clearNavigation();

	const { id } = router.currentRoute.value.params;

	if (!id) {
		return;
	}

	await fetchCollectionDetails(id as string);

	if (router.currentRoute.value.name === 'collection-details') {
		title.value = 'Collection items';
		description.value = 'from ' + collectionDetails.value?.name || '';
	}
};

onBeforeUnmount(() => {
	clearNavigation();
});
</script>

<style lang="stylus" scoped>
.collection-details-page
	width 100%
	height 100%
	padding 0 calc(5vw)
	display grid
	grid-template-columns 23rem minmax(40rem, 1fr)
	gap 1.5rem
	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(2vw)
	@media (max-width calc(64.5rem + 10vw))
		padding 0 calc(1vw)
</style>
