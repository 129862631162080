import { ResultsSortOption } from '@/shared/api';
import { cartListModel } from '@/widgets/cart';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	selectedOption: ResultsSortOption;
}
const data: IViewModel = {
	selectedOption: ResultsSortOption.defaultCartOption,
};

const state = reactive(data);

const options = computed(() => {
	const { hits } = cartListModel;

	if (!hits.value?.length) {
		return [];
	}

	return Object.keys(hits.value?.[0].attributes)
		.map((attribute) =>
			ResultsSortOption.mapWithSortDirection({
				type: attribute,
			})
		)
		.flat();
});

export const cartSortModel = {
	...toRefs(state),
	options,
};
