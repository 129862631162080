<template>
	<bModal
		v-if="isVisible"
		type="primary"
		:class="{ 'is-sended': isSended }"
		:title="
			isSended
				? 'Thank you for your request!'
				: 'eMolecules order details'
		"
		:width="500"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bButton
				v-if="!isSended"
				label="Cancel"
				type="tertiary"
				:isGhost="true"
				@onClick="onClose"
			/>
			<bButton
				v-if="!isSended"
				label="Send"
				type="primary"
				:isGhost="true"
				:isDisabled="!isValid"
				:isLoading="isLoading"
				@onClick="onClick"
			>
				<template v-slot:icon-left>
					<IconSent
						size="0.75rem"
						:color="
							!isValid
								? ThemeColors.neutral[400]
								: ThemeColors.primary[600]
						"
					/>
				</template>
			</bButton>
		</template>
		<template v-slot:body>
			<div v-if="!isSended" class="suggest-modal-body">
				<bInput
					placeholder="Shipping address"
					type="text"
					size="large"
					@onChange="(value) => (shippingAddress = value.value)"
					:isDisabled="false"
					:isCaption="false"
				/>

				<bSelect
					v-model="compoundFormat"
					size="large"
					placeholder="Compound Format"
					:options="compoundFormatOptions"
				/>

				<bInput
					v-if="compoundFormat.title === CompoundFormatType.Other"
					placeholder="Specify your compound format"
					type="text"
					size="large"
					@onChange="(value) => (customCompoundFormat = value.value)"
					:isDisabled="false"
					:isCaption="false"
				/>

				<bInput
					placeholder="Comments"
					type="text"
					size="large"
					@onChange="(value) => (comment = value.value)"
					:isDisabled="false"
					:isCaption="false"
				/>
			</div>
			<bImageSuccess v-else :width="300" />
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import {
	IconSent,
	ThemeColors,
	bButton,
	bImageSuccess,
	bInput,
	bModal,
	bSelect,
} from '@/shared/uikit/';
import { computed, defineEmits } from 'vue';
import { CompoundFormatType, sendEmoleculesModel } from './model';

const emit = defineEmits(['onClose']);
const {
	customCompoundFormat,
	compoundFormat,
	compoundFormatOptions,
	isVisible,
	submit,
	shippingAddress,
	comment,
	isLoading,
	isSended,
} = sendEmoleculesModel;

const isValid = computed(() => {
	if (compoundFormat.value.title === CompoundFormatType.Other) {
		return (
			shippingAddress.value.length > 0 &&
			customCompoundFormat.value.length > 0
		);
	}

	return (
		shippingAddress.value.length > 0 &&
		compoundFormat.value.title.length > 0
	);
});

const onClose = () => {
	isVisible.value = false;

	Analytics.send(AnalyticsEvents.CART.CLOSE_EMOLECULES_MODAL);
};

const onClick = () => {
	submit();
};
</script>

<style lang="stylus" scoped>
.suggest-modal-body
	padding-top 2rem
	display flex
	flex-direction column
	align-items center
	gap 1rem
.is-sended
	.b-modal-header
		.bodyRegular
			text-align center
</style>
<style lang="stylus">
.b-modal-container
	&.is-sended
		.b-modal-header
			display flex
			flex-direction column
			align-items center
			justify-content center
			.bodyRegular
				text-align center
				max-width max-content
		.b-modal-body
			display flex
			flex-direction column
			align-items center
			justify-content center
	.b-text-input
		border-radius 0.5rem !important
		padding-left 1rem !important
</style>
