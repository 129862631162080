<template>
	<div class="link-copy-container">
		<transition name="fade">
			<div v-if="isHovered || isCopied" class="link-copy-tooltip">
				{{ title }}
			</div>
		</transition>

		<bUnstyledButton
			@mouseenter="isHovered = true"
			@mouseleave="isHovered = false"
			@onClick="onCopy"
			:class="buttonClasses"
		>
			<template v-slot:content>
				<IconCheck
					v-show="isCopied && !isLoading"
					size="1.5rem"
					:color="iconColor"
				/>
				<IconCopy
					v-show="!isCopied && !isLoading"
					size="1.5rem"
					:color="iconColor"
				/>

				<IconReload
					v-show="isLoading"
					size="1.5rem"
					:color="iconColor"
					class="spin"
				/>
			</template>
		</bUnstyledButton>
	</div>
</template>

<script lang="ts" setup>
import { copyToClipboard } from '@/shared/lib';
import { computed, ref, watch } from 'vue';
import { IconCheck, IconCopy, IconReload, bUnstyledButton } from '.';
import { ThemeColors } from '..';

const emit = defineEmits(['onCopy']);

const props = defineProps({
	link: { type: String, required: true },
	hoverTitle: { type: String, required: false, default: 'Copy link' },
	copiedTitle: { type: String, required: false, default: 'Copied!' },
	isLoading: { type: Boolean, required: false, default: false },
	isForce: { type: Boolean, required: false, default: false },
	type: { type: String, required: false, default: 'primary' },
});

const isHovered = ref(false);
const isCopied = ref(false);

const title = computed(() => {
	if (isCopied.value) {
		return props.copiedTitle;
	}

	if (isHovered.value) {
		return props.hoverTitle;
	}

	return '';
});

watch(
	() => props.link,
	() => {
		copyLink();
	}
);

const buttonClasses = computed(() => {
	return {
		'b-unstyled-button--primary': props.type === 'primary',
		'b-unstyled-button--neutral': props.type === 'neutral',
	};
});

const iconColor = computed(() => {
	if (props.type === 'primary') return ThemeColors.primary[800];

	return ThemeColors.neutral[800];
});

const copyLink = () => {
	if (props.isLoading && props.link.length === 0) {
		return;
	}

	isCopied.value = true;

	copyToClipboard(props.link);


	setTimeout(() => {
		isCopied.value = false;
		isHovered.value = false;
	}, 600);
};

const onCopy = () => {
	if (props.isForce) {
		copyLink();
	} else {
		emit('onCopy');
	}
};
</script>

<style lang="stylus" scoped>
.link-copy-container
	position relative
	.b-unstyled-button
		padding 0.5rem
		border-radius 50%
		&--neutral
			&:hover
				background: var(--neutral-100)
		&--primary
			background: var(--primary-100)
			&:hover
				background: var(--primary-200)
	.link-copy-tooltip
		position absolute
		bottom 3rem
		left 50%
		transform translateX(-50%)
		padding 0.5rem
		background: var(--neutral-600)
		border-radius 0.5rem
		font-family: var(--font-family)
		font-size: 0.75rem
		color: white
		white-space: nowrap
		z-index 9999
</style>
