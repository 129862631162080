import { HitEntity } from '..';
import { NavigationTabEntity } from '../shared';

type DBRecord = {
	id: string;
	[key: string]: any;
};

export class IDBService<T extends DBRecord> {
	private dbName: string;
	private storeName: string;
	private db!: IDBDatabase;
	private entityConstructor: new (...args: any[]) => T;

	public constructor(
		dbName: string,
		storeName: string,
		entityConstructor: new (...args: any[]) => T
	) {
		this.dbName = dbName;
		this.storeName = storeName;
		this.entityConstructor = entityConstructor;
	}

	public async init(): Promise<void> {
		try {
			const storeExists = await this.checkDatabaseAndStoreExist();
			if (!storeExists) {
				await this.upgradeDatabase();
			} else {
				await this.openConnection();
			}
		} catch (error) {
			console.error('Failed during database initialization:', error);
			throw error;
		}
	}

	public async ensureStoreExists(): Promise<void> {
		const storeExists = await this.checkDatabaseAndStoreExist();
		if (!storeExists) {
			await this.upgradeDatabase();
		}
	}

	private async openConnection(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const request = indexedDB.open(this.dbName);

			request.onsuccess = (event: any) => {
				this.db = event.target.result;
				resolve();
			};

			request.onerror = (event: any) => {
				reject(`Error opening database: ${event.target.errorCode}`);
			};

			request.onupgradeneeded = (event: any) => {
				this.db = event.target.result;
				if (!this.db.objectStoreNames.contains(this.storeName)) {
					this.db.createObjectStore(this.storeName, {
						keyPath: 'id',
						autoIncrement: true,
					});
				}
			};
		});
	}

	private async checkDatabaseAndStoreExist(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const request = indexedDB.open(this.dbName);
			request.onupgradeneeded = (event: any) => {
				event.target.transaction.abort();
				resolve(false);
			};

			request.onsuccess = (event: any) => {
				const db = event.target.result;
				const storeExists = db.objectStoreNames.contains(
					this.storeName
				);
				db.close();
				resolve(storeExists);
			};

			request.onerror = () => {
				resolve(false);
			};
		});
	}

	private async upgradeDatabase(): Promise<void> {
		const currentVersion = await this.getCurrentDbVersion();
		const newVersion = currentVersion + 1;

		return new Promise<void>((resolve, reject) => {
			const request = indexedDB.open(this.dbName, newVersion);

			request.onupgradeneeded = (event: any) => {
				const db = event.target.result;
				if (!db.objectStoreNames.contains(this.storeName)) {
					db.createObjectStore(this.storeName, {
						keyPath: 'id',
						autoIncrement: true,
					});
				}
			};

			request.onsuccess = (event: any) => {
				this.db = event.target.result;
				resolve();
			};

			request.onerror = (event: any) => {
				reject(`Error upgrading database: ${event.target.errorCode}`);
			};
		});
	}

	private async getCurrentDbVersion(): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			const request = indexedDB.open(this.dbName);

			request.onupgradeneeded = (event: any) => {
				event.target.transaction.abort();
				resolve(0);
			};

			request.onsuccess = (event: any) => {
				const db = event.target.result;
				const version = db.version;
				db.close();
				resolve(version);
			};

			request.onerror = (event: any) => {
				console.error(
					`Error getting current DB version: ${event.target.errorCode}`
				);
			};
		});
	}

	public async get(id: string): Promise<T | undefined> {
		if (!this.db) {
			await this.init();
		}

		const result = await this.transaction('readonly', (store) =>
			store.get(id)
		);
		if (result && this.hasFromObjectMethod()) {
			return (this.entityConstructor as any).fromObject(result);
		}
		return result;
	}

	public async getAll(): Promise<T[]> {
		const result = await this.transaction('readonly', (store) =>
			store.getAll()
		);
		if (result.length > 0 && this.hasFromObjectMethod()) {
			return result.map((item) =>
				(this.entityConstructor as any).fromObject(item)
			);
		}
		return result;
	}

	public async add(item: Omit<T, 'id'>): Promise<void> {
		await this.transaction('readwrite', (store) => store.add(item));
	}

	public async put(item: T): Promise<void> {
		await this.transaction('readwrite', (store) => store.put(item));
	}

	public async addOrUpdate(item: T): Promise<void> {
		try {
			const existingItem = await this.get(item.id);
			if (existingItem) {
				await this.put(item);
			} else {
				await this.add(item as Omit<T, 'id'>);
			}
		} catch (error) {
			console.error('Error adding or updating item:', error);
			throw error;
		}
	}

	public async delete(id: string): Promise<void> {
		await this.transaction('readwrite', (store) => store.delete(id));
	}

	public async clear(): Promise<void> {
		await this.transaction('readwrite', (store) => store.clear());
	}

	private transaction<R>(
		mode: IDBTransactionMode,
		action: (store: IDBObjectStore) => IDBRequest<R>
	): Promise<R> {
		return new Promise<R>((resolve, reject) => {
			const transaction = this.db.transaction([this.storeName], mode);
			const store = transaction.objectStore(this.storeName);
			transaction.onerror = (event: any) =>
				reject(`Transaction error: ${event.target.errorCode}`);
			const request = action(store);
			request.onsuccess = (event: any) => resolve(event.target.result);
			request.onerror = (event: any) =>
				reject(`Request error: ${event.target.errorCode}`);
		});
	}

	private hasFromObjectMethod(): boolean {
		return typeof (this.entityConstructor as any).fromObject === 'function';
	}
}

export class IndexedDBNavigationTabsInstance {
	private static dbName = 'navigationTabs';
	private static storeName = 'tabs';

	static async get(): Promise<IDBService<NavigationTabEntity>> {
		try {
			const dbService = new IDBService<NavigationTabEntity>(
				IndexedDBNavigationTabsInstance.dbName,
				IndexedDBNavigationTabsInstance.storeName,
				NavigationTabEntity
			);

			await dbService.init();

			return dbService;
		} catch (error) {
			console.error(
				'Error working with IndexedDBNavigationTabsInstance:',
				error
			);
			throw Error('Error working with IndexedDBNavigationTabsInstance');
		}
	}
}

export class IndexedDBHitsInstance {
	private static dbName = 'hits';
	private static storeName = 'selectedHits';

	static async get(): Promise<IDBService<HitEntity>> {
		try {
			const dbService = new IDBService<HitEntity>(
				IndexedDBHitsInstance.dbName,
				IndexedDBHitsInstance.storeName,
				HitEntity
			);

			await dbService.init();

			return dbService;
		} catch (error) {
			console.error('Error working with IndexedDBHitsInstance:', error);
			throw Error('Error working with IndexedDBHitsInstance');
		}
	}
}
