<template>
	<div class="container">
		<IconLogo size="2.5rem" :color="ThemeColors.primary[600]" />

		<div class="login-container">
			<div class="header-block">
				<div class="calloutRegular">
					{{
						isSent
							? $t('auth.resetPassword.sent')
							: $t('auth.resetPassword.title')
					}}
				</div>

				<div v-if="isSent" class="footnoteRegular">
					{{ $t('auth.resetPassword.subtitleFirst') }}
					<span>{{ form.email }}</span>
					{{ $t('auth.resetPassword.subtitleSecond') }}
					<ResendVerifyPasswordButton
						id="sendedVerifyPassword"
						@onResend="onResend"
					/>
				</div>
			</div>

			<form v-if="!isSent">
				<bInput
					ref="inputRef"
					:placeholder="$t('auth.signin.form.emailPlaceholder')"
					type="email"
					size="large"
					:isDisabled="false"
					:isCaption="false"
					@onChange="onChangeEmail"
				/>
				<bButton
					:label="$t('auth.resetPassword.button.send')"
					type="primary"
					size="large"
					:isDisabled="!form.emailValid"
					:isLoading="isLoading"
					@click="sendRecoveryEmail(form.email)"
				/>
				<bButton
					:label="$t('auth.resetPassword.button.cancel')"
					type="tertiary"
					size="large"
					@onClick="router.push('/login')"
				/>
			</form>
		</div>

		<div class="login-footer">
			<bButton
				:label="$t('auth.button.signin')"
				type="tertiary"
				@onClick="router.push('/login')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents, router } from '@/app/providers';
import { IconLogo, ThemeColors, bButton, bInput } from '@/shared/uikit';
import { onMounted, onUnmounted, reactive } from 'vue';
import ResendVerifyPasswordButton from './ResendVerifyPasswordButton.vue';
import { resetModel } from './model';

const form = reactive({
	email: '',
	emailValid: false,
});

const { isSent, isLoading, sendRecoveryEmail } = resetModel;

const onChangeEmail = (value: any) => {
	form.email = value.value;
	form.emailValid = value.isValid;
};

onMounted(() => {
	Analytics.send(AnalyticsEvents.ResetPassword.VIEW_PAGE);
});

onUnmounted(() => {
	isSent.value = false;
});
const onResend = () => {
	sendRecoveryEmail(form.email);
};
</script>
<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';

.header-block
	.footnoteRegular
		padding 0 3.13rem !important
</style>
