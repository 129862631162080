<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M17.2999 6.30004C16.9099 5.91004 16.2799 5.91004 15.8899 6.30004L10.2499 11.94L11.6599 13.35L17.2999 7.70004C17.6799 7.32004 17.6799 6.68004 17.2999 6.30004ZM21.5399 6.29004L11.6599 16.17L8.17989 12.7C7.78989 12.31 7.15989 12.31 6.76989 12.7C6.37989 13.09 6.37989 13.72 6.76989 14.11L10.9499 18.29C11.3399 18.68 11.9699 18.68 12.3599 18.29L22.9499 7.71004C23.3399 7.32004 23.3399 6.69004 22.9499 6.30004H22.9399C22.5599 5.90004 21.9299 5.90004 21.5399 6.29004ZM1.11989 14.12L5.29989 18.3C5.68989 18.69 6.31989 18.69 6.70989 18.3L7.40989 17.6L2.52989 12.7C2.13989 12.31 1.50989 12.31 1.11989 12.7C0.729893 13.09 0.729893 13.73 1.11989 14.12Z"
			:fill="color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
});

const { color, size } = toRefs(props);
</script>
