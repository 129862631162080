<template>
	<div class="delete-job-dropdown-item" @click.stop="onClick">
		<IconTrash :color="ThemeColors.danger[600]" size="1.125rem" />
		Delete collection
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconTrash, ThemeColors } from '@/shared/uikit/';
import { deleteCollectionModel } from './model';

const emit = defineEmits(['onClick']);
const { collectionId } = deleteCollectionModel;

const props = defineProps({
	collectionId: {
		type: String,
		required: true,
	},
});

const onClick = () => {
	collectionId.value = props.collectionId;
	Analytics.send(AnalyticsEvents.Collections.OPEN_DELETE_COLLECTION_MODAL);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.delete-job-dropdown-item
	width 100%
	display grid
	grid-template-columns auto 1fr
	align-items center
	justify-content flex-start
	padding: 0.625rem 1rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	gap 0.5rem
	color var(--danger-600)
	&:hover
		background: var(--danger-50)
		cursor pointer
</style>
