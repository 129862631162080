<template>
	<div class="collections-items-container">
		<div
			class="collections-items-target"
			:class="{ 'collections-items-target--shadow': isShadow }"
			@click="toggleDropdown"
		>
			<div v-if="items.length > 0" class="collections-items-target--left">
				<IconDropUp size="1.5rem" v-if="isOpened" />
				<IconDropDown size="1.5rem" v-else />
			</div>

			<IconCollections size="1.5rem" />

			<div class="subheadlineSemiBold">Collections</div>

			<bUnstyledButton @onClick="openNewCollectionModal">
				<template v-slot:content>
					<IconPlus size="1.25rem" />
				</template>
			</bUnstyledButton>
		</div>
		<transition>
			<div
				v-show="isOpened"
				class="collections-items-dropdown"
				ref="scrollContainer"
				@scroll="onScroll"
			>
				<div
					v-for="item in items"
					:key="`collections-items-dropdown--item-${item.id}`"
					class="collections-items-dropdown--item"
					@click="openCollection(item)"
				>
					<div class="collections-items-dropdown--item-info">
						<div class="footnoteBold">{{ item.name }}</div>
						<div class="captionRegular">
							{{ item.formattedSize }}
						</div>
					</div>

					<DeleteCollectionIcon :collectionId="item.id" />
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup lang="ts">
import {
	DeleteCollectionIcon,
	createCollectionModel,
} from '@/features/collections';
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabType, type CollectionEntity } from '@/shared/api';
import {
	IconCollections,
	IconDropDown,
	IconDropUp,
	IconPlus,
	bUnstyledButton,
} from '@/shared/uikit';
import { ref, type PropType } from 'vue';

const scrollContainer = ref<any>(null);

const props = defineProps({
	items: {
		type: Array as PropType<CollectionEntity[]>,
		required: true,
	},
});
const { isVisible } = createCollectionModel;
const isOpened = ref(true);
const isShadow = ref(false);

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
	scrollContainer.value.scrollTop = 0;
};

const onScroll = (e: any) => {
	const el = e.target;
	isShadow.value = el.scrollTop > 0;
};

const openCollection = (collection: CollectionEntity) => {
	const { addTab } = navigationTabsModel;

	addTab({
		id: `collection-${collection.id}`,
		title: 'Collection',
		description: collection.name,
		path: `/collection/${collection.id}`,
		type: NavigationTabType.Collection,
	});
};

const openNewCollectionModal = () => {
	isVisible.value = true;
};
</script>

<style lang="stylus" scoped>
.collections-items-container
	width 100%
	user-select none
	.collections-items-target
		position relative
		width 100%
		display grid
		grid-template-columns auto 1fr auto
		align-items center
		justify-content flex-start
		gap 0.75rem
		padding 0.75rem 1rem
		border-top-right-radius 0.5rem
		border-bottom-right-radius 0.5rem
		transition all 0.25s
		&:hover
			cursor pointer
		&--shadow
			border-bottom-left-radius 1rem
			border-bottom-right-radius 1rem
			box-shadow: 0px 10px 6px -10px rgba(11, 10, 13, 0.20), 0px 1px 2px -10px rgba(11, 10, 13, 0.05);
		&--left
			position absolute
			left -0.25rem
			display flex
			align-items center
			justify-content flex-start
			gap 0.15rem
		.b-unstyled-button
			display flex
			align-items center
			justify-content center
			border-radius 50%
			padding 0.25rem
			transition all 0.25s

	.collections-items-dropdown
		width 100%
		max-height 16rem
		overflow scroll
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border-radius: 0.25rem;
		z-index 1
		&::-webkit-scrollbar
			width 0.25rem
		.collections-items-dropdown--item
			width 100%
			display grid
			grid-template-columns 1fr auto
			align-items center
			justify-content space-between
			padding 0.75rem 1rem
			padding-left 2.5rem
			border-radius 0.5rem
			transition all 0.25s
			.collections-items-dropdown--item-info
				display flex
				flex-direction column
				align-items flex-start
				justify-content center
				gap 0.13rem
				.footnoteBold
					width 12rem
					white-space nowrap
					overflow hidden
					text-overflow ellipsis
			&:hover
				cursor pointer
				background-color var(--neutral-100)
</style>
