<template>
	<div class="ligand-search-page">
		<LigandBasedResultsWidget />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { LigandBasedResultsWidget } from '@/widgets/ligand-based-search/';
import { onMounted } from 'vue';

onMounted(() => {
	Analytics.send(AnalyticsEvents.DASHBOARD.VIEW_LIGAND_BASED_SEARCH);
});
</script>

<style lang="stylus" scoped>
.ligand-search-page
	width 100%
	height 100%
</style>
