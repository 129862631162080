import { Analytics, AnalyticsEvents } from '@/app/providers';
import {
	CollectionEntity,
	CollectionService,
	ShortCollectionEntity,
	type HitEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isVisible: boolean;
	isLoading: boolean;
}

const data: IViewModel = {
	isVisible: false,
	isLoading: false,
};

const state = reactive(data);

async function addHitToCollection(
	collection: CollectionEntity,
	hit: HitEntity
) {
	if (!collection) return;

	state.isLoading = true;
	try {
		hit.collections.push(ShortCollectionEntity.fromObject(collection));

		await CollectionService.getInstance().addHitToCollection(
			collection.id,
			[
				{
					catalog_id: hit.catalog_id,
					index: hit.index,
				},
			]
		);

		Analytics.send(AnalyticsEvents.Collections.ADD_MOLECULE_TO_COLLECTION, {
			collection_id: collection.id,
			catalog_id: hit.catalog_id,
			index: hit.index,
		});
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

async function removeHitFromCollection(collectionId: string, hit: HitEntity) {
	state.isLoading = true;
	try {
		hit.collections = hit.collections.filter(
			(collection) => collection.id !== collectionId
		);

		await CollectionService.getInstance().removeHitFromCollection(
			collectionId,
			[
				{
					catalog_id: hit.catalog_id,
					index: hit.index,
				},
			]
		);

		Analytics.send(
			AnalyticsEvents.Collections.REMOVE_MOLECULE_FROM_COLLECTION,
			{
				collection_id: collectionId,
				catalog_id: hit.catalog_id,
				index: hit.index,
			}
		);
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const addHitToCollectionDropdownModel = {
	...toRefs(state),
	addHitToCollection,
	removeHitFromCollection,
};
