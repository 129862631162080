<template>
	<div class="container">
		<IconLogo size="2.75rem" :color="ThemeColors.primary[600]" />

		<div class="login-container">
			<div class="header-block">
				<div class="calloutRegular">
					{{ $t('auth.verifyEmail.title') }}
				</div>
				<div class="footnoteRegular">
					{{ $t('auth.verifyEmail.subtitleFirst') }}
					<span>{{ userEmail }} </span>
					{{ $t('auth.verifyEmail.subtitleSecond') }}
				</div>
			</div>
			<bDivider />
			<div class="header-block">
				<div class="footnoteRegular">
					No email in your inbox or spam folder?
					<ResendVerifyEmailButton
						id="sendedVerifyEmail"
						@onResend="resend"
					/>.
				</div>
			</div>
			<bDivider />
		</div>
		<div class="login-footer">
			<div class="link footnoteRegular">
				{{ $t('auth.verifyEmail.footer.subtitleFirst') }}
				<span @click="logout">{{
					$t('auth.verifyEmail.button.logout')
				}}</span>
				{{ $t('auth.verifyEmail.footer.subtitleSecond') }}
			</div>
			<br />
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { logoutButtonModel } from '@/features/auth/logout/model';
import { IconLogo, ThemeColors, bDivider } from '@/shared/uikit';
import { onMounted } from 'vue';
import ResendVerifyEmailButton from './ResendVerifyEmailButton.vue';
import { verifyUserEmailModel } from './model';

const { logout, fetchUser, userEmail } = logoutButtonModel;
const { resend } = verifyUserEmailModel;

onMounted(() => {
	fetchUser();
	Analytics.send(AnalyticsEvents.VerifyEmail.VIEW_PAGE);
});
</script>
<style lang="stylus" scoped>
@import '../../../widgets/auth/shared/auth.styl';

.header-block
	.footnoteRegular
		span
			color var(--primary-600)
			cursor pointer
			transition all 0.2s ease-in-out
			&:hover
				color var(--primary-500)
				transition all 0.2s ease-in-out
</style>
