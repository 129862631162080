<template>
	<div v-if="isExistEmolecules" class="cart-button-item" @click="onClick">
		Order from eMolecules
	</div>
</template>

<script setup lang="ts">
import { navigationTabsModel } from '@/features/navigation';
import { NavigationTabEntity, NavigationTabType } from '@/shared/api';
import { computed } from 'vue';
import { cartModel } from '.';

const emit = defineEmits(['onClick']);

const isExistEmolecules = computed(() => {
	const { hits } = cartModel;
	const findedItem = hits.value.find(
		(item) =>
			item.catalog_id === '3aae946c-06cc-4737-b00f-097223cb7326' ||
			item.catalog_id === '8bc8012a-bb68-4271-8005-4c5c270fa924'
	);

	return findedItem ? true : false;
});

const onClick = () => {
	const { addTab, tabs } = navigationTabsModel;

	addTab(
		NavigationTabEntity.fromObject({
			id: `coumpounds-form`,
			title: 'eMolecules',
			description: 'Select compounds',
			path: `/cart/compounds`,
			type: NavigationTabType.Cart,
			tabs: tabs.value.length + 1,
			isPinned: false,
		})
	);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>

.cart-button-item
	width 100%
	display flex
	flex-direction row
	align-items center
	justify-content space-between
	padding: 0.85rem 0.75rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	color var(--neutral-700)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
