import CollectionDetailsPage from '../CollectionDetailsPage.vue';

export const collectionsRoutes = [
	{
		path: '/collection/:id',
		component: CollectionDetailsPage,
		name: 'collection-details',
		meta: {
			requiresAuth: true,
			requiresVerifiedEmail: true,
		},
	},
];
