<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.7501 6.59998C4.24715 6.59998 4.6501 7.00292 4.6501 7.49998V15.75C4.6501 17.7382 6.26187 19.35 8.2501 19.35H16.5001C16.9972 19.35 17.4001 19.7529 17.4001 20.25C17.4001 20.747 16.9972 21.15 16.5001 21.15H8.2501C5.26776 21.15 2.8501 18.7323 2.8501 15.75V7.49998C2.8501 7.00292 3.25304 6.59998 3.7501 6.59998Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : color"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.8 6V15C7.8 15.6627 8.33726 16.2 9 16.2H18C18.6627 16.2 19.2 15.6627 19.2 15V6C19.2 5.33726 18.6627 4.8 18 4.8H9C8.33726 4.8 7.8 5.33726 7.8 6ZM6 6V15C6 16.6569 7.34315 18 9 18H18C19.6569 18 21 16.6569 21 15V6C21 4.34315 19.6569 3 18 3H9C7.34315 3 6 4.34315 6 6Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : color"
		/>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { colorProp, sizeProp } from './config';

const props = defineProps({
	color: colorProp,
	size: sizeProp,
	isDisabled: Boolean,
});

const { color, size } = toRefs(props);
</script>
