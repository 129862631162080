<template>
	<div class="auth-page-template">
		<div class="auth-page-template--body">
			<slot name="body"></slot>
		</div>

		<div class="auth-page-template--footer">
			<div class="footnoteSemiBold">
				{{ $t('auth.signup.footer.about') }}
			</div>
			<div class="auth-page-template--footer-links">
				<a
					href="https://www.bioptic.io/terms-of-service"
					target="_blank"
					class="footnoteSemiBold"
					>{{ $t('auth.signup.footer.terms') }}</a
				>

				<a
					href="https://www.bioptic.io/privacy-policy"
					target="_blank"
					class="footnoteSemiBold"
					>{{ $t('auth.signup.footer.privacy') }}</a
				>
				<a
					href="https://bioptic.io/about"
					target="_blank"
					class="footnoteSemiBold"
					>{{ $t('auth.signup.footer.company') }}</a
				>
			</div>
		</div>
	</div>
</template>

<style lang="stylus" scoped>
.auth-page-template
	width 100vh
	&--body
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		width 100%
		max-width 32rem
		padding-top 2rem
		padding-bottom 1rem
		border 1px solid var(--neutral-200)
		border-radius: 1.75rem;
		z-index 1
	&--footer
		width 100%
		position absolute
		bottom 0
		left 0
		right 0
		padding 1.5rem
		display flex
		flex-flow row wrap
		justify-content space-between
		align-items center
		z-index 0
		.footnoteSemiBold
			color var(--neutral-600)
			&:nth-child(1)
				padding-left 1rem
		.auth-page-template--footer-links
			display flex
			flex-flow row wrap
			justify-content center
			align-items center
			gap 0.5rem
			.footnoteSemiBold
				padding 0.625rem 1rem
				text-decoration none
				letter-spacing: 0.00625rem;
			a
				&:hover
					color var(--primary-600)
					transition all 0.2s ease-in-out
</style>
