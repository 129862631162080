<template>
	<bModal
		v-if="isVisible"
		type="primary"
		title="Create new collection"
		:width="400"
		@onClose="onClose"
	>
		<template v-slot:right-controls>
			<bTextButton label="Cancel" :isGhost="true" @onClick="onClose" />
			<bButton
				label="Create collection"
				type="primary"
				:isGhost="true"
				:isDisabled="collectionName.length === 0"
				:isLoading="isLoading"
				@onClick="onCreate"
			/>
		</template>
		<template v-slot:body>
			<div class="suggest-modal-body">
				<bSimpleInput
					v-model="collectionName"
					placeholder="Name your collection"
				/>
			</div>
		</template>
	</bModal>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { bButton, bModal, bSimpleInput, bTextButton } from '@/shared/uikit/';
import { createCollectionModel } from './model';

const {
	isVisible,
	collectionName,
	isLoading,
	createNewCollection,
	createCollectionWithMolecule,
	includedHit,
	selectedHits,
	createNewCollectionWithSelected,
} = createCollectionModel;

const onClose = () => {
	isVisible.value = false;
	collectionName.value = '';

	Analytics.send(AnalyticsEvents.Collections.CLOSE_CREATE_COLLECTION_MODAL);
};

const onCreate = () => {
	if (includedHit.value) {
		createCollectionWithMolecule();
		return;
	}

	if (selectedHits.value.length > 0) {
		createNewCollectionWithSelected();
		return;
	}

	createNewCollection(collectionName.value);
};
</script>

<style lang="stylus">
.b-modal-header
	.bodyRegular
		justify-content flex-start !important
</style>
