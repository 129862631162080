// import { type RDKitModule } from '@rdkit/rdkit';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { app } from './app';
import { initSentry } from './app/providers/sentry';

async function initializeRDKit() {
	await new Promise<void>((resolve) => {
		const checkRDKit = () => {
			if (window.RDKit) {
				resolve();
			} else {
				setTimeout(checkRDKit, 100);
			}
		};
		checkRDKit();
	});

	initSentry(app);
	app.use(ElementPlus);
	app.mount('#app');
	console.log(
		`App Version: %c${import.meta.env.VITE_APP_VERSION}`,
		'background: #fff; color: #f00'
	);
	console.log(
		`App Environment: %c${import.meta.env.MODE}`,
		'background: #fff; color: #f00'
	);
}

initializeRDKit();
