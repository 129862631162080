<template>
	<div class="chat-main-widget">
		<ChatsGetStarted
			v-if="messages.length === 0"
			@onPromptSelected="useAlreadyPrompted"
		/>

		<ChatMessages
			v-else
			ref="chatMessagesRef"
			:messages="messages"
			:isLoading="isLoading"
			:isContuneButtonVisible="lastMessageType !== MessageType.OUTPUT"
			@on-click-continue="onSendMessage('Continue')"
		/>
		<ChemcrowChatInput
			@onSendMessage="onSendMessage"
			@onUploadFile="uploadFile"
			:isLoading="isLoading"
		>
			<template v-if="file !== null" v-slot:files>
				<div class="files-footer">
					<bChip :label="file.name" type="neutral">
						<template v-slot:left-icon>
							<IconFile size="1rem" />
						</template>
					</bChip>
				</div>
			</template>
		</ChemcrowChatInput>
	</div>
</template>

<script setup lang="ts">
import { router } from '@/app/providers';
import { ChemcrowChatInput } from '@/features/chats';
import { MessageType } from '@/shared/api';
import { bChip, IconFile } from '@/shared/uikit';
import { computed, onBeforeMount, ref } from 'vue';
import ChatMessages from './ChatMessages.vue';
import ChatsGetStarted from './ChatsGetStarted.vue';
import { chatWithMainModel } from './model';

const { messages, file, sendMessage, disconnect, isLoading, uploadFile } =
	chatWithMainModel;

const chatMessagesRef = ref<any | null>(null);

const onSendMessage = (message: string) => {
	const chat_id = router.currentRoute.value.params.id as string | null;
	sendMessage(message, chat_id);
	chatMessagesRef.value?.updateScroll();
};

const useAlreadyPrompted = (message: string) => {
	const chat_id = router.currentRoute.value.params.id as string | null;
	sendMessage(message, chat_id);
	chatMessagesRef.value?.updateScroll();
};

const lastMessageType = computed(() => {
	const lastMessage = messages.value[messages.value.length - 1];
	return lastMessage?.type;
});

onBeforeMount(() => {
	disconnect();
});
</script>

<style lang="stylus" scoped>
.chat-main-widget
	position relative
	width 100%
	height calc(100vh - 5.25rem)
	overflow scroll
	min-width 50rem
	display flex
	flex-direction column
	align-items center
	justify-content flex-start
	gap 2rem
	padding 0 10%
	padding-top 2rem
	.files-footer
		position absolute
		bottom 5rem
		left 0
		width 100%
		z-index 10
		display flex
		align-items center
		justify-content flex-start
		.b-chip
			text-decoration none !important
</style>
