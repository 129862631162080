<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="size"
		:height="size"
		viewBox="0 0 32 32"
		fill="none"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.2761 1.05715C20.7968 1.57785 20.7968 2.42207 20.2761 2.94277L12.5523 10.6666L18.6667 16.781L26.3905 9.05715C26.9112 8.53645 27.7554 8.53645 28.2761 9.05715C28.7968 9.57785 28.7968 10.4221 28.2761 10.9428L19.6095 19.6094C19.0888 20.1301 18.2446 20.1301 17.7239 19.6094L14.6667 16.5522L6.27614 24.9428C5.75544 25.4635 4.91122 25.4635 4.39052 24.9428C3.86983 24.4221 3.86983 23.5779 4.39052 23.0571L12.781 14.6666L9.72386 11.6094C9.20316 11.0887 9.20316 10.2445 9.72386 9.72382L18.3905 1.05715C18.9112 0.536451 19.7554 0.536451 20.2761 1.05715Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#0D9488'"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.8057 6.2989C20.6366 7.20625 22.1271 8.6968 23.0345 10.5277L25.0001 8.56201C23.9697 6.82145 22.5119 5.36372 20.7714 4.33324L18.8057 6.2989ZM14.8934 2.66872C14.818 2.66733 14.7425 2.66663 14.6667 2.66663C8.03933 2.66663 2.66675 8.03921 2.66675 14.6666C2.66675 17.0147 3.34117 19.2053 4.50688 21.0553L6.45525 19.1069C5.73973 17.7864 5.33341 16.274 5.33341 14.6666C5.33341 10.5228 8.03384 7.00979 11.7709 5.79126L14.8934 2.66872ZM14.6667 24C18.8105 24 22.3236 21.2995 23.5421 17.5625L26.6646 14.44C26.666 14.5153 26.6667 14.5909 26.6667 14.6666C26.6667 17.5 25.6848 20.1039 24.0427 22.1568L28.9429 27.0571C29.4636 27.5778 29.4636 28.422 28.9429 28.9427C28.4222 29.4634 27.578 29.4634 27.0573 28.9427L22.157 24.0425C20.1041 25.6846 17.5001 26.6666 14.6667 26.6666C12.3186 26.6666 10.128 25.9922 8.27811 24.8265L10.2265 22.8781C11.5469 23.5936 13.0594 24 14.6667 24Z"
			:fill="isDisabled ? ThemeColors.neutral[400] : '#1E293B'"
		/>
	</svg>
</template>

<script setup lang="ts">
import { ThemeColors } from '@/shared/uikit/';
import { defineProps, toRefs } from 'vue';
import { sizeProp } from '../config';

const props = defineProps({
	isDisabled: Boolean,
	size: sizeProp,
});

const { size } = toRefs(props);
</script>
