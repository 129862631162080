<template>
	<div class="pipeline-page">
		<div class="pipeline-page--logo">
			<div class="bodySemiBold">Choose our template for pipeline</div>
		</div>

		<nav class="applications-view">
			<ApplicationButton
				v-for="app in applications"
				:key="`application-button-${app.id}`"
				:value="app"
				@onClick="onClickApplication(app)"
			/>
		</nav>
	</div>
</template>

<script setup lang="ts">
import { ApplicationButton } from '@/features/home';
import type { ApplicationButtonProps } from '@/features/home/application-button/ui.vue';
import { ForeignObjectEntity } from '@/shared/api';
import { getCardSizeByStepType } from '@/shared/api/pipeline/PipelineService';
import { IconMagic, IconTargetSearch } from '@/shared/uikit';
import { markRaw, ref } from 'vue';

const emit = defineEmits(['onClickApplication', 'onClickManualCreatePipeline']);
const applications = ref<ApplicationButtonProps[]>([
	{
		id: 2,
		name: 'Target Driven Search',
		desc: 'Start search with Targets',
		icon: markRaw(IconTargetSearch),
		isDisabled: false,
		isNew: false,
		isBeta: false,
	},
	{
		id: 3,
		name: 'Manual Create Pipeline',
		desc: 'Create pipeline manually',
		icon: markRaw(IconMagic),
		isDisabled: false,
		isNew: false,
		isBeta: false,
	},
]);

const onClickApplication = (item: ApplicationButtonProps) => {
	if (item.id !== 2 && item.id !== 3) {
		return;
	}

	const data: any[] = [
		{
			id: 'a3e1f782-4d4e-4113-a5d8-c0bfa3c839bb',
			type: 'catalog_loader',
			data: {
				type: 'catalog_loader',
				catalog_id: '6c8819d5-8073-4be0-97e3-827f6f5f1a3e',
			},
			width: 400,
			height: 345,
			input: [],
			output: ['1d645617-3cd6-413a-9696-e6b6fc7136d6'],
			comment: null,
			isLoading: false,
		},
		{
			id: '1d645617-3cd6-413a-9696-e6b6fc7136d6',
			type: 'target_based_search',
			data: {
				type: 'target_based_search',
				top_k: 10000,
				protein_uni_prot_kb_ac: 'Q5S007',
				strategy: 'top_10_active_diverse',
			},
			width: 400,
			height: 450,
			input: ['a3e1f782-4d4e-4113-a5d8-c0bfa3c839bb'],
			output: ['183fb3ef-8cfd-4eb9-b00e-2828dfcb3464'],
			comment: null,
			isLoading: false,
		},
		{
			id: '183fb3ef-8cfd-4eb9-b00e-2828dfcb3464',
			type: 'attribute_filter',
			data: {
				type: 'attribute_filter',
				filters: [
					{
						attribute: 'hba',
						minimum: 0,
						maximum: 31,
					},
					{
						attribute: 'hbd',
						minimum: 0,
						maximum: 20,
					},
					{
						attribute: 'hac',
						minimum: 4,
						maximum: 78,
					},
					{
						attribute: 'molar_refractivity',
						minimum: 5.16,
						maximum: 326.5,
					},
					{
						attribute: 'log_p',
						minimum: -14.258,
						maximum: 24.782,
					},
					{
						attribute: 'weight',
						minimum: 51,
						maximum: 1078,
					},
					{
						attribute: 'tpsa',
						minimum: 0,
						maximum: 601,
					},
					{
						attribute: 'rotatable_bonds',
						minimum: 0,
						maximum: 64,
					},
					{
						attribute: 'f_csp3',
						minimum: 0,
						maximum: 1,
					},
				],
			},
			width: 400,
			height: 795,
			input: ['1d645617-3cd6-413a-9696-e6b6fc7136d6'],
			output: ['f9b3c7d1-d4e5-4a3b-b8c7-e9d4a1c7f8e4'],
			comment: 'Apply defaults druglikeness filters.',
			isLoading: false,
		},
		{
			id: 'f9b3c7d1-d4e5-4a3b-b8c7-e9d4a1c7f8e4',
			type: 'novelty_filter',
			data: {
				type: 'novelty_filter',
				method: 'tanimoto_ecfp',
				threshold: 0.4,
			},
			width: 400,
			height: 400,
			input: ['183fb3ef-8cfd-4eb9-b00e-2828dfcb3464'],
			output: [],
			comment: null,
			isLoading: false,
		},
	];

	const steps = data.map((step) => {
		const size = getCardSizeByStepType(step.data.type, step.data);
		return ForeignObjectEntity.fromObject({
			id: step.id,
			type: step.data.type,
			data: step.data,
			width: size.width,
			height: size.height,
			input: step.input,
			output: step.output,
			comment: step.comment,
		});
	});

	if (item.id === 2) {
		emit('onClickApplication', steps);
	}

	if (item.id === 3) {
		console.log('Manual Create Pipeline');
		emit('onClickManualCreatePipeline');
	}
};
</script>

<style lang="stylus" scoped>
.applications-view
	display flex
	flex-flow row wrap
	justify-content center
	align-items center
	gap 0.75rem
.pipeline-page
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	width 100%
	height 100%
	display flex
	flex-direction column
	align-items center
	justify-content center
	gap 1.25rem
	z-index 1
	.pipeline-page--logo
		margin-top -8rem
		height 6.25rem
		display flex
		flex-direction row
		align-items center
		justify-content center
</style>
