<template>
	<div :class="['b-input-container', className]">
		<div v-if="label" class="b-input-label footnoteRegular">
			<span class="footnoteRegular">{{ label }}</span>
		</div>
		<div
			v-if="placeholder"
			class="b-input-placeholder"
			:class="{
				'b-input-placeholder--focused': isFocused,
			}"
		>
			{{ placeholder }}
		</div>
		<input
			ref="inputRef"
			v-model="value"
			:class="classInputName"
			:type="computedType"
			@blur=""
			@focus=""
			autocomplete="off"
			class="b-input"
		/>

		<div
			:class="[
				'b-input-left-icon',
				{
					'b-input-left-icon__with-label': label,
					'b-input-left-icon__without-label': !label,
				},
			]"
		>
			<slot name="right-icon"></slot>
		</div>
		<div
			v-if="type === 'password' && isFocused"
			class="b-input-password-eye"
		>
			<IconEyeClose
				v-if="isShowPassword"
				@click="isShowPassword = false"
			/>
			<IconEye v-else @click="isShowPassword = true" />
		</div>
		<div class="b-input-caption">
			<template v-if="type == 'password' && caption.length > 0">
				<img src="@/shared/uikit/assets/icons/lock-neutral.svg" />
			</template>
			<template v-else-if="isCaption && isFocused">
				<img
					class="b-input-caption--error"
					src="@/shared/uikit/assets/icons/exclamation-triangle-danger.svg"
				/>
				<img
					class="b-input-caption--success"
					src="@/shared/uikit/assets/icons/check-circle-success.svg"
				/>
			</template>
			<span class="footnoteRegular">{{ caption }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { validateEmail } from '@/shared/lib/';
import { computed, defineExpose, ref, watch } from 'vue';
import { IconEye, IconEyeClose } from '.';
const value = ref<string>('');
const hasErorr = ref<string>('');
const isFocused = ref<boolean>(false);
const emit = defineEmits(['onChange']);
const inputRef = ref<any>(null);

const isShowPassword = ref<boolean>(false);

const props = defineProps({
	type: { type: String, required: false },
	size: { type: String, required: false, default: 'default' },
	label: { type: String, required: false },
	placeholder: { type: String, required: false },
	isDisabled: { type: Boolean, required: false, default: false },
	isCaption: { type: Boolean, required: false, default: false },
	isVerified: { type: Boolean, required: false, default: true },
	isEmptyDisabled: { type: Boolean, required: false, default: false },
});

function reset() {
	value.value = '';
}

function blurInput() {
	inputRef.value.blur();
}

function setValue(val: string) {
	value.value = val;
}

defineExpose({
	reset,
	blurInput,
	setValue,
});

watch(value, (newValue) => {
	emit('onChange', {
		value: value.value,
		isValid: isVerified(value.value),
	});

	isFocused.value = true;
	if (isVerified(newValue)) {
		hasErorr.value = '';
	} else if (newValue.length == 0) {
		hasErorr.value = '';
		isFocused.value = false;
	}
});

const caption = computed((): string => {
	if (hasErorr.value.length > 0) {
		return hasErorr.value;
	} else if (value.value.length == 0) {
		return '';
	} else {
		return 'Success';
	}
});

const isVerified = (str: string): boolean => {
	try {
		switch (props.type) {
			case 'email':
				return validateEmail(str);
			case 'text':
				return str.length >= 3;
			case 'password':
				return str.length >= 8;
			default:
				return false;
		}
	} catch (error: any) {
		hasErorr.value = error.message;
		return false;
	}
};

const className = computed((): any => {
	return {
		'b-input-container--error': hasErorr.value.length > 0,
		'b-input-container--success':
			hasErorr.value.length == 0 && value.value.length > 0,
		'b-input-container--caption': props.isCaption && isFocused.value,
		'b-input-container--without-caption': !props.isCaption,
	};
});

const classInputName = computed((): any => {
	var result = {
		'b-input--size-default': props.size === 'default',
		'b-input--size-small': props.size === 'small',
		'b-input--size-large': props.size === 'large',
		'b-input--succes': props.type === 'light',
		'b-input--disabled': props.isDisabled,
		'b-input--empty-disabled': props.isEmptyDisabled,
		'b-input--error': false,
		'b-input--success': false,
	};

	if (props.isVerified) {
		if (hasErorr.value.length > 0) {
			result['b-input--error'] = true;
		} else if (hasErorr.value.length == 0 && value.value.length > 0) {
			result['b-input--success'] = true;
		}
	}

	return result;
});

const computedType = computed(() => {
	if (props.type === 'password' && isShowPassword.value) {
		return 'text';
	} else {
		return props.type;
	}
});
</script>

<style lang="stylus" scoped>
@import '../../../app/styles/theme/variables.styl';

.b-input-container
	position relative
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content flex-start
	gap 6px
	.b-input-placeholder
		position absolute
		top 50%
		left 1rem
		transform translateY(-50%)
		font-family: var(--font-family);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		color: var(--neutral-500)
		border-radius 0.5rem
		transition all 0.25s ease-in-out
		&--focused
			top -8px
			padding-left 0.25rem
			padding-right 0.25rem
			background white
			transform translateY(0)
			font-family: var(--font-family);
			font-size: 0.6875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1rem;
			letter-spacing: 0.03125rem;
			transition all 0.25s ease-in-out
	.b-input-left-icon
		position absolute
		right 0.5rem
		display flex
		align-items center
		justify-content center

		&__without-label
			top 23%
		&__with-label
			top calc(50% - 0.05rem)

	.b-input-caption
		height 16px
		display flex
		flex-direction row
		align-items center
		justify-content flex-start
		gap 4px
		.footnoteRegular
			height 16px
			color var(--neutral-500)
			font-size 0.75rem
	&--without-caption
		.b-input-caption
			display none
	&--caption
		.b-input-caption
			height 16px
	&--error
		.b-input-caption--success
			display none
	&--success
		.b-input-caption--error
			display none
	.b-input-label
		padding 0px 4px
		.footnote.regular
			color $neutral-500
	input
		all: unset;
	.b-input
		width 100%
		padding 10px 12px
		border: 1px solid black;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		font-family: $font-family;
		font-style: normal;
		font-weight: 400;
		background white
		border: 1px solid $neutral-200;
		border-radius: 0.5rem;
		transition-property: background;
		transition-duration: .25s;
		transition-timing-function: ease-in
		&:focus
			border: 1px solid $primary-600;
		&::placeholder
			color: $neutral-500
		&--size-large
			height 3.5rem
			font-size: 0.875rem;
			line-height: 21px;
			padding 0 1rem
		&--size-default
			height 38px
			font-size: 15px;
			line-height: 18px;
		&--size-small
			height 32px
			font-size: 13px;
			line-height: 16px;
		&--error
			border: 1px solid $danger-600 !important
		&--success
			border: 1px solid $success-600 !important
		&--disabled
			pointer-events none
			user-select none
			background $neutral-200
		&--empty-disabled
			pointer-events none
			user-select none
	.b-input-password-eye
		width 3rem
		height 3rem
		position absolute
		right 0
		top 0.25rem
		display flex
		align-items center
		justify-content center
		cursor pointer
		transition all 0.2s ease-in-out
		&:hover
			opacity 0.9
			transition all 0.2s ease-in-out
</style>
