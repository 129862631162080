import { ocularRestErrorHandler, roundValues } from '@/shared/lib';
import { ADMETColumnEntity } from '@/widgets/chats/admet-table-results/entities';
import Papa from 'papaparse';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	url: string;
	previewTableData: any[];
	columns: any[];
}

const data: IViewModel = {
	url: '',
	isLoading: false,
	previewTableData: [],
	columns: [],
};

const state = reactive(data);

async function downdloadFile(newUrl: string) {
	state.isLoading = true;
	state.url = newUrl;
	try {
		const response = await fetch(newUrl);
		const csvText = await response.text();
		const parsedData = Papa.parse(csvText, { header: true });

		const filteredParsedData = parsedData.data.slice(0, 5).map(roundValues);

		const preparedInfo = ADMETColumnEntity.options;
		state.columns = Object.keys(filteredParsedData[0] || {})
			.filter((key) => key !== 'smiles')
			.map((key) => ({
				prop: key,
				label:
					preparedInfo.find((item) => item.label === key)?.name ||
					key,
			}));

		state.previewTableData = filteredParsedData;
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const messageTablePreviewModel = {
	...toRefs(state),
	downdloadFile,
};
