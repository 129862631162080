<template>
	<bUnstyledButton @onClick="onClick">
		<template v-slot:content>
			<IconTrash size="1.25rem" :color="ThemeColors.danger[600]" />
		</template>
	</bUnstyledButton>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconTrash, ThemeColors, bUnstyledButton } from '@/shared/uikit/';
import { deleteCollectionModel } from './model';

const emit = defineEmits(['onClick']);
const { collectionId } = deleteCollectionModel;

const props = defineProps({
	collectionId: {
		type: String,
		required: true,
	},
});

const onClick = () => {
	collectionId.value = props.collectionId;
	Analytics.send(AnalyticsEvents.Collections.OPEN_DELETE_COLLECTION_MODAL);
	emit('onClick');
};
</script>
<style lang="stylus" scoped>
.b-unstyled-button
	padding 0.25rem
	border-radius 50%
	display flex
	align-items center
	justify-content center
</style>
