import { ocularUserModel } from '@/entities/app';
import {
	ChemcrowService,
	ForeignObjectEntity,
	MessageType,
	PipelineMessageEntity,
	PipelineService,
} from '@/shared/api';
import { getCardSizeByStepType } from '@/shared/api/pipeline/PipelineService';
import { generateUuid } from '@/shared/lib';
import { reactive, toRefs } from 'vue';

interface IViewModel {
	messages: PipelineMessageEntity[];
	isLoading: boolean;
	isConnected: boolean;

	pipelines: Array<Array<ForeignObjectEntity>>;
}
const data: IViewModel = {
	messages: [],
	isLoading: false,
	isConnected: false,
	pipelines: [],
};

const state = reactive(data);

async function connect(): Promise<void> {
	try {
		const onopen = () => {};

		const onmessage = (event: MessageEvent) => {
			const receivedMessage = JSON.parse(event.data);

			const message = new PipelineMessageEntity(
				generateUuid(),
				receivedMessage.content,
				receivedMessage.type,
				new Date(Date.now())
			);

			state.messages.push(message);

			// console.log('Received message:', receivedMessage);

			if (
				receivedMessage.type === MessageType.OUTPUT ||
				receivedMessage.type === MessageType.THOUGHT
			) {
				state.isLoading = false;

				// console.log('Redirecting to chat:', receivedMessage.chat_id);
			}

			if (receivedMessage.type === MessageType.OUTPUT) {
				setPipelines(receivedMessage.content);
			}
		};

		const onerror = (event: Event) => {
			throw new Error('WebSocket error');
		};

		const onclose = (event: CloseEvent) => {
			console.log('WebSocket closed:', event);
			state.isConnected = false;
		};

		await PipelineService.getInstance().connect(
			onopen,
			onmessage,
			onerror,
			onclose
		);
	} catch (error: any) {
		state.isLoading = false;
		disconnect();
	}
}

async function disconnect(): Promise<void> {
	const chemcrowService = ChemcrowService.getInstance();
	await chemcrowService.disconnect();
	state.isConnected = false;
}

async function sendMessage(content: string): Promise<void> {
	state.isLoading = true;
	const { fetchUser } = ocularUserModel;
	await fetchUser();

	try {
		if (!state.isConnected) {
			await connect();
		}

		await PipelineService.getInstance().sendMessage(content, (message) => {
			console.log('Message sent:', message);
		});
	} catch (error: any) {
		console.error(error);
		state.isLoading = false;
	}
}

function newChat() {
	state.messages = [];
	state.isConnected = false;
	disconnect();
}

function setPipelines(content: string) {
	const steps: ForeignObjectEntity[] = JSON.parse(content).steps.map(
		(step: any) => {
			const size = getCardSizeByStepType(step.data.type, step.data);

			return ForeignObjectEntity.fromObject({
				id: step.id,
				type: step.data.type,
				data: step.data,
				width: size.width,
				height: size.height,
				input: step.input,
				output: step.output,
				comment: step.comment,
			});
		}
	);

	state.pipelines.push(steps);
}

export const pipelineChatModel = {
	...toRefs(state),
	disconnect,
	sendMessage,
	newChat,
	setPipelines,
};
