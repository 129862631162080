<template>
	<div class="cart-button-item" @click="onClick">
		{{ title }}
		<IconDownload size="1.25rem" />
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconDownload } from '@/shared/uikit';
import { computed } from 'vue';
import { cartModel } from './model';

const emit = defineEmits(['onClick']);

const { hits, exportSelectedHits } = cartModel;

const title = computed(() => {
	return `Export ${hits.value.length} ${hits.value.length === 1 ? 'selected molecule' : ' selected molecules'}`;
});

const onClick = () => {
	exportSelectedHits();
	Analytics.send(AnalyticsEvents.CART.CLICK_EXPORT_ITEMS);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.cart-button-item
	width 100%
	display flex
	flex-direction row
	align-items center
	justify-content space-between
	padding: 0.85rem 0.75rem
	font-family var(--font-family)
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem; /* 142.857% */
	letter-spacing: 0.00625rem;
	color var(--neutral-700)
	&:hover
		background: var(--neutral-100)
		cursor pointer
</style>
