<template>
	<div class="b-progress-container">
		<div class="b-progress" :style="dividerStyle"></div>
	</div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { ThemeColors } from '../theme/colors';

const props = defineProps({
	progress: {
		type: Number,
		default: 0,
	},
	isDefault: {
		type: Boolean,
		default: true,
	},
	size: {
		type: String,
		default: 'small',
	},
});

const { progress, isDefault } = toRefs(props);

const color = computed(() => {
	if (isDefault) {
		return ThemeColors.primary[500];
	} else {
		if (progress.value < 50) {
			return ThemeColors.success[600];
		} else if (progress.value < 80) {
			return ThemeColors.warning[600];
		} else {
			return ThemeColors.danger[600];
		}
	}
});

const dividerStyle = computed(() => ({
	backgroundColor: color.value,
	width: `${props.progress}%`,
}));
</script>

<style lang="stylus" scoped>
.b-progress-container
	position relative
	width 100%
	height 0.25rem
	// border-radius 0.5rem
	background-color var(--neutral-200)
	overflow hidden
	.b-progress
		height 100%
</style>
