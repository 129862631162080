import { router } from '@/app/providers';
import { navigationTabsModel } from '@/features/navigation';
import {
	DruglikenessEntity,
	JobsService,
	SearchAttributeType,
	SearchesAttributeEntity,
} from '@/shared/api';
import { ocularRestErrorHandler } from '@/shared/lib';
import { computed, reactive, toRefs } from 'vue';
import { AlertFilterOption, JobResultViewOption } from './entities';

interface IViewModel {
	isLoading: boolean;
	attributes: SearchesAttributeEntity[];
	druglikenessOptions: DruglikenessEntity[];
	clusteringOptions: JobResultViewOption[];
	alertsFilterOptions: AlertFilterOption[];
	noveltyThreshold: number[];
}
const data: IViewModel = {
	isLoading: false,
	attributes: [],
	druglikenessOptions: DruglikenessEntity.options,
	clusteringOptions: JobResultViewOption.options,
	alertsFilterOptions: AlertFilterOption.options,
	noveltyThreshold: [0, 0.4],
};

const state = reactive(data);

const selectedClusteringOption = computed(() => {
	return state.clusteringOptions.find((option) => option.isSelected);
});

const filters = computed(() => {
	const attributeFilters = state.attributes.flatMap((item) => {
		if (item.absolute_max !== item.max || item.absolute_min !== item.min) {
			return [
				{
					attribute: item.type,
					minimum: item.min,
					maximum: item.max,
				},
			];
		} else {
			return [];
		}
	});

	const alertsFilter = state.alertsFilterOptions
		.map((option) => {
			if (option.state == 0) {
				return [];
			}
			return {
				alert: option.label,
				is_triggered: option.state === 2,
			};
		})
		.flat();

	var results = [...attributeFilters, ...alertsFilter];

	const noveltyAttribute = state.attributes.find(
		(item) => item.type === SearchAttributeType.NOVELTY
	);

	if (
		noveltyAttribute &&
		(state.noveltyThreshold[0] !== noveltyAttribute.min ||
			state.noveltyThreshold[1] !== noveltyAttribute.max)
	) {
		const noveltyFilter = {
			attribute: SearchAttributeType.NOVELTY,
			minimum: state.noveltyThreshold[0],
			maximum: state.noveltyThreshold[1],
		};

		results.push(noveltyFilter);
	}

	return results;
});

async function fetchJobAttributes(id: string) {
	state.isLoading = true;
	try {
		const attributes =
			await JobsService.getInstance().fetchJobAttributes(id);
		state.attributes = attributes;
	} catch (error: any) {
		if (error.response?.status === 404) {
			const { removeCurrentTab } = navigationTabsModel;
			removeCurrentTab();
			router.push('/');
		} else {
			ocularRestErrorHandler(error);
		}
	} finally {
		state.isLoading = false;
	}
}

function setJobAttributes(attributes: SearchesAttributeEntity[]) {
	state.attributes = attributes;
	const findedAttribute = state.attributes.find(
		(item) => item.type === SearchAttributeType.NOVELTY
	);

	if (findedAttribute) {
		state.noveltyThreshold = [findedAttribute.min, findedAttribute.max];
	}
}

function setDefaultFilters() {
	state.attributes.forEach((item) => {
		item.min = item.absolute_min;
		item.max = item.absolute_max;
	});

	state.druglikenessOptions.forEach((option) => {
		option.isSelected = false;
	});

	const findedAttribute = state.attributes.find(
		(item) => item.type === SearchAttributeType.NOVELTY
	);

	if (findedAttribute) {
		state.noveltyThreshold = [findedAttribute.min, findedAttribute.max];
	}
}

function updateFiltersByDruglikeness() {
	state.attributes.forEach((filterItem) => {
		const relevantAttributes = state.druglikenessOptions
			.filter((option) => option.isSelected)
			.flatMap((option) => option.attributes)
			.filter((attr) => attr.attribute === filterItem.type);

		if (relevantAttributes.length > 0) {
			const minValues = relevantAttributes
				.map((attr) => attr.min)
				.concat(filterItem.min);
			const maxValues = relevantAttributes
				.map((attr) => attr.max)
				.concat(filterItem.max);

			const minIntersection = Math.max(...minValues);
			const maxIntersection = Math.min(...maxValues);

			if (minIntersection <= maxIntersection) {
				filterItem.min =
					minIntersection === -1 ? filterItem.min : minIntersection;
				filterItem.max = maxIntersection;
			}
		} else {
			filterItem.min = filterItem.absolute_min;
			filterItem.max = filterItem.absolute_max;
		}
	});
}
export const jobResultsFiltersModel = {
	...toRefs(state),
	fetchJobAttributes,
	setDefaultFilters,
	filters,
	updateFiltersByDruglikeness,
	selectedClusteringOption,
	setJobAttributes,
};
