<template>
	<div class="preselect-probes-container" ref="elementRef">
		<div
			:class="{
				'preselect-probes-target': true,
				'preselect-probes-target--active': isOpened,
			}"
			@click="toggleDropdown"
		>
			{{ selectedOption.label }}
			<IconArrowDropup
				v-if="isOpened"
				size="1.25rem"
				:color="ThemeColors.primary[600]"
			/>
			<IconArrowDropdown
				v-else
				size="1.25rem"
				:color="ThemeColors.primary[600]"
			/>
		</div>
		<div v-if="isOpened" class="preselect-probes-dropdown">
			<div
				class="preselect-probes-item"
				v-for="(option, index) in options"
				:key="'preselect-probes-item-key' + index"
				:class="{
					'preselect-probes-item--active':
						selectedOption.id === option.id,
				}"
				@click="selectOption(option)"
			>
				{{ option.label }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	IconArrowDropdown,
	IconArrowDropup,
	ThemeColors,
} from '@/shared/uikit';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { ProbesStrategyOptioin } from './entities';
import { probesListPreselectModel } from './model';

const emit = defineEmits(['onChange']);
const isOpened = ref(false);
const elementRef = ref<HTMLElement | null>(null);

const { selectedOption } = probesListPreselectModel;

const props = defineProps({
	isClustered: {
		type: Boolean,
		required: true,
	},
});

const options = computed(() => {
	return props.isClustered
		? ProbesStrategyOptioin.exampleClusters
		: ProbesStrategyOptioin.exampleProbes;
});

watch(
	() => props.isClustered,
	(newValue: boolean) => {
		selectedOption.value = ProbesStrategyOptioin.default;
		emit('onChange', selectedOption.value);
	},
	{ immediate: true }
);

const close = (e: any) => {
	if (elementRef.value && !elementRef.value.contains(e.target)) {
		isOpened.value = false;
	}
};

onMounted(() => {
	document.addEventListener('mousedown', close);
});

onBeforeUnmount(() => {
	document.removeEventListener('mousedown', close);
});

const toggleDropdown = (e: Event) => {
	isOpened.value = !isOpened.value;
};

const selectOption = (option: ProbesStrategyOptioin) => {
	selectedOption.value = option;
	isOpened.value = false;
	emit('onChange', option);
};
</script>

<style lang="stylus" scoped>
.preselect-probes-container
	position: relative
	.preselect-probes-target
		height: 2.5rem;
		padding: 0.625rem 1rem
		display: flex
		align-items: center
		justify-content: center
		gap 0.5rem
		font-family var(--font-family)
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.25rem;
		letter-spacing: 0.00625rem;
		transition all 0.2s ease-in-out
		cursor pointer
		user-select none
		color var(--primary-700)
		&--active
			transition all 0.2s ease-in-out



	.preselect-probes-dropdown
		position: absolute
		right: 0
		margin-top: 0.5rem
		display: flex;
		max-height 15rem
		padding: 0.625rem 0
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		border: 1px solid rgba(0, 0, 0, 0.10);
		border-radius: 0.25rem;
		background: var(--neutral-50);
		box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
		z-index 1
		overflow-y scroll
		&::-webkit-scrollbar
			width 0.25rem
		.preselect-probes-item
			width 100%
			display grid
			grid-template-columns 1fr auto
			align-items center
			justify-content flex-start
			padding: 0.625rem 1rem
			font-family var(--font-family)
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.25rem; /* 142.857% */
			letter-spacing: 0.00625rem;
			color var(--neutral-700)
			white-space nowrap
			&:hover
				background: var(--neutral-100)
				cursor pointer
			&--active
				color var(--primary-600)
				pointer-events none

.b-select-target--placeholder
	color: var(--neutral-500) !important;
</style>
