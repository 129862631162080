<template>
	<div class="lbss-prefilters-list-container">
		<div class="lbss-prefilters-list-container--header">
			<div class="lbss-prefilters-list-container--info">
				<div class="subheadlineBold">
					Set pre-filters to your results
				</div>
				<div class="bodySmallRegular">
					If you change the filters, the search time may change
				</div>
			</div>

			<bTooltip
				id="reset-filters-tooltip"
				hoverTitle="Reset filters"
				type="primary"
			>
				<template v-slot:body>
					<bUnstyledButton @onClick="setDefaultDilters">
						<template v-slot:content>
							<IconReplay size="1.25rem" />
						</template>
					</bUnstyledButton>
				</template>
			</bTooltip>
		</div>

		<div
			v-if="attributes.length > 0 && !isLoading"
			class="lbss-prefilters-list"
		>
			<SearchDruglikenessDrodropdown :isSmall="false" />
			<SearchFilterDrodropdown
				v-for="filter in attributes"
				:key="'filter-key-' + filter.type"
				:modelValue="filter"
				:isSmall="false"
				@update:modelValue="
					(newValue) => {
						filter.min = newValue.min;
						filter.max = newValue.max;
					}
				"
			/>
		</div>

		<bEmptyView
			v-if="attributes.length === 0 && !isLoading"
			title="Sorry, no catalogs found"
			description="Please try again later"
		>
			<template v-slot:image>
				<bImageEmpty2 :width="100" :height="100" />
			</template>
		</bEmptyView>

		<template v-if="isLoading">
			<div class="empty-view"><bLoader size="4" /></div>
		</template>
	</div>
</template>

<script setup lang="ts">
import {
	bEmptyView,
	bImageEmpty2,
	bLoader,
	bTooltip,
	bUnstyledButton,
	IconReplay,
} from '@/shared/uikit';
import { onMounted } from 'vue';
import { lbssPrefiltersListModel } from './model';
import SearchDruglikenessDrodropdown from './SearchDruglikenessDrodropdown.vue';
import SearchFilterDrodropdown from './SearchFilterDrodropdown.vue';

const { fetchPreFilters, attributes, isLoading, setDefaultDilters } =
	lbssPrefiltersListModel;

const props = defineProps({
	catalogId: {
		type: String,
		required: true,
	},
});

onMounted(() => {
	fetchPreFilters(props.catalogId);
});
</script>

<style lang="stylus" scoped>
.lbss-prefilters-list-container
	width 100%
	display flex
	flex-direction column
	gap 1rem
	&--info
		display flex
		flex-direction column
		gap 0.25rem
		padding-left 1.25rem
	&--header
		display flex
		flex-direction row
		align-items center
		justify-content space-between

	.lbss-prefilters-list
		display grid
		grid-template-columns repeat(auto-fill, minmax(15rem, 1fr))
		gap 0.75rem
		justify-content center
		transition all 0.25s
		&:hover
			cursor pointer
			transition all 0.25s
</style>
