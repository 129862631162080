<template>
	<div
		class="alert-filters-container"
		:class="{ 'alert-filters-container--opened': isOpened }"
		ref="elementRef"
	>
		<div class="alert-filters-target--header" @click="() => toggleFilter()">
			<section>
				<div class="subheadlineSemiBold">REOS filter</div>
			</section>
			<bUnstyledButton @onClick="() => toggleFilter()">
				<template v-slot:content>
					<IconArrowUp v-if="isOpened" size="1.125rem" />
					<IconArrowDown v-else size="1.125rem" />
				</template>
			</bUnstyledButton>
		</div>
		<div v-if="isOpened" class="alert-filters-dropdown">
			<div
				class="alert-section"
				@dragover.prevent="handleDragEnter('untriggered')"
				@drop.prevent="handleDrop(1)"
				@dragleave="handleDragLeave('untriggered')"
				:class="{ 'drag-over': isDragOver.untriggered }"
			>
				<div class="footnoteBold">Untriggered</div>
				<div
					v-if="untriggeredOptions.length > 0"
					class="alert-filters-dropdown-row"
				>
					<bNewChip
						v-for="(option, index) in untriggeredOptions"
						:key="'alert-filters-item-key' + index"
						:label="
							getTitleForAlert(option.label as MoleculeAlertsType)
						"
						:type="option.stateColor"
						@click="selectOption(option)"
						draggable="true"
						@dragstart="handleDragStart(option)"
					>
						<template v-slot:right-icon>
							<bUnstyledButton
								class="b-chip-close"
								@onClick="onRemove(option)"
							>
								<template v-slot:content>
									<IconFillCircleClose
										size="1rem"
										:color="ThemeColors.primary[600]"
									/>
								</template>
							</bUnstyledButton>
						</template>
					</bNewChip>
				</div>
				<div v-else class="alert-section-empty">
					<div class="bodySmallRegular">Include untriggered</div>
				</div>
			</div>

			<div
				class="alert-section"
				@dragover.prevent="handleDragEnter('triggered')"
				@drop.prevent="handleDrop(2)"
				@dragleave="handleDragLeave('triggered')"
				:class="{ 'drag-over': isDragOver.triggered }"
			>
				<div class="footnoteBold">Triggered</div>
				<div
					v-if="triggeredOptions.length > 0"
					class="alert-filters-dropdown-row"
				>
					<bNewChip
						v-for="(option, index) in triggeredOptions"
						:key="'alert-filters-item-key' + index"
						:label="
							getTitleForAlert(option.label as MoleculeAlertsType)
						"
						:type="option.stateColor"
						@click="selectOption(option)"
						draggable="true"
						@dragstart="handleDragStart(option)"
					>
						<template v-slot:right-icon>
							<bUnstyledButton
								class="b-chip-close"
								@onClick="onRemove(option)"
							>
								<template v-slot:content>
									<IconFillCircleClose
										size="1rem"
										:color="ThemeColors.danger[600]"
									/>
								</template>
							</bUnstyledButton>
						</template>
					</bNewChip>
				</div>
				<div v-else class="alert-section-empty">
					<div class="bodySmallRegular">Include triggered</div>
				</div>
			</div>

			<div
				class="alert-section"
				@dragover.prevent="handleDragEnter('unspecified')"
				@dragleave="handleDragLeave('unspecified')"
				@drop.prevent="handleDrop(0)"
				:class="{ 'drag-over': isDragOver.unspecified }"
			>
				<div class="footnoteBold">Unspecified</div>
				<div
					v-if="unspecifiedOptions.length > 0"
					class="alert-filters-dropdown-row"
				>
					<bNewChip
						v-for="(option, index) in unspecifiedOptions"
						:key="'alert-filters-item-key' + index"
						:label="
							getTitleForAlert(option.label as MoleculeAlertsType)
						"
						:type="option.stateColor"
						@click="selectOption(option)"
						draggable="true"
						@dragstart="handleDragStart(option)"
					/>
				</div>
				<div v-else class="alert-section-empty">
					<div class="bodySmallRegular">Leave as unspecified</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { MoleculeAlertsType, getTitleForAlert } from '@/shared/api';
import {
	IconArrowDown,
	IconArrowUp,
	IconFillCircleClose,
	ThemeColors,
	bNewChip,
	bUnstyledButton,
} from '@/shared/uikit';
import type { AlertFilterOption } from '@/widgets/job';
import { computed, ref } from 'vue';
import { jobResultsFiltersModel } from './model';

const emit = defineEmits(['onChange']);

const { alertsFilterOptions } = jobResultsFiltersModel;
const isOpened = ref(true);
const elementRef = ref<HTMLElement | null>(null);
let draggedOption = ref<AlertFilterOption | null>(null);
const isDragOver = ref<
	Record<'untriggered' | 'triggered' | 'unspecified', boolean>
>({
	untriggered: false,
	triggered: false,
	unspecified: false,
});

const unspecifiedOptions = computed(() =>
	alertsFilterOptions.value.filter((option) => option.state === 0)
);

const triggeredOptions = computed(() =>
	alertsFilterOptions.value.filter((option) => option.state === 2)
);

const untriggeredOptions = computed(() =>
	alertsFilterOptions.value.filter((option) => option.state === 1)
);

const selectOption = (option: AlertFilterOption) => {
	option.onClick();
	Analytics.send(AnalyticsEvents.Filter.SELECT_OPTION, {
		option,
		type: 'job',
	});
	emit('onChange');
};

const onRemove = (option: AlertFilterOption) => {
	option.onReset();
	Analytics.send(AnalyticsEvents.Filter.REMOVE_OPTION, {
		option,
		type: 'job',
	});
	emit('onChange');
};

const handleDragStart = (option: AlertFilterOption) => {
	draggedOption.value = option;
	Analytics.send(AnalyticsEvents.Filter.DRAG_START_OPTION, {
		option,
		type: 'job',
	});
};

const handleDrop = (newState: number) => {
	if (draggedOption.value) {
		draggedOption.value.state = newState;
		Analytics.send(AnalyticsEvents.Filter.DROP_OPTION, {
			option: draggedOption.value,
			newState,
			type: 'job',
		});
		emit('onChange');
		draggedOption.value = null;
	}
	handleDragLeave(
		newState === 0
			? 'unspecified'
			: newState === 1
				? 'untriggered'
				: 'triggered'
	);
};

const handleDragEnter = (
	section: 'untriggered' | 'triggered' | 'unspecified'
) => {
	isDragOver.value[section] = true;
};

const handleDragLeave = (
	section: 'untriggered' | 'triggered' | 'unspecified'
) => {
	isDragOver.value[section] = false;
};

const toggleFilter = () => {
	isOpened.value = !isOpened.value;
	Analytics.send(
		isOpened.value
			? AnalyticsEvents.Filter.OPEN_REOS_FILTER
			: AnalyticsEvents.Filter.CLOSE_REOS_FILTER,
		{ type: 'job' }
	);
};
</script>

<style lang="stylus" scoped>
.alert-filters-container
	width 100%
	display flex
	flex-direction column
	align-items flex-start
	justify-content center
	padding 1rem
	border-radius: 1rem
	background: #FFF
	box-shadow: 0px 0.85px 3px 0px rgba(11, 10, 13, 0.19), 0px 0.25px 1px 0px rgba(11, 10, 13, 0.04)
	z-index 1
	&:hover
		background var(--neutral-50)
		cursor pointer
	&--opened
		background var(--neutral-50)
		&:hover
			cursor default
		.alert-filters-target--header
			padding-bottom 1rem
	.alert-filters-target--header
		width 100%
		display flex
		flex-direction row
		align-items center
		justify-content space-between
		cursor pointer
		section
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			.bodySmallRegular
				color var(--neutral-600)
				white-space nowrap
		.b-unstyled-button
			border-radius 50%

	.alert-filters-dropdown
		display: flex
		width 100%
		padding: 0.625rem 0
		flex-direction: column
		align-items: flex-start
		justify-content: flex-start
		gap: 0.5rem
		background: var(--neutral-50)
		z-index 2
		.alert-section
			width 100%
			height 100%
			display flex
			flex-direction column
			gap 1rem
			&.drag-over .alert-section-empty
				border-color var(--primary-500)
			&.drag-over
				.alert-section-empty
					.bodySmallRegular
						color var(--primary-500)
			.alert-section-empty
				z-index 0
				width 100%
				padding 1rem
				height 4rem
				border 1px dashed var(--neutral-400)
				border-radius: 0.5rem
				display flex
				flex-direction column
				align-items center
				justify-content center
				.bodySmallRegular
					color: var(--neutral-500)

		.alert-filters-dropdown-row
			width 100%
			display: flex
			flex-flow: row wrap
			align-items: flex-start
			justify-content: flex-start
			gap: 0.25rem
			.b-new-chip
				cursor pointer
				opacity 1
				transition all 0.52s
				&:hover
					opacity 0.8
					transition all 0.52s

			.b-chip-close
				padding 0
				&:hover
					cursor pointer


.b-select-target--placeholder
	color: var(--neutral-500) !important
</style>
