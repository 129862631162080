<template>
	<div :class="className" @click.stop="onClick" class="b-radio">
		<div class="b-radio-dot"></div>
	</div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false,
	},
	size: { type: String, required: false, default: 'default' },
	type: { type: String, required: false, default: 'primary' },
	isDisabled: { type: Boolean, required: false, default: false },
});

const className = computed(() => {
	let className = `b-radio-${props.type}`;
	className += ` b-radio__${props.size.toLowerCase()}`;
	if (props.modelValue) {
		className += ' b-radio__is-checked';
	} else {
		className += ' b-radio__is-not-checked';
	}

	if (props.isDisabled) {
		className += ' b-radio__disabled';
	}

	return className;
});

const onClick = () => {
	if (!props.isDisabled) {
		emit('update:modelValue', !props.modelValue);
	}
};
</script>
<style lang="stylus">
.b-radio
    display flex
    flex-direction: row
    align-items: center
    justify-content: center
    border-radius: 50%
    background: var(--neutral-100)
    border: 2px solid var(--neutral-200)
    &:hover
        cursor pointer
        .b-radio-dot
            background: var(--neutral-50)

    .b-radio-dot
        border-radius 50%
        background: var(--neutral-100)
        transition: all .25s

// size

.b-radio__small
    width 18px
    height 18px
    .b-radio-dot
        height 10px
        width 10px
        transition: all .25s

    &:hover
        .b-radio-dot
            width 12px
            height 12px
            transition: all .25s
    &:active
        .b-radio-dot
            width 8px
            height 8px
            transition: all .25s


.b-radio__default
    width 1.5rem
    height 1.5rem
    .b-radio-dot
        width 12px
        height 12px
        transition: all .25s

    &:hover
        .b-radio-dot
            width 16px
            height 16px
            transition: all .25s
    &:active
        .b-radio-dot
            width 8px
            height 8px
            transition: all .25s

// color

.b-radio-primary
    &.b-radio__is-checked
        background: var(--primary-600)

    &.b-radio__is-not-checked
        &:hover
            border: none
            background: var(--primary-700)
            transition: all .25s

.b-radio__is-checked
    border none

.b-radio__is-not-checked
    background: var(--neutral-0)
    .b-radio-dot
        background: var(--neutral-0)

.b-radio__disabled
    background: var(--neutral-200) !important
    border none
    pointer-events: none !important
    &:hover
        cursor not-allowed
    .b-radio-dot
        background: var(--neutral-200) !important
</style>
