import { ocularRestErrorHandler } from '@/shared/lib';
import Papa from 'papaparse';
import { computed, reactive, toRefs } from 'vue';

interface IViewModel {
	isLoading: boolean;
	url: string;
	tableData: any[];
	columns: any[];
}

const data: IViewModel = {
	url: '',
	isLoading: false,
	tableData: [],
	columns: [],
};

const state = reactive(data);

const isVisible = computed(() => state.url !== '');

async function downdloadFile(newUrl: string) {
	state.isLoading = true;
	state.url = newUrl;
	try {
		const response = await fetch(newUrl);
		const csvText = await response.text();
		const parsedData = Papa.parse(csvText, { header: true });
		state.tableData = parsedData.data;
		state.columns = Object.keys(parsedData.data[0] || {}).map((key) => ({
			prop: key,
			label: key,
		}));
	} catch (error) {
		ocularRestErrorHandler(error);
	} finally {
		state.isLoading = false;
	}
}

export const eurofinFilePreviewModel = {
	...toRefs(state),
	isVisible,
	downdloadFile,
};
