<template>
	<div class="sugest-feature-menu-item" @click="onClick">
		<IconChat size="1.25rem" />
		<div class="footnoteSemiBold">Suggest a feature</div>
	</div>
</template>

<script setup lang="ts">
import { Analytics, AnalyticsEvents } from '@/app/providers';
import { IconChat } from '@/shared/uikit/';
import { suggestedFeatureModel } from './model';

const emit = defineEmits(['onClick']);

const { isVisible } = suggestedFeatureModel;

const onClick = () => {
	isVisible.value = true;
	Analytics.send(AnalyticsEvents.Home.OPEN_SUGGEST_FEATURE_MODAL);
	emit('onClick');
};
</script>

<style lang="stylus" scoped>
.sugest-feature-menu-item
	padding 0.75rem
	width 100%
	display: flex
	flex-direction: row
	align-items: center
	justify-content: flex-start
	gap 0.75rem
	border-radius 0.5rem
	&:hover
		background var(--neutral-50)
		cursor pointer
</style>
